import * as React from "react";
import { Form, Input } from "antd";
const { TextArea } = Input;

interface TextAreaInputProps {
    placeholder?: string;
    required?: boolean;
    warningMessage?: string;
    param: string;
    title?: string;
    rows?: number;
    minRows?: number;
    maxRows?: number;
    className?: string;
    formItemClassName?: string;
    hideClear?: boolean;
    autoFocus?: boolean;
    onChange?: any;
    onObjectChange?: any;
    onCleared?: any;
    shouldUpdate?: boolean;
    disabled?: boolean;
    actions?: any;
    defaultValue?: string;
    bordered?: boolean;
}

export class TextAreaInput extends React.Component<TextAreaInputProps> {
    onChange = (event) => {
        const value = event.target.value;

        if (this.props.onObjectChange)
            this.props.onObjectChange(value)
        if (this.props.onChange)
            this.props.onChange(value);

        if (this.props.onCleared && (!value || value.length == 0))
            this.props.onCleared();
    }
    render = () => {
        const { param, required, warningMessage, placeholder, rows, minRows, maxRows, className, autoFocus, disabled, formItemClassName, bordered } = this.props;
        const optional = !this.props.required ? " (optional)" : "";
        const title = this.props.title ? <><div>{this.props.title} {optional}</div><div>{this.props.actions ? this.props.actions : null}</div></> : null;

        return (
            <Form.Item
                label={title}
                name={param}
                shouldUpdate={this.props.shouldUpdate ? this.props.shouldUpdate : false}
                className={formItemClassName ? formItemClassName : ""}
                rules={
                    [{
                        required: required,
                        whitespace: true,
                        message: warningMessage ? warningMessage : 'Please type a value for this field'
                    }]}
            >
                <TextArea
                    placeholder={placeholder}
                    //rows={rows ? rows : 2}
                    autoSize={{
                        minRows: rows ? rows : 2,
                        maxRows: maxRows ? maxRows : 10
                    }}
                    autoFocus={autoFocus}
                    allowClear={this.props.hideClear ? false : true}
                    className={`${className ? className : ''} text-area-input`}
                    onChange={this.onChange}
                    disabled={disabled}
                    bordered={bordered}
                    defaultValue={this.props.defaultValue ?? null}
                />
            </Form.Item>
        );
    }
}