import * as React from "react";

import { Button } from "antd";
import { GeneralSelectorProps, SelectorInput } from "../Shared/SelectorInput";
import { PagedActorQuery, ActorType, ActorView, /*ActorView*/ } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { Capabilities } from "../../Definitions/_capabilties";
import { RequireAnyCapabilities } from "../Shared/RequireCapability";
import { Drawer } from "../Shared/Drawer";
import _ from "lodash";
import ActorSelector from "./ActorSelector";
import { PersonCreateForm } from "../People";
import { OrganizationCreateForm } from "../Organizations";
import ActorTypeSelector from "./ActorTypeSelector";



interface ActorWithCreateInputProps extends GeneralSelectorProps {
    fromOrganization?: boolean;
    onActorChange?: (actor: Partial</*ActorView*/any>) => void;
    onMultipleActorChange?: (actorIds: string[]) => void;
    types: ActorType[];
    selectorTitle: string;
    selectorPlaceholder?: string;
    filters?: Partial<PagedActorQuery>;
    optional?: boolean;
    disabled?: boolean;
    addText?: string;
    multiple?: boolean;
    renderInfo?: (entities: any[]) => JSX.Element | string;
    renderOptionItem?: (element: any, isLastItem?: boolean) => JSX.Element | string;
    value?: string;
}

interface ActorWithCreateInputState {
    showCreateActorDrawer: boolean;
    actorType: ActorType;
    error: string;
}

export class ActorWithCreateInput extends React.Component<ActorWithCreateInputProps, ActorWithCreateInputState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showCreateActorDrawer: false,
            error: null,
            actorType: null
        }
    }

    toggleAddActorDrawer = () => {
        this.setState({ actorType: null, showCreateActorDrawer: !this.state.showCreateActorDrawer });
    }

    onCompleteAddActor = async (data: ActorView) => {
        try {
            if (data) {
                const response = data; //await client.actors.getActorById(data.id);
                this.toggleAddActorDrawer();

                if (response) {
                    if (this.props.multiple && this.props.onMultipleActorChange)
                        this.props.onMultipleActorChange([response.id]);
                    else if (this.props.onActorChange)
                        this.props.onActorChange(response);
                }
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onMultipleActorChange = (actorIds: string[]) => {
        if (this.props.onMultipleActorChange)
            this.props.onMultipleActorChange(actorIds);
    }

    render = () => {
        let form = null;
        let typeTitle = this.state.actorType;

        const requiredCapabilities: Partial<Record<ActorType, Capabilities>> = {};

        _.each(this.props.types ?? [], type => {
            let capability: Capabilities = null;

            if (type == ActorType.Person)
                capability = Capabilities.PeopleWrite;
            else if (type == ActorType.Organization)
                capability = Capabilities.OrganizationsWrite;

            if (capability)
                requiredCapabilities[type] = capability;
        });

        if ((this.state.actorType && this.state.actorType == ActorType.Person) || (this.props.types.length == 1 && this.props.types[0] == ActorType.Person)) {
            form = <PersonCreateForm
                onCancel={this.toggleAddActorDrawer}
                onComplete={(created) => created ? this.onCompleteAddActor(created) : this.toggleAddActorDrawer()}
                fromOrganization={this.props.fromOrganization}
            />;

            typeTitle = ActorType.Person;
        }
        else if ((this.state.actorType && this.state.actorType == ActorType.Organization) || (this.props.types.length == 1 && this.props.types[0] == ActorType.Organization)) {
            form = <OrganizationCreateForm
                onCancel={this.toggleAddActorDrawer}
                onComplete={(created) => created ? this.onCompleteAddActor(created) : this.toggleAddActorDrawer()}
            />;

            typeTitle = ActorType.Organization;
        }
        //else if ((this.state.actorType && this.state.actorType == ActorType.Resource) || (this.props.types.length == 1 && this.props.types[0] == ActorType.Resource)) {
        //    form = <ResourceCreateOrEditForm
        //        onCancel={this.toggleAddActorDrawer}
        //        onComplete={(created) => created ? this.onCompleteAddActor(created) : this.toggleAddActorDrawer()}
        //        fromOrganization={this.props.fromOrganization}
        //    />;

        //    typeTitle = ActorType.Resource;
        //}
        //else if ((this.state.actorType && this.state.actorType == ActorType.Application) || (this.props.types.length == 1 && this.props.types[0] == ActorType.Application)) {
        //    form = <ApplicationCreateForm
        //        onCancel={this.toggleAddActorDrawer}
        //        onComplete={(created) => created ? this.onCompleteAddActor(created) : this.toggleAddActorDrawer()}
        //    />;

        //    typeTitle = ActorType.Application;
        //}
        else if (this.props.types.length > 1) {
            form =
                <ActorTypeSelector
                    excludeAllOption
                    excluded={_.filter(ActorType, val => !(requiredCapabilities[val] && this.context.user.hasCapability(requiredCapabilities[val])))}
                    value={this.state.actorType}
                    onChange={(type) => { this.setState({ actorType: type }) }}
                />;
        }

        return (
            <React.Fragment>
                <SelectorInput
                    title={
                        <React.Fragment>
                            <span style={{ width: '100%' }}>{this.props.selectorTitle}</span>
                            <RequireAnyCapabilities capabilities={[Capabilities.PeopleWrite, Capabilities.OrganizationsWrite, Capabilities.ResourcesWrite, Capabilities.ApplicationsWrite]}>
                                <Button type="link" onClick={this.toggleAddActorDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                                    {this.props.addText ? "+ " + this.props.addText : this.context.isMobile ? "+ Add" : "+ Add " + (typeTitle?.toLowerCase() ?? "actor")}
                                </Button>
                            </RequireAnyCapabilities>
                        </React.Fragment>
                    }
                    selector={
                        <ActorSelector
                            filters={this.props.filters ? Object.assign({}, this.props.filters, { types: this.props.types }) : { types: this.props.types }}
                            onObjectChange={this.props.multiple ? null : this.props.onActorChange}
                            onChange={this.props.multiple ? this.onMultipleActorChange : null}
                            placeholder={this.props.selectorPlaceholder ?? null}
                            disabled={this.props.disabled}
                            className={this.props.className ?? ""}
                            multiple={this.props.multiple}
                            renderInfo={this.props.renderInfo}
                            renderOptionItem={this.props.renderOptionItem}
                            value={this.props.value}
                        />

                    }
                    {...this.props}
                />

                <Drawer
                    title={this.props.addText ?? `Add ${typeTitle?.toLowerCase() ?? "actor"}`}
                    onClose={this.toggleAddActorDrawer}
                    open={this.state.showCreateActorDrawer}
                    destroyOnClose={true}
                    component={this.state.showCreateActorDrawer ? form : null}
                />
            </React.Fragment>
        );
    }
}