import { useState, useEffect, useRef } from 'react';

interface WidthComponentSizeProps {
    render: (ref: any, width: number) => JSX.Element
}

export const WidthComponentSize = (props: WidthComponentSizeProps) => {

    const ref = useRef<HTMLElement>(null);
    const getComponentDimensions = () => {
        return {
            width: ref.current ? ref.current.offsetWidth : 0
        };
    }

    const useComponentDimensions = () => {
        const [componentDimensions, setComponentDimensions] = useState(getComponentDimensions());

        useEffect(() => {
            setComponentDimensions(getComponentDimensions());
        }, [ref.current]);

        return componentDimensions;        
    }

    const { width } = useComponentDimensions();
    return props.render(ref, width);
}