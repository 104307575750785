import _ from "lodash";
import moment from "moment";
import { CategoryIds } from "../../Definitions/_definitions";
import client from "../client";
import { IncidentDateType, IncidentView, PagedIncidentQuery } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig } from "./BaseExportService";

export class IncidentExport extends BaseExportService<PagedIncidentQuery, IncidentView> {

    
    constructor() {

        const specification: ExportConfig<IncidentView>[] = [
            { header: 'Title', value: (data: IncidentView) => data.title ?? null },
            { header: 'Description', value: (data: IncidentView) => data.description ?? null },
            { header: 'Type', value: (data: IncidentView) => data.category?.name ?? null },
            {
                header: 'Causes', value: (data: IncidentView) => {
                    let causes = "";
                    _.map(data.tags ?? [], (cause, tag) => {
                        if (cause.category?.id == CategoryIds.IncidentCauses) {
                            if (causes.length != 0) causes += ", ";
                            causes += cause.name;
                        }
                    })
                    return causes;
                }
            },
            { header: 'Registered date', value: (data: IncidentView) => data.dates[this.correctCase(IncidentDateType.Occured)] ? moment(data.dates[this.correctCase(IncidentDateType.Occured)]).format() : moment(data.dates[this.correctCase(IncidentDateType.Registered)]).format() },
            { header: 'Registrator', value: (data: IncidentView) => data.registrator?.name ?? null },
            { header: 'Owner', value: (data: IncidentView) => data.owner?.name ?? null },
            {
                header: 'Customers', value: (data: IncidentView) => {
                    let customers = "";
                    _.map(data.customers ?? [], (customer, i) => {
                        if (i != 0) customers += ", ";
                        customers += customer.name;
                    })
                    return customers;
                }
            },
            {
                header: 'Participants', value: (data: IncidentView) => {
                    let participants = "";
                    _.map(data.otherParticipants ?? [], (participant, i) => {
                        if (i != 0) participants += ", ";
                        participants += participant.name;
                    })
                    return participants;
                }
            },
            {
                header: 'Products', value: (data: IncidentView) => {
                    let products = "";
                    _.map(data.products ?? [], (product, i) => {
                        if (i != 0) products += ", ";
                        products += product.name;
                    })
                    return products;
                }
            },
            {
                header: 'Tags', value: (data: IncidentView) => {
                    let tags = "";
                    _.map(data.tags ?? [], (tag, i) => {
                        if (tag.category?.id != CategoryIds.IncidentCauses) {
                            if (tags.length != 0) tags += ", ";
                            tags += tag.name;
                        }
                    })
                    return tags;
                }
            },
            { header: 'Created', value: (data: IncidentView) => data.created ? moment(data.created).format('DD.MM.YYYY') : null },
            { header: 'Updated', value: (data: IncidentView) => data.lastModified ? moment(data.lastModified).format('DD.MM.YYYY') : null },
            { header: 'Excepted closing', value: (data: IncidentView) => data.dates[this.correctCase(IncidentDateType.ExpectedClose)] ? moment(data.dates[this.correctCase(IncidentDateType.ExpectedClose)]).format() : "" },
            { header: 'Closed date', value: (data: IncidentView) => data.dates[this.correctCase(IncidentDateType.Closed)] ? moment(data.dates[this.correctCase(IncidentDateType.Closed)]).format() : null },
            { header: 'Closer', value: (data: IncidentView) => data.closer?.name ?? null },
        ];

        super(specification, client.incidents.queryIncidents);
    }
}