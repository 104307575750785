import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Button, Radio, List } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CompletionView, CreateCompletion, OrderlineView, OrderView, UpdateCompletion } from "../../ApiClient/swagger/data-contracts";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { NumberInput } from "../Shared/NumberInput";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";


interface CreateCompletionProps {
    onComplete: (request: CreateCompletion) => Promise<void>;
    orderline: Partial<OrderlineView>;
    productId: string;
    totalQuantity: number;
    order?: OrderView;
}

interface CreateCompletionState {
    loading: boolean;
    error: string;
    completion: CompletionView;
    view: string;
}

class CompletionCreateForm extends React.Component<CreateCompletionProps, CreateCompletionState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            completion: null,
            view: "create"
        }
    }

    onSubmit = async (request: Partial<CreateCompletion>) => {
        this.setState({ loading: true });

        const command: CreateCompletion = {
            orderlineId: this.props.orderline.id,
            quantity: request.quantity,
            timestamp: request.timestamp,
            itemId: this.props.productId,
        };

        try {
            await this.props.onComplete(command);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    validateQuantity = (rule, value) => {
        let sumCompleted = 0;
        if (this.props.orderline.completions) {
            for (let i = 0; i < this.props.orderline.completions.length; i++) {
                if (this.props.orderline.completions[i].item.id == this.props.productId) {
                    if (!this.props.orderline.completions[i].deleted)
                        sumCompleted += this.props.orderline.completions[i].quantity;
                }
            }
        }

        if (this.state.completion != null) {
            const currentCompletion = _.find(this.props.orderline.completions, c => { return c.id === this.state.completion.id });
            if (currentCompletion != null)
                sumCompleted -= currentCompletion.quantity
        }
        const isNegative = this.props.totalQuantity < 0;

        if (value === 0){
            return Promise.reject("Quantity must be larger than 0");
        }

        else if ((!isNegative && (value + sumCompleted) > this.props.totalQuantity) || (isNegative && (value + sumCompleted) < this.props.totalQuantity)) {

            return Promise.reject(new Error("Quantity can't exceed orderline quantity"));
        }

        else if (isNegative && (value + sumCompleted) > 0)
            return Promise.reject("Can't end up with positive amount on negative quantity");

        else if (!isNegative && (value + sumCompleted) < 0)
            return Promise.reject("Can't end up with negative amount on positive quantity");

        else
            return Promise.resolve();

    }

    onSaveCompletionSubmit = async (request: UpdateCompletion) => {
        if (this.props.order) {
            this.setState({ loading: true });

            const response = await client
                .orders.updateCompletion(this.props.order.id, this.state.completion.id, request, addChangeVectorHeader(this.props.order.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) {

            }

            this.setState({ completion: null, loading: false });
        }
    }

    editCompletion = (completion: CompletionView) => {
        this.setState({ completion });
    }

    removeCompletion = async (completion: CompletionView) => {
        if (this.props.order) {
            this.setState({ loading: true });

            const response = await client.orders
                .deleteCompletion(this.props.order.id, completion.id, addChangeVectorHeader(this.props.order.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) {

            }

            this.setState({ loading: false });
        }
    }

    onViewChange = (e) => {
        this.setState({ view: e.target.value });
    }

    render = () => {
        const completions = this.props.orderline ? _.reject(this.props.orderline.completions, c => {
            if (c)
                return c.deleted;
            else
                return c == null;
        }) : [];

        let view = null;
        if (this.state.view == "edit") {
            view = (
                <React.Fragment>
                    <List
                        className="completion-list"
                        itemLayout="vertical"
                        bordered
                        dataSource={completions}
                        renderItem={item => {
                            return (
                                <List.Item className="completion-list-item">
                                    <div>
                                        <div><span>Quantity:</span> {item.quantity}</div>
                                        <div><span>Timestamp:</span> {moment(item.timestamp).format('DD.MM.YYYY HH:mm')}</div>
                                    </div>
                                    <div className="table-actions completion-list-actions">
                                        <Button
                                            shape="circle"
                                            icon={<EditOutlined />}
                                            type="default"
                                            loading={this.state.loading}
                                            onClick={() => this.editCompletion(item)}
                                        />
                                        <Button
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            type="default"
                                            danger
                                            loading={this.state.loading}
                                            onClick={(e) => { e.stopPropagation(); this.removeCompletion(item) }}
                                        />
                                    </div>
                                </List.Item>
                            );
                        }}
                    />

                    {this.state.completion != null ?
                        <BaseForm
                            type={FormType.Edit}
                            onSubmit={this.onSaveCompletionSubmit}
                            loading={this.state.loading}
                            error={this.state.error}
                            initialValues={{
                                quantity: this.state.completion.quantity,
                                timestamp: moment(this.state.completion.timestamp)
                            }}
                            className="edit-completion-form"
                            key={this.state.completion.id}
                        >
                            <NumberInput
                                param="quantity"
                                title="Quantity"
                                required
                                min={1}
                                max={this.props.orderline ? this.props.orderline.quantity : null}
                                validator={this.validateQuantity}
                            />

                            <SelectorInput
                                key="timestamp"
                                param="timestamp"
                                title="Completion date"
                                required
                                warningMessage="Please choose a completion date"
                                selector={<DateSelector showTime />}
                            />
                        </BaseForm> : null}
                </React.Fragment>
            );
        }
        else {
            view = (
                <BaseForm
                    type={FormType.Create}
                    onSubmit={this.onSubmit}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={{
                        quantity: 1,
                        timestamp: moment()
                    }}
                >
                    <NumberInput
                        param="quantity"
                        title="Quantity"
                        required
                        //min={1}
                        max={this.props.orderline ? this.props.orderline.quantity : null}
                        validator={this.validateQuantity}
                    />

                    <SelectorInput
                        key="timestamp"
                        param="timestamp"
                        title="Completion date"
                        required
                        warningMessage="Please choose a completion date"
                        selector={<DateSelector showTime />}
                    />
                </BaseForm>
            );
        }

        return (
            <React.Fragment>
                <Radio.Group defaultValue="create" onChange={this.onViewChange} className="radio-actions">
                    <Radio.Button value="create">Create</Radio.Button>
                    <Radio.Button value="edit">Edit</Radio.Button>
                </Radio.Group>
                {view}

            </React.Fragment>
        );
    }
}

export default CompletionCreateForm;