import { Button, Divider, Form } from "antd";
import { useContext, useState } from "react";
import client from "../../ApiClient/client";
import { PagedTaskQuery, TaskView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import { Drawer } from "../Shared/Drawer";
import EntitySelector from "../Shared/EntitySelector";
import { RequireCapability } from "../Shared/RequireCapability";
import TaskCreateForm from "./TaskCreateForm";


export function TaskSelector(props: GenericEntitySelectorProps<PagedTaskQuery>) {
    return (
        <EntitySelector
            placeholder={props.placeholder ?? 'Select task...'}
            source={props.source ?? client.tasks.queryTasks}
            defaultValueSource={props.defaultValueSource ?? client.tasks.getTaskById}
            optionDisplayNameParam={props.optionDisplayNameParam ?? 'name'}
            renderOptionItem={(item, isLastItem) => {
                let path = [item.project.name]
                if (item.parents) {
                    path = path.concat(item.parents.reverse().map((item) => item.name));
                }             
                return (
                    <>
                        <p style={{ margin: 0, fontSize: 11 }}>{path.join(" / ")}</p>
                        {item.name}
                        {isLastItem ? null : <Divider style={{ margin: 0, marginTop: "5px" }} />}
                    </>
                )
            }}
            {...props}
        />
    );
}


interface TaskSelectorInputProps extends GenericEntitySelectorProps<PagedTaskQuery> {
    required?: boolean;
    warningMessage?: string;
    param: string;
    className?: string;
    titleLink?: React.ReactNode;
}


export function TaskSelectorInput(props: TaskSelectorInputProps) {

    const [addedTask, setAddedTask] = useState<TaskView>();
    const [showCreateDrawer, setShowCreateDrawer] = useState(false);

    const context = useContext(AppContext);

    function onComplete(added: TaskView) {
        setAddedTask(added);

        if (props.onChange)
            props.onChange(added.id);

        if (props.onObjectChange)
            props.onObjectChange(added);

        setShowCreateDrawer(false);
    }

    return (
        <>
            <Form.Item
                name={props.param}
                label={
                    <>
                        <span style={{ width: '100%' }}>{props.title}</span>
                        <RequireCapability capability={Capabilities.ProjectsRoleBased}>
                            <Button type="link" onClick={() => setShowCreateDrawer((current) => !current)} className={`select-task-title ${context.isMobile ? "task-title-mobile" : "task-title-desktop"}`}>
                                {context.isMobile ? "+ Add" : "+ Add  task"}
                            </Button>
                        </RequireCapability>
                    </>
                }
            >
                <TaskSelector
                    {...Object.assign(
                        {},
                        props,
                        { value: addedTask?.id ?? props.value },
                        { title: props.title ? null : props.title }
                    )}
                />

                <Drawer
                    title="Add new task"
                    onClose={() => setShowCreateDrawer(false)}
                    open={showCreateDrawer}
                    destroyOnClose={true}
                    component={<TaskCreateForm onComplete={onComplete} onCancel={() => setShowCreateDrawer(false)} project={addedTask?.project} />}
                />
            </Form.Item>

        </>
    );
}

export default TaskSelector;