import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Card, Avatar } from "antd";
import Title from "antd/lib/typography/Title";
import { BankOutlined, UserOutlined, PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { CallTargetView, ContactPointType, ContactType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { ContactPointLink, ContactPointPartialLink } from "../ContactPoints";
import { ActorLink } from "../Actors";


interface CallContactCardProps {
    contact: CallTargetView;
    className?: string;
    title: string;
    onCallTargetChanged?: (callTarget: CallTargetView) => void;
}

interface CallContactCardState {

}

interface CallActorContactCardProps {
    contact: CallTargetView;
    className?: string;
    title: string;
}


export class CallContactCard extends React.Component<CallContactCardProps, CallContactCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (!this.props.contact) return <Card title={<Title level={4} className="title">{this.props.title ?? " "}</Title>} />;

        if (this.props.contact && this.props.contact.contact != null)
            return <CallActorContactCard contact={this.props.contact} title={this.props.title} className={this.props.className} />;

        else {
            return (
                <Card
                    key="actor-info-card"
                    title={<Title level={4} className="title">{this.props.title ?? " "}</Title>}
                    className={`actor-info-card info-card-container ${this.props.className ? this.props.className : ""}`}
                >
                    <dl className="info">
                        <div className="actor-info contact-container">
                            <div className="avatar">
                                <Avatar shape="square" size={90} icon={this.props.contact.type == ContactPointType.Phone ? <PhoneOutlined /> : this.props.contact.type == ContactPointType.Email || this.props.contact.type == ContactPointType.Video ? <VideoCameraOutlined /> : null} />
                            </div>
                            <div>
                                <dt>Caller</dt>
                                <dd><ContactPointPartialLink contactPoint={this.props.contact} onCallTargetChanged={this.props.onCallTargetChanged} /></dd>
                            </div>
                        </div>

                        {this.props.contact.description ? <div className="description">
                            <dt>Description</dt>
                            <dd>{this.props.contact.description}</dd>
                        </div> : null}
                    </dl>
                </Card>
            );
        }
    }
}


export class CallActorContactCard extends React.Component<CallActorContactCardProps, CallContactCardState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (!this.props.contact || !this.props.contact.contact) return <Card title={<Title level={4} className="title">{this.props.title ?? " "}</Title>} />;

        const { contact } = this.props;

        const tagView = null;
        //    _.map(actor.tags || [], tag => {
        //    return <TagLink key={tag.id} {...tag} />;
        //});

        const orgRolesView = _.map(contact.organizationRoles || [], r => {
            return (
                <div key={r.organization.id}>
                    <ActorLink {...r.organization} /> {r.endDate ? "(Ended: " + moment(r.endDate).format('DD.MM.YYYY') + ")" : null}
                    {r.description ? " - " + r.description : null}
                </div>
            );
        });

        const hasTags = false; //tagView && tagView.length > 0;
        const hasDescription = contact.description != null && contact.description.trim() != "";
        const hasOrgRoles = orgRolesView && orgRolesView.length > 0;
        const hasTagsOrDescription = hasTags || hasDescription;

        const description = hasDescription ? (
            <div className="description">
                <dt>Description</dt>
                <dd>{contact.description}</dd>
            </div>
        ) : null;

        const orgRoles = hasOrgRoles ? (
            <div className="orgRoles">
                <dt>Organizations</dt>
                <dd>{orgRolesView}</dd>
            </div>
        ) : null;


        let subContent = null;
        let breakContent = null;

        if (hasTags) {
            subContent = tagView;
            breakContent = (
                <React.Fragment>
                    {description}
                    <br />
                    {orgRoles}
                </React.Fragment>
            );
        }
        else if (hasDescription) {
            subContent = contact.description;
            breakContent = orgRoles;

        }
        else if (hasOrgRoles) {
            subContent = orgRoles;
            breakContent = null;
        }

        const urlPath = contact?.contact?.contactType === ContactType.Organization ? "organizations" : contact?.contact?.contactType === ContactType.Person ? "people" : "resources";

        return (
            <Card
                key="actor-info-card"
                title={<Title level={4} className="title">{this.props.title ?? " "}</Title>}
                className={`actor-info-card info-card-container ${this.props.className ? this.props.className : ""}`}
            >
                <dl className="info">
                    <div className={!hasTags ? "actor-info contact-container" : "contact-container"}>
                        <div className={`avatar ${hasTagsOrDescription ? "" : "no-tags-description"}`}>
                            <Avatar shape="square" size={90} src={`/api/${urlPath}/${contact.contact.id}/avatar`} icon={contact?.contact?.contactType === ContactType.Organization ? <BankOutlined /> : <UserOutlined />} />
                        </div>
                        <div className="name">
                            <ActorLink {...contact.contact} />
                        </div>

                        <div className="subcontent">
                            (<ContactPointLink {...
                                {
                                    id: this.props.contact.id,
                                    description: this.props.contact.description,
                                    type: this.props.contact.type,
                                    value: this.props.contact.value
                                }} hideLabel disablePopover
                            />)
                            <div className="space" />
                            {subContent}
                        </div>
                    </div>
                    <br />
                    {breakContent}
                </dl>
            </Card>
        );
    }
}