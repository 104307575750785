import _ from "lodash";
import moment from "moment";
import client from "../client";
import { LeadProbability, LeadView, PagedLeadQuery } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class LeadExport extends BaseExportService<PagedLeadQuery, LeadView> {

    constructor() {

        const specification: ExportConfig<LeadView>[] = [
            { header: 'Customer', value: (data: LeadView) => data.customer ? data.customer.name : null },
            { header: 'Contact person', value: (data: LeadView) => data.contactPerson ? data.contactPerson.name : null },
            { header: 'Title', value: (data: LeadView) => data.title ?? null },
            { header: 'Description', value: (data: LeadView) => data.description ?? null },
            { header: 'Status', value: (data: LeadView) => data.status ?? null },
            {
                header: 'Progress', value: (data: LeadView) => {
                    if (data.probability === LeadProbability.VeryLow)
                        return "1-20%";
                    else if (data.probability === LeadProbability.Low)
                        return "21-40%";
                    else if (data.probability === LeadProbability.Medium)
                        return "41-60%";
                    else if (data.probability === LeadProbability.High)
                        return "61-80%";
                    else if (data.probability === LeadProbability.VeryHigh)
                        return "81-100%";
                    else
                        return data.probability;
                }
            },
            { header: 'Next action', value: (data: LeadView) => data.expectedClose ? moment(data.expectedClose).format() : null },
            { header: 'Fixed price', value: (data: LeadView) => data.fixedPrice ? data.fixedPrice : null, format: ExportFormat.Currency },
            { header: 'Annual price', value: (data: LeadView) => data.annualPrice ? data.annualPrice : null, format: ExportFormat.Currency },
            { header: 'Estimate', value: (data: LeadView) => data.estimate ? data.estimate : null, format: ExportFormat.Duration },
            { header: 'Owner', value: (data: LeadView) => data.owner ? data.owner.name : null },
            { header: 'Latest note time', value: (data: LeadView) => data.latestNote ? moment(data.latestNote.timestamp).format() : null },
            { header: 'Latest note text', value: (data: LeadView) => data.latestNote ? data.latestNote.text : null },
            { header: 'Latest note author', value: (data: LeadView) => data.latestNote && data.latestNote.author ? data.latestNote.author.name : null },
            {
                header: 'Attributes', value: (data: LeadView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })
                    }

                    return dataString
                }
            },
            { header: 'Created', value: (data: LeadView) => data.created ? moment(data.created).format('DD.MM.YYYY') : null },
            { header: 'Updated', value: (data: LeadView) => data.lastModified ? moment(data.lastModified).format('DD.MM.YYYY') : null },
            { header: 'Deleted', value: (data: LeadView) => data.deleted ? true : false }
        ];

        super(specification, client.leads.queryLeads);
    }
}