import { lazy } from 'react';

export * from './DetailsView';
export * from './ExtensionMappers';
export * from './FileCreateForm';
export * from './FileEditForm';
export * from './FileHelpers';
export * from './FileMailContent';
export * from './FileModal';
export * from './FileTextContent';
export * from './FileMoveForm';
export * from './FolderCreateForm';
export * from './FolderEditForm';
export * from './GalleryView';
export * from './FolderViewCard';
export * from './FolderSelector';

export const FileListCard = lazy(() => import('../Documents/FileListCard'));

