import * as React from "react";
import _ from 'lodash';
import { ApplicationView, CreateFileOrFolder, FolderView, PagedApplicationQuery, ParentRef } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { CreateEntityFolder, GetEntityFolderById } from "../../ApiClient/EntityFileClient";
import { ApplicationSelector } from "../Applications";
import client from "../../ApiClient/client";
import { CustomFormLabel } from "../Shared/CustomFormLabel";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { FolderIds } from "../../Definitions/_definitions";
import { AdditionalInfo } from "../Shared/AdditionalInfo";

interface CreateFolderProps {
    onCancel?: () => void;
    onComplete: (created: FolderView) => void;
    entityId: string;
    parent: ParentRef;
    getFolderSource: GetEntityFolderById;
    createFolderSource: CreateEntityFolder;
}

interface CreateFolderState {
    loading: boolean;
    loadingApp: boolean;
    loadingParent: boolean;
    error: string | null;
    folderName: string;
    parentFolder: FolderView;
    application: ApplicationView;
}

export class FolderCreateForm extends React.Component<CreateFolderProps, CreateFolderState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            loadingApp: false,
            loadingParent: false,
            error: null,
            folderName: null,
            parentFolder: null,
            application: null
        }
    }

    componentDidMount = async () => {
        if (this.props.parent != null) {
            this.setState({ loadingParent: true });
            try {
                const parentFolder = await this.props.getFolderSource(this.props.entityId, this.props.parent.id);
                if (parentFolder) {
                    this.setState({ parentFolder: parentFolder.data.view });
                }
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
            this.setState({ loadingParent: false });
        }

        this.loadApplication();
    }

    onSubmit = async (request: CreateFileOrFolder) => {
        this.setState({ loading: true });

        try {
            request.parent = this.props.parent?.id === FolderIds.FakeDocumentFolderId ? null : this.props.parent;
            const response = await this.props.createFolderSource(this.props.entityId, request);

            if (response) {
                this.props.onComplete(response.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    loadApplication = async () => {
        if (!this.context.applicationId) return;

        this.setState({ loadingApp: true });

        const response = await client.applications.getApplicationById(this.context.applicationId).catch(ex => { });
        if (response) this.setState({ application: response.data.view });

        this.setState({ loadingApp: false });
    }

    onFolderNameChange = (e) => {
        this.setState({ folderName: e.target.value });
    }

    render() {
        const parentApps = _.flatMap(this.state.parentFolder?.applications ?? []);
        const pathApps = _.flatMap(_.flatMap(this.state.parentFolder?.path ?? [], p => p.applications) ?? []);
        const appNames = [..._.map(parentApps, a => a.name), ..._.map(pathApps, a => a.name), ...this.state.application && !this.state.parentFolder?.attributes["entityId"] ? [this.state.application.name] : []];

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Name"
                />

                <AdditionalInfo>
                    <TextAreaInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                    />

                    <CustomFormLabel label="This folder and its files will be accessible in" />
                    <div style={{ marginBottom: 24 }}>{this.state.loadingApp || this.state.loadingParent ? <Loading3QuartersOutlined spin /> : _.uniq(appNames).join(", ") ?? "-"}</div>

                    <SelectorInput
                        param="applicationIds"
                        title="Share with"
                        selector={
                            <ApplicationSelector
                                disableOptionIds={[this.context.applicationId]}
                                multiple
                                filters={{
                                    deleted: false
                                } as PagedApplicationQuery}
                            />
                        }
                    />
                </AdditionalInfo>
                
            </BaseForm>
        );
    }
}

export default FolderCreateForm;
