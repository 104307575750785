import { Result } from "antd";
import { Component, ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


interface ErrorBoundaryInnerProps extends ErrorBoundaryProps {
    setHasError: React.Dispatch<React.SetStateAction<boolean>>;
    hasError: boolean;
}

interface ErrorBoundaryState {
    error: Error | null;
}

class ErrorBoundaryInner extends Component<ErrorBoundaryInnerProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryInnerProps) {
        super(props);
        this.state = {
            error: null
        };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidUpdate(prevProps: ErrorBoundaryInnerProps) {
        if (!this.props.hasError && prevProps.hasError) {
            this.setState({ error: null });
        }
    }

    componentDidCatch() {
        this.props.setHasError(true);
    }

    render() {
        //if (this.state.error) console.log(this.state.error.message);

        return this.state.error != null
            ? <Result
                status="500"
                title="An unexpected error occured."
                subTitle={`Details: ${this.state.error.message}`}
            />
            : this.props.children;
    }
}

interface ErrorBoundaryProps {
    children?: ReactNode;
}

function ErrorBoundary({ children }: ErrorBoundaryProps) {
    const [hasError, setHasError] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (hasError) {
            setHasError(false);
        }
    }, [location.key]);

    return (
        <ErrorBoundaryInner
            setHasError={setHasError}
            hasError={hasError}
        >
            {children}
        </ErrorBoundaryInner>
    );
}

export default ErrorBoundary;