import * as React from "react";
import { ProjectRoleType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";
import SelectorInformation from "../Shared/SelectorInformation";


export const ProjectRoleDescription = (roleType: ProjectRoleType) => {
    switch (roleType) {
        case ProjectRoleType.Leader:
            return "The leader will be able to do anything inside the project.";
        case ProjectRoleType.Manager:
            return "The manager will be able to do anything inside the project except adding/removing a project leader.";
        case ProjectRoleType.Participant:
            return "The participant can write comments, close and upload files to tasks.";
        case ProjectRoleType.Viewer:
            return "The viewer will be able to see everything inside the project, but won’t be able to modify anything.";
        default:
            return null;
    }
};

export class ProjectRoleTypeSelector extends React.Component<EnumSelectorProps> {
    renderOptionItem = (role: ProjectRoleType) => {
        const hoverInfoText = ProjectRoleDescription(role);
        return (
            <SelectorInformation displayText={role} hoverText={hoverInfoText} />
        );
    }

    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select project role...';

        return (
            <EnumSelector
                className="project-role-type-selector"
                enum={ProjectRoleType}
                placeholder={placeholder}
                renderOptionItem={this.renderOptionItem}
                {...this.props}
            />
        );
    }
}

export default ProjectRoleTypeSelector;