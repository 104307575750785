import _ from 'lodash';
import moment from 'moment';
import { Card } from "antd";
import ListViewCard from "../Shared/ListViewCard";
import { ActivityView, PagedActivityQuery } from "../../ApiClient/swagger/data-contracts";
import { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from '../../ApiClient/client';
import { updateCollectionWithEvent } from '../../Helpers/BasePageHelpers';
import { ActorLink } from '../Actors';
import { formatEllipsis } from '../../Helpers/Formatters';
import { useNavigate } from 'react-router-dom';
import { ProjectLink } from '../Projects';
import { TaskLink } from '../Tasks';
import { CategoryLink } from '../Categories';


interface ActivityWidgetProps {
    title?: any;
    className?: string;
    query: Partial<PagedActivityQuery>;
    exclutions?: string[];
    onCollectionChange?: (collection: ActivityView[]) => void;
    onItemCreatedEvent?: (collection: ActivityView[], Activity: ActivityView) => ActivityView[];
    onItemUpdatedEvent?: (collection: ActivityView[], Activity: ActivityView) => Promise<ActivityView[]>;
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    actions?: React.ReactNode;
}

export function ActivityWidget(props: ActivityWidgetProps) {

    const [collection, setCollection] = useState<ActivityView[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();

    //const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

    const context = useContext(AppContext);

    const loadCollection = useCallback(async () => {
        setLoading(true);

        const response = await client.activities
            .queryActivities(props.query)
            .catch(exception => setError(exception.message));

        if (response && response != undefined) setCollection(response.data.items)

        setLoading(false);
    }, [props.query]);

    const onItemCreatedEvent = useCallback((response: ActivityView) => {
        if (!response || !props.onItemCreatedEvent) return;
        setCollection(prevCollection => {
            const newCollection = props.onItemCreatedEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }, [props]);

    const onItemUpdatedEvent = useCallback( async (response: ActivityView) =>  {
        if (!response) return;

        if (props.onItemUpdatedEvent) {
            const updatedCollection = await props.onItemUpdatedEvent(collection?.slice() ?? [], response);
            setCollection(updatedCollection);
        }
        else {
            setCollection(prevCollection => {
                const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], response);
                return newCollection;
            });
        }
    }, [collection, props]);

    useEffect(() => {

        context.events.activities.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent
        });

        return () => {
            context.events.activities.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent
            });
        }
    }, [context.events.activities, onItemCreatedEvent, onItemUpdatedEvent])

    useEffect(() => {
       loadCollection();
    }, [loadCollection, props.query])

    const baseColumns = _.reject(props.customColumns ?? [
        {
            title: 'Time',
            render: (data: ActivityView) => {
                let time = data.start ? moment(data.start).format('DD.MM.YYYY HH:mm') : "";

                if (data.end)
                    time = time + " - " + moment(data.end).format('HH:mm');

                return time;

            },
            key: 'start',
            width: 190
        },
        {
            title: 'Customer',
            render: (data: ActivityView) => data.relation ? <ActorLink {...data.relation} /> : "",
            key: 'relation',
            ellipsis: true
        },
        {
            title: 'Project & task',
            render: (data: ActivityView) => {
                const project = data.project ? <ProjectLink {...data.project} /> : null;
                const task = data.task ? <> / <TaskLink {...data.task} /> </> : null;

                return <div className="project-task-column list-ellipsis-disabled">{project}{task}</div>;
            },
            key: 'project',
            ellipsis: true
        },
        {
            title: 'Description',
            key: 'description',
            render: (data: ActivityView) => formatEllipsis(data.description, 500),
            ellipsis: true
        },
        {
            title: 'Type of work',
            dataIndex: 'type',
            key: 'type',
            width: 100
        },
        {
            title: 'Category',
            render: (data: ActivityView) => data.category ? <CategoryLink {...data.category} /> : "",
            key: 'category',
            ellipsis: true
        },
        {
            title: 'Employee',
            render: (data: ActivityView) => data.actor ? <ActorLink {...data.actor} /> : "",
            ellipsis: true,
            key: "employee"
        }
    ], c => _.find(props.exclutions, e => { return c.key == e }) != null);

    if (error) return <Card title={props.title}>{error}</Card>;

    return (
        <ListViewCard
            title={props.title}
            columns={baseColumns}
            data={collection}
            loading={loading}
            className="activity-widget"
            {...props}
        />
    );
}