import * as React from "react";
import _ from 'lodash';
import { Tabs, Typography, Button } from "antd";
import { InvoiceSortOption, InvoiceView, OrderSortOption, OrderView, PagedInvoiceQuery, PagedOrderQuery, ProjectType, ProjectView, SortDirection } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { RequireCapability } from "../Shared/RequireCapability";
import { Capabilities } from "../../Definitions/_capabilties";
import { Drawer } from "../Shared/Drawer";
import { OrderCreateForm, OrderWidget } from "../Orders";
import { InvoiceWidget } from "../Invoices";


const { TabPane } = Tabs;
const { Title } = Typography;

interface ProjectFilesInvoicesOrdersTabProps {
    project: ProjectView;
    orderMold?: Partial<OrderView>;
}

interface ProjectFilesInvoicesOrdersTabState {
    activeTab: "orders" | "invoices";
    showCreateOrderDrawer: boolean;
}

export class ProjectFilesInvoicesOrdersTab extends React.Component<ProjectFilesInvoicesOrdersTabProps, ProjectFilesInvoicesOrdersTabState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "orders",
            showCreateOrderDrawer: false
        };
    }

    onTabChange = (activeTab) => {
        this.setState({ activeTab });
    }

    onInvoiceCreatedEvent = (collection: InvoiceView[], invoice: InvoiceView) => {
        if (this.props.project && invoice.project && invoice.project.id == this.props.project.id) {
            collection.push(invoice);
            collection = _.sortBy(collection, "state.lastModified").reverse();
        }

        return collection;
    }

    onOrderCreatedEvent = (collection: OrderView[], order: OrderView) => {
        //console.log(order);

        if (this.props.project && order.project && order.project.id == this.props.project.id) {
            collection.push(order);
            collection = _.sortBy(collection, "state.lastModified").reverse();
        }

        return collection;
    }

    onOrderCreated = (created: OrderView) => {
        this.setState({ showCreateOrderDrawer: false });
    }

    render = () => {
        const { project } = this.props;

        const invoiceQuery: Partial<PagedInvoiceQuery> = {
            projectId: project ? project.id : null,
            sortBy: InvoiceSortOption.LastModified,
            sortDirection: SortDirection.Desc,
            limit: 10
        };
        const orderQuery: Partial<PagedOrderQuery> = {
            projectId: project ? project.id : null,
            sortBy: OrderSortOption.LastModified,
            sortDirection: SortDirection.Desc,
            limit: 10
        };

        let operationsSlot = null;

        if (project && project.type === ProjectType.Fixed && this.state.activeTab === "orders") {
            operationsSlot = {
                right: <div className="operations"><RequireCapability key="add-order-capability" capability={Capabilities.OrdersWrite}>
                    <Button size="small" className={"create-projectorder-action"} onClick={() => { this.setState({ showCreateOrderDrawer: true }); }}>
                        Create order
                    </Button>
                </RequireCapability>
                </div>
            };
        }

        return (
            <div className="project-invoices-orders-tab">
                <Drawer
                    title="Create direct order"
                    onClose={() => { this.setState({ showCreateOrderDrawer: false }) }}
                    open={this.state.showCreateOrderDrawer}
                    destroyOnClose
                    width={"40%"}
                    component={
                        <OrderCreateForm
                            orderMold={this.props.orderMold as OrderView}
                            onComplete={(created) => { created ? this.onOrderCreated(created) : this.setState({ showCreateOrderDrawer: false }) }}
                            onCancel={() => this.setState({ showCreateOrderDrawer: false })}
                        />
                    }
                />
                <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange} tabBarExtraContent={operationsSlot}>
                    <TabPane key="orders" tab={<Title level={4} className="title">Orders</Title>}>
                        {this.context.user.hasCapability(Capabilities.OrdersRead) ?
                            <OrderWidget query={orderQuery} onItemCreatedEvent={this.onOrderCreatedEvent} exclutions={["reference", "label", "project", "roles", "dates"]} />
                            : null}
                    </TabPane>

                    <TabPane key="invoices" tab={<Title level={4} className="title">Invoices</Title>}>
                        {this.context.user.hasCapability(Capabilities.InvoicesRead) ?
                            <InvoiceWidget query={invoiceQuery} onItemCreatedEvent={this.onInvoiceCreatedEvent} />
                            : null}
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}