import * as React from "react";
import { Form, Input, Tooltip } from "antd";

interface TextInputProps {
    placeholder?: string;
    icon?: JSX.Element;
    required?: boolean;
    warningMessage?: string;
    param: string;
    title?: string;
    readOnly?: boolean;
    className?: string;
    formItemClassName?: string;
    defaultValue?: string;
    hideClear?: boolean;
    noStyle?: boolean;
    disabled?: boolean;
    validator?: (rule: any, value: any) => any;
    tooltip?: string;
    type?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    titleLink?: React.ReactNode;
    onObjectChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export class TextInput extends React.Component<TextInputProps> {
    render = () => {
        const { param, required, warningMessage, icon, placeholder, readOnly, className, noStyle, validator, tooltip, formItemClassName } = this.props;
        const optional = !this.props.required ? " (optional)" : "";

        const title = this.props.title ? <div className="selector-label-container"><span className="text-title">{this.props.title} {optional}</span>{this.props.titleLink ? this.props.titleLink : null}</div> : null;

        const rules = {
            required: required,
            whitespace: true,
            message: validator ? null : warningMessage ? warningMessage : 'This field is required',
        };

        if (validator)
            rules["validator"] = validator;


        const input = <Input
            prefix={icon ? icon : null}
            placeholder={placeholder}
            size="middle"
            allowClear={this.props.hideClear ? false : true}
            readOnly={readOnly}
            className={className ? className : ''}
            disabled={this.props.disabled}
            type={this.props.type ? this.props.type : null}
            value={this.props.defaultValue}
            onChange={(e) => { if (this.props.onChange) this.props.onChange(e); if (this.props.onObjectChange) this.props.onObjectChange(e); }}
        />;

        let formItem = <Form.Item
            noStyle={noStyle}
            label={title}
            name={param}
            rules={[rules]}
            className={formItemClassName ? formItemClassName : ""}
        >
            {input}
        </Form.Item>;

        if (tooltip) {
            formItem = <Tooltip
                trigger={['focus']}
                title={tooltip}
                placement="topRight"
            >
                {formItem}
            </Tooltip>
        }

        return formItem;
    }
}