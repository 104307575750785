import * as React from "react";
import _ from 'lodash';
import { Alert } from "antd";
import { AddOrUpdateProjectRole, PersonSortOption, ProjectRoleType, ProjectRoleView, ProjectView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { PersonSelector, PersonSelectorInput } from "../People";
import ProjectRoleTypeSelector from "./ProjectRoleTypeSelector";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";


interface ProjectRoleCreateOrEditFormProps {
    onComplete: (updated: ProjectView) => void;
    onCancel?: () => void;
    project: ProjectView;
    projectRole?: ProjectRoleView;
}

interface ProjectRoleCreateOrEditFormState {
    loading: boolean;
    error: string;
    actorIds: string[];
    addLeaderWarning: boolean;
}

export class ProjectRoleCreateOrEditForm extends React.Component<ProjectRoleCreateOrEditFormProps, ProjectRoleCreateOrEditFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            actorIds: this.props.projectRole?.actor ? [this.props.projectRole.actor.id] : [],
            addLeaderWarning: false
        }
    }
    onCancel = () =>
    {
        this.props.onComplete(this.props.project);
    }

    onSubmit = async (request: AddOrUpdateProjectRole) => {
        this.setState({ loading: true });

        try {
            const userRole = _.find(this.props.project?.roles ?? [], r => r.actor.id == this.context.user.actorId);

            const canUpdateProjectLeader = this.context.user.hasAnyCapability([Capabilities.ProjectsWrite/*, Capabilities.ProjectsFullAccess*/]) || (this.context.user.hasCapability(Capabilities.ProjectsRoleBased) && userRole?.role == ProjectRoleType.Leader);
            if (request.type == ProjectRoleType.Leader && !canUpdateProjectLeader) {
                this.setState({ addLeaderWarning: true });
            }

            const response = await client.projects.addOrUpdateProjectRole(this.props.project.id, request, addChangeVectorHeader(this.props.project.changeVector));
            if (response)
                this.props.onComplete(response.data);
        }
        catch (error: any) {
            this.setState({ error: error?.title ?? error?.message ?? error?.error });
        }

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.project != null && this.props.projectRole != null) {
            try {
                const response = await client.projects.removeProjectRole(this.props.project.id, this.props.projectRole.actor.id, addChangeVectorHeader(this.props.project.changeVector));
                this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onActorChange = (actorIds: string[]) => {
        this.setState({ actorIds });
    }

    render() {

        const initialValues = { actorIds: this.state.actorIds };
        const editValues = this.props.projectRole != null ? {
            type: this.props.projectRole.role,
            description: this.props.projectRole.description
        } : {};

        const { user } = this.context;
        const userRole = _.find(this.props.project?.roles ?? [], r => r.actor.id == user.actorId);

        const roleBased = userRole != null && user.hasCapability(Capabilities.ProjectsRoleBased) ? userRole.role : null;
        const editLeader = (roleBased != null && roleBased == ProjectRoleType.Leader) || user.hasCapability(Capabilities.ProjectsWrite);

        return (
            <BaseForm
                type={this.props.projectRole != null ? FormType.Edit : FormType.Create}
                onSubmit={this.onSubmit}
                onDelete={this.props.projectRole ? this.onDelete : null}
                onCancel={this.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={this.props.projectRole != null ? Object.assign({}, editValues, initialValues) : initialValues}
                className="project-role-form"
            >
                {this.props.projectRole != null ?
                    <SelectorInput
                        param="actorIds"
                        required
                        warningMessage="Please choose a person"
                        title="Person"
                        selector={
                            <PersonSelector
                                filters={{ sortBy: PersonSortOption.Name }}
                                placeholder="Select person"
                                disabled
                            />}
                    />
                    :
                    <PersonSelectorInput
                        title="Person"
                        param="actorIds"
                        warningMessage="Please choose a person"
                        required
                        onChange={this.onActorChange}
                        placeholder="Select person"
                        multiple
                    />
                }

                <SelectorInput
                    param="type"
                    required
                    warningMessage="Please choose a role type"
                    title="Project role"
                    selector={
                        <ProjectRoleTypeSelector
                            placeholder="Select role type"
                            excludeAllOption
                            disabled={this.props.projectRole != null && this.props.projectRole.role == ProjectRoleType.Leader && !editLeader}
                            excluded={editLeader ? [] : [ProjectRoleType.Leader]}
                        />
                    }
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                {this.state.addLeaderWarning
                    ? <Alert className="leader-warning" message="You must be project leader to add or update the leader of a project." type="info" showIcon />
                    : null}
            </BaseForm>
        );
    }
}

export default ProjectRoleCreateOrEditForm;