import * as React from "react";
import _ from 'lodash';
import { Tabs, Typography, Button } from "antd";
import { CallDirection, CallTargetView, CallView, ContactType, ProjectSortOption, SortDirection, TicketSortOption } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { ProjectWidget } from "../Projects/ProjectWidget";
import { TicketCreateForm, TicketWidget } from "../Tickets";
import { Drawer } from "../Shared/Drawer";
import RelatedCallsListCard from "./RelatedCallsListCard";

const { TabPane } = Tabs;
const { Title } = Typography;

interface CallRelatedTabCardProps {
    className?: string;
    call: CallView;
    actor: CallTargetView;
}

interface CallRelatedTabCardState {
    activeTab: "calls" | "projects" | "tickets";
    showCreateTicketDrawer: boolean;
}

export default class CallRelatedTabCard extends React.Component<CallRelatedTabCardProps, CallRelatedTabCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "calls",
            showCreateTicketDrawer: false
        };
    }

    onTabChange = (activeTab) => {
        this.setState({ activeTab });
    }

    toggleCreateTicketDrawer = (result) => {
        this.setState({ showCreateTicketDrawer: !this.state.showCreateTicketDrawer });
    }

    render() {
        let customerId = null

        if (this.props.actor) {
            const { actor } = this.props;

            if (actor?.contact?.contactType === ContactType.Person) {
                const roles = actor.organizationRoles;

                const customerRole = _.find(roles || [], r => {
                    const userCompanyMatch = _.find(Object.keys(this.context.user.companies) || [], c => { return c == r.organization.id }) != null;
                    return !userCompanyMatch;
                });

                customerId = customerRole?.organization.id;
            }
            else if (actor?.contact?.contactType === ContactType.Organization)
                customerId = actor?.contact?.id;
        }
        else if (this.props.call.direction === CallDirection.Internal) {
            const fromOrgRoles = this.props.call?.from?.organizationRoles ?? [];
            const toOrgRoles = this.props.call?.to?.organizationRoles ?? [];
            const allOrgs = [...fromOrgRoles, ...toOrgRoles];
            const firstMatch = _.find(allOrgs, org => {
                const userCompanyMatch = _.find(Object.keys(this.context.user.companies) || [], c => c == org.organization.id) != null;
                return userCompanyMatch;
            });

            customerId = firstMatch?.organization.id;
        }

        const unavailable = this.props.actor?.contact == null && customerId == null;

        const operationsSlot = {
            right: <Button onClick={this.toggleCreateTicketDrawer} size="small">Create ticket</Button>
        };

        return (
            <>
                <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange} className="call-related-tab-card" tabBarExtraContent={operationsSlot}>
                    <TabPane key="calls" tab={<Title level={4} className="title">Related calls</Title>}>
                        <RelatedCallsListCard call={this.props.call} />
                    </TabPane>
                    <TabPane key="projects" tab={< Title level={4} className="title"> Projects</Title>} disabled={unavailable}>
                        <ProjectWidget
                            title={null}
                            query={{
                                actorId: this.props.actor?.contact == null ? null : this.props.actor?.contact.id,
                                customerId: this.props.actor?.contact == null ? customerId : this.props.actor.contact.id,
                                deleted: false,
                                sortBy: ProjectSortOption.Start,
                                sortDirection: SortDirection.Desc,
                                from: 0,
                                limit: 100
                            }}
                            exclutions={["description", "roles", "projectLeader", "type", "start", "invoiced", "invoices"]}
                            loadAllOnLoadMore
                        />
                    </TabPane>
                    <TabPane key="tickets" tab={<Title level={4} className="title">Tickets</Title>} disabled={unavailable}>
                        <TicketWidget
                            title={null}
                            query={{
                                customerId: customerId,
                                deleted: false,
                                sortBy: TicketSortOption.TicketNumber,
                                sortDirection: SortDirection.Desc,
                                from: 0,
                                limit: 100
                            }}
                            loadAllOnLoadMore
                        />
                    </TabPane>
                </Tabs>

                <Drawer
                    title="Create ticket"
                    onClose={this.toggleCreateTicketDrawer}
                    open={this.state.showCreateTicketDrawer}
                    component={
                        <TicketCreateForm
                            onComplete={this.toggleCreateTicketDrawer}
                            filters={{
                                customerId: customerId,
                                requesterId: this.props.actor?.contact?.contactType === ContactType.Person && this.props.actor?.contact == null ? this.props.actor.contact?.id : null
                            }}
                        />
                    }
                />
            </>
        );
    }
}