import * as React from "react";
import { Progress, Popover } from 'antd';
import moment from "moment";
import momentDurationFormatSetup from 'moment-duration-format';
import { formatDuration } from "../../Helpers/Formatters";

export interface ProgressProps {
    duration: number;
    estimate: number;
    complete?: boolean;
    paused?: boolean;
    type?: "circle" | "line" | "dashboard";
    hideDurationText?: boolean;
}

//@ts-ignore
momentDurationFormatSetup(moment);

export class ProgressBar extends React.Component<ProgressProps> {

    private percentage: number;
    private progress: number;

    constructor(props) {
        super(props)
        this.state = {};
    }

    render = () => {
        const { duration, estimate, complete, paused } = this.props;

        const tooltip = (
            <dl>
                <dt>Estimate</dt>
                <dd>{moment.duration(estimate, 'milliseconds').format('h [hours] m [minutes]')}</dd>
                <dt>Duration</dt>
                <dd>{moment.duration(duration, 'milliseconds').format('h [hours] m [minutes]')}</dd>
            </dl>
        );

        const hasEstimate = this.props.estimate != null && this.props.estimate > 0;

        if (this.props.complete == true && !hasEstimate) {
            return "Ended";
        }
        else if (this.props.paused == true && !hasEstimate) {
            return "Paused";
        }

        const padding = this.props.complete || this.props.paused ? 40 : 8;
        const trailColor = '#e5e5e5';


        if (estimate <= 0) {
            return (
                <Popover content={tooltip}>
                    <div style={{ display: 'flex', paddingRight: padding }}>
                        {this.props.hideDurationText ? null : <span style={{ whiteSpace: 'nowrap', marginRight: 20 }}>{formatDuration(duration, true)}</span>}
                        <Progress
                            className="progress-bar"
                            type={this.props.type}
                            percent={this.props.duration > 0 ? 30 : 0}
                            status={!complete ? "normal" : "success"}
                            format={() => complete ? paused ? "Paused" : 'Ended' : `?`}
                            trailColor={trailColor}
                        />
                    </div>
                </Popover>
            );
        }

        this.percentage = this.progress = estimate > 0 ? Math.ceil((duration / estimate) * 100) : 0;
        const isOverEstimate = this.percentage > 100;

        let props = {};

        if (isOverEstimate) {
            //this.percentage = this.percentage % 100;
            //var percentOver = this.percentage % 100;
            //var percent = this.percentage >= 200 ? 100 : 100 - percentOver;

            const percent = (estimate / (duration ?? 1)) * 100;
            //var red = 100 - orange;

            props = {
                success: {
                    percent: percent,
                    strokeColor:/* percent >= 100 ? "#ff4d4f" : */"rgb(255, 165, 0)"
                }
            };
        }

        const percentageDisplay = this.progress + "%";

        return (
            <Popover content={tooltip}>
                <div style={{ display: 'flex', paddingRight: padding }}>
                    {this.props.hideDurationText ? null : <span style={{ whiteSpace: 'nowrap', marginRight: 20 }}>{formatDuration(duration, true)}</span>}
                    <Progress
                        className="progress-bar"
                        type={this.props.type}
                        percent={this.progress}
                        status={!complete && !isOverEstimate ? "normal" : isOverEstimate ? "exception" : "success"}
                        format={() => `${complete ? `${percentageDisplay} Ended` : paused ? `${percentageDisplay} Paused` : percentageDisplay}`}
                        trailColor={trailColor}
                        {...props}
                    />
                </div>
            </Popover>
        );
    }
}

export default ProgressBar;