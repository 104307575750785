import client from "../../ApiClient/client";
import { PagedActorQuery } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";


interface CustomerFilterProps extends GenericEntitySelectorProps<PagedActorQuery> {
    types?: string[];
}

export function CustomerSelector(props: CustomerFilterProps) {
    const placeholder = props.placeholder ? props.placeholder : 'Select customer...';
    const source = props.source ? props.source : client.actors.queryActors;
    const defaultValueSource = props.defaultValueSource ? props.defaultValueSource : client.actors.getActorById;
    const optionDisplayNameParam = props.optionDisplayNameParam ? props.optionDisplayNameParam : 'name';

    const filters = Object.assign({}, props.filters, { isCustomer: true })

    return (
        <EntitySelector
            placeholder={placeholder}
            source={source}
            defaultValueSource={defaultValueSource}
            optionDisplayNameParam={optionDisplayNameParam}
            filters={filters}
            {...props}
        />
    );
}

export default CustomerSelector;