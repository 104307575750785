import * as React from "react";
import _ from 'lodash';
import { Button } from 'antd';

interface QuickSearchProps {
    name: string;
    queryParameters: any;
    currentQuery: any;
    onQueryChange: (queryChanges: any) => any;
    disabled?: boolean;
}

export class QuickSearch extends React.Component<QuickSearchProps>{
    constructor(props: any) {
        super(props);
    }

    toggleOn = () => {
        const parameterValues = {};
        const parameterKeys = Object.keys(this.props.queryParameters);
        _.each(parameterKeys, key => {
            parameterValues[key] = this.props.queryParameters[key];
        });
        const newQuery = Object.assign({}, this.props.currentQuery ?? {}, parameterValues);

        this.props.onQueryChange(newQuery);
    }

    toggleOff = () => {
        const parameterValues = {};
        const parameterKeys = Object.keys(this.props.queryParameters);
        _.each(parameterKeys, key => {
            if (this.props.queryParameters.hasOwnProperty(key)) {
                parameterValues[key] = null;
            }
        });
        const newQuery = Object.assign({}, this.props.currentQuery ?? {}, parameterValues);

        this.props.onQueryChange(newQuery);
    }

    isActive = () => {
        let active = true;
        const keys = Object.keys(this.props.queryParameters);

        _.each(keys, key => {
            const value = (this.props.currentQuery ?? {})[key];
            const matchValue = this.props.queryParameters[key];

            if (Array.isArray(matchValue)) {
                const arrayValue = value || [];
                const diff1 = _.difference(arrayValue, matchValue);
                const diff2 = _.difference(matchValue, arrayValue);
                if (diff1.length !== 0 || diff2.length !== 0) {
                    active = false;
                }
            }
            else if (matchValue != value) {
                active = false;
            }
        });
        return active;
    }

    render = () => {

        const active = this.isActive();

        return (
            <Button disabled={this.props.disabled ?? false} type="link" onClick={active ? this.toggleOff : this.toggleOn} className={active ? "active" : ""}>{this.props.name}</Button>
        );
    }
}