import { Button, Divider, Form, Input, Space } from "antd";
import { ContactPointLinkView, ContactPointType } from "../../ApiClient/swagger/data-contracts";
import ContactPointSelector from "./ContactPointSelector";
import { PlusOutlined } from "@ant-design/icons";
import { useState } from "react";
import client from "../../ApiClient/client";

interface EmailContactPointSelectorProps {
    personId: string;
    onCreatedContactPoint: (contactPoint: ContactPointLinkView) => void;
    onObjectChange?: (contactPoint: ContactPointLinkView) => void;
}

const EmailContactPointSelector = (props: EmailContactPointSelectorProps) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const addEmail = async (request) => {
        setLoading(true);

        const response = await client.contactPoints.createContactPoint({
            type: ContactPointType.Email,
            value: request.email,
            primary: false,
            contactId: props.personId,
        }).catch((error) => form.setFields([{ name: 'email', errors: [error.error] }]));
        if (response) {
            form.resetFields();
            props.onCreatedContactPoint(response.data);
        }

        setLoading(false);
    }

    return (
        <ContactPointSelector
            {...props}
            filters={{
                contactId: props.personId,
                types: [ContactPointType.Email]
            }}
            renderOptionItem={(item) => {
                return `${item.value} ${item.primary ? '(primary)' : ''}`;
            }}
            renderLabelItem={(item) => {
                return `${item.value} ${item.primary ? '(primary)' : ''}`;
            }}
            
            dropdownRender={(menu) => (
                <div>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Form form={form} layout="inline" onFinish={addEmail}>
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'The input is not valid email',
                                    },
                                    {
                                        required: true,
                                        message: 'Please input a email',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Please enter a email"
                                    onKeyDown={(e) => e.stopPropagation()}
                                    autoComplete="off"
                                />
                            </Form.Item>
                            <Button loading={loading} type="text" icon={<PlusOutlined />} htmlType="submit">
                                Add email
                            </Button>
                        </Space>
                    </Form>
                </div>
            )}
        />
    )
}

export default EmailContactPointSelector;