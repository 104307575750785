import _ from "lodash";
import moment from "moment";
import client from "../client";
import { PagedProjectQuery, ProjectRoleType, ProjectType, ProjectView, } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class ProjectExport extends BaseExportService<PagedProjectQuery, ProjectView> {
    constructor() {

        const specification: ExportConfig<ProjectView>[] = [
            { header: 'Project name', value: (data: ProjectView) => data.name },
            {
                header: 'Project leader', value: (data: ProjectView) => {
                    const projectLeader = _.find(data.roles || [], r => {
                        return r.role == ProjectRoleType.Leader;
                    });
                    return projectLeader ? projectLeader.actor.name : "";
                }
            },
            {
                header: 'Other roles', value: (data: ProjectView) => {
                    let roles = "";

                    _.each(data.roles || [], (role) => {
                        if (role.role != ProjectRoleType.Leader) {
                            if (roles.length > 0) roles = roles + ", ";

                            roles = roles + role.role + ": " + role.actor.name;
                        }
                    })

                    return roles;
                }
            },
            { header: 'Customer', value: (data: ProjectView) => data.customer ? data.customer.name : "" },
            { header: 'Type', value: (data: ProjectView) => data.type },
            { header: 'Actual start', value: (data: ProjectView) => data.firstActivity ? moment(data.firstActivity).format() : null },
            { header: 'Actual end', value: (data: ProjectView) => data.ended ? moment(data.ended).format() : null },
            { header: 'Planned start', value: (data: ProjectView) => data.plannedStart ? moment(data.plannedStart).format() : null },
            { header: 'Planned end', value: (data: ProjectView) => data.plannedEnd ? moment(data.plannedEnd).format() : null },
            { header: 'Duration', value: (data: ProjectView) => data.duration, format: ExportFormat.Duration },
            {
                header: 'Progress',
                value: (data: ProjectView) => {
                    //var hasEstimate = data.estimate != null && data.estimate > 0;
                    //var completed = data.state.ended != null;
                    //var paused = !data.state.activated && !data.state.ended && data.actualStart != null;

                    //if (completed && !hasEstimate) {
                    //    return "Ended";
                    //}
                    //else if (paused && !hasEstimate) {
                    //    return "Paused";
                    //}
                    //else
                    return data.estimate != null && data.estimate > 0 ?
                        data.duration != null && data.duration > 0 ?
                            Math.round(data.duration / data.estimate * 100) + '%'
                            : 0 + '%'
                        : null
                },
                format: ExportFormat.Percentage
            },
            { header: 'Estimate', value: (data: ProjectView) => data.estimate != null && data.estimate > 0 ? data.estimate : null, format: ExportFormat.Duration },
            { header: 'Invoiced', value: (data: ProjectView) => data.type == ProjectType.Hourly || data.type == ProjectType.Fixed ? data.netInvoiced : null, format: ExportFormat.Currency },
            { header: 'Price', value: (data: ProjectView) => data.type == ProjectType.Hourly ? data.runningSalesPrice : data.type == ProjectType.Fixed ? data.price : null, format: ExportFormat.Currency },
            {
                header: 'InvoiceProgress',
                value: (data: ProjectView) => {

                    if (data.type == ProjectType.Internal)
                        return null;

                    let value = null;

                    if (data.type == ProjectType.Hourly)
                        value = data.netInvoiced != null && data.netInvoiced > 0 && data.runningSalesPrice != null && data.runningSalesPrice > 0 ?
                            Math.round(data.netInvoiced / data.runningSalesPrice * 100) + "%" : 0 + "%";

                    if (data.type == ProjectType.Fixed)
                        value = data.netInvoiced != null && data.netInvoiced > 0 && data.price != null && data.price > 0 ?
                            Math.round(data.netInvoiced / data.price * 100) + "%" : 0 + "%";

                    return value;
                },
                format: ExportFormat.Percentage
            },
            {
                header: 'Attributes',
                value: (data: ProjectView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })
                    }

                    return dataString
                }
            },

            { header: 'Created', value: (data: ProjectView) => data.created ? moment(data.created).format('DD.MM.YYYY') : null },
            { header: 'Updated', value: (data: ProjectView) => data.lastModified ? moment(data.lastModified).format('DD.MM.YYYY') : null },
            { header: 'Deleted', value: (data: ProjectView) => data.deleted ? true : false }
        ];

        super(specification, client.projects.queryProjects);
    }
}