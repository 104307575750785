import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Card, Button } from "antd";
import Title from "antd/lib/typography/Title";
import { Address, OrderDateType, OrderType, OrderView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { TagLink } from "../Tags";
import { RequireCapability } from "../Shared/RequireCapability";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorLink } from "../Actors";
import { ProjectLink } from "../Projects";
import { Drawer } from "../Shared/Drawer";
import OrderEditForm from "./OrderEditForm";

interface OrderInfoCardProps {
    order: OrderView;
    className?: string;
    onEditComplete: (order: OrderView) => void;
}

interface OrderInfoCardState {
    showEditDrawer: boolean
}

export default class OrderInfoCard extends React.Component<OrderInfoCardProps, OrderInfoCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showEditDrawer: false
        }
    }

    toggleEditDrawer = () => {
        this.setState({
            showEditDrawer: !this.state.showEditDrawer
        });
    }

    render() {
        if (!this.props.order) return null;

        const { type, category, reference, label, project, tags, dates, deliveryAddress, invoiceAddress, customer, invoiceReceiver, externalContact, internalContact } = this.props.order;

        const tagView = _.map(tags, tag => {
            return <TagLink key={tag.id} {...tag} />;
        });

        const quotationDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.Quotation.toLowerCase(); });
        const quotationExpiryDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.QuotationExpiry.toLowerCase(); });
        const quotationLostDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.QuotationLost.toLowerCase(); });

        const salesDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.Sales.toLowerCase(); });
        const salesCorrectionDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.SalesCorrection.toLowerCase(); });
        const salesProductionDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.SalesProduction.toLowerCase(); });
        const salesCompletedDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.SalesCompleted.toLowerCase(); });

        const requestedDeliveryDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.RequestedDelivery.toLowerCase(); });
        const deliveredDate = _.find(dates, (d, key) => { return key.toLowerCase() == OrderDateType.Delivered.toLowerCase(); });

        const DateView = (props) => <div><dt>{props.name}</dt><dd>{props.date != null ? moment(props.date).format("DD.MM.YYYY") : "-"}</dd></div>;

        const AddressView = (props: Address) => <span>{props.line1}{props.line2 != null ? <span><br />{props.line2}</span> : "-"}<br />{props.zipCode} {props.area}</span>

        let dateViews;

        if (type == OrderType.Quotation) {
            dateViews = [
                <DateView key="quotationDate" name="Quotation date" date={quotationDate} />,
                <DateView key="quotationExpiryDate" name="Expiry date" date={quotationExpiryDate} />,
                <DateView key="requestedDeliveryDate" name="Delivery date" date={requestedDeliveryDate} />,
                <DateView key="quotationLostDate" name="Lost date" date={quotationLostDate} />,
            ];
        } else if (type == OrderType.Normal) {
            dateViews = [
                <DateView key="salesDate" name="Sales date" date={salesDate} />,
                <DateView key="salesCorrectionDate" name="Correction date" date={salesCorrectionDate} />,
                <DateView key="salesProductionDate" name="Production date" date={salesProductionDate} />,
                <DateView key="requestedDeliveryDate" name="Delivery date" date={requestedDeliveryDate} />,
                <DateView key="salesCompletedDate" name="Completed date" date={salesCompletedDate} />,
                <DateView key="deliveredDate" name="Delivered date" date={deliveredDate} />,
            ];
        }
        else if (type == OrderType.Direct) {
            dateViews = [
                <DateView key="salesDate" name="Sales date" date={salesDate} />
            ];
        }

        return (
            <React.Fragment>
                <Card
                    key="order-info-card"
                    title={
                        <React.Fragment>
                            <Title level={4} className="title">Info</Title>
                            <RequireCapability capability={Capabilities.OrdersWrite}>
                                <Button className="action" onClick={this.toggleEditDrawer}>Edit</Button>
                            </RequireCapability>
                        </React.Fragment>
                    }
                    className={`order-info-card info-card-container ${this.props.className ? this.props.className : ""}`}
                >
                    <dl className="">
                        <div>
                            <dt>Order type</dt>
                            <dd>{type}</dd>
                        </div>
                        <div>
                            <dt>Reference</dt>
                            <dd>{reference ?? "-"}</dd>
                        </div>
                        <div>
                            <dt>Customer</dt>
                            <dd><ActorLink {...customer} /></dd>
                        </div>
                        <div>
                            <dt>Invoice receiver</dt>
                            <dd>{invoiceReceiver ? <ActorLink {...invoiceReceiver} /> : "-"}</dd>
                        </div>
                        <div>
                            <dt>Internal contact</dt>
                            <dd>{internalContact ? <ActorLink {...internalContact} /> : "-"}</dd>
                        </div>
                        <div>
                            <dt>External contact</dt>
                            <dd><ActorLink {...externalContact} /></dd>
                        </div>
                        <div>
                            <dt>Label</dt>
                            <dd>{label ?? "-"}</dd>
                        </div>
                        {project ? <div>
                            <dt>Project</dt>
                            <dd><ProjectLink {...project} /></dd>
                        </div> : null}
                        <div>
                            <dt>Category</dt>
                            <dd>{category != null ? category.name : "-"}</dd>
                        </div>
                        <div>
                            <dt>Tags</dt>
                            <dd>{tagView?.length > 0 ? tagView : "-"}</dd>
                        </div>
                        <div>
                            <dt>Delivery address</dt>
                            <dd><AddressView {...deliveryAddress} /></dd>
                        </div>
                        <div>
                            <dt>Invoice address</dt>
                            <dd><AddressView {...invoiceAddress} /></dd>
                        </div>
                        {dateViews}
                    </dl>

                    
                </Card>

                <Drawer
                    title="Edit order"
                    onClose={this.toggleEditDrawer}
                    open={this.state.showEditDrawer}
                    destroyOnClose
                    component={
                        <OrderEditForm
                            order={this.props.order}
                            onComplete={(updated) => {
                                this.props.onEditComplete(updated);
                                this.toggleEditDrawer();
                            }}
                            onCancel={this.toggleEditDrawer}
                        />
                    }
                />

            </React.Fragment>
        );
    }
}