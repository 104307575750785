import { PlotOptions } from 'highcharts';
import _ from 'lodash';
import { NormalHistogramProps, StackedHistogramProps } from '../Definitions/_graphinterfaces';
import { ValueType } from '../Models/ValueType';
import { formatCurrency, formatDuration, capitalizeFirstLetter } from './Formatters';

const getHistogramOptions = (optionProps: NormalHistogramProps | StackedHistogramProps, seriesData: any[]) => {

    const xFieldUpperCase = capitalizeFirstLetter(optionProps.xField);

    const max = optionProps.enableScroll ? seriesData?.length > 0 ? seriesData[0].data?.length - 1 : null : null;
    const min = optionProps.enableScroll ? max != null && max > 30 ? max - 30 : null : null;

    const options: Highcharts.Options = {
        credits: {
            enabled: false
        },
        chart: {
            type: optionProps.type,
            backgroundColor: null,
            height: optionProps.height ?? null,
            width: null,
            marginRight: optionProps.chartMarginRight ?? null
        },
        exporting: {
            sourceWidth: optionProps.exportSourceWidth ?? null,
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "separator",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "separator",
                        "downloadCSV",
                        "downloadXLS"
                    ]
                }
            }
        },
        title: {
            text: ""
        },
        lang: {
            drillUpText: " Back "
        },
        scrollbar: {
            enabled: optionProps.enableScroll
        },
        xAxis: {
            type: 'category',
            categories: optionProps?.categories,
            uniqueNames: false,
            min: min,
            max: max,
            labels: {
                step: optionProps.labelsStep != null ? optionProps.labelsStep : 1,
                rotation: optionProps.labelRotation ?? 0,
                useHTML: optionProps.route ? true : false,
                formatter: function () {
                    if (optionProps.xAxisFormatter)
                        return optionProps.xAxisFormatter(this.value);
                    else {
                        if (optionProps.route) {
                            //@ts-ignore
                            const points = this.chart.userOptions.series[0]?.data || [];

                            const matches = _.filter(points, point => { return point.name == this.value.toString(); });
                            let nameMatch = null;

                            for (let i = 0; i <= matches.length; i++) {
                                const match = matches[i];
                                const pos = _.indexOf(points, match);
                                const posMatch = pos == this.pos;

                                if (posMatch) {
                                    nameMatch = match;
                                    break;
                                }
                            }

                            const position = nameMatch ? _.indexOf(points, nameMatch) : -1;
                            const positionMatch = position == this.pos;

                            if (nameMatch && positionMatch && nameMatch.custom?.id) {
                                return `<a className="no-break list-ellipsis" href="/${optionProps.route}/${nameMatch.custom.id}">${this.value.toString()}</a>`;

                                //<Link to={ `/${optionProps.route}/${nameMatch.custom.id}` }> this.value.toString() < /Link>
                                //`<Link to={/${optionProps.route}/${nameMatch.custom.id}}>${this.value.toString()}</Link>`
                                //`<a className="no-break list-ellipsis" href="/${optionProps.route}/${nameMatch.custom.id}">${this.value.toString()}</a>`;
                            }
                            else
                                return this.value.toString();
                        }
                        else
                            return this.value.toString();
                    }
                }
            },
            //min: 0
        },
        yAxis: {
            min: optionProps.yAxisMinUnlimited ? null : 0,
            allowDecimals: false,
            title: {
                text: null
            },
            stackLabels: {
                enabled: true,
                formatter: function () {
                    if (optionProps.stackedLabelSteps) {
                        if (this.x % optionProps.stackedLabelSteps === 0) {
                            if (optionProps.valueBy == ValueType.Duration) {
                                return formatDuration(this.total);
                            }
                            else if (optionProps.valueBy == ValueType.Price) {
                                return formatCurrency(this.total);
                            }
                            else {
                                return this.total.toString();
                            }
                        }
                    } else {
                        if (optionProps.valueBy == ValueType.Duration) {
                            return formatDuration(this.total);
                        }
                        else if (optionProps.valueBy == ValueType.Price) {
                            return formatCurrency(this.total);
                        }
                        else {
                            return this.total.toString();
                        }
                    }
                }
            },
            labels: {
                formatter: function () {
                    if (optionProps.valueBy == ValueType.Duration)
                        return formatDuration(this.value);
                    else if (optionProps.valueBy == ValueType.Price)
                        return formatCurrency(this.value, 0, true);
                    else if (optionProps.valueBy == ValueType.Sensor)
                        return `${this.value} ${optionProps.unit}`;
                    else
                        return this.value.toString();
                },
            },
            plotLines: optionProps.yAxisPlotLines,
        },
        tooltip: {
            formatter: function () {
                const prefix = this.point["drilldownFieldName"] ?? xFieldUpperCase;

                if (optionProps.valueBy == ValueType.Duration)
                    return (this.point.name ? `<b>${prefix}: </b>${this.point.name}<br />` : "") + `<b>${this.point["yFieldUpperCase"]}: </b>${formatDuration(this.y)}`;
                else if (optionProps.valueBy == ValueType.Price)
                    return (this.point.name ? `<b>${prefix}: </b>${this.point.name}<br />` : "") + `<b>${this.point["yFieldUpperCase"]}: </b>${formatCurrency(this.y, 2)}`;
                else if (optionProps.valueBy == ValueType.Sensor)
                    return (this.point.name ? `<b>${prefix}: </b>${this.point.name}<br />` : "") + `<b>${this.point["yFieldUpperCase"]}: </b>${this.y} ${optionProps.unit}`;
                else
                    return `<b>${prefix}: </b>${this.point.name}
                            <br />
                            <b>${this.point["yFieldUpperCase"]}: </b>${this.y}
                            ${this.point.series.name && this.point.name != this.point.series.name && this.point.series.name != "Data" ?
                            "<br /><b>Series:</b>" + this.point.series.name : ""}`;
            }
        },
        plotOptions: {
            [optionProps.type]: {
                dataLabels: {
                    enabled: _.find(optionProps.data, d => d.stacking == "normal") == null && !optionProps.disableDataLabels ? true : false,
                    formatter: function () {
                        //@ts-ignore
                        if (this.point.noDataLabel)
                            return;

                        if (optionProps.valueBy == ValueType.Duration)
                            //@ts-ignore
                            return formatDuration(this.y);
                        else if (optionProps.valueBy == ValueType.Price)
                            //@ts-ignore
                            return formatCurrency(this.y);
                        else
                            //@ts-ignore
                            return this.y;
                    },
                    style: {
                        fontWeight: "normal"
                    }
                }
            },
            line: {
                marker: {
                    enabled: optionProps.enableMarkers
                },
                dataLabels: {
                    enabled: _.find(optionProps.data, d => d.stacking == "normal") == null && !optionProps.disableDataLabels ? true : false,
                    formatter: function () {
                        //@ts-ignore
                        if (this.point.noDataLabel)
                            return;

                        if (optionProps.valueBy == ValueType.Duration)
                            //@ts-ignore
                            return formatDuration(this.y);
                        else if (optionProps.valueBy == ValueType.Price)
                            //@ts-ignore
                            return formatCurrency(this.y);
                        else
                            //@ts-ignore
                            return this.y;
                    },
                    style: {
                        fontWeight: "normal"
                    }
                }

            }
        },
        legend: {
            enabled: optionProps.enableLegend,
            reversed: optionProps.reverseLegend,
            verticalAlign: optionProps.legendAlign ?? "bottom"
        }
    };

    return options;
}

export {
    getHistogramOptions
}