import * as React from "react";
import _ from 'lodash';
import { Card, Tooltip } from "antd";
import AppContext from "../../Definitions/AppContext";
import { formatMonthNr } from "../../Helpers/Formatters";
import { Capabilities } from "../../Definitions/_capabilties";
import { RequireCapability } from "../Shared/RequireCapability";
import { Statistics } from "../Shared/Statistics";
import { ColumnChartCard } from "../Shared/Histogram";
import { ValueType } from "../../Models/ValueType";
import { OrganizationAggregationResult, SortDirection } from "../../ApiClient/swagger/data-contracts";
import { sortBy } from "lodash";



interface StatisticsProps {
    aggregates: OrganizationAggregationResult;
    loading: boolean;
    createActions?: any;
    onExportClick?: () => void;
    exporting?: boolean;
    loadAggregates: () => void;
}

interface StatisticsState { }

export default class OrganizationStatisticsView extends React.Component<StatisticsProps, StatisticsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    formatMonths = (data) => {
        const month = data.split("|")[1];
        return month ? formatMonthNr(month) : data;
    }

    render = () => {
        const { aggregates } = this.props;

        const noHandlersText = "Without handlers";
        const noContactInfoText = "Without contact info";
        const noOrgNrText = "Without org. number";
        const noCallsText = "Without calls";
        const noPeopleText = "Without people";

        return (
            <RequireCapability capability={Capabilities.OrganizationsReadRelated}>
                <Statistics
                    className="organization-statistics-view"
                    actions={this.props.createActions ? this.props.createActions : null}
                    onExportClick={this.props.onExportClick ? this.props.onExportClick : null}
                    exporting={this.props.exporting ? this.props.exporting : null}
                    loadAggregates={this.props.loadAggregates}
                >
                    <ColumnChartCard
                        title="Types of organizations"
                        height={200}
                        xField="label"
                        yField="count"
                        data={aggregates ? aggregates.typeCount : null}
                        valueBy={ValueType.Count}
                    />

                    <ColumnChartCard
                        title={<Tooltip title="Organizations connected to orders, tracked hours or calls">Active organizations</Tooltip>}
                        height={200}
                        xField="label"
                        yField="count"
                        sortDirection={SortDirection.Asc}
                        sortBy="label"
                        xAxisFormatter={this.formatMonths}
                        data={aggregates ? aggregates.activePerYear : null}
                        valueBy={ValueType.Count}
                    />

                    {!this.context.isMobile && aggregates && Object.keys(aggregates).length > 0 ?
                        <Card className="page-stats-card" style={{ marginTop: 'unset' }}>
                            <Card.Grid hoverable={false} className="grid-item">
                                <Tooltip title={noHandlersText}><label></label>{noHandlersText}</Tooltip>
                                <div className="result">{aggregates["withoutHandlers"].count}</div>
                            </Card.Grid>
                            <Card.Grid hoverable={false} className="grid-item">
                                <Tooltip title={noContactInfoText}><label></label>{noContactInfoText}</Tooltip>
                                <div className="result">{aggregates["withoutContactInfo"].count}</div>
                            </Card.Grid>
                            <Card.Grid hoverable={false} className="grid-item">
                                <Tooltip title={noOrgNrText}><label></label>{noOrgNrText}</Tooltip>
                                <div className="result">{aggregates["withoutOrgNr"].count}</div>
                            </Card.Grid>
                            <Card.Grid hoverable={false} className="grid-item">
                                <Tooltip title={noCallsText}><label></label>{noCallsText}</Tooltip>
                                <div className="result">{aggregates["withoutCalls"].count}</div>
                            </Card.Grid>
                            <Card.Grid hoverable={false} className="grid-item">
                                <Tooltip title={noPeopleText}><label></label>{noPeopleText}</Tooltip>
                                <div className="result">{aggregates["withoutPeople"].count}</div>
                            </Card.Grid>
                        </Card> : null}
                </Statistics>
            </RequireCapability>
        );
    }
}