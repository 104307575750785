import * as React from "react";
import _ from 'lodash';
import { Card, Tooltip } from "antd";
import { formatCurrency } from "../../Helpers/Formatters";
import { Statistics } from "../Shared/Statistics";
import { PieChartCard } from "../Shared/PieChart";
import { ValueType } from "../../Models/ValueType";
import { ColumnChartCard } from "../Shared/Histogram";
import AppContext from "../../Definitions/AppContext";


interface StatisticsProps {
    aggregates: any;
    loading: boolean;
    count: number;
    createActions?: any;
    onExportClick?: () => void;
    exporting?: boolean;
    loadAggregates: () => void;
    hideTopCustomers?: boolean;
}

interface StatisticsState { }

export default class ProjectStatisticsView extends React.Component<StatisticsProps, StatisticsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render = () => {
        const { aggregates } = this.props;

        return (
            <Statistics
                className="project-statistics-view"
                actions={this.props.createActions ? this.props.createActions : null}
                onExportClick={this.props.onExportClick ? this.props.onExportClick : null}
                exporting={this.props.exporting ? this.props.exporting : null}
                loadAggregates={this.props.loadAggregates}
            >
                <PieChartCard
                    title="Type of projects"
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates["projectTypes"] : null}
                    valueBy={ValueType.Count}
                    height={200}
                />
                <PieChartCard
                    title="Project - by estimate"
                    loading={this.props.loading}
                    valueBy={ValueType.Count}
                    data={this.props.aggregates ? this.props.aggregates["estimateRange"] : null}
                    height={200}
                />
                {!this.props.hideTopCustomers ? <ColumnChartCard
                    title="Top customers - by estimate"
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates["customerEstimate"] : null}
                    valueBy={ValueType.Duration}
                    xField="label"
                    yField="sum"
                    height={200}
                    route="actors"
                /> : null}
                <ColumnChartCard
                    title="Projects overrun size - hours"
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates["overrunHours"] : null}
                    valueBy={ValueType.Count}
                    xField="label"
                    yField="count"
                    stopSort
                    totalDisplayCount={6}
                    height={200}
                />
                <ColumnChartCard
                    title="Projects overrun size - days"
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates["overrunDays"] : null}
                    valueBy={ValueType.Count}
                    xField="label"
                    yField="count"
                    stopSort
                    totalDisplayCount={6}
                    height={200}
                />

                {!this.context.isMobile && aggregates && Object.keys(aggregates).length > 0 ?
                    <Card className="page-stats-card">
                        <Card.Grid hoverable={false} className="grid-item last-item">
                            <Tooltip title="Projects without estimate"><label>Projects without estimate</label></Tooltip>
                            <div className="result">{this.props.count ? this.props.count - (aggregates["estimateCount"]?.count ?? 0) : 0}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item last-item">
                            <Tooltip title="Remaining amount to be invoiced"><label>Remaining amount to be invoiced</label></Tooltip>
                            <div className="result">{formatCurrency(aggregates["remainingInvoiced"]?.sum ?? 0)}</div>
                        </Card.Grid>
                    </Card>
                    : null}
            </Statistics>
        );
    }
}