import { RoutineFrequencyType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";

export function RoutinePeriodSelector(props: EnumSelectorProps ) {
    return (
        <EnumSelector
            className="routine-period-selector"
            enum={RoutineFrequencyType}
            placeholder={props.placeholder ? props.placeholder : 'Select period...'}
            {...props}
        />
    );
}

export default RoutinePeriodSelector;