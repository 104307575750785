import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Button, Popconfirm } from "antd";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import { OrganizationRoleView, PersonView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { OrganizationLink } from "../Organizations";
import { Capabilities } from "../../Definitions/_capabilties";
import { Drawer } from "../Shared/Drawer";
import OrganizationRoleForm from "./OrganizationRoleForm";
import ListViewCard from "../Shared/ListViewCard";


interface OrganizationRoleListProps {
    person: PersonView;
    className?: string;
}

interface OrganizationRoleListState {
    showCreateDrawer: boolean;
    organizationRole: OrganizationRoleView;
    error: string;
    loadingRemove: boolean;
}

export class OrganizationRoleList extends React.Component<OrganizationRoleListProps, OrganizationRoleListState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showCreateDrawer: false,
            organizationRole: null,
            error: null,
            loadingRemove: false
        }
    }

    toggleCreateDrawer = () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    onEdit = async (organizationRole) => {
        this.setState({ organizationRole });
    }

    onEditDrawerClose = () => {
        this.setState({ organizationRole: null });
    }

    removeRole = async (organizationRole: OrganizationRoleView) => {
        await client.people
            .deletePersonOrganizationRole(this.props.person.id, organizationRole.organization.id, addChangeVectorHeader(this.props.person.changeVector))
            .catch(exception => this.setState({ error: exception.error.title }));

    }

    render = () => {
        if (this.props.person && this.props.person.organizationRoles === undefined) return null;

        const listColumns = [
            {
                title: '',
                render: (data: OrganizationRoleView) => {
                    return (
                        <>
                            <OrganizationLink {...data.organization} />
                            <div>{data.endDate ? `Ended: ${moment(data.endDate).format('DD.MM.YYYY')}` : ""}</div>
                        </>
                    );
                },
                key: 'organization',
                align: 'left'
            },
            {
                title: '',
                dataIndex: 'description',
                key: 'description',
            }
        ];

        if (this.context.user.hasCapability(Capabilities.PeopleWrite)) {
            listColumns.push(
                {
                    title: '',
                    render: (data: OrganizationRoleView) => {
                        if (data.endDate) {
                            return (
                                <Popconfirm title="Remove this organization?" onConfirm={() => this.removeRole(data)}>
                                    <Button
                                        className="edit-btn"
                                        type="link"
                                        style={{ margin: 0, padding: 0 }}
                                        icon={<CloseOutlined />}
                                        shape="circle"
                                    />
                                </Popconfirm>
                            );
                        }

                        return (
                            <Button
                                className="edit-btn"
                                type="link"
                                style={{ margin: 0, padding: 0 }}
                                onClick={() => this.onEdit(data)}
                                icon={<EditOutlined />}
                                shape="circle"
                            />
                        );
                    },
                    key: 'actions',
                    align: 'right',
                    //@ts-ignore
                    width: 40
                }
            );
        }

        return (
            <React.Fragment>
                <Drawer
                    title="Add organization to person"
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    destroyOnClose={true}
                    component={
                        <OrganizationRoleForm
                            person={this.props.person}
                            onComplete={this.toggleCreateDrawer}
                            onCancel={this.toggleCreateDrawer}
                        />
                    }
                />

                <Drawer
                    title="Edit organization role"
                    onClose={this.onEditDrawerClose}
                    open={this.state.organizationRole != null}
                    destroyOnClose={true}
                    component={
                        <OrganizationRoleForm
                            person={this.props.person}
                            organizationRole={this.state.organizationRole}
                            onComplete={this.onEditDrawerClose}
                            onCancel={this.onEditDrawerClose}
                        />
                    }
                />

                <ListViewCard
                    title="Organizations"
                    onAdd={this.context.user.hasAnyCapability([Capabilities.OrganizationsWrite]) ? this.toggleCreateDrawer : null}
                    columns={listColumns}
                    data={this.props.person.organizationRoles}
                    className={`organization-role-list ${this.props.className ? this.props.className : ''}`}
                    emptyText=" "
                />
            </React.Fragment>
        );
    }
}

export default OrganizationRoleList;