import * as React from "react";
import _ from 'lodash';
import { Popover } from 'antd';
import { formatEllipsis } from "../../Helpers/Index";
import { ContactPointLinkView, ContactPointType, ContactPointView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { useContext, useEffect, useState } from "react";


interface ContactPointLinkProps extends ContactPointLinkView {
    disablePopover?: boolean;
    hideLabel?: boolean;
    prefix?: string;
    suffix?: string;
    maxCharacters?: number;
}

export function ContactPointLink(props: ContactPointLinkProps){

    //const [id, setId] = useState<string>(props.id);
    //const [value, setValue] = useState<string>(props.value);
    //const [description, setDescription] = useState<string>(props.description);
    //const [type, setType] = useState<ContactPointType>(props.type);
    //const [deleted, setDeleted] = useState<boolean>(false);
    //const [changeVector, setChangevector] = useState<string>();

    const [contactPoint, setContactPoint] = useState<ContactPointLinkView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        //if (id && (id != props.id)) {
        //    setId(props.id);
        //    setValue(props.value);
        //    setType(props.type);
        //    setDeleted(props.deleted);
        //    setChangevector(null);
        //}

        context.events.contactPoints.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.contactPoints.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    async function onItemUpdatedEvent(eventData: ContactPointView) {
        if (contactPoint.id === eventData.id) {
            setContactPoint(eventData);
        }
    }

    const { prefix, suffix } = props;
    let protocol = null;

    switch (contactPoint.type) {
        case ContactPointType.Email:
            protocol = "mailto:";
            break;
        case ContactPointType.Video:
            protocol = "sip:";
            break;
        case ContactPointType.Phone:
            protocol = "tel:";
            break;
        default:
            protocol = "";
    }

    const dest = protocol + contactPoint.value;

    const linkText = (prefix ? prefix : "") + (props.maxCharacters ? formatEllipsis(contactPoint.value, props.maxCharacters) : contactPoint.value) + (suffix ? suffix : "");
       
    const extra = { className: "no-break list-ellipsis" };

    const link = (
        <span>
            {props.hideLabel ? null : contactPoint.type + ': '}
            {protocol
                ? <a onClick={(e) => e.stopPropagation()} href={`${dest}`} {...extra}>{linkText}</a>
                : <div {...extra}>{linkText}</div>
            }
                
        </span>
    );

    if (props.disablePopover) {
        return link;
    }

    return (
        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
            <Popover placement="bottomLeft" content={contactPoint.value}>
                {link}
            </Popover>
        </span>
    );
}