import classNames from 'classnames';
import { ConfigProvider } from 'antd';
import { useStyleRegister } from '@ant-design/cssinjs';
import { theme as antdTheme } from 'antd';
import type { GlobalToken } from 'antd/lib/theme/interface';
import { resetComponent } from 'antd/lib/style';
import type { CSSInterpolation } from '@ant-design/cssinjs';
import * as React from "react";
import _ from 'lodash';

const { ConfigContext } = ConfigProvider;


export interface CommentProps {
    /** List of action items rendered below the comment content */
    actions?: React.ReactNode[];
    /** The element to display as the comment author. */
    author?: React.ReactNode;
    /** The element to display as the comment avatar - generally an antd Avatar */
    avatar?: React.ReactNode;
    /** ClassName of comment */
    className?: string;
    /** The main content of the comment */
    content: React.ReactNode;
    /** Nested comments should be provided as children of the Comment */
    children?: React.ReactNode;
    /** Comment prefix defaults to '.ant-comment' */
    prefixCls?: string;
    /** Additional style for the comment */
    style?: React.CSSProperties;
    /** A datetime element containing the time to be displayed */
    datetime?: React.ReactNode;
}

export const Comment: React.FC<CommentProps> = ({
    actions,
    author,
    avatar,
    children,
    className,
    content,
    prefixCls: customizePrefixCls,
    datetime,
    ...otherProps
}) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);

    const renderNested = (prefixCls: string, nestedChildren: any) => (
        <div className={classNames(`${prefixCls}-nested`)}>{nestedChildren}</div>
    );

    const prefixCls = getPrefixCls('comment', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls);

    const avatarDom = avatar ? (
        <div className={`${prefixCls}-avatar`}>
            {typeof avatar === 'string' ? <img src={avatar} alt="comment-avatar" /> : avatar}
        </div>
    ) : null;

    const actionDom =
        actions && actions.length ? (
            <ul className={`${prefixCls}-actions`}>
                {actions.map((action, index) => (
                    <li key={`action-${index}`}>{action}</li> // eslint-disable-line react/no-array-index-key
                ))}
            </ul>
        ) : null;

    const authorContent = (author || datetime) && (
        <div className={`${prefixCls}-content-author`}>
            {author && <span className={`${prefixCls}-content-author-name`}>{author}</span>}
            {datetime && <span className={`${prefixCls}-content-author-time`}>{datetime}</span>}
        </div>
    );

    const contentDom = (
        <div className={`${prefixCls}-content`}>
            {authorContent}
            <div className={`${prefixCls}-content-detail`}>{content}</div>
            {actionDom}
        </div>
    );

    const cls = classNames(
        prefixCls,
        {
            [`${prefixCls}-rtl`]: direction === 'rtl',
        },
        className,
        hashId,
    );

    return wrapSSR(
        <div {...otherProps} className={cls}>
            <div className={`${prefixCls}-inner`}>
                {avatarDom}
                {contentDom}
            </div>
            {children ? renderNested(prefixCls, children) : null}
        </div>,
    );
};




interface MergedToken extends GlobalToken {
    componentCls: string;
}

// ============================== Export ==============================
const genSharedButtonStyle = (token: MergedToken): CSSInterpolation => {
    const {
        componentCls,
        colorBgContainer,
        fontSize,
        fontSizeSM,
        padding,
        paddingXS,
        marginSM,
        marginXXS,
        controlHeight,
        lineHeightSM,
        colorText,
        colorTextSecondary,
        colorTextTertiary,
        motionDurationSlow,
    } = token;

    return {
        [componentCls]: {
            ...resetComponent(token) as any,

            position: 'relative',
            backgroundColor: colorBgContainer,

            [`${componentCls}-inner`]: {
                display: 'flex',
                paddingBlock: padding,
            },

            [`${componentCls}-avatar`]: {
                position: 'relative',
                flexShrink: 0,
                marginInlineEnd: marginSM,
                cursor: 'pointer',

                img: {
                    width: controlHeight,
                    height: controlHeight,
                    borderRadius: '50%',
                },
            },

            [`${componentCls}-content`]: {
                position: 'relative',
                flex: 'auto',
                minWidth: 0,
                wordWrap: 'break-word',

                '&-author': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'flex-start',
                    marginBottom: marginXXS,

                    '& > a, & > span': {
                        paddingInlineEnd: paddingXS,
                        fontSize: fontSizeSM,
                        lineHeight: lineHeightSM,
                    },

                    '&-name': {
                        color: colorTextSecondary,
                        fontSize,
                        transition: `color ${motionDurationSlow}`,

                        '> *': {
                            color: colorTextSecondary,
                            '&:hover': {
                                color: colorTextSecondary,
                            },
                        },
                    },
                    '&-time': {
                        color: colorTextTertiary,
                        whiteSpace: 'nowrap',
                        cursor: 'auto',
                    },
                },
                '&-detail p': {
                    whiteSpace: 'pre-wrap',
                    marginBlock: 0,
                },
            },

            [`${componentCls}-actions`]: {
                marginTop: marginSM,
                marginBottom: 0,
                paddingInlineStart: 0,

                '> li': {
                    display: 'inline-block',
                    color: colorTextSecondary,

                    '> span': {
                        marginInlineEnd: marginSM,
                        color: colorTextSecondary,
                        fontSize: fontSizeSM,
                        cursor: 'pointer',
                        transition: `color ${motionDurationSlow}`,
                        userSelect: 'none',

                        '&:hover': {
                            color: colorText,
                        },
                    },
                },
            },

            [`${componentCls}-nested`]: {
                marginInlineStart: 44,
            },
        },
    };
};

export function useStyle(
    prefixCls: string,
): [(node: React.ReactNode) => React.ReactElement, string] {
    const { theme, token, hashId } = antdTheme.useToken();
    const { iconPrefixCls } = React.useContext(ConfigProvider.ConfigContext);

    return [
        //@ts-ignore
        useStyleRegister(
            { theme, token, hashId, path: ['compatible', 'Comment', prefixCls, iconPrefixCls] },
            () => {
                const mergedToken = {
                    componentCls: `.${prefixCls}`,
                    ...token,
                };
                return [genSharedButtonStyle(mergedToken)];
            },
        ),
        hashId,
    ];
}

export default Comment;