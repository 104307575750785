/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommentSortOption,
  CommentView,
  CommentViewEntityViewModel,
  CommentViewPagedCommentQueryCollectionViewModel,
  CreateComment,
  CreateOrUpdateNews,
  NewsSortOption,
  NewsView,
  NewsViewEntityViewModel,
  NewsViewPagedNewsQueryCollectionViewModel,
  SortDirection,
  UpdateComment,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class News<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags News
   * @name GetNewsById
   * @request GET:/api/news/{id}
   * @secure
   */
  getNewsById = (id: string, params: RequestParams = {}) =>
    this.http.request<NewsViewEntityViewModel, any>({
      path: `/api/news/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name UpdateNews
   * @request PUT:/api/news/{id}
   * @secure
   */
  updateNews = (id: string, data: CreateOrUpdateNews, params: RequestParams = {}) =>
    this.http.request<NewsView, any>({
      path: `/api/news/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name DeleteNews
   * @request DELETE:/api/news/{id}
   * @secure
   */
  deleteNews = (id: string, params: RequestParams = {}) =>
    this.http.request<NewsView, any>({
      path: `/api/news/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name CreateNews
   * @request POST:/api/news
   * @secure
   */
  createNews = (data: CreateOrUpdateNews, params: RequestParams = {}) =>
    this.http.request<NewsView, any>({
      path: `/api/news`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name QueryNews
   * @request GET:/api/news
   * @secure
   */
  queryNews = (
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      sortBy?: NewsSortOption;
      sortDirection?: SortDirection;
      authorId?: string;
      published?: boolean;
      featured?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NewsViewPagedNewsQueryCollectionViewModel, any>({
      path: `/api/news`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name RestoreNews
   * @request POST:/api/news/{id}/restore
   * @secure
   */
  restoreNews = (id: string, params: RequestParams = {}) =>
    this.http.request<NewsView, any>({
      path: `/api/news/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name UpdateNewsAttributes
   * @request POST:/api/news/{id}/attributes
   * @secure
   */
  updateNewsAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<NewsView, any>({
      path: `/api/news/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name RegisterNewsReaction
   * @request POST:/api/news/{id}/reactions/{emojicode}
   * @secure
   */
  registerNewsReaction = (id: string, emojicode: string, params: RequestParams = {}) =>
    this.http.request<NewsView, any>({
      path: `/api/news/${id}/reactions/${emojicode}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name GetNewsImage
   * @request GET:/api/news/{id}/image
   * @secure
   */
  getNewsImage = (
    id: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/news/${id}/image`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name SaveNewsImage
   * @request POST:/api/news/{id}/image
   * @secure
   */
  saveNewsImage = (
    id: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NewsView, any>({
      path: `/api/news/${id}/image`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags News
   * @name DeleteNewsImage
   * @request DELETE:/api/news/{id}/image
   * @secure
   */
  deleteNewsImage = (id: string, params: RequestParams = {}) =>
    this.http.request<NewsView, any>({
      path: `/api/news/${id}/image`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsComment
   * @name GetNewsCommentById
   * @request GET:/api/news/{entityId}/comments/{commentId}
   * @secure
   */
  getNewsCommentById = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentViewEntityViewModel, any>({
      path: `/api/news/${entityId}/comments/${commentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsComment
   * @name UpdateNewsComment
   * @request PUT:/api/news/{entityId}/comments/{commentId}
   * @secure
   */
  updateNewsComment = (entityId: string, commentId: string, data: UpdateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/news/${entityId}/comments/${commentId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsComment
   * @name DeleteNewsComment
   * @request DELETE:/api/news/{entityId}/comments/{commentId}
   * @secure
   */
  deleteNewsComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/news/${entityId}/comments/${commentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsComment
   * @name CreateNewsComment
   * @request POST:/api/news/{entityId}/comments
   * @secure
   */
  createNewsComment = (entityId: string, data: CreateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/news/${entityId}/comments`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsComment
   * @name QueryNewsComments
   * @request GET:/api/news/{entityId}/comments
   * @secure
   */
  queryNewsComments = (
    entityId: string,
    query?: {
      sortBy?: CommentSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CommentViewPagedCommentQueryCollectionViewModel, any>({
      path: `/api/news/${entityId}/comments`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsComment
   * @name RestoreNewsComment
   * @request POST:/api/news/{entityId}/comments/{commentId}/restore
   * @secure
   */
  restoreNewsComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/news/${entityId}/comments/${commentId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsComment
   * @name AddNewsCommentEmoji
   * @request POST:/api/news/{entityId}/comments/{commentId}/reactions/{emojicode}
   * @secure
   */
  addNewsCommentEmoji = (entityId: string, commentId: string, emojicode: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/news/${entityId}/comments/${commentId}/reactions/${emojicode}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
