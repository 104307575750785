import _ from "lodash";
import moment from "moment";
import client from "../client";
import { OrderType, OrderView, PagedOrderQuery } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class OrderExport extends BaseExportService<PagedOrderQuery, OrderView> {

    constructor() {

        const specification: ExportConfig<OrderView>[] = [
            { header: 'Number', value: (data: OrderView) => data.orderNumbers.map((value) => { return value.applicationLink.name + ": " + value.orderNumber }).join(" ") ?? null },
            {
                header: 'Customer', value: (data: OrderView) => {
                    let roles = "";

                    if (data.customer) roles = roles + data.customer.name;
                    if (data.invoiceReceiver && data.customer?.id != data.invoiceReceiver.id) {
                        if (roles.length > 0) roles = roles + ", ";
                        roles = roles + "Invoice receiver: " + data.invoiceReceiver.name;
                    }
                    return roles
                }
            },
            { header: 'Label', value: (data: OrderView) => data.label ?? null },
            { header: 'Type', value: (data: OrderView) => data.type ?? null },
            { header: 'Project', value: (data: OrderView) => data.project ? data.project.name : null },
            { header: 'Category', value: (data: OrderView) => data.category ? data.category.name : null },
            { header: 'Quotation', value: (data: OrderView) => data.dates && data.dates.Quotation ? moment(data.dates.Quotation).format() : null },
            { header: 'Quotation expiry', value: (data: OrderView) => data.dates && data.dates.QuotationExpiry ? moment(data.dates.QuotationExpiry).format() : null },
            { header: 'Quotation lost', value: (data: OrderView) => data.dates && data.dates.QuotationLost ? moment(data.dates.QuotationLost).format() : null },
            { header: 'Sales', value: (data: OrderView) => data.dates && data.dates.Sales ? moment(data.dates.Sales).format() : null },
            { header: 'Sales correction', value: (data: OrderView) => data.dates && data.dates.SalesCorrection ? moment(data.dates.SalesCorrection).format() : null },
            { header: 'Sales production', value: (data: OrderView) => data.dates && data.dates.SalesProduction ? moment(data.dates.SalesProduction).format() : null },
            { header: 'Sales completed', value: (data: OrderView) => data.dates && data.dates.SalesCompleted ? moment(data.dates.SalesCompleted).format() : null },
            { header: 'Requested delivery', value: (data: OrderView) => data.dates && data.dates.RequestedDelivery ? moment(data.dates.RequestedDelivery).format() : null },
            { header: 'Delivered', value: (data: OrderView) => data.dates && data.dates.Delivered ? moment(data.dates.Delivered).format() : null },
            { header: 'Completed', value: (data: OrderView) => data.type != OrderType.Direct && data.values ? data.values.completed + " of " + data.values.quantity : null },
            { header: 'Sum', value: (data: OrderView) => data.values ? data.values.discountedPrice : null, format: ExportFormat.Currency },
            { header: 'Last modified', value: (data: OrderView) => data.lastModified ? moment(data.lastModified).format() : "" },
            {
                header: 'Attributes', value: (data: OrderView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })
                    }

                    return dataString;
                }
            },
            { header: 'Created', value: (data: OrderView) => data.created ? moment(data.created).format('DD.MM.YYYY') : null },
            { header: 'Updated', value: (data: OrderView) => data.lastModified ? moment(data.lastModified).format('DD.MM.YYYY') : null },
            { header: 'Deleted', value: (data: OrderView) => data.deleted ? true : false }
        ];

        super(specification, client.orders.queryOrders);
    }
}
