import * as React from "react";
import _ from 'lodash';
import { Card, Empty, Typography } from 'antd'
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportdata from 'highcharts/modules/export-data';
import boost from 'highcharts/modules/boost';
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { ValueType } from "../../Models/ValueType";
import { capitalizeFirstLetter, formatCurrency, formatDuration } from "../../Helpers/Formatters";

const { Title } = Typography;

exporting(Highcharts);
exportdata(Highcharts);
boost(Highcharts);

interface PieChartProps extends HighchartsReact.Props {
    yField?: string,
    xField?: string,
    valueBy?: ValueType,
    data: any,
    title?: JSX.Element | string,
    loading?: boolean,
    height?: number,
    width?: number,
    className?: string,
    tooltipFormatter?: (context: Highcharts.TooltipFormatterContextObject) => string,
    dataLabelFormatter?: (context: Highcharts.PointLabelObject) => string
}

export class PieChart extends React.Component<PieChartProps> {
    render = () => {
        if (!this.props.data) return null;

        const valueType = this.props.valueBy ? this.props.valueBy : ValueType.Duration;
        const xField = this.props.xField ? this.props.xField : "label";
        const yField = this.props.yField ? this.props.yField : valueType == ValueType.Count ? "count" : "sum";

        const xFieldUpperCase = xField.charAt(0).toUpperCase() + xField.slice(1);
        const yFieldUpperCase = yField.charAt(0).toUpperCase() + yField.slice(1);

        const data = _.map(_.reverse(_.sortBy(this.props.data, yField)), (d, key) => {
            return {
                name: d[xField],
                y: d[yField] == 0 ? null : d[yField]
            };
        });

        const self = this;

        const options: Highcharts.Options = {
            credits: {
                enabled: false
            },
            chart: {
                type: 'pie',
                backgroundColor: null,
                height: this.props.height ? this.props.height : null,
                width: this.props.width ? this.props.width : null
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "separator",
                            "downloadPNG",
                            "downloadJPEG",
                            "downloadPDF",
                            "downloadSVG",
                            "separator",
                            "downloadCSV",
                            "downloadXLS"
                        ]
                    }
                }
            },
            title: null,
            boost: {
                useGPUTranslations: true,
                // Chart-level boost when there are more than 5 series in the chart
                seriesThreshold: 5
            },
            series: [
                //@ts-ignore
                {
                    data: data,
                    boostThreshold: 1,
                    type: "pie"
                }
            ],
            tooltip: {
                formatter: function () {
                    if (self.props.tooltipFormatter)
                        return self.props.tooltipFormatter(this);
                    else if (valueType == ValueType.Duration)
                        return `<b>${xFieldUpperCase}: </b>${capitalizeFirstLetter(this.point.name)}<br /><b>${yFieldUpperCase}: </b>${formatDuration(this.y)}<br /><b>Percentage: </b>${this.point.percentage.toFixed(1)}%`;
                    else if (valueType == ValueType.Price)
                        return `<b>${xFieldUpperCase}: </b>${capitalizeFirstLetter(this.point.name)}<br /><b>${yFieldUpperCase}: </b>${formatCurrency(this.y)}`;
                    else
                        return `<b>${xFieldUpperCase}: </b>${capitalizeFirstLetter(this.point.name)}<br /><b>${yFieldUpperCase}: </b>${this.y}<br /><b>Percentage: </b>${this.point.percentage.toFixed(1)}%`;
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    colors: ['#DCF1FC', '#A6D4F1', '#78B6E3', '#3589B6', '#2679A1', '#1A3F57', '#1A5275', '#192D41', '#1E354E', '#1A1F28'],
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            if (self.props.dataLabelFormatter)
                                return self.props.dataLabelFormatter(this);
                            else
                                return capitalizeFirstLetter(this.point.name);
                        },
                        style: {
                            fontWeight: "normal"
                        },
                        distance: 7
                    }
                }
            },
            legend: {
                enabled: false
            }
        };

        const customClass = this.props.className ? this.props.className : null;

        return (
            <HighchartsReact
                highcharts={Highcharts}
                containerProps={{
                    style: {
                        textAlign: "-webkit-center"
                    },
                    className: `chart pie-chart ${customClass}`
                }}
                options={options}
            />
        );
    }
}

export class PieChartCard extends React.Component<PieChartProps> {
    render = () => {
        if (this.props.data === undefined) return null;

        return (
            <Card
                title={typeof this.props.title == "string" ? <Title level={4} className="title">{this.props.title}</Title> : this.props.title}
                className={`graph-card pie-chart-card ${this.props.cardClassName ? this.props.cardClassName : ""}`}

            >
                {this.props.loading ?
                    <Loading3QuartersOutlined spin className="icon icon-loading" />
                    : this.props.data && Object.keys(this.props.data).length > 0
                        ? <PieChart {...this.props} />
                        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </Card>
        );
    }
}