import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Tabs, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { ActorView, CallAggregateQuery, CallAggregationTypes, CallDirection, CallStatus, CallView, ContactPointLinkView, SingleDrilldown } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { formatDuration, formatMonthNr } from "../../Helpers/Formatters";
import { Capabilities } from "../../Definitions/_capabilties";
import { ColumnChartCard } from "../Shared/Histogram";
import { ValueType } from "../../Models/ValueType";
import { ActorLink } from "./ActorLink";
import { ContactPointLink, ContactPointPartialLink } from "../ContactPoints";
import { Drawer } from "../Shared/Drawer";
import { CallWidget } from "../Calls";
import CallCreateForm from "../Calls/CallCreateForm";
import CountUpDuration from "../Shared/CountUpDuration";
import client from "../../ApiClient/client";

const { TabPane } = Tabs;
const { Title } = Typography;

interface ActorCallStatsTabProps {
    actor?: ActorView;
    actorId: string;
    aggregates: any;
    view: "week" | "yearMonth";
    selfView?: boolean;
    dashboard?: boolean;
    contactPoints?: ContactPointLinkView[];
}

interface ActorCallStatsTabState {
    activeTab: "recentCalls" | "numberOfCalls" | "totalCallTime" | "averageCallDuration";
    showCreateDrawer: boolean;
    weekAggregates: SingleDrilldown;
    yearAggregates: Record<string, SingleDrilldown>;
    loading: boolean;
}

export class ActorCallStatsTab extends React.Component<ActorCallStatsTabProps, ActorCallStatsTabState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "recentCalls",
            showCreateDrawer: false,
            weekAggregates: null,
            yearAggregates: null,
            loading: false
        };
    }

    componentDidMount = () => {
        if (this.props.view == "week")
            this.loadWeekAggs();

        if (this.props.view == "yearMonth")
            this.loadYearAggs();
    }

    loadWeekAggs = async () => {
        this.setState({ loading: true })

        const query: Partial<CallAggregateQuery> = {
            fromDate: moment().local().subtract(4, "weeks").startOf('isoWeek').format("YYYY-MM-DDTHH:mm:ss"),
            toDate: moment().local().endOf('isoWeek').format("YYYY-MM-DDTHH:mm:ss"),
            contactId: this.props.actorId,
            deleted: false,
            aggs: [CallAggregationTypes.CallsPerWeek]
        }

        const response = await client.calls.getCallAggregates(query).catch(ex => { });
        if (response) this.setState({ weekAggregates: response.data.results.callsPerWeek[this.props.actorId] });

        this.setState({ loading: false })
    }

    loadYearAggs = async () => {
        this.setState({ loading: true })

        const query: Partial<CallAggregateQuery> = {
            //fromDate: moment().local().subtract(4, "years").startOf('year').format("YYYY-MM-DDTHH:mm:ss"),
            //toDate: moment().local().endOf('year').format("YYYY-MM-DDTHH:mm:ss"),
            contactId: this.props.actorId,
            deleted: false,
            aggs: [CallAggregationTypes.CallsDurationPerMonth]
        }

        const response = await client.calls.getCallAggregates(query).catch(ex => { });
        if (response) this.setState({ yearAggregates: response.data.results.callsDurationPerMonth ?? {} });

        this.setState({ loading: false })
    }

    onTabChange = (activeTab) => {
        this.setState({ activeTab });
    }

    onCallCreatedEvent = (collection: CallView[], call: CallView) => {
        const relatedOrg = _.find(call.organizations || [], org => { return org.id == this.props.actorId; });

        if (relatedOrg != null) {
            collection.push(call);
        }

        return _.orderBy(collection, ["start"], ["desc"]);
    }

    formatMonths = (data) => {
        const month = data.split("|")[1];
        return month ? formatMonthNr(month) : data;
    }

    formatWeeks = (data) => {
        const week = data.split("|")[1];

        const thisWeek = moment().week();

        if (thisWeek == week)
            return "This week";

        return week ? ('Week ' + week) : data;
    }

    toggleCreateDrawer = async () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    render = () => {

        const operationsSlot = {
            right: (
                <nav>
                    {this.props.dashboard ? <Link to={`/calls?actorId=${this.props.actorId}`}>See all calls</Link> : null}
                    {this.props.dashboard ? null : this.context.user.hasCapability(Capabilities.CallsWrite) ? <Button size="small" onClick={this.toggleCreateDrawer}> Add call</Button> : null}
                </nav>
            )
        };

        let numberOfCallsChart = null;
        let totalCallTimeChart = null;

        if (this.props.view == "week") {

            const recentWeeks = _.sortBy(this.state.weekAggregates?.drilldown.slice() ?? [], d => d.label);

            const mappedRecentWeeks = [];
            const thisWeek = moment().week();

            for (let i = thisWeek - 4; i <= thisWeek; i++) {

                const match = _.find(recentWeeks, (week, key) => {
                    const weekNr = week.label.split("|")[1];
                    return weekNr == i.toString();
                });

                if (match)
                    mappedRecentWeeks.push(match);
                else {
                    mappedRecentWeeks.push({
                        average: 0,
                        count: 0,
                        sum: 0,
                        label: "x|" + i
                    });
                }
            }

            numberOfCallsChart = (
                <ColumnChartCard
                    xField="label"
                    yField="count"
                    totalDisplayCount={7}
                    stopSort
                    xAxisFormatter={this.formatWeeks}
                    valueBy={ValueType.Count}
                    data={mappedRecentWeeks}
                    height={200}
                    loading={this.state.loading}
                />
            );

            totalCallTimeChart = (
                <ColumnChartCard
                    xField="label"
                    yField="sum"
                    totalDisplayCount={7}
                    stopSort
                    xAxisFormatter={this.formatWeeks}
                    valueBy={ValueType.Duration}
                    data={mappedRecentWeeks}
                    height={200}
                    loading={this.state.loading}
                />
            );
        }
        else if (this.props.view == "yearMonth") {
            numberOfCallsChart = (
                <ColumnChartCard
                    xField="label"
                    yField="count"
                    drilldownField="drilldown"
                    drilldownXField="label"
                    drilldownYField="count"
                    stopSort
                    xAxisFormatter={this.formatMonths}
                    valueBy={ValueType.Count}
                    data={this.state.yearAggregates ?? null}
                    height={200}
                    loading={this.state.loading}
                />
            );

            totalCallTimeChart = (
                <ColumnChartCard
                    xField="label"
                    yField="sum"
                    drilldownField="drilldown"
                    drilldownXField="label"
                    drilldownYField="sum"
                    stopSort
                    xAxisFormatter={this.formatMonths}
                    valueBy={ValueType.Duration}
                    data={this.state.yearAggregates ?? null}
                    height={200}
                    loading={this.state.loading}
                />
            );
        }

        const callColumns = [
            {
                title: 'Time',
                render: (call: CallView) => {
                    if(call.start)
                        return moment(call.start).format('DD.MM.YYYY HH:mm');

                    return moment(call.init).format('DD.MM.YYYY HH:mm');
                },
                key: 'start',
                width: 140
            },
            {
                title: 'Duration',
                render: (call: CallView) => {
                    if (call.status == CallStatus.Connected)
                        return <CountUpDuration start={call.start} />;
                    else
                        return call.duration ? formatDuration(call.duration) : null;
                },
                key: 'duration',
                width: 100
            },
            {
                title: 'Contact',
                key: 'contact',
                render: (call: CallView) => {
                    const { to, from } = call;

                    let direction = null;

                    if (call.direction == CallDirection.Incoming) {
                        direction = "From: ";
                    }
                    else if (call.direction == CallDirection.Outgoing) {
                        direction = "To: ";
                    }
                    else if (call.direction == CallDirection.Internal) {
                        direction = to.contact != null && to.contact.id == this.context.user.actorId ? "From: " : from.contact != null && from.contact.id == this.context.user.actorId ? "To: " : null;
                    }

                    const contact = to.contact != null && to.contact.id == this.context.user.actorId ? from : from.contact != null && from.contact.id == this.context.user.actorId ? to : null;

                    const actor = contact?.contact ? <ActorLink {...contact.contact} /> : null;
                    const cp = actor ? <ContactPointLink {...contact} hideLabel disablePopover /> : <ContactPointPartialLink contactPoint={contact} hideLabel disablePopover />;

                    return (
                        <>
                            {direction}{actor ?? cp}
                            {actor ? <> ({cp})</> : null}
                        </>
                    );
                }
            },
            {
                title: 'Note',
                key: "note",
                //width: 200,
                render: (call: CallView) => <div className="vertical-limit">{call.note}</div>
            }
        ];

        const canReadCalls = this.context.user.hasCapability(Capabilities.CallsReadActor);

        return (
            <React.Fragment>
                {canReadCalls ?
                    <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange} className="actor-call-stats-tab" tabBarExtraContent={operationsSlot}>
                        <TabPane key="recentCalls" tab={<Title level={4} className="title">Recent calls</Title>}>
                            {canReadCalls ?
                                <CallWidget
                                    title=""
                                    query={{
                                        contactId: this.props.actorId,
                                        deleted: false,
                                        limit: 5
                                    }}
                                    exclutions={this.props.selfView ? null : ["status", "organizations"]}
                                    customColumns={this.props.selfView ? callColumns : null}
                                    onItemCreatedEvent={this.onCallCreatedEvent}
                                />
                                : null}
                        </TabPane>

                        <TabPane key="numberOfCalls" tab={<Title level={4} className="title">Number of calls</Title>}>
                            {canReadCalls ? numberOfCallsChart : null}
                        </TabPane>

                        <TabPane key="totalCallTime" tab={<Title level={4} className="title">Total call time</Title>}>
                            {canReadCalls ? totalCallTimeChart : null}
                        </TabPane>
                    </Tabs>
                    : <div className="noPermission"><h3>Recent, number of & total calls</h3>You don’t have permissions to see this content</div>}

                <Drawer
                    title="Create call"
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    component={
                        <CallCreateForm
                            onCancel={this.toggleCreateDrawer}
                            onComplete={this.toggleCreateDrawer}
                            fromOrToActor={this.props.actor}
                        />
                    }
                />
            </React.Fragment>
        );
    }
}

export default ActorCallStatsTab;