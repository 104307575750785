import { EventTransformer } from './EventTransformer';


export class EventGroup {
    protected model: string;
    protected eventStream: EventTransformer;

    constructor(model: string, eventStream: EventTransformer) {
        this.model = model;
        this.eventStream = eventStream;
    }

    on = (eventType: string, eventHandler: (eventData: any) => void) => {
        const eventName = `${this.model}:${eventType.toLowerCase()}`;
        this.eventStream.subscribe(eventName, eventHandler);
    }

    onMany = (eventTypes: Record<string, (eventData: any) => void>) => {
        for (const key in eventTypes) {
            const value = eventTypes[key];
            this.on(key, value);
        }
    }

    off = (eventType: string, eventHandler: (eventData: any) => void) => {
        const eventName = `${this.model}:${eventType.toLowerCase()}`;
        this.eventStream.unsubscribe(eventName, eventHandler);
    }

    offMany = (eventTypes: Record<string, (eventData: any) => void>) => {
        for (const key in eventTypes) {
            const value = eventTypes[key];
            this.off(key, value);
        }
    }
}

export default EventGroup;