import * as React from "react";
import _ from 'lodash';
import { Card, Button } from 'antd';
import { LoadingOutlined, DownloadOutlined, } from "@ant-design/icons";
import AppContext from "../Definitions/AppContext";
import { addParamsToQuery, addParamsToUrl, handleQueryParamsOnLoad } from "../Helpers/QueryHashHandlers";
import client from "../ApiClient/client";
import ErrorPage from "./ErrorPage";
import { PagedSubscriptionReportQuery, ProductReportResultView, SubscriptionReportGroupBy } from "../ApiClient/swagger/data-contracts";
import { ActorLink, CustomerSelector } from "../Modules/Actors";
import { formatCurrency } from "../Helpers/Formatters";
import { ProductCategorySelector, ProductLink, ProductSelector } from "../Modules/Products";
import Page from "./Page";
import ListPageHeader from "../Modules/Shared/ListPageHeader";
import StaticSelector from "../Modules/Shared/StaticSelector";
import { ListView } from "../Modules/Shared/ListView";
import exportClient from "../ApiClient/Excel";
import moment from "moment";
import DateSelector from "../Modules/Shared/DateSelector";

interface SubscriptionReportProps {
    history: any;
    relationId?: string;
    disableCustomerSelector?: boolean;
    disableUrls?: boolean;
}

interface SubscriptionReportState {
    collection: ProductReportResultView[];
    loading: boolean
    query: Partial<PagedSubscriptionReportQuery>
    error: any
    exporting: boolean
    count: number
}

export default class SubscriptionReportPage extends React.Component<SubscriptionReportProps, SubscriptionReportState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    constructor(props) {
        super(props);

        this.state = {
            collection: [],
            exporting: false,
            count: 0,
            loading: false,
            error: null,
            query: {
                groupBy: SubscriptionReportGroupBy.Product,
                actorId: this.props?.relationId,
                date: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
            }
        }
    }

    componentDidMount = async () => {
        let query = {};

        if (!this.props.disableUrls) {
            const urlQuery = await handleQueryParamsOnLoad();
            query = await addParamsToQuery(urlQuery.query, urlQuery.default ? this.state.query : {}, true);
        } else {
            query = this.state.query;
        }

        this.loadCollection(query);
    }

    loadCollection = async (query: Partial<PagedSubscriptionReportQuery>) => {
        this.setState({ loading: true });

        addParamsToUrl(query);

        const response = await client.subscriptions
            .getProductReport(query)
            .catch(ex => this.setState({ error: ex.error.message ?? ex.error.title }));

        if (response)
            this.setState({ collection: response.data.items, query: response.data.query, count: response.data.count });

        this.setState({ loading: false });
    }

    onExportClick = async () => {
        this.setState({
            exporting: true
        })

        await exportClient.subscriptionReport
            .excel(this.state.query, "subscriptionReport")
            .catch(ex => { });

        this.setState({
            exporting: false
        })
    }

    onCategoriesChange = async (cat) => {
        const newQuery = Object.assign({}, this.state.query);
        newQuery.categoryIds = cat;
        this.loadCollection(newQuery);
    }

    onActChange = async (act) => {
        const newQuery = Object.assign({}, this.state.query);
        newQuery.actorId = act && act.id ? act.id : null;
        this.loadCollection(newQuery);
    }

    onProductChange = async (productIds) => {
        const newQuery = Object.assign({}, this.state.query);
        newQuery.productIds = productIds;
        this.loadCollection(newQuery);
    }

    onDateChange = (date) => {
        const newQuery = Object.assign({}, this.state.query);
        newQuery.date = date;
        this.loadCollection(newQuery);
    }

    onQueryChange = async (group) => {
        let query = Object.assign({}, this.state.query);
        query = await addParamsToQuery(query, group, false);
        this.loadCollection(query);
    }

    render = () => {
        if (this.state.error) return <ErrorPage status={this.state.error} />

        let totalQuantity = 0;
        let totalCostMonth = 0;
        let totalSalesMonth = 0;
        let totalDiscountMonth = 0;
        let totalTurnover = 0;
        let totalResult = 0;

        _.each(this.state.collection, (entry) => {
            totalQuantity += entry.quantity ?? 0;
            totalCostMonth += entry.costMonth ?? 0;
            totalSalesMonth += entry.sumMonth ?? 0;
            totalDiscountMonth += entry.discountMonth ?? 0;
        });

        totalTurnover = totalSalesMonth - totalDiscountMonth;
        totalResult = totalSalesMonth - totalDiscountMonth - totalCostMonth;

        /*const actions = [
            <Link key="subs-link" to="/subscriptions" style={{ whiteSpace: 'nowrap' }}><ArrowLeftOutlined /> Subscriptions</Link>,
        ];*/

        let listColumns = [];

        if (this.state.query?.groupBy == SubscriptionReportGroupBy.Customer) {
            listColumns = [
                {
                    title: 'Customer',
                    render: (data: ProductReportResultView) => <ActorLink {...data.customer} />,
                    key: 'customer',
                    width: 100,
                    sorter: 'customerName'
                },
                {
                    title: 'Quantity',
                    render: (data: ProductReportResultView) => data.quantity,
                    key: 'quantity',
                    sorter: 'quantity'
                },
                {
                    title: 'Cost',
                    render: (data: ProductReportResultView) => { return formatCurrency(data.costMonth) },
                    key: 'yearCost',
                    sorter: 'cost'
                },
                {
                    title: 'Discount',
                    render: (data: ProductReportResultView) => { return formatCurrency(data.discountMonth) },
                    key: 'discount',
                    sorter: 'discount'
                },
                {
                    title: 'Revenue',
                    render: (data: ProductReportResultView) => {
                        return <span>{formatCurrency(data.revenue)}</span>
                    },
                    key: 'yturnover',
                    sorter: 'revenue'
                },
                {
                    title: 'Result',
                    render: (data: ProductReportResultView) => {
                        return <span>{formatCurrency(data.result)}</span>
                    },
                    key: 'yearres',
                    sorter: 'result'
                }
            ];
        }
        else {
            listColumns = [
                {
                    title: 'Code',
                    render: (data: ProductReportResultView) => data.product.code,
                    key: 'code',
                    width: 100,
                    sorter: 'code'
                },
                {
                    title: 'Product',
                    render: (data: ProductReportResultView) => {
                        return <ProductLink {...data.product} />;
                    },
                    key: 'product',
                    ellipsis: true,
                    width: 450,
                    sorter: 'productName'
                },
                {
                    title: 'Quantity',
                    render: (data: ProductReportResultView) => data.quantity,
                    key: 'quantity',
                    sorter: 'quantity'
                },
                {
                    title: 'Cost prices',
                    render: (data) => {
                        const costPrices = _.filter(data.product.prices, (price) => { return price.cost != null });
                        return _.map(costPrices, (price, key) => {
                            return <div key={key}>{price.unit ?? "Default"}: {formatCurrency(price.cost)}</div>;
                        })
                    },
                    key: 'cost',
                    ellipsis: true
                },
                {
                    title: 'Sales prices',
                    render: (data) => {
                        const salesPrices = _.filter(data.product.prices, (price) => { return price.sales != null });
                        return _.map(salesPrices, (price, key) => {
                            return <div key={key}>{price.unit ?? "Default"}: {formatCurrency(price.sales)}</div>;
                        })
                    },
                    key: 'sales',
                    ellipsis: true
                },
                {
                    title: 'Cost',
                    render: (data: ProductReportResultView) => { return formatCurrency(data.costMonth) },
                    key: 'yearCost',
                    sorter: 'cost'
                },
                {
                    title: 'Discount',
                    render: (data: ProductReportResultView) => { return formatCurrency(data.discountMonth) },
                    key: 'discount',
                    sorter: 'discount'
                },
                {
                    title: 'Revenue',
                    render: (data: ProductReportResultView) => {
                        return <span>{formatCurrency(data.revenue)}</span>
                    },
                    key: 'yturnover',
                    sorter: 'revenue'
                },
                {
                    title: 'Result',
                    render: (data: ProductReportResultView) => {
                        return <span>{formatCurrency(data.result)}</span>
                    },
                    key: 'yearres',
                    sorter: 'result'
                }
            ];
        }

        const actions = <React.Fragment>
            <Button type="link" size="middle" onClick={this.onExportClick} icon={this.state.exporting ? <LoadingOutlined spin /> : <DownloadOutlined />} className="export">Excel</Button>
        </React.Fragment>;

        return (
            <Page className="subscription-report-page" navigationKey="subscriptionReport">
                <ListPageHeader
                    title="Subscription report"
                    actions={actions}
                    onQueryChange={() => { }}
                    loading={this.state.loading}
                    initialValues={{}}
                    initialQuery={{}}
                    disableGlobalSearch
                    hideFilters
                    titleActions={[
                        <ProductCategorySelector
                            title="Product categories"
                            key="cat-sel"
                            placeholder="Select product categories..."
                            multiple
                            onChange={this.onCategoriesChange}
                            value={this.state.query?.categoryIds}
                        />,
                        <ProductSelector
                            title="Products"
                            key="product-selector"
                            value={this.state.query?.productIds ?? null}
                            onChange={this.onProductChange}
                            multiple
                        />,
                        <CustomerSelector
                            title="Customer"
                            key="act-sel"
                            onObjectChange={this.onActChange}
                            value={this.props.relationId ? this.props.relationId : (this.state.query ? this.state.query.actorId : null)}
                            placeholder="Select customer..."
                            disabled={this.props.disableCustomerSelector}
                        />,
                        <StaticSelector
                            title="Group by"
                            key="group-by"
                            onChange={(value: SubscriptionReportGroupBy) => this.onQueryChange({ groupBy: value })}
                            value={this.state.query ? this.state.query.groupBy : null}
                            placeholder="Group by..."
                            excludeAllOption
                            disableClear
                            options={[
                                {
                                    name: SubscriptionReportGroupBy.Product,
                                    value: SubscriptionReportGroupBy.Product
                                },
                                {
                                    name: SubscriptionReportGroupBy.Customer,
                                    value: SubscriptionReportGroupBy.Customer
                                }
                            ]}
                        />,
                        <DateSelector
                            title="Date"
                            key="date"
                            onChange={this.onDateChange}
                            placeholder="Select date..."
                            value={this.state.query?.date ?? null}
                        />
                    ]}
                >
                </ListPageHeader>

                {!this.context.isMobile ?
                    <Card className="subscription-report-page-stats-card">
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Quantity</label>
                            <div className="result">{totalQuantity}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Monthly cost</label>
                            <div className="result">{formatCurrency(totalCostMonth, 0, true)}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Monthly discount</label>
                            <div className="result">{formatCurrency(totalDiscountMonth, 0, true)}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Monthly revenue</label>
                            <div className="result">{formatCurrency(totalTurnover, 0, true)}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Monthly result</label>
                            <div className="result">{formatCurrency(totalResult, 0, true)}</div>
                        </Card.Grid>
                    </Card>
                    : null}

                <ListView
                    className="subscriptionPageListView"
                    onQueryChange={this.onQueryChange}
                    columns={listColumns}
                    collection={this.state.collection}
                    loading={this.state.loading}
                    sortBy={this.state.query ? this.state.query.sortBy : null}
                    sortDirection={this.state.query ? this.state.query.sortDirection : null}
                />
            </Page>
        );
    }
}