import client from "./client";
import {
    CreateFileOrFolder, FileView, FileViewPagedFileQueryCollectionViewModel, FolderView,
    FolderViewEntityViewModel, PagedFileQuery, ParentRef, RevertFileToVersion, UpdateFileOrFolder
} from "./swagger/data-contracts";
import { HttpResponse } from "./swagger/http-client";


//Dafuq, why cant requests handle "links?"
export type CreateEntityFileWebRequest = {
    file: File;
    "parent.id"?: string;
    "parent.collection"?: string;
    name: string;
    description?: string;
    applicationIds?: string[];
}

type UpdateEntityFileWebRequest = {
    file: File;
    name: string;
    description?: string;
    tagIds?: string[];
    updateChildrenTags?: boolean;
}


export type GetEntityFolderById = (entityId: string, folderId: string) => Promise<HttpResponse<FolderViewEntityViewModel, any>>;

export type RevertEntityFile = (entityId: string, fileId: string, request: RevertFileToVersion) => Promise<HttpResponse<FileView, any>>;

export type QueryEntityFiles = (entityId: string, fileQuery: PagedFileQuery) => Promise<HttpResponse<FileViewPagedFileQueryCollectionViewModel, any>>;

export type CreateEntityFile = (entityId: string, request: CreateEntityFileWebRequest) => Promise<HttpResponse<FileView, any>>;

export type EditEntityFile = (entityId: string, fileId: string, request: UpdateEntityFileWebRequest) => Promise<HttpResponse<FileView, any>>;

export type DeleteEntityFile = (entityId: string, fileId: string) => Promise<HttpResponse<FileView, any>>;

export type RestoreEntityFile = (entityId: string, fileId: string) => Promise<HttpResponse<FileView, any>>;

export type DownloadEntityFile = (entityId: string, fileId: string, query?: { versionId?: string }) => Promise<Blob>;

export type PreviewFile = (entityId: string, fileId: string, previewType: string) => Promise<Blob>;

export type CreateEntityFolder = (entityId: string, request: CreateFileOrFolder) => Promise<HttpResponse<FolderView, any>>;

export type EditEntityFolder = (entityId: string, folderId: string, request: UpdateFileOrFolder) => Promise<HttpResponse<FolderView, any>>;

export type DeleteEntityFolder = (entityId: string, folderId: string) => Promise<HttpResponse<FolderView, any>>;

export type RestoreEntityFolder = (entityId: string, folderId: string) => Promise<HttpResponse<FolderView, any>>;

async function downloadFile(path: string, entityId: string, fileId: string, versionId?: string) {
    let url = `/api/${path}/${entityId}/files/${fileId}/download`;
    if (versionId)
        url += `?${versionId}`;

    const response = await fetch(url, {
        method: "GET"
    });

    if (response.status === 200 || response.status === 201) {
        const blobbed = await response.blob();
        return blobbed;
    }

    else
        return null;
}

async function getPreviewFile(path: string, entityId: string, fileId: string, previewType: string) {
    const url = `/api/${path}/${entityId}/files/${fileId}/preview?type=` + previewType;
    
    const response = await fetch(url, {
        method: "GET"
    });

    if (response.status === 200 || response.status === 201) {
        const blobbed = await response.blob();
        return blobbed;
    }

    else
        return null;
}

export interface EntityFileClient {
    getFolderById: GetEntityFolderById,
    revertFile: RevertEntityFile;
    queryFiles: QueryEntityFiles;
    createFile: CreateEntityFile;
    editFile: EditEntityFile
    deleteFile: DeleteEntityFile;
    restoreFile: RestoreEntityFile;
    downloadFile: DownloadEntityFile;
    previewFile: PreviewFile;
    createFolder: CreateEntityFolder;
    editFolder: EditEntityFolder;
    deleteFolder: DeleteEntityFolder;
    restoreFolder: RestoreEntityFolder;
}


export const personFileClient: EntityFileClient = {
    revertFile: client.people.revertPersonFile,
    queryFiles: client.people.queryPersonFiles,
    createFile: client.people.createPersonFile,
    editFile: client.people.updatePersonFile,
    deleteFile: client.people.deletePersonFile,
    restoreFile: client.people.restorePersonFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("people", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("people", entityId, fileId, previewType),
    getFolderById: client.people.getPersonFolderById,
    createFolder: client.people.createPersonFolder,
    editFolder: client.people.updatePersonFolder,
    deleteFolder: client.people.deletePersonFolder,
    restoreFolder: client.people.restorePersonFolder
}

export const organizationFileClient: EntityFileClient = {
    revertFile: client.organizations.revertOrganiationFile,
    queryFiles: client.organizations.queryOrganiationFiles,
    createFile: client.organizations.createOrganiationFile,
    editFile: client.organizations.updateOrganiationFile,
    deleteFile: client.organizations.deleteOrganiationFile,
    restoreFile: client.organizations.restoreOrganiationFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("organizations", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("organizations", entityId, fileId, previewType),
    getFolderById: client.organizations.getOrganiationFolderById,
    createFolder: client.organizations.createOrganiationFolder,
    editFolder: client.organizations.updateOrganiationFolder,
    deleteFolder: client.organizations.deleteOrganiationFolder,
    restoreFolder: client.organizations.restoreOrganiationFolder
}

export const ticketFileClient: EntityFileClient = {
    revertFile: client.tickets.revertTicketFile,
    queryFiles: client.tickets.queryTicketFiles,
    createFile: client.tickets.createTicketFile,
    editFile: client.tickets.updateTicketFile,
    deleteFile: client.tickets.deleteTicketFile,
    restoreFile: client.tickets.restoreTicketFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("tickets", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("tickets", entityId, fileId, previewType),
    getFolderById: client.tickets.getTicketFolderById,
    createFolder: client.tickets.createTicketFolder,
    editFolder: client.tickets.updateTicketFolder,
    deleteFolder: client.tickets.deleteTicketFolder,
    restoreFolder: client.tickets.restoreTicketFolder
}

export const projectFileClient: EntityFileClient = {
    revertFile: client.projects.revertProjectFile,
    queryFiles: client.projects.queryProjectFiles,
    createFile: client.projects.createProjectFile,
    editFile: client.projects.updateProjectFile,
    deleteFile: client.projects.deleteProjectFile,
    restoreFile: client.projects.restoreProjectFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("projects", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("projects", entityId, fileId, previewType),
    getFolderById: client.projects.getProjectFolderById,
    createFolder: client.projects.createProjectFolder,
    editFolder: client.projects.updateProjectFolder,
    deleteFolder: client.projects.deleteProjectFolder,
    restoreFolder: client.projects.restoreProjectFolder
}

export const productFileClient: EntityFileClient = {
    revertFile: client.products.revertProductFile,
    queryFiles: client.products.queryProductFiles,
    createFile: client.products.createProductFile,
    editFile: client.products.updateProductFile,
    deleteFile: client.products.deleteProductFile,
    restoreFile: client.products.restoreProductFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("products", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("products", entityId, fileId, previewType),
    getFolderById: client.products.getProductFolderById,
    createFolder: client.products.createProductFolder,
    editFolder: client.products.updateProductFolder,
    deleteFolder: client.products.deleteProductFolder,
    restoreFolder: client.products.restoreProductFolder
}

export const taskFileClient: EntityFileClient = {
    revertFile: client.tasks.revertTaskFile,
    queryFiles: client.tasks.queryTaskFiles,
    createFile: client.tasks.createTaskFile,
    editFile: client.tasks.updateTaskFile,
    deleteFile: client.tasks.deleteTaskFile,
    restoreFile: client.tasks.restoreTaskFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("tasks", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("tasks", entityId, fileId, previewType),
    getFolderById: client.tasks.getTaskFolderById,
    createFolder: client.tasks.createTaskFolder,
    editFolder: client.tasks.updateTaskFolder,
    deleteFolder: client.tasks.deleteTaskFolder,
    restoreFolder: client.tasks.restoreTaskFolder,
}

export const orderFileClient: EntityFileClient = {
    revertFile: client.orders.revertOrderFile,
    queryFiles: client.orders.queryOrderFiles,
    createFile: client.orders.createOrderFile,
    editFile: client.orders.updateOrderFile,
    deleteFile: client.orders.deleteOrderFile,
    restoreFile: client.orders.restoreOrderFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("orders", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("orders", entityId, fileId, previewType),
    getFolderById: client.orders.getOrderFolderById,
    createFolder: client.orders.createOrderFolder,
    editFolder: client.orders.updateOrderFolder,
    deleteFolder: client.orders.deleteOrderFolder,
    restoreFolder: client.orders.restoreOrderFolder,
}

export const resourceFileClient: EntityFileClient = {
    revertFile: client.resources.revertResourceFile,
    queryFiles: client.resources.queryResourceFiles,
    createFile: client.resources.createResourceFile,
    editFile: client.resources.updateResourceFile,
    deleteFile: client.resources.deleteResourceFile,
    restoreFile: client.resources.restoreResourceFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("resources", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("resources", entityId, fileId, previewType),
    getFolderById: client.resources.getResourceFolderById,
    createFolder: client.resources.createResourceFolder,
    editFolder: client.resources.updateResourceFolder,
    deleteFolder: client.resources.deleteResourceFolder,
    restoreFolder: client.resources.restoreResourceFolder,
}

export const routineFileClient: EntityFileClient = {
    revertFile: client.routines.revertRoutineFile,
    queryFiles: client.routines.queryRoutineFiles,
    createFile: client.routines.createRoutineFile,
    editFile: client.routines.updateRoutineFile,
    deleteFile: client.routines.deleteRoutineFile,
    restoreFile: client.routines.restoreRoutineFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("routines", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("routines", entityId, fileId, previewType),
    getFolderById: client.routines.getRoutineFolderById,
    createFolder: client.routines.createRoutineFolder,
    editFolder: client.routines.updateRoutineFolder,
    deleteFolder: client.routines.deleteRoutineFolder,
    restoreFolder: client.routines.restoreRoutineFolder,
}

export const leadFileClient: EntityFileClient = {
    revertFile: client.leads.revertLeadFile,
    queryFiles: client.leads.queryLeadFiles,
    createFile: client.leads.createLeadFile,
    editFile: client.leads.updateLeadFile,
    deleteFile: client.leads.deleteLeadFile,
    restoreFile: client.leads.restoreLeadFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("leads", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("leads", entityId, fileId, previewType),
    getFolderById: client.leads.getLeadFolderById,
    createFolder: client.leads.createLeadFolder,
    editFolder: client.leads.updateLeadFolder,
    deleteFolder: client.leads.deleteLeadFolder,
    restoreFolder: client.leads.restoreLeadFolder
}

export const incidentFileClient: EntityFileClient = {
    revertFile: client.incidents.revertIncidentFile,
    queryFiles: client.incidents.queryIncidentFiles,
    createFile: client.incidents.createIncidentFile,
    editFile: client.incidents.updateIncidentFile,
    deleteFile: client.incidents.deleteIncidentFile,
    downloadFile: (entityId: string, fileId: string, query: { versionId?: string }) => downloadFile("incidents", entityId, fileId, query?.versionId),
    previewFile: (entityId: string, fileId: string, previewType: string) => getPreviewFile("incidents", entityId, fileId, previewType),
    getFolderById: client.incidents.getIncidentFolderById,
    createFolder: client.incidents.createIncidentFolder,
    editFolder: client.incidents.updateIncidentFolder,
    deleteFolder: client.incidents.deleteIncidentFolder,
    restoreFile: client.incidents.restoreIncidentFile,
    restoreFolder: client.incidents.restoreIncidentFolder,
}