import _ from 'lodash';
import moment from 'moment';
import { ProductUnit, SubscriptionLineHistoryView, SubscriptionLineView, SubscriptionStatusType, SubscriptionTransferLineView, SubscriptionType, SubscriptionView } from '../../ApiClient/swagger/data-contracts';

interface TypeOrStatus {
    key: moment.Moment;
    value: any; //SubscriptionType | SubscriptionStatusType;
}

export const getTotalSum = (type: SubscriptionType, lines: SubscriptionTransferLineView[] | SubscriptionLineView[]) => {
    return _.reduce(lines, (sum, line: SubscriptionTransferLineView | SubscriptionLineView) => {
        return sum + getLineSum(type, line);
    }, 0);
}

export const getLineSum = (type: SubscriptionType, line: SubscriptionTransferLineView | SubscriptionLineView) => {
    let factor;
    if (!line || !line.product)
        return 0;
    const salesPrices = _.filter(line.product.prices, (price) => { return price.sales != null });
    if (!type || !salesPrices)
        return 0;

    //@ts-ignore
    if (line.history && line.history[0] && line.history[0].end && moment(line.history[0].end) < moment().startOf("day"))
        return 0;

    const discount = (100 - line.discount) / 100;
    const units = _.map(salesPrices, (s) => { return s.unit.toString() });
    if (units.includes(type)) {
        var salesToUse = _.filter(salesPrices, (s) => { return s.unit.toString() === type });
        return salesToUse[0].sales * line.quantity * discount;
    }
    else {
        if (units.includes(ProductUnit.Monthly)) {
            factor = type == SubscriptionType.Quarterly ? 3 : 12;
            var salesToUse = _.filter(salesPrices, (s) => { return s.unit.toString() === ProductUnit.Monthly });
            return salesToUse[0].sales * factor * line.quantity * discount;
        }
        else if (units.includes(ProductUnit.Yearly)) {
            factor = type == SubscriptionType.Quarterly ? (1 / 4) : (1 / 12);
            var salesToUse = _.filter(salesPrices, (s) => { return s.unit.toString() === ProductUnit.Yearly });
            return salesToUse[0].sales * factor * line.quantity * discount;
        }
        else if (units.includes(ProductUnit.Quarterly)) {
            factor = type == SubscriptionType.Monthly ? (1 / 3) : 4;
            var salesToUse = _.filter(salesPrices, (s) => { return s.unit.toString() === ProductUnit.Quarterly });
            return salesToUse[0].sales * factor * line.quantity * discount;
        }
        return 0;
    }
}
export const findNextTypeOrStatus = (coll: Record<string, any>) => {
    let dates = [];
    const now = moment();
    _.each(coll, (value, key) => {
        if (moment(key).isAfter(now))
            dates.push({ value: value, key: moment(key) })
    });
    dates = _.sortBy(dates, "key");
    if (dates.length > 0)
        return dates[0];
}

export const findCurrentTypeOrStatus = (coll: Record<string, SubscriptionType | SubscriptionStatusType>) => {
    let dates: TypeOrStatus[] = [];
    const now = moment();
    _.each(coll, (value, key) => {
        if (moment(key).isBefore(now))
            dates.push({ value: value, key: moment(key) })
    });
    dates = _.sortBy(dates, "key");
    if (dates.length > 0)
        return dates[dates.length - 1];
}

export const formatSubStatusLabel = (subscription: SubscriptionView) => {
    if (!subscription) return "Unknown";

    const startsInFuture = subscription.startDate != null && moment(subscription.startDate) > moment().startOf('day');
    const cancelledInFuture = subscription.cancellationDate != null && moment(subscription.cancellationDate) > moment().startOf('day');

    let subText = "";

    if (startsInFuture && cancelledInFuture) {
        subText = moment(subscription.startDate) > moment(subscription.cancellationDate)
            ? `(Paused from ${moment(subscription.cancellationDate).format("DD.MM.YYYY")} to ${moment(subscription.startDate).format("DD.MM.YYYY")})`
            : `(Inactive from ${(moment(subscription.cancellationDate).format("DD.MM.YYYY"))})`;
    }
    else if (startsInFuture)
        subText = `(Active from ${moment(subscription.startDate).format("DD.MM.YYYY")})`;
    else if (cancelledInFuture)
        subText = `(Inactive from ${(moment(subscription.cancellationDate).format("DD.MM.YYYY"))})`;

    return `${subscription.status} ${subText}`;

}

export const findActiveSubscriptionline = (line: SubscriptionLineView, includeFuture?: boolean) => {
    let res = null;
    const now = moment().startOf("day");
    _.each(line?.history, (value) => {
        const startOfLine = moment(value.start).startOf("day");

        if ((includeFuture || startOfLine.isSameOrBefore(now)) && (!value.end || moment(value.end).endOf("day").isAfter(now)))
            res = value;
    });

    return res as SubscriptionLineHistoryView;
}

export const findLastSubscriptionLine = (line: SubscriptionLineView) => {

    let res: SubscriptionLineHistoryView = null;

    _.each(line?.history, (value) => {
        if (res == null) {
            res = value;
            return;
        }

        const oldStart = moment(res.start);
        const newStart = moment(value.start);

        if (newStart.isAfter(oldStart)) {
            res = value;
            return;
        }
    });

    return res;
}
export const findLatestFutureLine = (line: SubscriptionLineView) => {

    let res: SubscriptionLineHistoryView = null;

    _.each(line?.history, (value) => {
        const newStart = moment(value.start);
        if (!newStart.isAfter(moment()))
            return;

        if (res == null) {
            res = value;
            return;
        }

        const oldStart = moment(res.start);

        if (newStart.isAfter(oldStart)) {
            res = value;
            return;
        }
    });

    return res;
}

export default {
    getTotalSum,
    getLineSum,
    findNextTypeOrStatus,
    findCurrentTypeOrStatus,
    formatSubStatusLabel,
    findActiveSubscriptionline,
    findLatestFutureLine
}
