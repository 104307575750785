import { Result, Button } from "antd";
import AppContent from "../Framework/AppContent";
import AppLayout from "../Framework/AppLayout";

interface ErrorPageProps {
    status: any;
}

function ErrorPage({ status }: ErrorPageProps) {

    let cleanedStatus = "";
    let subTitle = "";
    if (typeof status === "string") {
        cleanedStatus = status.toString();
        subTitle = "Some error has occured. Error: " + cleanedStatus;
    } else if ('status' in status)
    {
        cleanedStatus = status.status.toString();
        subTitle = "Some error has occured. Statuscode: " + cleanedStatus;
    }

    let title = "Some error happened";    
     
    let antResult: "success" | "error" | "info" | "warning" | "404" | "403" | "500" | undefined = undefined;

    if (cleanedStatus === "400") {
        title = "400";
        subTitle = "Bad request";
        antResult = "500";
    }
    else if (cleanedStatus === "401") {
        title = "401";
        subTitle = "Unauthorized";
        antResult = "403";
    }
    else if (cleanedStatus === "403") {
        title = "403";
        subTitle = "Forbidden";
        antResult = "403";
    }
    else if (cleanedStatus === "404") {
        title = "404";
        subTitle = "Not found";
        antResult = "404";
    }
    else {
        antResult = "error";
    }

    const actions = cleanedStatus == "401"
        ? <Button type="primary" onClick={() => window.location.reload()}>Login</Button>
        : <Button type="primary" onClick={() => window.location.href = '/'}>Home</Button>

    return (
        <div className="error-page">
            <Result
                status={antResult}
                title={title}
                subTitle={subTitle}
                extra={actions}
            />
        </div>
    );
}

export function ErrorLayout({ status }: ErrorPageProps) {
    return (
        <AppLayout>
            <AppContent>
                <ErrorPage status={status} />
            </AppContent>
        </AppLayout>
    );
}

export default ErrorPage;