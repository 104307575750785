import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import { AddOrUpdateProductPart, ProductPart, ProductPartView, ProductType, ProductView } from "../../ApiClient/swagger/data-contracts";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { NumberInput } from "../Shared/NumberInput";
import ProductSelector from "./ProductSelector";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";


interface CreateProductPartProps {
    onComplete: (product: ProductView) => void,
    onCancel?: () => void,
    product: ProductView,
    part?: ProductPartView
}

interface CreateProductPartState {
    loading: boolean,
    error: string,
}

class ProductPartForm extends React.Component<CreateProductPartProps, CreateProductPartState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
        }
    }

    validateQuantity = (rule, quantity) => {
        if (quantity == null || quantity == undefined)
            return Promise.reject("Invalid quantity.");
        else if (quantity == 0 || quantity < 0)
            return Promise.reject("Quantity must be larger than 0.");

        return Promise.resolve();
    }

    onSubmit = async (partRequest: AddOrUpdateProductPart) => {
        if (this.props.product.id === partRequest.subProductId) {
            this.setState({
                error: "Product can't be a part of itself."
            });

            return;
        }

        this.setState({ loading: true });

        try {
            if (this.props.part != null) {
                const updateProductResponse = await client.products.updateProductPart(this.props.product.id, this.props.part.partId, partRequest, addChangeVectorHeader(this.props.product.changeVector));
                if (updateProductResponse) this.props.onComplete(updateProductResponse.data);
            }
            else {
                const addProductResponse = await client.products.addProductPart(this.props.product.id, partRequest, addChangeVectorHeader(this.props.product.changeVector));
                if (addProductResponse) this.props.onComplete(addProductResponse.data);
            }
        }
        catch (exception: any) {
            this.setState({ error: exception.error ?? exception.statusText });
        }

        this.setState({ loading: false });
    }

    render = () => {
        return (
            <BaseForm
                type={this.props.part != null ? FormType.Edit : FormType.Create}
                onCancel={this.props.onCancel}
                onSubmit={this.onSubmit}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={this.props.part != null ?
                    {
                        subProductId: this.props.part ? this.props.part.product.id : null,
                        quantity: this.props.part ? this.props.part.quantity : null
                    } : null}
            >
                <SelectorInput
                    param="subProductId"
                    required
                    warningMessage="Please choose a product"
                    title="Product"
                    selector={<ProductSelector
                        filters={{
                            types: [ProductType.Standard]
                        }}
                    />}
                />

                <NumberInput
                    param="quantity"
                    title="Quantity"
                    placeholder="Quantity"
                    //warningMessage="Invalid quantity"
                    validator={this.validateQuantity}
                    required
                />
            </BaseForm>
        );
    }
}

export default ProductPartForm;