﻿import * as React from "react";
import { Drawer as AntDrawer } from "antd";
import { DrawerProps as AntDrawerProps } from "antd/lib/drawer";
import AppContext from "../../Definitions/AppContext";
import { areMakingChanges } from "../../Helpers/BasePageHelpers";

interface DrawerProps extends AntDrawerProps {
    component?: JSX.Element;
    toggleOpacity?: boolean;
    toggleOpacityStyle?: () => void;
    hideDrawerContent?: boolean;
}

interface DrawerState { }

export class Drawer extends React.Component<DrawerProps, DrawerState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {}
    }

    //REFRESH EVENTS
    componentWillReceiveProps = (prevProps, prevState) => {
        if (this.props.open != prevProps.open)
            areMakingChanges(prevProps.open);
    }

    //toggleOpacity = () => {
    //    if (this.props.toggleOpacityStyle) {
    //        this.props.toggleOpacityStyle();
    //    }
    //}

    render() {
        const width = this.context.isMobile ? '100%' : this.props.width ? this.props.width : 500;

        return (
            <AntDrawer
                width={width}
                {...this.props}
                getContainer={this.props.getContainer ?? document.getElementById('main')}
                rootClassName={`container-drawer ${this.props.rootClassName ?? ""}`}
                className={this.props.className ?? ""}
                headerStyle={/*Object.assign({}, { paddingRight: this.props.toggleOpacity ? "30px" : {} }, */this.props.headerStyle ?? {}}
                contentWrapperStyle={{ backgroundColor: this.props.hideDrawerContent ? "transparent" : "#fff" }}
            >
                {/*{this.props.toggleOpacity ? <EyeInvisibleOutlined style={{ color: this.props.hideDrawerContent ? "blue" : null, fontSize: "16px", zIndex: 20 }} onClick={this.toggleOpacity} className="toggleOpacity" /> : null}*/}
                {this.props.open || this.props.visible ? this.props.component != null ? this.props.component : this.props.children : null}
            </AntDrawer>
        );
    }
}