import React, { Component, ReactNode, ReactElement } from "react";
import _ from 'lodash';
import { Button, Typography, Form, Space } from "antd";
import { FilterOutlined } from '@ant-design/icons';
import { FormInstance } from "antd/lib/form";
import AppContext from "../../Definitions/AppContext";
import { Drawer } from "./Drawer";
import { EntitySearch } from "./EntitySearch";
const { Title } = Typography;


interface ListPageHeaderProps {
    title: string;
    subTitle?: string;
    actions?: ReactNode;
    titleActions?: ReactNode;
    actionsAfterFilter?: ReactNode;
    onQueryChange: (values: any) => any;
    loading: boolean;
    disableGlobalSearch?: boolean;
    initialValues: Record<string, any>;
    quickSearch?: ReactElement;
    hideFilters?: boolean;
    initialQuery?: any;
    children?: ReactNode;
}

interface ListPageHeaderState {
    showFilterDrawer: boolean;
    updatedQueryValues: Record<string, any>;
    formValues: any;
    searchValue: string;
}

export class ListPageHeader extends Component<ListPageHeaderProps, ListPageHeaderState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    private formRef = React.createRef<FormInstance>();

    constructor(props: ListPageHeaderProps) {
        super(props)
        this.state = {
            showFilterDrawer: false,
            updatedQueryValues: {},
            formValues: [],
            searchValue: "",
        };
    }

    componentDidMount = () => {
        document.title = this.props.title ? `${this.props.title} | Overviu` : "Overviu";
    }

    componentDidUpdate = (prevProps: ListPageHeaderProps) => {
        const { initialValues } = this.props;

        const isEqualValues = _.isEqual(initialValues, prevProps.initialValues);

        if (!isEqualValues) {
            this.setState({ updatedQueryValues: initialValues });

            _.each(initialValues, (value, key) => {
                if (prevProps.initialValues && prevProps.initialValues[key] === value) return;

                const setValue = {
                    [key]: value
                };

                this.formRef.current.setFieldsValue(setValue);
            });

            this.setState({ searchValue: initialValues["terms"] })
        }
    }

    toggleFilterDrawer = () => {
        const { showFilterDrawer } = this.state;

        this.setState({ showFilterDrawer: !showFilterDrawer });

        this.formRef.current.resetFields();
    }

    onQueryChange = (group) => {
        const { onQueryChange } = this.props;
        const { updatedQueryValues } = this.state;

        if (group['terms'] !== undefined) {
            onQueryChange(group);
        }
        else {
            const query = Object.assign({}, updatedQueryValues, group);
            this.setState({ updatedQueryValues: query });
        }
    }

    resetFilters = () => {
        const { initialValues, initialQuery } = this.props;

        this.setState({ searchValue: "" });
        this.setState({ formValues: [] });

        const resetQuery = {};

        _.each(initialValues, (value, key) => {
            if (key === "deleted") {
                resetQuery[key] = false;
            }
            else {
                const initialMatch = _.find(initialQuery, (q, key2) => { return key === key2; });
                if (initialMatch != null) {
                    resetQuery[key] = initialMatch;
                }
                else {
                    resetQuery[key] = null;
                }
            }
        });

        this.props.onQueryChange(resetQuery);
    }

    onSubmit = () => {
        const { onQueryChange } = this.props;
        const { formValues, showFilterDrawer } = this.state;

        let query = {};

        _.forEach(formValues, (value) => {
            query = Object.assign({}, query, value);
        });
        onQueryChange(query);

        this.setState({ showFilterDrawer: !showFilterDrawer });
    }

    onFilterChange = (group) => {
        this.setState(prevState => ({
            formValues: [...prevState.formValues, group]
        }))
    }

    onSearchChange = (group) => {
        const { onQueryChange } = this.props;
        this.setState({ searchValue: group });
        const query = { terms: group };
        onQueryChange(query);
    }

    render = () => {

        const mobileFilterBtn = this.context.isMobile ? <Button type="primary" size="middle" icon={<FilterOutlined />} onClick={this.toggleFilterDrawer} /> : null;
        const actions = this.props.actions ? <nav className="page-actions">{this.props.actions} {mobileFilterBtn}</nav> : null;

        let isInitialQuery = false;
        const standardValues = ["from", "limit", "includeAggregates", "subRoute"];

        if (this.props.initialQuery) {
            const queryQithoutEmptyValues = {};
            _.each(this.state.updatedQueryValues, (v, k) => {
                if (k === "deleted") {
                    if (v === true)
                        queryQithoutEmptyValues[k] = v //Handle deleted specifically
                }
                else if (v != null && !standardValues.includes(k))
                    queryQithoutEmptyValues[k] = v;
            });

            const initialQueryWithoutStandardValues = {};
            _.each(this.props.initialQuery, (v, k) => {
                if (k === "deleted") {
                    if (v === true)
                        initialQueryWithoutStandardValues[k] = v //Handle deleted specifically
                }
                else if (!standardValues.includes(k))
                    initialQueryWithoutStandardValues[k] = v;


            });

            const updatedLength = Object.keys(queryQithoutEmptyValues).length;
            const initialLength = Object.keys(initialQueryWithoutStandardValues).length;
            const isEqual = _.isEqual(initialQueryWithoutStandardValues, queryQithoutEmptyValues);

            if (initialLength === updatedLength && isEqual)
                isInitialQuery = true;
        }

        const filterBreak = this.props.quickSearch && window.matchMedia("(min-width: 0) and (max-width: 1600px) ").matches;

        const filtersView = (
            <React.Fragment>
                {this.props.quickSearch && !this.context.isMobile ?
                    <div className="quick-search">
                        <label>Quick search:</label>
                        {this.props.quickSearch}
                    </div>
                    : null}
                {
                    this.context.isMobile ?
                        actions : this.props.hideFilters ? null :
                            <div className="filter-show" style={this.props.quickSearch || !filterBreak ? {} : { marginLeft: 0, marginBottom: 20 }}>
                                <Button size="small" onClick={this.toggleFilterDrawer}>Filters <FilterOutlined /></Button>
                            </div>
                }

                {this.props.loading ? null :
                    this.props.initialQuery && !this.context.isMobile && !isInitialQuery ? (
                        <Button type="primary" className="reset-filters-btn" size="small" onClick={this.resetFilters}>Reset filters</Button>
                    ) : null
                }

                {this.props.hideFilters ? null :
                    <Drawer
                        title="Filters"
                        extra={
                            <Space>
                                <Button onClick={this.onSubmit} type="primary">Use filters</Button>
                            </Space>
                        }
                        open={this.state.showFilterDrawer}
                        onClose={this.toggleFilterDrawer}
                        destroyOnClose={true}
                    >
                        <div className="filter-list">
                            {this.props.children} {/*THIS IS THE FILTERS. WRAP THEM IN <FORM.ITEM />*/}
                        </div>
                    </Drawer>
                }
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <header className={`page-header list-page-header ${this.context.isMobile ? 'page-header-mobile' : 'page-header-desktop'} ${this.props.actions ? 'page-header-with-action' : null}`}>
                    <Title level={this.context.isMobile ? 3 : 2} className="page-title list-page-title">{this.props.title}</Title>
                    <Form onValuesChange={this.onFilterChange} ref={this.formRef} initialValues={this.props.initialValues} className={`filter-container ${this.context.isMobile ? 'filter-container-mobile' : 'filter-container-desktop'}`}>
                        {this.props.disableGlobalSearch ? null : <EntitySearch size="middle" loading={this.props.loading} onChange={this.onSearchChange} value={this.state.searchValue} />}
                        {this.props.titleActions ?? null}
                        {filterBreak && !this.context.isMobile ? null : filtersView}
                        {this.props.actionsAfterFilter}
                    </Form>
                    {!this.context.isMobile ? actions : null}

                    {filterBreak && !this.context.isMobile ? <Form onValuesChange={this.onFilterChange} ref={this.formRef} initialValues={this.props.initialValues} className={`filter-container filter-break`}>
                        <div className="filter-break-container">{filtersView}</div></Form> : null}

                </header>
            </React.Fragment>
        );
    }
}

export default ListPageHeader;