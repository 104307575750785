import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { StopSubscriptionLine, SubscriptionLineView, SubscriptionView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";


interface SubscriptionLineStopFormProps {
    onComplete: (updated: SubscriptionLineView[]) => void;
    onCancel?: () => void;
    subscription: SubscriptionView;
    line: SubscriptionLineView;
    selectedLines: SubscriptionLineView[];
    onLineUpdateSuccess: (line: SubscriptionLineView[]) => void;
    hideContent?: boolean;
}

interface SubscriptionLineStopFormState {
    loading: boolean,
    error: string
}

export class SubscriptionLineStopForm extends React.Component<SubscriptionLineStopFormProps, SubscriptionLineStopFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null
        }
    }

    onSubmit = async (request: StopSubscriptionLine) => {
        this.setState({ loading: true });

        if (this.props.line) {
            const response = await client.subscriptions
                .stopSubscriptionLine(this.props.subscription.id, this.props.line.id, request, addChangeVectorHeader(this.props.subscription.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete([response.data]);
        }
        else if (this.props.selectedLines?.length > 0) {
            const results: SubscriptionLineView[] = [];
            let errorMsg = null;
            const successLines = [];

            for (let i = 0; i < this.props.selectedLines.length; i++) {
                const line = this.props.selectedLines[i];
                const response2 = await client.subscriptions.stopSubscriptionLine(this.props.subscription.id, line.id, request).catch(exception => errorMsg = exception.error.title);
                if (response2) results.push(response2.data);
                successLines.push(line);
            }

            if (errorMsg) {
                this.setState({ error: errorMsg });
                if (this.props.onLineUpdateSuccess)
                    this.props.onLineUpdateSuccess(successLines);
            }
            else
                this.props.onComplete(results);
        }

        this.setState({ loading: false });
    }

    render() {
        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                loading={this.state.loading}
                error={this.state.error}
                onCancel={this.props.onCancel}
                className={this.props.hideContent ? "hideContent" : ""}
                initialValues={{
                    application: moment().endOf('month').endOf('day').format()
                }}
            >
                <SelectorInput
                    param="application"
                    required
                    warningMessage="Please choose a application date"
                    title="Stop date"
                    selector={
                        <DateSelector />
                    }
                />

            </BaseForm>
        );
    }
}

export default SubscriptionLineStopForm;