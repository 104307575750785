import { SubscriptionType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";


export function SubscriptionTypeSelector(props: EnumSelectorProps) {
    return (
        <EnumSelector
            className="subscription-type-selector"
            enum={SubscriptionType}
            placeholder={props.placeholder ? props.placeholder : 'Select subscription type...'}
            {...props}
        />
    );
}