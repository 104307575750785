import * as React from "react";
import _ from 'lodash';
import { Button, Popconfirm, Typography } from "antd";
import { EditOutlined, DeleteOutlined, CheckCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { CreateCompletion, CreateOrderline, OrderlineView, OrderType, OrderView, ProductPartView, ProductType, ProductView, UpdateOrderline } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { ProductLink } from "../Products";
import { formatCurrency, formatNumber } from "../../Helpers/Formatters";
import { Capabilities } from "../../Definitions/_capabilties";
import ListViewCard from "../Shared/ListViewCard";
import { Drawer } from "../Shared/Drawer";
import OrderlineForm from "./OrderlineForm";
import ProductPartOnOrderlineForm from "./ProductPartOnOrderlineForm";
import CompletionCreateForm from "./CompletionCreateForm";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";

const { Title } = Typography;

export interface OrderlineListProps {
    showCompletions: boolean;

    orderlines: Partial<OrderlineView>[];
    className?: string;

    onCreateCompleted: (request: Partial<CreateOrderline>, product: ProductView) => Promise<void>;
    onEditCompleted: (orderline: Partial<OrderlineView>, request: Partial<UpdateOrderline>, product: ProductView) => Promise<void>;
    onRemoveCompleted: (request: Partial<OrderlineView>) => Promise<void>;
    onCompletionCompleted?: (request: Partial<CreateCompletion>) => Promise<void>;

    onAddPart?: (part: ProductPartView, orderlineId: string) => void;
    onRemovePart?: (part: ProductPartView, orderlineId: string) => void;

    minimized?: boolean;

    order?: OrderView;
    orderType?: OrderType;
    optional?: boolean;

    orderFormSubTitle?: boolean;
    showSum?: boolean;
}

export interface OrderlineListState {
    showCreateDrawer: boolean;
    showEditDrawer: boolean;
    showCompletionDrawer: boolean;
    showPartDrawer: boolean;

    orderline: Partial<OrderlineView>;
    productId: string;
    partQuantity: number;

    part: ProductPartView;
    orderlineProduct: ProductView;
    partOrderline: Partial<OrderlineView>;

    error: string;
    loading: boolean;
    expandedOrderlines: string[];
}

export class OrderlineList extends React.Component<OrderlineListProps, OrderlineListState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showCreateDrawer: false,
            showEditDrawer: false,
            showCompletionDrawer: false,
            showPartDrawer: false,
            orderline: null,
            partQuantity: null,
            part: null,
            orderlineProduct: null,
            partOrderline: null,
            expandedOrderlines: [],
            productId: null,
            error: null,
            loading: false
        }
    }

    onRemoveComplete = async (orderline: Partial<OrderlineView>) => {
        this.setState({ loading: true });

        try {
            await this.props.onRemoveCompleted(orderline);
            this.onEditDrawerClose();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onCreate = () => {
        this.setState({
            showCreateDrawer: true
        });
    }

    onCreateComplete = async (request: Partial<CreateOrderline>, product: ProductView) => {
        this.setState({ loading: true });
        try {
            await this.props.onCreateCompleted(request, product);
            this.onCreateDrawerClose();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
        this.setState({ loading: false });

    }

    onCreateDrawerClose = () => {
        this.setState({
            showCreateDrawer: false
        });
    }

    onEdit = (orderline) => {
        this.setState({
            orderline: orderline,
            showEditDrawer: true,
        });
    }

    onEditComplete = async (request: Partial<UpdateOrderline>, product: ProductView) => {
        this.setState({ loading: true });
        try {
            await this.props.onEditCompleted(this.state.orderline, request, product);
            this.onEditDrawerClose();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
        this.setState({ loading: false });
    }

    onEditDrawerClose = () => {
        this.setState({
            orderline: null,
            showEditDrawer: false
        });
    }

    onCompletion = (orderline, productId, quantity) => {
        this.setState({
            showCompletionDrawer: true,
            orderline: orderline,
            productId: productId,
            partQuantity: quantity
        });
    }

    onCompletionComplete = async (request: CreateCompletion) => {

        this.setState({ loading: true });

        try {
            await this.props.onCompletionCompleted(request);
            this.onCompletionDrawerClose();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onCompletionDrawerClose = () => {
        this.setState({
            showCompletionDrawer: false,
            orderline: null,
            productId: null,
            partQuantity: null
        });
    }

    onExpand = async (expanded, record) => {
        this.setState({ loading: true });

        try {
            if (expanded) {
                const collection = this.props.orderlines.slice();
                const orderline = _.find(collection, c => { return c.id === record.id; });
                if (orderline) {
                    const expandedOrderlines = [...this.state.expandedOrderlines, orderline.id];
                    this.setState({ expandedOrderlines });
                }
            } else {
                const index = this.state.expandedOrderlines.indexOf(record.id);
                if (index > -1) {
                    const expandedOrderlines2 = [...this.state.expandedOrderlines];
                    expandedOrderlines2.splice(index, 1);
                    this.setState({ expandedOrderlines: expandedOrderlines2 });
                }
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onAddPartDrawer = (orderlineProduct, partOrderline) => {
        this.setState({
            orderlineProduct,
            partOrderline,
            showPartDrawer: true
        });
    }

    onAddPartDrawerClose = () => {
        this.setState({
            orderlineProduct: null,
            partOrderline: null,
            showPartDrawer: false
        });
    }

    onAddPartComplete = (part: ProductPartView) => {
        this.props.onAddPart(part, this.state.partOrderline.id);
        if (this.state.part != null)
            this.onEditPartDrawerClose();
        else
            this.onAddPartDrawerClose();
    }

    onEditPart = (part, partOrderline, orderlineProduct) => {
        this.setState({
            part,
            partOrderline,
            orderlineProduct
        });
    }

    onEditPartDrawerClose = () => {
        this.setState({
            part: null,
            partOrderline: null,
            orderlineProduct: null
        });
    }

    onRemovePart = async (part: ProductPartView, orderline: Partial<OrderlineView>) => {
        if (this.props.onRemovePart)
            this.props.onRemovePart(part, orderline.id);
        else if (this.props.order != null) {
            this.setState({ loading: true });

            const response = await client.orders
                .orderlineRemoveProductPart(this.props.order.id, orderline.id, part.product.id, addChangeVectorHeader(this.props.order.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) {

            }

            this.setState({ loading: false });
        }
    }

    orderlineCustomExpandable = (orderline: OrderlineView) => {
        if (orderline) {
            if (orderline.product && orderline.product.type == ProductType.Custom) return true;
            else if (orderline.parts && orderline.parts.length > 0) return true;
            else return false;
        }
        else
            return false;
    }

    render = () => {
        if (!this.props.orderlines) return null;
        const { minimized, showSum } = this.props;

        const listColumns = [
            //{
            //    title: 'Reference',
            //    dataIndex: 'reference',
            //    key: 'reference'
            //},
            {
                title: 'Product',
                render: (orderline: OrderlineView) => orderline.product ? <ProductLink {...orderline.product} /> : null,
                key: 'actor'
            },
            {
                title: 'Description',
                render: (orderline: OrderlineView) => <div style={{ whiteSpace: 'normal' }}>{orderline.description}</div>,
                key: 'description',
                hide: minimized
            },
            {
                title: 'Quantity',
                render: (orderline: OrderlineView) => formatNumber(orderline.quantity, 2),
                key: 'quantity',
                hide: this.props.showCompletions,
                width: 100
            },
            {
                title: 'Completed',
                render: (orderline: OrderlineView) => {

                    let sum = 0;
                    if (orderline.completions) {
                        for (let i = 0; i < orderline.completions.length; i++) {
                            if (orderline.completions[i].item.id == orderline.product.id) {
                                if (!orderline.completions[i].deleted)
                                    sum += orderline.completions[i].quantity;
                            }
                        }
                    }
                    return <span>{sum} of {orderline.quantity}</span>;
                },
                key: 'completions',
                hide: !this.props.showCompletions,
                width: 110
            },
            {
                title: 'Unit',
                dataIndex: 'unit',
                key: 'unit',
                hide: minimized,
                width: 90
            },
            {
                title: 'Price',
                render: (orderline: OrderlineView) => {
                    const price = (orderline.salesPrice?.value || 0) * (100 - (orderline.discount?.value || 0)) / 100;
                    return formatCurrency(price);
                },
                key: 'price',
                hide: minimized,
                width: 110
            },
            {
                title: 'Coverage',
                render: (orderline: OrderlineView) => {
                    const discountedPrice = (orderline.salesPrice?.value || 0) * ((100 - (orderline.discount?.value || 0)) / 100) * (orderline.quantity || 0);
                    const costprice = orderline.costPrice?.value * (orderline.quantity || 0);

                    const coverageAmount = discountedPrice - costprice;
                    const coveragePercent = coverageAmount / discountedPrice * 100;

                    return <span>{formatCurrency(coverageAmount)} ({coveragePercent.toFixed(2)}%)</span>
                },
                key: 'completions',
                hide: minimized,
                width: 130
            },
            {
                title: 'Sum',
                render: (orderline: OrderlineView) => {
                    const sum = (orderline.quantity || 0) * (orderline.salesPrice?.value || 0) * ((100 - (orderline.discount?.value || 0)) / 100);
                    return formatCurrency(sum);
                },
                key: 'sum',
                hide: minimized && !showSum,
                width: 110
            },
        ];

        const partColumns = [
            {
                title: 'Product',
                render: (part: ProductPartView) => part.product ? <ProductLink {...part.product} /> : null,
                key: 'actor',
            },
            {
                title: 'Default unit',
                dataIndex: 'unit',
                key: 'unit'
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity'
            },
            {
                title: 'Completed',
                render: (part: ProductPartView) => {
                    const orderline = _.find(this.props.orderlines, o => { return o.parts.includes(part); });
                    if (orderline.product.type != ProductType.Custom) return null;

                    let sum = 0;
                    if (orderline.completions) {
                        for (let i = 0; i < orderline.completions.length; i++) {
                            if (orderline.completions[i].item.id == part.product.id) {
                                if (!orderline.completions[i].deleted)
                                    sum += orderline.completions[i].quantity;
                            }
                        }
                    }
                    return <span>{sum} of {part.quantity}</span>;
                },
                key: 'completions',
                hide: !this.props.showCompletions
            },
        ];

        if (this.context.user.hasAnyCapability([Capabilities.OrdersWrite])) {
            listColumns.push({
                title: 'Actions',
                //@ts-ignore
                align: 'center',
                render: (orderline: OrderlineView) =>
                    <div className="table-actions">
                        <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            type="default"
                            loading={this.state.loading}
                            onClick={() => this.onEdit(orderline)}
                        />

                        {this.props.showCompletions ?
                            <Button
                                shape="circle"
                                icon={<CheckCircleOutlined />}
                                type="default"
                                loading={this.state.loading}
                                onClick={() => this.onCompletion(orderline, orderline.product.id, orderline.quantity)}
                            /> : null}

                        <Popconfirm title="Are you sure to remove this orderline?" onConfirm={() => this.onRemoveComplete(orderline)} okText="Yes" cancelText="No">
                            <Button
                                shape="circle"
                                icon={<DeleteOutlined />}
                                type="default"
                                danger
                                loading={this.state.loading}
                                onClick={(e) => { e.stopPropagation() }}
                            />
                        </Popconfirm>
                    </div>,
                key: 'actions',
                width: 140
            });

            partColumns.push({
                title: 'Actions',
                render: (part: ProductPartView) => {
                    const orderline = _.find(this.props.orderlines, o => { return o.parts.includes(part); });
                    if (orderline.product.type != ProductType.Custom) return null;

                    if (orderline != null) {
                        return (
                            <div className="table-actions">
                                <Button
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    type="default"
                                    loading={this.state.loading}
                                    onClick={() => this.onEditPart(part, orderline, orderline.product)}
                                />

                                {this.props.showCompletions ?
                                    <Button
                                        shape="circle"
                                        icon={<CheckCircleOutlined />}
                                        type="default"
                                        loading={this.state.loading}
                                        onClick={() => this.onCompletion(orderline, part.product.id, part.quantity)}
                                    /> : null}

                                <Button
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    type="default"
                                    danger
                                    loading={this.state.loading}
                                    onClick={() => this.onRemovePart(part, orderline)}
                                />
                            </div>
                        );
                    }
                },
                key: 'actions'
            });
        }

        const partsSubLists = _.map(this.state.expandedOrderlines, (id) => {
            const match = _.find(this.props.orderlines, entity => entity.id === id);
            if (match) {
                const subCollection = _.reject(match.parts, part => part.product.deleted);

                return <ListViewCard
                    subtableTitle={() => {
                        if (match.product.type != ProductType.Custom) return null;

                        return (
                            <div className="subtable-title">
                                <Title level={4} className="subtable-title">Parts</Title>
                                <Button className="action" onClick={() => this.onAddPartDrawer(match.product, match)} icon={<PlusCircleOutlined />}>{this.context.isMobile ? null : "Add"}</Button>
                            </div>
                        );
                    }}
                    columns={partColumns}
                    data={subCollection}
                    loading={this.state.loading}
                    isSubTable
                    className="parts-sub-table"
                    key={id}
                />;
            }
        });

        return (
            <React.Fragment>
                <Drawer
                    title="Add orderline"
                    onClose={this.onCreateDrawerClose}
                    open={this.state.showCreateDrawer}
                    destroyOnClose={true}
                    component={
                        <OrderlineForm
                            onComplete={this.onCreateComplete}
                            onCancel={this.onCreateDrawerClose}
                            orderType={this.props.orderType}
                        />
                    }
                />

                <Drawer
                    title="Edit orderline"
                    onClose={this.onEditDrawerClose}
                    open={this.state.showEditDrawer}
                    destroyOnClose={true}
                    component={
                        <OrderlineForm
                            orderline={this.state.orderline}
                            onComplete={this.onEditComplete}
                            onCancel={this.onEditDrawerClose}
                            orderType={this.props.orderType}
                        />
                    }
                />

                <Drawer
                    title="Completions"
                    onClose={this.onCompletionDrawerClose}
                    open={this.state.showCompletionDrawer}
                    destroyOnClose={true}
                    component={
                        <CompletionCreateForm
                            orderline={this.state.orderline}
                            productId={this.state.productId}
                            onComplete={this.onCompletionComplete}
                            totalQuantity={this.state.partQuantity}
                            order={this.props.order}
                        />
                    }
                />

                <Drawer
                    title="Add part"
                    onClose={this.onAddPartDrawerClose}
                    open={this.state.showPartDrawer}
                    destroyOnClose={true}
                    component={
                        <ProductPartOnOrderlineForm
                            product={this.state.orderlineProduct}
                            customSubmit={this.props.onAddPart ? this.onAddPartComplete : null}
                            onComplete={this.props.onAddPart ? null : this.onAddPartDrawerClose}
                            orderline={this.state.partOrderline}
                            order={this.props.order}
                        />
                    }
                />

                <Drawer
                    title="Update part"
                    onClose={this.onEditPartDrawerClose}
                    open={this.state.part != null}
                    destroyOnClose={true}
                    component={
                        <ProductPartOnOrderlineForm
                            product={this.state.orderlineProduct}
                            part={this.state.part}
                            customSubmit={this.props.onAddPart ? this.onAddPartComplete : null}
                            onComplete={this.props.onAddPart ? null : this.onEditPartDrawerClose}
                            orderline={this.state.partOrderline}
                            order={this.props.order}
                        />
                    }
                />

                <ListViewCard
                    title={this.props.optional ? "Orderlines (optional)" : "Orderlines"}
                    onAdd={this.context.user.hasAnyCapability([Capabilities.OrdersWrite]) ? this.onCreate : null}
                    columns={listColumns}
                    data={this.props.orderlines}
                    className={`orderline-list ${this.props.className ? this.props.className : ''}`}
                    paging={false}
                    onExpand={!minimized ? this.onExpand : null}
                    nestedSubTables={!minimized ? partsSubLists : null}
                    customExpandable={!minimized ? this.orderlineCustomExpandable : null}
                    subtitle={this.props.orderFormSubTitle ? "You can also add orderlines after the order is created." : null}
                />
            </React.Fragment>
        );
    }
}

export default OrderlineList;

