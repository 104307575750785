import * as React from "react";
import { ContactPointType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";

export class ContactPointTypeSelector extends React.Component<EnumSelectorProps> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select contact point type...';

        enum sortedCPEnum { }

        for (const cpType in ContactPointType) {
            if (cpType == ContactPointType.Other) continue;
            sortedCPEnum[cpType] = cpType;
        }

        sortedCPEnum[ContactPointType.Other] = ContactPointType.Other;

        return (
            <EnumSelector
                className="contact-point-type-selector"
                enum={sortedCPEnum}
                placeholder={placeholder}
                {...this.props}
            />
        );
    }
}

export default ContactPointTypeSelector;