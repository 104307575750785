import { MeasurementLevel, MeasurementThreshold, MeasurementType, SensorEventType, SensorEventView, SensorValueType } from "../../ApiClient/swagger/data-contracts";


import {
    MdOutlineTouchApp, MdThermostat, MdOutlineOpacity, MdOutlineMeetingRoom, MdOutlineWaterDrop,
    MdOutlineBolt, MdOutlineSignalCellularAlt, MdOutlineBatteryFull
} from 'react-icons/md';
import { capitalizeFirstLetter } from "../../Helpers/Formatters";
import _ from "lodash";
import { MeasurementThresholdForm } from "./MeasuringPointFormElements";
import moment from "moment";



export const getIconFromDataType = (dataType: SensorEventType, size: number = 24) => {

    const commonIconProps = { size: size };

    switch (dataType) {
        case SensorEventType.Touch:
        case SensorEventType.TouchCount:
            return <MdOutlineTouchApp {...commonIconProps} />;

        case SensorEventType.Temperature:
            return <MdThermostat {...commonIconProps} />;

        case SensorEventType.Humidity:
            return <MdOutlineOpacity  {...commonIconProps} />;

        case SensorEventType.ObjectPresent:
        case SensorEventType.ObjectPresentCount:
            return <MdOutlineMeetingRoom  {...commonIconProps} />;

        case SensorEventType.WaterPresent:
            return <MdOutlineWaterDrop  {...commonIconProps} />;

        case SensorEventType.Voltage:
        case SensorEventType.Watt:
        case SensorEventType.Amperage:
        case SensorEventType.VA:
        case SensorEventType.VAR:
        case SensorEventType.CosPhi:
            return <MdOutlineBolt  {...commonIconProps} />;        

        case SensorEventType.NetworkStatus:
            return <MdOutlineSignalCellularAlt {...commonIconProps} />;

        case SensorEventType.BatteryStatus:
            return <MdOutlineBatteryFull  {...commonIconProps} />;

        default:
            return null;
    }
}


export const getThresholdUnitAndRangeFromType = (type: MeasurementType, threshold: MeasurementThreshold, measurementLevel: MeasurementLevel): string => {

    if (type.eventType === SensorEventType.ObjectPresent && threshold.duration != null)
        return `${measurementLevel}: ${threshold.higherOrEqual === 1 ? `Present longer than ${moment.duration(threshold.duration, 'minutes').asMinutes()}m` : `Not present longer than ${moment.duration(threshold.duration, 'minutes').asMinutes()}m`}`;
    else if (type.eventType === SensorEventType.ObjectPresent && threshold.duration == null)
        return `${measurementLevel}: ${threshold.higherOrEqual === 1 ? "Present" : "Not present"}`;

    if (type.eventType === SensorEventType.WaterPresent && threshold.duration != null)
        return `${measurementLevel}: ${threshold.higherOrEqual === 1 ? `Water detected longer than ${moment.duration(threshold.duration, 'minutes').asMinutes()}m` : `Water undetected longer than ${moment.duration(threshold.duration, 'minutes').asMinutes()}m`}`;
    else if (type.eventType === SensorEventType.WaterPresent && threshold.duration == null)
        return `${measurementLevel}: ${threshold.higherOrEqual === 1 ? "Water detected" : "Water undetected"}`;

    const unit = getThresholdUnitFromType(type);

    const hasHigher = threshold.higherOrEqual != null;
    const hasLower = threshold.lowerOrEqual != null;

    const thresholdRange = threshold
        ? hasLower && hasHigher
            ? `${threshold.lowerOrEqual}${unit ? ` ${unit}` : ''} - ${threshold.higherOrEqual}${unit ? ` ${unit}` : ''}`
            : hasLower
                ? `${threshold.lowerOrEqual}${unit ? ` ${unit}` : ''}`
                : hasHigher
                    ? `${threshold.higherOrEqual}${unit ? ` ${unit}` : ''}`
                    : null
        : null;


    return `${measurementLevel}: ${hasHigher && hasLower ? "Outside range" : hasHigher ? "Higher than" : hasLower ? "Lower than" : ""} (${thresholdRange})`;
}


export const getThresholdUnitFromType = (type: MeasurementType) => {

    let unit = null;
    switch (type.eventType) {
        case SensorEventType.Temperature:
            unit = '\u2103';
            break;
        case SensorEventType.Humidity:
            unit = type.id.includes("temperature") ? '\u2103' : '%';
            break;
        case SensorEventType.BatteryStatus:
            unit = "%";
            break;
        //case SensorEventType.Ppm:
        //    unit = "ppm";
        //    break;
        case SensorEventType.Co2:
            unit = "ppm";
            break;
        case SensorEventType.Pressure:
            unit = "p";
            break;
        case SensorEventType.Voltage:
            unit = "V";
            break;
        case SensorEventType.Watt:
            unit = "W";
            break;
        case SensorEventType.Amperage:
            unit = "A";
            break;
        case SensorEventType.Frequency:
            unit = "Hz";
            break;
        case SensorEventType.VAR:
            unit = "Var";
            break;
        case SensorEventType.VA:
            unit = "VA";
            break;
        default:
            unit = "";
            break;
    }

    return unit;
}

export const mapMeasurementTypeName = (type: MeasurementType) => {
    if (!type || !type.eventType) return type?.id ?? "-";

    if (type.eventType === SensorEventType.Voltage)
        return "Volt";
    else if (type.eventType === SensorEventType.Amperage)
        return "Ampere";
    else if (type.eventType === SensorEventType.Watt)
        return "Watt";

    let text = "";
    const splitByColon = type.id.split(':');
    const splitByUnderscore = type.id.split('_');

    if (splitByColon.length > 1) {
        text = `${splitByColon[0]} (${capitalizeFirstLetter(splitByColon[1])})`;
    }
    else if (splitByUnderscore.length > 1) {
        _.each(splitByUnderscore, s => s ? text += s + " " : "");
    }
    else
        text = type.id;

    return capitalizeFirstLetter(text);
}

export const mapSensorEventTypeName = (eventType: SensorEventType) => {
    if (!eventType) return "";
    if (eventType.length < 5)
        return eventType;
    const splitByUpperCase = eventType.split(/(?=[A-Z])/);
    return capitalizeFirstLetter(splitByUpperCase.join(' ').toLowerCase());
}

export const mapThresholdToRequest = (threshold: MeasurementThresholdForm) => {
    const updatedThreshold: MeasurementThreshold = Object.assign({}, threshold, { duration: null });
    if (threshold.duration == null) return updatedThreshold;

    const timespan = moment.duration(threshold.duration * 60000);
    //@ts-ignore
    updatedThreshold.duration = timespan ? `${timespan.hours() < 10 ? "0" + timespan.hours() : timespan.hours()}:${timespan.minutes() < 10 ? "0" + timespan.minutes() : timespan.minutes()}:${timespan.seconds() < 10 ? "0" + timespan.seconds() : timespan.seconds()}` : null;
    return updatedThreshold;
}

export const mapThresholdToFormValue = (threshold: MeasurementThreshold) => {
    if (threshold == null) return null;

    const updatedThreshold: MeasurementThresholdForm = Object.assign({}, threshold ?? {}, { duration: null });
    if (threshold.duration === null) return updatedThreshold;

    updatedThreshold.duration = moment.duration(threshold.duration, 'minutes').asMinutes();
    return updatedThreshold;
}

export const formatRegistrationValue = (value: any, type: MeasurementType) => {
    if (value == null || !type) return null;

    const unit = getThresholdUnitFromType(type);

    if (type.valueType === SensorValueType.Boolean) {
        let formattedValue = value;
        if (type.eventType === SensorEventType.ObjectPresent)
            formattedValue = value === true ? "Present" : "Not present";
        else if (type.eventType === SensorEventType.WaterPresent)
            formattedValue = value === true ? "Present" : "Not present";
        else if (type.eventType === SensorEventType.DeskOccupancy)
            formattedValue = value === true ? "Occupied" : "Unccupied";
        else if (type.eventType === SensorEventType.Touch)
            formattedValue = value === true ? "Not touched" : "Touched"
        else if (type.eventType === SensorEventType.Toggle)
            formattedValue = value === true ? "Off" : "On";
        else
            formattedValue = value === true ? "Present" : "Not present";

        return `${formattedValue} ${unit}`
    }
    else if (type.valueType === SensorValueType.Integer) {
        return `${value.toFixed(2)} ${unit}`
    }
    else if (type.valueType === SensorValueType.Decimal) {
        if (value > 1000)
            return `${(value /1000).toFixed(2)} K${unit}`;
        if (0 < value && value < 0.1)
            return `${(value*1000).toFixed(2)} m${unit}`;
        return `${value.toFixed(2)} ${unit}`
    }
    else if (type.valueType === SensorValueType.None) {
        return `${value} ${unit}`
    }
    else
        return `${value} ${unit}`
}

export const formatSensorEventRegistrationValue = (event: SensorEventView) => {
    if (!event || !event.eventType || !event.values) return "";

    const values = event.values;

    switch (event.eventType) {
        case SensorEventType.Temperature:
            return `${values.temperature.toFixed(2)} \u2103`;
        case SensorEventType.Humidity:
            return `${values.relativeHumidity.toFixed(2)} % ${values.absoluteHumidity ? "- " + values.absoluteHumidity.toFixed(2) + " g/m3" : ""}`
        case SensorEventType.Touch:
            return "Touched";
        case SensorEventType.TouchCount:
            return values.total;
        case SensorEventType.ObjectPresent:
            return values.objectPresent ? "Present" : "Not present";
        case SensorEventType.ObjectPresentCount:
            return values.total;
        case SensorEventType.WaterPresent:
            return values.waterPresent ? "Water present" : "Water not present";
        case SensorEventType.DeskOccupancy:
            return values.motionDetected ? "Occupied" : "Unoccupied";
        case SensorEventType.Motion:
            return values.motionDetected ? "Motion detected" : "Motion not detected";
        case SensorEventType.Co2:
            return `${values.ppm.toFixed(2)}ppm`;
        case SensorEventType.CosPhi:
            return `${values.value.toFixed(2)}`;
        case SensorEventType.GenericNumeric:
            return `${values.value.toFixed(2)}`;
        case SensorEventType.VAR:
            return `${formatScaledDecimal(values.value)}Var`;
        case SensorEventType.VA:
            return `${formatScaledDecimal(values.value)}VA`;
        case SensorEventType.NetworkStatus:
            return `${values.percentage.toFixed(2)}%`;
        case SensorEventType.BatteryStatus:
            return `${values.percentage.toFixed(2)}%`;    
        case SensorEventType.Pressure:
            return `${formatScaledDecimal(values.pascal)}p`;
        case SensorEventType.Voltage:
            return `${formatScaledDecimal(values.value)}V`;
        case SensorEventType.Amperage:
            return `${formatScaledDecimal(values.value)}A`;
        case SensorEventType.Watt:
            return `${formatScaledDecimal(values.value)}W`;
        case SensorEventType.Frequency:
            return `${formatScaledDecimal(values.value)}Hz`;
        case SensorEventType.Toggle:
            return values.value ? 'On' : 'Off';
        
        
        default:
            return null;

    }
    
}

export const formatScaledDecimal = (value: any) => {
    if (value > 1000)
        return `${(value/1000).toFixed(2)}K`;
    if (value >0 && value < 0.1)
        return `${(value*1000).toFixed(2)}m`;
    return `${value.toFixed(2)}`
}