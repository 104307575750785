/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ApplicationSettingFieldsView,
  ApplicationSortOrder,
  ApplicationView,
  ApplicationViewEntityViewModel,
  ApplicationViewPagedApplicationQueryCollectionViewModel,
  CreateApplication,
  SortDirection,
  UpdateApplication,
  UpdateClientSecret,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Applications<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Application
   * @name GetApplicationById
   * @request GET:/api/applications/{id}
   * @secure
   */
  getApplicationById = (id: string, params: RequestParams = {}) =>
    this.http.request<ApplicationViewEntityViewModel, any>({
      path: `/api/applications/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name UpdateApplication
   * @request PUT:/api/applications/{id}
   * @secure
   */
  updateApplication = (id: string, data: UpdateApplication, params: RequestParams = {}) =>
    this.http.request<ApplicationView, any>({
      path: `/api/applications/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name DeleteApplication
   * @request DELETE:/api/applications/{id}
   * @secure
   */
  deleteApplication = (id: string, params: RequestParams = {}) =>
    this.http.request<ApplicationView, any>({
      path: `/api/applications/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name CreateApplication
   * @request POST:/api/applications
   * @secure
   */
  createApplication = (data: CreateApplication, params: RequestParams = {}) =>
    this.http.request<ApplicationView, any>({
      path: `/api/applications`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name QueryApplications
   * @request GET:/api/applications
   * @secure
   */
  queryApplications = (
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      sortBy?: ApplicationSortOrder;
      sortDirection?: SortDirection;
      tenantId?: string;
      eligibleForRoles?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ApplicationViewPagedApplicationQueryCollectionViewModel, any>({
      path: `/api/applications`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name RestoreApplication
   * @request POST:/api/applications/{id}/restore
   * @secure
   */
  restoreApplication = (id: string, params: RequestParams = {}) =>
    this.http.request<ApplicationView, any>({
      path: `/api/applications/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name UpdateApplicationAttributes
   * @request POST:/api/applications/{id}/attributes
   * @secure
   */
  updateApplicationAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ApplicationView, any>({
      path: `/api/applications/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name UpdateApplicationSecret
   * @request POST:/api/applications/{id}/secret
   * @secure
   */
  updateApplicationSecret = (id: string, data: UpdateClientSecret, params: RequestParams = {}) =>
    this.http.request<ApplicationView, any>({
      path: `/api/applications/${id}/secret`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name GetApplicationSettings
   * @request GET:/api/applications/{id}/settings
   * @secure
   */
  getApplicationSettings = (id: string, params: RequestParams = {}) =>
    this.http.request<ApplicationSettingFieldsView, any>({
      path: `/api/applications/${id}/settings`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Application
   * @name SetApplicationSettings
   * @request POST:/api/applications/{id}/settings
   * @secure
   */
  setApplicationSettings = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ApplicationSettingFieldsView, any>({
      path: `/api/applications/${id}/settings`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
