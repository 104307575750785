import * as React from "react";
import _ from 'lodash';
import { Select, Radio } from 'antd';
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import { Keyboard } from "../../Definitions/_keyboard";
const { Option } = Select;

interface BaseFilterSelectorState {
    value: any;
    isFocused: boolean;
}

class EnumSelector extends React.Component<EnumSelectorProps, BaseFilterSelectorState> {
    constructor(props: any) {
        super(props);

        this.state = {
            value: undefined,
            isFocused: false
        };
    }

    componentDidMount = () => {
        document.addEventListener("keydown", this.handleKeyDown);
        if (this.props.value)
            this.setState({ value: this.props.value });
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (e, fromClick?: boolean) => {
        if (e.keyCode == Keyboard.DELETE) {
            if (this.state.isFocused) {
                this.setState({ value: undefined });

                if (this.props.onChange)
                    this.props.onChange(undefined);
            }
        }
    }

    handleChange = (change) => {
        let value = null;

        if (this.props.type == "radio") {
            value = change.target.value;
        } else {
            value = change || null;
        }

        this.setState({ value: value });

        this.props.onChange(value);
    }

    handleClick = (event) => {
        if (this.props.type == "radio" && this.props.onToggle) {
            if (event.target.value == this.state.value) this.props.onToggle(event.target.value)
        }
    }

    handleFocus = () => {
        this.setState({ isFocused: true });
    }

    handleBlur = () => {
        this.setState({ isFocused: false });
    }

    render = () => {
        const options = [];

        if (!this.props.multiple && this.props.type != "radio" && !this.props.excludeAllOption)
            options.push(<Option key="All" value={null}>All</Option>)

        _.forEach(this.props.enum, (value) => {
            const isOption = this.props.optionIds == null || (this.props.optionIds != null && _.find(this.props.optionIds, id => id === value) != null);
            if (!isOption) return;

            if (this.props.excluded && this.props.excluded.length > 0 && _.indexOf(this.props.excluded, value) !== -1)
                return;

            const disabled = this.props.disableOptionIds != null && _.find(this.props.disableOptionIds, id => id === value) != null;

            switch (this.props.type) {
                case "radio":
                    options.push(<Radio.Button disabled={disabled} key={Math.random()} onClick={this.handleClick} value={value}>{this.props.renderOptionItem ? this.props.renderOptionItem(value) : value}</Radio.Button>);
                    break;
                default:

                    if (this.props.renderOptionItem)
                        options.push(<Option disabled={disabled} key={value} value={value} label={this.props.renderLabelItem ? this.props.renderLabelItem(value) : value}>{this.props.renderOptionItem(value)}</Option>);
                    else
                        options.push(<Option disabled={disabled} key={value} value={value} label={value}>{value}</Option>);
            }
        });

        let title = this.props.title ? <label>{this.props.title}</label> : null;

        if (this.props.wrappedFormTitle)
            title = <div className="ant-form-item-label"><label>{this.props.title}</label></div>;

        const variableProps = {
            value: this.state.value ? this.state.value : undefined
        };

        let element = null;
        switch (this.props.type) {
            case "radio":
                element = <Radio.Group size="large" key={Math.random()} onChange={this.handleChange} buttonStyle="solid" {...variableProps} disabled={this.props.disabled}>
                    {options}
                </Radio.Group>
                break;
            default:
                element = <Select
                    optionLabelProp="label"
                    mode={this.props.multiple ? 'multiple' : null}
                    placeholder={this.props.placeholder}
                    size={this.props.size ?? "middle"}
                    allowClear={this.props.disableClear ? false : true}
                    onChange={this.handleChange}
                    className="select"
                    disabled={this.props.disabled}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    {...variableProps}
                >
                    {options}
                </Select>
        }

        return (
            <div className={`selector enum-selector ${this.props.className}`}>
                {title}
                {element}
            </div>
        );
    }
}

export default EnumSelector;