import * as React from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import client from "../../ApiClient/client";
import { Popover, Spin, Card, Avatar } from 'antd';
import { ToolOutlined } from "@ant-design/icons";
import AppContext from "../../Definitions/AppContext";
import { ProductLinkView, ProductType, ProductView } from "../../ApiClient/swagger/data-contracts";
import { Capabilities } from "../../Definitions/_capabilties";
import { useContext, useEffect, useState } from "react";

const { Meta } = Card;

interface ProductLinkCardProps {
    productId: string
}

interface ProductLinkCardState {
    product?: ProductView,
    error?: Error,
    avatarUrl: string,
}



class ProductLinkCard extends React.Component<ProductLinkCardProps, ProductLinkCardState> {
    _isMounted = false;

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
        this.state = {
            product: null,
            error: null,
            avatarUrl: null
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.loadProduct();

        this.context.events.products.onMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentWillUnmount = () => {
        this._isMounted = false;

        this.context.events.people.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
        this.context.events.organizations.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.productId !== this.props.productId) {
            this.loadProduct()
        }
    }

    onItemUpdatedEvent = (eventData: ProductView) => {
        if (this.state.product && this.state.product.id === eventData.id) {
            if (this.state.product.changeVector == eventData.changeVector) {
                return;
            }
            this.setState({
                product: eventData
            });
        }
    }

    loadProduct = async () => {
        try {
            const product = await client.products.getProductById(this.props.productId);

            if (product && this._isMounted) {
                if (product.data.view && product.data.view.image)
                    this.setState({ avatarUrl: product.data.links.avatar?.url ?? null });

                this.setState({ product: product.data.view })
            }
        } catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    render = () => {
        if (this.state.error)
            return this.state.error.message;

        if (!this.state.product)
            return <Spin size="large" />;

        const fallbackAvatar = <Avatar shape="square" size={100} icon={<ToolOutlined />} className="avatar" />;
        const avatar = this.state.avatarUrl ? <Avatar src={this.state.avatarUrl} size={100} shape="square" alt="avatar" /> : fallbackAvatar;

        const { product } = this.state;

        const details = (
            <dl>
                {product.description ? <div className="description"><dt>Description</dt><dd>{product.description}</dd></div> : null}
                {product.type ? <div className="type"><dt>Description</dt><dd>{product.type}</dd></div> : null}
                {/*{product.category ? <div className="category"><dt>Category</dt><dd><CategoryLink {...product.category} /></dd></div> : null}*/}

            </dl>
        );

        return (
            <Card className="product-link-card" bordered={false} size="small" title={this.state.product.name}>
                <Meta avatar={avatar} description={
                    <React.Fragment>
                        {details}
                    </React.Fragment>
                }
                />
            </Card>
        );
    }
}

interface ProductLinkProps extends ProductLinkView {
    disablePopover?: boolean
}

export function ProductLink(props: ProductLinkProps){
    const [product, setProduct] = useState<ProductView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.products.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.products.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    useEffect(() => {
        setProduct(props);
    }, [props]);

    function onItemUpdatedEvent(eventData: ProductView) {
        if (product && product.id === eventData.id) {
            setProduct(eventData);
        }
    }

    if (!product.name) return null;

    if (!context.user.hasCapability(Capabilities.ProductsRead))
        return <Popover content="You do not have access to read products.">{product.name}</Popover>;

    let className = ""; //"no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (product.deleted)
        className += " deleted";

    const link = <Link
        //style={this.props.disablePopover ? { color: 'unset' } : null}
        to={`/products/${product.id}`}
        className={className}>
        {product.name}
    </Link>;

    if (props.disablePopover)
        return link;

    return (
        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
            <Popover placement="bottomLeft" content={<ProductLinkCard productId={product.id} />}>
                {link}
            </Popover>
        </span>
    );
}
