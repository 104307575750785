import * as React from "react";
import { Form } from "antd";
import { NamePath } from "antd/es/form/interface";


interface SelectorInputProps extends GeneralSelectorProps {
    customValidator?: (rule: any, value: any) => any;
    selector: JSX.Element;
    shouldUpdate?: boolean;
    optional?: boolean;
}

export interface GeneralSelectorProps {
    required?: boolean;
    warningMessage?: string;
    param: NamePath;
    title?: string | React.ReactNode;
    className?: string;
    titleLink?: React.ReactNode;
}

export class SelectorInput extends React.Component<SelectorInputProps> {

    onValidate = async (rule, value) => {
        if (!this.props.required)
            return Promise.resolve();

        if (this.props.customValidator)
            return this.props.customValidator(rule, value);

        if (value)
            return Promise.resolve();
        else
            return Promise.reject(this.props.warningMessage);
    }

    render = () => {
        const { param, selector, required, warningMessage } = this.props;
        const optional = this.props.optional == false ? null : !this.props.required ? " (optional)" : "";
        const title = this.props.title ? <div className="selector-label-container"><span className="text-title">{this.props.title}{optional}</span>{this.props.titleLink ? this.props.titleLink : null}</div> : null;

        return (
            <Form.Item
                label={title}
                name={param}
                className={`selector-input ${this.props.className ?? ""}`}
                shouldUpdate={this.props.shouldUpdate ? this.props.shouldUpdate : false}
                rules={
                    [{
                        required: required,
                        validator: this.onValidate,
                        message: warningMessage ? warningMessage : 'This field is required'
                    }]}
            >
                {selector}
            </Form.Item>
        );
    }
}