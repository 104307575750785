/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateProductCategory,
  ProductCategorySortOption,
  ProductCategoryView,
  ProductCategoryViewEntityViewModel,
  ProductCategoryViewPagedProductCategoryQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Productcategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ProductCategory
   * @name GetProductCategoryById
   * @request GET:/api/productcategories/{id}
   * @secure
   */
  getProductCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<ProductCategoryViewEntityViewModel, any>({
      path: `/api/productcategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductCategory
   * @name UpdateProductCategory
   * @request PUT:/api/productcategories/{id}
   * @secure
   */
  updateProductCategory = (id: string, data: CreateOrUpdateProductCategory, params: RequestParams = {}) =>
    this.http.request<ProductCategoryView, any>({
      path: `/api/productcategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductCategory
   * @name DeleteProductCategory
   * @request DELETE:/api/productcategories/{id}
   * @secure
   */
  deleteProductCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<ProductCategoryView, any>({
      path: `/api/productcategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductCategory
   * @name CreateProductCategory
   * @request POST:/api/productcategories
   * @secure
   */
  createProductCategory = (data: CreateOrUpdateProductCategory, params: RequestParams = {}) =>
    this.http.request<ProductCategoryView, any>({
      path: `/api/productcategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductCategory
   * @name QueryProductCategories
   * @request GET:/api/productcategories
   * @secure
   */
  queryProductCategories = (
    query?: {
      sortBy?: ProductCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductCategoryViewPagedProductCategoryQueryCollectionViewModel, any>({
      path: `/api/productcategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductCategory
   * @name RestoreProductCategory
   * @request POST:/api/productcategories/{id}/restore
   * @secure
   */
  restoreProductCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<ProductCategoryView, any>({
      path: `/api/productcategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductCategory
   * @name UpdateProductCategoryAttributes
   * @request POST:/api/productcategories/{id}/attributes
   * @secure
   */
  updateProductCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ProductCategoryView, any>({
      path: `/api/productcategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
