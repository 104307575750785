import * as React from "react";
import _ from 'lodash';
import { Card, Empty, Typography } from 'antd'
import * as Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportdata from 'highcharts/modules/export-data';
import boost from 'highcharts/modules/boost';
import { Loading3QuartersOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { ValueType } from "../../Models/ValueType";
import { formatCurrency, formatDuration, roundToNearestHour } from "../../Helpers/Formatters";

const { Title } = Typography;

exporting(Highcharts);
exportdata(Highcharts);
boost(Highcharts);

interface HeatMapProps extends HighchartsReact.Props {
    xField?: string;
    yField?: string;
    valueField?: string;
    groupField?: string;
    data: any;
    title?: JSX.Element | string;
    height?: number;
    loading?: boolean;
    className?: string;
    cardClassName?: string;
    valueBy: ValueType;
    actions?: React.ReactNode;
    xAxisFormatter?: (value) => string;
    yAxisFormatter?: (value) => string;
    seriesFormatter?: (value) => string;
    yRoute?: string;
    xRoute?: string;
    useAccordion?: boolean;
    accordionDefaultOpen?: boolean;
    xAxisTotalSumsLabelRotation?: number;
    showBorders?: boolean;
    disableColors?: boolean;
    disableLegend?: boolean;
}

interface HeatMapState {
    displayContent: boolean;
}

export class HeatMap extends React.Component<HeatMapProps> {
    render = () => {
        const xField = this.props.xField ? this.props.xField : "label";
        const yField = this.props.yField ? this.props.yField : "label";
        const valueField = this.props.valueField ? this.props.valueField : "sum";
        const groupField = this.props.groupField ? this.props.groupField : "drilldown";

        let xAxisCategories: any = [];
        let yAxisCategories: any = [];

        _.each(this.props.data, (d) => {
            yAxisCategories.push(d[yField]);

            _.each(d[groupField], w => {
                if (!xAxisCategories.includes(w[xField]))
                    xAxisCategories.push(w[xField]);
            });
        });

        xAxisCategories = _.sortBy(xAxisCategories, x => x);
        yAxisCategories = _.sortBy(yAxisCategories, y => y);

        const data = [];

        _.each(yAxisCategories, (yCategory, yIndex) => {
            const yData = _.find(this.props.data, y => y[yField] === yCategory)[groupField];
            _.each(xAxisCategories, (xCategory, xIndex) => {
                const xData = _.find(yData, x => x[xField] === xCategory);
                const value = xData ? xData[valueField] || 0 : 0;

                let yId = null;
                let xId = null;

                _.each(this.props.data, (y, yIdKey) => {
                    if (y[xField] == yCategory)
                        yId = yIdKey;

                    _.each(y[groupField] || [], (x, xIdKey) => {
                        if (x[yField] == xCategory)
                            xId = xIdKey;
                    });
                });

                data.push({
                    //id: xIndex,
                    name: xCategory,
                    x: xIndex,
                    y: yIndex,
                    value: value,
                    custom: {
                        yName: yCategory,
                        yId: yId,
                        //xName: xCategory,
                        xId: xId
                    }
                })
            });
        });

        const yAxisTotalSums = [];

        _.each(yAxisCategories, yCategory => {
            const matches = _.filter(data, d => d.custom?.yName == yCategory);
            const totalSum = _.sumBy(matches, match => match.value);

            if (this.props.valueBy == ValueType.Duration)
                yAxisTotalSums.push(formatDuration(totalSum));
            else if (this.props.valueBy == ValueType.Price)
                yAxisTotalSums.push(formatCurrency(totalSum));
            else
                yAxisTotalSums.push(totalSum);
        });

        const xAxisTotalSums = [];

        _.each(xAxisCategories, xCategory => {
            const matches = _.filter(data, d => d.name == xCategory);
            const totalSum = _.sumBy(matches, match => match.value);

            if (this.props.valueBy == ValueType.Duration)
                xAxisTotalSums.push(formatDuration(totalSum));
            else if (this.props.valueBy == ValueType.Price)
                xAxisTotalSums.push(formatCurrency(totalSum));
            else
                xAxisTotalSums.push(totalSum);
        });

        const self = this;
        const optionProps = this.props;

        const options: Highcharts.Options = {
            credits: {
                enabled: false
            },
            chart: {
                type: 'heatmap',
                backgroundColor: null,
                height: this.props.height ? this.props.height : (yAxisCategories.length * 30) + 130,
                width: null,
                events: {
                    beforePrint: function () {
                        //@ts-ignore
                        this.oldhasUserSize = this.hasUserSize;
                        //@ts-ignore
                        this.resetParams = [this.chartWidth, this.chartHeight, false];
                        //@ts-ignore
                        this.setSize(500, 400, false);
                    },
                    afterPrint: function () {
                        //@ts-ignore
                        this.setSize.apply(this, this.resetParams);
                        //@ts-ignore
                        this.hasUserSize = this.oldhasUserSize;
                    }
                }
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "separator",
                            "downloadPNG",
                            "downloadJPEG",
                            "downloadPDF",
                            "downloadSVG",
                            "separator",
                            "downloadCSV",
                            "downloadXLS"
                        ]
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: [
                {
                    min: 0,
                    max: xAxisCategories.length - 1,
                    //linkedTo: 0,
                    opposite: true,
                    categories: xAxisCategories,
                    labels: {
                        useHTML: optionProps.xRoute ? true : false,
                        formatter: function () {
                            if (optionProps.xAxisFormatter)
                                return optionProps.xAxisFormatter(this.value);
                            else {
                                if (optionProps.xRoute) {
                                    //@ts-ignore
                                    const points = this.chart.userOptions.series[0]?.data || [];

                                    const matches = _.filter(points, point => { return point.name == this.value.toString(); });
                                    let nameMatch = null;

                                    for (let i = 0; i <= matches.length; i++) {
                                        const match = matches[i];
                                        const pos = _.indexOf(points, match);
                                        const posMatch = pos == this.pos;

                                        if (posMatch) {
                                            nameMatch = match;
                                            break;
                                        }
                                    }

                                    const position = nameMatch ? _.indexOf(points, nameMatch) : -1;
                                    const positionMatch = position == this.pos;

                                    if (nameMatch && positionMatch && nameMatch.custom?.xId) {
                                        return `<a className="no-break list-ellipsis" href="/${optionProps.xRoute}/${nameMatch.custom.xId}">${this.value.toString()}</a>`;
                                    }
                                    else
                                        return this.value.toString();
                                }
                                else
                                    return this.value.toString();
                            }
                        }
                    }
                },
                {
                    linkedTo: 0,
                    categories: xAxisTotalSums,
                    labels: {
                        rotation: self.props.xAxisTotalSumsLabelRotation ?? 0,
                        autoRotationLimit: 0,
                    }
                },
            ],
            yAxis: [
                {
                    min: 0,
                    max: yAxisCategories.length - 1,
                    categories: yAxisCategories,
                    title: null,
                    reversed: true,
                    labels: {
                        useHTML: optionProps.yRoute ? true : false,
                        formatter: function () {
                            if (optionProps.yAxisFormatter)
                                return optionProps.yAxisFormatter(this.value);
                            else {
                                if (optionProps.yRoute) {
                                    //@ts-ignore
                                    const points = this.chart.userOptions.series[0]?.data || [];
                                    const categoriesLength = this.axis.categories.length;
                                    const dataPerYItem = points.length / categoriesLength;

                                    const nameMatch = _.find(points, point => { return point.custom?.yName == this.value.toString(); });
                                    const position = nameMatch ? _.indexOf(points, nameMatch) : -1;
                                    const positionMatch = position == (this.pos * dataPerYItem);

                                    if (nameMatch && positionMatch && nameMatch.custom?.yId) {
                                        return `<a className="no-break list-ellipsis" href="/${optionProps.yRoute}/${nameMatch.custom.yId}">${this.value.toString()}</a>`;
                                    }
                                    else
                                        return this.value.toString();
                                }
                                else
                                    return this.value.toString();
                            }
                        }
                    }
                },
                {
                    linkedTo: 0,
                    opposite: true,
                    categories: yAxisTotalSums,
                    title: null
                }
            ],
            colorAxis: {
                min: 0,
                minColor: '#FFFFFF',
                maxColor: this.props.disableColors ? '#FFFFFF' : '#78B6E3',
                labels: {
                    formatter: function () {
                        if (self.props.valueBy == ValueType.Duration)
                            return roundToNearestHour(this.value);
                        else if (self.props.valueBy == ValueType.Price)
                            return formatCurrency(this.value);
                        else
                            return this.value.toString();
                    }
                }
            },
            boost: {
                useGPUTranslations: true,
                // Chart-level boost when there are more than 5 series in the chart
                seriesThreshold: 5
            },
            series: [
                {
                    data: data,
                    type: "heatmap",
                    turboThreshold: data.length + 10,
                    boostThreshold: 1,
                    borderColor: '#e5e5e5',
                    borderWidth: self.props.showBorders ? 1 : 0,
                    dataLabels: {
                        enabled: true,
                        color: '#000000',
                        formatter: function () {
                            if (self.props.seriesFormatter)
                                return self.props.seriesFormatter(this.point.value);
                            else if (self.props.valueBy == ValueType.Duration)
                                return this.point.value > 0 ? formatDuration(this.point.value) : '-';
                            else if (self.props.valueBy == ValueType.Price)
                                return formatCurrency(this.point.value);
                            else
                                return this.point.value;
                        }
                    }
                }
            ],
            tooltip: {
                formatter: function () {
                    if (self.props.valueBy == ValueType.Duration)
                        return `<b>Duration: </b>${formatDuration(this.point.value)}`;
                    else if (self.props.valueBy == ValueType.Price)
                        return `<b>Price: </b>${formatCurrency(this.point.value)}`;
                    else if (self.props.valueBy == ValueType.Count)
                        return `<b>Count: </b>${this.point.value}`;
                    else
                        return `<b>Value: </b>${this.point.value}`;
                }
            },
            legend: {
                enabled: this.props.disableLegend ? false : true
            }
        };

        const customClass = this.props.className ? this.props.className : "";

        if (window.matchMedia) {
            const mediaQueryList = window.matchMedia('print');
            mediaQueryList.addListener(function (mql) {
                for (let i = 0; i < Highcharts.charts.length; i++) {
                    if (Highcharts.charts[i] !== undefined) {
                        Highcharts.charts[i].reflow();
                    }
                }
            });
        }
        
        return (
            <HighchartsReact
                highcharts={Highcharts}
                containerProps={{
                    style: {
                        textAlign: "-webkit-center"
                    },
                    className: `chart heatmap ${customClass}`
                }}
                options={options}
            />
        );
    }
}

export class HeatMapCard extends React.Component<HeatMapProps, HeatMapState> {
    constructor(props) {
        super(props);
        this.state = {
            displayContent: this.props.useAccordion ? this.props.accordionDefaultOpen ?? false : true
        }
    }

    toggleContent = () => {
        this.setState({ displayContent: !this.state.displayContent });
    }

    render = () => {
        if (this.props.data === undefined) return null;

        //var cardProps = {};
        //if (!this.state.displayContent)
        //    cardProps["data-html2canvas-ignore"] = "true";

        return (
            <Card
                title={
                    <div className="list-title">
                        {typeof this.props.title == "string"
                            ? <Title level={4} className="title" style={this.props.useAccordion ? { cursor: 'pointer' } : {}} onClick={this.props.useAccordion ? this.toggleContent : null}> {this.props.useAccordion ? <span className="expander">{this.state.displayContent ? <UpOutlined /> : <DownOutlined />}</span> : null} {this.props.title}</Title>
                            : this.props.title}
                        {this.props.actions && this.state.displayContent ? this.props.actions : null}
                    </div>
                }
                className={`graph-card heatmap-card ${this.props.cardClassName} ${this.state.displayContent ? "" : "pdf-ignore"}`}
            >
                {
                    this.state.displayContent
                        ? this.props.loading
                            ? <Loading3QuartersOutlined spin className="icon icon-loading" />
                            : Object.keys(this.props.data || {}).length > 0
                                ? <HeatMap {...this.props} />
                                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        : null
                }
            </Card>
        );
    }
}