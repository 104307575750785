import { Card, Popover } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { PersonView, PrincipalType, PrincipalView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { ApplicationLinkCard } from "../Applications";
import { PersonLinkCard } from "../People";
import { RequireCapability } from "./RequireCapability";

export interface LastModifiedViewProps {

    /** @format date-time */
    created?: string | null;
    createdBy?: PrincipalView;

    /** @format date-time */
    lastModified?: string | null;
    lastModifiedBy?: PrincipalView;

    deleted?: boolean;
}



export function LastModifiedPersonPopup(props:{ id: string, children: any }) {

    //return <span className="popup person">{props.children}</span>
    return (
        <span className="popup person">
            <Popover
                zIndex={1051}
                placement="bottomLeft"
                content={<PersonLinkCard id={props.id} />}>
                {props.children}
            </Popover>
        </span>);
}

export function LastModifiedApplicationPopup(props: { id: string, children: any }) {


    return (<span className="popup person">
            <Popover
                zIndex={1051}
                placement="bottomLeft"
                //content={<ApplicationLinkCard id={props.id} />}>
                content={<PersonLinkCard id={props.id} />}>
                {props.children}
            </Popover>
        </span>);
}

export function LastModifiedSystemPopup(props: { children: any }) {

    return (<span className="popup system">
        <Popover
            zIndex={1051}
            placement="bottomLeft"
            content={<span>This was last modified by system</span>}>
            {props.children}
        </Popover>
    </span>


    );
}



export default function LastModifiedView(props:LastModifiedViewProps) {

    const context = useContext(AppContext);

    let date: string | null,
        principal: PrincipalView;

    if (props.lastModified) {
        date = props.lastModified;
        principal = props.lastModifiedBy;
    }
    else if (props.created) {
        date = props.created;
        principal = props.createdBy;
    }
    else {
        return props.deleted ? <span style={{ textDecoration: 'line-through' }}>Never</span> : <span>Never</span>;
    }

    const timestamp = moment(date).format('DD.MM.YYYY HH:mm')
    const text = props.deleted ? <span style={{ textDecoration: 'line-through' }}>{timestamp}</span> : <span>{timestamp}</span>; 

    if (principal) {
        if (principal.type == PrincipalType.System)
            return <LastModifiedSystemPopup>{text}</LastModifiedSystemPopup>
            //return <span>{text} This was last modified by system</span>

        if (principal.type == PrincipalType.Application) {
            if (context.user.hasCapability(Capabilities.ApplicationsRead)) {
                return <LastModifiedApplicationPopup id={principal.id}>{text} </LastModifiedApplicationPopup>;
            }
            else {
                return text;
            }
        }

        if (principal.type == PrincipalType.User)
            if (context.user.hasCapability(Capabilities.PeopleRead)) {
                return <LastModifiedPersonPopup id={principal.id}>{text} </LastModifiedPersonPopup>;
            }
            else {
                return text;
            }
    }

    return text;
}