export enum TagIds {
    Invoiceable = "93065bc5-21cd-4d7a-a782-7e588e736f41"
}

export enum ActorIds {
    System = "system"
}
export enum FolderIds {
    //SharedFolder = "b8037d9b-bb05-4904-acf0-4917f496527f",
    //ImageFolder = "0d76240f-1d13-4c3b-8268-55c6f8467194",
    FakeDocumentFolderId = "11111111-1111-1111-1111-11111111"
}

export enum CategoryIds {
    RelationStatus = "bab52342-a66c-400d-882d-f38ffc9d13cb",
    IncidentCauses = "bf0f3a5b-024e-44c5-a5a9-761dcb7f6abd",
    IncidentSubCategories = "8a175a84-0d7d-44fd-81a9-5d60fb494d2e",
    RoutineCategory = "0edaad94-e85e-4352-8f44-fbecef91ff45",
    LeadWonCauses = "2fb8d72f-0c8a-4a27-a743-aa6530e0ab23",
    LeadLostCauses = "809222c1-8e1a-4f07-ae97-6210a4bd5540",
    LeadCancelledCauses = "e4be64d2-1ed3-4b6c-829b-e08e4aceb4a4",
    FileManagement = "534e6611-7061-4347-b514-4e8a832ba346"
}

export enum DashboardIds {
    Standard = "71849a8f-baba-4756-873e-f21b56597f82"

}
export enum ProductIds {
    Project = "26d07cf5-dfef-4a43-b81e-4e267810d34a"
}

export enum NewsChannelIds {
    Overviu = "action:internal",
    OverviuRSS = "action:public"
}
export enum AttributeKeys {
    //RequirePriceUpdate = "requirePriceUpdate"
}
export enum ApplicationIds {
    Overviu = "4021aa2d-ecd7-4ff1-a423-4718b5ea405a"
}

export enum MenuGroupIds {
    Access = "access",
    Interface = "interface",
    Categories = "categories",
    Overview = "overview",
    Network = "network",
    Management = "management",
    Sales = "sales",
    Documents = "documents",
    Sensors = "monitoring",
    Reports = "reports",
    Links = "links",
    Configuration = "configuration"
}


export enum CoreAttributeKeys {
    RootFolderId = "rootFolderId"
}

export const sessionStorageKey = "NavigationKeys"
export const dashboardSessionStorageKey = "ActiveDashboardId"
