import * as React from "react";
import client from "../../ApiClient/client";
import { ApplicationView } from "../../ApiClient/swagger/data-contracts";
import { EntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";
import SelectorInformation from "../Shared/SelectorInformation";


export class ApplicationSelector extends React.Component<EntitySelectorProps> {
    renderOptionItem = (app: ApplicationView) => app.description ? <SelectorInformation displayText={app.name} hoverText={app.description} /> : app.name;

    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select application...';
        const source = this.props.source ? this.props.source : client.applications.queryApplications;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.applications.getApplicationById;

        return (
            <EntitySelector
                className="application-selector"
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                renderOptionItem={this.renderOptionItem}
                {...this.props}
            />
        );
    }
}

export default ApplicationSelector;