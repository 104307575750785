import * as React from "react";
import { ActorType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";


class ActorTypeSelector extends React.Component<EnumSelectorProps> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select actor type...';

        return (
            <EnumSelector
                className="actor-type-selector"
                enum={ActorType}
                placeholder={placeholder}
                {...this.props}
            />
        );
    }
}

export default ActorTypeSelector;