import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Card, Button } from 'antd';
import Title from "antd/lib/typography/Title";
import { EditOutlined } from "@ant-design/icons";
import { PrincipalType, ProjectRoleType, ProjectType, ProjectView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { TagLink } from "../Tags";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorLink } from "../Actors";
import { formatCurrency, formatDuration } from "../../Helpers/Formatters";
import { Drawer } from "../Shared/Drawer";
import ProjectEditForm from "./ProjectEditForm";
import { PersonLink } from "../People/PersonLink";
import { ApplicationLink } from "../Applications/ApplicationLink";


interface ProjectInfoCardProps {
    project: ProjectView;
    className?: string;
    onEditComplete: (project: ProjectView) => void;
}

interface ProjectInfoCardState {
    showEditDrawer: boolean
}

export class ProjectInfoCard extends React.Component<ProjectInfoCardProps, ProjectInfoCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showEditDrawer: false
        }
    }

    toggleEditDrawer = () => {
        this.setState({
            showEditDrawer: !this.state.showEditDrawer
        });
    }

    render() {
        if (!this.props.project) return null;

        const { description, type, plannedStart, plannedEnd, price, discount, tags, customer, invoice, name, estimate, orderCategory, roles, contacts } = this.props.project;

        const tagView = _.map(tags, tag => {
            return <TagLink key={tag.id} {...tag} />;
        });

        let plannedPeriod = plannedStart ? moment(plannedStart).format("DD.MM.YYYY") : null;
        if (plannedEnd) {
            const endDate = moment(plannedEnd).format("DD.MM.YYYY")
            plannedPeriod = plannedPeriod != null ? plannedPeriod + " - " + endDate : endDate;
        }

        const { user } = this.context;
        const userRole = _.find(roles ?? [], r => r.actor.id == user.actorId);
        const hasFullProjectAccess = user.hasAnyCapability([Capabilities.ProjectsWrite]) || (user.hasCapability(Capabilities.ProjectsRoleBased) && (userRole?.role == ProjectRoleType.Leader || userRole?.role == ProjectRoleType.Manager))

        const contactViews = _.map(contacts ?? [], c => <ActorLink {...c} />);

        return (
            <>
                <Card
                    key="project-info-card"
                    title={
                        <>
                            <Title level={4} className="title">Project details</Title>
                            {
                                hasFullProjectAccess ?
                                    <Button type="link" className="action" onClick={this.toggleEditDrawer} icon={<EditOutlined />}>Edit</Button>
                                    : null
                            }
                        </>
                    }
                    className={`project-info-card info-card ${this.props.className ? this.props.className : ''}`}
                >
                    <dl>
                        <div className="description">
                            <dt>{name}</dt>
                            <dd>{description ?? "-"}</dd>
                        </div>

                        <div>
                            <dt>Customer</dt>
                            <dd>{customer ? <ActorLink {...customer} /> : '-'}</dd>
                        </div>

                        <div>
                            <dt>Contacts</dt>
                            <dd>
                                {contactViews && contactViews.length > 0
                                    //@ts-ignore
                                    ? _.reduce(contactViews, (prev: any, curr: any) => [prev, ", ", curr])
                                    : "-"
                                }
                            </dd>
                        </div>

                        {invoice ? <div>
                            <dt>Invoice</dt>
                            <dd>{invoice ? <ActorLink {...invoice} /> : '-'}</dd>
                        </div> : null}

                        <div>
                            <dt>Project type</dt>
                            <dd>{type}</dd>
                        </div>

                        {type == ProjectType.Fixed ? <div><dt>Price</dt><dd>{price ? formatCurrency(price, 2) : '-'}</dd></div> : null}
                        {type == ProjectType.Hourly ? <div><dt>Discount</dt><dd>{discount ? `${discount}%` : '-'}</dd></div> : null}

                        <div>
                            <dt>Planned project period</dt>
                            <dd>{plannedPeriod ?? "-"}</dd>
                        </div>

                        <div>
                            <dt>Project estimate</dt>
                            <dd>{estimate ? formatDuration(estimate) : "-"}</dd>
                        </div>

                        {type == ProjectType.Fixed || type == ProjectType.Hourly ?
                            <div>
                                <dt>Order category</dt>
                                <dd>{orderCategory != null ? orderCategory.name : "-"}</dd>
                            </div> : null}

                        <div>
                            <dt>Tags</dt>
                            <dd>{tagView && tagView.length > 0 ? tagView : "-"}</dd>
                        </div>
                    </dl>

                    <div className="created-updated">
                        {this.props.project.created ? <div><span className="record-text">Created</span> {moment(this.props.project.created).fromNow()}, {moment(this.props.project.created).format('DD.MM.YYYY')} by {this.props.project.createdBy?.type === PrincipalType.User ? <PersonLink {...this.props.project.createdBy} /> : this.props.project.createdBy?.type === PrincipalType.Application ? <ApplicationLink {...this.props.project.createdBy} /> : this.props.project.createdBy?.type}</div> : null}
                        {this.props.project?.lastModified && this.props.project?.lastModifiedBy ? <div><span className="record-text">Edited</span> {moment(this.props.project.lastModified).fromNow()}, {moment(this.props.project.lastModified).format('DD.MM.YYYY')} by <PersonLink id={this.props.project.lastModifiedBy.id} /></div> : null}
                    </div>
                </Card>

                <Drawer
                    title="Edit project"
                    onClose={this.toggleEditDrawer}
                    open={this.state.showEditDrawer}
                    destroyOnClose
                    component={
                        <ProjectEditForm
                            project={this.props.project}
                            onComplete={(data) => {
                                this.props.onEditComplete(data);
                                this.toggleEditDrawer();
                            }}
                            onCancel={this.toggleEditDrawer}
                        />
                    }
                />
            </>
        );
    }
}

export default ProjectInfoCard;