import _ from "lodash";
import client from "../client";
import { PagedSubscriptionReportQuery, ProductReportResultView, ProductUnit } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class SubscriptionReportExport extends BaseExportService<PagedSubscriptionReportQuery, ProductReportResultView> {
    constructor() {

        const specification: ExportConfig<ProductReportResultView>[] = [
            { header: 'Code', value: (data: ProductReportResultView) => data.product ? data.product.code : null },
            { header: 'Product', value: (data: ProductReportResultView) => data.product ? data.product.name : null },
            { header: 'Quantity', value: (data: ProductReportResultView) => data.quantity ? data.quantity : null }
        ];

        _.each(Object.values(ProductUnit), (val) => {
            specification.push({
                header: `Cost price: ${val}`,
                value: (data: ProductReportResultView) => {
                    const match = _.find(data.product?.prices ?? [], p => p.unit === val);
                    if (match) return match.cost;
                    return null;
                },
                format: ExportFormat.Currency
            });
            specification.push({
                header: `Sales price: ${val}`,
                value: (data: ProductReportResultView) => {
                    const match = _.find(data.product?.prices ?? [], p => p.unit === val);
                    if (match) return match.sales;
                    return null;
                },
                format: ExportFormat.Currency
            });
        });

        specification.push(
            { header: 'Quantity', value: (data: ProductReportResultView) => data.quantity ? data.quantity : null },
            { header: 'Cost', value: (data: ProductReportResultView) => data.costMonth ? data.costMonth : null, format: ExportFormat.Currency },
            { header: 'Discount', value: (data: ProductReportResultView) => data.discountMonth ? data.discountMonth : null, format: ExportFormat.Currency },
            { header: 'Revenue', value: (data: ProductReportResultView) => data.revenue ? data.revenue : null, format: ExportFormat.Currency },
            { header: 'Result', value: (data: ProductReportResultView) => data.result ? data.result : null, format: ExportFormat.Currency }
        );

        super(specification, client.subscriptions.getProductReport);
    }
}