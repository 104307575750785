import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { ActorView, Address, AddressCollectionItem, AddressType, OrderDateType, OrderType, OrderView, TaggableType, UpdateOrder } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import BaseForm, { FormType } from "../Shared/Form";
import OrderTypeSelector from "./OrderTypeSelector";
import { ActorSelector, CustomerSelector } from "../Actors";
import { TagIds } from "../../Definitions/_definitions";
import { PersonSelector } from "../People";
import { TextInput } from "../Shared/TextInput";
import { ProjectSelector } from "../Projects";
import OrderCategorySelector from "./OrderCategorySelector";
import { TagSelector } from "../Tags";
import { AddressInput } from "../Shared/AddressInput";


interface EditOrderProps {
    order: OrderView,
    onComplete: (updated: OrderView) => void,
    onCancel?: () => void,
}

interface EditOrderState {
    loading: boolean;
    error: string;
    type: OrderType;
    deliveryAddress: Partial<AddressCollectionItem>;
    invoiceAddress: Partial<AddressCollectionItem>;
}

interface UpdateRequest extends UpdateOrder {
    [OrderDateType.Quotation]: string;
    [OrderDateType.QuotationExpiry]: string;
    [OrderDateType.QuotationLost]: string;
    [OrderDateType.Sales]: string;
    [OrderDateType.SalesCorrection]: string;
    [OrderDateType.SalesProduction]: string;
    [OrderDateType.SalesCompleted]: string;
    [OrderDateType.RequestedDelivery]: string;
    [OrderDateType.Delivered]: string;
}


class OrderEditForm extends React.Component<EditOrderProps, EditOrderState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            type: this.props.order.type,
            deliveryAddress: null,
            invoiceAddress: null
        }
    }

    componentDidMount = () => {
        const initialState = {};

        if (this.props.order.deliveryAddress != null)
            initialState['deliveryAddress'] = this.props.order.deliveryAddress;

        if (this.props.order.invoiceAddress != null)
            initialState['invoiceAddress'] = this.props.order.invoiceAddress;

        this.setState(initialState);

    }

    onSubmit = async (request: UpdateRequest) => {
        this.setState({ loading: true });

        const dates: any = Object.assign({}, this.props.order.dates);

        if (request[OrderDateType.Quotation])
            dates[OrderDateType.Quotation] = request[OrderDateType.Quotation];

        if (request[OrderDateType.QuotationExpiry])
            dates[OrderDateType.QuotationExpiry] = request[OrderDateType.QuotationExpiry];

        if (request[OrderDateType.QuotationLost])
            dates[OrderDateType.QuotationLost] = request[OrderDateType.QuotationLost];

        if (request[OrderDateType.Sales])
            dates[OrderDateType.Sales] = request[OrderDateType.Sales];

        if (request[OrderDateType.SalesCorrection])
            dates[OrderDateType.SalesCorrection] = request[OrderDateType.SalesCorrection];

        if (request[OrderDateType.SalesProduction])
            dates[OrderDateType.SalesProduction] = request[OrderDateType.SalesProduction];

        if (request[OrderDateType.SalesCompleted])
            dates[OrderDateType.SalesCompleted] = request[OrderDateType.SalesCompleted];

        if (request[OrderDateType.RequestedDelivery])
            dates[OrderDateType.RequestedDelivery] = request[OrderDateType.RequestedDelivery];

        if (request[OrderDateType.Delivered])
            dates[OrderDateType.Delivered] = request[OrderDateType.Delivered];

        const command: UpdateOrder = {
            type: request.type ? request.type : this.props.order.type,
            label: request.label,
            dates: dates,
            deliveryAddress: request.deliveryAddress,
            invoiceAddress: request.invoiceAddress,
            customerId: request.customerId,
            invoiceReceiverId: request.invoiceReceiverId,
            externalContactId: request.externalContactId,
            internalContactId: request.internalContactId,
            projectId: request.projectId,
            categoryId: request.categoryId,
            tagIds: request.tagIds,
        };

        const response = await client.orders.updateOrder(this.props.order.id, command, addChangeVectorHeader(this.props.order.changeVector)).catch(exception => this.setState({ error: exception.error.title }));
        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.order != null) {
            const response = await client.orders.deleteOrder(this.props.order.id, addChangeVectorHeader(this.props.order.changeVector)).catch(exception => this.setState({ error: exception.error.title }));
            if (response) this.props.onComplete(response.data);
        }
    }

    onRestore = async () => {
        if (this.props.order != null) {
            const response = await client.orders.restoreOrder(this.props.order.id, addChangeVectorHeader(this.props.order.changeVector)).catch(exception => this.setState({ error: exception.error.title }));
            if (response) this.props.onComplete(response.data);
        }
    }

    onTypeChange = (type) => {
        this.setState({ type: type });
    }

    onCustomerChange = (customer: ActorView) => {
        let newDeliveryAddress: Partial<Address> = null;

        if (customer) {
            const deliveryAddress = _.find(customer.addresses, a => {
                return a.types.includes(AddressType.Delivery);
            });

            if (deliveryAddress) {
                newDeliveryAddress = {
                    line1: deliveryAddress.line1,
                    line2: deliveryAddress.line2,
                    zipCode: deliveryAddress.zipCode,
                    area: deliveryAddress.area,
                    country: deliveryAddress.country,
                    note: deliveryAddress.note
                };
            }
        }

        this.setState({ deliveryAddress: newDeliveryAddress });
    }

    onInvoiceReceiverChange = (receiver: ActorView) => {
        let newReceiverAddress: Partial<Address> = null;

        if (receiver) {
            const invoiceAddress = _.find(receiver.addresses, a => {
                return a.types.includes(AddressType.Invoice);
            });

            if (invoiceAddress) {
                newReceiverAddress = {
                    line1: invoiceAddress.line1,
                    line2: invoiceAddress.line2,
                    zipCode: invoiceAddress.zipCode,
                    area: invoiceAddress.area,
                    country: invoiceAddress.country,
                    note: invoiceAddress.note
                };
            }
        }

        this.setState({ invoiceAddress: newReceiverAddress });
    }

    render = () => {
        if (!this.props.order) return null;

        const { order } = this.props;
        let dates = null;

        const quotationDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.Quotation.toLowerCase()) || moment().format()
        const quotationExpiryDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.QuotationExpiry.toLowerCase()) || moment().add(30, 'days').format();
        const quotationLostDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.QuotationLost.toLowerCase());

        const currentSales = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.Sales.toLowerCase());
        const salesDate = currentSales == null ? order.type?.toLowerCase() != OrderType.Normal.toLowerCase() ? null : moment().format() : currentSales;
        const salesCorrectionDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.SalesCorrection.toLowerCase());
        const salesProductionDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.SalesProduction.toLowerCase());
        const salesCompletedDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.SalesCompleted.toLowerCase());

        const requestedDeliveryDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.RequestedDelivery.toLowerCase());
        const deliveredDate = _.find(order.dates, (d, key) => key.toLowerCase() == OrderDateType.Delivered.toLowerCase());

        const tagIds = _.map(this.props.order.tags, tag => {
            if (tag.category?.deleted || tag?.deleted) return;
            return tag.id; 
        });


        if (this.state.type == OrderType.Quotation) {
            dates = (
                <>
                    <SelectorInput
                        key="quotation"
                        param={OrderDateType.Quotation}
                        title="Quotation date"
                        required
                        warningMessage="Please choose a date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="quotationExpiry"
                        param={OrderDateType.QuotationExpiry}
                        title="Expiry date"
                        required
                        warningMessage="Please choose a expiry date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="requestedDelivery"
                        param={OrderDateType.RequestedDelivery}
                        warningMessage="Please choose a delivery date"
                        title="Delivery date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="quotationLost"
                        param={OrderDateType.QuotationLost}
                        title="Lost date"
                        selector={<DateSelector />}
                    />

                </>
            );
        }
        else if (this.state.type == OrderType.Normal) {
            dates = (
                <>
                    <SelectorInput
                        key="sales"
                        param={OrderDateType.Sales}
                        title="Sales date"
                        required
                        warningMessage="Please choose a sales date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="salesCorrection"
                        param={OrderDateType.SalesCorrection}
                        title="Correction date"
                        selector={<DateSelector />}
                    />
                    <SelectorInput
                        key="salesProduction"
                        param={OrderDateType.SalesProduction}
                        title="Production date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="requestedDelivery"
                        param={OrderDateType.RequestedDelivery}
                        warningMessage="Please choose a delivery date"
                        required
                        title="Delivery date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="delivered"
                        param={OrderDateType.Delivered}
                        title="Delivered date"
                        selector={<DateSelector />}
                    />
                </>
            );
        }
        else if (this.state.type == OrderType.Direct) {
            dates = (<SelectorInput
                key="sales"
                param={OrderDateType.Sales}
                title="Sales date"
                selector={<DateSelector />}
            />);
        }
        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                isDeleted={this.props.order.deleted}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    type: this.props.order.type,
                    label: this.props.order.label,
                    [OrderDateType.Quotation]: quotationDate ? moment(quotationDate) : null,
                    [OrderDateType.QuotationExpiry]: quotationExpiryDate ? moment(quotationExpiryDate) : null,
                    [OrderDateType.QuotationLost]: quotationLostDate ? moment(quotationLostDate) : null,
                    [OrderDateType.Sales]: salesDate ? moment(salesDate) : null,
                    [OrderDateType.SalesCorrection]: salesCorrectionDate ? moment(salesCorrectionDate) : null,
                    [OrderDateType.SalesProduction]: salesProductionDate ? moment(salesProductionDate) : null,
                    [OrderDateType.SalesCompleted]: salesCompletedDate ? moment(salesCompletedDate) : null,
                    [OrderDateType.RequestedDelivery]: requestedDeliveryDate ? moment(requestedDeliveryDate) : null,
                    [OrderDateType.Delivered]: deliveredDate ? moment(deliveredDate) : null,
                    customerId: order.customer != null ? order.customer.id : null,
                    invoiceReceiverId: order.invoiceReceiver != null ? order.invoiceReceiver.id : null,
                    externalContactId: order.externalContact != null ? order.externalContact.id : null,
                    internalContactId: order.internalContact != null ? order.internalContact.id : null,
                    deliveryAddress: this.state.deliveryAddress ?? order.deliveryAddress ?? null,
                    invoiceAddress: this.state.invoiceAddress ?? order.invoiceAddress ?? null,
                    projectId: this.props.order.project ? this.props.order.project.id : null,
                    categoryId: this.props.order.category ? this.props.order.category.id : null,
                    tagIds: tagIds
                }}
            >
                <SelectorInput
                    key="type"
                    param="type"
                    title="Order type"
                    required
                    selector={
                        <OrderTypeSelector
                            type="radio"
                            onChange={this.onTypeChange}
                        />
                    }
                />

                <SelectorInput
                    key="customer"
                    param="customerId"
                    title="Customer"
                    required
                    warningMessage="Please select a customer"
                    selector={<CustomerSelector placeholder="Select customer" onObjectChange={this.onCustomerChange} />}
                />

                <SelectorInput
                    key="invoiceReceiver"
                    param="invoiceReceiverId"
                    title="Invoice receiver"
                    warningMessage="Please select a invoice receiver"
                    selector={<CustomerSelector placeholder="Select invoice receiver" onObjectChange={this.onInvoiceReceiverChange} />}
                />

                <SelectorInput
                    key="internalContact"
                    param="internalContactId"
                    title="Internal contact"
                    warningMessage="Please select a internal contact"
                    selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select person" />}
                />

                <SelectorInput
                    key="externalContact"
                    param="externalContactId"
                    title="External contact"
                    warningMessage="Please select a external contact"
                    selector={<PersonSelector placeholder="Select person" />}
                />

                <TextInput
                    param="label"
                    title="Label"
                    placeholder="Label..."
                />


                <SelectorInput
                    param="projectId"
                    title="Project"
                    selector={<ProjectSelector />}
                />

                <SelectorInput
                    param="categoryId"
                    title="Category"
                    selector={<OrderCategorySelector />}
                />

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Order] }} />}
                />

                <AddressInput
                    title="Delivery address"
                    param="deliveryAddress"
                    addressType={AddressType.Delivery}
                />

                <AddressInput
                    title="Invoice address"
                    param="invoiceAddress"
                    addressType={AddressType.Invoice}
                />

                {dates}

            </BaseForm>
        );
    }
}

export default OrderEditForm;

