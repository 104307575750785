import React from "react";
import _ from 'lodash';
import moment from "moment";
import { EyeOutlined, AimOutlined, PlusCircleOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import NotesCreateForm from "./NotesCreateForm";
import NotesEditForm from "./NotesEditForm";
import { Capabilities } from "../../Definitions/_capabilties";
import { CreateNote, NoteView, UpdateNote } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Drawer } from "../Shared/Drawer";
import { RequireCapability } from "../Shared/RequireCapability";
import { PersonLink } from "../People";
import { Card, Typography, Avatar, Tooltip, Button } from 'antd';
import { EntityNoteClient } from '../../ApiClient/EntityNoteClient';
import Comment from "../Shared/Comment";
import { ActorIds } from "../../Definitions/_definitions";
const { Title } = Typography;


interface NotesCardProps {
    entityId: string;
    className?: string;
    readAccess?: boolean;
    writeAccess?: boolean;
    readCapability?: Capabilities;
    writeCapability?: Capabilities;
    entityNoteClient: EntityNoteClient;
}
interface NotesCardState {
    notes: NoteView[];
    selected: NoteView;
    loading: boolean;
    error: any;
    showDrawer: boolean;
    takeElements: number;
}

export class NotesCard extends React.Component<NotesCardProps, NotesCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props)
        this.state = {
            notes: [],
            loading: false,
            error: null,
            selected: null,
            showDrawer: false,
            takeElements: 5
        }
    }

    componentDidMount = async () => {
        this.loadData();

        this.context.events.notes.on("created", this.updatedEvent);
        this.context.events.notes.on("updated", this.updatedEvent);
        this.context.events.notes.on("deleted", this.deletedEvent);
    }

    componentWillUnmount = () => {
        this.context.events.notes.off("created", this.updatedEvent);
        this.context.events.notes.off("updated", this.updatedEvent);
        this.context.events.notes.off("deleted", this.deletedEvent);
    }

    componentDidUpdate = (prevProps: NotesCardProps) => {
        if (this.props.entityId !== prevProps.entityId) {
            this.loadData();
        }
    }

    updatedEvent = async (data: NoteView) => {
        const notes = this.state.notes.slice();

        const stateNote = _.find(notes, (note) => { return note.id == data.id; });

        const index = _.indexOf(notes, stateNote);
        if (index < 0 && data.entity.id == this.props.entityId) {
            if (data.sticky)
                notes.splice(0, 0, data);
            else
                notes.splice(_.findIndex(notes, (note) => { return !note.sticky; }), 0, data);

            this.setState({ notes: notes });
            return;
        }

        if (index >= 0) {
            notes[index] = data;
            this.setState({ notes: notes });
        }
        else {
            _.remove(notes, function (o) { return o.id == data.id; });
            this.setState({ notes: notes });
        }
    }

    deletedEvent = (data: NoteView) => {
        const stateNote = _.find(this.state.notes, (note) => { return note.id == data.id; });
        if (stateNote == null)
            return;

        const collection = this.state.notes;
        _.remove(collection, function (o) { return o.id == data.id; });
        this.setState({ notes: collection });
    }

    loadData = async (fromEvent?: boolean) => {
        if (!fromEvent)
            this.setState({ loading: true });

        try {
            const response = await this.props.entityNoteClient.queryEntityNotes(this.props.entityId, { limit: 100 });
            this.setState({ notes: response.data.items });
        } catch (error) {
            this.setState({ error });
        }

        if (!fromEvent)
            this.setState({ loading: false });
    }

    loadMore = () => {
        let shownElements = this.state.takeElements;
        shownElements += 5;
        this.setState({ takeElements: shownElements });
    }

    onSelect = async (note: NoteView) => {
        this.setState({ selected: note });
    }

    toggleDrawer = () => {
        this.setState({ showDrawer: !this.state.showDrawer });
    }

    renderNotes = () => {
        const notesCopy = this.state.notes.slice();
        const excludeDeletedNotes = _.reject(notesCopy, n => { return n.deleted; });

        const shownElements = _.take(excludeDeletedNotes, this.state.takeElements);

        return _.map(shownElements, (note: NoteView) => {
            const actions = [];
            if (note.sticky)
                actions.push(<Tooltip key="0" title="Sticky" placement="left"><AimOutlined /></Tooltip>);

            if (note.private)
                actions.push(<Tooltip key="1" title="Private" placement="left"><EyeOutlined /></Tooltip>);

            return (
                <div key={note.id} className="note-select" onClick={() => this.onSelect(note)}>
                    <Comment
                        actions={actions}
                        author={<PersonLink {...note.author} />}
                        avatar={
                            <Avatar
                                src={`/api/people/${note.author.id}/avatar`}
                            //alt={note.author.name}
                            />
                        }
                        content={
                            <p>{note.text}</p>
                        }
                        datetime={
                            <Tooltip title={moment(note.timestamp).format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(note.timestamp).fromNow()}</span>
                            </Tooltip>
                        }
                    />
                </div>);
        });
    }

    onCreate = async (request: CreateNote) => {
        this.setState({ loading: true, error: null });

        if (!request.authorId && this.context.user.actorId != ActorIds.System)
            request.authorId = this.context.user.actorId;

        const response = await this.props.entityNoteClient
            .createEntityNote(this.props.entityId, request)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            this.updatedEvent(response.data);
            this.toggleDrawer();
        }

        this.setState({ loading: false });
    }

    onEdit = async (request: UpdateNote) => {
        this.setState({ loading: true,error: null });

        const response = await this.props
            .entityNoteClient.updateEntityNote(this.props.entityId, this.state.selected.id, request)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            const notes = this.state.notes?.slice() ?? [];
            const note = response.data as NoteView;

            const index = _.findIndex(notes, n => n.id == note.id);
            if (index != -1) {
                notes[index] = note;
                this.setState({ notes });
            }
        }

        this.setState({ loading: false, selected: null });
    }

    onDelete = async () => {
        this.setState({ loading: true, error: null });

        const response = await this.props.entityNoteClient
            .deleteEntityNote(this.props.entityId, this.state.selected.id)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            const notes = this.state.notes?.slice() ?? [];
            const note = response.data as NoteView;

            const index = _.findIndex(notes, n => n.id == note.id);
            if (index != -1) {
                notes.splice(index, 1);
                this.setState({ notes });
            }
        }

        this.setState({ loading: false, selected: null });
    }

    render = () => {
        const notes = this.state.notes && this.state.notes.length > 0 ? this.renderNotes() : [];
        const excludeDeletedNotes = _.reject(this.state.notes.slice(), n => { return n.deleted; });

        return (
            <>
                <Drawer
                    title="Create note"
                    onClose={this.toggleDrawer}
                    open={this.state.showDrawer}
                    component={
                        <NotesCreateForm
                            onCancel={this.toggleDrawer}
                            onCreate={this.onCreate}
                            loading={this.state.loading}
                            error={this.state.error} />
                    }
                />
                <Drawer
                    title="Edit note"
                    onClose={() => { this.setState({ selected: null }); }}
                    open={this.state.selected != null}
                    component={
                        <NotesEditForm
                            selected={this.state.selected}
                            onEdit={this.onEdit}
                            onDelete={this.onDelete}
                            loading={this.state.loading}
                            error={this.state.error} />
                    }
                />
                <Card
                    title={
                        <div>
                            <Title level={4} className="title">Notes</Title>
                            <RequireCapability key="add-note" bypass={this.props.writeAccess} capability={this.props.writeCapability}>
                                <Button size="small" className="action" onClick={this.toggleDrawer} icon={this.context.isMobile ? <PlusCircleOutlined /> : null}>{this.context.isMobile ? null : "Add"}</Button>
                            </RequireCapability>
                        </div>
                    }
                    className={`notes-card ${this.props.className ? this.props.className : ''}`}
                >
                    <div className="notes">
                        {this.state.loading ? <Loading3QuartersOutlined spin /> : notes}

                        {notes && notes.length > 0 && excludeDeletedNotes.length > notes.length ?
                            <div className="load-more-notes">
                                <Button onClick={this.loadMore} disabled={excludeDeletedNotes.length <= notes.length}>Load more</Button>
                            </div>
                            : null}
                    </div>
                    <RequireCapability bypass={this.props.readAccess} capability={this.props.readCapability}>
                        {notes && notes.length > 0 ? null : this.state.loading ? null : "No notes"}
                    </RequireCapability>
                </Card>
            </>
        );
    }
}

export default NotesCard;

