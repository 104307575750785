import * as React from "react";
import moment from "moment";
import _ from 'lodash';
import { Button, Alert } from "antd";
import { ActivityView, CallTargetView, MeetingLocationType, MeetingView, ResourceType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { LoadingPage } from "../../Pages/LoadingPage";
import { ContactPointPartialLink } from "../ContactPoints";
import { formatDuration } from "../../Helpers/Formatters";
import { ActorLink } from "../Actors";
import { Capabilities } from "../../Definitions/_capabilties";
import { ArrowUpOutlined } from "@ant-design/icons";
import { AttributesView } from "../Shared/AttributesView";
import { Drawer } from "../Shared/Drawer";
import ActivityCreateForm from "../Activities/ActivityCreateForm";
import { ActivityMold, CalendarResourceType } from "../../Models/CalendarModels";


interface MeetingProps {
    meeting: MeetingView;
    onTrackedHourComplete?: (activity?: Partial<ActivityView>) => void;
    closeDrawer?: () => void;
    onCancel?: () => void;
}

interface MeetingState {
    activities: ActivityView[];
    loadingActivities: boolean;
    totalHours: number;
    trackHour: boolean;
    error: any;
    loadingDelete: boolean;
}

export default class MeetingDrawerView extends React.Component<MeetingProps, MeetingState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            activities: [],
            totalHours: 0,
            loadingActivities: false,
            trackHour: false,
            loadingDelete: false
        }
    }

    componentDidMount = () => {
        this.loadActivities();
    }

    loadActivities = async () => {
        this.setState({ loadingActivities: true });

        try {
            const response = await client.activities.queryActivities({
                deleted: false,
                meetingId: this.props.meeting.id
                //includeAggregates: true
            });

            this.setState({
                activities: response.data.items,
                //totalHours: response.data.aggregates["duration:total"]?.sum ?? 0
            });
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loadingActivities: false });
    }

    deleteMeeting = async () => {
        this.setState({ loadingDelete: true });

        try {
            await client.meetings.deleteMeeting(this.props.meeting.id, addChangeVectorHeader(this.props.meeting.changeVector));
            if (this.props.closeDrawer)
                this.props.closeDrawer();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loadingDelete: false });
    }

    restoreMeeting = async () => {
        this.setState({ loadingDelete: true });

        try {
            await client.meetings.restoreMeeting(this.props.meeting.id, addChangeVectorHeader(this.props.meeting.changeVector));
            if (this.props.closeDrawer)
                this.props.closeDrawer();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loadingDelete: false });
    }

    toggleTrackHour = () => {
        this.setState({ trackHour: !this.state.trackHour });
    }

    onTrackingComplete = () => {
        if (this.props.onTrackedHourComplete)
            this.props.onTrackedHourComplete();

        this.toggleTrackHour();
    }

    render() {
        if (!this.props.meeting) return <LoadingPage />;

        const { meeting } = this.props;

        const activityMold: ActivityMold = {
            resourceType: CalendarResourceType.Meeting,
            meetingId: meeting.id,
            participants: _.map(_.filter(meeting.participants ?? [], p => p.contact != null), p => p),
            organizer: meeting.organizer ?? null,
            start: meeting.start,
            end: meeting.end,
        };

        const allParticipants = meeting.organizer ? [meeting.organizer, ...meeting.participants] : meeting.participants.slice();

        const meetingParticipants = _.map(allParticipants ?? [], (p, i) => {

            return (
                <React.Fragment key={p.contactPoint.id}>
                    <ContactPointPartialLink key={p.contactPoint.id} contactPoint={p} hideContactPointIfActor />
                    {meeting.organizer?.contact && p.contact && p.contact.id == meeting.organizer.contact.id ? " (Organizer)" : ""}
                    {i == allParticipants.length - 1 ? "" : <span >, </span>}
                </React.Fragment>
            );
        });


        let location = null;
        if (meeting.location) {
            if (meeting.location.type == MeetingLocationType.Online) {
                if (meeting.location.value != null) {
                    if (!meeting.location.value.startsWith("http"))
                        location = '//' + meeting.location.value;
                    else
                        location = meeting.location.value
                }
            }
            else if (meeting.location.type == MeetingLocationType.Unknown) {
                location = meeting.location.value
            }
        }

        const calendarFormats: moment.CalendarSpec = {
            sameDay: '[Today] HH:mm',
            nextDay: '[Tomorrow] HH:mm',
            nextWeek: 'dddd HH:mm',
            lastDay: '[Yesterday] HH:mm',
            lastWeek: '[Last] dddd HH:mm',
            sameElse: 'DD.MM.YYYY HH:mm'
        };

        const activitiesView = _.map(this.state.activities, (a, i) => {
            return (
                <React.Fragment key={a.id}>
                    <tr className="a-row">
                        <td className="a-date">{moment(a.start).calendar(calendarFormats)}</td>
                        <td className="a-duration">{formatDuration(a.duration)}</td>
                        <td><ActorLink {...a.actor} /></td>
                    </tr>
                    <tr className={`description-row ${i == this.state.activities.length - 1 ? "" : "show-border"}`}>
                        <td colSpan={3} className="a-description">{a.description}</td>
                    </tr>
                </React.Fragment>
            );
        });


        const userIsParticipant = _.find(allParticipants, p => p.contact && p.contact.id == this.context.user.actorId) != null;
        const userHasWriteRights = this.context.user.hasCapability(Capabilities.MeetingsWrite) || (this.context.user.hasCapability(Capabilities.MeetingsWritePersonal) && userIsParticipant);
        const hasActivityWriteRights = this.context.user.hasCapability(Capabilities.ActivitiesWrite) || (this.context.user.hasCapability(Capabilities.ActivitiesWriteActor) && userIsParticipant);

        return (
            <div className="meeting-drawer-view">

                <div className="info-container">
                    <div className="title-action-container">
                        <div className="time-container">
                            <div className="info-title">Time</div>
                            <div className="info-content">{moment(meeting.start).format('DD.MM.YYYY HH:mm')} - {moment(meeting.end).format('HH:mm')} ({formatDuration(moment(meeting.end).diff(moment(meeting.start), "milliseconds", true))})</div>
                        </div>
                        {hasActivityWriteRights ? <Button onClick={this.toggleTrackHour} size="small" className="track-hour-btn">Track hour</Button> : null}
                    </div>
                </div>

                <div className="info-container">
                    <div className="info-title">Title</div>
                    <div className="info-content">{meeting.name}</div>
                </div>

                <div className="info-container">
                    <div className="info-title">Description</div>
                    <div className="info-content">{meeting.description ? meeting.description : "-"}</div>
                </div>

                <div className="info-container">
                    <div className="info-title">Participants</div>
                    <div className="info-content">{meetingParticipants}</div>
                </div>

                <div className="info-container">
                    <div className="info-title">Location</div>
                    <div className="info-content">
                        {
                            meeting.location && meeting.location.type == MeetingLocationType.Online
                                ?
                                <a onClick={(e) => e.stopPropagation()} href={`${location}`} target="_blank">
                                    Join meeting <ArrowUpOutlined style={{ transform: 'rotate(45deg)' }} />
                                </a>
                                : location
                        }
                    </div>
                </div>

                <div className="info-container">
                    <div className="info-title">{`Tracked hours: ${formatDuration(this.state.totalHours)}`}</div>
                    <div className="info-content">
                        <table><tbody>{activitiesView.length > 0 ? activitiesView : "-"}</tbody></table>
                    </div>
                </div>

                {this.state.error
                    ? <div className="info-container">
                        <Alert message="Error" description={this.state.error} />
                    </div>
                    : null
                }

                {userHasWriteRights && !this.props.meeting.deleted
                    ? <div className="info-container">
                        <Button loading={this.state.loadingDelete} onClick={this.deleteMeeting} danger size="middle">Delete</Button>
                    </div>
                    : null}

                {userHasWriteRights && this.props.meeting.deleted
                    ? <div className="info-container">
                        <Button loading={this.state.loadingDelete} onClick={this.restoreMeeting} size="middle">Restore</Button>
                    </div>
                    : null}

                <div className="info-container">
                    <AttributesView attributes={meeting.attributes} />
                </div>

                <Drawer
                    title="Track hours based on meeting"
                    onClose={this.toggleTrackHour}
                    open={this.state.trackHour}
                    destroyOnClose
                    component={
                        <ActivityCreateForm
                            activityMolds={this.state.trackHour ? [activityMold] : null}
                            actorId={this.context.user.actorId}
                            onClose={this.toggleTrackHour}
                            onComplete={this.onTrackingComplete}
                            calendarDate={moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss")}
                        />
                    }
                />

                {/*<ActivityCreateForm
                    activityMolds={this.state.trackHour ? [activityMold] : null}
                    actorId={this.context.user.actorId}
                    onClose={this.toggleTrackHour}
                    onComplete={this.onTrackingComplete}
                    calendarDate={moment().startOf('day').format("YYYY-MM-DDTHH:mm:ss")}
                />*/}
            </div>
        );
    }
}

