/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SuggestionViewPagedSuggestionQueryCollectionViewModel } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Suggestions<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Suggestion
   * @name QuerySuggestions
   * @request GET:/api/suggestions
   * @secure
   */
  querySuggestions = (
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      groupId?: string;
      participantId?: string;
      /** @format date-time */
      startAfter?: string;
      /** @format date-time */
      endBefore?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SuggestionViewPagedSuggestionQueryCollectionViewModel, any>({
      path: `/api/suggestions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Suggestion
   * @name IgnoreSuggestion
   * @request DELETE:/api/suggestions/{id}/ignore/{participantId}
   * @secure
   */
  ignoreSuggestion = (id: string, participantId: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/api/suggestions/${id}/ignore/${participantId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}
