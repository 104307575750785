import * as React from "react";
import client from "../../ApiClient/client";
import { PagedTagQuery, TagView } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";
import SelectorInformation from "../Shared/SelectorInformation";


export class TagSelector extends React.Component<GenericEntitySelectorProps<PagedTagQuery>> {
    renderOptionItem = (tag: TagView) => tag.description ? <SelectorInformation displayText={tag.name} hoverText={tag.description} /> : tag.name;
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select tags...';
        const source = this.props.source ? this.props.source : client.tags.queryTags;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.tags.getTagById;

        return (
            <EntitySelector
                className="tag-selector"
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                multiple
                renderOptionItem={this.renderOptionItem}
                {...this.props}
            />
        );
    }
}

export default TagSelector;