import _ from 'lodash';
import moment from 'moment';
import { ActivityView, PagedActivityQuery, ActivityType, ProjectActivityTypeLinks, CustomerActivityTypeLinks, InternalActivityTypeLinks, ProductUnit } from '../../ApiClient/swagger/data-contracts';

export const capitalize = (input) => {
    return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
}

export const isLocked = (activity: ActivityView) => {
    //activity.
    if (!activity || activity.locked || activity.confirmed)
        return true;
    return false;
}

export const activityEventCompatibility = (activity: ActivityView, query: Partial<PagedActivityQuery>) => {
    if (!activity) return false;

    if (_.hasIn(query, "deleted") && query.deleted != null) {
        if (query.deleted != activity.deleted) { return false; }
    }
    if (_.hasIn(query, "locked") && query.locked != null) {
        if (query.locked != activity.locked) { return false; }
    }
    if (_.hasIn(query, "confirmed") && query.confirmed != null) {
        if (query.confirmed != activity.confirmed) { return false; }
    }
    if (_.hasIn(query, "type") && query.types != null && query.types.length > 0) {
        const exists = _.find(query.types || [], t => { return t == activity.type; });
        if (!exists) { return false; }
    }
    if (_.hasIn(query, "actorId")) {
        if (query.actorId != activity.actor.id) { return false; }
    }
    if (_.hasIn(query, "categoryIds") && query.categoryIds != null && query.categoryIds.length > 0) {
        const match = _.find(query.categoryIds, c => c == activity.category.id);
        if (!match) return false;
    }
    if (_.hasIn(query, "taskId") && query.taskId != null) {
        if (query.taskId != activity.task.id) { return false; }
    }
    if (_.hasIn(query, "projectId") && query.projectId != null) {
        if (query.projectId != activity.project.id) { return false; }
    }
    if (_.hasIn(query, "startAfter") && query.startAfter != null) {
        if (moment(query.startAfter).isAfter(activity.start)) { return false; }
    }
    if (_.hasIn(query, "endBefore") && query.endBefore != null) {
        if (moment(query.endBefore).isBefore(activity.end)) { return false; }
    }

    return true;
}

export const formatRequestTypeFromActivity = (activity: ActivityView) => {
    const type: ActivityType = activity.type;
    if (type == ActivityType.Project) {
        const project = { type: type, taskId: activity.task.id, productId: activity.product ? activity.product.id : null, ticketId: activity.ticket?.id ?? null } as ProjectActivityTypeLinks;
        return project;
    }
    else if (type == ActivityType.Customer) {
        const categoryProduct = activity.category && activity.category.product;
        const customer = { type: type, categoryId: activity.category.id, customerId: activity.relation.id, productId: activity.product ? activity.product.id : categoryProduct ? categoryProduct.id : null, ticketId: activity.ticket?.id ?? null } as CustomerActivityTypeLinks;
        return customer;
    }
    else if (type == ActivityType.Internal) {
        const internal = { type: type, categoryId: activity.category.id, ticketId: activity.ticket?.id ?? null } as InternalActivityTypeLinks;
        return internal;
    }
}

export const formatRequestTypeFromRequest = (request) => {
    if (request.taskId) {
        const relations = {} as ProjectActivityTypeLinks;
        relations.type = ActivityType.Project;
        relations.taskId = request.taskId;
        relations.productId = request.productId ?? null;
        relations.ticketId = request.ticketId ?? null;

        return relations;
    }

    if (request.customerId && request.categoryId) {
        const customerRelations = {} as CustomerActivityTypeLinks;
        customerRelations.type = ActivityType.Customer;
        customerRelations.customerId = request.customerId;
        if (typeof request.categoryId == "object") {
            customerRelations.categoryId = request.categoryId.id;
        } else {
            customerRelations.categoryId = request.categoryId;
        }
        customerRelations.productId = request.productId ?? null;
        customerRelations.ticketId = request.ticketId ?? null;

        return customerRelations;
    }

    else if (request.categoryId) {
        const internalRelations = {} as InternalActivityTypeLinks;
        internalRelations.type = ActivityType.Internal;
        internalRelations.categoryId = request.categoryId;
        internalRelations.ticketId = request.ticketId ?? null;

        return internalRelations;
    }

    return null;
}

export default {
    capitalize,
    isLocked,
    activityEventCompatibility,
    formatRequestTypeFromActivity,
    formatRequestTypeFromRequest
}
