import * as React from "react";
import _ from 'lodash';
import { AutoComplete } from 'antd';
import { EntitySelectorProps } from "../../Models/EntitySelectorProps";

interface BaseEntityFilterState {
    collection: any[],
    query: any
    value: any,
    defaultValues: any,
    loading: boolean,
}

class AutoCompleteInput extends React.Component<EntitySelectorProps, BaseEntityFilterState> {
    _isMounted = false;
    _LastSearch = 0;

    constructor(props) {
        super(props)
        this.state = {
            collection: [],
            query: {
                deleted: false
            },
            value: props.value,
            defaultValues: null,
            loading: false
        };
        this.handleSearch = _.debounce(this.handleSearch, 500);
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.onLoadCollection(this.state.query, this._LastSearch);
    }

    componentDidUpdate = async (prevProps) => {
        if (this._isMounted) {
            if (!_.isEqual(prevProps.filters, this.props.filters)) {
                this.onLoadCollection(this.state.query, this._LastSearch);
            }

            if (!_.isEqual(this.props.value, this.state.value))
                this.setState({ value: this.props.value });
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    onLoadCollection = async (query, searchId) => {
        if (this._isMounted)
            this.setState({ loading: true });

        try {
            const updatedQuery = await this.handleQueryFilters(query, this.props.filters);

            const response = await this.props.source(updatedQuery);
            if (response && this._isMounted && this._LastSearch === searchId)
                this.setState({ collection: response.data.items, query: response.data.query, loading: false });
        }
        catch (error) {
            //this.setState({ error });
        }

        if (this._isMounted)
            this.setState({ loading: false });
    }

    handleQueryFilters = async (query, filters) => {
        _.each(filters, (value, param) => {
            query[param] = value;
        });

        return query;
    }

    handleSearch = (terms) => {
        this._LastSearch += 1;
        const query = Object.assign({}, this.state.query);
        query.terms = terms;
        const searchId = this._LastSearch;
        this.onLoadCollection(query, searchId);
    }

    handleChange = (value) => {
        if (!this._isMounted || (!this.props.onChange && !this.props.onObjectChange)) return;
        this.setState({ value: value, defaultValues: null });

        if (value === undefined || !value || value.length === 0)
            value = null;

        if (this.props.onChange)
            this.props.onChange(value);
        if (this.props.onObjectChange)
            this.props.onObjectChange(_.find(this.state.collection, (o) => o.value === value))
    }

    handleFocus = () => {
        this.onLoadCollection(this.state.query, this._LastSearch);
    }

    render = () => {
        const { value } = this.state;
        const collection = this.state.collection?.slice() ?? [];

        const options = [];

        if (value && !Array.isArray(value) && typeof value == "object") {
            collection.push(value);
        }

        if (collection) {
            _.each(collection, (o) => {
                if (o)
                    options.push({ value: o.value, label: `${o.value} ${o.actor ? `(${o.actor.name})` : ""}` });
            });
        }

        const title = this.props.title ? <label>{this.props.title}</label> : null;

        return (
            <div className={`selector autocomplete-input ${this.props.className}`}>
                {title}
                <AutoComplete
                    options={options}
                    placeholder={this.props.placeholder}
                    size="middle"
                    allowClear={true}
                    onChange={this.handleChange}
                    className={this.props.className ? this.props.className : ''}
                    disabled={this.props.disabled}
                    onSearch={this.handleSearch}
                    value={this.state.value}
                />
            </div>
        );
    }
}

export default AutoCompleteInput;