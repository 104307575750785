import _ from 'lodash';
import { Popover, Spin, Card, Button } from 'antd';
import { AbsenceCategoryView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { Meta } = Card;

interface AbsenceCategoryLinkCardProps {
    id?: string;
}


export function AbsenceCategoryLinkCard({ id }: AbsenceCategoryLinkCardProps) {

    const [absenceCategory, setAbsenceCategory] = useState<AbsenceCategoryView>();
    const [error, setError] = useState<string>();

    const context = useContext(AppContext);

    useEffect(() => {
        if (!absenceCategory || id != absenceCategory.id)
            loadAbsenceCategory();

        context.events.absenceCategories.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.absenceCategories.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }

    }, [id, absenceCategory])


    function onItemUpdatedEvent(eventData: AbsenceCategoryView) {
        if (absenceCategory?.id === eventData.id) {
            if (absenceCategory.changeVector == eventData.changeVector) return;

            setAbsenceCategory(eventData);
        }
    }

    async function loadAbsenceCategory() {
        const response = await client
            .absenceCategories.getAbsenceCategoryById(id)
            .catch(exception => setError(exception.error));

        if (response)
            setAbsenceCategory(response.data.view);
    }

    if (error) return <span>{error}</span>;
    if (!absenceCategory) return <Spin size="large" />;

    return (
        <Card className="category-link-card" bordered={false} size="small" title={absenceCategory.name}>
            <Meta description={absenceCategory.description} />
        </Card>
    );
}

interface AbsenceCategoryLinkProps extends AbsenceCategoryLinkCardProps {
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
}

export function AbsenceCategoryLink(props: AbsenceCategoryLinkProps) {

    //const [error, setError] = useState<string>();

    const [absenceCategory, setAbsenceCategory] = useState<AbsenceCategoryView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.absenceCategories.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.absenceCategories.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    function onItemUpdatedEvent(eventData: AbsenceCategoryView) {
        if (absenceCategory.id === eventData.id) {
            setAbsenceCategory(eventData);
        }
    }

    if (!absenceCategory.name) return null;

    if (!context.user.hasCapability(Capabilities.ActivitiesReadTimeTrackingCategories))
        return <Popover content="You do not have access to read actors.">{absenceCategory.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (absenceCategory.deleted)
        className += " deleted";

    if (props.className)
        className += " " + props.className;

    //var link = props.disableRedirect
    //    ? <Button type="link" className={className}>{absenceCategory.name}</Button>
    //    : <Link onClick={(e) => e.stopPropagation()} to={`/absenceCategories/${absenceCategory.id}`} className={className}>{absenceCategory.name}</Link>;


    return <span>{absenceCategory.name}</span>;

//    if (props.disablePopover || context.isMobile)
//        return link;

//    return (
//        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
//            <Popover zIndex={1051} placement="bottomLeft" content={<AbsenceCategoryLinkCard id={absenceCategory.id} />}>
//                {link}
//            </Popover>
//        </span>
//    );
}