import { Content } from "antd/lib/layout/layout";
import { useContext } from "react";
import AppContext from "../Definitions/AppContext";

interface AppContentProps {
    children: React.ReactNode;
}

const AppContent = ({ children }: AppContentProps) => {
    const context = useContext(AppContext);

    return (
        <Content className={`${context.isMobile ? "mobile-content" : "desktop-content"}`} id="main">
            {children}
        </Content>
    );
}

export default AppContent;