/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateDashboard,
  DashboardCollectionViewModel,
  DashboardSortOption,
  DashboardView,
  DashboardViewModel,
  SortDirection,
  UpdateDashboard,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Dashboards<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Dashboard
   * @name GetDashboardById
   * @request GET:/api/dashboards/{id}
   * @secure
   */
  getDashboardById = (id: string, params: RequestParams = {}) =>
    this.http.request<DashboardViewModel, any>({
      path: `/api/dashboards/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dashboard
   * @name UpdateDashboard
   * @request PUT:/api/dashboards/{id}
   * @secure
   */
  updateDashboard = (id: string, data: UpdateDashboard, params: RequestParams = {}) =>
    this.http.request<DashboardViewModel, any>({
      path: `/api/dashboards/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dashboard
   * @name DeleteDashboard
   * @request DELETE:/api/dashboards/{id}
   * @secure
   */
  deleteDashboard = (id: string, params: RequestParams = {}) =>
    this.http.request<DashboardView, any>({
      path: `/api/dashboards/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dashboard
   * @name CreateDashboard
   * @request POST:/api/dashboards
   * @secure
   */
  createDashboard = (data: CreateDashboard, params: RequestParams = {}) =>
    this.http.request<DashboardViewModel, any>({
      path: `/api/dashboards`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dashboard
   * @name QueryDashboards
   * @request GET:/api/dashboards
   * @secure
   */
  queryDashboards = (
    query?: {
      sortBy?: DashboardSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      roleIds?: string[];
      actorId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<DashboardCollectionViewModel, any>({
      path: `/api/dashboards`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dashboard
   * @name RestoreDashboard
   * @request POST:/api/dashboards/{id}/restore
   * @secure
   */
  restoreDashboard = (id: string, params: RequestParams = {}) =>
    this.http.request<DashboardView, any>({
      path: `/api/dashboards/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
