import React from "react";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";
import { OrderType } from "../../ApiClient/swagger/data-contracts";

class OrderSalesTypeSelector extends React.Component<EnumSelectorProps> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : "Select order type...";

        return (
            <EnumSelector
                className="order-type-selector"
                enum={OrderType}
                placeholder={placeholder}
                excluded={[OrderType.Production, OrderType.Quotation] }
                {...this.props}
            />
        );
    }
}

export default OrderSalesTypeSelector;
