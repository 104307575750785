import { TicketStatus } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";


export function TicketStatusSelector(props: EnumSelectorProps) {

    function renderOptionItem(item: TicketStatus) {
        if (item === TicketStatus.OnHold)
            return "On hold";

        return item;
    }

    return (
        <EnumSelector
            className="ticket-status-selector"
            enum={TicketStatus}
            placeholder={props.placeholder ?? 'Select ticket status...'}
            renderOptionItem={renderOptionItem}
            renderLabelItem={renderOptionItem}
            {...props}
        />
    );
}

export default TicketStatusSelector;