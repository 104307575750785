import * as React from "react";
import _ from 'lodash';
import { Checkbox, Form, Row, Col } from 'antd';
import { NoteView } from "../../ApiClient/swagger/data-contracts";
import BaseForm, { FormType } from "../Shared/Form";
import { TextAreaInput } from "../Shared/TextAreaInput";

interface NotesEditFormProps {
    onEdit: (req) => void,
    onDelete: () => void,
    selected: NoteView,
    loading: boolean
    error: any
}
export class NotesEditForm extends React.Component<NotesEditFormProps> {
    constructor(props) {
        super(props)
    }

    render = () => {
        if (this.props.selected == null)
            return null;

        const formDetails = {
            type: FormType.Edit,
            onSubmit: this.props.onEdit,
            onDelete: this.props.onDelete,
            isDeleted: false,
            loading: this.props.loading,
            error: this.props.error,
            initialValues: {
                text: this.props.selected.text,
                sticky: this.props.selected.sticky,
                private: this.props.selected.private
            }
        };

        return (
                <BaseForm
                    {...formDetails}
                >
                    <TextAreaInput
                        key={0}
                        param="text"
                        required
                        warningMessage="Write a note"
                        placeholder="Create a note"
                    />
                    <Row>
                        <Col>
                            <Form.Item
                                name={"sticky"}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    className="checkbox-sticky"
                                > Sticky</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                name={"private"}
                                valuePropName="checked"
                            >
                                <Checkbox
                                    className="checkbox-private"
                                > Private</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </BaseForm>
        );
    }
}

export default NotesEditForm;