import { HourCategoryLinkView, HourCategoryType } from "../../ApiClient/swagger/data-contracts";
import { AbsenceCategoryLink } from "../HourCategories/AbsenceCategoryLink";
import { WorkCategoryLink } from "../HourCategories/WorkCategoryLink";


interface CategoryLinkProps extends Partial<HourCategoryLinkView> {
    id?: string;
    name?: string;
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
}

export function CategoryLink(props: CategoryLinkProps) {
    if (props.type == HourCategoryType.Work)
        return <WorkCategoryLink {...props} />;
    else if (props.type == HourCategoryType.Absence)
        return <AbsenceCategoryLink {...props} />;
    else
        return null;
}