/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdatePrice,
  AddOrUpdateProductPart,
  AddOrUpdateSupplierOptions,
  CreateFileOrFolder,
  CreateNote,
  CreateProduct,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  PreviewType,
  ProductAggregationResultProductAggregateQueryAggregationResults,
  ProductAggregationTypes,
  ProductSortOption,
  ProductType,
  ProductUnit,
  ProductView,
  ProductViewEntityViewModel,
  ProductViewPagedProductQueryCollectionViewModel,
  RevertFileToVersion,
  SortDirection,
  UnitPrice,
  UpdateFileOrFolder,
  UpdateNote,
  UpdateProduct,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Products<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Product
   * @name GetProductById
   * @request GET:/api/products/{id}
   * @secure
   */
  getProductById = (id: string, params: RequestParams = {}) =>
    this.http.request<ProductViewEntityViewModel, any>({
      path: `/api/products/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name UpdateProduct
   * @request PUT:/api/products/{id}
   * @secure
   */
  updateProduct = (id: string, data: UpdateProduct, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name DeleteProduct
   * @request DELETE:/api/products/{id}
   * @secure
   */
  deleteProduct = (id: string, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name CreateProduct
   * @request POST:/api/products
   * @secure
   */
  createProduct = (data: CreateProduct, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name QueryProducts
   * @request GET:/api/products
   * @secure
   */
  queryProducts = (
    query?: {
      sortBy?: ProductSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      categoryId?: string;
      types?: ProductType[];
      withParts?: boolean;
      productCode?: string;
      units?: ProductUnit[];
      tagIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductViewPagedProductQueryCollectionViewModel, any>({
      path: `/api/products`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name RestoreProduct
   * @request POST:/api/products/{id}/restore
   * @secure
   */
  restoreProduct = (id: string, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name UpdateProductAttributes
   * @request POST:/api/products/{id}/attributes
   * @secure
   */
  updateProductAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name AddProductSupplier
   * @request POST:/api/products/{productId}/suppliers
   * @secure
   */
  addProductSupplier = (productId: string, data: AddOrUpdateSupplierOptions, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${productId}/suppliers`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name UpdateProductSupplier
   * @request PUT:/api/products/{productId}/suppliers/{optionId}
   * @secure
   */
  updateProductSupplier = (
    productId: string,
    optionId: string,
    data: AddOrUpdateSupplierOptions,
    params: RequestParams = {},
  ) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${productId}/suppliers/${optionId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name DeleteProductSupplier
   * @request DELETE:/api/products/{productId}/suppliers/{optionId}
   * @secure
   */
  deleteProductSupplier = (productId: string, optionId: string, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${productId}/suppliers/${optionId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name AddProductPart
   * @request POST:/api/products/{productId}/parts
   * @secure
   */
  addProductPart = (productId: string, data: AddOrUpdateProductPart, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${productId}/parts`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name UpdateProductPart
   * @request PUT:/api/products/{productId}/parts/{partId}
   * @secure
   */
  updateProductPart = (productId: string, partId: string, data: AddOrUpdateProductPart, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${productId}/parts/${partId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name DeleteProductPart
   * @request DELETE:/api/products/{productId}/parts/{partId}
   * @secure
   */
  deleteProductPart = (productId: string, partId: string, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${productId}/parts/${partId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name AddOrUpdateProductPrices
   * @request POST:/api/products/{id}/prices
   * @secure
   */
  addOrUpdateProductPrices = (id: string, data: UnitPrice[], params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${id}/prices`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name AddOrUpdateProductPrice
   * @request PUT:/api/products/{productId}/prices/{unit}
   * @secure
   */
  addOrUpdateProductPrice = (
    productId: string,
    unit: ProductUnit,
    data: AddOrUpdatePrice,
    params: RequestParams = {},
  ) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${productId}/prices/${unit}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name GetProductImageById
   * @request GET:/api/products/{id}/image
   * @secure
   */
  getProductImageById = (
    id: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/products/${id}/image`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name UpdateProductImage
   * @request POST:/api/products/{id}/image
   * @secure
   */
  updateProductImage = (
    id: string,
    data: {
      /** @format binary */
      image: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${id}/image`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name DeleteProductImage
   * @request DELETE:/api/products/{id}/image
   * @secure
   */
  deleteProductImage = (id: string, params: RequestParams = {}) =>
    this.http.request<ProductView, any>({
      path: `/api/products/${id}/image`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name GetProductsAggregates
   * @request GET:/aggs/products
   * @secure
   */
  getProductsAggregates = (
    query?: {
      productId?: string;
      aggs?: ProductAggregationTypes[];
      categoryId?: string;
      types?: ProductType[];
      withParts?: boolean;
      productCode?: string;
      units?: ProductUnit[];
      tagIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductAggregationResultProductAggregateQueryAggregationResults, any>({
      path: `/aggs/products`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Product
   * @name GetProductAggregatesById
   * @request GET:/aggs/products/{id}
   * @secure
   */
  getProductAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/products/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name GetProductFileById
   * @request GET:/api/products/{entityId}/files/{fileId}
   * @secure
   */
  getProductFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/products/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name UpdateProductFile
   * @request PUT:/api/products/{entityId}/files/{fileId}
   * @secure
   */
  updateProductFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/products/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name DeleteProductFile
   * @request DELETE:/api/products/{entityId}/files/{fileId}
   * @secure
   */
  deleteProductFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/products/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name DownloadProductFile
   * @request GET:/api/products/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadProductFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/products/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name PreviewProductFile
   * @request GET:/api/products/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewProductFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/products/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name CreateProductFile
   * @request POST:/api/products/{entityId}/files
   * @secure
   */
  createProductFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/products/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name QueryProductFiles
   * @request GET:/api/products/{entityId}/files
   * @secure
   */
  queryProductFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/products/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name RestoreProductFile
   * @request POST:/api/products/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreProductFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/products/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name MoveProductFile
   * @request POST:/api/products/{entityId}/files/{fileId}/move
   * @secure
   */
  moveProductFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/products/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name RevertProductFile
   * @request POST:/api/products/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertProductFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/products/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name GetProductFolderById
   * @request GET:/api/products/{entityId}/folders/{folderId}
   * @secure
   */
  getProductFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/products/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name UpdateProductFolder
   * @request PUT:/api/products/{entityId}/folders/{folderId}
   * @secure
   */
  updateProductFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/products/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name DeleteProductFolder
   * @request DELETE:/api/products/{entityId}/folders/{folderId}
   * @secure
   */
  deleteProductFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/products/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name CreateProductFolder
   * @request POST:/api/products/{entityId}/folders
   * @secure
   */
  createProductFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/products/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name QueryProductFolders
   * @request GET:/api/products/{entityId}/folders
   * @secure
   */
  queryProductFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/products/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name RestoreProductFolder
   * @request POST:/api/products/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreProductFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/products/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductFile
   * @name MoveProductFolder
   * @request POST:/api/products/{entityId}/{folderId}/move
   * @secure
   */
  moveProductFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/products/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductNote
   * @name GetProductNoteById
   * @request GET:/api/products/{entityId}/notes/{noteId}
   * @secure
   */
  getProductNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/products/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductNote
   * @name UpdateProductNote
   * @request PUT:/api/products/{entityId}/notes/{noteId}
   * @secure
   */
  updateProductNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/products/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductNote
   * @name DeleteProductNote
   * @request DELETE:/api/products/{entityId}/notes/{noteId}
   * @secure
   */
  deleteProductNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/products/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductNote
   * @name CreateProductNote
   * @request POST:/api/products/{entityId}/notes
   * @secure
   */
  createProductNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/products/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductNote
   * @name QueryProductNotes
   * @request GET:/api/products/{entityId}/notes
   * @secure
   */
  queryProductNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/products/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProductNote
   * @name RestoreProductNote
   * @request POST:/api/products/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreProductNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/products/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
