import _ from "lodash";
import { EventGroup } from "./EventGroup";
import EventTransformer from "./EventTransformer";


export interface EventClient {
    activities: EventGroup;
    activityTransfers: EventGroup;
    suggestions: EventGroup;
    applications: EventGroup;
    calls: EventGroup;
    feeds: EventGroup;
    logins: EventGroup;
    leads: EventGroup;
    roles: EventGroup;
    files: EventGroup;
    folders: EventGroup;
    organizations: EventGroup;
    people: EventGroup;
    products: EventGroup;
    contactPoints: EventGroup;
    tags: EventGroup;
    tickets: EventGroup;
    notes: EventGroup;
    comments: EventGroup;
    employees: EventGroup;
    tagCategories: EventGroup;
    workCategories: EventGroup;
    absenceCategories: EventGroup;
    meetings: EventGroup;
    productCategories: EventGroup;
    projects: EventGroup;
    polls: EventGroup;
    tasks: EventGroup;
    routines: EventGroup;
    routineExecutions: EventGroup;
    routineCategories: EventGroup;
    orders: EventGroup;
    orderCategories: EventGroup;
    invoices: EventGroup;
    activityGroups: EventGroup;
    resources: EventGroup;
    resourceCategories: EventGroup;
    subscriptions: EventGroup;
    subscriptionTransfers: EventGroup;
    incidents: EventGroup;
    incidentCategories: EventGroup;
    channels: EventGroup;
    externalLinks: EventGroup;
    dashboards: EventGroup;
    news: EventGroup;
    customerCategories: EventGroup;
    supplierCategories: EventGroup;
    measuringPoints: EventGroup;
    sensors: EventGroup;
    sensoralerts: EventGroup;
    sensorevents: EventGroup;
    measuringPointTemplates: EventGroup;
    measurements: EventGroup;
    notifications: EventGroup;
}


export const eventClient = (eventStream: EventTransformer) => {

    const events: EventClient = {
        activities: new EventGroup("activity", eventStream),
        activityTransfers: new EventGroup("activitytransfer", eventStream),
        suggestions: new EventGroup("suggestion", eventStream),
        applications: new EventGroup("application", eventStream),
        calls: new EventGroup("call", eventStream),
        feeds: new EventGroup("feed", eventStream),
        logins: new EventGroup("login", eventStream),
        leads: new EventGroup("lead", eventStream),
        roles: new EventGroup("role", eventStream),
        files: new EventGroup("file", eventStream),
        folders: new EventGroup("folder", eventStream),
        organizations: new EventGroup("organization", eventStream),
        people: new EventGroup("person", eventStream),
        products: new EventGroup("product", eventStream),
        contactPoints: new EventGroup("contactpoint", eventStream),
        tags: new EventGroup("tag", eventStream),
        tickets: new EventGroup("ticket", eventStream),
        notes: new EventGroup("note", eventStream),
        comments: new EventGroup("comment", eventStream),
        employees: new EventGroup("employee", eventStream),
        tagCategories: new EventGroup("tagcategory", eventStream),
        workCategories: new EventGroup("workcategory", eventStream),
        absenceCategories: new EventGroup("absencecategory", eventStream),
        meetings: new EventGroup("meeting", eventStream),
        productCategories: new EventGroup("productcategory", eventStream),
        projects: new EventGroup("project", eventStream),
        polls: new EventGroup("poll", eventStream),
        tasks: new EventGroup("task", eventStream),
        routines: new EventGroup("routine", eventStream),
        routineExecutions: new EventGroup("routineexecution", eventStream),
        routineCategories: new EventGroup("routinecategory", eventStream),
        orders: new EventGroup("order", eventStream),
        orderCategories: new EventGroup("ordercategory", eventStream),
        invoices: new EventGroup("invoice", eventStream),
        activityGroups: new EventGroup("activitygroup", eventStream),
        resources: new EventGroup("resource", eventStream),
        resourceCategories: new EventGroup("resourcecategory", eventStream),
        subscriptions: new EventGroup("subscription", eventStream),
        subscriptionTransfers: new EventGroup("subscriptiontransfer", eventStream),
        incidents: new EventGroup("incident", eventStream),
        incidentCategories: new EventGroup("incidentcategory", eventStream),
        channels: new EventGroup("channel", eventStream),
        externalLinks: new EventGroup("externalLink", eventStream),
        dashboards: new EventGroup("dashboard", eventStream),
        news: new EventGroup("news", eventStream),
        customerCategories: new EventGroup("customercategory", eventStream),
        supplierCategories: new EventGroup("suppliercategory", eventStream),
        measuringPoints: new EventGroup("measuringpoint", eventStream),
        sensors: new EventGroup("sensor", eventStream),
        measuringPointTemplates: new EventGroup("measuringpointtemplate", eventStream),
        measurements: new EventGroup("measurement", eventStream),
        notifications: new EventGroup("notifications", eventStream),
        sensoralerts: new EventGroup("sensoralert", eventStream),
        sensorevents: new EventGroup("sensorevent", eventStream),
        
    };

    return events;
}

export default eventClient;
