import * as React from "react";
import _ from 'lodash';
import { Button } from "antd";

interface ColumnExpanderProps {
    shownElements?: number;
    expandWith?: number;
    className?: string;
    children?: React.ReactNode;
}

interface ColumnExpanderState {
    takeElements: number;
}


export class ColumnExpander extends React.Component<ColumnExpanderProps, ColumnExpanderState> {
    constructor(props: any) {
        super(props);

        this.state = {
            takeElements: this.props.shownElements ? this.props.shownElements : 1
        }
    }

    onShowMore = (e) => {
        e.stopPropagation();

        let shownElements = this.state.takeElements;
        const expandValue = this.props.expandWith ? this.props.expandWith : 5;
        shownElements += expandValue;
        this.setState({ takeElements: shownElements });
    }

    render = () => {
        if (!this.props.children) return null;

        //@ts-ignore
        const hideDeleted = _.reject(this.props.children, (c: any) => {
            if (c.props && c.props.children && c.props.children.props && c.props.children.props.state)
                return c.props.children.props.state.deleted;
            else
                return false;
        });

        let take = this.state.takeElements;

        const total = Object.keys(hideDeleted).length;
        if (total == 2)
            take = 2

        const shownElements = _.take(hideDeleted, take);
        const shown = shownElements.length;
        const remaining = total - shown;

        return (
            <div className={`list-column-extender ${this.props.className ?? ""}`}>
                {shownElements}
                {shown < total ? <Button size="small" onClick={this.onShowMore}>{remaining} more...</Button> : null}
            </div>
        );
    }
}