import { useState, useEffect, useContext, useCallback, useRef } from "react";
import moment from 'moment';
import _ from 'lodash';
import { Button, Tooltip, Card, Popconfirm } from "antd";
import { WarningOutlined, DeleteOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons';
import Title from "antd/lib/typography/Title";
import { PagedSubscriptionLineQuery, ProductReportItemView, ResumeOrUpdateSubscriptionLine, StopSubscriptionLine, SubscriptionLineHistoryView, SubscriptionLineView, SubscriptionType, SubscriptionView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addParamsToQuery } from "../../Helpers/QueryHashHandlers";
import { findActiveSubscriptionline, findCurrentTypeOrStatus, findLastSubscriptionLine, findLatestFutureLine } from "./SubscriptionHelper";
import { ProductLink } from "../Products";
import { formatCurrency } from "../../Helpers/Formatters";
import { Capabilities } from "../../Definitions/_capabilties";
import { RequireCapability } from "../Shared/RequireCapability";
import { ListView } from "../Shared/ListView";
import EnumSelector from "../Shared/EnumSelector";
import { Drawer } from "../Shared/Drawer";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import SubscriptionLineCreateForm from "./SubscriptionLineCreateForm";
import SubscriptionLineResumeOrUpdateForm from "./SubscriptionLineResumeOrUpdateForm";
import SubscriptionLineUpdateForm from "./SubscriptionLineUpdateForm";
import SubscriptionLineStopForm from "./SubscriptionLineStopForm";
import { RiStopCircleLine } from 'react-icons/ri'
import { usePreviousValue } from "../../Hooks/usePreviousValue";

interface SubscriptionLineListProps {
    subscription: SubscriptionView;
    productStatus: ProductReportItemView;
    onProductStatusEditClose?: () => void;
}

enum SubscriptionLineTypes {
    All = "All",
    Active = "Active",
    Inactive = "Inactive"
}

export enum LineDrawerActionType {
    Add = "Add",
    Remove = "Remove",
    Edit = "Edit"
}

export const SubscriptionLineList: React.FC<SubscriptionLineListProps> = ({ subscription, productStatus, onProductStatusEditClose }) => {
    const context = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [showAddDrawer, setShowAddDrawer] = useState(false);
    const [showEditDrawer, setShowEditDrawer] = useState(false);
    const [showRemoveDrawer, setShowRemoveDrawer] = useState(false);
    const [selected, setSelected] = useState<SubscriptionLineView | null>(null);
    const [historyLine, setHistoryLine] = useState<SubscriptionLineHistoryView | null>(null);
    const [expandedLines, setExpandedLines] = useState<string[]>([]);
    const [expandedOnSelect, setExpandedOnSelect] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [filter, setFilter] = useState<SubscriptionLineTypes>(SubscriptionLineTypes.Active);
    const [query, setQuery] = useState<Partial<PagedSubscriptionLineQuery>>({});
    const [subscriptionLines, setSubscriptionLines] = useState<SubscriptionLineView[]>([]);
    const [selectedLines, setSelectedLines] = useState<SubscriptionLineView[]>([]);
    const [lineDrawerActionType, setLineDrawerActionType] = useState<LineDrawerActionType | null>(null);
    const [hideAddDrawerContent, setHideAddDrawerContent] = useState(false);
    const [hideEditDrawerContent, setHideEditDrawerContent] = useState(false);
    const [hideStopDrawerContent, setHideStopDrawerContent] = useState(false);
    const [hideProductStatusEditDrawerContent, setHideProductStatusEditDrawerContent] = useState(false);

    const previousSubscription = usePreviousValue(subscription);

    const loadLines = useCallback(async (funcQuery?: Partial<PagedSubscriptionLineQuery>) => {
        setLoading(true);

        const q = funcQuery ?? query;

        const response = await client.subscriptions.querySubscriptionLines(subscription.id, q).catch(exception => setError(exception.error.title));
        if (response) {
            setSubscriptionLines(response.data.items);
            setQuery(response.data.query);
        }

        setLoading(false);
    }, [query, subscription.id]);

    const onItemUpdatedEvent = useCallback(async (funcSubscription: SubscriptionView,) => {
        if (subscription?.id === funcSubscription.id) {
            setTimeout(async () => {
                const response = await client.subscriptions.querySubscriptionLines(funcSubscription.id, query).catch(exception => setError(exception.error.title));
                if (response) setSubscriptionLines(response.data.items);
            }, 1000)
        }
    }, [query, subscription?.id]);

    const eventHandlersRef = useRef({
        'updated': onItemUpdatedEvent,
        'updated:attributes': onItemUpdatedEvent,
        'deleted': onItemUpdatedEvent,
        'restored': onItemUpdatedEvent
    });

    useEffect(() => {
        loadLines();
    }, [])

    useEffect(() => {
        const handlers = eventHandlersRef.current;
        context.events.subscriptions.onMany(handlers);

        return () => {
            context.events.subscriptions.offMany(handlers);
        };
    }, [context.events.subscriptions]);

    useEffect(() => {
        if (previousSubscription && previousSubscription?.id != subscription?.id) {
            loadLines(query);
            setFilter(SubscriptionLineTypes.Active);
        }

        if (productStatus?.product?.id) {
            const updateFromProductStatusMatches = _.filter(subscriptionLines, line => line.product.id == productStatus?.product?.id);
            if (updateFromProductStatusMatches.length == 0 && onProductStatusEditClose)
                onProductStatusEditClose();
        }
    }, [subscription, previousSubscription, productStatus, loadLines, query, subscriptionLines, onProductStatusEditClose,]);


    const onFilterChange = async (filterType: SubscriptionLineTypes) => {
        setFilter(filterType);
    }

    const onRemove = async (subLine: SubscriptionLineView) => {
        setSelected(subLine);
        setShowRemoveDrawer(true);
    }

    const onRemoveClose = async () => {
        setSelected(null);
        setShowRemoveDrawer(false);
        setHideStopDrawerContent(false);
    }

    const onRemoveComplete = (updated: SubscriptionLineView[]) => {
        if (!updated) return;

        const collection = subscriptionLines?.slice() ?? [];

        _.each(updated, s => {
            const index = _.findIndex(collection, c => c.id == s.id);
            if (index == -1) collection.push(s);
            else collection[index] = s;
        });

        setSelected(null);
        setShowRemoveDrawer(false);
        setSelectedLines([]);
        setSubscriptionLines(collection);
    }

    const toggleAddDrawer = () => {
        setShowAddDrawer(!showAddDrawer);
        setHideAddDrawerContent(false);
    }

    const onAddLinesComplete = (created: SubscriptionLineView[]) => {
        if (!created) return;

        const collection = subscriptionLines?.slice() ?? [];

        _.each(created, s => {
            const index = _.findIndex(collection, c => c.id == s.id);
            if (index == -1) collection.push(s);
            else collection[index] = s;
        });

        setSubscriptionLines(collection);
        toggleAddDrawer();
    }

    const toggleEditDrawer = (type: LineDrawerActionType) => {
        setShowEditDrawer(!showEditDrawer);
        setLineDrawerActionType(type);
    }

    const toggleRemoveDrawer = () => {
        setShowRemoveDrawer(!showRemoveDrawer);
    }

    const onEdit = async (subLine: SubscriptionLineView) => {
        setSelected(subLine);
        setShowEditDrawer(true);
    }

    const onEditClose = () => {
        const expandedLinesCopy = expandedLines.slice();
        const line = _.find(expandedLinesCopy, l => l == selected?.id);

        let expandedOnSelectCopy = expandedOnSelect;
        if (line != null && expandedOnSelect == selected?.id) {
            const index = expandedLinesCopy.indexOf(line);
            if (index != -1) {
                expandedLinesCopy.splice(index, 1);
                expandedOnSelectCopy = null;
            }
        }

        setSelected(null);
        setShowEditDrawer(false);
        setHideEditDrawerContent(false);
        setExpandedOnSelect(expandedOnSelectCopy);
        setExpandedLines(expandedLinesCopy);
    }

    const onEditComplete = (updated: SubscriptionLineView[]) => {
        const collection = subscriptionLines?.slice() ?? [];

        _.each(updated, s => {
            const index = _.findIndex(collection, c => c.id == s.id);
            if (index == -1) collection.push(s);
            else collection[index] = s;
        });

        const expandedLinesCopy = expandedLines.slice();
        const line = _.find(expandedLinesCopy, l => l == selected?.id);

        let expandedOnSelectCopy = expandedOnSelect;
        if (line != null && expandedOnSelect == selected?.id) {
            const index = expandedLinesCopy.indexOf(line);
            if (index != -1) {
                expandedLinesCopy.splice(index, 1);
                expandedOnSelectCopy = null;
            }
        }

        if (productStatus && onProductStatusEditClose)
            onProductStatusEditClose();

        setSelected(null);
        setShowEditDrawer(false);
        setSelectedLines([]);
        setExpandedOnSelect(expandedOnSelectCopy);
        setExpandedLines(expandedLinesCopy);
        setSubscriptionLines(collection);
    }

    const onEditHistoryLine = (historyLine: SubscriptionLineHistoryView) => {
        setHistoryLine(historyLine);
    }

    const onEditHistoryComplete = (updated?: SubscriptionLineView) => {
        const collection = subscriptionLines?.slice() ?? [];

        if (updated) {
            const index = _.findIndex(collection, c => c.id == updated.id);
            if (index != -1) collection[index] = updated;
        }

        setHistoryLine(null);
        setHideEditDrawerContent(false);
        setSubscriptionLines(collection);
    }

    const onExpand = (expanded: boolean, record: SubscriptionLineView) => {
        setLoading(true);

        try {
            if (expanded) {
                const collection = subscriptionLines.slice();
                const line = _.find(collection, c => { return c.id === record.id; });
                if (line) {
                    const expandedLinesCopy = [...expandedLines, line.id];
                    setExpandedLines(expandedLinesCopy);
                }
            } else {
                const index = expandedLines.indexOf(record.id);
                if (index > -1) {
                    const expandedLinesCopy = [...expandedLines];
                    expandedLinesCopy.splice(index, 1);
                    setExpandedLines(expandedLinesCopy);
                }
            }
        }
        catch (error: any) {
            setError(error.message);
        }

        setLoading(false);
    }

    const onQueryChange = async (group: Partial<PagedSubscriptionLineQuery>) => {
        let queryCopy = Object.assign({}, query);
        queryCopy = await addParamsToQuery(queryCopy, group, false);
        loadLines(queryCopy);
    }

    const rowSelectionHandler = (keys: string[], items: SubscriptionLineView[]) => {
        setSelectedLines(items);
    }

    const onLineUpdateSuccess = (lines: SubscriptionLineView[]) => {
        const selectedLinesCopy = selectedLines?.slice() ?? [];

        let change = false;

        _.each(lines, line => {
            const match = _.find(selectedLinesCopy, l => l.id == line.id);
            if (match) {
                const index = selectedLinesCopy.indexOf(match);
                if (index != -1) {
                    selectedLinesCopy.splice(index, 1);
                    change = true;
                }
            }
        });

        if (change)
            setSelectedLines(selectedLinesCopy);
    }

    const onSelect = (line: SubscriptionLineView) => {
        if (selectedLines && selectedLines.length > 0) return;

        const expandedLinesCopy = expandedLines.slice();
        const isExpanded = _.find(expandedLinesCopy, l => l == line.id) != null;

        if (!isExpanded)
            expandedLinesCopy.push(line.id);

        setSelected(line);
        setShowEditDrawer(true);
        setExpandedOnSelect(isExpanded ? null : line.id);
        setExpandedLines(expandedLinesCopy);
        setLineDrawerActionType(LineDrawerActionType.Edit);
    }

    const onRemoveLine = async (lineHistory: SubscriptionLineHistoryView) => {
        const request: StopSubscriptionLine = {
            application: lineHistory.start,
        };

        const response = await client.subscriptions
            .stopSubscriptionLine(subscription.id, lineHistory.lineId, request, addChangeVectorHeader(subscription.changeVector))
            .catch();

        if (!response) return;

        const collection = subscriptionLines?.slice() ?? [];
        const index = _.findIndex(collection, c => c.id == response.data.id);
        if (index == -1) return;

        collection[index] = response.data;
        setSubscriptionLines(collection);
    }

    const onResumeInactiveLine = async (line: SubscriptionLineView) => {
        if (line) {
            const sortedHistory = _.orderBy(line.history, h => h.end, "desc");
            const lastInactiveSubscriptionLine = _.find(sortedHistory, h => h.end != null);

            if (!lastInactiveSubscriptionLine) return;

            const request: ResumeOrUpdateSubscriptionLine = {
                application: lastInactiveSubscriptionLine.start,
                quantity: lastInactiveSubscriptionLine.quantity,
                discount: lastInactiveSubscriptionLine.discount,
                note: lastInactiveSubscriptionLine.note,
                internalNote: lastInactiveSubscriptionLine.internalNote
            };

            const response = await client.subscriptions
                .resumeOrUpdateSubscriptionLine(subscription.id, lastInactiveSubscriptionLine.lineId, request, addChangeVectorHeader(subscription.changeVector))
                .catch();

            if (!response) return;

            const collection = subscriptionLines?.slice() ?? [];
            const index = _.findIndex(collection, c => c.id == response.data.id);
            if (index == -1) return;

            collection[index] = response.data;
            setSubscriptionLines(collection);
        }
    }

    const onResumeLine = async (lineHistory: SubscriptionLineHistoryView) => {
        if (lineHistory) {
            const request: ResumeOrUpdateSubscriptionLine = {
                application: lineHistory.start,
                quantity: lineHistory.quantity,
                discount: lineHistory.discount,
                note: lineHistory.note,
                internalNote: lineHistory.internalNote
            };

            const response = await client.subscriptions
                .resumeOrUpdateSubscriptionLine(subscription.id, lineHistory.lineId, request, addChangeVectorHeader(subscription.changeVector))
                .catch();

            if (!response) return;

            const collection = subscriptionLines?.slice() ?? [];
            const index = _.findIndex(collection, c => c.id == response.data.id);
            if (index == -1) return;

            collection[index] = response.data;
            setSubscriptionLines(collection);
        }
    }

    const onRevertLine = async (history: SubscriptionLineHistoryView[]) => {
        const sortedHistory = _.orderBy(history, h => h.end, "desc");
        const lastInactiveSubscriptionLine = _.find(sortedHistory, h => h.end != null);

        if (lastInactiveSubscriptionLine) {
            const request: ResumeOrUpdateSubscriptionLine = {
                application: lastInactiveSubscriptionLine.start,
                quantity: lastInactiveSubscriptionLine.quantity,
                discount: lastInactiveSubscriptionLine.discount,
                note: lastInactiveSubscriptionLine.note,
                internalNote: lastInactiveSubscriptionLine.internalNote
            };

            const response = await client.subscriptions
                .resumeOrUpdateSubscriptionLine(subscription.id, lastInactiveSubscriptionLine.lineId, request, addChangeVectorHeader(subscription.changeVector))
                .catch();

            if (!response) return;

            const collection = subscriptionLines?.slice() ?? [];
            const index = _.findIndex(collection, c => c.id == response.data.id);
            if (index == -1) return;

            collection[index] = response.data;
            setSubscriptionLines(collection);
        }
    }

    if (error) console.log(error);
    if (!subscription || !subscriptionLines) return null;

    const currentType = findCurrentTypeOrStatus(subscription.typeHistory).value;

    const filteredLines = _.filter(subscriptionLines || [], (line) => {
        if (filter == SubscriptionLineTypes.Active)
            return !line.end || moment(line.end).isSameOrAfter(moment().startOf("day"));
        else if (filter == SubscriptionLineTypes.Inactive)
            return line.end && moment(line.end) < moment().startOf("day");
        else
            return true;
    });

    const hasSelectedLines = selectedLines != null && selectedLines.length > 0;

    const findRelevantSubscriptionLine = (line: SubscriptionLineView) => {
        let res = findActiveSubscriptionline(line, false);
        if (res == null) res = findActiveSubscriptionline(line, true);
        if (res == null) res = findLastSubscriptionLine(line);
        return res;
    }

    const getQuantityIndicator = (line: SubscriptionLineView) => {
        const current = findActiveSubscriptionline(line, false);
        const future = findLatestFutureLine(line);

        if (current && !future)
            return "";
        if (!current && future)
            return `from ${moment(future.start).format("DD-MM-YY")}`;
        if (current && future && current.quantity != future.quantity)
            return `(${future.quantity} from ${moment(future.start).format("DD-MM-YY")})`;

        return "";
    }

    const listColumns = [
        {
            title: 'Product code',
            render: (data: SubscriptionLineView) => {

                if (!data.product) return null;
                return <span>{data.product.code}</span>;
            },
            key: 'productCode',
            width: 150,
            sorter: 'productCode'
        },
        {
            title: 'Product name',
            render: (data: SubscriptionLineView) => {

                if (!data.product) return null;

                let warning = null;
                let color = null;

                if (subscription.relatedReports && subscription.relatedReports.products) {
                    const rep = _.find(subscription.relatedReports.products.items ?? [], (item: ProductReportItemView) => {
                        return item.product.id == data.product.id
                    })

                    //var report = _.find(subscription.relatedReports.products.differences || [], (x: ProductDifference) => x.product.id == data.product.id);

                    if (rep && rep.valuesBySource == null) {
                        warning = "Product was not included in latest report, does not contain resources and is not in a category that is excluded from differences.";
                        color = "#FA8C16";
                    }
                    else if (rep && _.find(rep.valuesBySource, (values) => {
                        const reported = _.find(values.reported, (value) => value.type == null || value.type == currentType);
                        return reported != null && reported.count > 0;
                    }) == null) {
                        warning = "Product was not reported for this subscription type.";
                        color = "#fcd77a";
                    }
                }

                return <><ProductLink {...data.product} />{warning ? <Tooltip title={warning}><WarningOutlined style={{ color: color, marginLeft: "10px" }} /></Tooltip> : null}</>;
            },
            key: 'productName',
            sorter: 'productName'
        },
        {
            title: 'Invoice note',
            render: (data: SubscriptionLineView) => <span>{findRelevantSubscriptionLine(data)?.note ?? ""}</span>,
            key: 'invoiceNote',
        },
        {
            title: 'Quantity',
            render: (data: SubscriptionLineView) => <span>{findRelevantSubscriptionLine(data)?.quantity ?? ""} {getQuantityIndicator(data)}</span>,
            key: 'quantity',
            width: 200,
            sorter: 'quantity'
        },
        {
            title: currentType == SubscriptionType.Yearly ? 'Yearly price' : 'Monthly price',
            render: (data: SubscriptionLineView) => {
                if (currentType == SubscriptionType.Quarterly) {
                    const monthly = data.unitPrice / 3;
                    return formatCurrency(monthly, 2);
                }
                return formatCurrency(data.unitPrice, 2);
            },
            key: 'unitPrice',
            sorter: 'unitPrice',
            width: 120
        },
        {
            title: 'Discount (%)',
            render: (data: SubscriptionLineView) => <span>{findRelevantSubscriptionLine(data)?.discount ?? ""}</span>,
            key: 'discount',
            width: 115,
            sorter: 'discount'
        },
        {
            title: currentType == SubscriptionType.Yearly ? 'Yearly value' : 'Monthly value',
            render: (data: SubscriptionLineView) => {
                const monthlyValue = data.monthlyValue ?? 0;
                if (currentType == SubscriptionType.Yearly) {
                    return formatCurrency(monthlyValue * 12, 2);
                }
                return formatCurrency(monthlyValue, 2);
            },
            key: 'monthlyValue',
            sorter: 'monthlyValue',
            width: 200
        }
    ];

    if (context.user.hasAnyCapability([Capabilities.SubscriptionsWrite])) {
        listColumns.push({
            title: 'Actions',
            render: (data: SubscriptionLineView) => {
                const isInactive = data.end && moment(data.end) < moment().startOf("day");

                if (isInactive) {
                    return (
                        <div className="table-actions">
                            <Button
                                shape="circle"
                                icon={<EditOutlined />}
                                type="default"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEdit(data);
                                }}
                            />
                            <Tooltip title="Remove end date">
                                <Button
                                    shape="circle"
                                    icon={<UndoOutlined />}
                                    type="default"
                                    danger
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onResumeInactiveLine(data)
                                    }}
                                />
                            </Tooltip>
                        </div>
                    );
                }
                else {
                    return (
                        <div className="table-actions">
                            <Button
                                shape="circle"
                                icon={<EditOutlined />}
                                type="default"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onEdit(data);
                                }}
                                disabled={hasSelectedLines}
                            />
                            <Button
                                shape="circle"
                                icon={<RiStopCircleLine style={{ color: "#ff4d4f", width: 35, height: 35 }} />}
                                type="default"
                                danger
                                className="stop-button"
                                loading={false}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onRemove(data)
                                }}
                                disabled={hasSelectedLines}
                            />
                        </div>
                    );
                }
            },
            key: 'actions',
            width: 100,
            sorter: null
        });
    }

    const historyColumns = [
        {
            title: 'Start',
            render: (data: SubscriptionLineHistoryView) => <span>{moment(data.start).format("DD.MM.YYYY")}</span>,
            key: 'start'
        },
        {
            title: 'End',
            render: (data: SubscriptionLineHistoryView) => <span>{data.end ? moment(data.end).format("DD.MM.YYYY") : null}</span>,
            key: 'end'
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Discount (%)',
            dataIndex: 'discount',
            key: 'discount'
        },
        {
            title: 'Invoice note',
            render: (data: SubscriptionLineHistoryView) => _.map((data.note ?? "").split("\n"), (n, key) => <div key={key}>{n}</div>),
            key: 'invoiceNote'
        },
        {
            title: 'Internal note',
            render: (data: SubscriptionLineHistoryView) => _.map((data.internalNote ?? "").split("\n"), (n, key) => <div key={key}>{n}</div>),
            key: 'internalNote'
        },
        {
            title: '',
            render: (data: SubscriptionLineHistoryView) => {
                const now = moment().startOf("day");
                const startOfLine = moment(data.start).startOf("day");
                const expandedLine = _.find(subscriptionLines, sl => sl.id == data.lineId);

                const expandedIsInactive = expandedLine.end && moment(expandedLine.end) < now;

                const pendingFutureChange = _.find(expandedLine?.history ?? [], h => {
                    return moment(h.start).startOf('day').isAfter(now) && !h.end;
                });

                if (expandedLine?.history?.length == 1 && !expandedIsInactive) {
                    return (
                        <RequireCapability capability={Capabilities.SubscriptionsWrite}>
                            <Popconfirm title="Are you sure you want to remove line?" onConfirm={() => onRemoveLine(data)} okText="Yes">
                                <Button
                                    shape="circle"
                                    icon={<DeleteOutlined />}
                                    type="default"
                                    danger
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </Popconfirm>
                        </RequireCapability>
                    );
                }
                else if ((startOfLine.isSameOrBefore(now) && (!data.end || moment(data.end).endOf("day").isAfter(now))) && pendingFutureChange == null) {
                    if (data.end == null) {
                        return (
                            <RequireCapability capability={Capabilities.SubscriptionsWrite}>
                                <div className="table-actions">
                                    <Button
                                        shape="circle"
                                        icon={<EditOutlined />}
                                        type="default"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onEditHistoryLine(data);
                                        }}
                                    />
                                    <Popconfirm title="Are you sure you want to revert line?" onConfirm={() => onRevertLine(expandedLine.history)} okText="Yes">
                                        <Button
                                            shape="circle"
                                            icon={<DeleteOutlined />}
                                            type="default"
                                            danger
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    </Popconfirm>
                                </div>
                            </RequireCapability>
                        );
                    }
                    else {
                        return (
                            <RequireCapability capability={Capabilities.SubscriptionsWrite}>
                                <Tooltip title="Remove end date">
                                    <Button
                                        shape="circle"
                                        icon={<UndoOutlined />}
                                        type="default"
                                        danger
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            onResumeLine(data)
                                        }}
                                    />
                                </Tooltip>
                            </RequireCapability>
                        );
                    }
                }
                else if (pendingFutureChange != null && startOfLine.isAfter(now) && !data.end) {
                    return (
                        <RequireCapability capability={Capabilities.SubscriptionsWrite}>
                            <div className="table-actions">
                                <Button
                                    shape="circle"
                                    icon={<EditOutlined />}
                                    type="default"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onEditHistoryLine(data);
                                    }}
                                />
                                <Popconfirm title="Are you sure you want to revert line?" onConfirm={() => onRevertLine(expandedLine.history)} okText="Yes">
                                    <Button
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        type="default"
                                        danger
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    />
                                </Popconfirm>
                            </div>
                        </RequireCapability>
                    );
                }
            },
            key: 'revert'
        },
    ];

    const subscriptionlinesSubLists = _.map(expandedLines, (id) => {
        const match = _.find(subscriptionLines, entity => entity.id === id);
        if (match) {

            const data = _.map(match.history, (value, index) => {
                return Object.assign({ id: `${id}:${index}`, key: id }, value);
            });

            return <ListView
                key={id}
                onQueryChange={() => { }}
                columns={historyColumns}
                collection={data}
                loading={loading}
                className="subscriptionline-sublist"
            />;
        }
    });

    const tableActions = [
        <EnumSelector
            key="line-selector"
            enum={SubscriptionLineTypes}
            value={filter}
            placeholder={"Subscription type"}
            onChange={onFilterChange}
            excludeAllOption
            className={`subscription-lines-selector`}
            disabled={hasSelectedLines}
        />,
        <RequireCapability capability={Capabilities.SubscriptionsWrite} key="add-btn">
            <Button className="action" onClick={toggleAddDrawer} disabled={hasSelectedLines}>Add new</Button>
        </RequireCapability>
    ];

    if (hasSelectedLines) {
        tableActions.push(
            <RequireCapability capability={Capabilities.SubscriptionsWrite} key="add-to-btn">
                <Button className="action" onClick={() => toggleEditDrawer(LineDrawerActionType.Add)}>Add to selected</Button>
            </RequireCapability>
        );

        tableActions.push(
            <RequireCapability capability={Capabilities.SubscriptionsWrite} key="remove-from-btn">
                <Button className="action" onClick={() => toggleEditDrawer(LineDrawerActionType.Remove)}>Remove from selected</Button>
            </RequireCapability>
        );

        tableActions.push(
            <RequireCapability capability={Capabilities.SubscriptionsWrite} key="stop-multiple-btn">
                <Button className="action" onClick={toggleRemoveDrawer}>Stop selected</Button>
            </RequireCapability>
        );
    }

    const updateFromProductStatusMatches = _.filter(_.filter(subscriptionLines, line => line.product.id == productStatus?.product?.id), line => findActiveSubscriptionline(line, true) != null);
    const productStatusLine = updateFromProductStatusMatches.length == 1 ? updateFromProductStatusMatches[0] : null;

    return (
        <>
            <Drawer
                title="Add subscription line"
                onClose={toggleAddDrawer}
                open={showAddDrawer}
                destroyOnClose
                toggleOpacity
                toggleOpacityStyle={() => setHideAddDrawerContent(prev => !prev)}
                hideDrawerContent={hideAddDrawerContent}
                className="opacityDrawer"
                component={
                    <SubscriptionLineCreateForm
                        subscription={subscription}
                        onComplete={onAddLinesComplete}
                        onCancel={toggleAddDrawer}
                        hideContent={hideAddDrawerContent}
                    />
                }
            />

            <Drawer
                title={selected
                    ? `Edit subscription line - ${selected?.product?.name} (${selected?.product?.code ?? ""})`
                    : lineDrawerActionType == LineDrawerActionType.Add && selectedLines?.length > 0
                        ? "Add to selected lines"
                        : lineDrawerActionType == LineDrawerActionType.Remove && selectedLines?.length > 0
                            ? "Remove from selected lines"
                            : ""}
                onClose={onEditClose}
                open={showEditDrawer}
                destroyOnClose
                toggleOpacity
                toggleOpacityStyle={() => setHideEditDrawerContent(prev => !prev)}
                hideDrawerContent={hideEditDrawerContent}
                component={
                    <SubscriptionLineResumeOrUpdateForm
                        subscription={subscription}
                        line={selected}
                        onComplete={onEditComplete}
                        onCancel={onEditClose}
                        selectedLines={selectedLines}
                        lineDrawerType={lineDrawerActionType}
                        onLineUpdateSuccess={onLineUpdateSuccess}
                        hideContent={hideEditDrawerContent}
                        disableSave={!context.user.hasCapability(Capabilities.SubscriptionsWrite)}
                    />
                }
            />

            <Drawer
                title={`Edit subscription line - ${(_.find(subscriptionLines, el => { return el.id == historyLine?.lineId }))?.product?.name} (${(_.find(subscriptionLines, el => { return el.id == historyLine?.lineId }))?.product?.code})`}
                onClose={() => onEditHistoryComplete()}
                open={historyLine != null}
                destroyOnClose
                toggleOpacity
                toggleOpacityStyle={() => setHideEditDrawerContent(prev => !prev)}
                hideDrawerContent={hideEditDrawerContent}
                component={
                    <SubscriptionLineUpdateForm
                        subscription={subscription}
                        line={historyLine}
                        onComplete={onEditHistoryComplete}
                        onCancel={onEditHistoryComplete}
                        hideContent={hideEditDrawerContent}
                    />
                }
            />

            <Drawer
                title={selected
                    ? `Stop subscription line - ${selected?.product?.name} (${selected?.product?.code ?? ""})`
                    : selectedLines?.length > 0
                        ? "Stop selected subscription lines"
                        : ""}
                onClose={onRemoveClose}
                open={showRemoveDrawer}
                destroyOnClose
                toggleOpacity
                toggleOpacityStyle={() => setHideStopDrawerContent(prev => !prev)}
                hideDrawerContent={hideStopDrawerContent}
                component={
                    <SubscriptionLineStopForm
                        subscription={subscription}
                        line={selected}
                        onComplete={onRemoveComplete}
                        onCancel={onRemoveClose}
                        selectedLines={selectedLines}
                        onLineUpdateSuccess={onLineUpdateSuccess}
                        hideContent={hideStopDrawerContent}
                    />
                }
            />

            <Drawer
                title={productStatus ? `Edit subscription line - ${productStatus.product?.name ?? ""}` : "Edit subscription line"}
                onClose={onProductStatusEditClose}
                open={productStatus != null && updateFromProductStatusMatches?.length > 0}
                destroyOnClose
                toggleOpacity
                toggleOpacityStyle={() => setHideProductStatusEditDrawerContent(prev => !prev)}
                hideDrawerContent={hideProductStatusEditDrawerContent}
                component={
                    <SubscriptionLineResumeOrUpdateForm
                        subscription={subscription}
                        line={productStatusLine}
                        onComplete={context.user.hasCapability(Capabilities.SubscriptionsWrite) ? onEditComplete : null}
                        onCancel={onProductStatusEditClose}
                        selectedLines={selectedLines}
                        lineDrawerType={lineDrawerActionType}
                        onLineUpdateSuccess={onLineUpdateSuccess}
                        updateFromProductStatus={updateFromProductStatusMatches}
                        hideContent={hideProductStatusEditDrawerContent}
                    />
                }
            />

            <Card
                title={
                    <div className="list-title">
                        <Title level={4} className="title">
                            Subscription lines
                        </Title>

                        {tableActions}
                    </div>}
                className="subscription-lines"
            >
                <ListView
                    onQueryChange={onQueryChange}
                    loading={loading}
                    columns={listColumns}
                    collection={filteredLines}
                    onExpand={onExpand}
                    nestedSubTables={subscriptionlinesSubLists}
                    rowSelectionHandler={rowSelectionHandler}
                    selectedKeys={_.map(selectedLines, l => l.id)}
                    onSelect={onSelect}
                    expandedRowKeys={expandedLines}
                />
            </Card>

        </>
    );
}

export default SubscriptionLineList;