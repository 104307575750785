/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateTag,
  SortDirection,
  TagSortOption,
  TagView,
  TagViewEntityViewModel,
  TagViewPagedTagQueryCollectionViewModel,
  TaggableType,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Tags<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Tag
   * @name GetTagById
   * @request GET:/api/tags/{id}
   * @secure
   */
  getTagById = (id: string, params: RequestParams = {}) =>
    this.http.request<TagViewEntityViewModel, any>({
      path: `/api/tags/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tag
   * @name UpdateTag
   * @request PUT:/api/tags/{id}
   * @secure
   */
  updateTag = (id: string, data: CreateOrUpdateTag, params: RequestParams = {}) =>
    this.http.request<TagView, any>({
      path: `/api/tags/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tag
   * @name DeleteTag
   * @request DELETE:/api/tags/{id}
   * @secure
   */
  deleteTag = (id: string, params: RequestParams = {}) =>
    this.http.request<TagView, any>({
      path: `/api/tags/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tag
   * @name CreateTag
   * @request POST:/api/tags
   * @secure
   */
  createTag = (data: CreateOrUpdateTag, params: RequestParams = {}) =>
    this.http.request<TagView, any>({
      path: `/api/tags`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tag
   * @name QueryTags
   * @request GET:/api/tags
   * @secure
   */
  queryTags = (
    query?: {
      sortBy?: TagSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      categoryId?: string;
      taggableTypes?: TaggableType[];
      excludedCategories?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TagViewPagedTagQueryCollectionViewModel, any>({
      path: `/api/tags`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tag
   * @name RestoreTag
   * @request POST:/api/tags/{id}/restore
   * @secure
   */
  restoreTag = (id: string, params: RequestParams = {}) =>
    this.http.request<TagView, any>({
      path: `/api/tags/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tag
   * @name UpdateTagAttributes
   * @request POST:/api/tags/{id}/attributes
   * @secure
   */
  updateTagAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<TagView, any>({
      path: `/api/tags/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
