import _ from "lodash";
import moment from "moment";
import client from "../client";
import { InvoiceView, PagedInvoiceQuery } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class InvoiceExport extends BaseExportService<PagedInvoiceQuery, InvoiceView> {

    constructor() {

        const specification: ExportConfig<InvoiceView>[] = [
            { header: 'Number', value: (data: InvoiceView) => data.invoiceNo ?? null },
            {
                header: 'Customer', value: (data: InvoiceView) => {
                    let roles = "";
                    if (data.customer) roles = roles + data.customer.name
                    if (data.invoiceReceiver && data.customer?.id != data.invoiceReceiver.id) {
                        if (roles.length > 0) roles = roles + ", "
                        roles = roles + "Invoice receiver: " + data.invoiceReceiver.name
                    }
                    return roles;
                }
            },
            { header: 'Label', value: (data: InvoiceView) => data.label ?? null },
            { header: 'Order', value: (data: InvoiceView) => data.order ? data.order.orderNumbers.map((value) => { return value.applicationLink.name + ": " + value.orderNumber }).join(", ") : null },
            { header: 'Project', value: (data: InvoiceView) => data.project ? data.project.name : null },
            { header: 'Issued', value: (data: InvoiceView) => data.issued ? moment(data.issued).format() : null },
            { header: 'Due date', value: (data: InvoiceView) => data.dueDate ? moment(data.dueDate).format() : null },
            { header: 'Net amount', value: (data: InvoiceView) => data.netSum, format: ExportFormat.Currency },
            { header: 'Gross amount', value: (data: InvoiceView) => data.grossSum, format: ExportFormat.Currency },
            { header: 'Last modified', value: (data: InvoiceView) => data.lastModified ? moment(data.lastModified).format() : null },
            {
                header: 'Attributes', value: (data: InvoiceView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })

                    }

                    return dataString
                }
            },
            { header: 'Deleted', value: (data: InvoiceView) => data.deleted ? true : false }
        ];

        super(specification, client.invoices.queryInvoices);
    }
}