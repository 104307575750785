import * as React from "react";
import moment from "moment";
import { Card, Typography, Progress } from 'antd';
import AppContext from "../../Definitions/AppContext";
import { TaskView } from "../../ApiClient/swagger/data-contracts";
import { formatDuration } from "../../Helpers/Formatters";

const { Title } = Typography;

interface TaskProgressionCardProps {
    task: TaskView;
}

export class TaskProgressionCard extends React.Component<TaskProgressionCardProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {};
    }

    render = () => {
        const { task } = this.props;
        const estimatedTaskProgress = task.estimate && task.estimate > 0 ? Math.ceil((task.duration / task.estimate) * 100) : 0;
        const taskIsOverEstimate = estimatedTaskProgress > 100;

        const totalTaskDuration = task.aggregatedDuration ?? 0;
        const totalTaskEstimate = task.aggregatedEstimate ?? 0;
        const estimatedSubTaskProgress = totalTaskEstimate > 0 ? Math.ceil((totalTaskDuration / (totalTaskEstimate)) * 100) : 0;
        const subTaskIsOverEstimate = estimatedSubTaskProgress > 100;


        let taskProps = {};
        if (taskIsOverEstimate) {
            const taskPercentage = ((task.estimate ?? 0) / (task.duration ?? 1)) * 100;

            taskProps = {
                success: {
                    percent: taskPercentage,
                    strokeColor: "rgb(255, 165, 0)"
                }
            };
        }

        let subTaskProps = {};
        if (subTaskIsOverEstimate) {
            const subTaskPercentage = ((totalTaskEstimate) / (totalTaskDuration)) * 100;

            subTaskProps = {
                success: {
                    percent: subTaskPercentage,
                    strokeColor: "rgb(255, 165, 0)"
                }
            };
        }

        const taskClosed = task.actualEnd != null;
        const trailColor = '#e5e5e5';

        return (
            <Card
                title={
                    <div className="title-container">

                        <div className="progression-title">
                            <Title level={4} className="title">Progression</Title>
                            {task.actualStart ? <span className="date-container">Started: <span className="date">{moment(task.actualStart).format('DD.MM.YYYY')}</span></span> : null}
                        </div>
                        {task.actualEnd ? <span className="date-container end-date">Closed: <span className="date">{moment(task.actualEnd).format('DD.MM.YYYY')}</span></span> : null}
                    </div>

                }
                className="task-progress-card progression-card"
            >

                <div className={`estimate-container task-estimate}`}>
                    <div className="estimated-progress">
                        <span className="upper-text">Task</span>
                        <span className="upper-text-percentage">{estimatedTaskProgress ? estimatedTaskProgress.toFixed(1) : "-"}%</span>
                    </div>

                    <Progress
                        className="progress-bar"
                        percent={estimatedTaskProgress}
                        status={!taskClosed && !taskIsOverEstimate ? "normal" : taskIsOverEstimate ? "exception" : "success"}
                        showInfo={false}
                        trailColor={trailColor}
                        {...taskProps}
                    />

                    <div className="estimated-progress lower-text">
                        <span className="lower-text">Duration: <span className="duration">{formatDuration(task.duration)}</span></span>
                        <span className="total">Estimate: {task.estimate ? <span className="duration">{formatDuration(task.estimate)}</span> : <span className="duration">-</span>}</span>
                    </div>
                </div>

                <div className="estimate-container estimate-container-nr2 sub-task-estimate">
                    <div className="estimated-progress">
                        <span className="upper-text">Task including subtasks</span>
                        <span className="upper-text-percentage">{estimatedSubTaskProgress ? estimatedSubTaskProgress.toFixed(1) : "-"}%</span>
                    </div>

                    <Progress
                        className="progress-bar"
                        percent={estimatedSubTaskProgress}
                        status={!taskClosed && !subTaskIsOverEstimate ? "normal" : subTaskIsOverEstimate ? "exception" : "success"}
                        showInfo={false}
                        trailColor={trailColor}
                        {...subTaskProps}
                    />

                    <div className="estimated-progress lower-text">
                        <span className="lower-text">Duration: <span className="duration">{formatDuration(totalTaskDuration)}</span></span>
                        <span className="total">Total estimate: <span className="duration">{formatDuration(totalTaskEstimate)}</span></span>
                    </div>
                </div>
            </Card>
        );
    }
}