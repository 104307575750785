import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { DateSelectorProps } from "../../Models/DateSelectorProps";
import { validateDate } from "../../Helpers/QueryHashHandlers";
import MomentDatePicker from "./MomentDatePicker";

interface DateSelectorState {
    date?: moment.Moment,
    //disabledDate: (date: moment.Moment) => boolean;
}

//@ts-ignore
const DatePickerAutoAccept = (props: any) => {
    const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
        const isMoment = moment.isMoment(elem.target.value);
        //@ts-ignore
        const value = isMoment ? props.value : moment(props.value, props.format);
        if (value && validateDate(value) && props.onChange) {
            props.onChange(value, elem.target.value);
        }
    }
    return <MomentDatePicker {...props} onBlur={onBlur}/>
}

class DateSelector extends React.Component<DateSelectorProps, DateSelectorState> {
    _isMounted = false;
    constructor(props: any) {
        super(props);

        this.state = {
            //@ts-ignore
            date: this.props.value ? moment(this.props.value) : null,
            //disabledDate: null
        }
    }

    //handleDisabledDates = () => {
    //    if (this.props.disabledDates)
    //        return this.props.disabledDates();
    //    else
    //        return false;
    //} 

    componentDidMount = async () => {
        this._isMounted = true;
    }

    //componentDidUpdate = (prevProps) => {
    //    if (this._isMounted) {
    //        if (this.props.value != this.state.date) {
    //            var isMoment = moment.isMoment(this.props.value);
    //            var newValue = isMoment ? this.props.value : moment(this.props.value);

    //            var isEqual = _.isEqual(newValue, this.state.date);
    //            if (!isEqual) {
    //                this.setState({ date: newValue });
    //                console.log(newValue);
    //            }
    //        }
    //        else if (this.props.value != this.state.date) {
    //            if (this.props.value == null)
    //                this.setState({ date: this.props.value });
    //            else {
    //                var isMoment = moment.isMoment(this.props.value);
    //                var newValue = isMoment ? this.props.value : moment(this.props.value);

    //                var isEqual = _.isEqual(newValue, this.state.date);
    //                if (!isEqual)
    //                    this.setState({ date: newValue });
    //            }
    //        }
    //    }
    //}

    onDateCalendarChange = (date) => {
        this.setState({ date });

        if (!date) {
            if (this.props.onChange)
                this.props.onChange(null);
            if (this.props.onObjectChange)
                this.props.onObjectChange(null);

            return;
        }

        if (!this.props.showTime && this.props.endOfDay)
            date = date.endOf("day");
        else if (!this.props.showTime)
            date = date.startOf("day")

       
        if (this.props.onChange)
            this.props.onChange(date.format("YYYY-MM-DDTHH:mm:ss"));
        if(this.props.onObjectChange)
            this.props.onObjectChange(moment(date));
    }

    onDateChange = (date) => {
        if (!date) {
            if (this.props.onChange)
                this.props.onChange(null);
            if (this.props.onObjectChange)
                this.props.onObjectChange(null);

            return;
        }

        if (!this.props.showTime && this.props.endOfDay)
            date = date.endOf("day");
        else if (!this.props.showTime)
            date = date.startOf("day")


        if (this.props.onChange)
            this.props.onChange(date.format("YYYY-MM-DDTHH:mm:ss"));
        if (this.props.onObjectChange)
            this.props.onObjectChange(moment(date));
    }

    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select date...'
        const format = this.props.format ? this.props.format : this.props.showTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY";
        const defaultValue = this.props.value ? moment(this.props.value) : null;
        const title = this.props.title ? <label>{this.props.title}</label> : null;

        //@ts-ignore
        const value = this.state.date || defaultValue;


        return (
            <div className="selector date-selector">
                {title}
                <DatePickerAutoAccept
                    {...this.props}
                    style={{ width: "100%" }}
                    className={`date-picker ${this.props.className ? this.props.className : ""}`}
                    disabledDate={this.props.disabledDate}
                    onCalendarChange={this.onDateCalendarChange}
                    onChange={() => this.onDateChange}
                    format={format}
                    size={this.props.size ? this.props.size : "middle"}
                    placeholder={placeholder}
                    allowClear={this.props.disableClear ? false : true}
                    value={value}
                />
            </div>
        );
    }
}

export default DateSelector;