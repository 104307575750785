import * as React from "react";
import _ from 'lodash';
import { PageProps } from "../Definitions/PageProps"; 
import AppContext from "../Definitions/AppContext";

//import MobilePage from './MobilePage';
//import DesktopPage from './DesktopPage';


export class Page extends React.Component<PageProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props)
        this.state = {
            value: undefined
        };
    }

    componentDidMount() {
        //this.context.setNavigationKey(this.props.navigationKey);
        this.context.setBreadcrumbItems(this.props.breadcrumbItems);

        //if (this.props.status !== "200" && this.props.status !== "201")
        //    this.context.setError(this.props.status);
    }

    componentDidUpdate = (prevProps) => {
        this.handleUpdatedBreadCrumb(prevProps);
    }

    handleUpdatedBreadCrumb = (prevProps) => {
        const currentCrumbs = this.props.breadcrumbItems as any;
        const preCrumbs = prevProps.breadcrumbItems as any;

        const currentKeys = currentCrumbs == null ? [] : Array.isArray(currentCrumbs) ? _.map(currentCrumbs, x => x.key || null) : [currentCrumbs.key];
        const previousKeys = preCrumbs == null ? [] : Array.isArray(preCrumbs) ? _.map(preCrumbs, x => x.key || null) : [preCrumbs.key];

        const equal = _.isEqual(previousKeys, currentKeys);
        if (!equal) {
            this.context.setBreadcrumbItems(this.props.breadcrumbItems);
        }
    }

    render = () => {
        return (
            <section className={`page ${this.context.isMobile ? "page-mobile" : "page-desktop"} ${this.props.className}`} id={this.props.id}>
                <main className={`page-content`}>
                    {this.props.children}
                </main>
            </section>
        );

        //if (this.context.isMobile)
        //    return <MobilePage {...this.props} />
        //else
        //    return <DesktopPage {...this.props} />
    }
}

export default Page;