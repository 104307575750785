import { ActivityExport } from "./ActivityExport";
import { CallExport } from "./CallExport";
import { EmployeeExport } from "./EmployeeExport";
import { IncidentExport } from "./IncidentExport";
import { InvoiceExport } from "./InvoiceExport";
import { LeadExport } from "./LeadExport";
import { OrderExport } from "./OrderExport";
import { OrganizationExport } from "./OrganizationExport";
import { PersonExport } from "./PersonExport";
import { ProductExport } from "./ProductExport";
import { ProjectExport } from "./ProjectExport";
import { ResourceExport } from "./ResourceExport";
import { RoutineExport } from "./RoutineExport";
import { SubscriptionExport } from "./SubscriptionExport";
import { SubscriptionReportExport } from "./SubscriptionReportExport";
import { TaskExport } from "./TaskExport";
import { TicketExport } from "./TicketExport";

const exportClient = {
    activities: new ActivityExport(),
    calls: new CallExport(),
    employees: new EmployeeExport(),
    incidents: new IncidentExport(),
    invoices: new InvoiceExport(),
    leads: new LeadExport(),
    orders: new OrderExport(),
    organizations: new OrganizationExport(),
    people: new PersonExport(),
    products: new ProductExport(),
    projects: new ProjectExport(),
    resources: new ResourceExport(),
    routines: new RoutineExport(),
    subscriptions: new SubscriptionExport(),
    subscriptionReport: new SubscriptionReportExport(),
    tasks: new TaskExport(),
    tickets: new TicketExport()
}

export default exportClient;