import _ from 'lodash';
import moment from 'moment';
import { notification, Modal, ModalFuncProps } from 'antd';
import { ActorType } from '../ApiClient/swagger/data-contracts';
import { IconType } from 'antd/es/notification/interface';

const handleListDuplicates = async (collection: any[], data: any) => {
    const slicedCollection = _.slice(collection);
    const newCollection = [...slicedCollection, ...data];

    const duplicates = _.uniqBy(_.flatten(_.reject(_.groupBy(newCollection, (i) => { return i.id; }), (f) => { return f.length <= 1; })), v => { return v.id; });
    if (duplicates) {
        await _.each(duplicates, d => {
            const index = _.findIndex(newCollection, c => { return c.id === d.id; });
            newCollection.splice(index, 1);
        });
    }

    return newCollection;
}

const setTimeToDate = (date: moment.Moment, time: string, timeFormat: string) => {

    const timeObj = moment(time, timeFormat);
    const dateObj = date;

    dateObj.set({
        hours: timeObj.hours(),
        minutes: timeObj.minutes(),
        seconds: 0
    });
    return dateObj;
}

const alterTemplateAreas = (elClass: string, from: string, to: string) => {
    const contentEl = document.querySelector(elClass);
    if (contentEl) {
        const style = getComputedStyle(contentEl);
        let areas = style.gridTemplateAreas;
        areas = areas.replace(from, to);
        contentEl.setAttribute("style", `grid-template-areas: ${areas}`);
    }
}

const rewriteTemplateAreas = (elClass: string, newAreas: string) => {
    const contentEl = document.querySelector(elClass);
    if (contentEl) {
        const style = getComputedStyle(contentEl);
        let areas = style.gridTemplateAreas;
        areas = newAreas;
        contentEl.setAttribute("style", `grid-template-areas: ${areas}`);
    }
}

const roundDownToNearestQuarter = (date) => {
    const newDate = moment(date);
    const roundedDown = Math.floor(newDate.minute() / 15) * 15;

    newDate.set({
        minutes: roundedDown,
        seconds: 0
    });

    return newDate;
}

const roundUpToNearestQuarter = (date) => {
    const newDate = moment(date);
    const roundedUp = Math.ceil(newDate.minute() / 15) * 15;

    newDate.set({
        minutes: roundedUp,
        seconds: 0
    });

    return newDate;
}

const roundToNearestQuarter = (date) => {
    const newDate = moment(date);

    newDate.set({
        milliseconds: 0,
        seconds: 0,
        minutes: Math.round(newDate.minutes() / 15) * 15
    });

    return newDate;
}

const updateCollectionFromEvent = async (collection: any[], event: any, merge: (event: any, existing: any) => any = null) => {
    const match = _.find(collection, entity => entity.id === event.id);

    if (match) {

        const copy = [...collection];
        const index = _.findIndex(copy, { id: event.id })

        if (index > -1) {
            const old = copy[index];
            const updated = merge ? merge(event, old) : event;
            copy.splice(index, 1, updated)
            return copy;
        }
    }
    else
        return collection;
}

const updateCollectionWithEvent = (collection: any[], event: any, merge: (event: any, existing: any) => any = null) => {
    const match = _.find(collection, entity => entity.id === event.id);

    if (match) {
        const copy = [...collection];
        const index = _.findIndex(copy, { id: event.id })

        if (index > -1) {
            const old = copy[index];
            const updated = merge ? merge(event, old) : event;
            copy.splice(index, 1, updated)
            return copy;
        }
    }
    else
        return collection;
}

//ACtorView => any temp fix to remove errors, actor is being removed all togheter
const updateActorCollectionFromEvent = async (collection: any[], event: any, actorType?: ActorType,) => {
    const match = _.find(collection, entity => entity.id === event.id);

    if (match) {
        const updated = event;

        const copy = [...collection];
        const index = _.findIndex(copy, { id: updated.id })
        if (index > -1) {
            if (actorType && updated.type != actorType) {
                copy.splice(index, 1);
            } else {
                copy.splice(index, 1, updated)
            }
            return copy;
        }
    }
    else
        return collection;
}

const openNotification = (
    title: React.ReactNode,
    description: React.ReactNode,
    type?: IconType,
    onClick?: () => void,
    key?: string,
    autoClose: boolean = true,
    className?: string,
    icon?: JSX.Element,
    closeIcon?: React.ReactNode) => {
    (type ? notification[type] : notification.open)({
        message: title,
        key: key,
        className: className,
        description: description,
        onClick: onClick,
        placement: "bottomRight",
        duration: autoClose ? 4.5 : 0,
        icon: icon,
        closeIcon: closeIcon
    });
}

const closeNotification = (key: string) => {
    notification.destroy(key);
}

const openModal = (
    title: React.ReactNode,
    content: React.ReactNode,
    type?: ModalFuncProps["type"],
    onOk?: () => void,
    okText?: string) => {
    Modal.confirm({
        title: title,
        content: content,
        onOk: onOk,
        okText: okText ?? "Ok",
        centered: true,
        type: type ?? "warn",
        className: "modalContainer"
    })
}

const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
}

const createGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const isColorDark = (rgbColor: string) => {
    if (!rgbColor) return null;

    const matchColor = rgbColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (!matchColor) return null;

    const r = parseInt(matchColor[1]);
    const g = parseInt(matchColor[2]);
    const b = parseInt(matchColor[3]);

    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    if (hsp > 127.5)
        return false;
    else
        return true;
}

const isColorLight = (rgbColor: string) => {
    if (!rgbColor) return null;

    const matchColor = rgbColor.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    if (!matchColor) return null;

    const r = parseInt(matchColor[1]);
    const g = parseInt(matchColor[2]);
    const b = parseInt(matchColor[3]);

    const hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    if (hsp > 127.5)
        return true;
    else
        return false;
}
//REFRESH EVENTS
const areMakingChanges = (isChanging: boolean) => {
    const event = new CustomEvent('changesInitiated', { detail: isChanging });
    document.dispatchEvent(event);
}
export {
    handleListDuplicates,
    updateCollectionFromEvent,
    updateCollectionWithEvent,
    updateActorCollectionFromEvent,
    openNotification,
    closeNotification,
    openModal,
    asyncForEach,
    isColorDark,
    isColorLight,
    createGuid,
    alterTemplateAreas,
    rewriteTemplateAreas,
    roundDownToNearestQuarter,
    roundUpToNearestQuarter,
    roundToNearestQuarter,
    setTimeToDate,
    areMakingChanges
};