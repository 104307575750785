import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import SelectorProps from "../../Models/SelectorProps";
import MomentTimePicker from "./MomentTimePicker";

interface TimeSelectorState {
    date?: moment.Moment,
    disabledDates: any
}
interface TimeSelectorProps extends SelectorProps {
}


//@ts-ignore
const TimePickerAutoaccept = (props: any) => {
    const onBlur = (elem: React.FocusEvent<HTMLInputElement>) => {
        //@ts-ignore
        const value = moment(elem.target.value, props.format);
        if (value && value.isValid() && props.onChange) {
            props.onChange(value, elem.target.value);
        }
    }

    return <MomentTimePicker {...props} onBlur={onBlur} />
}


export class TimeSelector extends React.Component<TimeSelectorProps, TimeSelectorState> {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            //@ts-ignore
            date: this.props.value ? moment(this.props.value, "HH:mm") : null,
            disabledDates: null
        }
    }

    componentDidMount = async () => {
        this._isMounted = true;
    }

    componentDidUpdate = (prevProps) => {
        if (this._isMounted) {
            if (this.props.value != this.state.date) {
                const isMoment = moment.isMoment(this.props.value);
                const newValue = isMoment ? this.props.value : moment(this.props.value, "HH:mm");

                const isEqual = _.isEqual(newValue, this.state.date);
                if (!isEqual)
                    this.setState({ date: newValue });
            }
        }
    }

    onTimeChange = (date, dateString) => {
        this.setState({ date: moment(dateString, "HH:mm") });

        if (!date) {
            if (this.props.onChange)
                this.props.onChange(null);
            return;
        }

        if (this.props.onChange)
            this.props.onChange(date.format("HH:mm:ss"));
        if (this.props.onObjectChange)
            this.props.onObjectChange(moment(date.format("HH:mm:ss"), "HH:mm:ss"));
    }

    //onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //    var currentDate = event.currentTarget.value;
    //    var length = currentDate != null ? this.state.date.format("HH:mm").length : 0;
    //    var key = event.keyCode;
        
    //    if (key == Keyboard.BACKSPACE) {
    //        currentDate = currentDate.slice(0, -1);
    //    }
    //    else if (key == Keyboard.KEY_0 || key == Keyboard.KEY_1 || key == Keyboard.KEY_2 || key == Keyboard.KEY_3 || key == Keyboard.KEY_4
    //        || key == Keyboard.KEY_5 || key == Keyboard.KEY_6 || key == Keyboard.KEY_7 || key == Keyboard.KEY_8 || key == Keyboard.KEY_9) {
    //        currentDate = currentDate + event.key;
    //    }

    //    //TODO: handle semi-colon and validate that the final value is valid and set to state 
    //    //console.log(currentDate, length);
    //}

    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Time'
        const format = "HH:mm";
        const defaultValue = this.props.value ? moment(this.props.value, format) : null;
        const title = this.props.title ? <label>{this.props.title}</label> : null;

        //@ts-ignore
        const value = this.state.date || defaultValue;

        return (
            <div className="selector time-selector">
                {title}
                <TimePickerAutoaccept
                    {...this.props}
                    style={{ width: "100%" }}
                    className={`time-picker ${this.props.className ? this.props.className : ""}`}
                    onCalendarChange={this.onTimeChange}
                    onChange={this.onTimeChange}
                    //onKeyDown={this.onKeyDown}
                    format={format}
                    size={this.props.size ? this.props.size : "middle"}
                    placeholder={placeholder}
                    allowClear={this.props.disableClear ? false : true}
                    value={value}
                />
            </div>
        );
    }
}

export default TimeSelector;