import client from "../../ApiClient/client";
import { PagedWorkCategoryQuery, WorkCategoryView } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";
import SelectorInformation from "../Shared/SelectorInformation";


interface WorkCategoryFilterProps extends GenericEntitySelectorProps<PagedWorkCategoryQuery> {
    types?: string[];
}

export function WorkCategorySelector(props: WorkCategoryFilterProps) {
    function renderOptionItem(category: WorkCategoryView) {
        return category.description ? <SelectorInformation displayText={category.name} hoverText={category.description} /> : category.name;
    }

    const placeholder = props.placeholder ? props.placeholder : 'Select work category...';
    const source = props.source ? props.source : client.workCategories.queryWorkCategories;
    const defaultValueSource = props.defaultValueSource ? props.defaultValueSource : client.workCategories.getWorkCategoryById;
    const optionDisplayNameParam = props.optionDisplayNameParam ? props.optionDisplayNameParam : 'name';

    return (
        <EntitySelector
            placeholder={placeholder}
            source={source}
            defaultValueSource={defaultValueSource}
            optionDisplayNameParam={optionDisplayNameParam}
            renderOptionItem={renderOptionItem}
            {...props}
        />
    );
}

export default WorkCategorySelector;