import * as React from "react";
import _ from 'lodash';
import { ActivityType, ProjectType, ProjectView, TaggableType, TaskType, TaskView, UpdateTask } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import TaskTypeSelector from "./TaskTypeSelector";
import { SelectorInput } from "../Shared/SelectorInput";
import { PersonSelector } from "../People";
import { WorkCategorySelector } from "../HourCategories";
import { PercentageInput } from "../Shared/PercentageInput";
import DateSelector from "../Shared/DateSelector";
import { DurationInput } from "../Shared/DurationInput";
import { TagSelector } from "../Tags";



interface EditTaskProps {
    task: TaskView;
    onComplete: (response: TaskView) => void;
    onCancel?: () => void;
}

interface EditTaskState {
    loading: boolean;
    error: string;
    discountWarning: string;
    project: ProjectView;
    type: TaskType;
    disbledTypeChoice: boolean;
}

class TaskEditForm extends React.Component<EditTaskProps, EditTaskState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            discountWarning: null,
            project: null,
            type: this.props.task.type,
            disbledTypeChoice: false
        }
    }

    componentDidMount = async () => {
        if (this.props.task.project) {
            const project = await client.projects.getProjectById(this.props.task.project.id);
            if (project)
                this.setState({ project: project.data.view });
        }
        if (this.props.task.type == TaskType.Container && this.props.task.hasChildren) {
            const containerChildrenResponse = await client.tasks.queryTasks({ parentId: this.props.task.id, isContainer: true, limit: 0, deleted: false });
            if (containerChildrenResponse.data.count > 0)
                this.setState({ disbledTypeChoice: true });
        }
        else if (this.props.task.type == TaskType.Normal && this.props.task.duration > 0)
            this.setState({ disbledTypeChoice: true });
    }

    onSubmit = async (request: UpdateTask) => {
        this.setState({ loading: true });
        request.type = this.state.type;

        const response = await client.tasks
            .updateTask(this.props.task.id, request, addChangeVectorHeader(this.props.task.changeVector))
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.task != null) {

            const response = await client.tasks
                .deleteTask(this.props.task.id, addChangeVectorHeader(this.props.task.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    onRestore = async () => {
        if (this.props.task != null) {
            const response = await client.tasks
                .restoreTask(this.props.task.id, addChangeVectorHeader(this.props.task.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    onDiscountChange = (event) => {
        if (event.target.value == null || event.target.value == "" || event.target.value == undefined) {
            this.setState({ discountWarning: null });
        }
        else {
            if (this.state.project.discount && this.state.project.discount != event.target.value)
                this.setState({ discountWarning: "This will override the existing discount" });
        }
    }

    onTypeChange = (type) => {
        this.setState({ type });
    }

    render() {
        if (!this.props.task) return <div>No task</div>;

        const { name, description, owner, assignee, category, plannedStart, plannedEnd, estimate } = this.props.task;
        const { project, type, disbledTypeChoice } = this.state;

        const isContainer = type == TaskType.Container;
        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                isDeleted={this.props.task.deleted}
                loading={this.state.loading}
                error={this.state.error}
                className="task-form"
                initialValues={{
                    name: name,
                    description: description,
                    ownerId: owner ? owner.id : null,
                    assigneeId: assignee ? assignee.id : null,
                    categoryId: category ? category.id : null,
                    discount: project && project.type == ProjectType.Hourly ? this.props.task.discount : null,
                    plannedStart: plannedStart,
                    plannedEnd: plannedEnd,
                    estimate: estimate,
                    tagIds: _.map(this.props.task.tags, tag => {
                        if (tag.category?.deleted || tag?.deleted) return;
                        return tag.id; 
                    }),
                } as Partial<UpdateTask>}
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Task name"
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                <TaskTypeSelector title="Type of task" type="radio" onChange={this.onTypeChange} value={type} className={`task-type ${disbledTypeChoice ? "disabled-input" : ""}`} wrappedFormTitle disabled={disbledTypeChoice} />

                {isContainer ? null : <SelectorInput
                    param="ownerId"
                    required
                    warningMessage="Please choose a owner"
                    title="Task owner"
                    selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select a person" />}
                />}
                {isContainer ? null : <SelectorInput
                    param="assigneeId"
                    title="Assignee"
                    selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select a person" />}
                />}

                {isContainer ? null : <SelectorInput
                    param="categoryId"
                    required
                    warningMessage="Please choose a category"
                    title="Category"
                    selector={<WorkCategorySelector filters={{ activityTypes: [ActivityType.Project] }} />}
                />}

                {project && project.type == ProjectType.Hourly && !isContainer ?
                    <>
                        <PercentageInput
                            param="discount"
                            title="Discount"
                            placeholder={project.discount ? project.discount.toString() : ""}
                            onChange={this.onDiscountChange}
                            formClassName={this.state.discountWarning != null ? "override-margin" : ""}
                        />
                        {<div className="warning">{this.state.discountWarning ?? null}</div>}
                    </>
                    : null}
                {/* TODO: Validate start < end */}

                <div className="start-end-combo-input-container">
                    <div className="label-container">
                        <label className="start-end-label">Planned task period (optional)</label>
                    </div>
                    <div className="start-end-group">
                        <SelectorInput
                            param="plannedStart"
                            selector={<DateSelector placeholder="Start" />}
                        />

                        <SelectorInput
                            param="plannedEnd"
                            selector={<DateSelector placeholder="End" endOfDay />}
                        />
                    </div>
                </div>

                <DurationInput
                    param="estimate"
                    title="Estimate"
                    duration={estimate}
                />

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Task] }} />}
                />
            </BaseForm>
        );
    }
}

export default TaskEditForm;