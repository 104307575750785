import { ActivityView } from "../ApiClient/swagger/data-contracts";



export interface ActivityMold extends ActivityView {
    resourceType?: CalendarResourceType;
}

export enum CalendarResourceType {
    Activity = 'Activity',
    Call = 'Call',
    Meeting = 'Meeting',
    Suggestion = "Suggestion",
    Routine = "Routine",
    Ticket = "Ticket",
}