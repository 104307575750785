import * as React from "react";
import _ from 'lodash';
import { Radio, Space, RadioChangeEvent } from "antd";
import { ProductUnit, ProductView } from "../../ApiClient/swagger/data-contracts";
import { NumberInputProps, PriceInput } from "../Shared/PriceInput";
import { SelectorInput } from "../Shared/SelectorInput";


interface OrderPriceProps {
    product?: ProductView;
    unit?: ProductUnit;
    onSalesPriceChange: (e: RadioChangeEvent) => void;
    radioValue: "auto" | "fixed";
    param: string;
    selectorProps: NumberInputProps;
    title: string;
}

class OrderPriceSelector extends React.Component<OrderPriceProps> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            product: null,
            unit: null,
            radioValue: "fixed"
        }
    }

    render = () => {
        let unit = this.props.unit != null ? this.props.unit : null;

        if(!unit)
            unit = this.props.product != null ? this.props.product.defaultUnit : null;

        let salesPrice = null;
        if (this.props.product != null && this.props.product.prices.length > 0 && unit != null) {
            const salesPrices = _.filter(this.props.product.prices, (p) => { return p.unit === unit && p.sales != null });
            if (salesPrices.length > 0)
                salesPrice = salesPrices[0].sales;
        }
        //const salesPrice = this.props.product != null && this.props.product.salesPrice != null && unit != null ? this.props.product.salesPrice[unit.toLowerCase()] : null;

        return (
            <SelectorInput
                title={this.props.title}
                param={this.props.param}
                required
                selector={
                    <Radio.Group onChange={this.props.onSalesPriceChange} value={this.props.radioValue}>
                        <Space direction="vertical">
                            <Radio value="auto">Use automatic price</Radio>
                            {this.props.radioValue !== "auto" ? null :
                                <PriceInput
                                    {...this.props.selectorProps}
                                    value={salesPrice}
                                    disabled
                                    footnote="This price is indicative and might be updated when synchronized to external order system"
                                />
                            }
                            <Radio value="fixed">Use fixed price</Radio>
                            {this.props.radioValue !== "fixed" ? null :
                                <PriceInput
                                    {...this.props.selectorProps}
                                    value={salesPrice}
                                    footnote="This price is fixed and will not be updated when synchronized to external order system"
                                />
                            }
                        </Space>
                    </Radio.Group>
                }
            />
        );
    }
}

export default OrderPriceSelector;