import * as React from "react";
import client from "../../ApiClient/client";
import { RoleView } from "../../ApiClient/swagger/data-contracts";
import { EntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";
import SelectorInformation from "../Shared/SelectorInformation";


export class RoleSelector extends React.Component<EntitySelectorProps> {
    renderOptionItem = (role: RoleView) => role.description ? <SelectorInformation displayText={role.name} hoverText={role.description} /> : role.name;

    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select role...';
        const source = this.props.source ? this.props.source : client.roles.queryRoles;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.roles.getRoleById;

        return (
            <EntitySelector
                className="role-selector"
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                renderOptionItem={this.renderOptionItem}
                {...this.props}
            />
        );
    }
}

export default RoleSelector;