import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import AppContext from "../../Definitions/AppContext";
import { ProductView, TaggableType, UnitPrice, UpdateProduct } from "../../ApiClient/swagger/data-contracts";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { ProductUnitSelector } from "./ProductUnitSelector";
import { TagSelector } from "../Tags";
import ProductCategorySelector from "./ProductCategorySelector";
import { Button, Form, InputNumber } from "antd";
import { NumberInput } from "../Shared/NumberInput";


interface EditProductProps {
    product: ProductView,
    onComplete: (product: ProductView) => void,
    onCancel?: () => void,
}

interface EditProductState {
    loading: boolean,
    error: string
}


interface UpdateProductRequest extends UpdateProduct {
    defaultSalesPrice: number;
    defaultCostPrice: number;
    salesPrices: UnitPrice[];
    costPrices: UnitPrice[];
}

export class ProductEditForm extends React.Component<EditProductProps, EditProductState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null
        }
    }

    onSubmit = async (request: UpdateProductRequest) => {
        this.setState({ loading: true });

        try {
            const pureProductUpdateRequest: UpdateProduct =
            {
                name: request.name,
                categoryId: request.categoryId,
                defaultUnit: request.defaultUnit,
                description: request.description,
                tagIds: request.tagIds
            };
            const response = await client.products.updateProduct(this.props.product.id, pureProductUpdateRequest);

            if (response) {
                const sales = request.salesPrices ?? [];
                const cost = request.costPrices ?? [];
                const salesUnits = _.map(sales, s => s.unit);
                const costUnits = _.map(cost, s => s.unit);
                const allUnits = _.uniq([...salesUnits ?? [], ...costUnits ?? []]);

                const unitPrices: UnitPrice[] = request.defaultCostPrice != null || request.defaultSalesPrice != null ? [{
                    sales: request.defaultSalesPrice,
                    cost: request.defaultCostPrice
                }] : [];

                _.each(allUnits, unit => {
                    unitPrices.push({
                        unit: unit,
                        sales: _.find(sales, c => c.unit === unit)?.sales ?? null,
                        cost: _.find(cost, c => c.unit === unit)?.cost ?? null
                    });
                });

                const res2 = unitPrices?.length > 0 ? await client.products.addOrUpdateProductPrices(response.data.id, unitPrices) : null;

                if (res2) this.props.onComplete(res2.data);
                else this.props.onComplete(response.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.product != null) {
            try {
                const response = await client.products.deleteProduct(this.props.product.id);

                if (response) this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onRestore = async () => {
        if (this.props.product != null) {
            try {
                const response = await client.products.restoreProduct(this.props.product.id);

                if (response) this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    render = () => {
        if (!this.props.product) return <div>No product</div>;

        const salesPrices = _.filter(this.props.product.prices, (price) => price.sales != null && price.unit != null);
        const initialSales = {};
        _.each(salesPrices, s => {
            initialSales[s.unit] = s.sales;
        });
        const costPrices = _.filter(this.props.product.prices, (price) => price.cost != null && price.unit != null);
        const initialCost = {};
        _.each(costPrices, c => {
            initialCost[c.unit] = c.cost;
        });

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                isDeleted={this.props.product.deleted}
                loading={this.state.loading}
                error={this.state.error}
                className="product-form product-edit-form"
                initialValues={{
                    name: this.props.product.name,
                    description: this.props.product.description,
                    defaultUnit: this.props.product.defaultUnit,
                    defaultSalesPrice: _.find(this.props.product.prices, p => p.unit === null)?.sales,
                    defaultCostPrice: _.find(this.props.product.prices, p => p.unit === null)?.cost,
                    costPrices: costPrices,
                    salesPrices: salesPrices,
                    categoryId: this.props.product.category ? this.props.product.category.id : null,
                    tagIds: _.map(this.props.product.tags, tag => {
                        if (tag.category?.deleted || tag?.deleted) return;
                        return tag.id; 
                    })
                }}
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Name"
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                <SelectorInput
                    param="defaultUnit"
                    warningMessage="Please choose a unit"
                    title="Default unit"
                    selector={<ProductUnitSelector excludeAllOption />}
                />

                <NumberInput
                    title="Sales price"
                    param="defaultSalesPrice"
                    placeholder="Price"
                    formClassName="default-price default-sales-price"
                    addonAfter="kr"
                />

                <Form.List name="salesPrices">
                    {(fields, { add, remove }) => (
                        <div className="prices-container sales-prices-container">
                            {fields.map((field) => (
                                <div className="prices sales-prices" key={field.key}>
                                    <Form.Item label={null} name={[field.name, 'sales']} className="price-input" rules={[{ required: true, message: "" }]}>
                                        <InputNumber placeholder="Price" decimalSeparator="," controls={false} style={{ width: '100%' }} />
                                    </Form.Item>

                                    <span className="text-seperator">per</span>

                                    <Form.Item label={null} name={[field.name, 'unit']} className="unit-input" rules={[{ required: true, message: "" }]}>
                                        <ProductUnitSelector />
                                    </Form.Item>

                                    <Button
                                        shape="circle"
                                        icon="X"
                                        className="remove-btn"
                                        onClick={() => remove(field.name)}
                                    />
                                </div>
                            ))}

                            <Button className="add-price-btn" onClick={() => add()} block>+ Add unit specific sales prices</Button>
                        </div>
                    )}
                </Form.List>

                <NumberInput
                    title="Cost price"
                    param="defaultCostPrice"
                    placeholder="Price"
                    formClassName="default-price default-cost-price"
                    addonAfter="kr"
                />

                <Form.List name="costPrices">
                    {(fields, { add, remove }) => (
                        <div className="prices-container cost-prices-container">
                            {fields.map((field) => (
                                <div className="prices cost-prices" key={field.key}>
                                    <Form.Item label={null} name={[field.name, 'cost']} className="price-input" rules={[{ required: true, message: "" }]}>
                                        <InputNumber placeholder="Price" decimalSeparator="," controls={false} style={{ width: '100%' }} />
                                    </Form.Item>

                                    <span className="text-seperator">per</span>

                                    <Form.Item label={null} name={[field.name, 'unit']} className="unit-input" rules={[{ required: true, message: "" }]}>
                                        <ProductUnitSelector />
                                    </Form.Item>

                                    <Button
                                        shape="circle"
                                        icon="X"
                                        className="remove-btn"
                                        onClick={() => remove(field.name)}
                                    />
                                </div>
                            ))}

                            <Button className="add-price-btn" onClick={() => add()} block>+ Add unit specific cost prices</Button>
                        </div>
                    )}
                </Form.List>

                <SelectorInput
                    param="categoryId"
                    title="Category"
                    selector={<ProductCategorySelector />}
                />

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Product] }} />}
                />
            </BaseForm>
        );
    }
}

export default ProductEditForm;

