import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Button, Tooltip } from "antd";
import { UnlockOutlined, LockOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ActivityType, ActivityView, ActorLinkView, HourCategoryType, ProjectLinkView, ProjectType, TaskLinkViewWithParents, WorkCategoryView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { RequireCapability } from "../Shared/RequireCapability";
import { ProjectLink } from "../Projects";
import { ActorLink } from "../Actors";
import { formatDuration } from "../../Helpers/Formatters";
import { Capabilities } from "../../Definitions/_capabilties";
import { TaskLink } from "../Tasks";
import { CoreCollection } from "../../Definitions/collections";
import { isColorDark } from "../../Helpers/BasePageHelpers";
import { TagLink } from "../Tags";



interface ConfirmActivitiesByOrganizationAndProjectViewProps {
    activities: ActivityView[];
    loading: boolean;
    confirmLoadingIds: string[];
    includeUsers: boolean;
    confirmActivity: (activity: ActivityView) => void;
    confirmActivities: (activity: ActivityView[], groupId: string) => void;
    unconfirmActivity: (activity: ActivityView) => void;
    onEditActivity: (activity: ActivityView) => void;
}

interface ConfirmActivitiesByOrganizationAndProjectViewState {
    //error: any;
    //query: Partial<ActivityQuery>;
}

export default class ConfirmActivitiesByOrganizationAndProjectView extends React.Component<ConfirmActivitiesByOrganizationAndProjectViewProps, ConfirmActivitiesByOrganizationAndProjectViewState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;


    sortData = () => {
        const activities = this.props.activities.slice();

        const organizationList: ActorLinkView[] = [];
        const projectList: ProjectLinkView[] = [];
        const taskList: TaskLinkViewWithParents[] = [];
        //let activityList: ActivityView[] = [];
        const actorList: ActorLinkView[] = [];

        const groupedByProject = _.groupBy(activities, activity => {
            const organization = activity.relation ?? null;
            const project = activity.project ?? null;

            if (organization != null) {
                if (_.find(organizationList, o => { return o.id == organization.id }) == null)
                    organizationList.push(organization);

                let link = organization.id;
                const projLink = project ? project.id : null;
                if (projLink != null) {
                    if (_.find(projectList, o => { return o.id == project.id }) == null)
                        projectList.push(project);

                    link += "/" + projLink;
                }

                return link;
            }
            else {
                if (project != null) {
                    if (_.find(projectList, o => { return o.id == project.id }) == null)
                        projectList.push(project);

                    return project.id;
                }
                else
                    return activity.type == ActivityType.Internal ? "åååå" : "Unknown"; //"åååå" IS TO PUT INTERNAL ACTIVITIES LAST IN SORTING
            }
        });

        const custProjs = Object.keys(groupedByProject);

        const temp = [];

        for (let i = 0; i < custProjs.length; i++) {
            const custProj = custProjs[i];

            const groupedByUser = _.groupBy(groupedByProject[custProj], act => {
                if (act.actor) {
                    if (_.find(actorList, a => { return a.id == act.actor.id }) == null)
                        actorList.push(act.actor);

                    return act.actor.id;
                }
                else
                    return "Unknown";
            });

            const temp1 = [];

            const users = Object.keys(groupedByUser);
            for (let j = 0; j < users.length; j++) {
                const user = users[j];

                const groupedByTasks = _.groupBy(groupedByUser[user], act => {
                    if (act.task) {
                        if (_.find(taskList, t => { return t.id == act.task.id; }) == null)
                            taskList.push(act.task);

                        return act.task.id;
                    }
                    else
                        return "Unknown";
                });



                const tasks = Object.keys(groupedByTasks);
                const temp2 = [];
                for (let l = 0; l < tasks.length; l++) {
                    var task = tasks[l];

                    const real = task == "Unknown" ? null : _.find(taskList, t1 => { return t1.id == task; });
                    var sortKey = "";
                    if (real == null)
                        sortKey = "z";
                    else {
                        _.each(real.parents, p => {
                            sortKey += p.name;
                        });
                        sortKey += real.name;
                    }

                    const groupedByDay = _.groupBy(groupedByTasks[task], t => {
                        const m = moment(t.start);
                        return m.startOf('day');
                    });

                    const days = Object.keys(groupedByDay);
                    const temp3 = [];
                    for (let k = 0; k < days.length; k++) {
                        const day = days[k];
                        const activitiesForTaskAndDay = _.sortBy(groupedByDay[day], a => { return moment(a.start) });

                        temp3.push({
                            day: day,
                            activities: activitiesForTaskAndDay
                        });
                    }

                    const sortedDays = _.sortBy(temp3, d => {
                        return moment(d.day);
                    });

                    temp2.push({
                        task: task,
                        sortKey: sortKey,
                        days: sortedDays
                    });
                }

                const sortedTasks = _.sortBy(temp2, t => { return t.sortKey });

                temp1.push({
                    user: user,
                    tasks: sortedTasks
                });
            }

            const sortedUsers = _.sortBy(temp1, u => { return u.user; });

            temp.push({
                key: custProj,
                users: sortedUsers
            });
        }

        const sorted = _.sortBy(temp, t => { return t.key; });
        return {
            sorted: sorted,
            organizations: organizationList,
            projects: projectList,
            tasks: taskList,
            //activities: activityList,
            actors: actorList,
        };
    }

    render = () => {
        const data = this.sortData();

        const sorted = data.sorted;
        const organizations = data.organizations;
        const projects = data.projects;
        const tasks = data.tasks;
        const actors = data.actors;

        const rows = [];

        for (var i = 0; i < sorted.length; i++) {

            var custProj = sorted[i];

            let totalDuration = 0;
            const notConfirmed: ActivityView[] = [];
            let notConfirmedGroupId = "";

            _.each(custProj.users, cp => {
                _.each(cp.tasks, t => {
                    _.each(t.days, d => {
                        notConfirmedGroupId = i + "_" + custProj.key + _.map(d.activities, c => { return c.id; }).join('_');
                        _.each(d.activities, (a: ActivityView) => {
                            totalDuration += a.duration;
                            if (!a.confirmed) {
                                notConfirmed.push(a);
                            }
                        });
                    });
                });
            });

            const isLoadingNotConfirmedGroup = _.find(this.props.confirmLoadingIds, id => { return id == notConfirmedGroupId; }) != null;

            const confirmAll = notConfirmed.length > 0
                ? <RequireCapability capability={Capabilities.ActivitiesConfirmActor}><Button loading={isLoadingNotConfirmedGroup} onClick={(e) => {
                    e.preventDefault();
                    this.props.confirmActivities(notConfirmed, notConfirmedGroupId);
                }} icon={<UnlockOutlined />} /></RequireCapability>
                : <Button style={{ visibility: "hidden" }} disabled />;

            var custProjKeySplit = custProj.key.split("/");
            let organization = null;
            let project = null;
            let custProjView = null;

            if (custProjKeySplit.length == 1) {
                project = _.find(projects, p => { return p.id == custProjKeySplit[0]; });
                if (project != null) {
                    custProjView = <ProjectLink {...project} />;
                }
                else if (custProjKeySplit == "åååå") {
                    custProjView = ActivityType.Internal;
                }
                else { //Customer activity
                    organization = _.find(organizations, o => { return o.id == custProjKeySplit[0]; });
                    custProjView = <React.Fragment>{ActivityType.Customer} - <ActorLink {...organization} /></React.Fragment>;
                }
            }
            else if (custProjKeySplit.length == 2) {
                organization = _.find(organizations, o => { return o.id == custProjKeySplit[0]; });
                project = _.find(projects, p => { return p.id == custProjKeySplit[1]; });

                custProjView = (
                    <React.Fragment>
                        <ActorLink {...organization} /> / <ProjectLink {...project} />
                    </React.Fragment>
                );
            }

            rows.push(
                <tr key={i} className="row-header">
                    <th colSpan={3}>{custProjView}</th>
                    <th colSpan={3} className="confirm-cell"><span className="duration">{formatDuration(totalDuration)}</span> {confirmAll}</th>
                </tr>
            );

            for (var u = 0; u < custProj.users.length; u++) {

                var user = custProj.users[u];
                const actor = user.user == "Unknown" ? null : _.find(actors, a => { return a.id == user.user; });
                const actorView = actor != null ? <ActorLink {...actor} /> : null;

                let userTotalDuration = 0;
                const notConfirmedUser = [];
                let notConfirmedForUserGroupId = "";

                _.each(user.tasks, t => {
                    _.each(t.days, d => {
                        notConfirmedForUserGroupId = i + "_" + u + user.user + "_" + custProj.key + _.map(d.activities, c => { return c.id; }).join('_');
                        _.each(d.activities, a => {
                            userTotalDuration += a.duration;
                            if (!a.confirmed) {
                                notConfirmedUser.push(a);
                            }
                        });
                    });
                });

                const isLoadingNotConfirmedForUserGroup = _.find(this.props.confirmLoadingIds, id => { return id == notConfirmedForUserGroupId; }) != null;

                const confirmAllUser = notConfirmedUser.length > 0
                    ? <RequireCapability capability={Capabilities.ActivitiesConfirm}><Button loading={isLoadingNotConfirmedForUserGroup} onClick={(e) => {
                        e.preventDefault();
                        this.props.confirmActivities(notConfirmedUser, notConfirmedForUserGroupId);
                    }} icon={<UnlockOutlined />} /></RequireCapability>
                    : <Button style={{ visibility: "hidden" }} disabled />;

                if (this.props.includeUsers) {
                    rows.push(
                        <tr className="row-sum-partial" key={i + "_" + u + "_" + user.user}>
                            <td colSpan={3} >{actorView}</td>
                            <th colSpan={3} className="confirm-all-user-cell"><span className="duration">{formatDuration(userTotalDuration)}</span> {confirmAllUser}</th>
                        </tr>
                    );
                }

                for (let j = 0; j < user.tasks.length; j++) {
                    var taskObj = user.tasks[j];
                    const task = taskObj.task == "Unknown" ? null : _.find(tasks, t => { return t.id == taskObj.task; });
                    var taskView = [];
                    if (task != null) {
                        _.each(task.parents, (p, index) => {
                            taskView.push(<TaskLink {...p} key={p.id} />);
                            const splitterKey = `splitter_0${index}`;
                            taskView.push(<span key={splitterKey}> / </span>);
                        });
                        taskView.push(<TaskLink {...task} />)
                    }
                    //var taskView = task != null ? <TaskLink {...task} /> : null;

                    let span = _.reduce(taskObj.days, (memo, day) => {
                        let sumRow = 0;

                        if (day.activities.length > 1)
                            sumRow = 1;

                        return memo + day.activities.length + sumRow;
                    }, 0);

                    //var span = activityCount;
                    if (taskObj.days.length > 1) {
                        span += 1; //Adding extra span for task sum
                    }

                    let colums = [];
                    colums.push(<td key={i + "_" + u + "_" + j + "_" + taskObj.task} rowSpan={span} className="task-cell">{taskView}</td>);

                    let taskTotalDuration = 0;

                    for (let k = 0; k < taskObj.days.length; k++) {
                        const day = taskObj.days[k];
                        const d = moment(day.day);
                        const span2 = day.activities.length + (day.activities.length > 1 ? 1 : 0);

                        colums.push(<td key={k + day} className="duration-cell" rowSpan={span2}>{d.format('DD.MM.YYYY')}</td>);

                        let dayTotalDuration = 0;
                        for (let l = 0; l < day.activities.length; l++) {
                            const act = day.activities[l] as ActivityView;
                            const workCategory = act.category.type == HourCategoryType.Work ? act.category as WorkCategoryView : null;
                            const isLoading = _.find(this.props.confirmLoadingIds, id => { return act.id == id; }) != null;
                            dayTotalDuration += act.duration;

                            const confirm = act.locked ? "Transferred" : act.confirmed
                                ? <RequireCapability capability={Capabilities.ActivitiesConfirmActor}><Button loading={isLoading} className="confirmed-btn" icon={<LockOutlined />} onClick={(e) => { e.stopPropagation(); if (e.ctrlKey) this.props.unconfirmActivity(act); }} /></RequireCapability>
                                : <RequireCapability capability={Capabilities.ActivitiesConfirmActor}><Button loading={isLoading} onClick={(e) => { e.stopPropagation(); this.props.confirmActivity(act); }} icon={<UnlockOutlined />} /></RequireCapability>;

                            //var edit = <Button className="edit-btn" type="link" onClick={() => this.props.onEditActivity(act)}>{moment(act.start).format('HH:mm')} - {moment(act.end).format('HH:mm')}</Button>;
                            const duration = <span className="duration">{formatDuration(act.duration)}</span>;

                            const color = act.category.color;
                            const isDarkColor = isColorDark(color);

                            let productOverridden = null;
                            const overriddenProduct = act.product ?? null;
                            const defaultProduct = workCategory ? workCategory.product : null;

                            if (overriddenProduct && defaultProduct && overriddenProduct.id != defaultProduct.id) {
                                productOverridden = (
                                    <span className="product-override"><Tooltip title={act.product.name}><InfoCircleOutlined /> Product override</Tooltip></span>
                                );
                            }

                            let tags = [];
                            if (act.tags) {
                                tags = _.map(act.tags, tag => { return <TagLink key={tag.id} {...tag} />; });
                            }

                            let productInUse = overriddenProduct ?? defaultProduct ?? null;

                            if (act.project != null && act.project.type == ProjectType.Internal)
                                productInUse = null;

                            colums.push(<td key={l + "edit"} className="edit-cell">{moment(act.start).format('HH:mm')} - {moment(act.end).format('HH:mm')}</td>);
                            colums.push(<td key={l + "color"} style={{ backgroundColor: color }} className={`category-cell ${isDarkColor ? "color-light" : "color-dark"}`}>{act.category.name} <div className="product">{productInUse ? productInUse.name : null}</div></td>);
                            colums.push(<td key={l + "desc"}><span className="description">{act.description}</span></td>);
                            colums.push(<td key={l + "lock"} className="confirm-cell">{tags} {productOverridden} {duration} {confirm}</td>);

                            rows.push(<tr className="columns-row" key={act.id} onClick={(e) => { e.preventDefault(); this.props.onEditActivity(act) }}>{colums}</tr>);
                            colums = [];
                        }

                        if (day.activities.length > 1) {
                            rows.push(
                                <tr className="row-sum-partial2" key={u + "_" + i + "_" + j + "_partial2_" + k}>
                                    <td className="day-sum">Sum day:</td>
                                    <td className="day-sum">{formatDuration(dayTotalDuration)}</td>
                                    <td colSpan={4}>&nbsp;</td>
                                </tr>
                            );
                        }

                        taskTotalDuration += dayTotalDuration;
                    }
                    if (taskObj.days.length > 1) {
                        rows.push(
                            <tr className="row-sum-partial2" key={u + "_" + i + "_partial3_" + j}>
                                <td className="day-sum">Sum task:</td>
                                <td>&nbsp;</td>
                                <td className="day-sum">{formatDuration(taskTotalDuration)}</td>
                                <td colSpan={4}>&nbsp;</td>
                            </tr>
                        );
                    }
                }
            }
        }

        return (
            <table className="verify-activities-table by-org-and-project-view" id="verifyTableId">
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}