import * as React from "react";
import _ from 'lodash';
import { Input, Form } from "antd";
import { Address, AddressType } from "../../ApiClient/swagger/data-contracts";



interface AddressInputValueConverterProps {
    getValue?: () => string,
    setValue?: (value: object) => void,
    addressType: AddressType,
    value?: any
}

interface AddressInputValueConverterState {
    address: any,
    loading: boolean
}

interface AddressInputProps {
    title?: string,
    param: string,
    addressType: AddressType,
    readOnly?: boolean,
    className?: string,
    required?: boolean,
    warningMessage?: string,
    value?: any
    onAddressChange?: (value: Address) => void
}

export class AddressInputValueConverter extends React.Component<AddressInputValueConverterProps, AddressInputValueConverterState> {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            address: this.props.getValue(),
            loading: false
        }
    }

    componentDidMount = () => {
        this._isMounted = true;
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.value != prevProps.value && this._isMounted && !this.state.loading) {
            this.handleValueChange(this.props.value);
        }
    }

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    handleValueChange = async (address) => {
        this.setState({ loading: true });

        let addressMap: Partial<Address> = null;

        if (address) {
            addressMap = {
                name: address.name,
                line1: address.line1,
                line2: address.line2,
                zipCode: address.zipCode,
                area: address.area,
                country: address.country,
                note: address.note
            };
        }

        await this.setState({ address: addressMap });
        await this.props.setValue(addressMap);

        this.setState({ loading: false });
    }

    onValueChange = (type, event) => {
        const address = Object.assign({}, this.state.address);
        address[type] = event.currentTarget.value;
        address.type = this.props.addressType;

        this.setState({ address });
        this.props.setValue(address);
    }

    render = () => {
        return (
            <div className="address-input-container">
                <Input
                    placeholder="Address title"
                    size="middle"
                    allowClear
                    onChange={(e) => this.onValueChange("name", e)}
                    value={this.state.address ? this.state.address.name : null}
                />

                <Input
                    placeholder="Line 1"
                    size="middle"
                    allowClear
                    onChange={(e) => this.onValueChange("line1", e)}
                    value={this.state.address ? this.state.address.line1 : null}
                />

                <Input
                    placeholder="Line 2"
                    size="middle"
                    allowClear
                    onChange={(e) => this.onValueChange("line2", e)}
                    value={this.state.address ? this.state.address.line2 : null}
                />

                <Input
                    placeholder="Zip code"
                    size="middle"
                    allowClear
                    onChange={(e) => this.onValueChange("zipCode", e)}
                    value={this.state.address ? this.state.address.zipCode : null}
                />

                <Input
                    placeholder="Area"
                    size="middle"
                    allowClear
                    onChange={(e) => this.onValueChange("area", e)}
                    value={this.state.address ? this.state.address.area : null}
                />

                <Input
                    placeholder="Country"
                    size="middle"
                    allowClear
                    onChange={(e) => this.onValueChange("country", e)}
                    value={this.state.address ? this.state.address.country : null}
                />

                <Input
                    placeholder="Note"
                    size="middle"
                    allowClear
                    onChange={(e) => this.onValueChange("note", e)}
                    value={this.state.address ? this.state.address.note : null}
                />
            </div>
        );
    }
}

export class AddressInput extends React.Component<AddressInputProps> {
    render = () => {
        return (
            <Form.Item
                noStyle
                shouldUpdate={(prev, next) => { return prev[this.props.param] !== next[this.props.param] }}
            >
                {({ getFieldValue, setFieldsValue }) => {
                    return (
                        <Form.Item
                            label={this.props.title}
                            name={this.props.param}
                        >
                            <AddressInputValueConverter
                                getValue={() => getFieldValue(this.props.param)}
                                setValue={(value) => {
                                    const values = {};
                                    values[this.props.param] = value;
                                    setFieldsValue(values);
                                    if (this.props.onAddressChange)
                                        this.props.onAddressChange(value);
                                }}
                                {...this.props}
                            />
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
}