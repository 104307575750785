import * as React from "react";
import { OrderType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";

class OrderTypeSelector extends React.Component<EnumSelectorProps> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : "Select order type...";
        enum PartialOrderType{
            Quotation = OrderType.Quotation,
            Sales = "Sales",
            Production = OrderType.Production
        }

        return (
            <EnumSelector
                className="order-type-selector"
                enum={PartialOrderType}
                placeholder={placeholder}
                {...this.props}
            />
        );
    }
}

export default OrderTypeSelector;

