import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import client from "../../ApiClient/client";
import { CreateIncident, IncidentDateType, IncidentView, PagedIncidentQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { updateCollectionFromEvent, updateCollectionWithEvent } from "../../Helpers/BasePageHelpers";
import { CategoryLink } from "../Categories";
import { PersonLink } from "../People";
import ListViewCard from "../Shared/ListViewCard";
import { Button } from "antd";
import { Drawer } from "../Shared/Drawer";
import IncidentCreateForm from "./IncidentCreateForm";
import ErrorPage from "../../Pages/ErrorPage";

interface IncidentWidgetProps {
    title: any;
    className?: string;
    query: Partial<PagedIncidentQuery>;
    exclutions?: string[];
    onItemCreatedEvent?: (collection: IncidentView[], lead: IncidentView) => IncidentView[];
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    incidentRequest?: Partial<CreateIncident>;
}

export function IncidentWidget(props: IncidentWidgetProps) {

    const [incidents, setIncidents] = useState<IncidentView[]>();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);
    const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

    const context = useContext(AppContext);
    const navigate = useNavigate();

    let _isMounted = false;

    useEffect(() => {
        if(_isMounted == false)
            _isMounted = true;
        if(!incidents)
            getIncidents();

        context.events.incidents.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });
        return () => {
            _isMounted = false;

            context.events.incidents.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    async function getIncidents() {
        setLoading(true);

        const response = await client.incidents.queryIncidents(props.query).catch(exception => setError(exception.error));

        if (response && _isMounted)
            setIncidents(response.data.items);

        if (_isMounted)
            setLoading(false);
    }

    function onItemCreatedEvent(incident: IncidentView) {
        if (!incident || !props.onItemCreatedEvent) return;

        setIncidents(prevCollection => {
            const newCollection = props.onItemCreatedEvent(prevCollection?.slice() ?? [], incident);
            return newCollection;
        });
    }

    function onItemUpdatedEvent(incident: IncidentView) {
        if (!incident) return;

        setIncidents(prevCollection => {
            const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], incident);
            return newCollection;
        });
    }

    function onSelect(data: IncidentView) {
        if (context.user.hasCapability(Capabilities.IncidentsReadRelated))
            navigate("/incidents/" + data.id);
    }

    async function toggleCreateDrawer() {
        setShowCreateDrawer((current) => !current);
    }

    if (error) return <ErrorPage status={error} />;

    let baseColumns = [
        {
            title: 'Title & description',
            render: (incident: IncidentView) => {
                return (
                    <React.Fragment>
                        <div className="incident-title">{incident.title}</div>
                        <div className="incident-description">{incident.description ? incident.description.length < 250 ? incident.description : incident.description.slice(0, 250) + "..." : null}</div>
                    </React.Fragment>
                );
            },
            key: 'name',
        },
        {
            title: 'Type',
            render: (incident: IncidentView) => {
                return <CategoryLink {...incident.category} />;
            },
            key: 'type',
        },
        //{
        //    title: 'Causes',
        //    render: (incident: IncidentView) => {
        //        var columns = [];
        //        _.each(incident.tags, (tag) => {
        //            if (tag.category.id == CategoryIds.IncidentCauses)
        //                columns.push(<div key={tag.id} className="extend title"><TagLink {...tag} /></div>)
        //        });
        //        return <ColumnExpander>{columns}</ColumnExpander>;
        //    },
        //    key: 'causes',
        //    ellipsis: true
        //},
        {
            title: 'Registered',
            render: (incident: IncidentView) => <div className="registered">{moment(incident.dates["registered"]).format("DD.MM.YYYY")} - <PersonLink {...incident.registrator} /></div>,
            key: 'registrator',
        }
    ];

    if (props.customColumns) {
        baseColumns = props.customColumns;
    }
    else {
        baseColumns = _.reject(baseColumns, c => {
            const exclude = _.find(props.exclutions, e => { return c.key == e }) != null;

            if (exclude)
                return true;
            else
                return false;
        });
    }

    return (
        <>
            <ListViewCard
                title={props.title}
                columns={baseColumns}
                data={incidents}
                loading={loading}
                onSelect={onSelect}
                className="incident-widget"
                actions={<Button key="add" size="small" onClick={toggleCreateDrawer}>Add incident</Button>}
                {...props}
            />

            <Drawer
                title="Create incident"
                onClose={toggleCreateDrawer}
                open={showCreateDrawer}
                component={
                    <IncidentCreateForm
                        onCancel={toggleCreateDrawer}
                        onComplete={toggleCreateDrawer}
                        incidentRequest={props.incidentRequest}
                    />
                }
            />
        </>
    );
}