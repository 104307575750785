import * as React from "react";
import _ from 'lodash';
import { Checkbox, Form, Row, Col } from 'antd';
import AppContext from "../../Definitions/AppContext";
import BaseForm, { FormType } from "../Shared/Form";
import { ActorIds } from "../../Definitions/_definitions";
import { Capabilities } from "../../Definitions/_capabilties";
import { SelectorInput } from "../Shared/SelectorInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { PersonSelector } from "../People";

interface NotesCreateFormProps {
    onCreate: (req) => void,
    onCancel?: () => void,
    loading: boolean
    error: any
}
export class NotesCreateForm extends React.Component<NotesCreateFormProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props)
    }

    render = () => {
        const formDetails = {
            type: FormType.Create,
            onSubmit: this.props.onCreate,
            onCancel: this.props.onCancel,
            loading: this.props.loading,
            error: this.props.error
        };

        return (
            <BaseForm
                {...formDetails}
                initialValues={{
                    authorId: this.context.user.actorId == ActorIds.System ? null : this.context.user.actorId,
                    sticky: false,
                    private: false
                }}
            >
                {this.context.user.hasCapability(Capabilities.NotesWrite) && this.context.user.actorId == ActorIds.System ?
                    <SelectorInput
                        param="authorId"
                        required
                        title="Select actor"
                        selector={<PersonSelector />}
                        warningMessage="You must choose an actor"
                    />
                    : null}
                <TextAreaInput
                    key={0}
                    param="text"
                    required
                    warningMessage="Write a note"
                    placeholder="Create a note"
                />
                <Row>
                    <Col>
                        <Form.Item
                            name="sticky"
                            valuePropName="checked"
                        >
                            <Checkbox className="checkbox-sticky">
                                Sticky
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="private"
                            valuePropName="checked"
                        >
                            <Checkbox className="checkbox-private">
                                Private
                            </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </BaseForm>
        );
    }
}

export default NotesCreateForm;