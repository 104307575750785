import * as React from "react";
import { Form, Checkbox } from "antd";
//import { CheckboxValueType } from "antd/lib/checkbox/Group";
import _ from "lodash";

interface CheckInputState {
    selected: string[]
}

interface CheckInputProps {
    param: string,
    title?: string,
    required?: boolean,
    warningMessage?: string,
    customValidator?: (rule: any, value: any) => any,
    render: (selected: string[]) => JSX.Element,
    value?: any
    onChange?: any
    onObjectChange?: any
    disabled?: boolean
}

export class CheckboxGroupInput extends React.Component<CheckInputProps, CheckInputState> {

    constructor(props: any) {
        super(props);

        this.state = {
            selected: this.props.value
        }
    }

    onChange = (selected: string[]) => {
        this.setState({ selected });
        if (this.props.onChange)
            this.props.onChange(selected);
        if (this.props.onObjectChange)
            this.props.onObjectChange(selected);
    }

    componentDidUpdate = (prevProps: CheckInputProps) => {
        const isEqual = _.isEqual(prevProps.value, this.props.value);
        if (!isEqual) this.setState({ selected: this.props.value });
    }

    onValidate = async (rule, value) => {
        if (!this.props.required)
            return Promise.resolve();

        if (this.props.customValidator)
            return this.props.customValidator(rule, value);

        if (value)
            return Promise.resolve();
        else
            return Promise.reject(this.props.warningMessage);
    }

    render = () => {
        const { param, render } = this.props;
        const title = this.props.title ? <div>{this.props.title}</div> : null;

        return (
            <Form.Item
                label={title}
                name={param}
                rules={[{
                    validator: this.onValidate
                }]}
                className="checkbox-group-input"
            >
                <Checkbox.Group disabled={this.props.disabled} onChange={this.onChange}>
                    {render(this.state.selected as string[])}
                </Checkbox.Group>
            </Form.Item>
        );
    }
}