import * as React from "react";
import _ from 'lodash';
import { Form, Checkbox } from "antd";
import { CreateOrUpdateTag, TagCategoryView, TaggableType, TagView } from "../../ApiClient/swagger/data-contracts";
import { Capabilities } from "../../Definitions/_capabilties";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { ColorInput } from "../Shared/ColorInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { CategoryIds } from "../../Definitions/_definitions";
import TagCategorySelector from "./TagCategorySelector";

interface EditTagProps {
    tag: TagView;
    onComplete: (tag: TagView) => void;
    onCancel?: () => void;
    writeCapability?: Capabilities;
}

interface EditTagState {
    loading: boolean;
    error: string;
    category: TagCategoryView;
}

export class TagEditForm extends React.Component<EditTagProps, EditTagState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            category: null
        }
    }

    componentDidMount = async () => {
        if (this.props.tag && this.props.tag.category) {
            const response = await client.tagCategories.getTagCategoryById(this.props.tag.category.id);
            this.setState({ category: response.data.view });
        }
    };

    onSubmit = async (request: CreateOrUpdateTag) => {
        this.setState({ loading: true });
        try {
            const response = await client.tags.updateTag(this.props.tag.id, request);
            this.props.onComplete(response.data);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.tag != null) {
            try {
                const response = await client.tags.deleteTag(this.props.tag.id);
                this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onRestore = async () => {
        if (this.props.tag != null) {
            try {
                const response = await client.tags.restoreTag(this.props.tag.id);
                this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    render() {
        if (!this.props.tag) return <div>No tag</div>;

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.props.writeCapability && !this.context.user.hasCapability(this.props.writeCapability) ? null : this.onSubmit}
                onDelete={this.props.writeCapability && !this.context.user.hasCapability(this.props.writeCapability) ? null : this.onDelete}
                onRestore={this.props.writeCapability && !this.context.user.hasCapability(this.props.writeCapability) ? null : this.onRestore}
                onCancel={this.props.onCancel}
                isDeleted={this.props.tag.deleted}
                isSystemPreset={this.props.tag.systemPreset}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    name: this.props.tag.name,
                    description: this.props.tag.description,
                    categoryId: this.props.tag.category.id,
                    color: this.props.tag.color ? this.props.tag.color : this.state.category && this.state.category.limitedToTaggableTypes && this.state.category.limitedToTaggableTypes.indexOf(TaggableType.Activity) >= 0 ? this.state.category.color : null,
                    overrideColor: this.props.tag.overrideColor
                }}
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Name"
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                <SelectorInput
                    param="categoryId"
                    required
                    warningMessage="Please choose a category"
                    title="Category"
                    selector={
                        <TagCategorySelector
                            onObjectChange={(cat: TagCategoryView) => { this.setState({ category: cat as TagCategoryView }) }}
                            disabled={this.props.tag.category && (this.props.tag.category.id == CategoryIds.IncidentCauses || this.props.tag.category.id == CategoryIds.IncidentSubCategories)}
                        />
                    }
                />
                {this.props.tag.category && this.props.tag.category.id == CategoryIds.IncidentCauses ? null : <ColorInput title="Color" param="color" />}
                {this.state.category && this.state.category.limitedToTaggableTypes && this.state.category.limitedToTaggableTypes.indexOf(TaggableType.Activity) >= 0 ?
                    <Form.Item
                        name="overrideColor"
                        valuePropName="checked"
                    >
                        <Checkbox>If the tag is used let this color override the original time tracking color</Checkbox>
                    </Form.Item>
                    : null}
            </BaseForm>
        );
    }
}

export default TagEditForm;