import * as React from "react";
import _ from 'lodash';
import { ContactPointLinkView, ContactPointView, CreateOrUpdateOrganizationWebRequest, OrganizationRoleView, OrganizationView, TaggableType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { TagSelector } from "../Tags";
import { Button, Checkbox, Form, Popconfirm, Space } from "antd";
import { CustomFormLabel } from "../Shared/CustomFormLabel";
import { NumberInput } from "../Shared/NumberInput";
import CustomerCategorySelector from "../Shared/CustomerCategorySelector";
import SupplierCategorySelector from "../Shared/SupplierCategorySelector";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { ContactPointEditForm } from "../ContactPoints";
import { Drawer } from "../Shared/Drawer";

interface EditOrganizationProps {
    organization: OrganizationView;
    onComplete: (organization: OrganizationView) => void;
    onCancel?: () => void;
}

interface EditOrganizationState {
    loading: boolean;
    error: string | null;
    contactPoint: ContactPointLinkView;
    contactPoints: ContactPointLinkView[];
    organizationRole: OrganizationRoleView;
    isCustomer: boolean;
    isSupplier: boolean;
}

interface ExtendedCreateOrUpdateOrganization extends CreateOrUpdateOrganizationWebRequest {
    isCustomer: boolean;
    isSupplier: boolean;
}

//TODO: Add selector for actor type and display fields accordingly

export class OrganizationEditForm extends React.Component<EditOrganizationProps, EditOrganizationState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            contactPoint: null,
            contactPoints: this.props.organization.contactPoints ?? [],
            organizationRole: null,
            isCustomer: this.props.organization.customerInfo !== null,
            isSupplier: this.props.organization.supplierInfo !== null,
        }
    }

    onSubmit = async (request: ExtendedCreateOrUpdateOrganization) => {
        this.setState({ loading: true });

        try {
            const updatedResponse = await client.organizations.updateOrganization(this.props.organization.id, request, addChangeVectorHeader(this.props.organization.changeVector));

            this.props.onComplete(updatedResponse.data);
        }
        catch (error: any) {
            this.setState({ error: error.message ?? error.error, loading: false });
        }

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.organization != null) {
            try {
                const response = await client.organizations.deleteOrganization(this.props.organization.id, addChangeVectorHeader(this.props.organization.changeVector));

                this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onRestore = async () => {
        if (this.props.organization != null) {
            try {
                const response = await client.organizations.restoreOrganization(this.props.organization.id, addChangeVectorHeader(this.props.organization.changeVector));
                this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onCpEdit = (contactPoint: ContactPointLinkView) => {
        try {
            this.setState({ contactPoint });
        }
        catch (error: any) {

        }
    }

    onCpRemove = async (cp: ContactPointLinkView) => {
        await client.contactPoints
            .deleteContactPoint(cp.id)
            .catch(exception => this.setState({ error: exception.error.title }));
        this.setState({contactPoints: this.state.contactPoints.filter((contactPoint) => {
            return contactPoint !== cp
        })});
    }

    cpEditComplete = (cp: ContactPointLinkView) => {
        this.setState({ contactPoint: null });

        const contactPoints = this.state.contactPoints?.slice() ?? [];
        const index = _.findIndex(this.state.contactPoints, c => c.id == cp.id);
        if (index != -1) {
            contactPoints.splice(index, 1, cp);
            this.setState({ contactPoints });
        }
    }

    render = () => {
        if (!this.props.organization) return <div>No organization</div>;

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onRestore={this.onRestore}
                onCancel={this.props.onCancel}
                isDeleted={this.props.organization.deleted}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    name: this.props.organization.name,
                    description: this.props.organization.description,
                    organizationNumber: this.props.organization.organizationNumber ?? null,
                    tagIds: _.map(this.props.organization.tags, tag => {
                        if (tag.category?.deleted || tag?.deleted) return;
                        return tag.id; 
                    }),
                    isCustomer: this.props.organization.customerInfo !== null,
                    isSupplier: this.props.organization.supplierInfo !== null,
                    creditLimit: this.props.organization.customerInfo?.creditLimit,
                    invoiceEmail: this.props.organization.customerInfo?.email,
                    customerCategoryId: this.props.organization.customerInfo?.category?.id,
                    supplierCategoryId: this.props.organization.supplierInfo?.category?.id,
                }}
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please input your name"
                    placeholder="Name"
                    title="Name"
                />

                <TextAreaInput
                    param="description"
                    placeholder="Name"
                    title="Description"
                />

                <TextInput
                    param="organizationNumber"
                    placeholder="Organization number"
                    title="Organization number"
                />

                <div>
                    {_.map(this.state.contactPoints ?? [], cp => {
                        return (
                            <div className="person-edit-info actor-edit-cps" key={cp.id}>
                                <div className="edit-label">{cp.type}</div>
                                <div className="edit-container">
                                    <div className="edit-value">{cp.value}</div>
                                    <div className="edit-actions">
                                        <Button onClick={() => this.onCpEdit(cp as ContactPointLinkView)} icon={<EditOutlined />} shape="circle" />
                                        <Popconfirm title="Remove this contact point?" onConfirm={() => this.onCpRemove(cp as ContactPointLinkView)}>
                                            <Button icon={<CloseOutlined />} shape="circle" />
                                        </Popconfirm>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Organization] }} />}
                />

                <CustomFormLabel label="Type of business relation" optional />

                <Space>
                    <Form.Item
                        name="isCustomer"
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(e) =>
                                this.setState({ isCustomer: e.target.checked })
                            }
                        >Customer</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="isSupplier"
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(e) =>
                                this.setState({ isSupplier: e.target.checked })
                            }
                        >Supplier</Checkbox>
                    </Form.Item>
                </Space>

                {this.state.isCustomer ? (
                    <>
                        <NumberInput
                            param="creditLimit"
                            title="Credit limit"
                            placeholder="Enter"
                            addonAfter="kr"
                        />
                        <TextInput param="invoiceEmail" title="Invoice email" placeholder="Enter" type="email" />
                        <SelectorInput 
                            param="customerCategoryId"
                            title="Customer category"
                            selector={<CustomerCategorySelector />}
                        />
                    </>
                ) : null}
                
                {this.state.isSupplier ? (
                    <>
                        <SelectorInput 
                            param="supplierCategoryId"
                            title="Supplier category"
                            selector={<SupplierCategorySelector />}
                        />
                    </>
                ) : null}

                <Drawer
                    title="Edit contact point"
                    onClose={() => this.setState({ contactPoint: null })}
                    open={this.state.contactPoint != null}
                    destroyOnClose={true}
                    component={
                        <ContactPointEditForm
                            actorId={this.props.organization.id}
                            contactPoint={this.state.contactPoint as ContactPointView}
                            onComplete={(data) => { this.cpEditComplete(data)}}
                            onCancel={() => this.setState({ contactPoint: null })}
                        />
                    }
                />
            </BaseForm>
        );
    }
}