import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Radio, Space, RadioChangeEvent } from "antd";
import { ResumeOrUpdateSubscriptionLine, SubscriptionLineView, SubscriptionType, SubscriptionView } from "../../ApiClient/swagger/data-contracts";
import { LineDrawerActionType } from "./SubscriptionLineList";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { findActiveSubscriptionline, findCurrentTypeOrStatus, findLatestFutureLine } from "./SubscriptionHelper";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { ProductSelector } from "../Products";
import { NumberInput } from "../Shared/NumberInput";
import { PercentageInput } from "../Shared/PercentageInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";


interface SubscriptionLineResumeOrUpdateFormProps {
    onComplete: (updated: SubscriptionLineView[]) => void;
    onCancel?: () => void;
    subscription: SubscriptionView;
    line: SubscriptionLineView;
    selectedLines: SubscriptionLineView[];
    lineDrawerType: LineDrawerActionType;
    onLineUpdateSuccess: (line: SubscriptionLineView[]) => void;
    updateFromProductStatus?: SubscriptionLineView[];
    hideContent?: boolean;
    disableSave?: boolean;
}

interface SubscriptionLineResumeOrUpdateFormState {
    loading: boolean,
    error: string,
    line: SubscriptionLineView;
    changeDate: string;
}

export class SubscriptionLineResumeOrUpdateForm extends React.Component<SubscriptionLineResumeOrUpdateFormProps, SubscriptionLineResumeOrUpdateFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            line: null,
            changeDate: moment().startOf('month').startOf('day').format(),
        }
    }

    componentDidMount = () => {
        if (this.props.line) {
            const future = findLatestFutureLine(this.props.line);
            if (future)
                this.setState({ changeDate: moment(future.start).format() });

            this.setState({ line: this.props.line });
        }
        else if (this.props.updateFromProductStatus?.length == 1)
            this.setState({ line: this.props.updateFromProductStatus[0] });
    }

    componentDidUpdate = (prevProps: SubscriptionLineResumeOrUpdateFormProps) => {
        if (prevProps.line?.id != this.props.line?.id) {
            this.setState({ line: this.props.line });
        }
    }

    onSubmit = async (request: ResumeOrUpdateSubscriptionLine) => {
        this.setState({ loading: true });

        if (this.state.line) {
            const res = await client.subscriptions
                .resumeOrUpdateSubscriptionLine(this.props.subscription.id, this.state.line.id, request, addChangeVectorHeader(this.props.subscription.changeVector))
                .catch(exception => this.setState({ error: exception.error }));

            if (res) this.props.onComplete([res.data]);
        }
        else if (this.props.selectedLines?.length > 0) {
            const results: SubscriptionLineView[] = [];
            let errorMsg = null;
            const successLines = [];

            for (let i = 0; i < this.props.selectedLines.length; i++) {
                const line = this.props.selectedLines[i];

                const req: ResumeOrUpdateSubscriptionLine = {
                    application: request.application,
                    discount: line.discount,
                    quantity: this.props.lineDrawerType == LineDrawerActionType.Add ? line.quantity + request.quantity : this.props.lineDrawerType == LineDrawerActionType.Remove ? line.quantity - request.quantity : line.quantity,
                    note: request.note,
                    internalNote: request.internalNote,
                };

                const response = await client
                    .subscriptions.resumeOrUpdateSubscriptionLine(this.props.subscription.id, line.id, req)
                    .catch(exception => errorMsg = exception.error.title);

                results.push(response.data);
                successLines.push(line);
            }

            if (errorMsg) {
                this.setState({ error: errorMsg });
                if (this.props.onLineUpdateSuccess)
                    this.props.onLineUpdateSuccess(successLines);
            }
            else
                this.props.onComplete(results);
        }

        this.setState({ loading: false });
    }

    onLineChange = (event: RadioChangeEvent) => {
        const match = _.find(this.props.updateFromProductStatus, ps => ps.id == event.target.value);
        this.setState({ line: match });
    }

    onDateChange = (event) => {
        this.setState({
            changeDate: moment(event).format(),
        })
    }

    onLineQuantityChange = (quantity: number) => {
        const line = this.state.line;
        line.quantity = quantity;

        this.setState({
            line: line,
        })
    }

    render() {
        const isMultiple = !this.state.line && this.props.selectedLines?.length > 0;

        let productStatuses;
        if (this.props.updateFromProductStatus?.length > 1) {

            productStatuses = _.map(this.props.updateFromProductStatus, (ps, key) => {
                const activeLine = findActiveSubscriptionline(ps, true);
                return (
                    <Radio value={ps.id} key={key} className="subscription-line-radio-item">
                        <table className="radio-info-table">
                            <thead>
                                <tr>
                                    <th>Start</th>
                                    <th>Quantity</th>
                                    <th>Discount (%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{moment(activeLine.start).format('DD.MM.YYYY')}</td>
                                    <td>{activeLine.quantity}</td>
                                    <td>{activeLine.discount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Radio>
                );
            });

        }

        let internalNote = null;

        if (this.state.line && this.state.line.history.length > 0) {
            _.map(this.state.line.history, history => {
                if (moment(history.start).isSame(moment(this.state.changeDate))) {
                    if (history.internalNote && history.internalNote != "") {
                        internalNote = history.internalNote;
                    }
                }
            })
        }

        const type = findCurrentTypeOrStatus(this.props.subscription.typeHistory);

        let currentBillingPeriod = null;
        let nextBillingPeriod = null;
        if (type && type.value == SubscriptionType.Yearly && this.state.line) {
            if (this.state.line.latestBindingPeriod != null) {
                const latestBinding = this.state.line.latestBindingPeriod;
                if (moment(this.state.line.latestBindingPeriod.periodStart) <= moment().startOf('day') && moment(latestBinding.periodEnd) > moment().endOf('day')) {
                    currentBillingPeriod = `${moment(latestBinding.periodStart).format('DD.MM.YYYY')} - ${moment(latestBinding.periodEnd).format('DD.MM.YYYY')}`;
                    nextBillingPeriod = `${moment(latestBinding.periodStart).add(1, 'year').format('DD.MM.YYYY')} - ${moment(latestBinding.periodEnd).add(1, 'year').format('DD.MM.YYYY')}`;
                }
                else {
                    currentBillingPeriod = "-";
                    nextBillingPeriod = `${moment(latestBinding.periodStart).format('DD.MM.YYYY')} - ${moment(latestBinding.periodEnd).format('DD.MM.YYYY')}`;
                }
            }
            else {
                const start = moment(this.props.line.start);
                const end = /*this.props.line.end ? moment(this.props.line.end) : */start.clone().add(1, 'year').subtract(1, 'day');
                const startsInFuture = start > moment().startOf('day');
                const normalStartEnd = `${start.format('DD.MM.YYYY')} - ${end.format('DD.MM.YYYY')}`

                currentBillingPeriod = startsInFuture ? null : normalStartEnd
                nextBillingPeriod = startsInFuture ? normalStartEnd : `${start.clone().add(1, 'year').format('DD.MM.YYYY')} - ${end.clone().add(1, 'year').format('DD.MM.YYYY')}`
            }
        }

        return (
            <React.Fragment>
                {productStatuses != null ?
                    <Radio.Group className="subscription-line-radiogroup" onChange={this.onLineChange} value={this.state.line?.id ?? null}>
                        <Space direction="vertical">
                            {productStatuses}
                        </Space>
                    </Radio.Group>
                    : null}

                {(this.state.line || isMultiple) && !this.state.loading ?
                    <BaseForm
                        type={FormType.Edit}
                        onSubmit={this.props.disableSave ? null : this.onSubmit}
                        onCancel={this.props.onCancel}
                        loading={this.state.loading}
                        error={this.state.error}
                        className={`resumeOrUpdate-form ${this.props.hideContent ? "hideContent" : ""}`}
                        initialValues={{
                            application: this.props.lineDrawerType == LineDrawerActionType.Remove
                                ? moment().endOf('month').endOf('day').format()
                                : this.state.changeDate/*moment().startOf('month').startOf('day').format()*/,
                            quantity: this.props.line != null ? this.props.line.quantity : null,
                            discount: this.state.line != null ? this.state.line.discount : null,
                            note: this.state.line != null ? this.state.line.note : null,
                            internalNote: this.state.line != null ? internalNote : null,
                            selectedLines: isMultiple ? _.map(this.props.selectedLines || [], line => line.product.id) : null
                        }}
                    >
                        <div className="billing-periods">
                            {currentBillingPeriod ? <div className={`ant-row ant-form-item current-billing`}>
                                <div className="ant-col ant-form-item-label sub-label">
                                    <label>
                                        <div className="selector-label-container">
                                            <span className="text-title">Current billing period</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="sub-value">{currentBillingPeriod}</div>
                            </div> : null}

                            {nextBillingPeriod ? <div className={`ant-row ant-form-item next-billing`}>
                                <div className="ant-col ant-form-item-label sub-label">
                                    <label>
                                        <div className="selector-label-container">
                                            <span className="text-title">Next billing period</span>
                                        </div>
                                    </label>
                                </div>
                                <div className="sub-value">{nextBillingPeriod}</div>
                            </div> : null}
                        </div>

                        <SelectorInput
                            param="application"
                            required
                            warningMessage="Please choose a change date"
                            title="Change date"
                            selector={
                                <DateSelector onChange={this.onDateChange} /*disabled={findCurrentTypeOrStatus(this.props.subscription.typeHistory)?.value != SubscriptionType.Yearly ? false : true}*/ />
                            }
                        />

                        {isMultiple ?
                            <SelectorInput
                                param="selectedLines"
                                title="Products"
                                selector={
                                    <ProductSelector
                                        multiple
                                        disabled
                                    />
                                }
                            /> : null}

                        <NumberInput
                            param="quantity"
                            title={this.props.lineDrawerType == LineDrawerActionType.Remove ? "Quantity to remove" : this.props.selectedLines?.length > 0 ? "Quantity to add" : "Quantity"}
                            required
                            warningMessage="Please fill in the quantity"
                            onChange={this.onLineQuantityChange}
                        />

                        {isMultiple ? null :
                            <PercentageInput
                                param="discount"
                                title="Discount"
                            />}

                        <TextAreaInput
                            title="Invoice note"
                            param="note"
                        />

                        <TextAreaInput
                            title="Internal note"
                            param="internalNote"
                        />
                    </BaseForm>
                    : null}
            </React.Fragment>
        );
    }
}

export default SubscriptionLineResumeOrUpdateForm;