/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ReadyToTransferStats,
  SortDirection,
  SubscriptionTransferAggregationResultSubscriptionTransferAggregateQueryAggregationResults,
  SubscriptionTransferAggregationTypes,
  SubscriptionTransferSortOption,
  SubscriptionTransferView,
  SubscriptionTransferViewEntityViewModel,
  SubscriptionTransferViewPagedSubscriptionTransferQueryCollectionViewModel,
  SubscriptionType,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Subscriptiontransfers<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SubscriptionTransfer
   * @name QuerySubscriptionTransfers
   * @request GET:/api/subscriptiontransfers
   * @secure
   */
  querySubscriptionTransfers = (
    query?: {
      sortBy?: SubscriptionTransferSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      types?: SubscriptionType[];
      subscriptionId?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SubscriptionTransferViewPagedSubscriptionTransferQueryCollectionViewModel, any>({
      path: `/api/subscriptiontransfers`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionTransfer
   * @name GetSubscriptionTransfer
   * @request GET:/api/subscriptiontransfers/{id}
   * @secure
   */
  getSubscriptionTransfer = (id: string, params: RequestParams = {}) =>
    this.http.request<SubscriptionTransferViewEntityViewModel, any>({
      path: `/api/subscriptiontransfers/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionTransfer
   * @name GetReadyToTransferStats
   * @request GET:/api/subscriptiontransfers/{id}/readyToTransfer
   * @secure
   */
  getReadyToTransferStats = (id: string, params: RequestParams = {}) =>
    this.http.request<ReadyToTransferStats[], any>({
      path: `/api/subscriptiontransfers/${id}/readyToTransfer`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionTransfer
   * @name CalculateSubscriptionTransfer
   * @request GET:/api/subscriptiontransfers/{id}/calculate
   * @secure
   */
  calculateSubscriptionTransfer = (id: string, params: RequestParams = {}) =>
    this.http.request<SubscriptionTransferView, any>({
      path: `/api/subscriptiontransfers/${id}/calculate`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionTransfer
   * @name TransferSubscriptionTransfer
   * @request POST:/api/subscriptiontransfers/{id}/transfer
   * @secure
   */
  transferSubscriptionTransfer = (id: string, params: RequestParams = {}) =>
    this.http.request<SubscriptionTransferView, any>({
      path: `/api/subscriptiontransfers/${id}/transfer`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionTransfer
   * @name TransferSingleSubscription
   * @request POST:/api/subscriptiontransfers/{id}/transfer/{subscriptionId}
   * @secure
   */
  transferSingleSubscription = (id: string, subscriptionId: string, params: RequestParams = {}) =>
    this.http.request<SubscriptionTransferView, any>({
      path: `/api/subscriptiontransfers/${id}/transfer/${subscriptionId}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionTransfer
   * @name QuerySubscriptionTransferAggregates
   * @request GET:/aggs/subscriptiontransfers
   * @secure
   */
  querySubscriptionTransferAggregates = (
    query?: {
      aggs?: SubscriptionTransferAggregationTypes[];
      types?: SubscriptionType[];
      subscriptionId?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SubscriptionTransferAggregationResultSubscriptionTransferAggregateQueryAggregationResults, any>({
      path: `/aggs/subscriptiontransfers`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
