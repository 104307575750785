import * as React from "react";
import _ from 'lodash';
import { FolderView, PagedApplicationQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { DeleteEntityFolder, EditEntityFolder, RestoreEntityFolder } from "../../ApiClient/EntityFileClient";
import { ApplicationSelector } from "../Applications";
import { AdditionalInfo } from "../Shared/AdditionalInfo";

interface EditFolderProps {
    onComplete: (updatedFolder: FolderView) => void;
    entityId: string;
    folder: FolderView;
    onDelete?: () => void;
    onCancel?: () => void;
    editFolderSource: EditEntityFolder;
    deleteFolderSource: DeleteEntityFolder;
    restoreFolderSource: RestoreEntityFolder;
}

interface EditFolderState {
    loading: boolean;
    error: string | null;
    folderName: string;
}


export class FolderEditForm extends React.Component<EditFolderProps, EditFolderState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            folderName: null
        }
    }

    onSubmit = async (request: any) => {
        this.setState({ loading: true });

        try {
            const folder = await this.props.editFolderSource(this.props.entityId, this.props.folder.id, request);

            if (folder) {
                this.props.onComplete(folder.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onFolderNameChange = (e) => {
        this.setState({ folderName: e.target.value });
    }

    onDelete = async () => {
        if (this.props.onDelete) {
            this.props.onDelete();
            return;
        }

        this.setState({ loading: true });

        try {
            const response = await this.props.deleteFolderSource(this.props.entityId, this.props.folder.id);
            if (response)
                this.props.onComplete(response.data);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onRestore = async () => {
        if (this.props.folder != null) {
            try {
                const response = await this.props.restoreFolderSource(this.props.entityId, this.props.folder.id);
                this.props.onComplete(response?.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    render() {


        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                loading={this.state.loading}
                error={this.state.error}
                isDeleted={this.props.folder.deleted}
                initialValues={{
                    name: this.props.folder ? this.props.folder.name : null,
                    description: this.props.folder ? this.props.folder.description : null,
                    applicationIds: _.map(this.props.folder?.applications ?? [], a => a.id)
                }}
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Name"
                />


                <AdditionalInfo defaultOpen={this.props.folder?.description != null || _.reject(this.props.folder?.applications ?? [], a => a.id === this.context.applicationId)?.length > 0}>
                    <TextAreaInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                    />

                    <SelectorInput
                        param="applicationIds"
                        title="Share with"
                        selector={
                            <ApplicationSelector
                                disableOptionIds={[this.context.applicationId]}
                                multiple
                                filters={{
                                    deleted: false
                                } as PagedApplicationQuery}
                            />
                        }
                    />
                </AdditionalInfo>


            </BaseForm>
        );
    }
}

export default FolderEditForm;
