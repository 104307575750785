import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Button, Radio, RadioChangeEvent, Space } from "antd";
import { OrganizationAccessLevel, OrganizationRole, OrganizationRoleView, OrganizationView, PersonView } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { TextInput } from "../Shared/TextInput";
import { Drawer } from "../Shared/Drawer";
//import { OrganizationCreateForm, OrganizationSelector } from "../Organizations";
import DateSelector from "../Shared/DateSelector";
import AppContext from "../../Definitions/AppContext";
import { OrganizationCreateForm } from "../Organizations/OrganizationCreateForm";
import OrganizationSelector from "../Organizations/OrganizationSelector";


interface OrganizationRoleFormProps {
    onComplete: (person: PersonView) => void,
    onCancel?: () => void,
    person: PersonView;
    organizationRole?: OrganizationRoleView
    organization?: OrganizationView;
}

interface OrganizationRoleFormState {
    loading: boolean,
    error: string,
    showCreateOrganizationDrawer: boolean;
    organization: Partial<OrganizationView>;
    leftOrganizationType: LeftOrganizationType;
}

interface FromOrganizationPageRequest extends OrganizationRole {
    organizationId: string;
    newOrganizationId: string;
    newDescription: string;
    newJobTitle: string;
}

enum LeftOrganizationType {
    No = 1,
    YesOrgNew = 2,
    YesOrgNone = 3
}

export class OrganizationRoleForm extends React.Component<OrganizationRoleFormProps, OrganizationRoleFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            showCreateOrganizationDrawer: false,
            organization: this.props.organization,
            leftOrganizationType: LeftOrganizationType.No
        }
    }

    onSubmit = async (tempRequest: FromOrganizationPageRequest) => {
        this.setState({ loading: true });

        try {
            const request: OrganizationRole = {
                accessLevel: tempRequest.accessLevel,
                description: tempRequest.description,
                jobTitle: tempRequest.jobTitle
            };

            if (tempRequest.endDate)
                request.endDate = tempRequest.endDate;

            if (request.accessLevel == null)
                request.accessLevel = OrganizationAccessLevel.None;

            const changeVector = this.props.person.changeVector;

            if (tempRequest.newOrganizationId) {
                const newOrgRoleRequest = {
                    accessLevel: tempRequest.accessLevel,
                    description: tempRequest.newDescription,
                    jobTitle: tempRequest.newJobTitle
                };

                await client.people.addOrUpdatePersonOrganizationRole(this.props.person.id, tempRequest.newOrganizationId, newOrgRoleRequest, addChangeVectorHeader(changeVector));
                //changeVector = newOrg.changeVector;
            }

            const response = await client.people.addOrUpdatePersonOrganizationRole(this.props.person.id, tempRequest.organizationId, request/*, addChangeVectorHeader(changeVector)*/);

            if (response) this.props.onComplete(response.data);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    toggleAddOrganizationDrawer = () => {
        this.setState({ showCreateOrganizationDrawer: !this.state.showCreateOrganizationDrawer });
    }

    onCompleteAddOrganization = async (data) => {
        try {
            if (data) {
                this.setState({ organization: data });
                this.toggleAddOrganizationDrawer();
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onLeftOrgTypeChange = (e: RadioChangeEvent) => {
        this.setState({ leftOrganizationType: e.target.value });
    }

    disabledEndDates = (date) => {
        const future = date.endOf('day') > moment().endOf('day');
        const olderThanYearAgo = moment().subtract(1, "year").endOf('day') > date.endOf('day');
        return future || olderThanYearAgo;
    }

    render() {

        const initialValues = {
            organizationId: this.props.organizationRole ? this.props.organizationRole.organization.id : this.state.organization ? this.state.organization.id : null,
            accessLevel: this.props.organizationRole ? this.props.organizationRole.role : null,
            description: this.props.organizationRole ? this.props.organizationRole.description : null,
            jobTitle: this.props.organizationRole ? this.props.organizationRole.jobTitle : null,
            endDate: this.props.organizationRole ? this.props.organizationRole.endDate : null
        };

        if (this.state.leftOrganizationType == LeftOrganizationType.YesOrgNew || this.state.leftOrganizationType == LeftOrganizationType.YesOrgNone)
            initialValues.endDate = moment().endOf('day').format();

        return (
            <BaseForm
                type={this.props.organizationRole != null ? FormType.Edit : FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                className="organizationRoleForm"
                initialValues={initialValues}
            >
                <SelectorInput
                    param="organizationId"
                    required
                    warningMessage="Please choose an organization"
                    title={
                        <React.Fragment>
                            <span style={{ width: '100%' }}>Organization</span>
                            <Button disabled={this.props.organizationRole != null} type="link" onClick={this.toggleAddOrganizationDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>{this.context.isMobile ? "+ Add" : "+ Add organization"}
                            </Button>
                        </React.Fragment>
                    }
                    selector={
                        <OrganizationSelector
                            placeholder="Select organization"
                            disabled={this.props.organizationRole != null}
                        />
                    }
                />

                <TextInput
                    param="description"
                    placeholder="Job title"
                    title="Job title"
                />

                <Drawer
                    title="Create organization"
                    onClose={this.toggleAddOrganizationDrawer}
                    open={this.state.showCreateOrganizationDrawer}
                    destroyOnClose={true}
                    component={this.state.showCreateOrganizationDrawer ?
                        <OrganizationCreateForm
                            onCancel={this.toggleAddOrganizationDrawer}
                            onComplete={(created) => created ? this.onCompleteAddOrganization(created) : this.toggleAddOrganizationDrawer()}
                        /> : null
                    }
                />

                {
                    this.props.organizationRole != null ?
                        <div className="leftOrgInputContainer">
                            <div className="leftLabel">Has the person left the organization?</div>

                            <Radio.Group value={this.state.leftOrganizationType} onChange={this.onLeftOrgTypeChange}>
                                <Space direction="vertical">
                                    <Radio value={LeftOrganizationType.No}>No</Radio>
                                    <Radio value={LeftOrganizationType.YesOrgNew}>Yes, and started in a new organization</Radio>
                                    <Radio value={LeftOrganizationType.YesOrgNone}>Yes, but not started in a new organization</Radio>
                                </Space>
                            </Radio.Group>
                        </div>
                        : null
                }

                {
                    this.state.leftOrganizationType == LeftOrganizationType.YesOrgNew
                        ?
                        <>
                            <SelectorInput
                                param="endDate"
                                required
                                title="End/start date"
                                selector={<DateSelector placeholder="Date" endOfDay disabledDate={this.disabledEndDates} />}
                                className="endDate-input"
                            />
                            <div className="endDate-sub-text">After this date the person will be connected to the new organization. It can not be a future date.</div>
                        </>
                        : this.state.leftOrganizationType == LeftOrganizationType.YesOrgNone
                            ?
                            <>
                                <SelectorInput
                                    param="endDate"
                                    required
                                    title="End date"
                                    selector={<DateSelector placeholder="Date" endOfDay disabledDate={this.disabledEndDates} />}
                                    className="endDate-input"
                                />
                                <div className="endDate-sub-text">After this date the person will no longer be connected to the existing organization. It can not be a future date.</div>
                            </>
                            : null
                }

                {
                    this.state.leftOrganizationType == LeftOrganizationType.YesOrgNew
                        ?
                        <>
                            <SelectorInput
                                param="newOrganizationId"
                                required
                                warningMessage="Please choose an organization"
                                title={
                                    <React.Fragment>
                                        <span style={{ width: '100%' }}>New organization</span>
                                        <Button type="link" onClick={this.toggleAddOrganizationDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>{this.context.isMobile ? "+ Add" : "+ Add organization"}
                                        </Button>
                                    </React.Fragment>
                                }
                                selector={
                                    <OrganizationSelector
                                        placeholder="Select organization"
                                    />
                                }
                            />

                            <TextInput
                                param="newDescription"
                                placeholder="Enter job title"
                                title="New job title"
                            />
                        </>
                        : null
                }

            </BaseForm>
        );
    }
}

export default OrganizationRoleForm;