import * as React from "react";
import _ from 'lodash';
import { OrderDateType, OrderType } from "../../ApiClient/swagger/data-contracts";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";

interface OrderDateProps {
    type: OrderType
}

class OrderDateSelector extends React.Component<OrderDateProps> {
    render = () => {
        let dates = null;

        if (this.props.type == OrderType.Quotation) {
            dates = (
                <React.Fragment>
                    <SelectorInput
                        key="quotation"
                        param={OrderDateType.Quotation}
                        title="Quotation date"
                        required
                        warningMessage="Please choose a date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="quotationExpiry"
                        param={OrderDateType.QuotationExpiry}
                        title="Expiry date"
                        required
                        warningMessage="Please choose a expiry date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="requestedDelivery"
                        param={OrderDateType.RequestedDelivery}
                        warningMessage="Please choose a date"
                        title="Delivery date"
                        selector={<DateSelector />}
                    />
                </React.Fragment>
            );
        }
        else if (this.props.type == OrderType.Normal) {
            dates = (
                <React.Fragment>
                    <SelectorInput
                        key="sales"
                        param={OrderDateType.Sales}
                        title="Sales date"
                        required
                        warningMessage="Please choose a sales date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="salesCorrection"
                        param={OrderDateType.SalesCorrection}
                        title="Correction date"
                        selector={<DateSelector />}
                    />
                    <SelectorInput
                        key="salesProduction"
                        param={OrderDateType.SalesProduction}
                        title="Production date"
                        selector={<DateSelector />}
                    />
                    <SelectorInput
                        key="requestedDelivery"
                        param={OrderDateType.RequestedDelivery}
                        warningMessage="Please choose a delivery date"
                        required
                        title="Delivery date"
                        selector={<DateSelector />}
                    />
                </React.Fragment>
            );
        }
        else if (this.props.type == OrderType.Direct) {
            dates = (<SelectorInput
                key="sales"
                param={OrderDateType.Sales}
                title="Sales date"
                selector={<DateSelector />}
            />);
        }
        else if (this.props.type == OrderType.Production) {
            dates = (
                <React.Fragment>
                    <SelectorInput
                        key="salesProduction"
                        param={OrderDateType.SalesProduction}
                        required
                        title="Production date"
                        selector={<DateSelector />}
                    />                   
                </React.Fragment>
            );
        }

        return (
            <>
                {dates}
            </>
        );
    }
}

export default OrderDateSelector;