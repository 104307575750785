import * as React from "react";
import moment from "moment";
import { Card, Typography, Progress, Divider } from 'antd';
import { ProjectType, ProjectView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { formatCurrency, formatDuration } from "../../Helpers/Formatters";
import { Capabilities } from "../../Definitions/_capabilties";

const { Title } = Typography;

interface ProjectProgressionCardProps {
    project: ProjectView;
}

export class ProjectProgressionCard extends React.Component<ProjectProgressionCardProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {};
    }

    render = () => {
        const { project } = this.props;

        const estimatedTaskProgress = project.aggregatedEstimate && project.aggregatedEstimate > 0 ? Math.ceil((project.durationOnEstimatedTasks / project.aggregatedEstimate) * 100) : 0;
        const projectEstimateProgress = project.estimate && project.estimate > 0 ? Math.ceil((project.duration / project.estimate) * 100) : 0;

        //var salesSum = project.runningSalesPrice;

        const displayTaskEstimate = project.aggregatedEstimate && project.aggregatedEstimate != project.estimate;

        const taskIsOverEstimate = estimatedTaskProgress > 100;
        const projectIsOverEstimate = projectEstimateProgress > 100;

        let taskProps = {};
        if (taskIsOverEstimate) {
            //var taskPercentageOver = estimatedTaskProgress % 100;
            //var taskPercentage = estimatedTaskProgress >= 200 ? 100 : 100 - taskPercentageOver;
            const taskPercentage = ((project.aggregatedEstimate ?? 0) / (project.durationOnEstimatedTasks ?? 1)) * 100;

            taskProps = {
                success: {
                    percent: taskPercentage,
                    strokeColor: /*taskPercentage >= 100 ? "#ff4d4f" : */"rgb(255, 165, 0)"
                }
            };
        }

        let projectProps = {};
        if (projectIsOverEstimate) {
            //var projectPercentageOver = projectEstimateProgress % 100;
            //var projectPercentage = projectEstimateProgress >= 200 ? 100 : 100 - projectPercentageOver;
            const projectPercentage = ((project.estimate ?? 0) / (project.duration ?? 1)) * 100;

            projectProps = {
                success: {
                    percent: projectPercentage,
                    strokeColor: /*projectPercentage >= 100 ? "#ff4d4f" : */"rgb(255, 165, 0)"
                }
            };
        }

        const projectEnded = project.ended != null;
        const trailColor = '#e5e5e5';

        const isMoneyProject = project.type == ProjectType.Fixed || project.type == ProjectType.Hourly;

        const invoiced = project.netInvoiced ?? 0;
        let runningSales = 0;

        if (project.type == ProjectType.Hourly)
            runningSales = project.runningSalesPrice ?? 0;

        else if (project.type == ProjectType.Fixed)
            runningSales = project.price ?? 0;

        const invoicedProgress = isMoneyProject ? runningSales > 0 ? Math.ceil((invoiced / runningSales) * 100) : 0 : 0;

        return (
            <Card
                title={
                    <div className="title-container">
                        <div className="progression-title">
                            <Title level={4} className="title">Progression</Title>
                            {project.firstActivity ? <span className="date-container">Started: <span className="date">{moment(project.firstActivity).format('DD.MM.YYYY')}</span></span> : null}
                        </div>
                        {project.ended ? <div className="date-container end-date">Ended: <span className="date">{moment(project.ended).startOf('day').format('DD.MM.YYYY')}</span></div> : null}
                    </div>
                }
                className="project-progress-card progression-card"
            >
                {displayTaskEstimate ? <div className="estimate-container task-estimate">
                    <div className="estimated-progress">
                        <span className="upper-text">Estimated tasks</span>
                        <span className="upper-text-percentage">{estimatedTaskProgress ? estimatedTaskProgress.toFixed(1) : "-"}%</span>
                    </div>

                    <Progress
                        className="progress-bar"
                        percent={estimatedTaskProgress}
                        status={!projectEnded && !taskIsOverEstimate ? "normal" : taskIsOverEstimate ? "exception" : "success"}
                        showInfo={false}
                        trailColor={trailColor}
                        {...taskProps}
                    />

                    <div className="estimated-progress lower-text">
                        <span className="lower-text">Duration: <span className="duration">{formatDuration(project.durationOnEstimatedTasks)}</span></span>
                        <span className="total">Estimate: <span className="duration">{formatDuration(project.aggregatedEstimate)}</span></span>
                    </div>
                </div> : null}

                <div className={`estimate-container ${displayTaskEstimate ? "estimate-container-nr2" : ""}`}>
                    <div className="estimated-progress">
                        <span className="upper-text">Project</span>
                        <span><span className="upper-text-percentage">{projectEstimateProgress ? projectEstimateProgress.toFixed(1) : "-"}%</span></span>
                    </div>

                    <Progress
                        className="progress-bar"
                        percent={projectEstimateProgress}
                        status={!projectEnded && !projectIsOverEstimate ? "normal" : projectIsOverEstimate ? "exception" : "success"}
                        showInfo={false}
                        trailColor={trailColor}
                        {...projectProps}
                    />

                    <div className="estimated-progress lower-text">
                        <span className="lower-text">Duration: <span className="duration">{formatDuration(project.duration)}</span></span>
                        <span className="total">Project estimate: {project.estimate ? <span className="duration">{formatDuration(project.estimate)}</span> : <span className="duration">-</span>}</span>
                    </div>
                </div>

                {isMoneyProject && this.context.user.hasCapability(Capabilities.InvoicesRead) ?
                    <React.Fragment>
                        <Divider />

                        <div className={`estimate-container invoice-container`}>
                            <div className="estimated-progress">
                                <span className="upper-text">Invoices</span>
                                <span><span className="upper-text-percentage">{invoicedProgress ? invoicedProgress.toFixed(1) : "-"}%</span></span>
                            </div>

                            <Progress
                                className="progress-bar"
                                percent={invoicedProgress}
                                status={invoiced > 0 && invoiced == runningSales ? "success" : !projectEnded ? "normal" : "exception"}
                                showInfo={false}
                                trailColor={trailColor}
                            />

                            <div className="estimated-progress lower-text">
                                <span className="lower-text">Invoiced: <span className="duration">{formatCurrency(invoiced, 2)}</span></span>
                                <span className="total">{project.type == ProjectType.Fixed ? "Price: " : "Running sales: "} <span className="duration">{formatCurrency(runningSales, 2)}</span></span>
                            </div>
                        </div>
                    </React.Fragment>
                    : null}
            </Card>
        );
    }
}