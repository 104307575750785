import * as React from "react";
import _ from 'lodash';
import { UpOutlined, DownOutlined } from "@ant-design/icons";


interface AdditionalInfoProps {
    className?: string;
    content?: React.ReactNode;
    defaultOpen?: boolean;
    children?: React.ReactNode;
}

interface AdditionalInfoState {
    displayMoreInfo: boolean;
}

export class AdditionalInfo extends React.Component<AdditionalInfoProps, AdditionalInfoState> {
    constructor(props: any) {
        super(props);

        this.state = {
            displayMoreInfo: this.props.defaultOpen ?? false
        }
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    render() {
        return (
            <div className="additional-info-component">
                <div className={`ant-form-item-label more-info-header custom-form-label ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}>
                    <div className="more-info-label">
                        <label className="additional-info-label">Additional info (optional)
                            <span className="more-info-icon-container">
                                {this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}
                            </span>
                        </label>
                    </div>
                </div>

                <div className="more-info-content" style={!this.state.displayMoreInfo ? { display: 'none' } : {}}>
                    {this.props.content ?? this.props.children}
                </div>
            </div>
        );
    }
}