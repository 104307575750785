/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateNotificationWebRequest,
  NotificationMode,
  NotificationView,
  NotificationViewNotificationQueryReadCounterCollectionViewModel,
  UpdateNotificationStatusCommand,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Notifications<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Notification
   * @name QueryNotifications
   * @request GET:/api/notifications
   * @secure
   */
  queryNotifications = (
    query?: {
      userId?: string;
      mode?: NotificationMode;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NotificationViewNotificationQueryReadCounterCollectionViewModel, any>({
      path: `/api/notifications`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Notification
   * @name CreateAdminNotification
   * @request POST:/api/notifications
   * @secure
   */
  createAdminNotification = (data: CreateNotificationWebRequest, params: RequestParams = {}) =>
    this.http.request<NotificationView, any>({
      path: `/api/notifications`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Notification
   * @name GetNotification
   * @request GET:/api/notifications/{id}
   * @secure
   */
  getNotification = (
    id: string,
    query?: {
      id?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NotificationView, any>({
      path: `/api/notifications/${id}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Notification
   * @name UpdateStatus
   * @request PUT:/api/notifications/{id}/status
   * @secure
   */
  updateStatus = (id: string, data: UpdateNotificationStatusCommand, params: RequestParams = {}) =>
    this.http.request<NotificationView, any>({
      path: `/api/notifications/${id}/status`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Notification
   * @name UpdateStatusMany
   * @request POST:/api/notifications/status
   * @secure
   */
  updateStatusMany = (data: UpdateNotificationStatusCommand, params: RequestParams = {}) =>
    this.http.request<NotificationView[], any>({
      path: `/api/notifications/status`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
