import * as React from "react";
import _ from 'lodash';
import { Button } from 'antd';
import { ApplicationView, CreateOrUpdateRole, PagedApplicationQuery, RoleView } from "../../ApiClient/swagger/data-contracts";
import { Drawer } from "../Shared/Drawer";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import RoleSelector from "./RoleSelector";
import { TextInput } from "../Shared/TextInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { ApplicationSelector } from "../Applications";
import { CheckboxGroupInput } from "../Shared/CheckboxGroupInput";
import CapabilitiesEditor, { CapabilityGroupView, CapabilitySubGroupView, CapabilityView } from "./CapabilitiesEditor";
import AppContext from "../../Definitions/AppContext";



interface CreateRoleProps {
    onComplete: (created?: RoleView) => void;
    onCancel: () => void;
}

interface CreateRoleState {
    loading: boolean;
    error: string | null;

    application: ApplicationView;
    groupedApplicationCapabilities: CapabilityGroupView[];

    import_showDrawer: boolean;
    import_selectedRole: RoleView;

    initialCapabilities: string[];
}

enum RoleCreateFormPromptEvents {
    Change = "rolecreateformchange",
    Clear = "rolecreateformclear"
}

export class RoleCreateForm extends React.Component<CreateRoleProps, CreateRoleState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            error: null,

            application: null,
            groupedApplicationCapabilities: null,

            import_showDrawer: false,
            import_selectedRole: null,

            initialCapabilities: ["menu:all", "menu:dashboard"],
        }
    }

    componentDidMount = async () => {
        //var capabilities = await client.capabilities.getAll();
        this.getApplication(this.context.applicationId);
    }

    getApplication = async (applicationId: string) => {
        this.setState({ loading: true });

        const response = await client.applications
            .getApplicationById(applicationId)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) this.groupCapabilities(response.data.view);

        this.setState({ loading: false });
    }

    groupCapabilities = (application: ApplicationView) => {

        const groupedCapabilities = _.groupBy(application.capabilities?.app ?? [], c => c.group);

        const capabilityGroupView: CapabilityGroupView[] = _.map(groupedCapabilities, (capabilities, title) => {

            const groupedSubCapabilities = _.groupBy(capabilities, c => c.subGroup ?? "");

            return {
                name: title ?? null,
                hasRequirements: _.filter(capabilities, c => c.requires?.length > 0) != null,
                subGroups: _.map(groupedSubCapabilities, (subCapabilities, subKey) => {
                    return {
                        name: subKey ?? null,
                        hasRequirements: _.filter(subCapabilities, c => c.requires?.length > 0) != null,
                        capabilities: _.map(subCapabilities, capability => {
                            return {
                                id: capability.id,
                                name: capability.name,
                                description: capability.description,
                                includes: capability.includes,
                                requires: capability.requires
                            } as CapabilityView
                        })


                    } as CapabilitySubGroupView
                })
            } as CapabilityGroupView
        });

        this.setState({
            groupedApplicationCapabilities: capabilityGroupView,
            application
        });
    }

    clearPrompt = () => {
        window.dispatchEvent(new Event(RoleCreateFormPromptEvents.Clear));
    }

    markPrompt = () => {
        window.dispatchEvent(new Event(RoleCreateFormPromptEvents.Change));
    }

    onSubmit = async (request: CreateOrUpdateRole) => {
        this.setState({ loading: true });

        try {
            const role = await client.roles.createRole(request);

            this.setState({ loading: false });
            this.clearPrompt();

            if (role)
                this.props.onComplete(role.data);

        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    toggleImportPermissions = () => {
        this.setState({ import_showDrawer: !this.state.import_showDrawer });
    }

    onImportRoleChange = (importedRole: RoleView) => {
        this.setState({ import_selectedRole: importedRole });
    }

    onImportPermissions = () => {

        const existingCapabilities = this.state.initialCapabilities;
        const importedCapabilities = this.state.import_selectedRole?.capabilities ?? [];

        const newCapabilities = _.uniq([...existingCapabilities, ...importedCapabilities]);

        this.setState({
            initialCapabilities: newCapabilities,
            import_selectedRole: null,
            import_showDrawer: false
        });
        this.markPrompt();
    }

    onApplicationChange = (application: ApplicationView) => {
        this.groupCapabilities(application);
        this.markPrompt();
    }

    render = () => {
        const { application, groupedApplicationCapabilities } = this.state;

        const availableCapabilities: CapabilityGroupView[] = [];

        const appCapabilities = groupedApplicationCapabilities?.slice() ?? [];
        if (appCapabilities?.length > 0 && application?.id === this.context.applicationId) {

            const files = _.find(appCapabilities, g => g.name === "Documents (specific location)");
            if (files) {
                _.remove(appCapabilities, c => c.name === "Documents (specific location)");
                availableCapabilities.push(files);
            }

            const notes = _.find(appCapabilities, g => g.name === "Notes and comments");
            if (notes) {
                _.remove(appCapabilities, c => c.name === "Notes and comments");
                availableCapabilities.push(notes);
            }

            const menu = _.find(appCapabilities, g => g.name === "Menu");
            if (menu) {
                _.remove(appCapabilities, c => c.name === "Menu");
                availableCapabilities.push(menu);
            }
        }

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    capabilities: this.state.initialCapabilities,
                    applicationId: this.state.application?.id
                }}
            >
                <Drawer
                    title="Import permissions"
                    onClose={this.toggleImportPermissions}
                    open={this.state.import_showDrawer}
                    component={
                        <div className="import-permissions-drawer">
                            <RoleSelector title="Role" placeholder="Select role..." onObjectChange={this.onImportRoleChange} />
                            <Button onClick={this.onImportPermissions} disabled={!this.state.import_selectedRole}>Import</Button>
                        </div>
                    }
                />

                <div className="field-inputs">
                    <TextInput
                        param="name"
                        required
                        warningMessage="Please type a name"
                        placeholder="Name"
                        title="Name"
                        onObjectChange={(e) => { this.markPrompt(); }}
                    />

                    <SelectorInput
                        param="applicationId"
                        title="Where should the role apply?"
                        warningMessage="Please select application"
                        required
                        selector={
                            <ApplicationSelector
                                placeholder="Select application"
                                filters={{
                                    eligibleForRoles: true
                                } as PagedApplicationQuery}
                                onObjectChange={this.onApplicationChange}
                            />
                        }
                    />

                    <TextInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                        formItemClassName="role-desc"
                        onObjectChange={(e) => { this.markPrompt(); }}
                    />

                    <Button className="import-btn" onClick={this.toggleImportPermissions}>Import permissions</Button>
                </div>

                <CheckboxGroupInput
                    param="capabilities"
                    title={application && application.id == this.context.applicationId ? "Permissions from application" : "Permissions"}
                    onObjectChange={(e) => { this.markPrompt(); }}
                    value={this.state.initialCapabilities}
                    render={(selected) => {
                        return (<CapabilitiesEditor
                            selected={selected}
                            apiCapabilitites={availableCapabilities ?? []}
                            applicationCapabilities={appCapabilities}
                            applicationSpesific={application && application.id == this.context.applicationId}
                        />);
                    }
                    }
                />

                {/*<MyPrompt*/}
                {/*    changeEventKey={RoleCreateFormPromptEvents.Change}*/}
                {/*    clearEventKey={RoleCreateFormPromptEvents.Clear}*/}
                {/*/>*/}

            </BaseForm>
        );
    }
}

export default RoleCreateForm;