import * as React from "react";
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import AppContext from "../../Definitions/AppContext";
import type { ActionType } from '@rc-component/trigger/lib/interface';
interface EnumSelectorInfoProps {
    hoverText: string;
    displayText: string;
}

class SelectorInformation extends React.Component<EnumSelectorInfoProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render = () => {
        const triggers: ActionType[] = ["click"];
        if (!this.context.isMobile)
            triggers.push("hover");

        return (
            <React.Fragment>
                {this.props.displayText}
                {this.props.displayText ?
                    <Tooltip title={this.props.hoverText} trigger={triggers}>
                        <InfoCircleOutlined className="enum-information-icon" onClick={(e) => e.stopPropagation()} />
                    </Tooltip> : null}
            </React.Fragment>
        );
    }
}

export default SelectorInformation;