import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import AppContext from "../../Definitions/AppContext";
import { PagedIncidentQuery } from "../../ApiClient/swagger/data-contracts";
import { QuickSearch } from "../Shared/QuickSearch";

interface IncidentQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedIncidentQuery>
}

export class IncidentQuickSearch extends React.Component<IncidentQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <React.Fragment>
                <QuickSearch name="All"
                    queryParameters={{
                        start: null,
                        end: null,
                        closed: null,
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />
                <QuickSearch name="Open"
                    queryParameters={{
                        closed: false,
                        start: null,
                        end: null,
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="This year"
                    queryParameters={{
                        closed: null,
                        start: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                        end: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="This month"
                    queryParameters={{
                        closed: null,
                        start: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                        end: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Last year"
                    queryParameters={{
                        closed: null,
                        start: moment().subtract(1, "year").startOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                        end: moment().subtract(1, "year").endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />
            </React.Fragment>

        );
    }
}