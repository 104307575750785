import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import client from "../../ApiClient/client";
import AppContext from "../../Definitions/AppContext";
import { CreateIncident, IncidentView, TaggableType } from "../../ApiClient/swagger/data-contracts";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { OrganizationSelector } from "../Organizations";
import { ProductSelector } from "../Products";
import { PersonSelector } from "../People";
import { TagSelector } from "../Tags";
import { CategoryIds } from "../../Definitions/_definitions";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import IncidentCategorySelector from "./IncidentCategorySelector";
import { DownOutlined, UpOutlined, UploadOutlined } from "@ant-design/icons";
import { ResourceSelector } from "../Resources";
import { RoutineSelector } from "../Routines";
import { Capabilities } from "../../Definitions/_capabilties";
import { CustomerSelector } from "../Actors";
import { Button, Form, Skeleton, Upload, UploadFile } from "antd";
import { openNotification } from "../../Helpers/BasePageHelpers";
import { RcFile } from "antd/lib/upload";
import { incidentFileClient } from "../../ApiClient/EntityFileClient";

interface CreateIncidentProps {
    onComplete: (created?: IncidentView) => void;
    onCancel?: () => void;
    incidentRequest?: Partial<CreateIncident>;
}

interface CreateIncidentState {
    loading: boolean;
    error: string;
    displayMoreInfo: boolean;
    pictureChanged: boolean;
    loadingAvatar: boolean;
    picture: UploadFile[];
}

class IncidentCreateForm extends React.Component<CreateIncidentProps, CreateIncidentState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            displayMoreInfo: this.props.incidentRequest != null && (this.props.incidentRequest.expectedClosing != null
                || this.props.incidentRequest.customers?.length > 0 || this.props.incidentRequest.otherParticipants?.length > 0
                || this.props.incidentRequest.products?.length > 0 || this.props.incidentRequest.tagIds?.length > 0
                || this.props.incidentRequest.routineId != null) || this.props.incidentRequest?.resources?.length > 0,
            pictureChanged: false,
            loadingAvatar: false,
            picture: [],
        }
    }

    onSubmit = async (request: any) => {
        this.setState({ loading: true });

        if (request.tagIds && request.causes) {
            request.tagIds = request.tagIds.concat(request.causes);
            delete request.causes;
        }
        else if (request.causes && request.causes.length > 0) {
            request.tagIds = request.causes;
            delete request.causes;
        }
        try {
            const incident = await client.incidents.createIncident(request);
            if (incident) {
                if (this.state?.picture && this.state?.pictureChanged) {
                    try {
                        await incidentFileClient.createFile(incident.data.id, {
                            file: this.state.picture[0] as RcFile,
                            name: this.state.picture[0].name,
                            "parent.collection": "indcidents",
                            "parent.id": incident.data.id,
                        });
                    } catch (error) {
                        openNotification(
                            "Error adding picture to resource",
                            "An error occured while trying to add the picture.",
                            "error"
                        );
                    }
                }
                this.props.onComplete(incident.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    removePicture = () => {
        this.setState({ picture: null });
    };

    render = () => {
        // init values??

        const expectedClosingDateInput = (
            <SelectorInput
                title="Expected closing date"
                param="expectedClosing"
                selector={<DateSelector placeholder="Date" endOfDay />}
            />
        );

        const customersInput = (
            <SelectorInput
                title="Customers"
                param="customers"
                optional
                selector={
                    <CustomerSelector
                        placeholder="Select"
                        multiple
                    />
                }
            />
        );

        const resourcesInput = (
            <SelectorInput
                title="Resources"
                param="resources"
                optional
                selector={
                    <ResourceSelector
                        placeholder="Select"
                        multiple
                        disabled={this.props.incidentRequest?.resources ? true : false}
                    />
                }
            />
        );

        const otherParticipantsInput = (
            <SelectorInput
                title="Other participants"
                param="otherParticipants"
                optional
                selector={
                    <PersonSelector
                        placeholder="Select"
                        multiple
                        filters={{
                            isEmployee: true
                        }}
                    />
                }
            />
        );

        const productsInput = (
            <SelectorInput
                param="products"
                title="Products"
                optional
                shouldUpdate
                className="product-override"
                selector={
                    <ProductSelector
                        multiple
                        placeholder="Select"
                    /*filters={{ units: [ProductUnit.Hour] }}*/
                    /*disabled={disabledInputs}
                    className={disabledInputs ? "disabled-input" : ""}
                    onObjectChange={this.onProductChange}*/
                    />
                }
            />
        );

        const tagsInput = (
            <SelectorInput
                title="Tags"
                param="tagIds"
                selector={<TagSelector multiple filters={{ excludedCategories: [CategoryIds.IncidentCauses], taggableTypes: [TaggableType.Incident] }} />}
            />
        );

        const routineInput = (
            <SelectorInput
                title="Routine"
                param="routineId"
                optional
                selector={<RoutineSelector />}
            />
        );

        const moreInfoContent = (
            <React.Fragment>
                {expectedClosingDateInput}
                {customersInput}
                {resourcesInput}
                {otherParticipantsInput}
                {productsInput}
                {routineInput}
                {tagsInput}
            </React.Fragment>
        );

        const initialValues = this.props.incidentRequest ?? { ownerId: this.context.user.actorId };

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                loading={this.state.loading}
                error={this.state.error}
                className="incident-edit-or-create-form"
                onCancel={this.props.onCancel}
                initialValues={initialValues}
            >
                <TextInput
                    title="Title"
                    param="title"
                    required
                    warningMessage="Please type a title"
                    placeholder="Title"
                />
                <TextAreaInput
                    title="Description"
                    param="description"
                    placeholder="Write here"
                />

                <Form.Item label={<div>Upload picture (optional)</div>}>
                    <div>
                        <Upload
                            onChange={() => this.setState({ pictureChanged: true })}
                            beforeUpload={(file) => {
                                const isImg = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif';
                                if (!isImg) {
                                    return Upload.LIST_IGNORE;
                                }
                                this.setState({ picture: [file] })
                                return false;
                            }}
                            onRemove={this.removePicture}
                            maxCount={1}
                            listType="picture"
                            defaultFileList={this.state.picture}
                            className="actor-form-picture-upload"
                        >
                            {this.state.loadingAvatar ? (
                                <Skeleton.Button active />
                            ) : (
                                <Button
                                    className="actor-picture-btn"
                                    icon={<UploadOutlined />}
                                >
                                    {this.context.isMobile
                                        ? "Add"
                                        : "Add picture"}
                                </Button>
                            )}
                        </Upload>
                    </div>
                </Form.Item>

                <SelectorInput
                    title="Type"
                    param="categoryId"
                    required
                    selector={
                        <IncidentCategorySelector
                            placeholder="Select type"
                        />
                    }
                />
                <SelectorInput
                    title="Occured date"
                    param="occuredDate"
                    selector={<DateSelector placeholder="Date" endOfDay />}
                    className="include-selector-info-text"
                />
                <div className="selector-info-text">If you don't set a date, this will be the same as the registration date.</div>

                <SelectorInput
                    title="Causes"
                    param="causes"
                    selector={<TagSelector placeholder="Select causes..." multiple filters={{ categoryId: CategoryIds.IncidentCauses }} />}
                />
                <SelectorInput
                    title="Owner"
                    param="ownerId"
                    required
                    selector={
                        <PersonSelector
                            placeholder="Select person"
                            filters={{
                                isEmployee: true
                            }}
                            disabled={!this.context.user.hasCapability(Capabilities.IncidentsWrite)}
                        />
                    }
                />

                <React.Fragment>
                    <div className={`ant-form-item-label add-more-info-header ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}>
                        <div className="more-info-label">
                            <label>
                                Additional info (optional)
                                <div className="more-info-icon-container">{this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}
                                </div>
                            </label>
                        </div>
                    </div>

                    {this.state.displayMoreInfo ? <div className="more-info-content">{moreInfoContent}</div> : null}
                </React.Fragment>

            </BaseForm >
        );
    }
}

export default IncidentCreateForm;