import * as React from "react";
import client from "../../ApiClient/client";
import { PagedProductQuery } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";


export class ProductSelector extends React.Component<GenericEntitySelectorProps<PagedProductQuery>> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : "Select product...";
        const source = this.props.source ? this.props.source : client.products.queryProducts;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.products.getProductById;

        return (
            <EntitySelector
                className="product-selector"
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                {...this.props}
            />
        );
    }
}

export default ProductSelector;

