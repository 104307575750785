import _ from "lodash";
import moment from "moment";
import { formatDuration } from "../../Helpers/Formatters";
import client from "../client";
import { CallMissedReason, CallStatus, CallView, PagedCallQuery } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig } from "./BaseExportService";

export class CallExport extends BaseExportService<PagedCallQuery, CallView> {

    constructor() {
        const specification: ExportConfig<CallView>[] = [
            { header: 'Time', value: (data: CallView) => data.start ? moment(data.start).format() : null },
            { header: 'Start', value: (data: CallView) => data.start ? moment(data.start).format("HH:mm") : null },
            { header: 'End', value: (data: CallView) => data.end ? moment(data.end).format("HH:mm") : null },
            {
                header: 'Status', value: (data: CallView) => {
                    const callStatusText = data.status == CallStatus.Connected ? 'Active' : data.status;

                    return data.status == CallStatus.Missed && data.missedReason && data.missedReason != CallMissedReason.Unknown
                        ? `${callStatusText}: ${data.missedReason}`
                        : callStatusText;
                }
            },
            { header: 'Duration', value: (data: CallView) => data.duration ? formatDuration(data.duration) : null },
            {
                header: 'From', value: (data: CallView) => {
                    let fromText = "";
                    if (data.from?.contact.name) fromText = fromText + data.from.contact.name
                    if (data.from?.contact.name && data.from.value) {
                        fromText = fromText + " (" + data.from.value + ")";
                    } else {
                        fromText = fromText + data.from.value;
                    }
                    return fromText;
                }
            },
            {
                header: 'To', value: (data: CallView) => {
                    let toText = "";
                    if (data.to?.contact.name) toText = toText + data.to.contact.name
                    if (data.to?.contact.name && data.to.value) {
                        toText = toText + " (" + data.to.value + ")";
                    } else {
                        toText = toText + data.to.value;
                    }
                    return toText;
                }
            },
            {
                header: 'Organization', value: (data: CallView) => {                   
                   
                    let actors = "";
                    _.each(data.organizations, (key, value) => {
                        actors = actors + key.name;
                    })

                    return actors;
                }
            },
            { header: 'Note', value: (data: CallView) => data.note ?? null },
            {
                header: 'Attributes', value: (data: CallView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })

                    }

                    return dataString
                }
            },
            { header: 'Deleted', value: (data: CallView) => data.deleted ? true : false }
        ];
        super(specification, client.calls.queryCalls);
    }
}