export interface IEventTransformer {
    subscribe: (eventType: string, handler: (eventData?: unknown) => void) => void;
    unsubscribe: (eventType: string, handler: (eventData?: unknown) => void) => void;
    eventSources: CustomEventSource[];
}

export interface ScopeStateData {
    scope: string;
    state: string;
}

class CustomEventSource extends EventSource {
    ignoreNextClose?: boolean;
}

export class EventTransformer implements IEventTransformer {

    private listeners: Map<string, Set<(eventData?: unknown) => void>>;
    eventSources: CustomEventSource[];


    constructor(scopes: Record<string, string>) {
        this.listeners = new Map();
        this.eventSources = [];

        Object.entries(scopes ?? {}).forEach(([key, url]) => {
            this.setup(key, url);
        });
    }

    private transformer = (event: MessageEvent) => {
        if (!event.data) return undefined;

        const data = event.data;

        try {
            return JSON.parse(data);
        } catch (error) {
            return data;
        }
    }

    private setup = (key: string, url: string) => {
        const eventSource = new CustomEventSource(url);

        eventSource.onopen = () => {
            console.log('EventStream: Open', key);
        };

        eventSource.addEventListener("state", (e) => {
            if (console && console.log && e.data) console.log('EventStream: State', key, e.data);

            eventSource.dispatchEvent(new MessageEvent("scope-state", {
                data: JSON.stringify({
                    scope: key,
                    state: e.data
                })
            }));
        })

        eventSource.addEventListener("warning", (e) => {
            if (e.data) console.warn('EventStream: Warning', key, e.data);
        })

        eventSource.onerror = (e: Event) => {
            if (e instanceof MessageEvent && e.data) {
                console.error('EventStream: Error', key, e.data);

                if (e.data) {
                    if (e.data === "bad-authentication") {

                        eventSource.dispatchEvent(new MessageEvent("bad-authentication", {
                            data: JSON.stringify({
                                scope: key,
                                reason: e.data
                            })
                        }));
                    }

                    if (e.data === "bad-request") {

                        eventSource.dispatchEvent(new MessageEvent("refresh", {
                            data: JSON.stringify({
                                scope: key,
                                reason: e.data
                            })
                        }));

                        eventSource.close();
                    }
                    if (e.data === "subscription-expired") {
                        eventSource.close();
                        this.setup(key, eventSource.url);
                        return;
                    }
                }
                else {

                    if (e.eventPhase === CustomEventSource.CLOSED) {
                        if (console && console.log) console.log('EventStream: Closed', key);

                        if (eventSource.ignoreNextClose) {
                            eventSource.ignoreNextClose = false;
                            return;
                        }

                        eventSource.dispatchEvent(new MessageEvent("closed", {
                            data: key
                        }));
                    }
                }
            }
        }

        this.eventSources.push(eventSource);
    };

    subscribe = (eventType: string, handler: (eventData?: unknown) => void): void => {
        let typeListeners = this.listeners.get(eventType);
        if (!typeListeners) {
            typeListeners = new Set();
            this.listeners.set(eventType, typeListeners);
        }

        if (!typeListeners.has(handler)) {
            const listener = (event: MessageEvent) => handler(this.transformer(event));

            typeListeners.add(handler);

            this.eventSources.forEach(eventSource => {
                eventSource.addEventListener(eventType, listener as EventListener, false);
            });
        }
    }

    unsubscribe = (eventType: string, handler: (eventData?: unknown) => void): void => {
        const typeListeners = this.listeners.get(eventType);
        if (!typeListeners) return;

        typeListeners.forEach(listener => {
            if (listener === handler) {
                typeListeners.delete(listener);

                this.eventSources.forEach(eventSource => {
                    eventSource.removeEventListener(eventType, listener as EventListener, false);
                });
            }
        });

        if (typeListeners.size === 0) {
            this.listeners.delete(eventType);
        }
    }
}

export default EventTransformer;