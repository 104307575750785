import * as React from "react";
import _ from 'lodash';
import { RadioChangeEvent } from "antd";
import { ActorView, Address, AddressCollectionItem, CreateOrder, CreateOrderline, OrderDateType, OrderlineView, OrderType, OrderView, OrganizationView, PersonSortOption, PersonView, ProductPart, ProductPartView, ProductView, ProjectSortOption, TaggableType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { ActorIds } from "../../Definitions/_definitions";
import { createGuid } from "../../Helpers/BasePageHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import OrderTypeSelector from "./OrderTypeSelector";
import { TextInput } from "../Shared/TextInput";
import { ProjectSelector } from "../Projects";
import OrderCategorySelector from "./OrderCategorySelector";
import { TagSelector } from "../Tags";
import { CustomerSelector } from "../Actors";
import { PersonSelector } from "../People";
import OrderDateSelector from "./OrderDateSelector";
import OrderAddressSelector from "./OrderAddressSelector";
import OrderlineList from "./OrderlineList";
import OrderSalesTypeSelector from "./OrderSalesTypeSelector";


interface OrderFormProps {
    onComplete: (created?: OrderView) => void,
    onCancel?: () => void,
    actorId?: string,
}

interface OrderFormState {
    loading: boolean;
    error: string;
    type: OrderType;
    isSalesOrder: boolean;
    orderlines: Partial<OrderlineView>[];
    customer: OrganizationView | PersonView;
    invoiceCustomer: OrganizationView | PersonView;
    deliveryAddress: Partial<AddressCollectionItem>;
    deliveryStatus: string;
    invoiceAddress: Partial<Address>;
    invoiceStatus: string;
    invoiceRecipentStatus: string;
}

class OrderForm extends React.Component<OrderFormProps, OrderFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            type: null,
            isSalesOrder: false,
            orderlines: [],
            customer: null,
            invoiceCustomer: null,
            deliveryAddress: null,
            deliveryStatus: null,
            invoiceAddress: null,
            invoiceStatus: null,
            invoiceRecipentStatus: null
        }
    }

    componentDidMount = () => {
        if (this.props.actorId)
            this.loadCustomer();
    }

    loadCustomer = async () => {
        this.setState({ loading: true });

        const response = await client.actors.getActorById(this.props.actorId).catch(exception => this.setState({ error: exception.error.title }));
        if (response) {
            const customer = response.data.view;
            await this.onCustomerChange(response.data.view);
        }

        this.setState({ loading: false });
    }

    onSubmit = async (request: any) => {
        this.setState({ loading: true });

        const dates: any = {};

        if (request[OrderDateType.Quotation])
            dates[OrderDateType.Quotation] = request[OrderDateType.Quotation];

        if (request[OrderDateType.QuotationExpiry])
            dates[OrderDateType.QuotationExpiry] = request[OrderDateType.QuotationExpiry];

        if (request[OrderDateType.Sales])
            dates[OrderDateType.Sales] = request[OrderDateType.Sales];

        if (request[OrderDateType.SalesCorrection])
            dates[OrderDateType.SalesCorrection] = request[OrderDateType.SalesCorrection];

        if (request[OrderDateType.SalesProduction])
            dates[OrderDateType.SalesProduction] = request[OrderDateType.SalesProduction];

        if (request[OrderDateType.RequestedDelivery])
            dates[OrderDateType.RequestedDelivery] = request[OrderDateType.RequestedDelivery];

        const orderlines = this.state.orderlines ? _.map(this.state.orderlines, (orderline) => {
            const request: CreateOrderline = {
                productId: orderline.product.id,
                costPrice: orderline.costPrice,
                description: orderline.description,
                discount: orderline.discount,
                quantity: orderline.quantity,
                reference: orderline.reference,
                salesPrice: orderline.salesPrice,
                unit: orderline.unit,
                parts: _.map(orderline.parts, p => {
                    const partRequest: ProductPart = {
                        productId: p.product.id,
                        quantity: p.quantity
                    }
                    return partRequest;
                })
            };
            return request;
        }) : null;

        let deliveryAddress: Address = null;
        let invoiceAddress: Address = null;

        if (request.deliveryStatus == "new" && request.deliveryAddress) {
            deliveryAddress = {
                name: request.deliveryAddress.name,
                line1: request.deliveryAddress.line1,
                line2: request.deliveryAddress.line2,
                zipCode: request.deliveryAddress.zipCode,
                area: request.deliveryAddress.area,
                country: request.deliveryAddress.country,
                coordinates: request.deliveryAddress.coordinates,
                note: request.deliveryAddress.note,
            }
        }
        else if (request.deliveryStatus == "existing") {
            var addressCollectionItem: AddressCollectionItem = null

            _.map(this.state.customer.addresses, address => {
                if (address.addressId == request.deliveryAddress) {
                    addressCollectionItem = address
                }
            })

            deliveryAddress = {
                name: addressCollectionItem.name,
                line1: addressCollectionItem.line1,
                line2: addressCollectionItem.line2,
                zipCode: addressCollectionItem.zipCode,
                area: addressCollectionItem.area,
                country: addressCollectionItem.country,
                coordinates: addressCollectionItem.coordinates,
                note: addressCollectionItem.note,
            }
        }

        if (request.invoiceStatus == "same") {
            invoiceAddress = deliveryAddress;
        }
        else if (request.invoiceStatus == "new" && request.invoiceAddress) {
            invoiceAddress = {
                name: request.invoiceAddress.name,
                line1: request.invoiceAddress.line1,
                line2: request.invoiceAddress.line2,
                zipCode: request.invoiceAddress.zipCode,
                area: request.invoiceAddress.area,
                country: request.invoiceAddress.country,
                coordinates: request.invoiceAddress.coordinates,
                note: request.invoiceAddress.note,
            }
        }

        else if (request.invoiceStatus == "existing") {
            var addressCollectionItem: AddressCollectionItem = null

            if (request.invoiceRecipentStatus == "same") {

                _.map(this.state.customer.addresses, address => {
                    if (address.addressId == request.invoiceAddress) {
                        addressCollectionItem = address
                    }
                })
            }
            else if (request.invoiceRecipentStatus == "another") {
                _.map(this.state.invoiceCustomer.addresses, address => {
                    if (address.addressId == request.invoiceAddress) {
                        addressCollectionItem = address
                    }
                })
            }

            if (addressCollectionItem != null) {
                const newAddress: Address = {
                    name: addressCollectionItem.name,
                    line1: addressCollectionItem.line1,
                    line2: addressCollectionItem.line2,
                    zipCode: addressCollectionItem.zipCode,
                    area: addressCollectionItem.area,
                    country: addressCollectionItem.country,
                    coordinates: addressCollectionItem.coordinates,
                    note: addressCollectionItem.note,
                }

                invoiceAddress = newAddress;
            } else {
                invoiceAddress = {
                    name: request.invoiceAddress.name,
                    line1: request.invoiceAddress.line1,
                    line2: request.invoiceAddress.line2,
                    zipCode: request.invoiceAddress.zipCode,
                    area: request.invoiceAddress.area,
                    country: request.invoiceAddress.country,
                    coordinates: request.invoiceAddress.coordinates,
                    note: request.invoiceAddress.note,
                }
            }
        }

        if (request.invoiceRecipentStatus == "same") {
            request.invoiceRecipient = request.customerId;
        }

        const command: CreateOrder = {
            reference: request.reference,
            type: this.state.type,
            label: request.label,
            customerId: request.customerId,
            invoiceReceiverId: request.invoiceRecipient,
            externalContactId: request.externalContactId,
            internalContactId: request.internalContactId,
            dates: dates,
            deliveryAddress: deliveryAddress,
            invoiceAddress: invoiceAddress,
            projectId: request.projectId,
            categoryId: request.categoryId,
            tagIds: request.tagIds,
            orderlines: orderlines,
        };

        const response = await client.orders.createOrder(command).catch(exception => this.setState({ error: exception.error.title }));
        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    onTypeChange = (type) => {
        if (type == "Sales") {
            this.setState({ isSalesOrder: true, type: null });
        }
        else {
            if (type === OrderType.Production || type === OrderType.Quotation) {
                this.setState({ isSalesOrder: false});
            }

            this.setState({ type: type });
        }
    }

    mapOrderline = (current: Partial<OrderlineView>, update: Partial<CreateOrderline>, product: ProductView) => {

        const fieldsToUpdate: Partial<OrderlineView> = {
            product: product,
            costPrice: update.costPrice,
            description: update.description,
            discount: update.discount,
            quantity: update.quantity,
            reference: update.reference,
            salesPrice: update.salesPrice,
            unit: update.unit,
            parts: product.parts,
            attributes: {}
        };

        return Object.assign({}, current, fieldsToUpdate);
    }

    onOrderlineCreated = async (request: Partial<CreateOrderline>, product: ProductView) => {
        this.setState({ loading: true });
        try {
            const orderline = this.mapOrderline({ id: createGuid() + "-created" }, request, product);
            const orderlines = this.state.orderlines.slice();
            orderlines.push(orderline);
            this.setState({ orderlines });
        } catch (error: any) {
            this.setState({ error: error.message });
        }
        this.setState({ loading: false });
    }

    onOrderlineEdited = async (orderline: Partial<OrderlineView>, request: Partial<CreateOrderline>, product: ProductView) => {
        this.setState({ loading: true });

        try {
            const orderlines = this.state.orderlines.slice();
            const index = _.indexOf(orderlines, _.find(orderlines, o => { return o.id == orderline.id; }));

            if (index != -1) {
                const existing = orderlines[index];
                const updated = this.mapOrderline(existing, request, product);
                orderlines[index] = updated;
                this.setState({ orderlines });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onOrderlineRemoved = async (orderline: Partial<OrderlineView>) => {
        this.setState({ loading: true });

        try {
            const orderlines = this.state.orderlines.slice();
            const index = orderlines.indexOf(orderline);
            if (index != -1) {
                orderlines.splice(index, 1);
                this.setState({ orderlines });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onPartAdded = (part: ProductPartView, orderlineId: string) => {
        const orderlines = this.state.orderlines.slice();
        const orderline = _.find(orderlines, o => { return o.id == orderlineId; });
        if (orderline != null) {
            const exists = _.find(orderline.parts || [], p => { return p.product.id == part.product.id; });
            const orderlineIndex = orderlines.indexOf(orderline);
            if (!exists) {
                orderline.parts.push(part);
            }
            else {
                const partIndex = orderline.parts.indexOf(exists);
                orderline.parts[partIndex] = part;
            }

            orderlines[orderlineIndex] = orderline;
            this.setState({ orderlines });
        }
    }

    onPartRemoved = (part: ProductPartView, orderlineId) => {
        try {
            const orderlines = this.state.orderlines.slice();
            const orderline = _.find(orderlines, o => { return o.id == orderlineId; });
            const orderlineIndex = orderlines.indexOf(orderline);

            if (orderline != null) {
                var part = _.find(orderline.parts, p => { return p.product.id == part.product.id; });
                const partIndex = orderline.parts.indexOf(part);
                if (partIndex != -1) {
                    orderline.parts.splice(partIndex, 1);
                    orderlines[orderlineIndex] = orderline;
                    this.setState({ orderlines });
                }
            }
        } catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onCustomerChange = (customer: ActorView) => {
        let deliveryAddress = null;

        if (customer) {
            if (customer.addresses && customer.addresses?.length > 0) {
                deliveryAddress = customer.addresses[0].addressId;
            }
        }

        let deliveryStatus = "new";
        if (customer && customer.addresses?.length > 0) {
            deliveryStatus = "existing"
        }

        this.setState({
            customer: customer,
            deliveryAddress: deliveryAddress,
            deliveryStatus: deliveryStatus,
        });
    }

    onInvoiceRecipientChange = (customer: OrganizationView | PersonView) => {
        let invoiceStatus = null;
        let invoiceAddress = null;

        if (customer) {
            let anotherAndAddress = null;
            if (this.state.invoiceRecipentStatus == "another") {
                if (customer.addresses && customer.addresses?.length > 0) {
                    anotherAndAddress = true;
                } else if (!customer.addresses || customer.addresses?.length <= 0) {
                    anotherAndAddress = false;
                }
            }

            if (this.state.deliveryStatus == "skip") {
                if (anotherAndAddress == true) {
                    invoiceStatus = "existing";
                } else if (anotherAndAddress == false) {
                    invoiceStatus = "new";
                }
            }

            if (customer.addresses && customer.addresses?.length > 0) {
                invoiceAddress = customer.addresses[0].addressId;
            }
        }

        this.setState({
            invoiceCustomer: customer,
            invoiceAddress: invoiceAddress,
            invoiceStatus: invoiceStatus,
        });
    }

    onDeliveryStatusChange = (event: RadioChangeEvent) => {
        this.setState({
            deliveryStatus: event.target.value,
        });
    }

    onInvoiceStatusChange = (event: RadioChangeEvent) => {
        this.setState({ invoiceStatus: event.target.value });
    }

    onInvoiceRecipentStatusChange = (event: RadioChangeEvent) => {
        if (event.target.value == "same") {
            this.setState({ invoiceCustomer: this.state.customer });
        }

        let invoiceStatus = null;
        let sameAndAddress = null;

        if (event.target.value == "same") {
            if (this.state.customer) {
                if (this.state.customer.addresses && this.state.customer.addresses.length > 0) {
                    sameAndAddress = true;
                } else if (!this.state.customer.addresses || this.state.customer.addresses.length <= 0) {
                    sameAndAddress = false;
                }
            }
        }

        if (this.state.deliveryStatus == "skip") {
            if (sameAndAddress == true) {
                invoiceStatus = "existing";
            } else if (sameAndAddress == false) {
                invoiceStatus = "new";
            }
        }

        this.setState({
            invoiceRecipentStatus: event.target.value,
            invoiceStatus: invoiceStatus,
            invoiceCustomer: event.target.value == "same" ? null : this.state.invoiceCustomer,
            invoiceAddress: null,
        });
    }

    onClear = () => {
        this.setState({
            type: null,
            orderlines: [],
            customer: null,
            invoiceCustomer: null,
            deliveryAddress: null,
            deliveryStatus: null,
            invoiceAddress: null,
            invoiceStatus: null,
            invoiceRecipentStatus: null,
        });
    }

    render = () => {
        const initialValues: Partial<any> = {};
        if (this.context.user.actorId != ActorIds.System)
            initialValues.internalContactId = this.context.user.actorId;


        initialValues.customerId = this.state.customer?.id ?? null;
        initialValues.deliveryStatus = this.state.deliveryStatus;
        initialValues.deliveryAddress = this.state.deliveryAddress;
        initialValues.invoiceStatus = this.state.invoiceStatus;
        initialValues.invoiceAddress = this.state.invoiceAddress;

        return (<>{this.state.loading ? null :
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                loading={this.state.loading}
                error={this.state.error}
                onCancel={this.props.onCancel}
                includeClearButton
                initialValues={initialValues}
                onClear={this.onClear}
            >
                <SelectorInput
                    key="type"
                    param="type"
                    title="Type of order"
                    required
                    selector={
                        <OrderTypeSelector
                            type="radio"
                            onChange={this.onTypeChange}
                        />
                    }
                />
                {this.state.isSalesOrder == false? null
                    :
                    <SelectorInput
                        key="saleType"
                        param="salesType"
                        title="Type of sales order"

                        required
                        selector={
                            <OrderSalesTypeSelector
                                type="radio"
                                onChange={this.onTypeChange}
                            />
                        }
                    />
                }
               

                {this.state.type == null ? null
                    :
                    <SelectorInput
                        key="customer"
                        param="customerId"
                        title="Customer"
                        required={this.state.type !=  OrderType.Production}
                        warningMessage="Please select a customer"
                        selector={<CustomerSelector placeholder="Select customer" onObjectChange={this.onCustomerChange} />}
                    />
                }

                {(this.state.type != OrderType.Production) && (this.state.type == null || this.state.customer == null) ? null
                    :
                    <>
                        {this.state.customer == null ? null
                            :
                            <OrderAddressSelector
                                type={this.state.type}
                                customer={this.state.customer}
                                invoiceCustomer={this.state.invoiceCustomer}
                                onDeliveryStatusChange={this.onDeliveryStatusChange}
                                onInvoiceStatusChange={this.onInvoiceStatusChange}
                                onInvoiceRecipientChange={this.onInvoiceRecipientChange}
                                onInvoiceRecipentStatusChange={this.onInvoiceRecipentStatusChange}
                                deliveryStatus={this.state.deliveryStatus}
                                invoiceStatus={this.state.invoiceStatus}
                                invoiceRecipentStatus={this.state.invoiceRecipentStatus}
                                onDeliveryAddressChange={(delAdr) => { this.setState({ deliveryAddress: delAdr }) }}
                            />
                        }
                        

                        <SelectorInput
                            param="internalContactId"
                            title="Internal contact"
                            required
                            selector={<PersonSelector placeholder="Select person" filters={{ isEmployee: true, sortBy: PersonSortOption.Name }} />}
                        />

                        <SelectorInput
                            param="externalContactId"
                            title="External contact"
                            selector={<PersonSelector placeholder="Select person" filters={{ sortBy: PersonSortOption.Name }} />}
                        />

                        <TextInput
                            param="label"
                            placeholder="Label..."
                            title="Label"
                        />
                        {this.state.customer == null ? null
                            :
                            <SelectorInput
                                param="projectId"
                                title="Project"
                                selector={<ProjectSelector filters={{ customerId: this.state.customer.id, deleted: false, sortBy: ProjectSortOption.Name }} />}
                            />
                        }
                        <SelectorInput
                            param="categoryId"
                            title="Category"
                            selector={<OrderCategorySelector />}
                        />

                        <SelectorInput
                            param="tagIds"
                            title="Tags"
                            selector={<TagSelector filters={{ taggableTypes: [TaggableType.Order] }} multiple />}
                        />

                        <OrderDateSelector type={this.state.type} />

                        <OrderlineList
                            onCreateCompleted={this.onOrderlineCreated}
                            onEditCompleted={this.onOrderlineEdited}
                            onRemoveCompleted={this.onOrderlineRemoved}
                            orderlines={this.state.orderlines}
                            showCompletions={false}
                            onAddPart={this.onPartAdded}
                            onRemovePart={this.onPartRemoved}
                            minimized={true}
                            optional
                            orderFormSubTitle
                            orderType={this.state.type}
                        />
                    </>
                }
            </BaseForm>
        }</>
        );
    }
}

export default OrderForm;