import * as React from "react";
import _ from 'lodash';
import { IncidentDateType, IncidentView, TaggableType, UpdateIncident } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { CategoryIds } from "../../Definitions/_definitions";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { PersonSelector } from "../People";
import { ProductSelector } from "../Products";
import { TagSelector } from "../Tags";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import IncidentCategorySelector from "./IncidentCategorySelector";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ResourceSelector } from "../Resources";
import { RoutineSelector } from "../Routines";
import { CustomerSelector } from "../Actors";

interface EditIncidentProps {
    incident: IncidentView,
    onComplete: (response: IncidentView) => void,
    onCancel?: () => void
}

interface EditIncidentState {
    loading: boolean,
    error: string,
    displayMoreInfo: boolean,
    hasClosingDateOnRender: boolean,
    hasCustomerOnRender: boolean,
    hasResourceOnRender: boolean,
    hasOtherParticipantsOnRender: boolean,
    hasProductsOnRender: boolean,
    hasRoutineOnRender: boolean,
    hasTagsOnRender: boolean,
}

export class IncidentEditForm extends React.Component<EditIncidentProps, EditIncidentState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            displayMoreInfo: false,
            hasClosingDateOnRender: this.props.incident?.dates["expectedClose"] != null,
            hasCustomerOnRender: this.props.incident?.customers != null && this.props.incident?.customers.length > 0,
            hasResourceOnRender: this.props.incident?.resources != null && this.props.incident?.resources.length > 0,
            hasOtherParticipantsOnRender: this.props.incident?.otherParticipants != null && this.props.incident?.otherParticipants.length > 0,
            hasProductsOnRender: this.props.incident?.products != null && this.props.incident?.products.length > 0,
            hasRoutineOnRender: this.props.incident?.routine != null,
            hasTagsOnRender: this.props.incident?.tags != null && this.props.incident?.tags.length > 0 && this.props.incident?.tags.find(x => x.category.id != CategoryIds.IncidentCauses) != null
        }
    }

    onSubmit = async (request: any) => {
        this.setState({ loading: true });

        if (request.tagIds && request.causes) {
            request.tagIds = request.tagIds.concat(request.causes);
            delete request.causes;
        }
        else if (request.causes && request.causes.length > 0) {
            request.tagIds = request.causes;
            delete request.causes;
        }

        try {
            const response = await client.incidents.updateIncident(this.props.incident.id, request);
            if (response) this.props.onComplete(response.data);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.incident != null) {
            try {
                const response = await client.incidents.deleteIncident(this.props.incident.id);
                if (response) this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onRestore = async () => {
        if (this.props.incident != null) {
            try {
                const response = await client.incidents.restoreIncident(this.props.incident.id);
                if (response) this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    render = () => {
        if (!this.props.incident) return <div>No incident</div>;

        const { incident } = this.props;
        const incidentCauses = _.filter(incident.tags || [], t => t.category?.id == CategoryIds.IncidentCauses);
        const tags = _.filter(incident.tags || [], t => t.category?.id != CategoryIds.IncidentCauses);

        const expectedClosingDateInput = (
            <SelectorInput
                title="Expected closing date"
                param="expectedClosing"
                selector={<DateSelector placeholder="Date" endOfDay />}
            />
        );

        const customersInput = (
            <SelectorInput
                title="Customers"
                param="customers"
                optional
                selector={
                    <CustomerSelector
                        multiple
                        placeholder="Select"
                    />
                }
            />
        );

        const resourcesInput = (
            <SelectorInput
                title="Resources"
                param="resources"
                optional
                selector={
                    <ResourceSelector
                        placeholder="Select"
                        multiple
                    />
                }
            />
        );

        const otherParticipantsInput = (
            <SelectorInput
                title="Other participants"
                param="otherParticipants"
                optional
                selector={
                    <PersonSelector
                        placeholder="Select"
                        multiple
                        filters={{
                            isEmployee: true
                        }}
                    />
                }
            />
        );

        const productsInput = (
            <SelectorInput
                param="products"
                title="Products"
                optional
                shouldUpdate
                className="product-override"
                selector={
                    <ProductSelector
                        multiple
                        placeholder="Select"
                    /*filters={{ units: [ProductUnit.Hour] }}*/
                    /*disabled={disabledInputs}
                    className={disabledInputs ? "disabled-input" : ""}
                    onObjectChange={this.onProductChange}*/
                    />
                }
            />
        );

        const routineInput = (
            <SelectorInput
                title="Routine"
                param="routineId"
                optional
                selector={<RoutineSelector />}
            />
        );

        const tagsInput = (
            <SelectorInput
                title="Tags"
                param="tagIds"
                selector={<TagSelector multiple filters={{ excludedCategories: [CategoryIds.IncidentCauses], taggableTypes: [TaggableType.Incident] }} />}
            />
        );

        const moreInfoContent = (
            <React.Fragment>
                {!this.state.hasClosingDateOnRender ? expectedClosingDateInput : null}
                {!this.state.hasCustomerOnRender ? customersInput : null}
                {!this.state.hasResourceOnRender ? resourcesInput : null}
                {!this.state.hasOtherParticipantsOnRender ? otherParticipantsInput : null}
                {!this.state.hasProductsOnRender ? productsInput : null}
                {!this.state.hasRoutineOnRender ? routineInput : null}
                {!this.state.hasTagsOnRender ? tagsInput : null}
            </React.Fragment>
        );

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                isDeleted={this.props.incident.deleted}
                loading={this.state.loading}
                className="incident-edit-or-create-form"
                error={this.state.error}
                initialValues={{
                    title: incident.title,
                    description: incident.description,
                    categoryId: incident.category?.id ?? "",
                    expectedClosing: incident.dates["expectedClose"] ?? null,
                    ownerId: incident.owner?.id ?? "",
                    causes: _.map(incidentCauses, tag => { if (tag.category.id == CategoryIds.IncidentCauses) return tag.id; }),
                    products: _.map(incident.products, product => product.id),
                    otherParticipants: _.map(incident.otherParticipants, participant => participant.id),
                    customers: _.map(incident.customers, customer => customer.id),
                    resources: _.map(incident.resources, resource => resource.id),
                    routineId: incident.routine?.id,
                    tagIds: _.map(tags, tag => { if (tag.category.id != CategoryIds.IncidentCauses && !tag.category?.deleted) return tag.id; }),
                    occuredDate: incident.dates["occured"] ?? null,
                } as Partial<UpdateIncident>}
            >
                <TextInput
                    title="Title"
                    param="title"
                    required
                    warningMessage="Please type a title"
                    placeholder="Title"
                />

                <TextAreaInput
                    title="Description"
                    param="description"
                    placeholder="Write here"
                />

                <SelectorInput
                    title="Type"
                    param="categoryId"
                    required
                    selector={
                        <IncidentCategorySelector
                            placeholder="Select type"
                        />
                    }
                />
                <SelectorInput
                    title="Occured date"
                    param="occuredDate"
                    selector={<DateSelector placeholder="Date" endOfDay />}
                    className="include-selector-info-text"
                />
                <div className="selector-info-text">If you don't set a date, this will be the same as the registration date.</div>

                <SelectorInput
                    title="Causes"
                    param="causes"
                    selector={<TagSelector placeholder="Select causes..." multiple filters={{ categoryId: CategoryIds.IncidentCauses }} />}
                />
                <SelectorInput
                    title="Owner"
                    param="ownerId"
                    required
                    selector={
                        <PersonSelector
                            placeholder="Select person"
                            filters={{
                                isEmployee: true
                            }}
                        />
                    }
                />

                {this.state.hasClosingDateOnRender ? expectedClosingDateInput : null}
                {this.state.hasCustomerOnRender ? customersInput : null}
                {this.state.hasResourceOnRender ? resourcesInput : null}
                {this.state.hasOtherParticipantsOnRender ? otherParticipantsInput : null}
                {this.state.hasProductsOnRender ? productsInput : null}
                {this.state.hasRoutineOnRender ? routineInput : null}
                {this.state.hasTagsOnRender ? tagsInput : null}

                <React.Fragment>
                    <div className={`ant-form-item-label add-more-info-header ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}><div className="more-info-label"><label>Additional info (optional) <div className="more-info-icon-container">{this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}</div></label></div></div>
                    {this.state.displayMoreInfo ? <div className="more-info-content">{moreInfoContent}</div> : null}
                </React.Fragment>
            </BaseForm>
        );
    }
}

export default IncidentEditForm;