/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreatePoll,
  PollSortOption,
  PollView,
  PollViewEntityViewModel,
  PollViewPagedPollQueryCollectionViewModel,
  SortDirection,
  UpdatePoll,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Polls<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Poll
   * @name GetPollById
   * @request GET:/api/polls/{id}
   * @secure
   */
  getPollById = (id: string, params: RequestParams = {}) =>
    this.http.request<PollViewEntityViewModel, any>({
      path: `/api/polls/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Poll
   * @name UpdatePoll
   * @request PUT:/api/polls/{id}
   * @secure
   */
  updatePoll = (id: string, data: UpdatePoll, params: RequestParams = {}) =>
    this.http.request<PollView, any>({
      path: `/api/polls/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Poll
   * @name DeletePoll
   * @request DELETE:/api/polls/{id}
   * @secure
   */
  deletePoll = (id: string, params: RequestParams = {}) =>
    this.http.request<PollView, any>({
      path: `/api/polls/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Poll
   * @name CreatePoll
   * @request POST:/api/polls
   * @secure
   */
  createPoll = (data: CreatePoll, params: RequestParams = {}) =>
    this.http.request<PollView, any>({
      path: `/api/polls`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Poll
   * @name QueryPolls
   * @request GET:/api/polls
   * @secure
   */
  queryPolls = (
    query?: {
      sortBy?: PollSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      inactive?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PollViewPagedPollQueryCollectionViewModel, any>({
      path: `/api/polls`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Poll
   * @name RestorePoll
   * @request POST:/api/polls/{id}/restore
   * @secure
   */
  restorePoll = (id: string, params: RequestParams = {}) =>
    this.http.request<PollView, any>({
      path: `/api/polls/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Poll
   * @name VotePoll
   * @request PUT:/api/polls/{id}/vote/{alternativeId}
   * @secure
   */
  votePoll = (id: string, alternativeId: string, params: RequestParams = {}) =>
    this.http.request<PollView, any>({
      path: `/api/polls/${id}/vote/${alternativeId}`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
}
