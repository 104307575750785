import * as React from "react";
import _ from 'lodash';
import { FolderFilled, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Breadcrumb, Alert } from "antd";
import { FolderLinkView, FolderView, MoveFileOrFolder } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { ListView } from "../Shared/ListView";
import { FolderIds } from "../../Definitions/_definitions";

interface FileMoveProps {
    type: "file" | "folder";
    fileOrFolderId: string;
    rootFolderId: string;
    onComplete: (moved) => void
}

interface MoveFileState {
    loading: boolean,
    error: string | null,
    folder: FolderView,
}

class FileMoveForm extends React.Component<FileMoveProps, MoveFileState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);
        this.state = {
            folder: null,
            loading: false,
            error: null,
        }
    }

    componentDidMount = async () => {
        this.loadFolder(this.props.rootFolderId);
    }

    loadFolder = async (folderId: string) => {
        this.setState({ loading: true, error: null });

        try {

            //TODO: FILES/Improve logic here?
            if (folderId == FolderIds.FakeDocumentFolderId) {
                const rootResponse = await client.folders.getRootFoldersAndFiles()
                if (rootResponse) {
                    const fakeFolder: FolderView =
                    {
                        id: folderId,
                        subFolders: rootResponse.data.subFolders,
                        name: "Documents"
                    };
                    this.setState({
                        folder: fakeFolder
                    });
                }
            } else {
                const response = await client.folders.getFolderById(folderId);
                if (response) {
                    this.setState({
                        folder: response.data.view
                    });
                }
            }           
            
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }


    onSelect = (selected) => {
        this.loadFolder(selected.id);
    }


    onBreadcrumbNavigation = async (folderId) => {
        if (this.state.folder.id != folderId) {
            this.loadFolder(folderId);
        }
    }

    onMoveFolder = async (e) => {
        if (!this.state.folder || !this.props.fileOrFolderId)
            return;

        const request: MoveFileOrFolder = {
            parent: this.state.folder.id == FolderIds.FakeDocumentFolderId ? null : this.state.folder.reference
        };

        try {
            let res;

            if (this.props.type == "folder")
                res = await client.folders.moveFolder(this.props.fileOrFolderId, request)
            else
                res = await client.files.moveFile(this.props.fileOrFolderId, request)
            
            this.props.onComplete(res);
        }
        catch (error: any) {
            this.setState({ error: error.message  });
        }
    }

    mapFolders = (folders: FolderLinkView[]) => {
        const mappedFolders = _.map(folders, f => {
            //@ts-ignore
            f.type = "folder";
            //@ts-ignore
            f.key = f.id;

            return f;
        });

        return mappedFolders;
    }

    render = () => {
        const listColumns = [
            {
                title: 'Name',
                render: (data: any) => {
                    if (data.type == "folder") {
                        return <React.Fragment><FolderFilled /><Button type="link"> {data.name}</Button></React.Fragment>;
                    }
                    else {
                        return "";
                    }
                },
                key: 'name'
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description'
            }
        ];

        const path = (this.state.folder && this.state.folder.id != FolderIds.FakeDocumentFolderId) ? [...(this.state.folder.path)] : [];

        path.unshift({ id: FolderIds.FakeDocumentFolderId, name: "Documents" });

        const breadcrumb = _.map(path, (p) => {
            return (
                <Breadcrumb.Item key={p.id}>
                    <Button onClick={() => this.onBreadcrumbNavigation(p.id)} type="link" size="small">{p.name}</Button>
                </Breadcrumb.Item>
            );
        });

        const data = this.state.folder ? this.mapFolders(this.state.folder.subFolders) : [];

        const filteredData = _.reject(data, d => {
            if (this.props.type == "folder" && this.props.fileOrFolderId == d.id)
                return true;

            if (d.deleted)
                return d.deleted;
            else
                return d == null;
        });

        const errorAlert = this.state.error ? <Alert type="warning" message={"Errorstatus: " + this.state.error} showIcon /> : null;

        const inFolder = this.props.type == "folder"
            ? _.findIndex(this.state.folder ? this.state.folder.subFolders : [], { id: this.props.fileOrFolderId }) > -1
            : _.findIndex(this.state.folder ? this.state.folder.files : [], { id: this.props.fileOrFolderId }) > -1;


        return (
            <div>
                <ListView
                    title={() =>
                        <React.Fragment>
                            {<div className="subtitle">
                                <Breadcrumb>{breadcrumb}</Breadcrumb>
                            </div>}
                        </React.Fragment>
                    }
                    bordered
                    collection={filteredData}
                    columns={listColumns}
                    size={"small"}
                    loading={this.state.loading}
                    onQueryChange={() => { }}
                    onSelect={this.onSelect}
                />

                {!inFolder
                    ? <div className="move-button">
                        <Button
                            icon={<ArrowRightOutlined />}
                            type="default"
                            onClick={(e) => this.onMoveFolder(e)}>
                            {`Move to ${this.state.folder ? this.state.folder.name : ""}`}
                        </Button>
                      </div>
                    : <div className="move-button">
                        <Button
                            icon={<ArrowRightOutlined />}
                            type="default"
                            disabled >
                            {`Already in ${this.state.folder ? this.state.folder.name : ""}`}
                        </Button>
                    </div>}
                {errorAlert}
            </div>
        );
    }
}

export default FileMoveForm;
