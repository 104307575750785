import * as React from "react";
import _ from 'lodash';
import AppContext from "../../Definitions/AppContext";
import { RequireCapability } from "../Shared/RequireCapability";
import { Statistics } from "../Shared/Statistics";
import { BarChartCard, ColumnChartCard } from "../Shared/Histogram";
import { ValueType } from "../../Models/ValueType";
import { Capabilities } from "../../Definitions/_capabilties";
import { PieChartCard } from "../Shared/PieChart";
import { ActivityAggregationResult } from "../../ApiClient/swagger/data-contracts";


interface StatisticsProps {
    aggregates: ActivityAggregationResult;
    loading: boolean;
    onExportClick?: () => void;
    exporting?: boolean;
    loadAggregates: () => void;
    hideTopCustomers?: boolean;
}

interface StatisticsState { }

export default class ActivityStatisticsView extends React.Component<StatisticsProps, StatisticsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render = () => (
        <RequireCapability capability={Capabilities.ActivitiesReadActor}>
            <Statistics
                className="activity-statistics-view"
                onExportClick={this.props.onExportClick ? this.props.onExportClick : null}
                exporting={this.props.exporting ? this.props.exporting : null}
                loadAggregates={this.props.loadAggregates}
            >
                <BarChartCard
                    title="Top contributors"
                    xField="label"
                    yField="sum"
                    valueBy={ValueType.Duration}
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates.actorDuration : null}
                    height={200}
                    route="people"
                />

                {!this.props.hideTopCustomers ? <ColumnChartCard
                    title="Top customers"
                    xField="label"
                    yField="sum"
                    valueBy={ValueType.Duration}
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates.customerDuration : null}
                    height={200}
                    route="actors"
                /> : null}

                <PieChartCard
                    title="Type of work"
                    xField="label"
                    yField="sum"
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates.typeDuration : null}
                    height={200}
                />

                <PieChartCard
                    title="Categories"
                    xField="label"
                    yField="sum"
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates.categoryDuration : null}
                    height={200}
                />

                <PieChartCard
                    title="Products"
                    xField="label"
                    yField="sum"
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates.productDuration : null}
                    height={200}
                />

                <ColumnChartCard
                    title="Top projects"
                    xField="label"
                    yField="sum"
                    valueBy={ValueType.Duration}
                    loading={this.props.loading}
                    data={this.props.aggregates ? this.props.aggregates.projectDuration : null}
                    height={200}
                    route="projects"
                />
            </Statistics>
        </RequireCapability>
    );
}