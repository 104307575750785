import * as React from "react";
import _ from 'lodash';
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";


interface RequireCapabilityProps {
    capability?: Capabilities;
    bypass?: boolean;
    children?: React.ReactNode;
}

export class RequireCapability extends React.Component<RequireCapabilityProps> {
    constructor(props) {
        super(props)
        this.state = {};
    }

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render() {
        if (this.props.bypass || !this.props.capability) return this.props.children;
        if (this.context.user.hasCapability(this.props.capability)) return this.props.children;
        return null;
    }
}

interface RequireCapabilitiesProps {
    capabilities?: Capabilities[];
    bypass?: boolean;
    children?: React.ReactNode;
}

export class RequireCapabilities extends React.Component<RequireCapabilitiesProps> {
    constructor(props) {
        super(props)
        this.state = {};
    }

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render() {
        if (this.props.bypass || !this.props.capabilities) return this.props.children;
        if (this.context.user.hasCapabilities(this.props.capabilities)) return this.props.children;
        return null;
    }
}

export class RequireAnyCapabilities extends React.Component<RequireCapabilitiesProps> {
    constructor(props) {
        super(props)
        this.state = {};
    }

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    render() {
        if (this.props.bypass || !this.props.capabilities) return this.props.children;
        if (this.context.user.hasAnyCapability(this.props.capabilities)) return this.props.children;
        return null;
    }
}