import * as React from "react";
import _ from 'lodash';
import { Card } from "antd";
import { FileImageOutlined, FileOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { FileLinkView, FileView } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { ImageExtentions, VideoExtentions } from "../../Definitions/_fileextentions";
import { HttpResponse } from "../../ApiClient/swagger/http-client";
const { Meta } = Card;

const formatFileSize = (size: number) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let i = 0;
    while (size >= 1024) {
        size /= 1024;
        ++i;
    }
    return size.toFixed(1) + ' ' + units[i];
}
const galleryUrlSwitch = (current: string, toGallery: boolean) => {
    const split = current.split("/");
    return (toGallery ? "/gallery/" : "/files/") + split[split.length - 1];
}

const downloadFile = async (selectedFile: FileView | FileLinkView) => {
    try {

        //@ts-ignore
        const isVersion = selectedFile.type == "version";

        const response: HttpResponse<File, any> = isVersion
            //@ts-ignore
            ? await client.files.downloadFile(selectedFile.parentFileId, selectedFile.id)
            : await client.files.downloadFile(selectedFile.id);

        if (!response) return;

        const blob = await response.blob();
        if (blob != null) {
            const url = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.style.display = 'none';
            a.download = selectedFile.attachmentName;
            a.href = url;

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
    catch (error: any) {
        
    }
}

const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
        const reader: any = new FileReader();
        reader.onloadend = () => resolve(reader.result.replace(/^data:.+;base64,/, ''));
        reader.readAsDataURL(blob);
    });
}

const mapFileToCard = async (file, onSelect?: (file) => void) => {
    if (Object.values(ImageExtentions).includes(file.extension)) {

        let blob;

        try {
            blob = await client.files.getThumbnail(file.id);
        }
        catch (error) {
            if (console && console.error)
                console.error("Error retreiving thumbnail");
        }

        const url = blob ? window.URL.createObjectURL(blob) : null; 

        if (url) {
            var card = <Card
                key={file.id}
                onClick={() => onSelect ? onSelect(file) : downloadFile(file)}
                className="thumbnail-card"
                hoverable
                cover={<div className="thumbnail-image-wrapper"><img alt={file.name} src={url} className="thumbnail-image" /></div>}
            >
                <Meta title={file.name} description={file.description} />
            </Card>;
            return card;
        }
        else {
            var card = <Card
                key={file.id}
                className="thumbnail-card"
                onClick={() => onSelect ? onSelect : downloadFile(file)}
                hoverable
                cover={<FileImageOutlined />}
            >
                <Meta title={file.name} description={file.description} />
            </Card>;
            return card;
        }
    }
    else if (Object.values(VideoExtentions).includes(file.extension)) {
        var card = <Card
            key={file.id}
            className="thumbnail-card"
            onClick={() => onSelect ? onSelect : downloadFile(file)}
            hoverable
            cover={<VideoCameraOutlined />}
        >
            <Meta title={file.name} description={file.description} />
        </Card>;
        return card;
    }
    else {
        var card = <Card
            key={file.id}
            className="thumbnail-card"
            onClick={() => onSelect ? onSelect : downloadFile(file)}
            hoverable
            cover={<FileOutlined />}
        >
            <Meta title={file.name} description={file.description} />
        </Card>;
        return card;
    }
}

export {
    formatFileSize,
    galleryUrlSwitch,
    downloadFile,
    blobToBase64,
    mapFileToCard
}