import { Button, Form } from "antd";
import * as React from "react";
import client from "../../ApiClient/client";
import { PagedPersonQuery, PersonView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import { Drawer } from "../Shared/Drawer";
import EntitySelector from "../Shared/EntitySelector";
import { RequireCapability } from "../Shared/RequireCapability";
import PersonCreateForm from "./PersonCreateForm";
import { AddPersonButtonPlacement } from "./PeopleModels";


export function PersonSelector(props: GenericEntitySelectorProps<PagedPersonQuery>) {
    const placeholder = props.placeholder ? props.placeholder : 'Select person...';
    const source = props.source ? props.source : client.people.queryPeople;
    const defaultValueSource = props.defaultValueSource ? props.defaultValueSource : client.people.getPersonById;
    const optionDisplayNameParam = props.optionDisplayNameParam ? props.optionDisplayNameParam : 'name';

    return (
        <EntitySelector
            placeholder={placeholder}
            source={source}
            defaultValueSource={defaultValueSource}
            optionDisplayNameParam={optionDisplayNameParam}
            {...props}
        />
    );
}


interface PersonSelectorInputProps extends GenericEntitySelectorProps<PagedPersonQuery> {
    required?: boolean;
    warningMessage?: string;
    param: string;
    className?: string;
    titleLink?: React.ReactNode;
    fromOrganization?: boolean;
    addPersonButtonPlacement?: AddPersonButtonPlacement;
}


export function PersonSelectorInput(props: PersonSelectorInputProps) {

    const context = React.useContext(AppContext);

    const [addedPerson, setAddedPerson] = React.useState<PersonView>();
    const [showCreateDrawer, setShowCreateDrawer] = React.useState(false);


    function onComplete(added: PersonView) {
        setAddedPerson(added);

        if (props.onChange)
            props.onChange(added.id);

        if (props.onObjectChange)
            props.onObjectChange(added);

        setShowCreateDrawer(false);
    }

    const AddPersonButton = () => {
        if (props.addPersonButtonPlacement === AddPersonButtonPlacement.Top) {
            return (
                <div>
                    <RequireCapability capability={Capabilities.PeopleWrite}>
                        <Button type="link" onClick={() => setShowCreateDrawer((current) => !current)} className={`select-actor-title ${context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                            {context.isMobile ? "+ Register" : "+ Register person"}
                        </Button>
                    </RequireCapability>
                    <span style={{ width: '100%', fontWeight: 500 }}>{props.title}</span>

                </div>
            );
        } else {
            return (
                <>

                    <span style={{ width: '100%', fontWeight: 500 }}>{props.title}</span>
                    <RequireCapability capability={Capabilities.PeopleWrite}>
                        <Button type="link" onClick={() => setShowCreateDrawer((current) => !current)} className={`select-actor-title ${context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                            {context.isMobile ? "+ Add" : "+ Add  person"}
                        </Button>
                    </RequireCapability>
                </>
            );
        }
    }

    return (
        <>
            <Form.Item
                className="with-create-entity"
                name={props.param}
                label={AddPersonButton()}
            >
                <PersonSelector
                    {...Object.assign(
                        {},
                        props,
                        { value: addedPerson?.id ?? props.value },
                        { title: props.title ? null : props.title }
                    )}
                />

                <Drawer
                    title="Add new person"
                    onClose={() => setShowCreateDrawer(false)}
                    open={showCreateDrawer}
                    destroyOnClose={true}
                    component={
                        <PersonCreateForm
                            fromOrganization={props.fromOrganization}
                            onComplete={onComplete}
                        />}
                />
            </Form.Item>

        </>
    );
}

export default PersonSelector;