import * as React from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { Popover, Spin, Card, Avatar, Button } from 'antd';
import { BankOutlined } from "@ant-design/icons";
import { ContactPointLinkView, ContactPointType, PersonLinkView, PersonView, PrincipalType, PrincipalView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { useContext, useEffect, useState } from "react";


const { Meta } = Card;

interface PersonLinkCardProps {
    id?: string;
    spanReturn?: boolean;
}


export function PersonLinkCard({ id }: PersonLinkCardProps) {

    const [error, setError] = useState<string>();
    const [avatarUrl, setAvatarUrl] = useState<string>();
    const [person, setPerson] = useState<PersonView>();

    const context = useContext(AppContext);

    useEffect(() => {
        if (!person || id != person.id)
            loadPerson();

        context.events.people.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.people.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }

    }, [id, person])


    function onItemUpdatedEvent(eventData: PersonView) {
        if (person?.id === eventData.id) {
            if (person.changeVector == eventData.changeVector) return;

            setPerson(eventData);
        }
    }

    async function loadPerson() {
        const response = await client
            .people.getPersonById(id)
            .catch(exception => setError(exception.error));

        if (response) {
            setPerson(response.data.view);
            if (response.data.links?.avatar)
                setAvatarUrl(response.data.links.avatar.url);
        }
    }

    if (error) return <span>{error}</span>;
    if (!person) return <Spin size="large" />;

    const fallbackAvatar = <Avatar shape="square" size={100} icon={<BankOutlined />} className="avatar" />;
    const avatar = avatarUrl ? <Avatar src={avatarUrl} shape="square" alt="avatar" /> : fallbackAvatar;

    const contactPoint = (type: ContactPointType): ContactPointLinkView[] => _.filter(person.contactPoints, (cp: ContactPointLinkView) => cp.type == type)

    const phones = contactPoint(ContactPointType.Phone);
    const videos = contactPoint(ContactPointType.Video);
    const emails = contactPoint(ContactPointType.Email);

    const contactPoints = (
        <dl>
            {phones && phones.length > 0 ? <div className="phone" style={{ marginBottom: '0.5em' }}><dt>Phone</dt>{_.map(phones, phone => <dd style={{ marginBottom: 0 }} key={phone.value}><a href={`tel:${phone.value}`}>{phone.value}</a></dd>)}</div> : null}
            {videos && videos.length > 0 ? <div className="mobile" style={{ marginBottom: '0.5em' }}><dt>Video</dt>{_.map(videos, video => <dd style={{ marginBottom: 0 }} key={video.value}><a href={`sip:${video.value}`}>{video.value}</a></dd>)}</div> : null}
            {emails && emails.length > 0 ? <div className="email" style={{ marginBottom: '0.5em' }}><dt>Email</dt>{_.map(emails, email => <dd style={{ marginBottom: 0 }} key={email.value}><a href={`mailto:${email.value}`}>{email.value}</a></dd>)}</div> : null
            }
        </dl>
    );

    return (
        <Card className="actor-link-card link-card" bordered={false} size="small" title={person.name}>
            <Meta avatar={avatar} description={
                <React.Fragment>
                    {contactPoints}
                </React.Fragment>
            }
            />
        </Card>
    );
}

interface PersonLinkProps extends PersonLinkCardProps, PersonLinkView {
    disablePopover?: boolean;
    disableRedirect?: boolean;
    type?: PrincipalType;
    className?: string;
    break?: boolean;
}



export function PersonLink(props: PersonLinkProps) {

    const [error, setError] = useState<string>();
    const [person, setPerson] = useState<PersonView>(props);

    const context = useContext(AppContext);

    useEffect(() => {

        if (props.id == "system" || props.type == PrincipalType.System) {
            const system: PersonView = { name: "System", id: null };
            setPerson(system);
        }
        else
            if (props.type == PrincipalType.Application) {
                const system: PersonView = { name: "Overviu", id: props.id };
                setPerson(system);
            }
            else
                if (props.id != person.id) {
                    loadPerson(props.id)
                }
                else
                    if (!person.name && person.id != null) { 
                        loadPerson(person.id)
                    }
                

        context.events.people.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.people.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [props])

    async function loadPerson(id: string) {
        const response = await client.people.getPersonById(id).catch(exception => setError(exception.error));

        if (response) {
            setPerson(response.data.view);
        }
    }

    function onItemUpdatedEvent(eventData: PersonView) {
        if (person.id === eventData.id) {
            setPerson(eventData);
        }
    }

    if (!person.name) return null;

    if (!context.user.hasCapability(Capabilities.PeopleReadRelated))
        return <Popover content="You do not have access to read actors.">{person.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (person.deleted)
        className += " deleted";

    if (props.className)
        className += " " + props.className;

    if (props.disableRedirect)
        className += " disable-redirect"

    const link = props.disableRedirect
        ? <Button type="link" className={className}>{person.name}</Button>
        : <Link onClick={(e) => e.stopPropagation()} to={`/people/${person.id}`} className={className}>{person.name}</Link>;

    if (props.id == "system" || props.type == PrincipalType.Application || props.type == PrincipalType.System || props.spanReturn)
        return <span>{person.name}</span>

    if (props.disablePopover || context.isMobile)
        return link;

    return (
        <span className="link-wrapper" >
            <Popover zIndex={1051} placement="bottomLeft" content={<PersonLinkCard id={person.id} />}>
                {link}
            </Popover>
        </span>
    );
}