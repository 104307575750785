import _ from 'lodash';
import moment from 'moment';
import { Card } from "antd";
import ListViewCard from "../Shared/ListViewCard";
import { TicketView, PagedTicketQuery, TicketStatus } from "../../ApiClient/swagger/data-contracts";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from '../../ApiClient/client';
import { updateCollectionWithEvent } from '../../Helpers/BasePageHelpers';
import { ActorLink } from '../Actors';
import { useNavigate } from 'react-router-dom';


interface TicketWidgetProps {
    title: any;
    className?: string;
    query: Partial<PagedTicketQuery>;
    exclutions?: string[];
    onItemCreatedEvent?: (collection: TicketView[], ticket: TicketView) => TicketView[];
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    //formFilters?: Partial<PagedTicketQuery>;
}

export function TicketWidget(props: TicketWidgetProps) {

    const [collection, setCollection] = useState<TicketView[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    //const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

    const context = useContext(AppContext);
    const navigate = useNavigate();

    async function loadCollection() {
        setLoading(true);

        const response = await client.tickets
            .queryTickets(props.query)
            .catch(exception => setError(exception.error));

        if (response) setCollection(response.data.items)

        setLoading(false);
    }

    function onItemCreatedEvent(response: TicketView) {
        if (!response || !props.onItemCreatedEvent) return;

        setCollection(prevCollection => {
            const newCollection = props.onItemCreatedEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }

    function onItemUpdatedEvent(response: TicketView) {
        if (!response) return;

        setCollection(prevCollection => {
            const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }

    useEffect(() => {
        if (!collection) loadCollection();

        context.events.tickets.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent
        });

        return () => {
            context.events.tickets.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent
            });
        }
    }, [])

    const baseColumns = _.reject(props.customColumns ?? [
        {
            title: 'Ticket number',
            dataIndex: 'ticketNumber',
            key: 'ticketNumber',
            width: 110
        },
        {
            title: 'Title',
            dataIndex: 'name',
            key: 'title'
        },
        {
            title: 'Status',
            render: (ticket: TicketView) => ticket.closedDate ? 'Closed' : 'Open',
            key: 'status'
        },
        {
            title: 'Customer',
            render: (ticket: TicketView) => ticket.customer ? <ActorLink {...ticket.customer} break /> : null,
            key: 'customer'
        },
        {
            title: 'Requester',
            render: (ticket: TicketView) => ticket.requester ? <ActorLink {...ticket.requester} break /> : null,
            key: 'requester'
        },
        {
            title: 'Assignee',
            render: (ticket: TicketView) => ticket.assignee ? <ActorLink {...ticket.assignee} break /> : null,
            key: 'assignee'
        },
        {
            title: 'Due date',
            render: (ticket: TicketView) => ticket.dueDate ? moment(ticket.dueDate).format('DD.MM.YYYY') : null,
            key: 'dueDate'
        }
    ], c => _.find(props.exclutions, e => { return c.key == e }) != null);

    if (error) return <Card title={props.title}>{error}</Card>;

    return (
        <>
            <ListViewCard
                title={props.title}
                columns={baseColumns}
                data={collection}
                loading={loading}
                onSelect={(data: TicketView) => navigate(`/tickets/${data.id}`)}
                className="ticket-widget"
                rowClassName={(ticket: TicketView) => {
                    const defaultClassNames = `list-row ${ticket.deleted ? 'deleted' : ''}`
                    if (!ticket.dueDate || ticket.status !== TicketStatus.Open) return defaultClassNames;

                    const midnightToday = moment().startOf('day');
                    const dueDate = moment(ticket.dueDate).startOf('day');
                    const diff = midnightToday.diff(dueDate, 'days');

                    if (diff > 0) return `${defaultClassNames} ticket-overdue`;
                    if (diff === 0) return `${defaultClassNames} ticket-due-today`;
                    if (diff < 0 && diff >= -2) return `${defaultClassNames} ticket-due-soon`;

                    return defaultClassNames;
                }}
                //actions={context.user.hasCapability(Capabilities.TicketsWrite) ? <Button key="add" size="small" onClick={() => setShowCreateDrawer(previous => !previous)}>Add ticket</Button> : null}
                {...props}
            />

            {/*<Drawer
                title="Create ticket"
                onClose={() => setShowCreateDrawer(false)}
                open={showCreateDrawer}
                component={
                    <TicketCreateForm
                        filters={props.query}
                        onCancel={() => setShowCreateDrawer(false)}
                        onComplete={() => setShowCreateDrawer(previous => !previous)}
                    />
                }
            />*/}
        </>
    );
}