import * as React from "react";
import _ from 'lodash';
import { PagedActivityQuery, PagedTaskQuery } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { TaskSelector } from "../Tasks";



interface MoveActivityProps {
    onComplete: () => void,
    onCancel?: () => void,
    activityQuery: Partial<PagedActivityQuery>
}

interface MoveActivityState {
    loading: boolean,
    error: string,
    query: Partial<PagedTaskQuery>
}

interface MoveActivities extends PagedActivityQuery {
    toTaskId?: string;
}

class ActivityMoveForm extends React.Component<MoveActivityProps, MoveActivityState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            query: {
                projectId: null
            }
        }
    }

    componentDidMount = async () => {
        if (this.props.activityQuery.projectId) {
            const query = { projectId: this.props.activityQuery.projectId };
            this.setState({ query });
        }
    }

    onSubmit = async (request: MoveActivities) => {
        this.setState({ loading: true });

        const moveRequest = Object.assign({}, this.props.activityQuery, request);

        try {
            const response = await client.activities.moveActivities(moveRequest);
            this.props.onComplete();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onQueryChange = async (newQuery, values) => {
        this.setState({ query: values });
    }

    render() {
        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onValuesChange={this.onQueryChange}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    projectId: this.props.activityQuery.projectId
                }}
            >
                <SelectorInput
                    param="toTaskId"
                    title="Task"
                    selector={
                        <TaskSelector
                            filters={{
                                projectId: this.state.query.projectId,
                                showAll: true
                            }}
                        />
                    }
                />
            </BaseForm>
        );
    }
}

export default ActivityMoveForm;