/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { NewsFeedCollectionViewModel, NewsInternalFeedCollectionViewModel } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Newsfeed<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags NewsFeed
   * @name QueryNewsFeeds
   * @request GET:/api/newsfeed
   * @secure
   */
  queryNewsFeeds = (
    query?: {
      feedIds?: string[];
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NewsFeedCollectionViewModel, any>({
      path: `/api/newsfeed`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsFeed
   * @name GetInternalFeed
   * @request GET:/api/newsfeed/internal
   * @secure
   */
  getInternalFeed = (
    query?: {
      feedIds?: string[];
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NewsInternalFeedCollectionViewModel, any>({
      path: `/api/newsfeed/internal`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsFeed
   * @name NewsfeedUpdate
   * @request PUT:/api/newsfeed/{id}
   * @secure
   */
  newsfeedUpdate = (id: string, params: RequestParams = {}) =>
    this.http.request<string[], any>({
      path: `/api/newsfeed/${id}`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NewsFeed
   * @name NewsfeedDelete
   * @request DELETE:/api/newsfeed/{id}
   * @secure
   */
  newsfeedDelete = (id: string, params: RequestParams = {}) =>
    this.http.request<string[], any>({
      path: `/api/newsfeed/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
