import _ from 'lodash';
import moment from 'moment';
import { Card } from "antd";
import ListViewCard from "../Shared/ListViewCard";
import { FileView, InvoiceView, PagedInvoiceQuery } from "../../ApiClient/swagger/data-contracts";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from '../../ApiClient/client';
import { updateCollectionWithEvent } from '../../Helpers/BasePageHelpers';
import { formatCurrency } from '../../Helpers/Formatters';
import { blobToBase64, FileModal } from '../Documents';


interface InvoiceWidgetProps {
    title?: string;
    className?: string;
    query: Partial<PagedInvoiceQuery>;
    exclutions?: string[];
    onItemCreatedEvent?: (collection: InvoiceView[], Invoice: InvoiceView) => InvoiceView[];
    onItemUpdatedEvent?: (collection: InvoiceView[], Invoice: InvoiceView) => Promise<InvoiceView[]>;
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    actions?: React.ReactNode;
}

export function InvoiceWidget(props: InvoiceWidgetProps) {

    const [collection, setCollection] = useState<InvoiceView[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();

    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToDisplay, setFileToDisplay] = useState<FileView>();
    const [fileDataToDisplay, setFileDataToDisplay] = useState<string>();

    const context = useContext(AppContext);

    async function loadCollection() {
        setLoading(true);

        const response = await client.invoices
            .queryInvoices(props.query)
            .catch(exception => setError(exception.error));

        if (response) setCollection(response.data.items)

        setLoading(false);
    }

    function onItemCreatedEvent(response: InvoiceView) {
        if (!response || !props.onItemCreatedEvent) return;

        setCollection(prevCollection => {
            const newCollection = props.onItemCreatedEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }

    async function onItemUpdatedEvent(response: InvoiceView) {
        if (!response) return;

        if (props.onItemUpdatedEvent) {
            const updatedCollection = await props.onItemUpdatedEvent(collection?.slice() ?? [], response);
            setCollection(updatedCollection);
        }
        else {
            setCollection(prevCollection => {
                const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], response);
                return newCollection;
            });
        }
    }

    async function onSelect(data: InvoiceView) {
        if (!data.document) return;

        //let fileData = await client.invoices.getInvoiceDocumentById(data.id).catch(exception => setError(exception.error));
        const fileData = await (await client.invoices.getInvoiceDocumentById(data.id)).blob().catch(exception => setError(exception.error));
        const base64string: any = await blobToBase64(fileData);

        setFileToDisplay(data);
        setFileDataToDisplay(base64string);
        setShowFileModal(true);
    }

    function changeFileModalVisibility(fileModalVisibility: boolean) {
        if (fileModalVisibility == false) {
            setFileDataToDisplay(null);
            setFileToDisplay(null);
        }

        setShowFileModal(fileModalVisibility);
    }

    useEffect(() => {
        if (!collection) loadCollection();

        context.events.invoices.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent
        });

        return () => {
            context.events.invoices.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent
            });
        }
    }, [])

    const baseColumns = _.reject(props.customColumns ?? [
        {
            title: 'Invoice',
            render: (invoice: InvoiceView) => invoice.invoiceNo,
            key: 'invoice',
        },
        {
            title: 'Amount',
            render: (invoice: InvoiceView) => invoice.netSum ? formatCurrency(invoice.netSum) : "-",
            key: 'amount',
        },
        {
            title: 'Created',
            render: (invoice: InvoiceView) => invoice.issued ? moment(invoice.issued).format('DD.MM.YYYY') : "-",
            key: 'created',
        },
        {
            title: 'Due date',
            render: (invoice: InvoiceView) => invoice.dueDate ? moment(invoice.dueDate).format('DD.MM.YYYY') : "-",
            key: 'dueDate',
        }
    ], c => _.find(props.exclutions, e => { return c.key == e }) != null);

    if (error) return <Card title={props.title}>{error}</Card>;

    return (
        <>
            <ListViewCard
                title={props.title}
                columns={baseColumns}
                data={collection}
                loading={loading}
                onSelect={onSelect}
                className="invoice-widget"
                {...props}
            />

            <FileModal
                filesInFolder={collection}
                file={fileToDisplay}
                fileData={fileDataToDisplay}
                showFileModal={showFileModal}
                changeFileModalVisibility={changeFileModalVisibility}
                changeFile={onSelect}
                isInvoice
            />
        </>
    );
}