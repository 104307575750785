import _ from "lodash";
import moment from "moment";
import { findCurrentTypeOrStatus, formatSubStatusLabel } from "../../Modules/Subscriptions";
import client from "../client";
import { PagedSubscriptionQuery, ProductReportValues, SubscriptionType, SubscriptionView } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class SubscriptionExport extends BaseExportService<PagedSubscriptionQuery, SubscriptionView> {

    constructor() {

        const specification: ExportConfig<SubscriptionView>[] = [
            { header: 'Customer', value: (data: SubscriptionView) => data.customer ? data.customer.name : null },
            { header: 'Type', value: (data: SubscriptionView) => data.typeHistory ? findCurrentTypeOrStatus(data.typeHistory).value : null },
            { header: 'Status', value: (data: SubscriptionView) => formatSubStatusLabel(data) },
            { header: 'Description', value: (data: SubscriptionView) => data.description ?? null },
            {
                header: 'Product status', value: (data: SubscriptionView) => {
                    if (data.relatedReports && data.relatedReports.products && data.relatedReports.products.timestamp) {
                        const thisDeviations = [];
                        const otherDeviations = [];
                        const thisMissingReport = [];
                        const otherMissingReport = [];

                        const currentType = findCurrentTypeOrStatus(data.typeHistory).value;

                        _.map(data.relatedReports.products.items || [], p => {
                            _.map(p.valuesBySource != null ? p.valuesBySource : [], (value: ProductReportValues, key) => {
                                _.map(Object.values(SubscriptionType), type => {
                                    const difference = value.difference[type];

                                    if (difference === undefined) return;

                                    if (difference === null) {
                                        if (type === currentType) {
                                            thisMissingReport.push(difference);
                                        } else {
                                            otherMissingReport.push({ type, value: difference });
                                        }
                                    } else {
                                        if (type === currentType) {
                                            thisDeviations.push(difference);
                                        } else {
                                            otherDeviations.push({ type, value: difference });
                                        }
                                    }
                                });
                            });
                        });

                        const otherDeviationsSums: { [key: string]: number } = {};
                        // Loop through array of objects
                        for (const obj of otherDeviations) {
                            // Check if attribute1 string exists in dictionary
                            if (obj.type in otherDeviationsSums) {
                                // If it does, add value of object to existing value in dictionary
                                otherDeviationsSums[obj.type] += 1;
                            } else {
                                // If it does not, add attribute1 string as key and value of object as value
                                otherDeviationsSums[obj.type] = 1;
                            }
                        }

                        // Initialize empty array to store new objects
                        const otherDeviationsCollectedByType: { type: string; value: number }[] = [];

                        // Loop through dictionary and add object to new array for each entry
                        for (const [type, value] of Object.entries(otherDeviationsSums)) {
                            otherDeviationsCollectedByType.push({ type, value });
                        }

                        const otherAdditionSums: { [key: string]: number } = {};
                        // Loop through array of objects
                        for (const obj of otherMissingReport) {
                            // Check if attribute1 string exists in dictionary
                            if (obj.type in otherAdditionSums) {
                                // If it does, add value of object to existing value in dictionary
                                otherAdditionSums[obj.type] += 1;
                            } else {
                                // If it does not, add attribute1 string as key and value of object as value
                                otherAdditionSums[obj.type] = 1;
                            }
                        }

                        // Initialize empty array to store new objects
                        const otherAdditionsCollectedByType: { type: string; value: number }[] = [];

                        // Loop through dictionary and add object to new array for each entry
                        for (const [type, value] of Object.entries(otherAdditionSums)) {
                            otherAdditionsCollectedByType.push({ type, value });
                        }

                        const otherStrings: string[] = [];

                        if (otherDeviationsCollectedByType != null) {
                            _.each(otherDeviationsCollectedByType ?? [], obj => {
                                if (obj.value != null && obj.value != 0) {
                                    const type = obj.type;
                                    const value = obj.value;
                                    const suffix = value > 1 ? "Deviations" : "Deviation";
                                    otherStrings.push(`${type}: ${value} ${suffix}`);
                                }
                            });
                        }

                        if (otherAdditionsCollectedByType != null) {
                            _.each(otherAdditionsCollectedByType ?? [], obj => {
                                if (obj.value != null && obj.value != 0) {
                                    const type = obj.type;
                                    const value = obj.value;
                                    const suffix = value > 1 ? "Additions" : "Addition";
                                    otherStrings.push(`${type}: ${value} ${suffix}`);
                                }
                            });
                        }

                        if (thisDeviations.length)
                            return thisDeviations.length + (thisDeviations.length > 1 ? " Deviations" : " Deviation") + (otherStrings.length > 0 ? `(${otherStrings.join(", ")})` : "")

                        if (thisMissingReport.length)
                            return thisMissingReport.length + (thisMissingReport.length > 1 ? " Additions" : " Addition") + (otherStrings.length > 0 ? `(${otherStrings.join(", ")})` : "")


                        return "OK"

                    }

                    return "-";
                }
            },
            {
                header: 'CPU diff', value: (data: SubscriptionView) => {
                    if (data.relatedReports && data.relatedReports.resources && data.relatedReports.resources.timestamp) {
                        const deviation = _.find(data.relatedReports.resources.values || [], p => {
                            return p.resource == "CPU" && p.difference != 0;
                        });

                        if (deviation)
                            return deviation.difference

                        return "0"

                    }

                    return "-";
                }
            },
            {
                header: 'RAM diff', value: (data: SubscriptionView) => {
                    if (data.relatedReports && data.relatedReports.resources && data.relatedReports.resources.timestamp) {
                        const deviation = _.find(data.relatedReports.resources.values || [], p => {
                            return p.resource == "RAM" && p.difference != 0;
                        });

                        if (deviation)
                            return deviation.difference

                        return "0"

                    }

                    return "-";
                }
            },
            {
                header: 'DISK diff', value: (data: SubscriptionView) => {
                    if (data.relatedReports && data.relatedReports.resources && data.relatedReports.resources.timestamp) {
                        const deviation = _.find(data.relatedReports.resources.values || [], p => {
                            return p.resource == "DISK" && p.difference != 0;
                        });

                        if (deviation)
                            return deviation.difference

                        return "0"

                    }

                    return "-";
                }
            },
            { header: 'Current yearly value', value: (data: SubscriptionView) => data.value == null ? null : data.currentValue * 12, format: ExportFormat.Currency },
            { header: 'Last modified', value: (data: SubscriptionView) => data.lastModified ? moment(data.lastModified).format() : "" },
            {
                header: 'Attributes', value: (data: SubscriptionView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })

                    }

                    return dataString
                }
            },
            { header: 'Deleted', value: (data: SubscriptionView) => data.deleted ? true : false }
        ];

        super(specification, client.subscriptions.querySubscriptions);
    }
}