import * as React from "react";
import _, { sortBy } from 'lodash';
import moment from 'moment';
import AppContext from "../../Definitions/AppContext";
import { PagedTicketQuery, SortDirection, TicketSortOption, TicketStatus } from "../../ApiClient/swagger/data-contracts";
import { QuickSearch } from "../Shared/QuickSearch";

interface TicketQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedTicketQuery>
}


export class TicketQuickSearch extends React.Component<TicketQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <React.Fragment>
                <QuickSearch
                    name="All"
                    queryParameters={{
                        status: [],
                        //sortBy: null,
                        //sortDirection: null
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Open"
                    queryParameters={{
                        status: [TicketStatus.Open]
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="On hold"
                    queryParameters={{
                        status: [TicketStatus.OnHold]
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Closed"
                    queryParameters={{
                        status: [TicketStatus.Closed],
                        //Adjusting so quickselect don't decide sorting only selection
                        //sortBy: TicketSortOption.ClosedDate,
                        //sortDirection: SortDirection.Desc
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="My tickets"
                    queryParameters={{
                        relatedToId: this.context.user.actorId
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Unassigned"
                    queryParameters={{
                        assigneeId: "unassigned",
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                {/*<QuickSearch*/}
                {/*    name="This month"*/}
                {/*    queryParameters={{*/}
                {/*        closedAfter: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),*/}
                {/*        closedBefore: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),*/}
                {/*        closed: null*/}
                {/*    }}*/}
                {/*    currentQuery={this.props.query}*/}
                {/*    onQueryChange={this.props.onQuickSearch}*/}
                {/*/>*/}

                {/*<QuickSearch*/}
                {/*    name="Last month"*/}
                {/*    queryParameters={{*/}
                {/*        closedAfter: moment().subtract(1, "month").startOf('month').format('YYYY-MM-DDTHH:mm:ss'),*/}
                {/*        closedBefore: moment().subtract(1, "month").endOf('month').format('YYYY-MM-DDTHH:mm:ss'),*/}
                {/*        closed: null*/}
                {/*    }}*/}
                {/*    currentQuery={this.props.query}*/}
                {/*    onQueryChange={this.props.onQuickSearch}*/}
                {/*/>*/}

                {/*<QuickSearch*/}
                {/*    name="This week"*/}
                {/*    queryParameters={{*/}
                {/*        closedAfter: moment().startOf('week').format('YYYY-MM-DDTHH:mm:ss'),*/}
                {/*        closedBefore: moment().endOf('week').format('YYYY-MM-DDTHH:mm:ss'),*/}
                {/*        closed: null*/}
                {/*    }}*/}
                {/*    currentQuery={this.props.query}*/}
                {/*    onQueryChange={this.props.onQuickSearch}*/}
                {/*/>*/}
            </React.Fragment>
        );
    }
}
export default TicketQuickSearch;