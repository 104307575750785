import * as React from "react";
import _ from 'lodash';
import { Radio, RadioChangeEvent, Space } from "antd";
import { RoutineExecutionView, RoutineView, UpdateRoutine } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { TextAreaInput } from "../Shared/TextAreaInput";


enum YesOrNo {
    Yes = 1,
    No = 2
}

interface DeleteExecutionProps {
    routine: RoutineView;
    execution: RoutineExecutionView;
    onCancel?: () => void;
    onComplete?: (updated: RoutineExecutionView) => void;
}

interface DeleteExecutionState {
    loading: boolean;
    error: string;
    affectNext: YesOrNo;
}

export class RoutineDeleteExecutionForm extends React.Component<DeleteExecutionProps, DeleteExecutionState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            affectNext: null,
        }
    }

    onSubmit = async (request) => {
        this.setState({ loading: true });

        //TODO:
        //Update starting date for routine if request includes new starting date
        //Update note and status of execution

        //if (this.state.affectNext == YesOrNo.Yes) {
        //    var routineRequest: UpdateRoutine = {
        //        title: this.props.routine.title,
        //        description: this.props.routine.description,
        //        severity: this.props.routine.severity,
        //        frequency: this.props.routine.frequency,
        //        ownerId: this.props.routine.owner?.id,
        //        responsibleIds: _.map(this.props.routine.responsible ?? [], res => res.id),
        //        allEmployees: this.props.routine.allEmployees,
        //        organizationId: this.props.routine.organization?.id,
        //        resourceId: this.props.routine.resource?.id,
        //        categoryId: this.props.routine.category?.id,
        //        checklist: _.map(this.props.routine.checklist ?? [], check => check.title),
        //        tagIds: _.map(this.props.routine.tags ?? [], tag => tag.id),
        //    }

        //    await client.routines.updateRoutine(this.props.routine.id, routineRequest, addChangeVectorHeader(this.props.routine.changeVector));
        //}

        const response = await client.routines
            .deleteExecution(this.props.routine.id, this.props.execution.id, addChangeVectorHeader(this.props.execution.changeVector))
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    onAffectNextChange = (event: RadioChangeEvent) => {
        this.setState({
            affectNext: event.target.value,
        });
    }

    render = () => {
        return (
            <>
                <BaseForm
                    type={FormType.Edit}
                    onSubmit={this.onSubmit}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={{

                    }}
                    onCancel={this.props.onCancel}
                >
                    {this.props.routine.frequency != null ?
                        <SelectorInput
                            param="affectNext"
                            title="Should this affect the next execution?"
                            selector={
                                <Radio.Group onChange={this.onAffectNextChange} value={this.state.affectNext}>
                                    <Space direction="horizontal">
                                        <Radio value={YesOrNo.Yes}>Yes, I would like to change the date</Radio>
                                        <Radio value={YesOrNo.No}>No</Radio>
                                    </Space>
                                </Radio.Group>
                            }
                            required
                        />
                        :
                        null
                    }

                    {this.props.routine.frequency != null && this.state.affectNext == YesOrNo.Yes ?
                        <SelectorInput
                            param="startDate"
                            title="Next execution date"
                            selector={<DateSelector />}
                            required
                        />
                        :
                        null
                    }

                    <TextAreaInput
                        title="Please note why you are deleting this execution"
                        param="note"
                        placeholder="Write here"
                        rows={2}
                        required
                    />
                </BaseForm>

            </>
        );
    }
}

export default RoutineDeleteExecutionForm;