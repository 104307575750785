import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import AppContext from "../../Definitions/AppContext";
import { ProductSupplierView, ProductView } from "../../ApiClient/swagger/data-contracts";
import { ActorLink } from "../Actors";
import { Drawer } from "../../Modules/Shared/Drawer";
import { Capabilities } from "../../Definitions/_capabilties";
import { Button, Popconfirm } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ListViewCard from "../Shared/ListViewCard";
import ProductSupplierForm from "./ProductSupplierForm";
import { ColumnsType } from "antd/es/table";


interface ProductSupplierListProps {
    product: ProductView;
    className?: string;
    onComplete: (product: ProductView) => void;
}

interface ProductSupplierListState {
    showCreateDrawer: boolean,
    supplier: ProductSupplierView,
    error: string,
    loadingRemove: boolean,
}

export class ProductSupplierList extends React.Component<ProductSupplierListProps, ProductSupplierListState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showCreateDrawer: false,
            supplier: null,
            error: null,
            loadingRemove: false
        }
    }

    onRemove = async (supplier: ProductSupplierView) => {
        this.setState({ loadingRemove: true });

        if (supplier != null) {
            try {

                const response = await client.products.deleteProductSupplier(this.props.product.id, supplier.optionId);
                this.onEditDrawerClose();
                this.props.onComplete(response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }

        this.setState({ loadingRemove: false });
    }

    toggleCreateDrawer = () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    onEdit = async (supplier) => {
        this.setState({ supplier });
    }

    onEditDrawerClose = () => {
        this.setState({ supplier: null });
    }

    render = () => {
        if (this.props.product && this.props.product.suppliers === undefined) return null;

        const listColumns: ColumnsType<any> = [
            {
                title: 'Supplier',
                render: (supplier: ProductSupplierView) => supplier.supplier ? <ActorLink {...supplier.supplier} /> : "",
                key: 'actor',
                width: '100%'
            }
        ];

        if (this.context.user.hasAnyCapability([Capabilities.ProductsWrite])) {
            //@ts-ignore
            listColumns.push({
                title: 'Actions',
                render: (supplier: ProductSupplierView) =>
                    <div className="table-actions">
                        <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            type="default"
                            onClick={() => this.onEdit(supplier)}
                        />
                        <Popconfirm title="Are you sure to remove supplier?" onConfirm={() => this.onRemove(supplier)} okText="Yes" cancelText="No">
                            <Button
                                shape="circle"
                                icon={<DeleteOutlined />}
                                type="default" danger
                                loading={this.state.loadingRemove}
                                onClick={(e) => { e.stopPropagation() }}
                            />
                        </Popconfirm>
                    </div>,
                key: 'actions',
                width: 90
            });
        }

        return (
            <React.Fragment>
                <Drawer
                    title="Add supplier"
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    destroyOnClose={true}
                    component={
                        <ProductSupplierForm
                            product={this.props.product}
                            onComplete={(data) => { this.props.onComplete(data); this.toggleCreateDrawer(); }}
                            onCancel={this.toggleCreateDrawer}
                        />
                    }
                />

                <Drawer
                    title="Update supplier"
                    onClose={this.onEditDrawerClose}
                    open={this.state.supplier != null}
                    destroyOnClose={true}
                    component={
                        <ProductSupplierForm
                            product={this.props.product}
                            supplier={this.state.supplier}
                            onComplete={(data) => { this.props.onComplete(data); this.onEditDrawerClose(); }}
                            onCancel={this.onEditDrawerClose}
                        />
                    }
                />

                <ListViewCard
                    title="Suppliers"
                    onAdd={this.context.user.hasAnyCapability([Capabilities.ProductsWrite]) ? this.toggleCreateDrawer : null}
                    columns={listColumns}
                    data={this.props.product.suppliers}
                    className={`product-supplier-list ${this.props.className ? this.props.className : ''}`}
                />
            </React.Fragment>
        );
    }
}

export default ProductSupplierList;

