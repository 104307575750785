import * as React from "react";
import _ from 'lodash';
import { Radio, Space, RadioChangeEvent } from "antd";
import { SelectorInput } from "../Shared/SelectorInput";
import { ActorView, Address, AddressType, OrderType } from "../../ApiClient/swagger/data-contracts";
import { AddressInput } from "../Shared/AddressInput";
import { CustomerSelector } from "../Actors";
import OrganizationAddressSelector from "../Organizations/OrganizationAddressSelector";

interface OrderAddressProps {
    type: OrderType;
    customer: ActorView;
    invoiceCustomer: ActorView;
    onDeliveryStatusChange: (e: RadioChangeEvent) => void;
    onInvoiceStatusChange: (e: RadioChangeEvent) => void;
    onInvoiceRecipientChange: (value: any) => any;
    onInvoiceRecipentStatusChange: (e: RadioChangeEvent) => void;
    deliveryStatus: string;
    invoiceStatus: string;
    invoiceRecipentStatus: string;
    onDeliveryAddressChange: (address: Address) => void;
}

class OrderAddressSelector extends React.Component<OrderAddressProps> {
    render = () => {
        const firstDeliveryAddressId = this.props.customer?.addresses?.length > 0 ? this.props.customer.addresses[0].addressId : null;

        return (
            <>
                <SelectorInput
                    title="Delivery address"
                    param="deliveryStatus"
                    required
                    selector={
                        <Radio.Group onChange={this.props.onDeliveryStatusChange} value={this.props.deliveryStatus}>
                            <Space direction="vertical">
                                <Radio value="existing">Select address</Radio>
                                {this.props.deliveryStatus != "existing" ? null :
                                    <SelectorInput
                                        key="deliveryAddress"
                                        param="deliveryAddress"
                                        warningMessage="Select address"
                                        className="addressSelector"
                                        selector={<OrganizationAddressSelector onObjectChange={this.props.onDeliveryAddressChange} placeholder="Select address" value={firstDeliveryAddressId} customer={this.props.customer} />}
                                    />
                                }
                                <Radio value="skip">Skip address</Radio>
                                <Radio value="new">Enter new address</Radio>
                                {this.props.deliveryStatus != "new" ? null :
                                    <AddressInput
                                        param="deliveryAddress"
                                        addressType={AddressType.Delivery}
                                        className="addressSelector"
                                        onAddressChange={this.props.onDeliveryAddressChange}
                                        required
                                    />
                                }
                            </Space>
                        </Radio.Group>
                    }
                />

                <SelectorInput
                    title="Invoice recipient"
                    param="invoiceRecipentStatus"
                    required
                    selector={
                        <Radio.Group onChange={this.props.onInvoiceRecipentStatusChange} value={this.props.invoiceRecipentStatus}>
                            <Space direction="vertical">
                                <Radio value="same">Same as customer</Radio>
                                <Radio value="another">Select another invoice recipient</Radio>
                                {this.props.invoiceRecipentStatus != "another" ? null :
                                    <SelectorInput
                                        title="Invoice Recipient"
                                        key="invoiceRecipient"
                                        param="invoiceRecipient"
                                        required
                                        warningMessage="Please select a customer"
                                        selector={<CustomerSelector onObjectChange={this.props.onInvoiceRecipientChange} />}
                                    />
                                }
                            </Space>
                        </Radio.Group>
                    }
                />

                {this.props.invoiceRecipentStatus == null || (this.props.invoiceRecipentStatus == "another" && this.props.invoiceCustomer == null) ? null :
                    <SelectorInput
                        title="Invoice address"
                        param="invoiceStatus"
                        required
                        selector={
                            <Radio.Group onChange={this.props.onInvoiceStatusChange} value={this.props.invoiceStatus}>
                                <Space direction="vertical">
                                    <Radio value="same">Same as delivery address</Radio>
                                    <Radio value="existing">Select address</Radio>
                                    {this.props.invoiceStatus != "existing" ? null :
                                        <SelectorInput
                                            key="invoiceAddress"
                                            param="invoiceAddress"
                                            required
                                            warningMessage="Select address"
                                            className="addressSelector"
                                            selector={<OrganizationAddressSelector placeholder="Select address" customer={this.props.invoiceRecipentStatus == "another" ? this.props.invoiceCustomer : this.props.customer} />}
                                        />
                                    }
                                    <Radio value="skip">Skip address</Radio>
                                    <Radio value="new">Enter new address</Radio>
                                    {this.props.invoiceStatus != "new" ? null :
                                        <AddressInput
                                            param="invoiceAddress"
                                            addressType={AddressType.Invoice}
                                            required
                                        />
                                    }
                                </Space>
                            </Radio.Group>
                        }
                    />
                }
            </>
        );
    }
}

export default OrderAddressSelector;