import * as React from "react";
import _ from 'lodash';
import { Button } from "antd";
import { BarChartOutlined, DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import AppContext from "../../Definitions/AppContext";

interface StatisticsProps {
    className?: string;
    shownElements?: number;
    loadAggregates?: () => void;
    expandWith?: number;
    actions?: any;
    onExportClick?: () => void;
    exporting?: boolean;
    children?: React.ReactNode;
}

interface StatisticsState {
    takeElements: number;
}

export class Statistics extends React.Component<StatisticsProps, StatisticsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            takeElements: this.props.shownElements != null ? this.props.shownElements : 0
        }
    }

    showMore = (e) => {
        e.stopPropagation();

        if (this.props.loadAggregates)
            this.props.loadAggregates();

        let shownElements = this.state.takeElements;
        const expandValue = this.props.expandWith ? this.props.expandWith : 1000;
        shownElements += expandValue;
        this.setState({ takeElements: shownElements });
    }

    hideMore = (e) => {
        e.stopPropagation();

        let shownElements = this.state.takeElements;
        const expandValue = this.props.expandWith ? this.props.expandWith : -1000;
        shownElements += expandValue;
        this.setState({ takeElements: shownElements });
    }

    render = () => {
        //if (this.context.isMobile) return null; //Limited for mobile??? Some "view-switcher" to swap to statistics view??

        const total = React.Children.count(this.props.children);
        const children = total == 1 ? [this.props.children] : this.props.children;

        //@ts-ignore
        const shownElements = _.take(children, this.state.takeElements);
        const shown = shownElements.length;

        const showGrahps = shown < total;
        const hideGraphs = shown >= total && total > 0; /* && total > 3*/

        if (this.context.isMobile) return null;

        else {
            return (
                <React.Fragment>
                    <div className={`statistics-actions`}>
                        <div className="btn-container">
                            <div>
                                {this.props.onExportClick ? <Button type="link" size="middle" onClick={this.props.onExportClick} icon={this.props.exporting ? <LoadingOutlined spin /> : <DownloadOutlined />} className="export">Excel</Button> : null}
                                {showGrahps && total > 0 ? <Button type="link" size="middle" onClick={this.showMore} icon={<BarChartOutlined />}>Show graphs</Button> : null}
                                {hideGraphs && total > 0 ? <Button type="link" size="middle" onClick={this.hideMore} icon={<BarChartOutlined />}>Hide graphs</Button> : null}

                            </div>
                            {this.props.actions ? this.props.actions : null}
                            {/*shown == 0 && !showGrahps && !hideGraphs ? <Button type="link" size="middle" icon={<BarChartOutlined />}>No graphs</Button> : null*/}
                        </div>
                    </div>

                    <div className={`statistics ${this.props.className ? this.props.className : ""} ${hideGraphs ? "active" : ""}`} style={hideGraphs ? {} : { gridGap: 0 }}>
                        {shownElements}
                    </div>
                </React.Fragment>
            );
        }
    }
}