import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import AppContext from "../../Definitions/AppContext";
import { MeetingType, PagedMeetingQuery } from "../../ApiClient/swagger/data-contracts";
import { QuickSearch } from "../Shared/QuickSearch";

interface MeetingQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedMeetingQuery>
}

export default class MeetingQuickSearch extends React.Component<MeetingQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <>
                <QuickSearch name="All"
                    queryParameters={{
                        type: MeetingType.Meeting,
                        isUpcoming: null,
                        startAfter: null,
                        endBefore: null
                    } as Partial<PagedMeetingQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="This week"
                    queryParameters={{
                        type: MeetingType.Meeting,
                        startAfter: moment().startOf('week').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        endBefore: moment().endOf('week').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        isUpcoming: null
                    } as Partial<PagedMeetingQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="This month"
                    queryParameters={{
                        type: MeetingType.Meeting,
                        startAfter: moment().startOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        endBefore: moment().endOf('month').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        isUpcoming: null
                    } as Partial<PagedMeetingQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Today"
                    queryParameters={{
                        type: MeetingType.Meeting,
                        startAfter: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        endBefore: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        isUpcoming: null
                    } as Partial<PagedMeetingQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Upcoming"
                    queryParameters={{
                        type: MeetingType.Meeting,
                        startAfter: null,
                        endBefore: null,
                        isUpcoming: true
                    } as Partial<PagedMeetingQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />
            </>
        );
    }
}

