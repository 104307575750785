import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
    Aggregate,
    MeasurementThreshold,
    MeasurementType,
    MeasurementView,
    SensorEventType,
} from "../../ApiClient/swagger/data-contracts";
import _ from "lodash";
import { getThresholdUnitFromType } from "./MeasuringPointHelpers";
import { Card, Empty, Spin } from "antd";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import AppContext from "../../Definitions/AppContext";
import Boost from "highcharts/modules/boost";

Boost(Highcharts);

type MeasuringPointGraphProps = {
    measuringPointId: string;
    loading: boolean;
    cautionThreshold?: MeasurementThreshold;
    criticalThreshold?: MeasurementThreshold;
    type?: MeasurementType;
    title?: React.ReactNode;
    className?: string;
    data?: Aggregate[];
};

const MeasuringPointGraphBinary = ({
    measuringPointId,
    loading,
    type,
    title,
    className,
    data,
}: MeasuringPointGraphProps) => {
    const chartComponent = useRef(null);
    const context = useContext(AppContext);

    const calculateOffset = (timestamp: string) => {
        const offset = new Date(timestamp).getTimezoneOffset();
        return new Date(timestamp).getTime() - (offset * 60000);
    }

    const series = useMemo(
        () =>
            _.map(data, (m) => {
                if (type?.eventType == SensorEventType.ObjectPresent)
                {
                    //1 - present, 0 - not present.Priority: Not present
                    return m.label ? [calculateOffset(m.label), Number(m.average) < 1 ? 0 : 1] : undefined;
                }
                if (type?.eventType == SensorEventType.Motion) {
                    //1 - detected, 0 - not detected.Priority: Detected
                    return m.label ? [calculateOffset(m.label), Number(m.average) > 0 ? 1 : 0] : undefined;
                }
                if (type?.eventType == SensorEventType.WaterPresent)
                {
                    //1 - present, 0 - not present.Priority: present
                    return m.label ? [calculateOffset(m.label), Number(m.average) > 0 ? 1 : 0] : undefined;
                }
                if (type?.eventType == SensorEventType.DeskOccupancy)
                {
                    //1 - occupied, 0 - not occupied.Priority: occupied
                    return m.label ? [calculateOffset(m.label), Number(m.average) > 0 ? 1 : 0] : undefined;
                }
                return m.label ? [calculateOffset(m.label), Number(m.average) > 0.5 ? 0: 1] : undefined;
            }),
        [data, type?.eventType]
    );

    const onItemUpdatedEvent = useCallback((eventData: unknown) => {
        const measurementEvent = eventData as MeasurementView;
        if (measurementEvent.measuringPoint?.id === measuringPointId && measurementEvent.timestamp) {
            chartComponent?.current?.chart.series[0].addPoint([calculateOffset(measurementEvent.timestamp), measurementEvent.value]);
        }
    }, [measuringPointId]);

    useEffect(() => {
        context.events.measurements.onMany({
            registered: onItemUpdatedEvent,
        });

        return () => {
            context.events.measurements.offMany({
                registered: onItemUpdatedEvent,
            });
        };
    }, [onItemUpdatedEvent, context.events?.measurements]);

    const options: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
        yAxis: {
            title: {
                text: `${type?.eventType} ( ${getThresholdUnitFromType(
                    type
                )} )`,
            },
            opposite: false,
            floor: -0.5,
            max: 1.5,
            labels:
            {
                formatter: function () {
                                                           
                    if (this.value == 0) {
                        if (type?.eventType == SensorEventType.ObjectPresent) 
                            return "Open";
                        if (type?.eventType == SensorEventType.WaterPresent)
                            return "Not present";                            
                        if (type?.eventType == SensorEventType.DeskOccupancy)
                            return "Not occupied";
                        if (type?.eventType == SensorEventType.Motion)
                            return "Not datacted";
                        return "Open";
                    }
                    if (this.value == 1) {
                        if (type?.eventType == SensorEventType.ObjectPresent)
                            return "Closed";
                        if (type?.eventType == SensorEventType.WaterPresent)
                            return "Present";
                        if (type?.eventType == SensorEventType.DeskOccupancy)
                            return "Occupied";
                        if (type?.eventType == SensorEventType.Motion)
                            return "Detected";
                        return "Closed";
                    }
                    return "";
                }
            }
        },
        series: [
            {
                name: type?.eventType,
                type: "line",
                data: series,
                step: "left",                
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: getThresholdUnitFromType(type),
                },
                dataGrouping: {
                    enabled: false,
                }
            },
        ],
    };

    useEffect(() => {
        if (
            chartComponent.current?.chart.xAxis[0] &&
            series !== undefined &&
            series.length > 0
        ) {
            chartComponent.current.chart.xAxis[0].setExtremes(undefined);
        }
    }, [series]);

    return (
        <Card
            title={title}
            className={`graph-card measuring-point-graph ${className}`}
        >
            <Spin spinning={loading}>
                {series !== undefined && series.length > 0 ? (
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType="stockChart"
                        options={options}
                        ref={chartComponent}
                    />
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
        </Card>
    );
};
export default MeasuringPointGraphBinary;
