import * as React from "react";
import _ from 'lodash';
import { ContactPointLink } from "./ContactPointLink";
import { Button } from "antd";
import { CallTargetView, ContactPointView, ParticipantInfoView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Drawer } from "../Shared/Drawer";
import { RequireCapability } from "../Shared/RequireCapability";
import { Capabilities } from "../../Definitions/_capabilties";
import { PersonLink } from "../People/PersonLink";
import ContactPointEditForm from "./ContactPointEditForm";


interface ContactPointPartialLinkProps {
    contactPoint: CallTargetView;
    disablePopover?: boolean;
    hideLabel?: boolean;
    hideContactPointIfActor?: boolean;
    onEditClick?: () => void;
    maxCharacters?: number;
    onCallTargetChanged?: (callTaget: CallTargetView) => void;
}

interface ContactPointPartialLinkState {
    cp: CallTargetView | ContactPointView | ParticipantInfoView;
    drawerVisible: boolean;
}

export class ContactPointPartialLink extends React.Component<ContactPointPartialLinkProps, ContactPointPartialLinkState>{
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);
        this.state = {
            cp: null,
            drawerVisible: false
        };
    }

    componentDidMount = () => {
        this.setState({ cp: this.props.contactPoint });

        //client.contactPoints.onMany({
        //    'updated': this.onItemUpdatedEvent,
        //    'deleted': this.onItemUpdatedEvent,
        //    'restored': this.onItemUpdatedEvent,
        //});
    }

    componentWillUnmount = () => {
        //client.contactPoints.offMany({
        //    'updated': this.onItemUpdatedEvent,
        //    'deleted': this.onItemUpdatedEvent,
        //    'restored': this.onItemUpdatedEvent,
        //});
    }

    onItemUpdatedEvent = (eventData: ContactPointView) => {
        let idTomatch = null;
        if ('contactPoint' in this.state.cp && this.state.cp.contactPoint) {
            idTomatch = this.state.cp.contactPoint.id;
        }
        else if ('id' in this.state.cp && this.state.cp.id) {
            idTomatch = this.state.cp.id;
        }

        if (this.state.cp && idTomatch === eventData.type.toLowerCase()+ ":" + eventData.value || idTomatch === eventData.id) {
            this.setState({
                cp: eventData
            });
        }
        this.props.onCallTargetChanged(eventData);

    }

    onEditClick = () => {
        if (this.props.onEditClick)
            this.props.onEditClick();

        this.setState({ drawerVisible: true });
    }


    render = () => {
        const { drawerVisible, cp } = this.state;

        if (!cp) return null;

        let links = null;
         
        if (cp.contact)
            links = (
                <>
                    <PersonLink {...cp.contact} disablePopover={this.props.disablePopover} />
                    {this.props.hideContactPointIfActor ? null : <ContactPointLink {...cp} prefix=" (" suffix=")" hideLabel disablePopover={this.props.disablePopover} maxCharacters={this.props.maxCharacters} />}
                </>
            );
        else if ('contactPoint' in cp && cp.contactPoint)
            links = (
                <>
                    <ContactPointLink {...cp.contactPoint} hideLabel disablePopover={this.props.disablePopover} maxCharacters={this.props.maxCharacters} />
                    <RequireCapability capability={Capabilities.ContactPointsWrite}>
                        <span>  </span>
                        <Button size="small" className="action" onClick={this.onEditClick}>Add</Button>
                    </RequireCapability>
                </>
            );
        else
            links = (
                <>
                    <ContactPointLink {...cp} hideLabel disablePopover={this.props.disablePopover} maxCharacters={this.props.maxCharacters} />
                    <RequireCapability capability={Capabilities.ContactPointsWrite}>
                        <span>  </span>
                        <Button size="small" className="action" onClick={this.onEditClick}>Add</Button>
                    </RequireCapability>
                </>
            );

        return (
            <React.Fragment>
                <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
                    <Drawer
                        title="Edit contactpoint"
                        onClose={() => { this.setState({ drawerVisible: false }); }}
                        open={drawerVisible}
                        destroyOnClose={true}
                        component={
                            <ContactPointEditForm
                                contactPoint={'contactPoint' in cp && cp.contactPoint ? cp.contactPoint : cp}
                                onComplete={(contactPoint) => {
                                    this.onItemUpdatedEvent(contactPoint) 
                                    this.setState({ drawerVisible: false });
                                }} 
                                onDelete={(contactPoint) => {
                                    this.onItemUpdatedEvent(contactPoint) 
                                    this.setState({ drawerVisible: false });
                                }}
                            />
                        }
                    />
                    {links}
                </span>
            </React.Fragment>
        );
    }
}