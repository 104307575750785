import { Form, Input, Space } from "antd";
import { useEffect, useState } from "react";

const ResourceLocationValueConverter = (props) => {
  const [address, setAddress] = useState(props.getValue());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddress(props.getValue());
  }, [props.getValue]);

  const handleValueChange = async (address) => {
    setLoading(true);

    let addressMap = null;

    if (address) {
      addressMap = {
        name: address.name,
        line1: address.line1,
        line2: address.line2,
        zipCode: address.zipCode,
        area: address.area,
        country: address.country,
        note: address.note,
      };
    }

    await setAddress(addressMap);
    await props.setValue(addressMap);

    setLoading(false);
  };

  const onValueChange = (type, event) => {
    const updatedAddress = { ...address };
    updatedAddress[type] = event.currentTarget.value;
    updatedAddress.type = props.addressType;

    setAddress(updatedAddress);
    props.setValue(updatedAddress);
  };

  return (
    <div className="resource-location-input-container">
      <Input
        placeholder="Address"
        size="middle"
        allowClear
        onChange={(e) => onValueChange("line1", e)}
        value={address ? address.line1 : null}
      />
      <Space.Compact block>
        <Input
          placeholder="ZIP Code"
          size="middle"
          allowClear
          onChange={(e) => onValueChange("zipCode", e)}
          value={address ? address.zipCode : null}
          style={{ width: "40%" }}
        />
        <Input
          placeholder="Area"
          size="middle"
          allowClear
          onChange={(e) => onValueChange("area", e)}
          value={address ? address.area : null}
        />
      </Space.Compact>
    </div>
  );
};

const ResourceLocationInput = (props) => {
  return (
    <Form.Item
      noStyle
      shouldUpdate={(prev, next) => prev[props.param] !== next[props.param]}
    >
      {({ getFieldValue, setFieldsValue }) => (
        <Form.Item label={<div>{props.title}</div>} name={props.param}>
          <ResourceLocationValueConverter
            getValue={() => getFieldValue(props.param)}
            setValue={(value) => {
              const values = {};
              values[props.param] = value;
              setFieldsValue(values);
            }}
            {...props}
          />
        </Form.Item>
      )}
    </Form.Item>
  );
};

export default ResourceLocationInput;
