import { Loading3QuartersOutlined } from "@ant-design/icons";
import Picker from 'emoji-picker-react';
import { Capabilities } from "../../Definitions/_capabilties";
import { CommentLinkView, CommentView, CreateComment, UpdateComment } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { RequireCapability } from "../Shared/RequireCapability";
import Comment from "../Shared/Comment";
import { PersonLink } from "../People";
import { CommentInput, CommentInputAction } from "./CommentInput";
import { EntityCommentClient } from "../../ApiClient/EntityCommentClient";
import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Card, Typography, Avatar, Tooltip, Button, Divider, Empty, Alert } from 'antd';
import { VscReactions } from "react-icons/vsc";
const { Title } = Typography;



interface CommentsCardProps {
    entityId: string,
    readAccess?: boolean;
    writeAccess?: boolean;
    writeCapability?: Capabilities;
    readCapability?: Capabilities;
    className?: string;
    entityCommentClient: EntityCommentClient;
}

interface CommentsCardState {
    comments: CommentView[],
    selected: CommentView,
    loading: boolean,
    loadingAction: boolean,
    error: any,
    replyToId: string,
    replyToReplyId: string,
    showEmojiPicker: boolean;
    currentEmojiCommentId: string;
}

export class CommentsCard extends React.Component<CommentsCardProps, CommentsCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    wrapperRef: React.RefObject<any>;

    constructor(props) {
        super(props)
        this.state = {
            comments: null,
            loading: false,
            loadingAction: false,
            error: null,
            selected: null,
            replyToId: null,
            replyToReplyId: null,
            showEmojiPicker: false,
            currentEmojiCommentId: null,
        }

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount = async () => {
        document.addEventListener("mousedown", this.handleClickOutside);
        this.loadData();

        this.context.events.comments.on("created", this.createdEvent);
        this.context.events.comments.on("updated", this.updatedEvent);
        this.context.events.comments.on("deleted", this.deletedEvent);
    }

    componentWillUnmount = () => {
        document.removeEventListener("mousedown", this.handleClickOutside);

        this.context.events.comments.off("created", this.createdEvent);
        this.context.events.comments.off("updated", this.updatedEvent);
        this.context.events.comments.off("deleted", this.deletedEvent);
    }

    handleClickOutside(event) {
        if (this.state.currentEmojiCommentId && !document.getElementById(this.state.currentEmojiCommentId).contains(event.target)) {
            if (this.state.showEmojiPicker)
                this.setState({ showEmojiPicker: false });
        }
    }

    createdEvent = async (data: CommentView) => {
        if (data.entity.id != this.props.entityId)
            return;

        const stateComment = _.find(this.state.comments, (comment) => { return comment.id == data.id; });
        if (stateComment != null)
            return;

        try {
            const comment = (await this.props.entityCommentClient.getEntityComment(this.props.entityId, data.id))?.data.view;
            if (comment) {
                this.loadData(true);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    updatedEvent = async (data: any) => {
        const stateComment = _.find(this.state.comments, (comment) => { return comment.id == data.id; });

        const index = _.indexOf(this.state.comments, stateComment);
        let comment: CommentView;
        try {
            comment = (await this.props.entityCommentClient.getEntityComment(this.props.entityId, data.id))?.data.view;
        }
        catch (error) {
            this.setState({ error });
            comment = null;
        }

        if (index < 0 && comment.entity.id == this.props.entityId) {
            this.loadData(true);
            return;
        }

        if (comment.replyToId != null) {
            let parent = null;
            let reply = null;

            const comments = this.state.comments.slice();

            _.each(comments, c => {
                _.each(c.replies, r => {
                    if (r.id == comment.id) {
                        parent = c;
                        reply = r;
                    }
                });
            });

            const parentIndex = _.indexOf(this.state.comments, parent);
            const replyIndex = _.indexOf(parent.replies, reply);
            parent.replies[replyIndex] = comment;
            comments[parentIndex] = parent;

            this.setState({ comments });
        }
        else if (comment) {
            const stateComments = this.state.comments.slice();
            stateComments[index] = comment;
            this.setState({ comments: stateComments });
        }
        else {
            const collection = this.state.comments;
            _.remove(collection, function (o) {
                _.remove(o.replies, r => { return r.id == data.id; });
                return o.id == data.id;
            });
            this.setState({ comments: collection });
        }
    }

    deletedEvent = (data: any) => {
        const stateComment = _.find(this.state.comments, (comment) => { return comment.id == data.id; });
        if (stateComment == null)
            return;

        const collection = this.state.comments;
        _.remove(collection, function (o) {
            _.remove(o.replies, r => { return r.id == data.id; });
            return o.id == data.id;
        });

        this.setState({ comments: collection });
    }

    loadData = async (fromEvent?: boolean) => {
        if (!fromEvent)
            this.setState({ loading: true });

        try {
            const response = await this.props.entityCommentClient.queryEntityComments(this.props.entityId, {});

            //let commentReactions: Record<string, Record<string, string[]>> = {};
            //let actors: Record<string, string> = {};
            //if (data && data.items.length > 0) {
            //    await Promise.all(
            //        _.map(data.items, async (comment) => {
            //            let emojiReactions: Record<string, string[]> = {};
            //            if (comment.state.emojis != null) {
            //                await Promise.all(_.map(comment.state.emojis, async (actorIds, key) => {
            //                    var actorNames: string[] = [];
            //                    await Promise.all(_.map(actorIds, async id => {
            //                        if (actors && Object.keys(actors).includes(id)) {
            //                            actorNames.push(actors[id]);
            //                        } else {
            //                            var actorName = id == ActorIds.System ? "System" : "";
            //                            if (!actorName) {
            //                                try {
            //                                    var actor = await client.actors.get(id);
            //                                    actorName = actor.view.name;
            //                                }
            //                                catch {

            //                                }
            //                            }

            //                            if (actorName) {
            //                                actors[id] = actorName;
            //                                actorNames.push(actorName);
            //                            }
            //                        }
            //                    }));
            //                    emojiReactions[key] = actorNames;
            //                }))
            //            }
            //            if (comment.replies, async (reply: CommentLinkView) => {
            //                if (reply.state.emojis != null) {
            //                    await Promise.all(_.map(reply.state.emojis, async (actorIds, key) => {
            //                        var actorNames: string[] = [];
            //                        await Promise.all(_.map(actorIds, async id => {
            //                            if (actors && Object.keys(actors).includes(id)) {
            //                                actorNames.push(actors[id]);
            //                            } else {
            //                                var actorName = id == ActorIds.System ? "System" : "";
            //                                if (!actorName) {
            //                                    try {
            //                                        var actor = await client.actors.get(id);
            //                                        actorName = actor.view.name;
            //                                    }
            //                                    catch {

            //                                    }
            //                                }
            //                                if (actorName) {
            //                                    actors[id] = actorName;
            //                                    actorNames.push(actorName);
            //                                }
            //                            }
            //                        }));
            //                        emojiReactions[key] = actorNames;
            //                    }))
            //                }
            //            })
            //                commentReactions[comment.id] = emojiReactions;
            //        })
            //    );
            //}

            if (response) this.setState({ comments: response.data.items });
        } catch (error) {
            this.setState({ error });
        }

        if (!fromEvent)
            this.setState({ loading: false });
    }

    onEmojiReplyClick = async (emojiCode: string, reply: string) => {
        try {
            await this.props.entityCommentClient.addEntityCommentEmoji(this.props.entityId, reply, emojiCode);
            this.loadData(true);
        } catch (error) {

        }
    }

    onEmojiClick = async (emojiCode: string, commentId: string) => {
        try {
            await this.props.entityCommentClient.addEntityCommentEmoji(this.props.entityId, commentId, emojiCode);
            this.loadData(true);
        }
        catch (error) {

        }
    }

    onNewEmojiClick = (event, emojiObject) => {
        this.setState({ showEmojiPicker: false });
        this.onEmojiClick(emojiObject.emoji, this.state.currentEmojiCommentId);
    }

    onNewEmojiReplyClick = (event, emojiObject) => {
        this.setState({ showEmojiPicker: false });
        this.onEmojiReplyClick(emojiObject.emoji, this.state.currentEmojiCommentId);
    }

    onSelect = async (e, comment) => {
        e.preventDefault();

        if (this.state.selected && this.state.selected.id == comment.id)
            this.setState({ selected: null, replyToId: null });
        else
            this.setState({ selected: comment, replyToId: null });
    }

    onCreate = async (request: CreateComment, topLevel?: boolean) => {
        this.setState({ loadingAction: true, error: null });

        if (!request.actorId)
            request.actorId = this.context.user.actorId;

        if (topLevel)
            request.replyToId = null;
        else if (this.state.replyToReplyId)
            request.replyToId = this.state.replyToReplyId;
        else if (this.state.replyToId)
            request.replyToId = this.state.replyToId;

        const response = await this.props.entityCommentClient.
            createEntityComment(this.props.entityId, request)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            const comments = this.state.comments?.slice() ?? [];
            if (!response.data.replyToId) {
                var comment = response.data as CommentView;

                const exists = _.find(comments, n => n.id == comment.id) != null;
                if (!exists) {
                    comments.unshift(comment);
                    this.setState({ comments });
                }
            } else if (response.data.replyToId) {
                const reply = response.data;
                var comment = _.find(comments, n => n.id == reply.replyToId);
                const index = _.findIndex(comments, n => n.id == comment.id);

                comment.replies.push(reply as CommentLinkView);
                comments.splice(index, 1, comment);
                this.setState({ comments });
            }
        }

        this.setState({
            loadingAction: false,
            replyToId: null,
            replyToReplyId: null
        });
    }

    reply = async (replyToId: string, replyToReplyId?: string) => {
        if (this.state.replyToId == replyToId)
            this.setState({ replyToId: null });
        else
            this.setState({ replyToId, selected: null });

        if (replyToReplyId) {
            if (this.state.replyToReplyId == replyToReplyId)
                this.setState({ replyToReplyId: null });
            else
                this.setState({ replyToReplyId, selected: null });
        }
        else
            this.setState({ replyToReplyId: null });

    }

    onEdit = async (request: UpdateComment) => {
        this.setState({ loadingAction: true, error: null });

        if (!request.actorId)
            request.actorId = this.context.user.actorId;

        const response = await this.props.entityCommentClient
            .updateEntityComment(this.props.entityId, this.state.selected.id, request)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            const comments = this.state.comments?.slice() ?? [];
            const comment = response.data as CommentView;

            const index = _.findIndex(comments, n => n.id == comment.id);
            if (index != -1) {
                comments[index] = comment;
                this.setState({ comments });
            }
        }

        this.setState({ loadingAction: false, selected: null });
    }

    onDelete = async (selected) => {
        this.setState({ loadingAction: true })

        const response = await this.props.entityCommentClient
            .deleteEntityComment(this.props.entityId, selected.id)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            const comments = this.state.comments?.slice() ?? [];
            if (!response.data.replyToId) {
                var comment = response.data as CommentView;

                const index = _.findIndex(comments, n => n.id == comment.id);
                if (index != -1) {
                    comments.splice(index, 1);
                    this.setState({ comments });
                }
            } else if (response.data.replyToId) {
                const reply = response.data;
                var comment = _.find(comments, n => n.id == reply.replyToId);
                const replyIndex = _.findIndex(comment.replies, n => n.id == reply.id);
                const commentIndex = _.findIndex(comments, n => n.id == comment.id);

                comment.replies.splice(replyIndex, 1)
                comments.splice(commentIndex, 1, comment);
                this.setState({ comments });
            }

        }

        this.setState({ loadingAction: false, selected: null });
    }

    renderReply = (reply: CommentLinkView, parentCommentId) => {
        const self = this;
        const isSelected = this.state.selected && this.state.selected.id == reply.id;
        const isReplyTo = this.state.replyToId == reply.id;

        const actions = [
            <span key="comment-list-reply-to-0" onClick={(e) => {
                e.preventDefault();
                self.reply(reply.id, parentCommentId)
            }}>Reply</span>,
            <span key="comment-list-reply-to-0" onClick={(e) => this.onSelect(e, reply)}>{isSelected ? "Close" : "Edit"}</span>,
            <span key="comment-list-reply-to-0" onClick={e => this.onDelete(reply)}>Delete</span>
        ];

        if (reply.reactions && reply.reactions.length > 0) {
            const emojis = {};

            _.forEach(reply.reactions, (reaction => {
                const person = reaction.person;
                const emojiTypes = reaction.types;

                _.forEach(emojiTypes, (emoji => {
                    if (emojis[emoji]) {
                        emojis[emoji].push(person);
                    } else {
                        emojis[emoji] = [person];
                    }
                }));
            }));

            _.forEach(Object.keys(emojis).map((key) => {
                const people = [];
                _.forEach(emojis[key], (person) => {
                    if (person != null) people.push(person);
                });
                actions.push(<>{reply.reactions.length <= 0 ? null :
                    <Tooltip title={people.map(p => p.name).join(", ")}>
                        <div className="emojis">
                            <Button
                                shape="round"
                                size="small"
                                icon={<span style={{ display: "inline" }} >{key}</span>}
                                onClick={() => this.onEmojiReplyClick(key, reply.id)}
                                className={`${people.map(p => p.id).includes(this.context.user.actorId) ? "emoji-included" : ""}`}
                            >{emojis[key]?.length}</Button>
                        </div>
                    </Tooltip>
                }</>);
            }))
        }

        actions.push(
            <div className={`emoji-selector ${this.state.showEmojiPicker && this.state.currentEmojiCommentId == reply.id ? "grid" : ""}`} id={reply.id}>
                <Tooltip title="Add reaction"><VscReactions style={{ width: 20, height: 20 }} onClick={() => this.setState({ showEmojiPicker: !this.state.showEmojiPicker, currentEmojiCommentId: reply.id })} /></Tooltip>
                {this.state.showEmojiPicker && this.state.currentEmojiCommentId == reply.id ?
                    <Picker onEmojiClick={this.onNewEmojiReplyClick} disableSkinTonePicker={true} groupVisibility={{ flags: false }} />
                    : null}
            </div>
        );

        return (
            <div key={reply.id} className="comment-select">
                <Comment
                    actions={actions}
                    author={<PersonLink {...reply.author} />}
                    avatar={
                        <Avatar
                            src={`/api/people/${reply.author.id}/avatar`}
                            alt={reply.author.name}
                        />
                    }
                    content={
                        isSelected ? <CommentInput onSubmit={this.onEdit} action={CommentInputAction.Edit} text={reply.text} loading={this.state.loadingAction} writeAccess={this.props.writeAccess} writeCapability={this.props.writeCapability} /> : <p>{reply.text}</p>
                    }
                    datetime={
                        <Tooltip title={moment(reply.created).format('YYYY-MM-DD HH:mm:ss')}>
                            <span>{moment(reply.created).fromNow()}</span>
                        </Tooltip>
                    }
                />
                {isReplyTo && !isSelected ? <CommentInput className="comment-input-reply" onSubmit={this.onCreate} action={CommentInputAction.Reply} loading={this.state.loadingAction} writeAccess={this.props.writeAccess} writeCapability={this.props.writeCapability} /> : null}
            </div>
        );
    }

    renderComments = (filtered: CommentView[]) => {
        const self = this;

        return _.map(filtered, (comment: CommentView) => {
            const isSelected = this.state.selected && this.state.selected.id == comment.id;
            const isReplyTo = this.state.replyToId == comment.id;

            const actions = [
                <RequireCapability bypass={this.props.writeAccess} capability={this.props.writeCapability}>
                    <span key="comment-list-reply-to-0" onClick={(e) => {
                        e.preventDefault();
                        self.reply(comment.id)
                    }}>Reply</span></RequireCapability>
            ];
            if (this.props.writeAccess === true || (this.props.writeAccess !== false && this.context.user.hasCapability(this.props.writeCapability))) {
                if (this.context.user.hasCapability(Capabilities.FilesWrite) || comment.author.id == this.context.user.actorId) {
                    actions.push(<span key="comment-list-reply-to-0" onClick={(e) => this.onSelect(e, comment)}>{isSelected ? "Close" : "Edit"}</span>);
                    actions.push(<span key="comment-list-reply-to-0" onClick={e => this.onDelete(comment)}>Delete</span>);
                }
            }

            if (comment.reactions && comment.reactions.length > 0) {
                const emojis = {};

                _.forEach(comment.reactions, (reaction => {
                    const person = reaction.person;
                    const emojiTypes = reaction.types;

                    _.forEach(emojiTypes, (emoji => {
                        if (emojis[emoji]) {
                            emojis[emoji].push(person);
                        } else {
                            emojis[emoji] = [person];
                        }
                    }));
                }));

                _.forEach(Object.keys(emojis).map((key) => {
                    const people = [];
                    _.forEach(emojis[key], (person) => {
                        if (person != null) people.push(person);
                    });

                    actions.push(<>{comment.reactions.length <= 0 ? null :
                        <Tooltip title={people.map(p => p.name).join(", ")}>
                            <div className="emojis">
                                <Button
                                    shape="round"
                                    size="small"
                                    icon={<span style={{ display: "inline" }} >{key}</span>}
                                    onClick={() => this.onEmojiClick(key, comment.id)}
                                    className={`${people.map(p => p.id).includes(this.context.user.actorId) ? "emoji-included" : ""}`}
                                >{emojis[key]?.length}</Button>
                            </div>
                        </Tooltip>
                    }</>);
                }))
            }

            actions.push(
                <div className={`emoji-selector ${this.state.showEmojiPicker && this.state.currentEmojiCommentId == comment.id ? "grid" : ""}`} id={comment.id}>
                    <Tooltip title="Add reaction"><VscReactions style={{ width: 20, height: 20 }} onClick={async () => await this.setState({ showEmojiPicker: !this.state.showEmojiPicker, currentEmojiCommentId: comment.id })} /></Tooltip>
                    {this.state.showEmojiPicker && this.state.currentEmojiCommentId == comment.id ?
                        <Picker onEmojiClick={this.onNewEmojiReplyClick} disableSkinTonePicker={true} groupVisibility={{ flags: false }} pickerStyle={{ top: "-350px" }} />
                        : null}
                </div>
            );

            const excludeDeletedReplies = _.reject(comment.replies, r => { return r.deleted; });
            const replies = _.map(excludeDeletedReplies, reply => {
                return self.renderReply(reply, comment.id);
            });

            return (
                <div key={comment.id} className="comment-select">
                    <Comment
                        style={{ padding: 0 }}
                        actions={actions}
                        author={<PersonLink {...comment.author} />}
                        avatar={
                            <Avatar
                                src={`/api/people/${comment.author.id}/avatar`}
                                alt={comment.author.name}
                            />
                        }
                        content={
                            isSelected && !isReplyTo ? <CommentInput onSubmit={this.onEdit} action={CommentInputAction.Edit} text={comment.text} loading={this.state.loadingAction} writeAccess={this.props.writeAccess} writeCapability={this.props.writeCapability} /> : <p>{comment.text}</p>
                        }
                        datetime={ comment.created != null ?
                            <Tooltip title={moment(comment.created).format('YYYY-MM-DD HH:mm:ss')}>
                                <span>{moment(comment.created).fromNow()}</span>
                            </Tooltip> : null
                        }>
                        {isReplyTo && !isSelected ? <CommentInput onSubmit={this.onCreate} action={CommentInputAction.Reply} loading={this.state.loadingAction} writeAccess={this.props.writeAccess} writeCapability={this.props.writeCapability} /> : null}
                        {replies}
                    </Comment>
                </div>
            );
        });
    }

    render = () => {
        const filtered = this.state.comments ? _.reject(this.state.comments, comment => { return comment.replyToId != null }) : [];
        const excludeDeleted = _.reject(filtered, f => { return f.deleted; });
        const comments = excludeDeleted ? this.renderComments(excludeDeleted) : [];
        const errorAlert = this.state.error ? <Alert type="warning" message={this.state.error} closable showIcon className="warning-text" /> : null;
        return (
            <React.Fragment>
                <Card title={
                    <div>
                        <Title level={4} className="title">Comments</Title>
                    </div>}
                    className={`comments-card ${this.props.className ? this.props.className : ''}`}>
                    <RequireCapability bypass={this.props.readAccess} capability={this.props.readCapability}>
                        <div className="comments">
                            {this.state.loading ? <Loading3QuartersOutlined spin /> : comments}
                        </div>
                        {comments && comments.length > 0 ? null : this.state.loading ? null : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        <RequireCapability bypass={this.props.writeAccess} capability={this.props.writeCapability}>
                            <Divider />
                            <CommentInput onSubmit={this.onCreate} action={CommentInputAction.Create} loading={this.state.loadingAction} writeAccess={this.props.writeAccess} writeCapability={this.props.writeCapability} />
                            {errorAlert}
                        </RequireCapability>
                    </RequireCapability>

                </Card>
            </React.Fragment>
        );
    }
}

export default CommentsCard;