/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateInvoice,
  CreateInvoiceline,
  CreateNote,
  InvoiceLineAggregationTypes,
  InvoiceSortOption,
  InvoiceView,
  InvoiceViewEntityViewModel,
  InvoiceViewPagedInvoiceQueryCollectionViewModel,
  InvoicelineAggregationResultInvoicelineLineAggregateQueryAggregationResults,
  InvoicelineSortOption,
  InvoicelineView,
  InvoicelineViewEntityViewModel,
  InvoicelineViewPagedInvoicelineQueryCollectionViewModel,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  RevenueType,
  SortDirection,
  UpdateInvoice,
  UpdateInvoiceline,
  UpdateNote,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Invoices<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Invoice
   * @name GetInvoiceById
   * @request GET:/api/invoices/{id}
   * @secure
   */
  getInvoiceById = (id: string, params: RequestParams = {}) =>
    this.http.request<InvoiceViewEntityViewModel, any>({
      path: `/api/invoices/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name UpdateInvoice
   * @request PUT:/api/invoices/{id}
   * @secure
   */
  updateInvoice = (id: string, data: UpdateInvoice, params: RequestParams = {}) =>
    this.http.request<InvoiceView, any>({
      path: `/api/invoices/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name DeleteInvoice
   * @request DELETE:/api/invoices/{id}
   * @secure
   */
  deleteInvoice = (id: string, params: RequestParams = {}) =>
    this.http.request<InvoiceView, any>({
      path: `/api/invoices/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name CreateInvoice
   * @request POST:/api/invoices
   * @secure
   */
  createInvoice = (data: CreateInvoice, params: RequestParams = {}) =>
    this.http.request<InvoiceView, any>({
      path: `/api/invoices`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name QueryInvoices
   * @request GET:/api/invoices
   * @secure
   */
  queryInvoices = (
    query?: {
      sortBy?: InvoiceSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      customerId?: string;
      invoiceNo?: string;
      orderId?: string;
      projectId?: string;
      /** @format double */
      minAmount?: number;
      /** @format double */
      maxAmount?: number;
      /** @format date-time */
      after?: string;
      /** @format date-time */
      before?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InvoiceViewPagedInvoiceQueryCollectionViewModel, any>({
      path: `/api/invoices`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name RestoreInvoice
   * @request POST:/api/invoices/{id}/restore
   * @secure
   */
  restoreInvoice = (id: string, params: RequestParams = {}) =>
    this.http.request<InvoiceView, any>({
      path: `/api/invoices/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name UpdateInvoiceAttributes
   * @request POST:/api/invoices/{id}/attributes
   * @secure
   */
  updateInvoiceAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<InvoiceView, any>({
      path: `/api/invoices/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name GetInvoicelineById
   * @request GET:/api/invoices/{invoiceId}/invoicelines/{invoicelineId}
   * @secure
   */
  getInvoicelineById = (invoiceId: string, invoicelineId: string, params: RequestParams = {}) =>
    this.http.request<InvoicelineViewEntityViewModel, any>({
      path: `/api/invoices/${invoiceId}/invoicelines/${invoicelineId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name UpdateInvoiceline
   * @request PUT:/api/invoices/{invoiceId}/invoicelines/{invoicelineId}
   * @secure
   */
  updateInvoiceline = (invoiceId: string, invoicelineId: string, data: UpdateInvoiceline, params: RequestParams = {}) =>
    this.http.request<InvoicelineView, any>({
      path: `/api/invoices/${invoiceId}/invoicelines/${invoicelineId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name DeleteInvoiceline
   * @request DELETE:/api/invoices/{invoiceId}/invoicelines/{invoicelineId}
   * @secure
   */
  deleteInvoiceline = (invoiceId: string, invoicelineId: string, params: RequestParams = {}) =>
    this.http.request<InvoicelineView, any>({
      path: `/api/invoices/${invoiceId}/invoicelines/${invoicelineId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name QueryInvoicelines
   * @request GET:/api/invoices/_all/invoicelines
   * @secure
   */
  queryInvoicelines = (
    query?: {
      sortBy?: InvoicelineSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      invoiceNo?: string;
      invoiceId?: string;
      /** @format double */
      minAmount?: number;
      /** @format double */
      maxAmount?: number;
      customerId?: string;
      projectId?: string;
      productId?: string;
      productCategoryIds?: string[];
      revenueTypes?: RevenueType[];
      /** @format date-time */
      issuedAfter?: string;
      /** @format date-time */
      issuedBefore?: string;
      deletedInvoice?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InvoicelineViewPagedInvoicelineQueryCollectionViewModel, any>({
      path: `/api/invoices/_all/invoicelines`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name CreateInvoiceline
   * @request POST:/api/invoices/{invoiceId}/invoicelines
   * @secure
   */
  createInvoiceline = (invoiceId: string, data: CreateInvoiceline, params: RequestParams = {}) =>
    this.http.request<InvoicelineView, any>({
      path: `/api/invoices/${invoiceId}/invoicelines`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name RestoreInvoiceline
   * @request POST:/api/invoices/{invoiceId}/invoicelines/{invoicelineId}/restore
   * @secure
   */
  restoreInvoiceline = (invoiceId: string, invoicelineId: string, params: RequestParams = {}) =>
    this.http.request<InvoicelineView, any>({
      path: `/api/invoices/${invoiceId}/invoicelines/${invoicelineId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name InvoicesInvoicelinesAttributesCreate
   * @request POST:/api/invoices/{invoiceId}/invoicelines/{invoicelineId}/attributes
   * @secure
   */
  invoicesInvoicelinesAttributesCreate = (
    invoiceId: string,
    invoicelineId: string,
    data: Record<string, string>,
    params: RequestParams = {},
  ) =>
    this.http.request<void, any>({
      path: `/api/invoices/${invoiceId}/invoicelines/${invoicelineId}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name GetInvoiceDocumentById
   * @request GET:/api/invoices/{id}/document
   * @secure
   */
  getInvoiceDocumentById = (
    id: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/invoices/${id}/document`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name UpdateInvoiceDocument
   * @request POST:/api/invoices/{id}/document
   * @secure
   */
  updateInvoiceDocument = (
    id: string,
    data: {
      /** @format binary */
      file: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InvoiceView, any>({
      path: `/api/invoices/${id}/document`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name DeleteInvoiceDocument
   * @request DELETE:/api/invoices/{id}/document
   * @secure
   */
  deleteInvoiceDocument = (id: string, params: RequestParams = {}) =>
    this.http.request<InvoiceView, any>({
      path: `/api/invoices/${id}/document`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name GetInvoicesAggregates
   * @request GET:/aggs/invoices
   * @secure
   */
  getInvoicesAggregates = (
    query?: {
      aggs?: InvoiceLineAggregationTypes[];
      /** @format int32 */
      compareRevenue?: number;
      /** @format int32 */
      compareCustomers?: number;
      /** @format int32 */
      compareProducts?: number;
      invoiceNo?: string;
      invoiceId?: string;
      /** @format double */
      minAmount?: number;
      /** @format double */
      maxAmount?: number;
      customerId?: string;
      projectId?: string;
      productId?: string;
      productCategoryIds?: string[];
      revenueTypes?: RevenueType[];
      /** @format date-time */
      issuedAfter?: string;
      /** @format date-time */
      issuedBefore?: string;
      deletedInvoice?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<InvoicelineAggregationResultInvoicelineLineAggregateQueryAggregationResults, any>({
      path: `/aggs/invoices`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoice
   * @name GetInvoiceAggregatesById
   * @request GET:/aggs/invoices/{id}
   * @secure
   */
  getInvoiceAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/invoices/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InvoiceNote
   * @name GetInvoiceNoteById
   * @request GET:/api/invoices/{entityId}/notes/{noteId}
   * @secure
   */
  getInvoiceNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/invoices/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InvoiceNote
   * @name UpdateInvoiceNote
   * @request PUT:/api/invoices/{entityId}/notes/{noteId}
   * @secure
   */
  updateInvoiceNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/invoices/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InvoiceNote
   * @name DeleteInvoiceNote
   * @request DELETE:/api/invoices/{entityId}/notes/{noteId}
   * @secure
   */
  deleteInvoiceNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/invoices/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InvoiceNote
   * @name CreateInvoiceNote
   * @request POST:/api/invoices/{entityId}/notes
   * @secure
   */
  createInvoiceNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/invoices/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InvoiceNote
   * @name QueryInvoiceNotes
   * @request GET:/api/invoices/{entityId}/notes
   * @secure
   */
  queryInvoiceNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/invoices/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags InvoiceNote
   * @name RestoreInvoiceNote
   * @request POST:/api/invoices/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreInvoiceNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/invoices/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
