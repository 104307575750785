import * as React from "react";
import _ from 'lodash';
import { Alert } from "antd";
import { ResumeOrUpdateSubscriptionLine, SubscriptionLineHistoryView, SubscriptionLineView, SubscriptionView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { NumberInput } from "../Shared/NumberInput";
import { PercentageInput } from "../Shared/PercentageInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { findLatestFutureLine } from "./SubscriptionHelper";

interface SubscriptionLineUpdateFormProps {
    onComplete: (updated: SubscriptionLineView) => void;
    onCancel?: () => void;
    subscription: SubscriptionView;
    line: SubscriptionLineHistoryView;
    hideContent?: boolean;
}

interface SubscriptionLineUpdateFormState {
    loading: boolean;
    error: string;
    application: string;
}

export class SubscriptionLineUpdateForm extends React.Component<SubscriptionLineUpdateFormProps, SubscriptionLineUpdateFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            application: null
        }
    }

    onSubmit = async (request: ResumeOrUpdateSubscriptionLine) => {
        this.setState({ loading: true });

        if (this.props.line) {
            const response = await client.subscriptions
                .resumeOrUpdateSubscriptionLine(this.props.subscription.id, this.props.line.lineId, request, addChangeVectorHeader(this.props.subscription.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }

        this.setState({ loading: false });
    }

    onApplicationChange = (application) => {
        this.setState({ application });
    }

    render() {
        const future = findLatestFutureLine(this.props.line);
        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                className={`sub-update-form ${this.props.hideContent ? "hideContent" : ""}`}
                initialValues={{
                    application: future ? future.start : this.props.line.start,
                    quantity: this.props.line != null ? this.props.line.quantity : null,
                    discount: this.props.line != null ? this.props.line.discount : null,
                    note: this.props.line != null ? this.props.line.note : null,
                    internalNote: this.props.line != null ? this.props.line.internalNote : null
                }}
            >
                <SelectorInput
                    param="application"
                    required
                    warningMessage="Please choose a change date"
                    title="Change date"
                    selector={
                        <DateSelector
                            onChange={this.onApplicationChange}
                        />
                    }
                />

                <NumberInput
                    param="quantity"
                    title="Quantity"
                    required
                    warningMessage="Please fill in the quantity"
                />

                <PercentageInput
                    param="discount"
                    title="Discount"
                />

                <TextAreaInput
                    title="Invoice note"
                    param="note"
                />

                <TextAreaInput
                    title="Internal note"
                    param="internalNote"
                />

                {
                    this.state.application != null && this.state.application != this.props.line.start
                        ? <Alert type="info" message="This will affect the last end date" showIcon className="affect-warning" />
                        : null
                }
            </BaseForm>
        );
    }
}

export default SubscriptionLineUpdateForm;