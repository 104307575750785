import { LoadingOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import _ from 'lodash';
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import client from "../../ApiClient/client";
import { ActivitySortOption, ActivityType, ActivityView, SortDirection } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorIds } from "../../Definitions/_definitions";
import ActivityCreateForm from "../../Modules/Activities/ActivityCreateForm";
import ActivityUpdateForm from "../../Modules/Activities/ActivityUpdateForm";
import CountUpDuration from "../../Modules/Shared/CountUpDuration";
import { Drawer } from "../../Modules/Shared/Drawer";
import { RiStopCircleLine } from 'react-icons/ri'
import eventEmitter from "../../Models/EventEmitter";

export default function ActivityHeaderDropdown() {

    const [error, setError] = useState<string>();

    const [loadRunning, setLoadRunning] = useState<boolean>(false);
    const [showStopActivityDrawer, setShowStopActivityDrawer] = useState<boolean>(false);
    const [showStartActivityDrawer, setShowStartActivityDrawer] = useState<boolean>(false);
    const [runningActivity, setRunningActivity] = useState<ActivityView>();

    const context = React.useContext(AppContext);

    const onActivityEvent = useCallback((activity: ActivityView) => {
        if (runningActivity?.id == activity.id) {
            if (runningActivity.end == null && activity.end != null)
                setRunningActivity(null);
            else if (runningActivity.end == null && activity.end == null) //Replace the "fake activity" from activity form
                setRunningActivity(activity);
        }

        if (activity?.actor.id == context.user.actorId) {
            if (activity.end == null) {
                setRunningActivity(activity);
            }
        }
    }, [context.user.actorId, runningActivity]);

    const onDeletedActivityEvent = useCallback((activity: ActivityView) => {
        if (activity.actor?.id === context.user.actorId) {
            if (runningActivity && runningActivity.id === activity.id) {
                setRunningActivity(null);
            }
        }
    }, [context.user.actorId, runningActivity]);

    useEffect(() => {
        context.events.activities.onMany({
            "created": onActivityEvent,
            "updated": onActivityEvent,
            "deleted": onDeletedActivityEvent,
            "restored": onActivityEvent
        });

        return () => {
            context.events.activities.offMany({
                "created": onActivityEvent,
                "updated": onActivityEvent,
                "deleted": onDeletedActivityEvent,
                "restored": onActivityEvent
            });
        }
    }, [context.events.activities, onActivityEvent, onDeletedActivityEvent]);

    useEffect(() => {
        loadRunningActivities();
    }, []);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (showStartActivityDrawer || showStopActivityDrawer) {
                e.stopImmediatePropagation();
            }
        }
        eventEmitter.on('prevent-header-keydown', handleKeyDown);

        return () => {
            eventEmitter.off('prevent-header-keydown', handleKeyDown);
        };
    }, [showStartActivityDrawer, showStopActivityDrawer]);

    async function loadRunningActivities() {
        setLoadRunning(true);

        const response = await client.activities.queryActivities({
            actorId: context.user.actorId,
            running: true,
            deleted: false,
            sortBy: ActivitySortOption.End,
            sortDirection: SortDirection.Desc,
            from: 0,
            limit: 5
        }).catch(exception => setError(exception.error));

        if (response) {
            const currentlyRunning = _.find(response.data.items || [], a => a.end == null); //Find first thats running

            setRunningActivity(currentlyRunning);
        }
        setLoadRunning(false);
    }

    async function toggleStopActivityForm() {
        setShowStopActivityDrawer((current) => !current);
    }

    function onCloseActivityForm(created?) {
        if (created && runningActivity?.id == created.id) {
            setRunningActivity(null);
            setShowStopActivityDrawer(false);
        }
        else {
            setShowStopActivityDrawer(false);
        }
    }

    async function toggleStartActivityForm() {
        setShowStartActivityDrawer((current) => !current);
    }

    function onStartActivityCompleted(fakeActivity: any) {
        setRunningActivity(fakeActivity);
        setShowStartActivityDrawer(false)
    }

    let trackActivityContent = null;

    if (context.user.hasCapability(Capabilities.OtherTracking)) {
        if (runningActivity != null) {

            const trackingOrgName = runningActivity.type == ActivityType.Customer || runningActivity.type == ActivityType.Project ? runningActivity.relation ? runningActivity.relation.name : null : null;
            const trackingTaskName = runningActivity.type == ActivityType.Project && runningActivity.task ? runningActivity.task.name : null;
            const trackingCategoryName = runningActivity.category ? runningActivity.category.name : null;

            trackActivityContent = (
                <div className="stop-tracking-container">
                    <div className="stop-tracking-content">
                        <div className="stop-tracking-description">
                            <Tooltip title={trackingTaskName ?? trackingCategoryName}><span className="description">{trackingOrgName ?? null}{trackingOrgName ? " / " : null}{trackingTaskName ?? trackingCategoryName}</span></Tooltip>
                            <span className="counter"><CountUpDuration start={runningActivity.start} /></span>
                        </div>
                        <div className="stop-tracking-action"><RiStopCircleLine style={{ color: "#ff4d4f", width: "35", height: "35" }} onClick={toggleStopActivityForm} /></div>
                    </div>
                </div>
            );
        }
        else if (loadRunning) {
            trackActivityContent = <LoadingOutlined className="activity-tracking" />
        }
        else {
            trackActivityContent = <PlayCircleOutlined onClick={runningActivity != null ? null : toggleStartActivityForm} className="activity-tracking" />
        }
    }

    return (
        <div>
            {context.user.actorId != ActorIds.System &&
                trackActivityContent != null ? (
                <div className="action-container">{trackActivityContent}</div>
            ) : null}

            {runningActivity != null ? (
                <Drawer
                    title="Stop tracking"
                    closable
                    onClose={toggleStopActivityForm}
                    open={showStopActivityDrawer}
                    component={
                        <ActivityUpdateForm
                            activity={runningActivity ?? null}
                            actorId={context.user.actorId}
                            onClose={onCloseActivityForm}
                        />
                    }
                    destroyOnClose
                />
            ) : null}

            <Drawer
                title="Start tracking"
                closable
                onClose={toggleStartActivityForm}
                open={showStartActivityDrawer}
                component={
                    <ActivityCreateForm
                        activityMolds={[
                            {
                                start: null,
                                end: null,
                            },
                        ]}
                        actorId={context.user.actorId}
                        onClose={toggleStartActivityForm}
                        onComplete={onStartActivityCompleted}
                        trackingForm
                    />
                }
                destroyOnClose
            />
        </div>
    );
}