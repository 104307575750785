import * as React from "react";
import _ from 'lodash';
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CreateOrUpdateExecution, PersonSortOption, RoutineExecutionView, RoutineView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { PersonSelector } from "../People";
import { Capabilities } from "../../Definitions/_capabilties";
import DateSelector from "../Shared/DateSelector";
import { TextAreaInput } from "../Shared/TextAreaInput";
import client from "../../ApiClient/client";

interface PlanExecutionProps {
    routine: RoutineView;
    onCancel?: () => void;
    onComplete?: (updated: RoutineExecutionView) => void;
}

interface PlanExecutionState {
    loading: boolean;
    error: string;
    allChecked: boolean;
}

export class RoutinePlanExecutionForm extends React.Component<PlanExecutionProps, PlanExecutionState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            allChecked: false,
        }
    }

    onSubmit = async (request: CreateOrUpdateExecution) => {
        this.setState({ loading: true });

        if (this.state.allChecked) {
            request.responsibleIds = null;
            request.allEmployees = true;
        }

        request.executionType = null;
        request.plannedExecutionDate = request.plannedExecutionDate;

        const response = await client.routines.createExecution(this.props.routine.id, request).catch(exception => this.setState({ error: exception.error.title }));
        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    onAllCheck = (event: CheckboxChangeEvent) => {
        this.setState({ allChecked: event.target.checked });
    }

    render = () => {
        const { user } = this.context;

        return (
            <>
                <BaseForm
                    type={FormType.Create}
                    onSubmit={this.onSubmit}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={{
                        responsibleIds: _.map(this.props.routine?.responsible ?? [], r => r.id)
                    }}
                    onCancel={this.props.onCancel}
                >
                    <SelectorInput
                        param="responsibleIds"
                        title="Responsible"
                        titleLink={<Checkbox onChange={this.onAllCheck} checked={this.state.allChecked}>All employees</Checkbox>}
                        className="responsibles-input"
                        selector={
                            <PersonSelector
                                filters={{
                                    isEmployee: true,
                                    sortBy: PersonSortOption.Name
                                }}
                                placeholder="Select responsible employees"
                                disabled={!user.hasCapability(Capabilities.RoutinesWriteRelated) || this.state.allChecked}
                                multiple
                            />
                        }
                        required
                    />

                    <SelectorInput
                        key="plannedExecutionDate"
                        param="plannedExecutionDate"
                        title="Planned execution date"
                        required
                        warningMessage="Please choose a execution date"
                        selector={<DateSelector />}
                    />

                    <TextAreaInput
                        title="Note"
                        param="note"
                        placeholder="Write here"
                        rows={2}
                    />
                </BaseForm>

            </>
        );
    }
}

export default RoutinePlanExecutionForm;