import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Radio, Space, RadioChangeEvent } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { ActorView, CallView, ContactPointType, ContactPointView, CreateOrUpdateCall } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { createGuid } from "../../Helpers/BasePageHelpers";
import { ActorIds } from "../../Definitions/_definitions";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import BaseForm, { FormType } from "../Shared/Form";
import { ContactPointAutoComplete } from "../ContactPoints";
import CallMissedReasonSelector from "./CallMissedReasonSelector";
import UserProfile from "../../Definitions/UserProfile";


interface CreateCallProps {
    onCancel?: () => void,
    onComplete: (created: CallView) => void;
    initialValues?: Partial<CreateOrUpdateCall>;
    fromOrToActor?: ActorView;
}

interface CreateCallState {
    loading: boolean;
    error: string;
    contactPointFrom: ContactPointView;
    contactPointTo: ContactPointView;
    start: string;
    end: string;
    fromAndToValue: "selfFrom" | "selfTo",
    //user: UserProfile;
    loadingUser: boolean;
}

interface CallCreateRequest extends CreateOrUpdateCall {
    note: string;
}

class CallCreateForm extends React.Component<CreateCallProps, CreateCallState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            contactPointFrom: null,
            contactPointTo: null,
            start: moment().subtract(5, "minutes").format(),
            end: moment().format(),
            fromAndToValue: null,
            //user: this.context.user,
            loadingUser: false
        }
    }

    componentDidMount = () => {
        //this.loadUser();
    }

    //loadUser = async () => {
    //    this.setState({ loadingUser: true });

    //    if (this.context.user?.actorId && this.context.user.actorId != ActorIds.System && this.context.user.hasAnyCapability([Capabilities.PeopleReadRelated, Capabilities.ProfileRead])) {
    //        var response = await client.actors
    //            .getActorById(this.context.user.actorId)
    //            .catch(exception => this.setState({ error: exception.error.title }));

    //        this.setState({ user: this.context.user });
    //    }

    //    this.setState({ loadingUser: false });
    //}

    onSubmit = async (request: CallCreateRequest) => {
        // if (!this.state.contactPointFrom || !this.state.contactPointTo) return;

        this.setState({ loading: true });

        request.reference = createGuid();
        if (this.state.contactPointFrom)
            request.from = this.state.contactPointFrom.value;
        if (this.state.contactPointTo)
            request.to = this.state.contactPointTo.value;

        const ignoredByIds = [];

        if (this.state.contactPointFrom && this.state.contactPointFrom.contact)
            ignoredByIds.push(this.state.contactPointFrom.contact.id);

        if (this.state.contactPointTo && this.state.contactPointTo.contact)
            ignoredByIds.push(this.state.contactPointTo.contact.id);

       //request.ignoredByIds = ignoredByIds;

        const response = await client.calls.createCall(request).catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            if (request.note) {
                const noteResponse = await client.calls.addOrUpdateCallNote(
                    response.data.id,
                    {
                        note: request.note
                    },
                    addChangeVectorHeader(response.data.changeVector));

                this.props.onComplete(noteResponse.data);
            }
            else {
                this.props.onComplete(response.data);
            }
        }


        this.setState({ loading: false });
    }

    onCPFromChange = (contactPointFrom) => {
        this.setState({ contactPointFrom, fromAndToValue: null });
    }

    onCPToChange = (contactPointTo) => {
        this.setState({ contactPointTo, fromAndToValue: null });
    }

    getActorCP = (actor: UserProfile | ActorView) => {
        if (!actor || actor.contactPoints?.length == 0) return null;

        const phone = _.find(actor.contactPoints || [], cp => cp.type == ContactPointType.Phone);
        const video = _.find(actor.contactPoints || [], cp => cp.type == ContactPointType.Video);
        const email = _.find(actor.contactPoints || [], cp => cp.type == ContactPointType.Email);
        const other = _.find(actor.contactPoints || [], cp => cp.type == ContactPointType.Other);

        const cp = phone ? phone : video ? video : email ? email : other ? other : null;

        const CPView: ContactPointView = cp != null ? {
            id: cp.id,
            contact: actor,
            description: cp.description,
            type: cp.type,
            value: cp.value
        } : null;

        return CPView;
    }

    onFromToChange = (e: RadioChangeEvent) => {
        const value = e.target.value;

        const { fromOrToActor } = this.props;

        const userCP = this.getActorCP(this.context.user);
        const fromOrToCP = this.getActorCP(fromOrToActor);

        const state = {
            fromAndToValue: value,
            contactPointFrom: null,
            contactPointTo: null
        };

        if (value == "selfFrom") {
            state.contactPointFrom = userCP;
            state.contactPointTo = fromOrToCP;
        }
        else if (value == "selfTo") {
            state.contactPointFrom = fromOrToCP;
            state.contactPointTo = userCP;
        }

        this.setState(state);
    }

    render = () => {
        const { fromAndToValue } = this.state;
        const { fromOrToActor } = this.props;

        const defaultInitialValues: Partial<CreateOrUpdateCall> = {
            start: this.state.start,
            end: this.state.end
        };

        const userCP = this.getActorCP(this.context.user);
        const fromOrToCP = this.getActorCP(fromOrToActor);

        if (fromAndToValue == "selfFrom") {
            defaultInitialValues.from = userCP?.value ?? null;
            defaultInitialValues.to = fromOrToCP?.value ?? null;
        }
        else if (fromAndToValue == "selfTo") {
            defaultInitialValues.from = fromOrToCP?.value ?? null;
            defaultInitialValues.to = userCP?.value ?? null;
        }

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={Object.assign({}, defaultInitialValues, this.props.initialValues ?? {})}
            >
                {this.context.user.actorId != ActorIds.System ?
                    <Radio.Group className="subscription-line-radiogroup" onChange={this.onFromToChange} value={this.state.fromAndToValue}>
                        <Space direction="vertical">
                            <Radio value="selfFrom" className="subscription-line-radio-item">
                                {this.state.loadingUser
                                    ? <Loading3QuartersOutlined spin />
                                    :
                                    <React.Fragment>
                                        <div>From: {this.context.user?.name ?? ""}</div>
                                        {fromOrToActor ? <div>To: {fromOrToActor.name}</div> : null}
                                    </React.Fragment>
                                }

                            </Radio>
                            <Radio value="selfTo" className="subscription-line-radio-item">
                                {this.state.loadingUser
                                    ? <Loading3QuartersOutlined spin />
                                    :
                                    <React.Fragment>
                                        {fromOrToActor ? <div>From: {fromOrToActor.name}</div> : null}
                                        <div>To: {this.context.user.name ?? ""}</div>
                                    </React.Fragment>
                                }
                            </Radio>
                        </Space>
                    </Radio.Group> : null}

                <SelectorInput
                    title="From"
                    param="from"
                    required
                    selector={
                        <ContactPointAutoComplete
                            placeholder="Please type a value"
                            filters={{
                                types: [ContactPointType.Phone, ContactPointType.Email]
                            }}
                            onObjectChange={this.onCPFromChange}
                            value={this.state.contactPointFrom?.value}
                        />
                    }
                />

                <SelectorInput
                    title="To"
                    param="to"
                    required
                    selector={
                        <ContactPointAutoComplete
                            placeholder="Please type a value"
                            filters={{
                                types: [ContactPointType.Phone, ContactPointType.Email]
                            }}
                            onObjectChange={this.onCPToChange}
                            value={this.state.contactPointTo?.value}
                        />
                    }
                />

                <SelectorInput
                    title="Start"
                    param="start"
                    selector={
                        <DateSelector showTime onChange={start => this.setState({ start })} />
                    }
                />

                <SelectorInput
                    title="End"
                    param="end"
                    selector={
                        <DateSelector showTime onChange={end => this.setState({ end })} />
                    }
                />

                {this.state.start && this.state.end && this.state.start == this.state.end ?
                    <SelectorInput
                        title="Missed reason"
                        param="missedReason"
                        selector={<CallMissedReasonSelector />}
                    /> : null
                }

                <TextAreaInput
                    param="note"
                    placeholder="Note..."
                    title="Note"
                />
            </BaseForm>
        );
    }
}

export default CallCreateForm;