import _ from "lodash";
import moment from "moment";
import client from "../client";
import { ContactPointType, PersonView, PagedPersonQuery, OrganizationRoleView } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig } from "./BaseExportService";

export class PersonExport extends BaseExportService<PagedPersonQuery, PersonView> {

    constructor() {

        const specification: ExportConfig<PersonView>[] = [
            { header: 'Name', value: (data: PersonView) => data.name ?? null },
            { header: 'Description', value: (data: PersonView) => data.description ?? null },
            {
                header: 'Phone', value: (data: PersonView) => {
                    let contactPoints = "";
                    _.map(data.contactPoints, (cp) => {
                        if (cp.type == ContactPointType.Phone) {
                            if (contactPoints && contactPoints.length > 0) contactPoints = contactPoints + "/";
                            contactPoints = contactPoints + cp.value;
                        }
                    })
                    return contactPoints;
                }
            },
            {
                header: 'Email', value: (data: PersonView) => {
                    let contactPoints = "";
                    _.map(data.contactPoints, (cp) => {
                        if (cp.type == ContactPointType.Email) {
                            if (contactPoints && contactPoints.length > 0) contactPoints = contactPoints + "/";
                            contactPoints = contactPoints + cp.value;
                        }
                    })
                    return contactPoints;
                }
            },
            {
                header: 'Video', value: (data: PersonView) => {
                    let contactPoints = "";
                    _.map(data.contactPoints, (cp) => {
                        if (cp.type == ContactPointType.Video) {
                            if (contactPoints && contactPoints.length > 0) contactPoints = contactPoints + "/";
                            contactPoints = contactPoints + cp.value;
                        }
                    })
                    return contactPoints;
                }
            },
            {
                header: 'Other', value: (data: PersonView) => {
                    let contactPoints = "";
                    _.map(data.contactPoints, (cp) => {
                        if (cp.type == ContactPointType.Other) {
                            if (contactPoints && contactPoints.length > 0) contactPoints = contactPoints + "/";
                            contactPoints = contactPoints + cp.value;
                        }
                    })
                    return contactPoints;
                }
            },
            {
                header: 'Tags', value: (data: PersonView) => {
                    let tags = "";
                    _.map(data.tags, (tag, index) => {
                        if (index != 0) tags = tags + ", ";
                        tags = tags + tag.name;
                    })
                    return tags;
                }
            },
            {
                header: 'Organizations', value: (data: PersonView) => {
                    let organizations = "";
                    _.map(data.organizationRoles, (or: OrganizationRoleView, index) => {
                        if (index != 0) organizations = organizations + ", ";
                        organizations = organizations + or.organization.name;
                    })
                    return organizations;
                }
            },
            { header: 'Last order', value: (data: PersonView) => data.metadata && data.metadata.lastOrder ? moment(data.metadata.lastOrder).format() : null },
            { header: 'Last call', value: (data: PersonView) => data.metadata && data.metadata.lastCall ? moment(data.metadata.lastCall).format() : null },
            { header: 'Last modified', value: (data: PersonView) => data.lastModified ? moment(data.lastModified).format() : null },
            { header: 'Deleted', value: (data: PersonView) => data.deleted ? true : false }
        ];

        super(specification, client.people.queryPeople);
    }
}
