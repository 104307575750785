import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import AppContext from "../../Definitions/AppContext";
import { IncidentView } from "../../ApiClient/swagger/data-contracts";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Input } from "antd";
import Title from "antd/es/typography/Title";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";

interface IncidentFinancialProps {
    incident: IncidentView;
    className?: string;
    writeAccess?: boolean;
    onFCActions: (incident: IncidentView) => void;
}

interface IncidentFinancialState {
    sum: number,
    comment: string,
    conflict: boolean,
    loading: boolean,
    error?: any
}

export default class IncidentFinancialConsequences extends React.Component<IncidentFinancialProps, IncidentFinancialState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            sum: this.props.incident ? this.props.incident.financialCost : 0,
            comment: this.props.incident ? this.props.incident.costComment : null,
            loading: false,
            conflict: false,
        }
    }

    componentDidUpdate = (prevProps: IncidentFinancialProps) => {
        if (!this.props.incident || prevProps == this.props) return;

        const newSumValue = this.props.incident.financialCost || 0;
        const newCommentValue = this.props.incident.costComment || "";

        const olderSumValue = prevProps.incident ? prevProps.incident.financialCost || 0 : 0;
        const olderCommentValue = prevProps.incident ? prevProps.incident.costComment || "" : "";

        const editedSumValue = this.state.sum || 0;
        const editedCommentValue = this.state.comment || "";

        if (newSumValue === 0 || newSumValue == editedSumValue) {
            if (this.state.conflict)
                this.setState({ conflict: false });

            this.setState({ sum: this.props.incident.financialCost });
            return;
        }

        if (newCommentValue === "" || newCommentValue == editedCommentValue) {
            if (this.state.conflict)
                this.setState({ conflict: false });

            this.setState({ comment: this.props.incident.costComment });
            return;
        }

        const isSumEdited = editedSumValue != olderSumValue;
        const isCommentEdited = editedCommentValue != olderCommentValue;

        if (isSumEdited && newSumValue != olderSumValue) {
            this.setState({ conflict: true });
            return;
        }

        if (isCommentEdited && newCommentValue != olderCommentValue) {
            this.setState({ conflict: true });
            return;
        }

        if (newSumValue != olderSumValue) {
            this.setState({ sum: this.props.incident.financialCost, conflict: false })
        }

        if (newCommentValue != olderCommentValue) {
            this.setState({ comment: this.props.incident.costComment, conflict: false })
        }
    }

    saveChanges = async () => {
        try {
            const response = await client.incidents.addOrUpdateFinancialConsequences(
                this.props.incident.id,
                { financialCost: this.state.sum, costComment: this.state.comment }
            );

            if (response) this.props.onFCActions(response.data);

            this.setState({
                error: null
            })
        }
        catch (e) {
            this.setState({
                error: e
            });
        }
    }

    autoSaveChanges = _.debounce(this.saveChanges, 2000);

    onSumChange = (sum) => {
        if (sum.target.value !== this.state.sum) {
            this.setState({ sum: sum.target.value });

            if (sum.target.value && sum.target.value != 0) {
                this.autoSaveChanges();
            }
        }
    }

    onCommentChange = (comment) => {
        if (comment !== this.state.comment) {
            this.setState({ comment });

            if (comment && comment != "") {
                this.autoSaveChanges();
            }
        }
    }

    render() {
        if (!this.props.incident) return null;

        const numSum = this.state.sum || 0;
        const stringComment = this.state.comment || "";

        const incidentSum = this.props.incident.financialCost || 0;
        const incidentComment = this.props.incident.costComment || "";

        const willDelete = numSum == 0 && incidentSum != 0 && stringComment == "" && incidentComment != "";
        const isChanged = numSum !== incidentSum || stringComment != incidentComment;
        const isConflict = this.state.conflict;
        const isEditable = this.props.writeAccess;
        const isClosed = this.props.incident.dates["closed"] != undefined;

        const text = willDelete ? "Delete" : isConflict ? "Override" : isChanged ? "Save" : "Saved";
        const icon = willDelete ? <DeleteOutlined /> : <SaveOutlined />;

        const save = isEditable
            ? !isClosed ?
                <Button className="action" onClick={this.saveChanges} disabled={!willDelete && !isChanged} icon={icon} > {text}</Button> : null
            : null;

        return (
            <React.Fragment>
                <Card
                    key="incident-financialConsequences-card"
                    title={
                        <React.Fragment>
                            <Title level={4} className="title">Financial consequences</Title>
                            {save}
                        </React.Fragment>
                    }
                    className={`incident-financialConsequences-card info-incident-container ${this.props.className ? this.props.className : ""}`}
                >
                    {!isClosed ?
                        <div>
                            <label>Sum (optional)</label>
                            <Input className="costInput" value={this.state.sum} type="number" onChange={this.onSumChange} disabled={!isEditable} placeholder="Sum" addonAfter="kr" />
                            <label>Comment (optional)</label>
                            {/*<PlainTextEditor value={this.state.comment} setValue={this.onCommentChange} className={`costComment ${!isEditable ? "costComment-disabled" : ""}`} readOnly={!isEditable} placeholder="Write here" />*/}
                            <TextAreaInput defaultValue={this.state.comment ?? ""} param="financialComment" disabled={!isEditable} className={`costComment ${!isEditable ? "costComment-disabled" : ""}`} placeholder={this.state.comment ? this.state.comment : "Write here"} onChange={this.onCommentChange} />
                        </div> :
                        <p>
                            <label>Sum: </label>
                            {this.props.incident.financialCost ? this.props.incident.financialCost : `-`}
                            <br />
                            <label>Comment: </label>
                            {this.props.incident.costComment ? this.props.incident.costComment : `-`}
                        </p>}
                </Card>
            </React.Fragment>
        );
    }
}