/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateSensor,
  ReplaceSensor,
  SensorAggregationResultSensorAggregateQueryAggregationResults,
  SensorAggregationTypes,
  SensorSortOption,
  SensorView,
  SensorViewEntityViewModel,
  SensorViewPagedSensorQueryCollectionViewModel,
  SortDirection,
  UpdateSensor,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Sensors<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Sensor
   * @name GetSensorById
   * @request GET:/api/sensors/{id}
   * @secure
   */
  getSensorById = (id: string, params: RequestParams = {}) =>
    this.http.request<SensorViewEntityViewModel, any>({
      path: `/api/sensors/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sensor
   * @name UpdateSensor
   * @request PUT:/api/sensors/{id}
   * @secure
   */
  updateSensor = (id: string, data: UpdateSensor, params: RequestParams = {}) =>
    this.http.request<SensorView, any>({
      path: `/api/sensors/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sensor
   * @name DeleteSensor
   * @request DELETE:/api/sensors/{id}
   * @secure
   */
  deleteSensor = (id: string, params: RequestParams = {}) =>
    this.http.request<SensorView, any>({
      path: `/api/sensors/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sensor
   * @name CreateSensor
   * @request POST:/api/sensors
   * @secure
   */
  createSensor = (data: CreateSensor, params: RequestParams = {}) =>
    this.http.request<SensorView, any>({
      path: `/api/sensors`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sensor
   * @name QuerySensors
   * @request GET:/api/sensors
   * @secure
   */
  querySensors = (
    query?: {
      sortBy?: SensorSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      deviceId?: string;
      resourceId?: string;
      applicationId?: string;
      availableForResourceId?: string;
      lowBattery?: boolean;
      weakOrNoSignal?: boolean;
      hasMoreThanOnePart?: boolean;
      noSignal?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SensorViewPagedSensorQueryCollectionViewModel, any>({
      path: `/api/sensors`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sensor
   * @name ReplaceSensor
   * @request POST:/api/sensors/{id}/replace
   * @secure
   */
  replaceSensor = (id: string, data: ReplaceSensor, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/api/sensors/${id}/replace`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Sensor
   * @name RestoreSensor
   * @request POST:/api/sensors/{id}/restore
   * @secure
   */
  restoreSensor = (id: string, params: RequestParams = {}) =>
    this.http.request<SensorView, any>({
      path: `/api/sensors/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Sensor
   * @name GetSensorAggregates
   * @request GET:/aggs/sensors
   * @secure
   */
  getSensorAggregates = (
    query?: {
      aggs?: SensorAggregationTypes[];
      sortBy?: SensorSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      deviceId?: string;
      resourceId?: string;
      applicationId?: string;
      availableForResourceId?: string;
      lowBattery?: boolean;
      weakOrNoSignal?: boolean;
      hasMoreThanOnePart?: boolean;
      noSignal?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SensorAggregationResultSensorAggregateQueryAggregationResults, any>({
      path: `/aggs/sensors`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
