import _ from "lodash";
import moment from "moment";
import client from "../client";
import { PagedRoutineQuery, RoutineView } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig } from "./BaseExportService";

export class RoutineExport extends BaseExportService<PagedRoutineQuery, RoutineView> {

    constructor() {

        const specification: ExportConfig<RoutineView>[] = [
            { header: 'Title', value: (data: RoutineView) => data.title },
            { header: 'Description', value: (data: RoutineView) => data.description },
            { header: 'Next execution', value: (data: RoutineView) => data.nextExecution ? moment(data.nextExecution).format('DD.MM.YYYY') : "" },
            { header: 'Owner', value: (data: RoutineView) => data.owner?.name ?? "" },
            {
                header: 'Responsible', value: (data: RoutineView) => {
                    const responsible = _.map(data.responsible, r => r.name);
                    return responsible.join(", ");
                }
            },
            { header: 'Type', value: (data: RoutineView) => data.category?.name ?? "" },
            { header: 'Recurring pattern', value: (data: RoutineView) => data.frequency ? data.frequency.interval + " - " + data.frequency.type : "" },
            { header: 'Severity', value: (data: RoutineView) => data.severity },
            { header: 'Organization', value: (data: RoutineView) => data.organization?.name ?? "" }
        ];

        super(specification, client.routines.queryRoutines);
    }
}