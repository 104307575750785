import * as React from "react";
import _ from 'lodash';
import { Alert, Button, Divider, Form, Radio, Skeleton, Space, Upload } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import {
    ActorType,
    AddOrUpdateAddressCollectionItem,
    AddressType,
    ContactPointLinkView,
    ContactPointType,
    CreateOrUpdateResource,
    PersonView,
    ResourceCategoryView,
    ResourceType,
    ResourceView,
    TaggableType,
} from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { SelectorInput } from "../Shared/SelectorInput";
import { TagSelector } from "../Tags";
import { PersonSelector } from "../People";
import { OrganizationSelector } from "../Organizations";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { TextInput } from "../Shared/TextInput";
import BaseForm, { FormType } from "../Shared/Form";
import {
    DeleteOutlined,
    PlusOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import { openNotification } from "../../Helpers/BasePageHelpers";
import ResourceCategorySelector from "./ResourceCategorySelector";
import ResourceLocationInput from "./ResourceLocationInput";
import { RcFile } from "antd/es/upload";
import { CustomFormLabel } from "../Shared/CustomFormLabel";
import EmailContactPointSelector from "../ContactPoints/EmailContactPointSelector";

interface CreateOrEditResourceProps {
    resource?: ResourceView;
    onComplete: (created: ResourceView) => void;
    onCancel?: () => void;
    fromOrganization?: boolean;
}

interface CreateOrEditResourceState {
    loading: boolean;
    error: string;
    ownerValue: "internal" | "organization" | "person";
    enableMeasuringPoints: boolean;
    ownerId: string;
    resourceType?: ResourceCategoryView;
    picture: UploadFile[];
    pictureChanged: boolean;
    loadingAvatar: boolean;
    enableAddress: boolean;
    selectedNf: { [key: number]: { personId: string, contactPoint: ContactPointLinkView } };
}

interface ExtendedCreateOrUpdateResource extends CreateOrUpdateResource {
    ownerValue: "internal" | "organization" | "person";
    resourceLocation?: {
        line1?: string;
        zipCode?: string;
        area?: string;
    };
}

export class ResourceCreateOrEditForm extends React.Component<
    CreateOrEditResourceProps,
    CreateOrEditResourceState
> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loadingAvatar: false,
            error: null,
            ownerValue:
                this.props.resource && this.props.resource.owner != null
                    ? this.props.resource.owner.actorType ==
                        ActorType.Organization
                        ? "organization"
                        : this.props.resource.owner.actorType ==
                            ActorType.Person
                            ? "person"
                            : null
                    : "internal",
            enableMeasuringPoints:
                this.props.resource?.enableMeasuringPoints ?? false,
            ownerId: this.props.resource?.owner?.id,
            resourceType: this.props.resource?.category,
            picture: this.props.resource?.hasAvatar ? [{
                uid: '0',
                name: "Avatar",
                url: `/api/resources/${this.props.resource.id}/avatar`,
            }] : [],
            pictureChanged: false,
            enableAddress: this.props.resource?.enableAddress ?? false,
            selectedNf: this.props.resource?.notificationReceivers?.map(nr => ({
                personId: nr.id,
                contactPoint: nr.contactPoints?.find(cp => cp.type === ContactPointType.Email && cp.primary)
            })).reduce((acc, nf, index) => ({ ...acc, [index]: nf }), {}) as { [key: number]: { personId: string, contactPoint: ContactPointLinkView }},
        };
    }

    onSubmit = async (request: ExtendedCreateOrUpdateResource) => {
        this.setState({ loading: true });

        try {
            const req = Object.assign({}, request);

            if (this.state.selectedNf) {
            const nfList = Object.values(this.state.selectedNf);

            for (const nf of nfList) {
                if (!nf.contactPoint.primary) {
                    await client.contactPoints.updateContactPoint(nf.contactPoint.id, {
                        value: nf.contactPoint.value,
                        primary: true,
                        contactId: nf.personId,
                        description: nf.contactPoint.description,
                    })
                }
                }
                req.notificationReceiverIds = nfList.map(nf => nf.personId);
            }

            
            
            req.ownerId = this.state.ownerId;
            if (
                this.state.ownerValue === "organization" ||
                this.state.ownerValue === "person"
            ) {
                req.type = ResourceType.External;
            } else {
                req.type = ResourceType.Internal;
            }


            if (this.props.resource?.category?.enableAddress === null) {
                req.enableAddress = this.state.enableAddress;
            }

            if (this.props.resource?.category?.enableMeasuringPoints === null) {
                req.enableMeasuringPoints = this.state.enableMeasuringPoints;
            }

            const pureResourceUpdateRequest: CreateOrUpdateResource =
            {
                name: req.name,
                categoryId: req.categoryId,
                type: req.type,
                description: req.description,
                tagIds: req.tagIds,
                supplierId: req.supplierId,
                contactPersonIds: req.contactPersonIds,
                enableAddress: req.enableAddress,
                enableContactPoints: req.enableContactPoints,
                enableMeasuringPoints: req.enableMeasuringPoints,
                monitored: req.monitored,
                notificationReceiverIds: req.notificationReceiverIds,
                ownerId: req.ownerId
            };

            let response = this.props.resource
                ? await client.resources.updateResource(
                    this.props.resource.id,
                    pureResourceUpdateRequest,
                    addChangeVectorHeader(this.props.resource.changeVector)
                )
                : await client.resources.createResource(req);

            if (response) {
                if (this.state?.picture && this.state?.pictureChanged) {
                    try {
                        response = await client.resources.updateResourceAvatar(
                            response.data.id,
                            {
                                avatar: this.state.picture[0] as RcFile,
                            }
                        );
                    } catch (error) {
                        openNotification(
                            "Error adding picture to resource",
                            "An error occured while trying to add the picture.",
                            "error"
                        );
                    }
                } else if (this.props.resource?.hasAvatar && this.state?.pictureChanged) {
                    try {
                        response = await client.resources
                            .deleteResourceAvatar(response.data.id);
                    } catch (error) {
                        this.props.resource.hasAvatar = false;
                    }
                }

                if (request.resourceLocation) {
                    if (this.props.resource?.addresses) {
                        try {
                            const address: AddOrUpdateAddressCollectionItem = {
                                types: [AddressType.Home],
                                line1: request.resourceLocation?.line1,
                                zipCode: request.resourceLocation?.zipCode,
                                area: request.resourceLocation?.area,
                            };
                            response = await client.resources.updateResourceAddress(
                                response.data.id,
                                this.props.resource.addresses[0].addressId,
                                address,
                                addChangeVectorHeader(
                                    response.data.changeVector
                                )
                            );
                        } catch (error: any) {
                            openNotification(
                                "Error adding address to resource",
                                error.message,
                                "error",
                                null,
                                "addressError",
                                false
                            );
                        }
                    } else {
                        try {
                            const address: AddOrUpdateAddressCollectionItem = {
                                types: [AddressType.Home],
                                line1: request.resourceLocation?.line1,
                                zipCode: request.resourceLocation?.zipCode,
                                area: request.resourceLocation?.area,
                            };
                            response = await client.resources.createResourceAddress(
                                response.data.id,
                                address,
                                addChangeVectorHeader(
                                    response.data.changeVector
                                )
                            );
                        } catch (error: any) {
                            openNotification(
                                "Error adding new address to resource",
                                error.message,
                                "error",
                                null,
                                "addressError",
                                false
                            );
                        }
                    }
                }
                this.props.onComplete(response.data);
            }
        } catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    };

    onDelete = async () => {
        if (this.props.resource != null) {
            const response = await client.resources
                .deleteResource(
                    this.props.resource.id,
                    addChangeVectorHeader(this.props.resource.changeVector)
                )
                .catch((exception) =>
                    this.setState({ error: exception.error.title })
                );

            if (response) this.props.onComplete(response.data);
        }
    };

    onRestore = async () => {
        if (this.props.resource != null) {
            const response = await client.resources
                .restoreResource(
                    this.props.resource.id,
                    addChangeVectorHeader(this.props.resource.changeVector)
                )
                .catch((exception) =>
                    this.setState({ error: exception.error.title })
                );

            if (response) this.props.onComplete(response.data);
        }
    };

    onOwnerRadioChange = (e) => {
        this.setState({ ownerValue: e.target.value });
    };

    onOwnerChange = (actorId: string) => {
        this.setState({ ownerId: actorId });
    };

    enableSensorPointsChange = (e) => {
        this.setState({ enableMeasuringPoints: e.target.value });
    };

    enableAddesssChange = (e) => {
        this.setState({ enableAddress: e.target.value });
    };

    removePicture = () => {
        this.setState({ picture: null });
    };

    handlePersonSelect = (person: PersonView, key: number) => {
        this.setState((prev) => {
            return {
                selectedNf: {
                    ...prev.selectedNf,
                    [key]: {
                        personId: person?.id,
                        contactPoint: person?.contactPoints?.find(
                            (cp) => cp.type === ContactPointType.Email && cp.primary
                        ),
                    },
                },
            };
        })
    };

    handleContactPointSelect = (contactPoint: ContactPointLinkView, key: number) => {
        this.setState((prev) => {
            return {
                selectedNf: {
                    ...prev.selectedNf,
                    [key]: {
                        personId: prev.selectedNf[key]?.personId,
                        contactPoint: contactPoint,
                    },
                },
            };
        });
    }

    render = () => {
        const initialValues =
            this.props.resource != null
                ? {
                    name: this.props.resource.name ?? null,
                    description: this.props.resource.description ?? null,
                    ownerId: this.props.resource.owner?.id ?? null,
                    organizationOwnerId: this.props.resource.owner?.actorType === ActorType.Organization ? this.props.resource.owner?.id : null,
                    personOwnerId: this.props.resource.owner?.actorType === ActorType.Person ? this.props.resource.owner?.id : null,
                    supplierId: this.props.resource.supplier?.id ?? null,
                    tagIds: _.map(this.props.resource.tags ?? [], tag => {
                        if (tag.category?.deleted || tag?.deleted) return;
                        return tag.id;
                    }),
                    categoryId: this.props.resource.category?.id ?? null,
                    enableMeasuringPoints:
                        this.props.resource?.enableMeasuringPoints,
                    enableContactPoints:
                        this.props.resource?.enableContactPoints,
                    enableAddress: this.props.resource?.enableAddress,
                    resourceLocation: this.props.resource?.addresses?.at(0),
                    notificationRecievers: this.state.selectedNf ? Object.values(this.state.selectedNf).map(nf => ({ personId: nf.personId, contactPointId: nf.contactPoint?.id })) : [],
                    contactPersonIds:
                        this.props.resource?.contactPeople?.map(
                            (nr) => nr.id
                        ),
                }
                : {
                    notificationRecievers: this.state.selectedNf ? Object.values(this.state.selectedNf).map(nf => ({ personId: nf.personId, contactPointId: nf.contactPoint?.id })) : [],
                    contactPersonIds: []
                };

        return (
            <BaseForm
                type={
                    this.props.resource === null
                        ? FormType.Create
                        : FormType.Edit
                }
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                onDelete={this.onDelete}
                onRestore={this.onRestore}
                isDeleted={this.props.resource?.deleted}
                loading={this.state.loading}
                error={this.state.error}
                className="resource-create-edit-form"
                initialValues={initialValues}
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please input your name"
                    placeholder="Name"
                    className="name-container"
                    title="Name"
                    formItemClassName="actor-name-input"
                />

                <Form.Item label={<div>Upload picture (optional)</div>}>
                    <div>
                        <Upload
                            onChange={() => this.setState({ pictureChanged: true })}
                            beforeUpload={(file) => {
                                const isImg = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif';
                                if (!isImg) {
                                    return Upload.LIST_IGNORE;
                                }
                                this.setState({ picture: [file] })
                                return false;
                            }}
                            onRemove={this.removePicture}
                            maxCount={1}
                            listType="picture"
                            defaultFileList={this.state.picture}
                            className="actor-form-picture-upload"
                        >
                            {this.state.loadingAvatar ? (
                                <Skeleton.Button active />
                            ) : (
                                <Button
                                    className="actor-picture-btn"
                                    icon={<UploadOutlined />}
                                >
                                    {this.context.isMobile
                                        ? "Add"
                                        : "Add picture"}
                                </Button>
                            )}
                        </Upload>
                    </div>
                </Form.Item>

                <TextAreaInput
                    param="description"
                    placeholder="Name"
                    title="Description"
                />

                <SelectorInput
                    param="categoryId"
                    title="Type"
                    required
                    selector={
                        <ResourceCategorySelector
                            placeholder="Select"
                            onObjectChange={(obj) =>
                                this.setState({ resourceType: obj })
                            }
                        />
                    }
                />

                {this.props.fromOrganization ? null : (
                    <>
                        <Form.Item
                            name="ownerValue"
                            label={<div>Who owns the resource?</div>}
                        >
                            <Radio.Group
                                onChange={this.onOwnerRadioChange}
                                className="resource-owner-input"
                                value={this.state.ownerValue}
                                defaultValue={this.state.ownerValue}
                            >
                                <Space direction="vertical">
                                    <Radio value={"internal"}>
                                        Internal
                                    </Radio>
                                    <Radio value={"organization"}>
                                        Select organization
                                    </Radio>
                                    {this.state.ownerValue ==
                                        "organization" ? (
                                        <SelectorInput
                                            param="organizationOwnerId"
                                            required
                                            className="owner-selector"
                                            selector={
                                                <OrganizationSelector
                                                    onChange={this.onOwnerChange}
                                                    placeholder="Select organization..."
                                                />
                                            }
                                        />
                                    ) : null}
                                    <Radio value={"person"}>
                                        Select person
                                    </Radio>
                                    {this.state.ownerValue == "person" ? (
                                        <SelectorInput
                                            param="personOwnerId"
                                            required
                                            className="owner-selector"
                                            selector={
                                                <PersonSelector
                                                    onChange={this.onOwnerChange}
                                                    placeholder="Select person..."
                                                />
                                            }
                                        />
                                    ) : null}
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </>
                )}

                {this.state.resourceType ? (
                    <div>
                        {this.state.resourceType.enableMeasuringPoints ===
                            null ? (
                            <Form.Item
                                name="enableMeasuringPoints"
                                label={
                                    <div>
                                        Will the resource be monitored by
                                        sensors?
                                    </div>
                                }
                                valuePropName="checked"
                            >
                                <Radio.Group
                                    onChange={this.enableSensorPointsChange}
                                    value={this.state.enableMeasuringPoints}
                                >
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        ) : null}

                        {this.state.resourceType.enableContactPoints ===
                            null ? (
                            <Form.Item
                                name="enableContactPoints"
                                label={
                                    <div>
                                        Should the resource have contact points?
                                    </div>
                                }
                            >
                                <Radio.Group defaultValue={false}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        ) : null}

                        {this.state.resourceType.enableAddress === null ? (
                            <Form.Item
                                name="enableAddress"
                                label={
                                    <div>
                                        Should the resource have addresses?
                                    </div>
                                }
                                valuePropName="checked"
                            >
                                <Radio.Group
                                    onChange={this.enableAddesssChange}
                                    value={this.state.enableAddress}
                                >
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        ) : null}
                    </div>
                ) : null}

                {this.state.enableMeasuringPoints ||
                    this.state.resourceType?.enableMeasuringPoints ? (
                    <>
                        <SelectorInput
                            param="supplierId"
                            title="Supplier/retailer"
                            selector={
                                <OrganizationSelector />
                            }
                        />

                        <div className="notification-receivers">
                            <Form.List name="notificationRecievers">
                                {(fields, { add, remove }) => (
                                    <>
                                        <CustomFormLabel label="Who should be notified of deviations? (optional)" />
                                        {fields.map((field, index) => (
                                            <div key={index}>
                                                <Form.Item
                                                    style={{ display: "flex" }}
                                                >
                                                    <div style={{ width: "100%" }}>
                                                        <Form.Item
                                                            {...field} 
                                                            name={[field.name, 'personId']} 
                                                            key={field.key + "person"}
                                                            rules={[{ required: true, message: 'Please select a person' }]}
                                                        >
                                                            <PersonSelector onObjectChange={(value) => this.handlePersonSelect(value, index)} />
                                                        </Form.Item>
                                                        {this.state.selectedNf[index]?.personId !== undefined && (
                                                            <Form.Item
                                                                {...field} 
                                                                name={[field.name, 'contactPointId']}
                                                                key={field.key + 'cp'}
                                                                rules={[{ required: true, message: 'Please select a contact point' }]}
                                                            >
                                                                <EmailContactPointSelector
                                                                    personId={this.state.selectedNf[index]?.personId}
                                                                    onObjectChange={(value) => this.handleContactPointSelect(value, index)}
                                                                    onCreatedContactPoint={(cp) => this.setState(prev => ({ ...prev, selectedNf: { ...prev.selectedNf, [field.key]: { personId: prev.selectedNf[field.key].personId, contactPoint: cp } } }))}
                                                                />
                                                            </Form.Item>
                                                        )}
                                                    </div>
                                                    <Button
                                                        type="text"
                                                        size="small"
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            remove(field.name);
                                                            delete this.state.selectedNf[field.key];
                                                        }}
                                                    />
                                                </Form.Item>
                                                {
                                                    this.state.selectedNf[index]?.personId !== undefined && 
                                                    this.state.selectedNf[index]?.contactPoint !== undefined && 
                                                    !this.state.selectedNf[index]?.contactPoint?.primary && 
                                                    <Alert type="info" message="This email is not the persons primary email. By proceeding this will be the new primary email." showIcon style={{ marginBottom: "5px" }} />
                                                }
                                                {index !== fields.length - 1 && <Divider style={{ marginBlock: "10px 5px" }} />}
                                            </div>
                                        ))}
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                this.setState(prev => ({ selectedNf: { ...prev.selectedNf, [fields.length]: { personId: undefined, contactPoint: undefined } }}));
                                                add();
                                            }}
                                            icon={<PlusOutlined />}
                                            style={{ marginBottom: "20px", marginTop: "10px" }}
                                        >
                                            Add notification reciever
                                        </Button>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </>
                ) : null}

                <div className="notification-receivers">
                    <Form.List name="contactPersonIds">
                        {(fields, { add, remove }) => (
                            <>
                                <CustomFormLabel label="Contacts (optional)" />

                                {fields.map((field, index) => (
                                    <div key={field.key}>
                                        <Form.Item
                                            style={
                                                index === fields.length - 1
                                                    ? {
                                                        marginBottom: "5px",
                                                        display: "flex",
                                                    }
                                                    : { display: "flex" }
                                            }
                                        >
                                            <Form.Item {...field} key={field.key}>
                                                <PersonSelector />
                                            </Form.Item>
                                            {fields.length > 0 ? (
                                                <Button
                                                    type="text"
                                                    size="small"
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => remove(index)}
                                                />
                                            ) : null}
                                        </Form.Item>
                                    </div>
                                ))}

                                <Button
                                    size="small"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                    style={{ marginBottom: "20px", marginTop: "10px" }}
                                >
                                    Add contact
                                </Button>
                            </>
                        )}
                    </Form.List>
                </div>

                {this.state.resourceType?.enableAddress === true ||
                    this.state.enableAddress ? (
                    <ResourceLocationInput
                        title="Resource location (optional)"
                        param="resourceLocation"
                        addressType={AddressType.Home}
                    />
                ) : null}

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={
                        <TagSelector
                            multiple
                            filters={{ taggableTypes: [TaggableType.Resource] }}
                        />
                    }
                />
            </BaseForm>
        );
    };
}

export default ResourceCreateOrEditForm;
