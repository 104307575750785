import { Bronnoysund } from "../Models/_bronnoysund";

export default class BronnoysundService {
    constructor() {
        this.url = `https://data.brreg.no/enhetsregisteret/api/enheter`;
    }

    protected url: string;

    query = async (terms: string): Promise<Bronnoysund> => {
        const response = await fetch(`${this.url}?navn=${terms}`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'content-type': 'application/json',
            },
        });

        if (response.status === 200 || response.status === 201)
            return await response.json();
        else
            throw Error(response.status.toString());
    }
}