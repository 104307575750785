import * as React from "react";
import _ from 'lodash';
import { FileLinkView, FileView, FolderLinkView, FolderView, PagedFileQuery } from "../../ApiClient/swagger/data-contracts";
import { Capabilities } from "../../Definitions/_capabilties";
import { PageViewState } from "../../Models/PageViewState";
import AppContext from "../../Definitions/AppContext";
import { handleListDuplicates, openNotification } from "../../Helpers/BasePageHelpers";
import client from "../../ApiClient/client";
import { addParamsToQuery } from "../../Helpers/QueryHashHandlers";
import { RequireCapability } from "../Shared/RequireCapability";
import { Breadcrumb, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { FolderIds } from "../../Definitions/_definitions";
import { EntitySearch } from "../Shared/EntitySearch";
import GalleryView from "./GalleryView";
import FileCreateForm from "./FileCreateForm";
import FolderCreateForm from "./FolderCreateForm";
import { Drawer } from "../Shared/Drawer";


interface FolderViewCardProps {
    onSelect: (file: FileLinkView) => void;
    folderId: string;
    hideActions?: boolean;
    writeCapability: Capabilities;
}
interface FolderViewCardState extends PageViewState {
    folder: FolderView,
    subFolders: (FolderLinkView | FolderView)[];
    files: (FileLinkView | FileView)[];
    error: string,
    created: string[];
    query: Partial<PagedFileQuery>;
    loadingData: boolean;
    queryResults: FolderView[];
    queryCount: number;
    fileDrawer: boolean;
    folderDrawer: boolean;
}

export class FolderViewCard extends React.Component<FolderViewCardProps, FolderViewCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            folder: null,
            subFolders: null,
            files: null,
            loadingData: false,
            error: null,
            errorPage: null,
            created: [],
            query: {
                deleted: false,
                terms: null,
            },
            queryResults: null,
            queryCount: 0,
            fileDrawer: null,
            folderDrawer: null,
        }
    }

    componentDidMount = () => {
        if (this.props.folderId)
            this.loadFolder(this.props.folderId);

        this.context.events.files.onMany({
            'created': this.onFileUpdatedEvent,
            'updated': this.onFileUpdatedEvent,
            'deleted': this.onFileUpdatedEvent,
            'restored': this.onFileUpdatedEvent
        });

        this.context.events.folders.onMany({
            'created': this.onFolderUpdatedEvent,
            'updated': this.onFolderUpdatedEvent,
            'deleted': this.onFolderUpdatedEvent,
            'restored': this.onFolderUpdatedEvent
        });

        window.addEventListener("scroll", this.handleScrollLoad, true);
    }

    componentWillUnmount = () => {
        this.context.events.files.offMany({
            'created': this.onFileUpdatedEvent,
            'updated': this.onFileUpdatedEvent,
            'deleted': this.onFileUpdatedEvent,
            'restored': this.onFileUpdatedEvent
        });

        this.context.events.folders.offMany({
            'created': this.onFolderUpdatedEvent,
            'updated': this.onFolderUpdatedEvent,
            'deleted': this.onFolderUpdatedEvent,
            'restored': this.onFolderUpdatedEvent
        });

        window.removeEventListener("scroll", this.handleScrollLoad, true);
    }

    handleScrollLoad = (e) => {
        const scrollTarget = e.target;
        if (scrollTarget.id !== "main") return;

        const visibleHeight = scrollTarget.offsetHeight;
        const totalHeight = scrollTarget.scrollHeight;
        const remaining = totalHeight - visibleHeight;

        const items = this.state.queryResults.length;
        const scrollOnItem = items - 8;
        const fraction = scrollOnItem / items;

        const triggerPoint = remaining * fraction;

        if (scrollTarget.scrollTop > triggerPoint) {
            this.loadMore();
        }
    }

    onFileUpdatedEvent = async (eventData: FileView | FolderView) => {
        if (_.indexOf(this.state.created, eventData.id) > -1)
            return;

        const updatedFiles = this.updateCollection(this.state.files, eventData);

        this.setState({ files: updatedFiles });

        openNotification(`Folder updated`,
            `The folder have been updated with the latest changes`,
            "success",
            null,
            eventData.id
        );
    }
    
    onFolderUpdatedEvent = async (eventData: FileView | FolderView) => {

        if (_.indexOf(this.state.created, eventData.id) > -1)
            return;

        if (eventData.id == this.state.folder.id) {

            const folder = eventData as FolderView;

            this.setState({
                folder: folder,
                files: folder.files,
                subFolders: folder.subFolders
            });

        } else {
            const updatedFolders = this.updateCollection(this.state.subFolders, eventData);

            this.setState({ subFolders: updatedFolders });
        }

        openNotification(`Folder updated`,
            `The folder have been updated with the latest changes`,
            "success",
            null,
            eventData.id
        );
    }

    updateCollection = (collection: any[], item: FileView | FolderView) => {

        let copy = [...(collection)];
        const index = _.findIndex(copy, i => { return i.id === item.id });

        const firstParent = _.last(item.path);

        if (firstParent && firstParent.id == this.state.folder.id) {

            if (index > -1) {
                //Update
                copy.splice(index, 1, item);
            }
            else {
                //Add
                copy = [...(copy), item];
            }

        }
        else if (firstParent && index > -1) {
            //Remove
            copy.splice(index, 1);
        }

        return copy;
    }

    toggleAddFileDrawer = () => {
        this.setState({ fileDrawer: !this.state.fileDrawer });
    }

    toggleAddFolderDrawer = () => {
        this.setState({ folderDrawer: !this.state.folderDrawer });
    }

    loadFolder = async (id: string) => {
        try {
            const response = await client.folders.getFolderById(id);
            this.setState({
                folder: response.data.view,
                files: response.data.view.files,
                subFolders: response.data.view.subFolders,
                query: Object.assign({}, this.state.query, { terms: null }),
                queryCount: 0,
                queryResults: null
            });
        }
        catch (error: any) {
            this.setState({ error });
        }
    }

    loadMore = async () => {
        if (this.state.loadingData || !this.state.queryResults || this.state.queryResults.length >= this.state.queryCount) return;
        this.setState({ loadingData: true });

        const query = Object.assign({}, this.state.query);
        query.from += query.limit;

        try {
            const response = await client.files.queryFiles(query);
            if (response) {
                const newCollection = await handleListDuplicates(this.state.queryResults, response.data.items);

                this.setState({
                    query: response.data.query,
                    queryResults: newCollection,
                    queryCount: response.data.count
                });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loadingData: false });
    }

    onSearch = async (terms) => {
        if (!terms || terms == "") {
            this.setState({ loadingData: false });
            this.loadFolder(this.state.folder.id);
            return;
        }

        this.setState({ loadingData: true });

        try {
            const newQuery = await addParamsToQuery(this.state.query, { terms }, false);
            const folderQuery = Object.assign({}, newQuery, { parentId: this.state.folder.id })
            const response = await client.files.queryFiles(folderQuery);

            const resultingQuery = Object.assign({}, response.data.query, { folderId: null });

            if (response)
                this.setState({
                    files: response.data.items,
                    queryResults: response.data.items,
                    queryCount: response.data.count,
                    query: resultingQuery
                });
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loadingData: false });
    }

    onFolderOrFileCreated = (response: FileView | FolderView) => {
        this.setState({
            created: [...this.state.created, response.id],
            folderDrawer: null,
            fileDrawer: null
        });
    }


    render() {
        const searching = this.state.query?.terms != null;

        const addFolder = <RequireCapability key="add-folder" capability={this.props.writeCapability}>
            <Button disabled={searching} key="addFolder" className="action file-title-action" onClick={this.toggleAddFolderDrawer} icon={this.context.isMobile ? <PlusCircleOutlined /> : null}>
                {this.context.isMobile ? null : "New folder"}
            </Button>
        </RequireCapability>;

        const addFile = <RequireCapability key="add-file" capability={this.props.writeCapability}>
            <Button key="addFile" className="action file-title-action" disabled={searching} onClick={this.toggleAddFileDrawer} icon={this.context.isMobile ? <PlusCircleOutlined /> : null}>
                {this.context.isMobile ? null : "Add files"}
            </Button>
        </RequireCapability>;

        const path = [...(this.state.folder?.path || []), ...(this.state.folder ? [this.state.folder] : [])];

        const breadcrumbItems = _.map(path, (p, key) => {
            //if (p.id != FolderIds.SharedFolder) {
                return (
                    <Breadcrumb.Item key={p.id}>
                        {<Button type="link" onClick={() => this.loadFolder(p.id)}>{p.name}</Button>}
                    </Breadcrumb.Item>
                );
            //}
        });

        return (
            <div className="folder-view-card">
                <div className="folder-view-card-header">
                    <EntitySearch size="middle" loading={this.state.loadingData} className="folder-search" onChange={this.onSearch} />
                    {this.props.hideActions ? null : addFolder}
                    {this.props.hideActions ? null : addFile}
                </div>
                <Breadcrumb className="breadcrumb">{breadcrumbItems}</Breadcrumb>
                <GalleryView folders={searching ? [] : this.state.subFolders} files={this.state.files} onFolderClick={this.loadFolder} onFileClick={this.props.onSelect} showDeleted={this.state.query.deleted} />

                <Drawer
                    title="Add files"
                    onClose={() => { this.setState({ fileDrawer: null }) }}
                    open={this.state.fileDrawer != null}
                    destroyOnClose={true}
                    component={
                        <FileCreateForm
                            getFolderSource={null}
                            createFileSource={null}
                            entityId={null}
                            folderId={this.state.folder != null ? this.state.folder.id : null}
                            onComplete={(created) => created ? this.onFolderOrFileCreated(created) : this.setState({ fileDrawer: false })}
                            onCancel={() => this.setState({ fileDrawer: false })}
                        />
                    }
                />
                <Drawer
                    title="New folder"
                    onClose={() => this.setState({ folderDrawer: false })}
                    open={this.state.folderDrawer}
                    destroyOnClose={true}
                    component={
                        <FolderCreateForm
                            getFolderSource={null}
                            createFolderSource={null}
                            onCancel={() => this.setState({ folderDrawer: false })}
                            entityId={null}
                            parent={this.state.folder != null ? this.state.folder.reference : null}
                            onComplete={(created) => created ? this.onFolderOrFileCreated(created) : this.setState({ folderDrawer: false })}
                        />
                    }
                />
            </div>
        );
    }
}