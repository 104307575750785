import * as React from "react";
import _ from 'lodash';
import { Table, Button } from 'antd';
import { PanelRender } from "rc-table/lib/interface";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { Drawer } from "./Drawer";
import { MoreOutlined } from "@ant-design/icons";
import AppContext from "../../Definitions/AppContext";
import { SortDirection } from "../../ApiClient/swagger/data-contracts";
import { WidthComponentSize } from "../../Hooks/ComponentSizeHook";
import { Capabilities } from "../../Definitions/_capabilties";

interface ListViewProps {
    title?: PanelRender<any>;
    size?: SizeType;
    bordered?: boolean;
    columns: any[]; //Table headers
    rowSelectionHandler?: (selectedKeys: string[], selectedItems: any) => void; //Set to true to show row-selector actions
    selectedKeys?: string[];
    //resetRowSelections?: boolean;
    onQueryChange: (values: any) => any;
    onDelete?: (ids: string[]) => any;
    onSelect?: (id: any, e?: React.MouseEvent) => any;
    onExpand?: (expanded: any, record: any) => any;
    expandedRowKeys?: string[];
    loading: boolean;
    collection: any;
    onScrollEnd?: () => any;
    onScrollTop?: () => any;
    count?: number;
    sortBy?: string;
    sortDirection?: string;
    childrenParam?: string;
    nestedSubTables?: JSX.Element[];
    rowClassName?: (record, index) => string;
    className?: string;
    expandedValues?: object;
    onEdit?: {
        title?: string;
        requiredCapability?: Capabilities;
        relatedCheck?: (object) => boolean;
        form: (item: any, onClose: () => void) => JSX.Element;
    };
    customKey?: (element: any) => string;
}
interface ListViewState {
    itemToEdit: any;
    //selectedKeys: any;
    //selectedItems: null;
}


export class ListView extends React.Component<ListViewProps, ListViewState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;
    constructor(props: any) {
        super(props);
        this.state = {
            itemToEdit: null,
            //selectedKeys: null,
            //selectedItems: null
        };
    }
    componentDidMount = () => {
        if (this.props.onScrollEnd || this.props.onScrollTop)
            window.addEventListener("scroll", this.handleScrollLoad, true);
    }

    componentWillUnmount = () => {
        if (this.props.onScrollEnd || this.props.onScrollTop)
            window.removeEventListener("scroll", this.handleScrollLoad, true);
    }

    componentDidUpdate = (prevProps: ListViewProps) => {
        //Will cause infinite loop sometimes when filtering:
        //if (prevProps.collection.length > 0 && !this.props.loading) {
        //    this.handleInitialLoad();
        //}
    }

    handleScrollLoad = (e) => {
        const scrollTarget = e.target;
        if (scrollTarget.id !== "main") return;

        const visibleHeight = scrollTarget.offsetHeight;
        const totalHeight = scrollTarget.scrollHeight;
        const remaining = totalHeight - visibleHeight;

        const items = this.props.collection?.length ?? 0;
        const scrollOnItem = items - 8;
        const fraction = scrollOnItem / items;

        const triggerPoint = remaining * fraction;

        if (scrollTarget.scrollTop > triggerPoint && this.props.onScrollEnd) {
            this.props.onScrollEnd();
        }

        if (scrollTarget.scrollTop < 200 && this.props.onScrollTop) {
            this.props.onScrollTop();
        }
    }

    handleInitialLoad = () => {
        const listTarget = document.querySelector<HTMLHtmlElement>('.base-list-component');
        const scrollTarget = document.querySelector<HTMLHtmlElement>('#main');

        const visibleHeight = scrollTarget.offsetHeight;
        const listHeight = listTarget.offsetHeight;

        if (listHeight <= visibleHeight && this.props.onScrollEnd) {
            this.props.onScrollEnd();
        }
    }

    rowSelectionHandler = (selectedKeys, selectedItems) => {
        //this.setState({ selectedKeys, selectedItems });

        if (this.props.rowSelectionHandler)
            this.props.rowSelectionHandler(selectedKeys, selectedItems);
    }

    onListChange = (pagination, filters, sorter, extra) => {
        if (sorter && Object.keys(sorter).length > 0 && sorter.order) {
            let sortDirection = SortDirection.Asc;
            if (sorter.order == "descend")
                sortDirection = SortDirection.Desc;

            const sortBy = sorter.column && typeof sorter.column.sorter == 'string' ? sorter.column.sorter : sorter.columnKey;
            this.props.onQueryChange({ sortBy, sortDirection });
        }
        else {
            this.props.onQueryChange({ sortBy: null, sortDirection: null });
        }
    }

    handleListData = (collection) => {

        const copy = collection?.slice() ?? [];

        const dataSource = _.map(copy, (data, key) => {
            if (this.props.expandedValues) {
                if (this.props.expandedValues[data.id]) {
                    data.children = this.props.expandedValues[data.id];
                    this.handleListData(data.children);
                }
                else if (data.hasChildren && !data.children)
                    data.children = [];
                else if (data[this.props.childrenParam] && !data.children)
                    data.children = [];
            }
            else {
                if (data.children != null && data.children.length > 0)
                    this.handleListData(data.children);
                else if (data.hasChildren && !data.children)
                    data.children = [];
                else if (data[this.props.childrenParam] && !data.children)
                    data.children = [];
            }

            data.key = this.props.customKey ? this.props.customKey(data) : data.id ?? key;
            return data;
        });

        return dataSource;
    }

    expandedSubTable = (row) => {
        const expanded = _.find(this.props.nestedSubTables, c => {
            return c?.key == row?.key;
        });

        return expanded;
    }

    render = () => {
        const { loading, collection } = this.props;

        const dataSource = this.handleListData(collection);

        const sortedColumns = _.map(this.props.columns, column => {
            if (this.props.sortBy && column.sorter && column.sorter.toLowerCase() == this.props.sortBy.toLowerCase()) {
                if (this.props.sortDirection == "Desc")
                    column.sortOrder = "descend";
                else
                    column.sortOrder = "ascend";

                return column;
            }

            return column;
        });

        let editDrawer = null;

        if (this.props.onEdit && (!this.props.onEdit.requiredCapability || this.context.user.hasCapability(this.props.onEdit.requiredCapability))) {
            sortedColumns.push({
                title: '',
                render: (data) => {
                    if(!this.props.onEdit.relatedCheck || this.props.onEdit.relatedCheck(data))
                        return <MoreOutlined className="row-actions" onClick={(e) => { e.stopPropagation(); this.setState({ itemToEdit: data }); }} />
                    else
                        return ""
                },
                key: 'actions',
                sorter: null,
                dataIndex: null,
                responsive: null,
                width: 40,
                className: "editCell",
            });

            editDrawer = (<Drawer
                title={this.props.onEdit.title}
                onClose={() => { this.setState({ itemToEdit: null }); }}
                open={this.state.itemToEdit != null}
                component={
                    this.props.onEdit.form(this.state.itemToEdit, () => { this.setState({ itemToEdit: null }); })
                }
            />);
        }

        const scroll = 100 * sortedColumns.length;

        const expandableSettings = this.props.expandedRowKeys ? { expandedRowKeys: this.props.expandedRowKeys } : {};

        return (
            <WidthComponentSize render={(ref, width) =>
                <div ref={ref} data-width={width} className={`list-view list-view-scroll ${this.context.isMobile ? 'list-view-mobile' : 'list-view-desktop'} ${this.props.className ?? ""} `}>
                    {this.props.count != null && this.context.isMobile ? <div className="list-count">Total results: {this.props.count}</div> : null}
                    <Table
                        title={this.props.title}
                        onChange={this.onListChange}
                        dataSource={dataSource}
                        columns={sortedColumns}
                        size={this.props.size ? this.props.size : this.context.isMobile ? "small" : "middle"}
                        sticky={this.context.isMobile ? true : false}
                        scroll={{ x: this.context.isMobile && scroll > width ? true : null }}
                        onExpand={this.props.onExpand}
                        loading={loading}
                        expandable={Object.assign({}, expandableSettings, {
                            expandedRowRender: this.props.nestedSubTables ? this.expandedSubTable : null,
                            rowExpandable: record => this.props.nestedSubTables && this.props.childrenParam ? record[this.props.childrenParam] && record[this.props.childrenParam].length > 0 : true
                        })}
                        pagination={false}
                        bordered={this.props.bordered}
                        onRow={(data, rowIndex) => {
                            return {
                                onClick: event => {
                                    event.stopPropagation();
                                    if (this.props.onSelect)
                                        this.props.onSelect(data, event);
                                }
                            };
                        }}
                        className={`list-table`}
                        id="list-table"
                        rowClassName={(record, index) => this.props.rowClassName ? this.props.rowClassName(record, index) : `list-row ${record && record.deleted ? 'deleted' : ''} `}
                        rowSelection={this.props.rowSelectionHandler ? {
                            onChange: this.rowSelectionHandler,
                            selectedRowKeys: this.props.selectedKeys/* ?? this.state.selectedKeys*/
                        } : null}
                    />
                    {editDrawer}
                </div>
            } />
        );
    }
}