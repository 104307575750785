import * as React from "react";
import moment from "moment";
import _ from 'lodash';
import { Card, Typography } from "antd";
const { Title } = Typography;


interface CountUpCardProps {
    start: string;
    interval?: number;
    title?: string;
    displayCountOnly?: boolean;
    className?: string;
}

interface CountUpCardState {
    value: moment.Moment;
    interval: number;
    ticks: number;
}

export class CountUpCard extends React.Component<CountUpCardProps, CountUpCardState> {
    _interval = null;

    constructor(props) {
        super(props);

        this.state = {
            interval: this.props.interval ?? 1000,
            value: this.props.start ? moment(this.props.start) : moment(),
            ticks: 0
        }
    }

    componentDidMount = () => {
        this.initiateCount();
    }

    componentWillUnmount = () => {
        clearInterval(this._interval);
    }

    initiateCount = () => {
        this._interval = setInterval(() => {
            this.setState({ ticks: this.state.ticks + 1 });
        }, this.state.interval);
    }

    render() {
        const now = moment();
        const start = this.state.value;

        const diff = now.diff(start);

        let hours = moment.duration(diff).hours().toString();
        let minutes = moment.duration(diff).minutes().toString();
        let seconds = moment.duration(diff).seconds().toString();

        if (hours.length == 1) hours = '0' + hours;
        if (minutes.length == 1) minutes = '0' + minutes;
        if (seconds.length == 1) seconds = '0' + seconds;

        const countDisplay = <span className={`count-up`}>{hours}:{minutes}:{seconds}</span>;

        if (this.props.displayCountOnly) {
            return countDisplay;
        }
        else {
            return (
                <Card
                    className={`count-up-card ${this.props.className ? this.props.className : ''}`}
                    title={<Title level={4}>{this.props.title}</Title>}
                >
                    {countDisplay}
                </Card>
            );
        }
    }
}