/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountingNumber,
  ActorRelatedNotesView,
  AddOrUpdateAddressCollectionItem,
  AddOrUpdateCustomerInfo,
  AddOrUpdateSupplierInfo,
  AddressCollectionItem,
  BusinessResponse,
  CreateFileOrFolder,
  CreateNote,
  CreateOrUpdateOrganizationWebRequest,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  Handler,
  HandlerType,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  OrganizationAggregationResultOrganizationAggregateQueryAggregationResults,
  OrganizationAggregationTypes,
  OrganizationSortOption,
  OrganizationView,
  OrganizationViewEntityViewModel,
  OrganizationViewPagedOrganizationQueryCollectionViewModel,
  PreviewType,
  RevertFileToVersion,
  SortDirection,
  UpdateFileOrFolder,
  UpdateNote,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Organizations<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Organization
   * @name GetOrganizationById
   * @request GET:/api/organizations/{id}
   * @secure
   */
  getOrganizationById = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationViewEntityViewModel, any>({
      path: `/api/organizations/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name UpdateOrganization
   * @request PUT:/api/organizations/{id}
   * @secure
   */
  updateOrganization = (id: string, data: CreateOrUpdateOrganizationWebRequest, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name DeleteOrganization
   * @request DELETE:/api/organizations/{id}
   * @secure
   */
  deleteOrganization = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name CreateOrganization
   * @request POST:/api/organizations
   * @secure
   */
  createOrganization = (data: CreateOrUpdateOrganizationWebRequest, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name QueryOrganizations
   * @request GET:/api/organizations
   * @secure
   */
  queryOrganizations = (
    query?: {
      sortBy?: OrganizationSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      hasOrganizationNumber?: boolean;
      handlerId?: string;
      handlerRoles?: HandlerType[];
      businessRelationTypes?: string[];
      /** @format date-time */
      notCalledSince?: string;
      hasActiveSubscription?: boolean;
      tagIds?: string[];
      isSupplier?: boolean;
      isCustomer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrganizationViewPagedOrganizationQueryCollectionViewModel, any>({
      path: `/api/organizations`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name RestoreOrganization
   * @request POST:/api/organizations/{id}/restore
   * @secure
   */
  restoreOrganization = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name UpdateOrganizationAttributes
   * @request POST:/api/organizations/{id}/attributes
   * @secure
   */
  updateOrganizationAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name GetOrganizationAvatar
   * @request GET:/api/organizations/{id}/avatar
   * @secure
   */
  getOrganizationAvatar = (
    id: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/organizations/${id}/avatar`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name UpdateOrganizationAvatar
   * @request POST:/api/organizations/{id}/avatar
   * @secure
   */
  updateOrganizationAvatar = (
    id: string,
    data: {
      /** @format binary */
      avatar?: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/avatar`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name DeleteOrganizationAvatar
   * @request DELETE:/api/organizations/{id}/avatar
   * @secure
   */
  deleteOrganizationAvatar = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/avatar`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name AddOrUpdateOrganizationHandler
   * @request PUT:/api/organizations/{id}/handlers/{employeeId}
   * @secure
   */
  addOrUpdateOrganizationHandler = (id: string, employeeId: string, data: Handler, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/handlers/${employeeId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name RemoveOrganizationHandler
   * @request DELETE:/api/organizations/{id}/handlers/{employeeId}
   * @secure
   */
  removeOrganizationHandler = (id: string, employeeId: string, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/handlers/${employeeId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name AddOrUpdateOrganizationCustomerInfo
   * @request POST:/api/organizations/{id}/customerInfo
   * @secure
   */
  addOrUpdateOrganizationCustomerInfo = (id: string, data: AddOrUpdateCustomerInfo, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/customerInfo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name DeleteOrganizationCustomerInfo
   * @request DELETE:/api/organizations/{id}/customerInfo
   * @secure
   */
  deleteOrganizationCustomerInfo = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/customerInfo`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name AddOrUpdateOrganizationSupplierInfo
   * @request POST:/api/organizations/{id}/supplierInfo
   * @secure
   */
  addOrUpdateOrganizationSupplierInfo = (id: string, data: AddOrUpdateSupplierInfo, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/supplierInfo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name DeleteOrganizationSupplierInfo
   * @request DELETE:/api/organizations/{id}/supplierInfo
   * @secure
   */
  deleteOrganizationSupplierInfo = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/${id}/supplierInfo`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name GetOrganizationRelatedNotes
   * @request GET:/api/organizations/{id}/relatedNotes
   * @secure
   */
  getOrganizationRelatedNotes = (
    id: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActorRelatedNotesView[], any>({
      path: `/api/organizations/${id}/relatedNotes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name CreateOrganizationAddress
   * @request POST:/api/organizations/{id}/addresses
   * @secure
   */
  createOrganizationAddress = (id: string, data: AddOrUpdateAddressCollectionItem, params: RequestParams = {}) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/organizations/${id}/addresses`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name UpdateOrganizationAddress
   * @request PUT:/api/organizations/{id}/addresses/{addressId}
   * @secure
   */
  updateOrganizationAddress = (
    id: string,
    addressId: string,
    data: AddOrUpdateAddressCollectionItem,
    params: RequestParams = {},
  ) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/organizations/${id}/addresses/${addressId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name DeleteOrganizationAddress
   * @request DELETE:/api/organizations/{id}/addresses/{addressId}
   * @secure
   */
  deleteOrganizationAddress = (id: string, addressId: string, params: RequestParams = {}) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/organizations/${id}/addresses/${addressId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name UpdateOrganizationAddressAttributes
   * @request POST:/api/organizations/{id}/addresses/{addressId}/attributes
   * @secure
   */
  updateOrganizationAddressAttributes = (
    id: string,
    addressId: string,
    data: Record<string, string>,
    params: RequestParams = {},
  ) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/organizations/${id}/addresses/${addressId}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name ConvertOrganizationFromPerson
   * @request POST:/api/organizations/_convertFromPerson/{id}
   * @secure
   */
  convertOrganizationFromPerson = (id: string, params: RequestParams = {}) =>
    this.http.request<OrganizationView, any>({
      path: `/api/organizations/_convertFromPerson/${id}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name GetOrganizationAggregates
   * @request GET:/aggs/organizations
   * @secure
   */
  getOrganizationAggregates = (
    query?: {
      organizationId?: string;
      aggs?: OrganizationAggregationTypes[];
      hasOrganizationNumber?: boolean;
      handlerId?: string;
      handlerRoles?: HandlerType[];
      businessRelationTypes?: string[];
      /** @format date-time */
      notCalledSince?: string;
      hasActiveSubscription?: boolean;
      tagIds?: string[];
      isSupplier?: boolean;
      isCustomer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrganizationAggregationResultOrganizationAggregateQueryAggregationResults, any>({
      path: `/aggs/organizations`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name GetOrganizationAggregatesById
   * @request GET:/aggs/organizations/{id}
   * @secure
   */
  getOrganizationAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/organizations/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name GetByndleActive
   * @request GET:/api/organizations/byndle/enabled
   * @secure
   */
  getByndleActive = (params: RequestParams = {}) =>
    this.http.request<boolean, any>({
      path: `/api/organizations/byndle/enabled`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name GetByndleAccounting
   * @request GET:/api/organizations/{id}/accounting
   * @secure
   */
  getByndleAccounting = (id: string, params: RequestParams = {}) =>
    this.http.request<AccountingNumber[], any>({
      path: `/api/organizations/${id}/accounting`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Organization
   * @name GetByndleBusiness
   * @request GET:/api/organizations/{id}/business
   * @secure
   */
  getByndleBusiness = (id: string, params: RequestParams = {}) =>
    this.http.request<BusinessResponse, any>({
      path: `/api/organizations/${id}/business`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name GetOrganiationFileById
   * @request GET:/api/organizations/{entityId}/files/{fileId}
   * @secure
   */
  getOrganiationFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/organizations/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name UpdateOrganiationFile
   * @request PUT:/api/organizations/{entityId}/files/{fileId}
   * @secure
   */
  updateOrganiationFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/organizations/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name DeleteOrganiationFile
   * @request DELETE:/api/organizations/{entityId}/files/{fileId}
   * @secure
   */
  deleteOrganiationFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/organizations/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name DownloadOrganizationFile
   * @request GET:/api/organizations/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadOrganizationFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/organizations/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name PreviewOrganizationFile
   * @request GET:/api/organizations/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewOrganizationFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/organizations/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name CreateOrganiationFile
   * @request POST:/api/organizations/{entityId}/files
   * @secure
   */
  createOrganiationFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/organizations/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name QueryOrganiationFiles
   * @request GET:/api/organizations/{entityId}/files
   * @secure
   */
  queryOrganiationFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/organizations/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name RestoreOrganiationFile
   * @request POST:/api/organizations/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreOrganiationFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/organizations/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name MoveOrganiationFile
   * @request POST:/api/organizations/{entityId}/files/{fileId}/move
   * @secure
   */
  moveOrganiationFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/organizations/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name RevertOrganiationFile
   * @request POST:/api/organizations/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertOrganiationFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/organizations/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name GetOrganiationFolderById
   * @request GET:/api/organizations/{entityId}/folders/{folderId}
   * @secure
   */
  getOrganiationFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/organizations/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name UpdateOrganiationFolder
   * @request PUT:/api/organizations/{entityId}/folders/{folderId}
   * @secure
   */
  updateOrganiationFolder = (
    entityId: string,
    folderId: string,
    data: UpdateFileOrFolder,
    params: RequestParams = {},
  ) =>
    this.http.request<FolderView, any>({
      path: `/api/organizations/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name DeleteOrganiationFolder
   * @request DELETE:/api/organizations/{entityId}/folders/{folderId}
   * @secure
   */
  deleteOrganiationFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/organizations/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name CreateOrganiationFolder
   * @request POST:/api/organizations/{entityId}/folders
   * @secure
   */
  createOrganiationFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/organizations/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name QueryOrganiationFolders
   * @request GET:/api/organizations/{entityId}/folders
   * @secure
   */
  queryOrganiationFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/organizations/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name RestoreOrganiationFolder
   * @request POST:/api/organizations/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreOrganiationFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/organizations/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationFile
   * @name MoveOrganiationFolder
   * @request POST:/api/organizations/{entityId}/{folderId}/move
   * @secure
   */
  moveOrganiationFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/organizations/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationNote
   * @name GetOrganizationNoteById
   * @request GET:/api/organizations/{entityId}/notes/{noteId}
   * @secure
   */
  getOrganizationNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/organizations/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationNote
   * @name UpdateOrganizationNote
   * @request PUT:/api/organizations/{entityId}/notes/{noteId}
   * @secure
   */
  updateOrganizationNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/organizations/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationNote
   * @name DeleteOrganizationNote
   * @request DELETE:/api/organizations/{entityId}/notes/{noteId}
   * @secure
   */
  deleteOrganizationNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/organizations/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationNote
   * @name CreateOrganizationNote
   * @request POST:/api/organizations/{entityId}/notes
   * @secure
   */
  createOrganizationNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/organizations/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationNote
   * @name QueryOrganizationNotes
   * @request GET:/api/organizations/{entityId}/notes
   * @secure
   */
  queryOrganizationNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/organizations/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrganizationNote
   * @name RestoreOrganizationNote
   * @request POST:/api/organizations/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreOrganizationNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/organizations/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
