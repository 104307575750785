import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Card } from 'antd'
import * as Highcharts from 'highcharts/highcharts-gantt';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import exportdata from 'highcharts/modules/export-data';
import boost from 'highcharts/modules/boost';
import { Loading3QuartersOutlined } from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import { TaskType, TaskView } from "../../ApiClient/swagger/data-contracts";

exporting(Highcharts);
exportdata(Highcharts);
boost(Highcharts);

interface TaskGanttChartProps {
    title?: string;
    height?: number;
    className?: string;
    cardClassName?: string;
    data: TaskView[];
    nameProperty: string;
    startProperty: string;
    endProperty: string;
    estimateProperty?: string;
    durationProperty?: string;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    loading?: boolean;
}

export class TaskGantChart extends React.Component<TaskGanttChartProps> {
    render = () => {

        let series = [];
        const mappedData = []
        const noParentsFirst = _.sortBy(this.props.data || [], d => d.parents.length)
        let containerWithNoParentCount = 0;
        const mapped = {};
        _.each(noParentsFirst || [], (d, key) => {

            const hasPlannedStart = d[this.props.startProperty] != null;
            const hasPlannedEnd = d[this.props.endProperty] != null;

            const hasActualStart = d.actualStart != null;
            const hasActualEnd = d.actualEnd != null;

            let start: moment.Moment = null
            let end: moment.Moment = null;
            let milestone = false;
            //var secondaryStart: moment.Moment = null
            //var secondaryEnd: moment.Moment = null;

            if (hasActualEnd && hasActualStart) {
                //Farga søyle mellom de to datoene
                start = moment(d["actualStart"]).startOf('day');
                end = moment(d["actualEnd"]).endOf('day');
            }

            else if (hasPlannedEnd && hasActualStart) {
                //Farga søyle fra start og frem til dagens dato.Dusere farge fra start og frem til planlagt slutt(den duse fargen vil ikke vises i de tilfeller hvor dagens dato har passert planlagt slutt)
                start = moment(d["actualStart"]).startOf('day');
                end = moment().endOf('day'); //moment(d[this.props.endProperty]).endOf('day');

                //secondaryStart = end.clone();
                //secondaryEnd = moment().endOf('day');
            }

            else if (hasPlannedEnd && hasPlannedStart) {
                //Søyle med dus farge mellom de to datoene
                start = moment(d[this.props.startProperty]).startOf('day');
                end = moment(d[this.props.endProperty]).endOf('day');
            }

            else if (hasPlannedEnd) {
                //Et punkt
                start = moment(d[this.props.endProperty]).startOf('day')
                end = moment(d[this.props.endProperty]).endOf('day');
                milestone = true;
            }

            else if (hasActualStart) {
                //Farga søyle fra start og frem til dagens dato
                start = moment(d["actualStart"]).startOf('day');
                end = moment().endOf('day');
            }

            else if (hasPlannedStart) {
                //Et punkt
                start = moment(d[this.props.startProperty]).startOf('day');
                end = moment(d[this.props.startProperty]).endOf('day');
                milestone = true;
            }

            else {
                //Blankt
            }

            if (start != null && end != null && start.isSameOrBefore(end)) {

                const estimate = d[this.props.estimateProperty] ?? null;
                const duration = d[this.props.durationProperty] ?? null;

                let progress = 0;

                if (estimate && duration) {
                    progress = duration / estimate;
                }

                const completed = progress ? parseFloat(progress.toFixed(1)) : 0;

                let sortingValue = parseFloat(containerWithNoParentCount.toString());
                if (d.parents.length > 0) {
                    const parentEntry = _.find(series, val => val.name === d.parents[0].id); //[0] is immidiate parent

                    if (parentEntry) {
                        if (mapped[parentEntry.data[0].sortingValue])
                            mapped[parentEntry.data[0].sortingValue]++;
                        else
                            mapped[parentEntry.data[0].sortingValue] = 1;

                        sortingValue = parentEntry.data[0].sortingValue + (mapped[parentEntry.data[0].sortingValue] * (10 ** (-d.parents.length)));
                    }
                }
                else
                    containerWithNoParentCount++;


                const value = {
                    name: d.id ?? key,
                    //id: d["id"] ?? key,
                    data: [{
                        name: d[this.props.nameProperty] ?? "",
                        start: start.valueOf(),
                        end: end.valueOf(),
                        completed: completed,
                        milestone: milestone,
                        sortingValue: sortingValue
                        //opacity: secondaryStart && secondaryEnd ? 0.5 : 1,
                        //y: key
                    }],
                    boostThreshold: 1,
                }
                //if (secondaryStart && secondaryEnd) {
                //    value.data.push({
                //        name: d[this.props.nameProperty] ?? "",
                //        start: secondaryStart.valueOf(),
                //        end: secondaryEnd.valueOf(),
                //        completed: completed,
                //        milestone: milestone,
                //        opacity: 1,
                //        y: key
                //    })
                //}

                series.push(value);

                mappedData.push({
                    name: d[this.props.nameProperty] ?? "",
                    start: start.format(),
                    end: end.format(),
                    completed: completed,
                    milestone: milestone
                });
            }
        });

        if (series && series.length == 0) {
            return "No data to display.";
        }

        series = _.sortBy(series, s => {
            return s.data[0].sortingValue;
        });

        const sortedByStartDate = _.sortBy(mappedData || [], d => { return moment(d.start); });
        const sortedByEndDate = _.sortBy(mappedData || [], d => { return moment(d.end); }).reverse();

        const firstStartDate = _.find(sortedByStartDate, d => { return d.start; });
        const firstEndDate = _.find(sortedByEndDate, d => { return d.end; });

        const startDate = this.props.startDate ?? firstStartDate ? moment(firstStartDate.start) : moment();
        const endDate = this.props.endDate ?? firstEndDate ? moment(firstEndDate.end) : moment();

        const startWeek = startDate.startOf('week');
        const endWeek = endDate.endOf('week');
        const totalWeeks = endWeek.diff(startWeek, "week"); //use this to display months instead of weeks if duration is long...?
        let tickInterval = 1000 * 60 * 60 * 24; //Equals one day

        if (totalWeeks > 75) {
            tickInterval = tickInterval * 365; //Year
        }
        else if (totalWeeks > 25) {
            tickInterval = tickInterval * 30 //Month
        }
        else {
            tickInterval = tickInterval * 7; //Week
        }

        const options: Highcharts.Options = {
            credits: {
                enabled: false
            },
            chart: {
                type: 'gantt',
                backgroundColor: null,
                height: this.props.height ? this.props.height : (series.length * 40) + 110,
                width: null
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "separator",
                            "downloadPNG",
                            "downloadJPEG",
                            "downloadPDF",
                            "downloadSVG",
                            "separator",
                            "downloadCSV",
                            "downloadXLS"
                        ]
                    }
                }
            },
            boost: {
                useGPUTranslations: true,
                // Chart-level boost when there are more than 5 series in the chart
                seriesThreshold: 5
            },
            series: series,
            title: {
                text: null
            },
            xAxis: {
                currentDateIndicator: {
                    color: 'red'
                },
                dateTimeLabelFormats: {
                    week: 'W%W'
                },
                min: startWeek.valueOf(),
                max: endWeek.valueOf(),
                tickInterval: tickInterval
            },
        };

        return (
            <HighchartsReact
                highcharts={Highcharts}
                constructorType="ganttChart"
                containerProps={{
                    style: {
                        textAlign: "-webkit-center",
                        display: 'inline-block',
                        width: '100%'
                    },
                    className: `chart gantt ${this.props.className ? this.props.className : ""}`
                }}
                options={options}
            />
        );
    }
}


export class TaskGanttChartCard extends React.Component<TaskGanttChartProps> {
    render = () => {
        if (this.props.data === undefined) return null;

        return (
            <Card
                title={typeof this.props.title == "string" ? <Title level={4} className="title">{this.props.title}</Title> : this.props.title}
                className={`graph-card gantt-card ${this.props.cardClassName}`}
            >
                {this.props.loading ?
                    <Loading3QuartersOutlined spin className="icon icon-loading" /> :
                    <TaskGantChart {...this.props} />
                }
            </Card>
        );
    }
}