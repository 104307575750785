import * as React from "react";
import { Progress, Popover } from 'antd';
import { formatCurrency } from "../../Helpers/Formatters";

export interface PriceProgressProps {
    invoiced: number;
    runningSales: number;
    type?: "circle" | "line" | "dashboard";
    hideInvoicedText?: boolean;
}

export class PriceProgressBar extends React.Component<PriceProgressProps> {

    private percentage: number;
    private progress: number;

    constructor(props) {
        super(props)
        this.state = {};
    }

    render = () => {
        const { invoiced, runningSales } = this.props;

        const tooltip = (
            <dl>
                <dt>Invoiced</dt>
                <dd>{formatCurrency(invoiced)}</dd>
                <dt>Running sales/price</dt>
                <dd>{formatCurrency(runningSales)}</dd>
            </dl>
        );

        const padding = 8;

        if (runningSales <= 0) {
            return (
                <Popover content={tooltip}>
                    <div style={{ display: 'flex', paddingRight: padding }}>
                        {this.props.hideInvoicedText ? null : <span style={{ whiteSpace: 'nowrap', marginRight: 20 }}>{formatCurrency(invoiced)}</span>}
                        <Progress
                            className="progress-bar"
                            type={this.props.type}
                            percent={this.props.invoiced > 0 ? 30 : 0}
                            status={"normal"}
                            format={() => `0%`}
                        />
                    </div>
                </Popover>
            );
        }

        this.percentage = this.progress = runningSales > 0 ? Math.ceil((invoiced / runningSales) * 100) : 0;
        const isOverEstimate = this.percentage > 100;

        if (isOverEstimate) {
            this.percentage = this.percentage % 100;
        }

        return (
            <Popover content={tooltip}>
                <div style={{ display: 'flex', paddingRight: padding  }}>
                    {this.props.hideInvoicedText ? null : <span style={{ whiteSpace: 'nowrap', marginRight: 20 }}>{formatCurrency(invoiced)}</span>}
                    <Progress
                        className="progress-bar"
                        type={this.props.type}
                        percent={this.progress}
                        status={"normal"}
                        format={() => `${this.progress}%`}
                    />
                </div>
            </Popover>
        );
    }
}

export default PriceProgressBar;