
export const addChangeVectorHeader = (changeVector: string) => {
    return { headers: { "Change-Vector": changeVector } };
}
export const addCustomHeaders = (headers: Record<string,string>) => {
    return { headers: headers };
}
export default {
    addChangeVectorHeader,
    addCustomHeaders
};