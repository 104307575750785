/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  ImageSize,
  MoveFileOrFolder,
  PreviewType,
  RevertFileToVersion,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Files<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags FileSystem
   * @name CreateFile
   * @request POST:/api/files
   * @secure
   */
  createFile = (
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name QueryFiles
   * @request GET:/api/files
   * @secure
   */
  queryFiles = (
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name UpdateFile
   * @request PUT:/api/files/{fileId}
   * @secure
   */
  updateFile = (
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name GetFileById
   * @request GET:/api/files/{fileId}
   * @secure
   */
  getFileById = (fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name DeleteFile
   * @request DELETE:/api/files/{fileId}
   * @secure
   */
  deleteFile = (fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name DownloadFile
   * @request GET:/api/files/{fileId}/download
   * @secure
   */
  downloadFile = (
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name GetThumbnail
   * @request GET:/api/files/{fileId}/thumbnail
   * @secure
   */
  getThumbnail = (fileId: string, params: RequestParams = {}) =>
    this.http.request<File, any>({
      path: `/api/files/${fileId}/thumbnail`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name GetPreview
   * @request GET:/api/files/{fileId}/preview
   * @secure
   */
  getPreview = (
    fileId: string,
    query: {
      type: PreviewType;
      size?: ImageSize;
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name RestoreFile
   * @request POST:/api/files/{fileId}/restore
   * @secure
   */
  restoreFile = (fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name MoveFile
   * @request POST:/api/files/{fileId}/move
   * @secure
   */
  moveFile = (fileId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name RevertFile
   * @request POST:/api/files/{fileId}/revert
   * @secure
   */
  revertFile = (fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
