import * as React from "react";
import _ from 'lodash';
import moment from "moment";

interface CountUpDurationProps {
    start: string;
    interval?: number;
}

interface CountUpDurationState {
    value: moment.Moment;
    interval: number;
    ticks: number;
}

export default class CountUpDuration extends React.Component<CountUpDurationProps, CountUpDurationState> {

    _interval = null;

    constructor(props) {
        super(props);

        this.state = {
            interval: this.props.interval ?? 1000,
            value: this.props.start ? moment(this.props.start) : moment(),
            ticks: 0
        }
    }

    componentDidMount = () => {
        this.initiateCount();
    }

    componentWillUnmount = () => {
        clearInterval(this._interval);
    }

    initiateCount = () => {
        this._interval = setInterval(() => {
            this.setState({ ticks: this.state.ticks + 1 });
        }, this.state.interval);
    }

    render() {
        const now = moment();
        const start = this.state.value;

        const diff = now.diff(start);

        const days = moment.duration(diff).days();
        const hours = moment.duration(diff).hours();
        const minutes = moment.duration(diff).minutes();
        const seconds = moment.duration(diff).seconds();

        const out = [];

        if (days > 0)
            out.push(days + "d");
        if (hours > 0)
            out.push(hours + "h");
        if (minutes > 0)
            out.push(minutes + "m");
        if (hours == 0)
            out.push(seconds + "s");

        return out.join(' ');
    }
}