import { Button, Form } from "antd";
import * as React from "react";
import client from "../../ApiClient/client";
import { PagedRoutineQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import { Drawer } from "../Shared/Drawer";
import EntitySelector from "../Shared/EntitySelector";
import { RequireCapability } from "../Shared/RequireCapability";
import RoutineCreateForm from "./RoutineCreateForm";


export class RoutineSelector extends React.Component<GenericEntitySelectorProps<PagedRoutineQuery>> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select routine...';
        const source = this.props.source ? this.props.source : client.routines.queryRoutines;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.routines.getRoutineById;
        const optionDisplayNameParam = this.props.optionDisplayNameParam ? this.props.optionDisplayNameParam : 'title';

        return (
            <EntitySelector
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                optionDisplayNameParam={optionDisplayNameParam}
                {...this.props}
            />
        );
    }
}


interface RoutineSelectorInputProps extends GenericEntitySelectorProps<PagedRoutineQuery> {
    required?: boolean;
    warningMessage?: string;
    param: string;
    className?: string;
    titleLink?: React.ReactNode;
}

export function RoutineSelectorInput(props: RoutineSelectorInputProps) {

    const context = React.useContext(AppContext);

    const [showCreateDrawer, setShowCreateDrawer] = React.useState(false);

    return (
        <>
            <Form.Item
                name={props.param}
                label={
                    <>
                        <span style={{ width: '100%' }}>{props.title}</span>
                        <RequireCapability capability={Capabilities.RoutinesWrite}>
                            <Button type="link" onClick={() => setShowCreateDrawer((current) => !current)} className={`select-routine-title ${context.isMobile ? "routine-title-mobile" : "routine-title-desktop"}`}>
                                {context.isMobile ? "+ Add" : "+ Add  routine"}
                            </Button>
                        </RequireCapability>
                    </>
                }
            >
                <RoutineSelector {...props} />

                <Drawer
                    title="Add new routine"
                    onClose={() => setShowCreateDrawer(false)}
                    open={showCreateDrawer}
                    destroyOnClose={true}
                    component={<RoutineCreateForm onComplete={() => setShowCreateDrawer(false)} />}
                />
            </Form.Item>
        </>
    );
}

export default RoutineSelector;