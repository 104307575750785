import * as React from "react";
import _ from 'lodash';
import { OrganizationAccessLevel, OrganizationRole, OrganizationRoleView, OrganizationView, PersonView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { PersonSelector } from "../People";
import { TextInput } from "../Shared/TextInput";
import OrganizationAccessLevelSelector from "./OrganizationAccessLevelSelector";



interface OrganizationActorRoleFormProps {
    onComplete: (updated: PersonView) => void;
    onCancel?: () => void;
    organizationId?: string;
    organizationRole?: OrganizationRoleView;
    person?: PersonView;
}

interface OrganizationActorRoleFormState {
    loading: boolean;
    error: string;
    person: Partial<PersonView>;
    showCreatePersonDrawer: boolean;
}

export class OrganizationPersonRoleForm extends React.Component<OrganizationActorRoleFormProps, OrganizationActorRoleFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            showCreatePersonDrawer: false,
            error: null,
            person: this.props.person
        }
    }

    onSubmit = async (tempRequest: OrganizationRole) => {
        this.setState({ loading: true });

        const request: OrganizationRole = {
            accessLevel: tempRequest.accessLevel,
            description: tempRequest.description,
            jobTitle: tempRequest.jobTitle,
        };

        if (request.accessLevel == null)
            request.accessLevel = OrganizationAccessLevel.None;

        const response = await client.people
            .addOrUpdatePersonOrganizationRole(this.state.person.id, this.props.organizationId, request)
            .catch(ex => this.setState({ error: ex.error.title }));

        if (response) this.props.onComplete(response.data);


        this.setState({ loading: false });
    }

    onRemove = async () => {
        if (!this.props.organizationRole) return;

        const response = await client.people
            .deletePersonOrganizationRole(this.state.person.id, this.props.organizationId)
            .catch(ex => this.setState({ error: ex.error.title }));

        if (response) this.props.onComplete(response.data);
    }

    onPersonChange = (person: PersonView) => {
        this.setState({ person });
    }

    render() {
        return (
            <BaseForm
                type={this.props.organizationRole != null ? FormType.Edit : FormType.Create}
                onSubmit={this.onSubmit}
                onDelete={this.props.organizationRole ? this.onRemove : null}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={
                    this.props.organizationRole != null ?
                        {
                            actorId: this.state.person ? this.state.person.id : null,
                            accessLevel: this.props.organizationRole ? this.props.organizationRole.role : null,
                            description: this.props.organizationRole ? this.props.organizationRole.description : null
                        } :
                        {
                            actorId: this.state.person ? this.state.person.id : null
                        }
                }
                className="organization-actor-role-form"
            >
                <SelectorInput
                    param="actorId"
                    warningMessage="Please select a person"
                    title="Person"
                    required
                    selector={
                        <PersonSelector
                            placeholder="Select person"
                            onObjectChange={this.onPersonChange}
                        />
                    }
                />

                {/*<ActorWithCreateInput*/}
                {/*    selectorTitle="Person"*/}
                {/*    param="actorId"*/}
                {/*    required*/}
                {/*    warningMessage="Please select a person"*/}
                {/*    types={[ActorType.Person]}*/}
                {/*    fromOrganization*/}
                {/*    onActorChange={this.onPersonChange}*/}
                {/*    selectorPlaceholder="Select"*/}
                {/*/>*/}

                <TextInput
                    param="description"
                    placeholder="Job title"
                    title="Job title"
                />

{/*                 <SelectorInput
                    param="accessLevel"
                    warningMessage="Please choose access level"
                    title="Access level"
                    selector={
                        <OrganizationAccessLevelSelector
                            placeholder="Access level"
                            excludeAllOption
                        />
                    }
                /> */}
            </BaseForm>
        );
    }
}

export default OrganizationPersonRoleForm;