import * as React from "react";
import moment from "moment";
import _ from 'lodash';
import { Button, Tooltip } from 'antd';
import { PhoneFilled, ArrowRightOutlined, BankOutlined, UserOutlined, LoadingOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Avatar from "antd/lib/avatar/avatar";
import AppContext from "../../Definitions/AppContext";
import { ActivityView, ActorLinkView, CallDirection, CallSortOption, CallStatus, CallTargetView, CallView, ContactPointType, ContactType, PagedCallQuery, SortDirection } from "../../ApiClient/swagger/data-contracts";
import { CallAnimation } from "../../Modules/Calls/CallAnimation";
import { formatDuration, formatEllipsis } from "../../Helpers/Formatters";
import { ContactPointLink, ContactPointPartialLink } from "../../Modules/ContactPoints";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { CountUpCard } from "../../Modules/Shared/CountUpCard";
import { OrganizationLink } from "../../Modules/Organizations";
import { PersonLink } from "../../Modules/People/PersonLink";
import { CustomRouter, withRouter } from "../../Hooks/withRouter";
import { Drawer } from "../../Modules/Shared/Drawer";
import ActivityFromCallForm from "../../Modules/Activities/ActivityFromCallForm";
import { TicketCreateForm } from "../../Modules/Tickets";
import { ActorLink } from "../../Modules/Actors";


interface CallDropdownBoxProps {
    call: CallView;
    history: any;
    setCallDropdownVisible: React.Dispatch<React.SetStateAction<boolean>>;
    ignoreCall: (e: any, call: CallView) => void;
    loadingIgnore: string[];
    loadingIgnoreAll: boolean;
    router?: CustomRouter;
    onTrackCallFinished: (trackedCall: CallView) => void;
}

interface CallDropdownBoxState {
    note: string;
    conflict: boolean;
    error: any;
    saving: boolean;
    call: CallView;
    recentCalls: CallView[];
    showRecentCalls: boolean;
    loadingRecentCalls: boolean;
    trackCall: CallView;
    showCreateTicketDrawer: boolean;
}

class CallDropdownItem extends React.Component<CallDropdownBoxProps, CallDropdownBoxState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props)
        this.state = {
            call: this.props.call,
            note: this.props.call.note,
            saving: false,
            conflict: false,
            error: null,
            recentCalls: [],
            showRecentCalls: this.props.call.status == CallStatus.Connected || this.props.call.status == CallStatus.Ringing ? true : false,
            loadingRecentCalls: false,
            trackCall: null,
            showCreateTicketDrawer: false
        };
    }

    componentDidMount = () => {

        this.context.events.calls.onMany({
            "updated": this.onCallEvent
        });

        if (this.state.call.status == CallStatus.Connected || this.state.call.status == CallStatus.Ringing)
            this.getRecentCalls();
    }

    onCallEvent = (call: CallView) => {
        if (call.id == this.state.call.id) {
            const eventModified = moment(call.lastModified);
            const currentModified = moment(this.state.call.lastModified);

            if (eventModified.isAfter(currentModified)) {
                this.setState({ call, note: call.note });
            }
        }
    }

    onNavigateToCall = (e, callId: string) => {
        e.stopPropagation();
        e.preventDefault();

        this.props.setCallDropdownVisible(false);
        this.props.router.navigate(`/calls/${callId}`)

    }

    onNavigateToTicket = (ticketId: string) => {
        this.props.router.navigate(`/tickets/${ticketId}`)
    }

    callBack = (e, callCP: CallTargetView) => {
        e.stopPropagation();

        if (callCP.type == ContactPointType.Phone) {
            window.location.href = `tel:${callCP.value}`;
        }
        else if (callCP.type == ContactPointType.Video) {
            window.location.href = `sip:${callCP.value}`;
        }
    }

    saveChanges = async () => {
        this.setState({ saving: true });

        try {
            const result = this.state.note && this.state.note != ""
                ? await client.calls.addOrUpdateCallNote(
                    this.state.call.id,
                    { note: this.state.note },
                    //this.state.call.state.changeVector
                )
                : await client.calls.deleteCallNote(
                    this.state.call.id,
                    //this.state.call.state.changeVector
                );

            const call = Object.assign({}, this.state.call);
            call.note = this.state.note;
            //call.deleted = result.data.
            //call.state = result.data.state;

            this.setState({
                call,
                saving: false
            })
        }
        catch (e) {
            this.setState({
                //error: e,
                saving: false
            });
        }
    }

    autoSaveChanges = _.debounce(this.saveChanges, 2000);

    onNoteChange = (event) => {
        const value = event.target.value;

        if (value !== this.state.note) {
            this.setState({ note: value });

            //if (value && value != "") {
            this.autoSaveChanges();
            //}
        }
    }

    getRecentCalls = async () => {
        this.setState({ loadingRecentCalls: true });

        try {
            const { call } = this.props;
            const { to, from } = call;

            const contact = to.contact?.id == this.context.user.actorId ? from : from.contact?.id == this.context.user.actorId ? to : null;

            const filters: Partial<PagedCallQuery> = {
                statuses: [CallStatus.Completed],
                from: 0,
                limit: 5,
                sortBy: CallSortOption.End,
                sortDirection: SortDirection.Desc
            };

            if (contact.contact)
                filters.contactId = contact.contact.id;
            //else
            //    filters.contactPointId = contact.id;

            if (contact?.contact?.id != this.context.user.actorId) {
                const recentCalls = await client.calls.queryCalls(filters);
                this.setState({ recentCalls: recentCalls.data.items });
            }
        }
        catch (error) {

        }

        this.setState({ loadingRecentCalls: false });
    }

    toggleShowRecentCalls = () => {
        if (!this.state.showRecentCalls)
            this.getRecentCalls();

        this.setState({ showRecentCalls: !this.state.showRecentCalls });
    }

    trackCall = (e, call: CallView) => {
        e.stopPropagation();
        this.props.setCallDropdownVisible(false);
        this.setState({ trackCall: call });
    }

    onCloseTrackCall = () => {
        this.setState({ trackCall: null });
        this.props.setCallDropdownVisible(true);
    }

    onTrackCallFinished = (activities: ActivityView[]) => {
        this.props.onTrackCallFinished(Object.assign({}, this.state.trackCall ?? {}));
        this.setState({ trackCall: null });
    }

    openCreateTicketDrawer = (e) => {
        e.stopPropagation();
        this.props.setCallDropdownVisible(false);
        this.setState({ showCreateTicketDrawer: !this.state.showCreateTicketDrawer });
    }

    closeCreateTicketDrawer = () => {
        this.setState({ showCreateTicketDrawer: false });
        this.props.setCallDropdownVisible(true);
    }

    render = () => {
        const { call } = this.state;

        const knownDirection = call.from?.contact && call.from?.contact?.id == this.context.user.actorId ? "Outgoing" : "Incoming";

        const callActorValue = knownDirection == "Incoming" ? call.from?.contact : knownDirection == "Outgoing" ? call.to?.contact : null;
        const callCP = knownDirection == "Incoming" ? call.from : knownDirection == "Outgoing" ? call.to : null;

        const isRinging = call.status == CallStatus.Ringing;

        const actionBtnClass = call.status == CallStatus.Completed ? "track-btn" : call.status == CallStatus.Missed ? "call-back-btn" : "track-btn";
        const actionBtnText = call.status == CallStatus.Completed ? "Track" : call.status == CallStatus.Missed ? "Call back" : "Track";

        const orgRolesView = _.map(callCP?.organizationRoles || [], r => {
            return (
                <div key={r.organization.id}>
                    <ActorLink {...r.organization} />
                    {r.description ? <div>{r.description}</div> : null}
                    {r.endDate ? <div>(Ended: {moment(r.endDate).format('DD.MM.YYYY')})</div> : null}
                </div>
            );
        });

        const calendarFormats: moment.CalendarSpec = {
            sameDay: '[Today] HH:mm',
            nextDay: '[Tomorrow] HH:mm',
            nextWeek: 'dddd HH:mm',
            lastDay: '[Yesterday] HH:mm',
            lastWeek: '[Last] dddd HH:mm',
            sameElse: 'DD.MM.YYYY HH:mm'
        };

        let displayDate = null;
        let callIcon = null;

        switch (call.status) {
            case CallStatus.Ringing:
                displayDate = call.start ? moment(call.start).calendar(calendarFormats) : moment().calendar(calendarFormats);
                callIcon = <CallAnimation className="call-icon call-icon-animating" animate={call.status == CallStatus.Ringing} />;
                break;
            case CallStatus.Connected:
                displayDate = moment(call.start).calendar(calendarFormats);
                callIcon = <PhoneFilled className="call-icon call-icon-connected" />;
                break;
            case CallStatus.Completed:
                displayDate = moment(call.start).calendar(calendarFormats) + " - " + moment(call.end).format('HH:mm');
                callIcon = <PhoneFilled className="call-icon call-icon-completed" />;
                break;
            case CallStatus.Missed:
                displayDate = call.end ? moment(call.end).calendar(calendarFormats) : call.start ? moment(call.start).calendar(calendarFormats) : "Invalid date";
                callIcon = <PhoneFilled className="call-icon call-icon-missed" />;
                break;
        }

        const loadingIgnore = (this.props.loadingIgnore != null && this.props.loadingIgnore.includes(call.id)) || this.props.loadingIgnoreAll ? true : false;

        const user = this.context.user;
        let isEditable = false;
        if (user.hasCapability(Capabilities.CallsWrite))
            isEditable = true;
        else if (user.hasCapability(Capabilities.CallsWriteRelated)) {
            const toOrganizations = _.map((call.to?.organizationRoles ?? []), role => {
                return role.organization?.id;
            });
            const fromOrganizations = _.map((call.from?.organizationRoles ?? []), role => {
                return role.organization?.id;
            });
            const allCallOrganizations = toOrganizations.concat(fromOrganizations);

            const allUserOrganizations = user.companies ? Object.keys(user.companies).concat(user.handlerCompanies) : user.handlerCompanies

            let authorized = false;
            _.each(allUserOrganizations, org => {
                if (_.indexOf(allCallOrganizations, org) != -1)
                    authorized = true;
            });

            isEditable = authorized;
        }
        else if (user.hasCapability(Capabilities.CallsWriteActor))
            isEditable = user.actorId == call.to?.contact?.id || user.actorId == call.from?.contact?.id;

        const stringValue = this.state.note || "";
        const noteValue = this.state.call.note || "";
        //var willDelete = stringValue == "" && noteValue != "";
        const isChanged = stringValue !== noteValue;

        const text = this.state.saving ? <span>Saving... <LoadingOutlined /></span> : isChanged ? "" : stringValue == null || stringValue == "" ? "" : "Saved";

        const actorDescription = null; // callActorValue != null ? formatEllipsis(callActorValue.description || "", 80) : null;

        const contact = call.to?.contact?.id == this.context.user.actorId ? call?.from : call.from?.contact?.id == this.context.user.actorId ? call.to : null;
        const recentCallsView = _.map(this.state?.recentCalls, rc => {
            const notContact = rc?.to?.contact?.id == contact?.contact?.id
                ? rc?.from
                : rc?.from?.contact?.id == contact?.contact?.id
                    ? rc?.to
                    : rc?.direction == CallDirection.Incoming
                        ? rc?.to
                        : rc?.direction == CallDirection.Outgoing
                            ? rc?.from
                            : null;

            return (
                <React.Fragment key={rc.id}>
                    <tr className="rc-row" key={rc.id}>
                        <td className="rc-date">{moment(rc.start).calendar(calendarFormats)}</td>
                        <td className="rc-duration">{formatDuration(rc.duration)}</td>
                        <td className="rc-actor">{notContact ? notContact.contact ? <PersonLink {...notContact.contact} /> : <ContactPointLink {...notContact} hideLabel /> : null}</td>
                    </tr>
                    <tr className="note-row">
                        <td colSpan={3} className="rc-note">{rc.note}</td>
                    </tr>
                </React.Fragment>
            );
        });

        return (
            <div className={`call-item ${call.status == CallStatus.Ringing || call.status == CallStatus.Connected ? "call-item-active" : ""}`}>

                <div className="call-item-header">
                    <div className="call-status-container">
                        <div className="call-status">{callIcon}{call.status == CallStatus.Ringing
                            ? knownDirection
                            : call.status == CallStatus.Connected
                                ? "Active"
                                : call.status} call <span className="call-date">{displayDate}</span>{call.status == CallStatus.Connected
                                    ? <CountUpCard start={call.start} displayCountOnly />
                                    : null}
                        </div>
                        {callCP != null ? <div className="fromOrToText">{knownDirection == CallDirection.Incoming ? "From: " : knownDirection == CallDirection.Outgoing ? "To: " : null}<ContactPointPartialLink contactPoint={callCP} onEditClick={() => this.props.setCallDropdownVisible(false)} hideLabel disablePopover /></div> : null}
                    </div>

                    <div>
                        <Button type="link" key={call.id} style={{ whiteSpace: 'nowrap' }} onClick={(e) => this.onNavigateToCall(e, call.id)}>More info <ArrowRightOutlined /></Button>
                    </div>
                </div>

                <div className="content-container">
                    <div className="note-container">
                        <TextArea
                            className="note-input"
                            value={this.state.note}
                            placeholder="Note"
                            readOnly={this.state.saving || !isEditable}
                            onChange={this.onNoteChange}
                            rows={5}
                        />
                    </div>

                    <div className="actor-details">
                        <div className="actor-details-header">
                            <div className="info">
                                <div className="fromOrToActor">{callActorValue != null ? <PersonLink disablePopover {...callActorValue} /> : callCP?.value ?? null}</div>
                                {orgRolesView && orgRolesView.length > 0 ?
                                    <React.Fragment>
                                        <div className="company">{orgRolesView}</div>
                                        {actorDescription != null ? <div className="actorDescription"><Tooltip title={actorDescription}>{actorDescription}</Tooltip></div> : null}
                                    </React.Fragment>
                                    : null}
                            </div>
                            <div className="avatar-container">
                                {callActorValue != null
                                    ? <Avatar shape="square" size={80} src={`/api/actors/${callActorValue.id}/avatar`} icon={callActorValue.contactType === ContactType.Organization ? <BankOutlined /> : <UserOutlined />} />
                                    : <Avatar shape="square" size={80} icon={<UserOutlined />} />
                                }
                            </div>
                        </div>

                        <div className="track-actions">
                            <div>
                                <Button disabled={isRinging} size="small" className={actionBtnClass} onClick={(e) => call.status == CallStatus.Missed ? this.callBack(e, callCP) : this.trackCall(e, call)}>{actionBtnText}</Button>
                                <Button disabled={isRinging || call.status == CallStatus.Connected || loadingIgnore} size="small" onClick={(e) => this.props.ignoreCall(e, call)} loading={loadingIgnore}>Hide</Button>
                            </div>
                            <Button disabled={isRinging} size="small" style={{ marginTop: "5px" }} onClick={(e) => this.openCreateTicketDrawer(e)}>New ticket</Button>
                        </div>
                    </div>
                </div>

                <div style={{ height: 25 }}>
                    <Button type="link" size="small" style={{ margin: 0, padding: 0, cursor: 'auto' }} disabled={!isChanged}>{text}</Button>
                </div>

                <div className="rc-container">
                    <div className="rc-header" onClick={this.toggleShowRecentCalls}><span className="toggle-icon">{this.state.loadingRecentCalls ? <LoadingOutlined spin /> : this.state.showRecentCalls ? <UpOutlined /> : <DownOutlined />}</span> Recent calls</div>
                    {this.state.loadingRecentCalls ? null : this.state.showRecentCalls ? <table><tbody>{recentCallsView}</tbody></table> : null}
                </div>


                {this.state.trackCall != null ?
                    <Drawer
                        title={"Track call"}
                        onClose={this.onCloseTrackCall}
                        open={this.state.trackCall != null}
                        destroyOnClose
                        component={
                            <ActivityFromCallForm
                                calls={[this.state.trackCall]}
                                call={this.state.trackCall}
                                actorId={this.context.user.actorId}
                                onClose={this.onCloseTrackCall}
                                onComplete={this.onTrackCallFinished}
                            />
                        }
                    /> : null}

                <Drawer
                    title="Create ticket"
                    onClose={this.closeCreateTicketDrawer}
                    open={this.state.showCreateTicketDrawer}
                    component={
                        <TicketCreateForm
                            onComplete={(ticket) => {
                                this.setState({ showCreateTicketDrawer: false });
                                this.onNavigateToTicket(ticket.id);
                            }}
                            onCancel={this.closeCreateTicketDrawer}
                            filters={{
                                customerId: this.state.call.to?.contact?.id == this.context.user.actorId 
                                    ? (this.state.call.from?.organizationRoles?.length > 0 ? this.state.call.to?.organizationRoles[0]?.organization?.id : null) 
                                    : (this.state.call.to?.organizationRoles?.length > 0 ? this.state.call.to?.organizationRoles[0]?.organization?.id : null),
                                requesterId: this.state.call.to?.contact?.id == this.context.user.actorId ? this.state.call.from?.contact?.id : this.state.call.to?.contact?.id,
                                description: this.state.call.note,
                            }}
                        />
                    }
                />
            </div>
        );
    }
}

export default withRouter(CallDropdownItem);