import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { UpOutlined, DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Form, Radio, RadioChangeEvent, InputNumber, Space, Alert, Tooltip } from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import { OrganizationSortOption, PersonSortOption, ResourceSortOptions, RoutineView, TaggableType, UpdateRoutine } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { PersonSelector } from "../People";
import { Capabilities } from "../../Definitions/_capabilties";
import RoutinePeriodSelector from "./RoutinePeriodSelector";
import DateSelector from "../Shared/DateSelector";
import SeveritySelector from "./SeveritySelector";
import { OrganizationSelector } from "../Organizations";
import { ResourceSelector } from "../Resources";
import { TagSelector } from "../Tags";
import CheckboxlistSelector from "../Shared/CheckboxlistSelector";
import RoutineCategorySelector from "./RoutineCategorySelector";

interface EditRoutineProps {
    routine: RoutineView;
    onComplete: (updated: RoutineView) => void;
    onCancel: () => void;
}

interface EditRoutineState {
    loading: boolean;
    error: string;
    displayMoreInfo: boolean;
    periodical: boolean;
    allChecked: boolean;
    responsible: string[];
    checklist: string[];
    affectNextChecked: boolean;
}

class RoutineEditForm extends React.Component<EditRoutineProps, EditRoutineState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            displayMoreInfo: this.props.routine.severity != null || this.props.routine.organization != null || this.props.routine.tags != null || this.props.routine.resource != null,
            periodical: this.props.routine?.frequency != null,
            allChecked: this.props.routine?.allEmployees ?? false,
            responsible: _.map(this.props.routine?.responsible ?? [], res => { return res.id }),
            checklist: this.props.routine.checklist ? _.map(this.props.routine.checklist ?? [], c => c.description) : [],
            affectNextChecked: this.props.routine?.frequency?.basedOnExecution ?? false,
        }
    }

    onSubmit = async (request: UpdateRoutine) => {
        this.setState({ loading: true });

        if (!this.state.periodical || (request.frequency && (request.frequency.interval == null || request.frequency.interval == 0 || request.frequency.type == null))) {
            request.frequency = null;
            //request.startDate = null;
        } else {
            request.frequency.basedOnExecution = this.state.affectNextChecked == true ? true : false;
        }

        if (this.state.allChecked) {
            request.responsibleIds = null;
            request.allEmployees = true;
        }

        const response = await client.routines
            .updateRoutine(this.props.routine.id, request, addChangeVectorHeader(this.props.routine.changeVector))
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.routine != null) {
            const response = await client.routines
                .deleteRoutine(this.props.routine.id, addChangeVectorHeader(this.props.routine.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    onRestore = async () => {
        if (this.props.routine != null) {
            const response = await client.routines
                .restoreRoutine(this.props.routine.id, addChangeVectorHeader(this.props.routine.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    onRoutinePeriodicallyChange = (event: RadioChangeEvent) => {
        this.setState({ periodical: event.target.value });
    }

    onAllCheck = (event: CheckboxChangeEvent) => {
        this.setState({ allChecked: event.target.checked });
    }

    onAffectNextCheck = (event: RadioChangeEvent) => {
        this.setState({ affectNextChecked: event.target.value });
    }

    onResponsibleChange = (data) => {
        this.setState({
            responsible: data
        });
    }

    disabledStartDates = (date: moment.Moment) => {
        const tenYearsAgo = moment().subtract(10, "years").startOf('day');
        if (date < tenYearsAgo) return true;

        return false;
    }

    render = () => {
        if (!this.props.routine) return <div>No routine</div>;

        const { user } = this.context;
        const { routine } = this.props;

        const wasPreviouslyNotRecurring = routine.frequency == null;
        const updatedToRecurring = this.state.periodical;

        const alertView = wasPreviouslyNotRecurring && updatedToRecurring
            ? <Alert className="recurring-warning" message="Any planned executions will be deleted by doing this change." type="info" showIcon />
            : null;

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onRestore={this.onRestore}
                onCancel={this.props.onCancel}
                isDeleted={this.props.routine.deleted}
                loading={this.state.loading}
                error={this.state.error}
                className="routine-edit-form"
                initialValues={{
                    title: routine.title,
                    description: routine.description,
                    checklist: this.state.checklist,
                    categoryId: routine.category?.id ?? null,
                    ownerId: routine.owner?.id,
                    responsibleIds: this.state.responsible ?? [],
                    allEmployees: routine.allEmployees,
                    frequency: routine.frequency ? {
                        interval: routine.frequency.interval,
                        type: routine.frequency.type,
                        basedOnExecution: routine.frequency.basedOnExecution,
                        startDate: routine.frequency.startDate ?? moment()
                    } : null,
                    severity: routine.severity,
                    organizationId: routine.organization?.id ?? null,
                    resourceId: routine.resource?.id ?? null,
                    tagIds: _.map(routine.tags ?? [], tag => {
                        if (tag.category?.deleted || tag?.deleted) return;
                        return tag.id;
                    }),
                    startDate: routine.originalNextExecution ?? moment(),
                } as UpdateRoutine}
            >
                <TextInput
                    title="Title"
                    param="title"
                    placeholder="Title"
                    required
                    warningMessage="Title is required"
                />

                <TextAreaInput
                    title="Description"
                    param="description"
                    placeholder="Description"
                    rows={4}
                />

                <SelectorInput
                    param="checklist"
                    title="Checklist"
                    selector={
                        <CheckboxlistSelector />
                    }
                />

                <SelectorInput
                    param="categoryId"
                    title="Type"
                    required
                    selector={
                        <RoutineCategorySelector
                            placeholder="Select category"
                        />
                    }
                />

                <SelectorInput
                    param="ownerId"
                    title="Owner"
                    required
                    selector={
                        <PersonSelector
                            filters={{
                                isEmployee: true,
                                sortBy: PersonSortOption.Name
                            }}
                            disabled={!user.hasCapability(Capabilities.RoutinesWrite)}
                            placeholder="Select employee"
                        />
                    }
                />

                <SelectorInput
                    param="responsibleIds"
                    title="Responsible"
                    titleLink={<div><Checkbox onChange={this.onAllCheck} checked={this.state.allChecked} className="all-employees-form">All employees</Checkbox></div>}
                    className="responsibles-input"
                    selector={
                        <PersonSelector
                            filters={{
                                isEmployee: true,
                                sortBy: PersonSortOption.Name
                            }}
                            placeholder="Select responsible employees"
                            onValueChange={this.onResponsibleChange}
                            disabled={!user.hasCapability(Capabilities.RoutinesWriteRelated) || this.state.allChecked}
                            multiple
                        />
                    }
                />

                <div className="routine-periodical-container">
                    <div className="ant-form-item-label add-more-info-header custom-routine-form-label">
                        <label>Should this routine be executed periodically?</label>
                    </div>
                    <Radio.Group onChange={this.onRoutinePeriodicallyChange} value={this.state.periodical}>
                        <Space direction="vertical">
                            <div className="routine-radio-yes">
                                <Radio value={true}>
                                    <span className="yes-text">Yes, every</span>

                                </Radio>
                                <span className="routine-period-container">
                                    <Form.Item name={["frequency", "interval"]} className="interval-input" rules={
                                        [{
                                            required: this.state.periodical,
                                            message: 'This field is required'
                                        }]}
                                    >
                                        <InputNumber min={1} disabled={!this.state.periodical} placeholder="1" />
                                    </Form.Item>

                                    <Form.Item name={["frequency", "type"]} className="period-input" required={this.state.periodical} rules={
                                        [{
                                            required: this.state.periodical,
                                            message: 'This field is required'
                                        }]}
                                    >
                                        <RoutinePeriodSelector disabled={!this.state.periodical} excludeAllOption />
                                    </Form.Item>
                                </span>
                            </div>
                            <Radio value={false}>No</Radio>
                            <Radio value="" disabled>Triggered by sensor</Radio>
                        </Space>
                    </Radio.Group>

                    {this.state.periodical
                        ? <div className="affect-next-container-edit">
                            <label className="form-title">Start:</label>
                            <Form.Item name={["frequency", "startDate"]} className="start-date-input" rules={
                                [{
                                    required: this.state.periodical,
                                    message: 'This field is required'
                                }]} >
                                <DateSelector disabledDate={this.disabledStartDates} />
                            </Form.Item>

                            <div>
                                <span className="form-title">
                                    If the execution of the routine is expedited or delayed, should the next execution date be adjusted accordingly?
                                    <Tooltip className="help-text" title="If so the system will ensure there will be a period between the executions."><InfoCircleOutlined /></Tooltip>
                                </span>

                                <Radio.Group onChange={this.onAffectNextCheck} value={this.state.affectNextChecked} className="radio-input">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </div>
                            {/*<span className="routine-periodical-input">*/}
                            {/*    <label className="start-title">Start:</label>*/}
                            {/*    <Checkbox onChange={this.onAffectNextCheck} checked={this.state.affectNextChecked}>Affected by executions</Checkbox>*/}
                            {/*</span>*/}
                            {/*<Form.Item name={["frequency", "startDate"]} className="start-date-input" rules={*/}
                            {/*    [{*/}
                            {/*        required: this.state.periodical,*/}
                            {/*        message: 'This field is required'*/}
                            {/*    }]} >*/}
                            {/*    <DateSelector />*/}
                            {/*</Form.Item>*/}
                        </div>
                        : null
                    }

                    {alertView}

                </div>

                <div className={`ant-form-item-label add-more-routine-info-header custom-routine-form-label ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}>
                    <div className="more-info-label">
                        <label>Additional info (optional) <div className="more-info-icon-container">{this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}</div></label>
                    </div>
                </div>

                <div className="more-info-content" style={!this.state.displayMoreInfo ? { display: 'none' } : {}}>
                    <SelectorInput
                        param="severity"
                        title="Severity"
                        selector={
                            <SeveritySelector excludeAllOption />
                        }
                    />

                    <SelectorInput
                        param="organizationId"
                        title="Organization"
                        selector={
                            <OrganizationSelector
                                filters={{
                                    sortBy: OrganizationSortOption.Name
                                }}
                                placeholder="Select organization"
                            />
                        }
                    />

                    <SelectorInput
                        param="resourceId"
                        title="Resource"
                        selector={
                            <ResourceSelector
                                filters={{
                                    sortBy: ResourceSortOptions.Name
                                }}
                                placeholder="Select resource"
                            />
                        }
                    />

                    <SelectorInput
                        param="tagIds"
                        title="Tags"
                        selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Routine] }} />}
                    />
                </div>
            </BaseForm>
        );
    }
}

export default RoutineEditForm;