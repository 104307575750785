/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateNotificationTemplate,
  NotificationTemplateView,
  NotificationTemplateViewNotificationTemplateQueryCollectionViewModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Notificationtemplatess<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags NotificationTemplate
   * @name GetNotificationTemplateById
   * @request GET:/api/notificationtemplatess/{id}
   * @secure
   */
  getNotificationTemplateById = (id: string, params: RequestParams = {}) =>
    this.http.request<NotificationTemplateView, any>({
      path: `/api/notificationtemplatess/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NotificationTemplate
   * @name UpdateNotificationTemplate
   * @request PUT:/api/notificationtemplatess/{id}
   * @secure
   */
  updateNotificationTemplate = (id: string, data: CreateOrUpdateNotificationTemplate, params: RequestParams = {}) =>
    this.http.request<NotificationTemplateView, any>({
      path: `/api/notificationtemplatess/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NotificationTemplate
   * @name QueryNotificationTemplates
   * @request GET:/api/notificationtemplatess
   * @secure
   */
  queryNotificationTemplates = (
    query?: {
      type?: string;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NotificationTemplateViewNotificationTemplateQueryCollectionViewModel, any>({
      path: `/api/notificationtemplatess`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags NotificationTemplate
   * @name GetNotificationTemplateTypes
   * @request GET:/api/notificationtemplatess/_types
   * @secure
   */
  getNotificationTemplateTypes = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/api/notificationtemplatess/_types`,
      method: "GET",
      secure: true,
      ...params,
    });
}
