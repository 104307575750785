/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ContactPointSearchView,
  ContactPointSortOption,
  ContactPointType,
  ContactPointView,
  ContactPointViewPagedContactPointQueryCollectionViewModel,
  ContactPointViewViewModel,
  CreateContactPoint,
  SortDirection,
  UpdateContactPoint,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Contactpoints<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ContactPoint
   * @name GetContactPointById
   * @request GET:/api/contactpoints/{id}
   * @secure
   */
  getContactPointById = (id: string, params: RequestParams = {}) =>
    this.http.request<ContactPointViewViewModel, any>({
      path: `/api/contactpoints/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactPoint
   * @name UpdateContactPoint
   * @request PUT:/api/contactpoints/{id}
   * @secure
   */
  updateContactPoint = (id: string, data: UpdateContactPoint, params: RequestParams = {}) =>
    this.http.request<ContactPointView, any>({
      path: `/api/contactpoints/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactPoint
   * @name DeleteContactPoint
   * @request DELETE:/api/contactpoints/{id}
   * @secure
   */
  deleteContactPoint = (id: string, params: RequestParams = {}) =>
    this.http.request<ContactPointView, any>({
      path: `/api/contactpoints/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactPoint
   * @name CreateContactPoint
   * @request POST:/api/contactpoints
   * @secure
   */
  createContactPoint = (data: CreateContactPoint, params: RequestParams = {}) =>
    this.http.request<ContactPointView, any>({
      path: `/api/contactpoints`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactPoint
   * @name QueryContactPoints
   * @request GET:/api/contactpoints
   * @secure
   */
  queryContactPoints = (
    query?: {
      sortBy?: ContactPointSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      contactId?: string;
      missingContact?: boolean;
      types?: ContactPointType[];
      domain?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ContactPointViewPagedContactPointQueryCollectionViewModel, any>({
      path: `/api/contactpoints`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactPoint
   * @name SearchContactPoints
   * @request GET:/api/contactpoints/search/{terms}
   * @secure
   */
  searchContactPoints = (terms: string, params: RequestParams = {}) =>
    this.http.request<ContactPointSearchView[], any>({
      path: `/api/contactpoints/search/${terms}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
