import React from "react";
import { ActorLinkView, ActorType } from "../../ApiClient/swagger/data-contracts";
import { OrganizationLink } from "../Organizations";
import { PersonLink } from "../People";


interface ActorLinkProps extends Partial<ActorLinkView> {
    id?: string;
    name?: string;
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
}


export function ActorLink(props: ActorLinkProps) {
    if (!props) return <>-</>;

    if (props.actorType === ActorType.Organization)
        return <OrganizationLink key={props.id} {...props} />;
    else if (props.actorType === ActorType.Person)
        return <PersonLink key={props.id} {...props} />;
    else
        return <React.Fragment key={props.id}>{props.name}</React.Fragment>;
}