import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { PagedProjectQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { QuickSearch } from "../Shared/QuickSearch";


interface ProjectQuickSearchProps {
    onQuickSearch: (filters) => any;
    query: Partial<PagedProjectQuery>;
    hasFilters?: boolean;
}

export class ProjectQuickSearch extends React.Component<ProjectQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <React.Fragment>

                <QuickSearch name="All" queryParameters={{
                    endPeriod: null,
                    startPeriod: null
                } as PagedProjectQuery} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="This year" queryParameters={{
                    endPeriod: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    startPeriod: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss')
                } as PagedProjectQuery} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="Last year" queryParameters={{
                    endPeriod: moment().subtract(1, "year").endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    startPeriod: moment().subtract(1, "year").startOf('year').format('YYYY-MM-DDTHH:mm:ss')
                } as PagedProjectQuery} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="This month" queryParameters={{
                    endPeriod: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                    startPeriod: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss')
                } as PagedProjectQuery} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="Current and future" queryParameters={{
                    endPeriod: null,
                    startPeriod: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                } as PagedProjectQuery} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                {this.props.hasFilters ? null
                    : <QuickSearch name="My projects" queryParameters={{
                        actorId: this.context.user.actorId
                    } as PagedProjectQuery} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>
                }

            </React.Fragment>
        );
    }
}

export default ProjectQuickSearch;