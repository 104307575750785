/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateMeasuringPointTemplate,
  MeasuringPointTemplateFields,
  MeasuringPointTemplateSortOption,
  MeasuringPointTemplateView,
  MeasuringPointTemplateViewEntityViewModel,
  MeasuringPointTemplateViewPagedMeasuringPointTemplateQueryCollectionViewModel,
  SortDirection,
  UpdateMeasuringPointTemplate,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Measuringpointtemplates<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name GetMeasuringPointTemplateById
   * @request GET:/api/measuringpointtemplates/{id}
   * @secure
   */
  getMeasuringPointTemplateById = (id: string, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateViewEntityViewModel, any>({
      path: `/api/measuringpointtemplates/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name UpdateMeasuringPointTemplate
   * @request PUT:/api/measuringpointtemplates/{id}
   * @secure
   */
  updateMeasuringPointTemplate = (id: string, data: UpdateMeasuringPointTemplate, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name DeleteMeasuringPointTemplate
   * @request DELETE:/api/measuringpointtemplates/{id}
   * @secure
   */
  deleteMeasuringPointTemplate = (id: string, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name CreateMeasuringPointTemplate
   * @request POST:/api/measuringpointtemplates
   * @secure
   */
  createMeasuringPointTemplate = (data: CreateMeasuringPointTemplate, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name QueryMeasuringPointTemplates
   * @request GET:/api/measuringpointtemplates
   * @secure
   */
  queryMeasuringPointTemplates = (
    query?: {
      sortBy?: MeasuringPointTemplateSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MeasuringPointTemplateViewPagedMeasuringPointTemplateQueryCollectionViewModel, any>({
      path: `/api/measuringpointtemplates`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name RestoreMeasuringPointTemplate
   * @request POST:/api/measuringpointtemplates/{id}/restore
   * @secure
   */
  restoreMeasuringPointTemplate = (id: string, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name UpdateMeasuringPointTemplateAttributes
   * @request POST:/api/measuringpointtemplates/{id}/attributes
   * @secure
   */
  updateMeasuringPointTemplateAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name CreateMeasuringPointElement
   * @request POST:/api/measuringpointtemplates/{id}/mp
   * @secure
   */
  createMeasuringPointElement = (id: string, data: MeasuringPointTemplateFields, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates/${id}/mp`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name UpdateMeasuringPointElement
   * @request PUT:/api/measuringpointtemplates/{id}/mp/{mpId}
   * @secure
   */
  updateMeasuringPointElement = (
    id: string,
    mpId: string,
    data: MeasuringPointTemplateFields,
    params: RequestParams = {},
  ) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates/${id}/mp/${mpId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointTemplate
   * @name RemoveMeasuringPointElement
   * @request DELETE:/api/measuringpointtemplates/{id}/mp/{mpId}
   * @secure
   */
  removeMeasuringPointElement = (id: string, mpId: string, params: RequestParams = {}) =>
    this.http.request<MeasuringPointTemplateView, any>({
      path: `/api/measuringpointtemplates/${id}/mp/${mpId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
