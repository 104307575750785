import _ from "lodash";
import moment from "moment";
import client from "../client";
import { PagedTicketQuery, TicketView } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig } from "./BaseExportService";

export class TicketExport extends BaseExportService<PagedTicketQuery, TicketView> {

    constructor() {
        
        const specification: ExportConfig<TicketView>[] = [
            { header: 'Ticket number', value: (data: TicketView) => data.ticketNumber ?? null },
            { header: 'Title', value: (data: TicketView) => data.name ?? null },
            { header: 'Description', value: (data: TicketView) => data.description ?? null },
            { header: 'Severity', value: (data: TicketView) => data.severity ?? null },
            { header: 'Status', value: (data: TicketView) => data.status ?? null },
            { header: 'Closed', value: (data: TicketView) => data.closedDate ? moment(data.closedDate).format('DD.MM.YYYY') : null },
            { header: 'Customer', value: (data: TicketView) => data.customer ? data.customer.name : null },
            { header: 'Requester', value: (data: TicketView) => data.requester ? data.requester.name : null },
            { header: 'Assignee', value: (data: TicketView) => data.assignee ? data.assignee.name : null },
            { header: 'Due date', value: (data: TicketView) => data.dueDate ? moment(data.dueDate).format() : null },
            {
                header: 'Tags', value: (data: TicketView) => {
                    let tags = "";
                    _.map(data.tags, (tag, index) => {
                        if (index != 0) tags = tags + ", ";
                        tags = tags + tag.name;
                    })
                    return tags;
                }
            },            
            {
                header: 'Attributes', value: (data: TicketView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })
                    }

                    return dataString;
                }
            },
            { header: 'Created', value: (data: TicketView) => data.created ? moment(data.created).format('DD.MM.YYYY') : null },
            { header: 'Updated', value: (data: TicketView) => data.lastModified ? moment(data.lastModified).format('DD.MM.YYYY') : null },
            { header: 'Deleted', value: (data: TicketView) => data.deleted ? true : false }
        ];

        super(specification, client.tickets.queryTickets);
    }
}