import client from "./client";
import { CreateComment, CommentViewPagedCommentQueryCollectionViewModel, PagedCommentQuery, UpdateComment, CommentViewEntityViewModel, CommentView } from "./swagger/data-contracts";
import { HttpResponse } from "./swagger/http-client";

export interface EntityCommentClient {
    queryEntityComments: (entityId: string, query?: PagedCommentQuery) => Promise<HttpResponse<CommentViewPagedCommentQueryCollectionViewModel, any>>;
    getEntityComment: (entityId: string, commentId: string) => Promise<HttpResponse<CommentViewEntityViewModel, any>>;
    createEntityComment: (entityId: string, data: CreateComment) => Promise<HttpResponse<CommentView, any>>;
    updateEntityComment: (entityId: string, commentId: string, data: UpdateComment) => Promise<HttpResponse<CommentView, any>>;
    deleteEntityComment: (entityId: string, commentId: string) => Promise<HttpResponse<CommentView, any>>;
    restoreEntityComment: (entityId: string, commentId: string) => Promise<HttpResponse<CommentView, any>>;
    addEntityCommentEmoji: (entityId: string, commentId: string, emojieCode: string) => Promise<HttpResponse<CommentView, any>>;
}


export const taskCommentClient: EntityCommentClient = {
    queryEntityComments: client.tasks.queryTaskComments,
    getEntityComment: client.tasks.getTaskCommentById,
    createEntityComment: client.tasks.createTaskComment,
    updateEntityComment: client.tasks.updateTaskComment,
    deleteEntityComment: client.tasks.deleteTaskComment,
    restoreEntityComment: client.tasks.restoreTaskComment,
    addEntityCommentEmoji: client.tasks.addTaskCommentEmoji
}

export const ticketCommentClient: EntityCommentClient = {
    queryEntityComments: client.tickets.queryTicketComments,
    getEntityComment: client.tickets.getTicketCommentById,
    createEntityComment: client.tickets.createTicketComment,
    updateEntityComment: client.tickets.updateTicketComment,
    deleteEntityComment: client.tickets.deleteTicketComment,
    restoreEntityComment: client.tickets.restoreTicketComment,
    addEntityCommentEmoji: client.tickets.addTicketCommentEmoji
}

export const routineCommentClient: EntityCommentClient = {
    queryEntityComments: client.routines.queryRoutineComments,
    getEntityComment: client.routines.getRoutineCommentById,
    createEntityComment: client.routines.createRoutineComment,
    updateEntityComment: client.routines.updateRoutineComment,
    deleteEntityComment: client.routines.deleteRoutineComment,
    restoreEntityComment: client.routines.restoreRoutineComment,
    addEntityCommentEmoji: client.routines.addRoutineCommentEmoji
}

export const incidentCommentClient: EntityCommentClient = {
    queryEntityComments: client.incidents.queryIncidentComments,
    getEntityComment: client.incidents.getIncidentCommentById,
    createEntityComment: client.incidents.createIncidentComment,
    updateEntityComment: client.incidents.updateIncidentComment,
    deleteEntityComment: client.incidents.deleteIncidentComment,
    restoreEntityComment: client.incidents.restoreIncidentComment,
    addEntityCommentEmoji: client.incidents.addIncidentCommentEmoji
}

export const newsCommentClient: EntityCommentClient = {
    queryEntityComments: client.news.queryNewsComments,
    getEntityComment: client.news.getNewsCommentById,
    createEntityComment: client.news.createNewsComment,
    updateEntityComment: client.news.updateNewsComment,
    deleteEntityComment: client.news.deleteNewsComment,
    restoreEntityComment: client.news.restoreNewsComment,
    addEntityCommentEmoji: client.news.addNewsCommentEmoji
}