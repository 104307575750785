import * as React from "react";
import _ from 'lodash';
import { Select } from 'antd';
import { Keyboard } from "../../Definitions/_keyboard";
import { EntitySelectorProps } from "../../Models/EntitySelectorProps";
const { Option } = Select;

interface BaseEntityFilterState {
    collection: any[];
    query: any;
    value: any;
    defaultValues: any;
    loading: boolean;
    isFocused: boolean;
    entities: any;
}

class EntitySelector extends React.Component<EntitySelectorProps, BaseEntityFilterState> {
    _isMounted = false;
    _LastSearch = 0;

    constructor(props: any) {
        super(props)
        this.state = {
            collection: [],
            query: {
                deleted: false
            },
            value: props.value,
            defaultValues: null,
            loading: false,
            isFocused: false,
            entities: null
        };
        this.handleSearch = _.debounce(this.handleSearch, 500);
    }

    componentDidMount = async () => {
        this._isMounted = true;
        document.addEventListener("keydown", this.handleKeyDown);
        this.handleDefaultValue(this.props.value, true);
    }

    componentDidUpdate = async (prevProps: any) => {
        if (this._isMounted) {
            if (!_.isEqual(this.props.value, prevProps.value)) {
                await this.handleDefaultValue(this.props.value);
            }
        }
    }

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.handleKeyDown);
        this._isMounted = false;
    }

    handleKeyDown = (e, fromClick?: boolean) => {
        if (e.keyCode == Keyboard.DELETE) {
            if (this.state.isFocused) {
                this.setState({ value: undefined });

                if (this.props.onChange)
                    this.props.onChange(undefined);

                if (this.props.onObjectChange)
                    this.props.onObjectChange(undefined);

                if (this.props.onValueChange)
                    this.props.onValueChange(undefined);

                this.setState({ entities: null });
            }
        }
    }

    abortController = new AbortController();

    handleDefaultValue = async (nextValue?, fromMount?: boolean) => {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = new AbortController();
        }

        const defaultValue = nextValue ? nextValue : this.props.value ? this.props.value : null;

        try {
            if (defaultValue && this._isMounted) {
                if (defaultValue.length <= 0) {
                    return;
                }
                else if (typeof defaultValue === 'string') {
                    this.setState({ loading: true });

                    var response = await this.props.defaultValueSource(defaultValue, { signal: this.abortController.signal });
                    this.setState({
                        defaultValues: [response.data.view], value: response.data.view.id,
                        loading: false,
                        entities: [response.data.view]
                    });
                }
                else if (defaultValue.length && defaultValue.length > 0) {
                    const defaultValues = [];

                    this.setState({ loading: true });

                    for (let i = 0; i < defaultValue.length; i++) {
                        const item = defaultValue[i];

                        if (typeof item == 'string') {
                            var response = await this.props.defaultValueSource(item, { signal: this.abortController.signal });
                            if(response.data?.view)
                                defaultValues.push(response.data?.view);
                            else
                                defaultValues.push(response.data);
                        }
                        else if (typeof item == 'object') {
                            defaultValues.push(item);
                        }
                    }

                    this.setState({
                        defaultValues: defaultValues,
                        entities: defaultValues,
                        value: undefined,
                        loading: false
                    });
                }
                else if (!defaultValue.length && typeof defaultValue == 'object' && this._isMounted) {
                    if (this.props.onObjectChange)
                        this.props.onObjectChange(defaultValue);

                    this.setState({ defaultValues: [defaultValue] });
                }
            }
            else if (!defaultValue && nextValue != null && this._isMounted) {
                this.setState({
                    defaultValues: [],
                    value: undefined
                });

                if (!fromMount)
                    this.resetCollection();
            }
            else if (!defaultValue && !nextValue && this._isMounted) {
                this.setState({
                    defaultValues: [],
                    value: undefined
                });

                if (!fromMount)
                    this.resetCollection();
            }
        }
        catch (error: any) {
            if (error.name === 'AbortError') {
                return;
            } else {
                this.setState({ loading: false });
            }
        }
    }

    resetCollection = () => {
        this.handleSearch("");
    }

    onLoadCollection = async (query, searchId) => {
        if (this._isMounted)
            this.setState({ loading: true });

        try {
            const updatedQuery = this.props.customQuery ? this.props.customQuery : await this.handleQueryFilters(query, this.props.filters);

            const response = this.props.extraSourceParam
                ? await this.props.source(this.props.extraSourceParam, updatedQuery)
                : await this.props.source(updatedQuery);

            if (response && this._isMounted && this._LastSearch == searchId) {
                const isArray = Array.isArray(response.data);
                this.setState({ collection: isArray ? response.data : response.data.items, query: isArray ? null : response.data.query, loading: false });
            }
        }
        catch (error: any) {
            //this.setState({ error });
        }

        if (this._isMounted)
            this.setState({ loading: false });
    }

    handleQueryFilters = async (query, filters) => {
        _.each(filters, (value, param) => {
            query[param] = value;
        });

        return query;
    }

    handleSearch = (terms) => {
        this._LastSearch += 1;
        const query = Object.assign({}, this.state.query);
        query.terms = terms;
        const searchId = this._LastSearch;
        this.onLoadCollection(query, searchId);
    }

    handleChange = (value) => {
        if (!this._isMounted || (!this.props.onChange && !this.props.onObjectChange)) return;
        this.setState({ value: value, defaultValues: null });

        if (value === undefined || !value || value.length === 0)
            value = null;

        if (this.props.onChange)
            this.props.onChange(value);

        const isArray = value && Array.isArray(value);
        const entities = isArray
            ? _.filter(this.state.collection, o => value.includes(o.id))
            : _.find(this.state.collection, (o) => o.id === value);

        if (this.props.onObjectChange)
            this.props.onObjectChange(entities);

        this.setState({ entities: isArray ? entities : [entities] });
        
        if (this.props.onValueChange) 
            this.props.onValueChange(value);
    }

    handleFocus = () => {
        this.setState({ isFocused: true });
        this.onLoadCollection(this.state.query, this._LastSearch);
    }

    handleBlur = () => {
        this.setState({ isFocused: false });
    }

    onClear = () => {
        this.handleSearch("");
    }

    onSelect = (value) => {
        const query = Object.assign({}, this.state.query);
        query.terms = null;

        this.setState({ query });
    }

    render = () => {
        const { value, defaultValues } = this.state;
        const { optionDisplayNameParam } = this.props;
        let collection = this.state.collection?.slice() ?? [];

        const displayPropertyName = optionDisplayNameParam ? optionDisplayNameParam : 'name';
        const options = [];

        if (value && !Array.isArray(value) && typeof value == "object") {
            collection.push(value);
        }

        if (defaultValues)
            collection = _.uniqBy([...collection, ...defaultValues], 'id');

        if (collection) {
            _.each(collection, (o, key) => {
                if (o) {
                    const isOption = this.props.optionIds == null || (this.props.optionIds != null && _.find(this.props.optionIds, id => id == o.id) != null);
                    if (!isOption) return;

                    const excluded = this.props.exlcludeOptionIds != null && _.find(this.props.exlcludeOptionIds, id => id == o.id) != null;
                    if (excluded) return;

                    const disabled = this.props.disableOptionIds != null && _.find(this.props.disableOptionIds, id => id == o.id) != null;

                    if (this.props.renderOptionItem)
                        options.push(<Option key={o.id} value={o.id} disabled={disabled} label={this.props.renderLabelItem ? this.props.renderLabelItem(o, key === collection.length - 1) : o[displayPropertyName]}>{this.props.renderOptionItem(o, key === collection.length - 1)}</Option>);
                    else
                        options.push(<Option key={o.id} value={o.id} disabled={disabled} label={o[displayPropertyName]}>{o[displayPropertyName]}</Option>);
                }
            });
        }

        const values = _.map(defaultValues, val => {
            return val.id;
        });

        const title = this.props.title ? <label>{this.props.title}</label> : null;
        let info = null;
        if (this.props.renderInfo) {
            info = this.props.renderInfo(this.state.entities);
        }
        return (
            <div className={`selector entity-selector ${this.props.className}`}>
                {title}
                <Select
                    optionLabelProp="label"
                    mode={this.props.multiple ? 'multiple' : null}
                    showSearch
                    allowClear={this.props.disableClear ? false : true}
                    onClear={this.onClear}
                    loading={this.state.loading}
                    size="middle"
                    placeholder={this.props.placeholder}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onSelect={this.onSelect}
                    defaultActiveFirstOption={this.props.defaultActiveFirstOption ? true : false}
                    filterOption={false}
                    className="select"
                    onBlur={this.handleBlur}
                    value={value ? value : values.length > 0 ? values : undefined}
                    disabled={this.props.disabled}
                    maxTagCount={this.props.maxTagCount}
                    dropdownRender={this.props.dropdownRender}
                >
                    {options}
                </Select>
                { info }
            </div>
        );
    }
}

export default EntitySelector;