import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Link } from "react-router-dom";
import AppContext from "../../Definitions/AppContext";
import { ActivitySortOption, ActivityView, SortDirection } from "../../ApiClient/swagger/data-contracts";
import { ActorLink } from "../Actors";
import { ActivityWidget } from "../Activities";


interface TaskRecentHoursProps {
    taskId: string;
}

interface TaskecentHoursState {

}

export class TaskRecentHours extends React.Component<TaskRecentHoursProps, TaskecentHoursState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {};
    }

    onActivityCreatedEvent = (collection: ActivityView[], activity: ActivityView) => {

        if (activity.task && activity.task.id == this.props.taskId && activity.end != null) {
            collection.push(activity);
            collection = _.sortBy(collection, "end").reverse();
        }

        return collection;
    }

    render = () => {
        return (
            <ActivityWidget
                title="Recent hours"
                query={
                    {
                        taskId: this.props.taskId,
                        running: false,
                        deleted: false,
                        sortBy: ActivitySortOption.End,
                        sortDirection: SortDirection.Desc,
                        endBefore: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        limit: 5
                    }
                }
                onItemCreatedEvent={this.onActivityCreatedEvent}
                customColumns={
                    [
                        {
                            title: 'Time',
                            render: (activity: ActivityView) => {
                                let time = activity.start ? moment(activity.start).format('DD.MM.YYYY HH:mm') : "";

                                if (activity.end)
                                    time = time + " - " + moment(activity.end).format('HH:mm');

                                return time;
                            },
                            key: 'start',
                            width: 190
                        },
                        {
                            title: 'Employee',
                            render: (activity: ActivityView) => activity.actor ? <ActorLink {...activity.actor} /> : "",
                            ellipsis: true
                        },
                        {
                            title: 'Description',
                            key: 'description',
                            dataIndex: 'description',
                            ellipsis: true
                        },
                    ]}
                actions={<Link to={`/hours?taskId=${this.props.taskId}`} style={{ whiteSpace: 'nowrap' }}>See all hours</Link>}
            />
        );
    }
}