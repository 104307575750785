/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  EmployeeInfo,
  HourBankView,
  IllnessChildView,
  PersonView,
  UpdateEmployeeHourBankCommand,
  UpdateEmployeeIllnessChildCommand,
  UpdateEmployeeVacationDaysCommand,
  VacationView,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Employees<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Employee
   * @name AddOrUpdatePersonEmployeeInfo
   * @request POST:/api/employees/{id}/employeeInfo
   * @secure
   */
  addOrUpdatePersonEmployeeInfo = (id: string, data: EmployeeInfo, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/employees/${id}/employeeInfo`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name RemovePersonEmployeeInfo
   * @request DELETE:/api/employees/{id}/employeeInfo
   * @secure
   */
  removePersonEmployeeInfo = (id: string, params: RequestParams = {}) =>
    this.http.request<PersonView, any>({
      path: `/api/employees/${id}/employeeInfo`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeVacation
   * @request GET:/api/employees/employeeInfo/vacation/{year}
   * @secure
   */
  getEmployeeVacation = (year: number, params: RequestParams = {}) =>
    this.http.request<VacationView[], any>({
      path: `/api/employees/employeeInfo/vacation/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeVacationById
   * @request GET:/api/employees/{id}/employeeInfo/vacation/{year}
   * @secure
   */
  getEmployeeVacationById = (id: string, year: number, params: RequestParams = {}) =>
    this.http.request<VacationView, any>({
      path: `/api/employees/${id}/employeeInfo/vacation/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployeeVacation
   * @request PUT:/api/employees/employeeInfo/vacation
   * @secure
   */
  updateEmployeeVacation = (data: UpdateEmployeeVacationDaysCommand, params: RequestParams = {}) =>
    this.http.request<VacationView[], any>({
      path: `/api/employees/employeeInfo/vacation`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeIllnessChild
   * @request GET:/api/employees/employeeInfo/illnessChild/{year}
   * @secure
   */
  getEmployeeIllnessChild = (year: number, params: RequestParams = {}) =>
    this.http.request<IllnessChildView[], any>({
      path: `/api/employees/employeeInfo/illnessChild/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeIllnessChildById
   * @request GET:/api/employees/{id}/employeeInfo/illnessChild/{year}
   * @secure
   */
  getEmployeeIllnessChildById = (id: string, year: number, params: RequestParams = {}) =>
    this.http.request<IllnessChildView, any>({
      path: `/api/employees/${id}/employeeInfo/illnessChild/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployeeIllnessChild
   * @request PUT:/api/employees/employeeInfo/illnessChild
   * @secure
   */
  updateEmployeeIllnessChild = (data: UpdateEmployeeIllnessChildCommand, params: RequestParams = {}) =>
    this.http.request<IllnessChildView[], any>({
      path: `/api/employees/employeeInfo/illnessChild`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeHourBank
   * @request GET:/api/employees/employeeInfo/hourBank/{year}
   * @secure
   */
  getEmployeeHourBank = (year: number, params: RequestParams = {}) =>
    this.http.request<HourBankView[], any>({
      path: `/api/employees/employeeInfo/hourBank/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name GetEmployeeHourBankById
   * @request GET:/api/employees/{id}/employeeInfo/hourBank/{year}
   * @secure
   */
  getEmployeeHourBankById = (id: string, year: number, params: RequestParams = {}) =>
    this.http.request<HourBankView, any>({
      path: `/api/employees/${id}/employeeInfo/hourBank/${year}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Employee
   * @name UpdateEmployeeHourBank
   * @request PUT:/api/employees/employeeInfo/hourBank
   * @secure
   */
  updateEmployeeHourBank = (data: UpdateEmployeeHourBankCommand, params: RequestParams = {}) =>
    this.http.request<HourBankView[], any>({
      path: `/api/employees/employeeInfo/hourBank`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
