/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdateAddressCollectionItem,
  AddressCollectionItem,
  CreateFileOrFolder,
  CreateNote,
  CreateOrUpdateResource,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MeasurementLevel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  PreviewType,
  ResourceAggregationResultResourceAggregateQueryAggregationResults,
  ResourceAggregationTypes,
  ResourceGraphsWebViewResourceGraphsWebQueryCollectionViewModel,
  ResourceLogType,
  ResourceLogViewPagedResourceLogQueryCollectionViewModel,
  ResourceSortOptions,
  ResourceView,
  ResourceViewEntityViewModel,
  ResourceViewPagedResourceQueryCollectionViewModel,
  RevertFileToVersion,
  SortDirection,
  UpdateFileOrFolder,
  UpdateNote,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Resources<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Resource
   * @name GetResourceById
   * @request GET:/api/resources/{id}
   * @secure
   */
  getResourceById = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceViewEntityViewModel, any>({
      path: `/api/resources/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResource
   * @request PUT:/api/resources/{id}
   * @secure
   */
  updateResource = (id: string, data: CreateOrUpdateResource, params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name DeleteResource
   * @request DELETE:/api/resources/{id}
   * @secure
   */
  deleteResource = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name CreateResource
   * @request POST:/api/resources
   * @secure
   */
  createResource = (data: CreateOrUpdateResource, params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name QueryResources
   * @request GET:/api/resources
   * @secure
   */
  queryResources = (
    query?: {
      sortBy?: ResourceSortOptions;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      resourceInternal?: boolean;
      resourceMonitored?: boolean;
      resourceOrganizationId?: string;
      limitToTags?: string[];
      enableAddress?: boolean;
      enableContactPoints?: boolean;
      enableMeasuringPoints?: boolean;
      status?: MeasurementLevel;
      relatedUserId?: string;
      notificationReceiverId?: string;
      supplierId?: string;
      categoryId?: string;
      hasOverdueRoutineExecutions?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ResourceViewPagedResourceQueryCollectionViewModel, any>({
      path: `/api/resources`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name RestoreResource
   * @request POST:/api/resources/{id}/restore
   * @secure
   */
  restoreResource = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResourceNotificationRceivers
   * @request PUT:/api/resources/{id}/notificationReceivers
   * @secure
   */
  updateResourceNotificationRceivers = (id: string, data: string[], params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}/notificationReceivers`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateContacts
   * @request PUT:/api/resources/{id}/contacts
   * @secure
   */
  updateContacts = (id: string, data: string[], params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}/contacts`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name QueryResourceGraphs
   * @request GET:/api/resources/{id}/graphs
   * @secure
   */
  queryResourceGraphs = (
    id: string,
    query: {
      measuringPointIds?: string[];
      /** @format date-time */
      fromDate: string;
      /** @format date-time */
      toDate: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ResourceGraphsWebViewResourceGraphsWebQueryCollectionViewModel, any>({
      path: `/api/resources/${id}/graphs`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResourceAttributes
   * @request POST:/api/resources/{id}/attributes
   * @secure
   */
  updateResourceAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name GetResourceAvatar
   * @request GET:/api/resources/{id}/avatar
   * @secure
   */
  getResourceAvatar = (
    id: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/resources/${id}/avatar`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResourceAvatar
   * @request POST:/api/resources/{id}/avatar
   * @secure
   */
  updateResourceAvatar = (
    id: string,
    data: {
      /** @format binary */
      avatar: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}/avatar`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name DeleteResourceAvatar
   * @request DELETE:/api/resources/{id}/avatar
   * @secure
   */
  deleteResourceAvatar = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceView, any>({
      path: `/api/resources/${id}/avatar`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name GetResourceAggregates
   * @request GET:/aggs/resources
   * @secure
   */
  getResourceAggregates = (
    query?: {
      aggs?: ResourceAggregationTypes[];
      resourceInternal?: boolean;
      resourceMonitored?: boolean;
      resourceOrganizationId?: string;
      limitToTags?: string[];
      enableAddress?: boolean;
      enableContactPoints?: boolean;
      enableMeasuringPoints?: boolean;
      status?: MeasurementLevel;
      relatedUserId?: string;
      notificationReceiverId?: string;
      supplierId?: string;
      categoryId?: string;
      hasOverdueRoutineExecutions?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ResourceAggregationResultResourceAggregateQueryAggregationResults, any>({
      path: `/aggs/resources`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name CreateResourceAddress
   * @request POST:/api/resources/{id}/addresses
   * @secure
   */
  createResourceAddress = (id: string, data: AddOrUpdateAddressCollectionItem, params: RequestParams = {}) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/resources/${id}/addresses`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResourceAddress
   * @request PUT:/api/resources/{id}/addresses/{addressId}
   * @secure
   */
  updateResourceAddress = (
    id: string,
    addressId: string,
    data: AddOrUpdateAddressCollectionItem,
    params: RequestParams = {},
  ) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/resources/${id}/addresses/${addressId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name DeleteResourceAddress
   * @request DELETE:/api/resources/{id}/addresses/{addressId}
   * @secure
   */
  deleteResourceAddress = (id: string, addressId: string, params: RequestParams = {}) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/resources/${id}/addresses/${addressId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name UpdateResourceAddressAttributes
   * @request POST:/api/resources/{id}/addresses/{addressId}/attributes
   * @secure
   */
  updateResourceAddressAttributes = (
    id: string,
    addressId: string,
    data: Record<string, string>,
    params: RequestParams = {},
  ) =>
    this.http.request<AddressCollectionItem, any>({
      path: `/api/resources/${id}/addresses/${addressId}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Resource
   * @name GetResourceLog
   * @request GET:/api/resources/{id}/log
   * @secure
   */
  getResourceLog = (
    id: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      unresolvedLimit?: number;
      /** @format int32 */
      limit?: number;
      measuringPointId?: string;
      logType?: ResourceLogType;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ResourceLogViewPagedResourceLogQueryCollectionViewModel, any>({
      path: `/api/resources/${id}/log`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name GetResourceFileById
   * @request GET:/api/resources/{entityId}/files/{fileId}
   * @secure
   */
  getResourceFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/resources/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name UpdateResourceFile
   * @request PUT:/api/resources/{entityId}/files/{fileId}
   * @secure
   */
  updateResourceFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/resources/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name DeleteResourceFile
   * @request DELETE:/api/resources/{entityId}/files/{fileId}
   * @secure
   */
  deleteResourceFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/resources/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name DownloadResourceFile
   * @request GET:/api/resources/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadResourceFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/resources/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name PreviewResourceFile
   * @request GET:/api/resources/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewResourceFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/resources/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name CreateResourceFile
   * @request POST:/api/resources/{entityId}/files
   * @secure
   */
  createResourceFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/resources/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name QueryResourceFiles
   * @request GET:/api/resources/{entityId}/files
   * @secure
   */
  queryResourceFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/resources/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name RestoreResourceFile
   * @request POST:/api/resources/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreResourceFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/resources/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name MoveResourceFile
   * @request POST:/api/resources/{entityId}/files/{fileId}/move
   * @secure
   */
  moveResourceFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/resources/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name RevertResourceFile
   * @request POST:/api/resources/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertResourceFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/resources/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name GetResourceFolderById
   * @request GET:/api/resources/{entityId}/folders/{folderId}
   * @secure
   */
  getResourceFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/resources/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name UpdateResourceFolder
   * @request PUT:/api/resources/{entityId}/folders/{folderId}
   * @secure
   */
  updateResourceFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/resources/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name DeleteResourceFolder
   * @request DELETE:/api/resources/{entityId}/folders/{folderId}
   * @secure
   */
  deleteResourceFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/resources/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name CreateResourceFolder
   * @request POST:/api/resources/{entityId}/folders
   * @secure
   */
  createResourceFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/resources/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name QueryResourceFolders
   * @request GET:/api/resources/{entityId}/folders
   * @secure
   */
  queryResourceFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/resources/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name RestoreResourceFolder
   * @request POST:/api/resources/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreResourceFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/resources/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceFile
   * @name MoveResourceFolder
   * @request POST:/api/resources/{entityId}/{folderId}/move
   * @secure
   */
  moveResourceFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/resources/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceNote
   * @name GetResourceNoteById
   * @request GET:/api/resources/{entityId}/notes/{noteId}
   * @secure
   */
  getResourceNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/resources/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceNote
   * @name UpdateResourceNote
   * @request PUT:/api/resources/{entityId}/notes/{noteId}
   * @secure
   */
  updateResourceNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/resources/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceNote
   * @name DeleteResourceNote
   * @request DELETE:/api/resources/{entityId}/notes/{noteId}
   * @secure
   */
  deleteResourceNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/resources/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceNote
   * @name CreateResourceNote
   * @request POST:/api/resources/{entityId}/notes
   * @secure
   */
  createResourceNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/resources/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceNote
   * @name QueryResourceNotes
   * @request GET:/api/resources/{entityId}/notes
   * @secure
   */
  queryResourceNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/resources/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceNote
   * @name RestoreResourceNote
   * @request POST:/api/resources/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreResourceNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/resources/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
