import * as React from "react";
import _ from 'lodash';
import { Select } from "antd";
import { OrganizationView, PersonView } from "../../ApiClient/swagger/data-contracts";
import SelectorProps from "../../Models/SelectorProps";
const { Option } = Select;

interface OrganizationAddressProps extends SelectorProps {
    customer: OrganizationView | PersonView;
    placeholder?: string;
    value?: string;
}

interface OrganizationAddressState {
    value: any,
    defaultValues: any,
}

class OrganizationAddressSelector extends React.Component<OrganizationAddressProps, OrganizationAddressState> {
    _isMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            value: this.props.value ? this.props.value : null,
            defaultValues: null,
        }
    }

    componentDidMount = async () => {
        this._isMounted = true;
    }

    componentDidUpdate = (prevProps) => {
        if (this._isMounted) {
            if (this.props.value != this.state.value) {
                this.setState({ value: this.props.value });
            }
        }
    }

    onChange = (value) => {
        this.setState({ value: value });

        if (!value) {
            if (this.props.onChange)
                this.props.onChange(null);
            return;
        }

        if (this.props.onChange)
            this.props.onChange(value);
        if (this.props.onObjectChange)
            this.props.onObjectChange(value);
    }

    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select address';
        const defaultValue = this.props.value ? this.props.value : null;

        const options = [];

        if (this.props.customer && this.props.customer.addresses) {
            _.each(this.props.customer.addresses, (address, index) => {
                if (address) {
                    let label = (address.line1 ? address.line1 : "") + (address.line2 ? " " + address.line2 : "") + (address.zipCode || address.area ? " (" : "") + (address.zipCode ? address.zipCode : "") + (address.zipCode && address.area ? ", " : "") + (address.area ? address.area : "") + (address.zipCode || address.area ? ")" : "");

                    if (address.name && address.line1) {
                        label = address.name + " (" + address.line1 + ")";
                    }
                    else if (address.line1 && address.zipCode && address.area) {
                        label = address.line1 + " (" + address.zipCode + ", " + address.area + ")";
                    }
                    else if (address.line2 && address.zipCode + address.area) {
                        label = address.line2 + " (" + address.zipCode + ", " + address.area + ")";
                    }
                    else if (address.zipCode && address.area) {
                        label = address.zipCode + " (" + address.area + ")";
                    } else if (!address.line1 && !address.line2 && !address.zipCode && !address.area && address.types.length > 0) {
                        label = address.types.toString();
                    }

                    options.push(<Option key={address.addressId} value={address.addressId} label={label}>{label}</Option>);
                }
            });
        }

        const value = this.state.value || defaultValue;

        return (
            <Select
                placeholder={placeholder}
                size="middle"
                allowClear
                onChange={this.onChange}
                onSelect={this.onChange}
                defaultValue={typeof value === 'string' ? value : null}
                value={typeof value === 'string' ? value : null}
            >
                {options}
            </Select>
        );
    }

}

export default OrganizationAddressSelector;