import * as React from 'react';
import { blobToBinaryString } from 'blob-util';

interface FilesTextFileProps {
    fileData: any;
}

interface FilesTextFileState {
    text: string;
}

export class FilesTextContent extends React.Component<FilesTextFileProps, FilesTextFileState> {
    constructor(props: any) {
        super(props);

        this.state = {
            text: null
        }
    }

    componentDidMount(): void {
        blobToBinaryString(this.props.fileData).then(binaryString => {
            this.setState({ text: binaryString });
        }).catch(function (err) {
            throw Error(err);
        });
    }

    render() {
        return (
            <>
                {this.state.text}
            </>
        );
    }
}

export default FilesTextContent;