import _ from 'lodash';
import React, { createElement } from 'react';
import { useRoutes, NonIndexRouteObject, useNavigate } from 'react-router-dom';
import { IAppRoute } from '../Models/MenuAndRouteConfig';
import ErrorPage from '../Pages/ErrorPage';

interface RouteProps {
    appRoutes: IAppRoute[];
}

function Routes ({ appRoutes }: RouteProps) {

    const navigate = useNavigate();

    const configRoutes: NonIndexRouteObject[] = _.map(appRoutes, route => (
        {
            path: route.path,
            element: React.isValidElement(route.element)
                ? route.element
                : createElement(route.element as React.LazyExoticComponent<(props: any) => JSX.Element>, {navigate: navigate})
        }
    ));

    return useRoutes([
        {
            path: '*',
            element: <ErrorPage status="404" />
        },
        ...configRoutes
    ]);
}

export default Routes;