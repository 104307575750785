/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdateNumberSeries,
  CreateOrUpdateCategory,
  CustomerCategorySortOption,
  CustomerCategoryView,
  CustomerCategoryViewEntityViewModel,
  CustomerCategoryViewPagedCustomerCategoryQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Customercategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags CustomerCategory
   * @name GetCustomerCategoryById
   * @request GET:/api/customercategories/{id}
   * @secure
   */
  getCustomerCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryViewEntityViewModel, any>({
      path: `/api/customercategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name UpdateCustomerCategory
   * @request PUT:/api/customercategories/{id}
   * @secure
   */
  updateCustomerCategory = (id: string, data: CreateOrUpdateCategory, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryView, any>({
      path: `/api/customercategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name DeleteCustomerCategory
   * @request DELETE:/api/customercategories/{id}
   * @secure
   */
  deleteCustomerCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryView, any>({
      path: `/api/customercategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name CreateCustomerCategory
   * @request POST:/api/customercategories
   * @secure
   */
  createCustomerCategory = (data: CreateOrUpdateCategory, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryView, any>({
      path: `/api/customercategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name QueryCustomerCategories
   * @request GET:/api/customercategories
   * @secure
   */
  queryCustomerCategories = (
    query?: {
      sortBy?: CustomerCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CustomerCategoryViewPagedCustomerCategoryQueryCollectionViewModel, any>({
      path: `/api/customercategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name RestoreCustomerCategory
   * @request POST:/api/customercategories/{id}/restore
   * @secure
   */
  restoreCustomerCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryView, any>({
      path: `/api/customercategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name UpdateCustomerCategoryAttributes
   * @request POST:/api/customercategories/{id}/attributes
   * @secure
   */
  updateCustomerCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryView, any>({
      path: `/api/customercategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name AddOrUpdateCustomerCategoryNumberSeries
   * @request POST:/api/customercategories/{id}/numberseries
   * @secure
   */
  addOrUpdateCustomerCategoryNumberSeries = (id: string, data: AddOrUpdateNumberSeries, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryView, any>({
      path: `/api/customercategories/${id}/numberseries`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CustomerCategory
   * @name RemoveCustomerCategoryNumberSeries
   * @request DELETE:/api/customercategories/{id}/numberseries/{applicationId}
   * @secure
   */
  removeCustomerCategoryNumberSeries = (id: string, applicationId: string, params: RequestParams = {}) =>
    this.http.request<CustomerCategoryView, any>({
      path: `/api/customercategories/${id}/numberseries/${applicationId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
