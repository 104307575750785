import * as React from 'react';
import type { Moment } from 'moment';

import MomentDatePicker from './MomentDatePicker';
//import { PickerTimeProps } from 'antd/es/time-picker';
import type { PickerProps } from 'antd/es/date-picker/generatePicker';

export interface TimePickerProps extends Omit<PickerProps<Moment>, 'picker'> { }

const MomentTimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
    <MomentDatePicker {...props} picker="time" mode={undefined} ref={ref} />
));

MomentTimePicker.displayName = 'TimePicker';

export default MomentTimePicker;