import * as React from "react";
import _ from 'lodash';
import { TaskView } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { ProjectSelector } from "../Projects";
import TaskSelector from "./TaskSelector";

interface ImportTaskProps {
    projectId?: string,
    parentId?: string,
    onComplete: (response: TaskView[]) => void,
    onCancel?: () => void
}

interface ImportTaskState {
    loading: boolean,
    error: string
}

export class TaskImportForm extends React.Component<ImportTaskProps, ImportTaskState> {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null
        }
    }

    onSubmit = async (request) => {
        this.setState({ loading: true });

        try {
            if (this.props.parentId) {
                const taskResponse = await client.tasks.importSubTasks(this.props.parentId, request.fromTaskId)
                if (taskResponse) {
                    this.props.onComplete([taskResponse.data]);
                }
            }
            else {
                const projectResponse = await client.projects.importTasksFromProject(this.props.projectId, request.fromProjectId)
                if (projectResponse) {
                    this.props.onComplete(projectResponse.data);
                }
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    render() {
        return (
            <BaseForm
                type={FormType.Import}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
            >

                {this.props.parentId ?
                    <SelectorInput
                        param="fromTaskId"
                        required
                        warningMessage="Please choose a task"
                        title="Task"
                        selector={<TaskSelector filters={{ deleted: false, hasChildren: true, showAll: true }} />}
                    /> :
                    <SelectorInput
                        param="fromProjectId"
                        required
                        warningMessage="Please choose a project"
                        title="Project"
                        selector={<ProjectSelector />}
                    />}

            </BaseForm>
        );
    }
}

export default TaskImportForm;