import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import client from "../../ApiClient/client";
import { IncidentView, PersonLinkView, UpdateIncident } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import Title from "antd/es/typography/Title";
import BaseForm, { FormType } from "../Shared/Form";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { PersonSelector } from "../People";

interface IncidentClosingProps {
    incident: IncidentView;
    className?: string;
    writeAccess?: boolean;
    onCloseAction: (incident: IncidentView) => void;
}

interface IncidentClosingState {
    value: string,
    closedDate: string,
    closerId: string,
    closer: PersonLinkView,
    loading: boolean,
    conflict: boolean,
    error?: any,
    status: string
}

export class IncidentClosingCard extends React.Component<IncidentClosingProps, IncidentClosingState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.incident ? this.props.incident.closingComment : null,
            closedDate: this.props.incident ? this.props.incident.dates.Closed : null,
            closerId: this.props.incident && this.props.incident.closer ? this.props.incident.closer.id : null,
            closer: null,
            loading: false,
            conflict: false,
            status: "Saved",
        }
    }

    componentDidMount = () => {
        this.setState({
            value: this.props.incident ? this.props.incident.closingComment : null,
            closerId: this.props.incident.closer ? this.props.incident.closer.id : this.state.closerId ? this.state.closerId : this.props.writeAccess ? this.context.user.actorId : null,
            closedDate: this.props.incident.dates.Closed ? this.props.incident.dates.Closed : this.state.closedDate ? this.state.closedDate : this.props.writeAccess ? moment().format() : null,
        })
    }

    componentDidUpdate = async (prevProps) => {
        if (!this.props.incident || prevProps == this.props) return;

        const newStringValue = this.props.incident.closingComment || "";
        const olderStringValue = prevProps.incident ? prevProps.incident.closingComment || "" : "";
        const editedStringValue = this.state.value || "";

        if (newStringValue === "" || newStringValue == editedStringValue) {
            if (this.state.conflict)
                this.setState({ conflict: false });

            this.setState({ value: this.props.incident.closingComment });
            return;
        }

        const isValueEdited = editedStringValue != olderStringValue;

        if (isValueEdited && newStringValue != olderStringValue) {
            this.setState({ conflict: true });
            return;
        }

        if (newStringValue != olderStringValue) {
            this.setState({ value: this.props.incident.closingComment, conflict: false })
        }

        if (prevProps.incident.closer != this.props.incident.closer && this.props.incident.closer == null) {
            try {
                const closer = await (await client.people.getPersonById(prevProps.incident.closer.id)).data.view;
                this.setState({ closer });
            }
            catch (e) {
                this.setState({ error: e });
            }
        }
    }

    saveChanges = async () => {
        try {
            await client.incidents.addOrUpdateClosingComment(
                this.props.incident.id,
                { closingComment: this.state.value }
            );

            this.setState({
                error: null,
                status: "Saved",
            })
        }
        catch (e: any) {
            this.setState({
                error: e.message
            });
        }
    }

    autoSaveChanges = _.debounce(this.saveChanges, 2000);

    onValueChange = (value) => {
        if (value !== this.state.value) {
            this.setState({ value, status: "Save" });

            //if (value && value != "") {
                this.autoSaveChanges();
            //}
        }
    }

    onClosedDateChange = (date) => {
        if (date !== this.state.closedDate) {
            this.setState({ closedDate: date });
        }
    }

    onCloserChange = (closerId) => {
        if (closerId !== this.state.closerId) {
            this.setState({
                closerId
            });
        }
    }

    closeIncident = async () => {
        if (this.state.closedDate == undefined || this.state.closedDate == null || this.state.closerId == null) return;

        try {
            this.setState({ loading: true });

            const response = await client.incidents.closeIncident(
                this.props.incident.id,
                {
                    closingComment: this.state.value ? this.state.value : this.props.incident ? this.props.incident.closingComment : null,
                    closerId: this.state.closerId,
                    closedDate: this.state.closedDate,
                }
            );

            if (response) this.props.onCloseAction(response.data);

            this.setState({ loading: false });
        }
        catch (e: any) {
            this.setState({
                error: e.message
            });
        }
    }

    openIncident = async () => {
        try {
            this.setState({ loading: true });

            const response = await client.incidents.openIncident(
                this.props.incident.id
            );

            if (response) this.props.onCloseAction(response.data);

            this.setState({ loading: false });
        }
        catch (e) {
            this.setState({
                error: e
            });
        }
    }

    render = () => {
        if (!this.props.incident) return <div>No incident</div>;

        const { incident } = this.props;

        const stringValue = this.state.value || "";
        const closingCommentValue = this.props.incident.closingComment || "";

        const willDelete = stringValue == "" && closingCommentValue != "";
        const isChanged = stringValue !== closingCommentValue;
        const isConflict = this.state.conflict;
        const closedDate = incident.dates["closed"];

        const isEditable = this.props.writeAccess;

        const text = willDelete ? "Delete" : isConflict ? "Override" : this.state.status;
        const icon = willDelete ? <DeleteOutlined /> : <SaveOutlined />;

        const save = isEditable
            ? !closedDate ?
                <Button className="action" size="small" onClick={this.saveChanges} disabled={!willDelete && this.state.status === "Saved"} icon={icon} > {text}</Button> : null
            : null;

        return (
            <React.Fragment>
                <Card
                    key="incident-closing-card"
                    title={
                        <React.Fragment>
                            <Title level={4} className="title">Step 3: Closing</Title>
                        </React.Fragment>
                    }
                    className={`incident-closing-card info-incident-container ${this.props.className ? this.props.className : ""}`}
                >
                    <div className="closing-input-container">
                        <BaseForm
                            type={FormType.Edit}
                            onSubmit={this.closeIncident}
                            customSubmit={!closedDate ? <Button onClick={this.closeIncident} disabled={!isEditable}>Close incident</Button> : <Button disabled={!isEditable} onClick={this.openIncident}>Reopen incident</Button>}
                            loading={this.state.loading}

                            error={this.state.error}
                            initialValues={{
                                closerId: incident.closer ? incident.closer.id : this.state.closerId ? this.state.closerId : isEditable ? this.context.user.actorId : null,
                                closingComment: incident.closingComment,
                                closedDate: closedDate ? closedDate : this.state.closedDate ? this.state.closedDate : isEditable ? moment().format() : null,
                            } as Partial<UpdateIncident>}
                        >
                            <div className="textarea-container">
                                {!closedDate ?
                                    <TextAreaInput
                                        title="Closing comment"
                                        actions={save}
                                        param="closingComment"
                                        disabled={!isEditable}
                                        placeholder={this.state.value ? this.state.value : "Write here"}
                                        onChange={this.onValueChange}
                                        //hideClear
                                    /> : this.props.incident ? <><b>Closing comment (Optional)</b><p>{this.props.incident.closingComment}</p></> : <><b>Closing comment (Optional)</b><p>-</p></>}
                            </div>
                            <SelectorInput
                                title="Closing date"
                                param="closedDate"
                                required
                                selector={<DateSelector disabled={closedDate != null || !isEditable} value={closedDate ? closedDate : moment().toString()} onChange={this.onClosedDateChange} placeholder="Date" endOfDay />}
                            />

                            <SelectorInput
                                title="Closed by"
                                param="closerId"
                                required
                                selector={
                                    <PersonSelector
                                        placeholder={this.state.closer ? this.state.closer.name : 'Select'}
                                        disabled={closedDate != null || !isEditable}
                                        value={this.state.closerId ? this.state.closerId : null}
                                        onChange={this.onCloserChange}
                                        filters={{
                                            isEmployee: true,
                                        }}
                                    />
                                }
                            />
                        </BaseForm>
                    </div>
                </Card>
            </React.Fragment>
        );
    }
}