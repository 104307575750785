import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Button, Tooltip } from "antd";
import { LockOutlined, UnlockOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { ActivityView, ActorLinkView, HourCategoryType, PagedActivityQuery, ProjectType, WorkCategoryView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { RequireCapability } from "../Shared/RequireCapability";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorLink } from "../Actors";
import { CoreCollection } from "../../Definitions/collections";
import { ProjectLink } from "../Projects";
import { TaskLink } from "../Tasks";
import { isColorDark } from "../../Helpers/BasePageHelpers";
import { TagLink } from "../Tags";
import { formatDuration } from "../../Helpers/Formatters";



interface ConfirmActivitiesByChronologicalProps {
    activities: ActivityView[];
    loading: boolean;
    confirmLoadingIds: string[];
    includeUsers: boolean;
    confirmActivity: (activity: ActivityView) => void;
    confirmActivities: (activity: ActivityView[], groupId: string) => void;
    unconfirmActivity: (activity: ActivityView) => void;
    onEditActivity: (activity: ActivityView) => void;
}

interface ConfirmActivitiesByChronologicalState {
    error: any;
    query: Partial<PagedActivityQuery>;
}

export default class ConfirmActivitiesByChronological extends React.Component<ConfirmActivitiesByChronologicalProps, ConfirmActivitiesByChronologicalState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;


    sortData = () => {
        const activities = this.props.activities.slice();

        const actors: ActorLinkView[] = [];
        const temp = [];

        const groupedByDay = _.groupBy(activities, act => {
            return moment(act.start).startOf('day');
        });

        const days = Object.keys(groupedByDay);
        for (let i = 0; i < days.length; i++) {
            const day = days[i];

            const groupedByUser = _.groupBy(groupedByDay[day], act => {
                if (act.actor) {
                    if (_.find(actors, a => { return a.id == act.actor.id }) == null)
                        actors.push(act.actor);

                    return act.actor.id;
                }
                else {
                    return "Unknown";
                }
            });

            const activitesForDayByUser = [];

            const users = Object.keys(groupedByUser);
            for (let j = 0; j < users.length; j++) {
                const user = users[j];
                const activitiesForDay = _.sortBy(groupedByUser[user], a => {
                    return a.start;
                });
                activitesForDayByUser.push({
                    name: user,
                    activities: activitiesForDay
                });
            }

            const sortedUsers = _.sortBy(activitesForDayByUser, u => { return u.name; });

            temp.push({
                day: moment(day),
                users: sortedUsers
            });
        }

        const sorted = _.sortBy(temp, t => { return t.day; });

        return {
            sorted,
            actors
        };
    }

    render = () => {
        const data = this.sortData();
        const sorted = data.sorted;
        const actors = data.actors;

        const rows = [];

        for (let i = 0; i < sorted.length; i++) {
            const day = sorted[i];

            const allActivitesForDay = _.flatten(_.map(day.users, d => { return d.activities; }));

            const notConfirmed: ActivityView[] = _.filter(allActivitesForDay, (a: ActivityView) => {
                return !a.confirmed;
            });

            const notConfirmedGroupId = i + "_" + day.day.format('DD-MM-YYYY') + "_" + _.map(allActivitesForDay, c => { return c.id; }).join('_');
            const isLoadingNotConfirmedGroup = _.find(this.props.confirmLoadingIds, id => { return id == notConfirmedGroupId; }) != null;

            const confirmAll = notConfirmed.length > 0
                ? <RequireCapability capability={Capabilities.ActivitiesConfirmActor}><Button key={notConfirmedGroupId} loading={isLoadingNotConfirmedGroup} onClick={(e) => {
                    e.preventDefault();
                    this.props.confirmActivities(notConfirmed, notConfirmedGroupId);
                }} icon={<UnlockOutlined />} /></RequireCapability>
                : <Button style={{ visibility: "hidden" }} disabled />;

            let total = 0;

            const dayRows = [];

            for (let l = 0; l < day.users.length; l++) {
                var dayUser = day.users[l];
                const actor = dayUser.name == "Unknown" ? null : _.find(actors, a => { return a.id == dayUser.name; });
                const actorView = actor != null ? <ActorLink {...actor} /> : null;
                const userRows = [];
                let totalForUser = 0;

                const notConfirmedForUser: ActivityView[] = _.filter(dayUser.activities, (a: ActivityView) => {
                    return !a.confirmed;
                });

                const notConfirmedForUserGroupId = i + "_" + l + "_" + dayUser.name + _.map(dayUser.activities, c => { return c.id; }).join('_');
                const isLoadingNotConfirmedForUserGroup = _.find(this.props.confirmLoadingIds, id => { return id == notConfirmedForUserGroupId; }) != null;

                const confirmAllForUser = notConfirmedForUser.length > 0
                    ? <RequireCapability capability={Capabilities.ActivitiesConfirm}><Button loading={isLoadingNotConfirmedForUserGroup} onClick={(e) => {
                        e.preventDefault();
                        this.props.confirmActivities(notConfirmedForUser, notConfirmedForUserGroupId);
                    }} icon={<UnlockOutlined />} /></RequireCapability>
                    : <Button style={{ visibility: "hidden" }} disabled />;

                for (var j = 0; j < dayUser.activities.length; j++) {
                    const act = dayUser.activities[j] as ActivityView;
                    const workCategory = act.category.type == HourCategoryType.Work ? act.category as WorkCategoryView : null;

                    total += act.duration;
                    totalForUser += act.duration;

                    const name = [];
                    const relation = act.relation != null ? name.push(<ActorLink {...act.relation} key={act.relation.id} />) : null;

                    if (relation && act.project != null) {
                        name.push(<span key="splitter_1"> / </span>);
                    }
                    if (act.project != null) {
                        name.push(<ProjectLink {...act.project} key={act.project.id} />);
                    }
                    //if ((relation || act.project) && act.task != null) {
                    //    name.push(<span key="splitter_2"> / </span>);
                    //}
                    if (act.task != null) {

                        _.each(act.task.parents, (p, index) => {
                            const splitterKey = `splitter_0${index}`;
                            name.push(<span key={splitterKey}> / </span>);
                            name.push(<TaskLink {...p} key={p.id} />);
                        });
                        name.push(<span key="splitter_task"> / </span>);
                        name.push(<TaskLink {...act.task} key={act.task.id} />);
                    }

                    const description = act.description ? (<span className="description"><br />{act.description}</span>) : null;

                    const isLoading = _.find(this.props.confirmLoadingIds, id => { return act.id == id; }) != null;

                    const confirm = act.locked ? "Transferred" : act.confirmed
                        ? <RequireCapability capability={Capabilities.ActivitiesConfirmActor}><Button loading={isLoading} className="confirmed-btn" icon={<LockOutlined />} onClick={(e) => { e.stopPropagation(); if (e.ctrlKey) this.props.unconfirmActivity(act); }} /></RequireCapability>
                        : <RequireCapability capability={Capabilities.ActivitiesConfirmActor}><Button loading={isLoading} onClick={(e) => { e.stopPropagation(); this.props.confirmActivity(act); }} icon={<UnlockOutlined />} /></RequireCapability>;

                    //var edit = <Button type="link" onClick={() => this.props.onEditActivity(act)}>{moment(act.start).format('HH:mm')} - {moment(act.end).format('HH:mm')}</Button>;

                    const color = act.category.color;
                    const isDarkColor = isColorDark(color);

                    let productOverridden = null;
                    const overriddenProduct = act.product ?? null;
                    const defaultProduct = workCategory ? workCategory.product : null;

                    if (overriddenProduct && defaultProduct && overriddenProduct.id != defaultProduct.id && (act.project == null || act.project.type == ProjectType.Hourly)) {
                        productOverridden = (
                            <span className="product-override"><Tooltip title={overriddenProduct.name}><InfoCircleOutlined /> Product override</Tooltip></span>
                        );
                    }
                    let tags = [];
                    if (act.tags) {
                        tags = _.map(act.tags, tag => { return <TagLink key={tag.id} {...tag} />; });
                    }
                    let productInUse = overriddenProduct ?? defaultProduct ?? null;

                    if (act.project != null && act.project.type == ProjectType.Internal)
                        productInUse = null;

                    userRows.push(
                        <tr className="columns-row" key={day.day.format(i + '_DD-MM-YYYY_') + "users_" + j + "_activities_" + l} onClick={(e) => { e.preventDefault(); this.props.onEditActivity(act) }}>
                            <td className="edit-cell">{moment(act.start).format('HH:mm')} - {moment(act.end).format('HH:mm')}</td>
                            <td style={{ backgroundColor: act.category.color }} className={`category-cell ${isDarkColor ? "color-light" : "color-dark"}`}>{act.category.name} <div className="product">{productInUse ? productInUse.name : null}</div></td>
                            <td className="description-cell">{act.type} {name && name.length > 0 ? <span>- {name}</span> : null} {description}</td>
                            <td className="confirm-cell">{tags}{productOverridden} <span className="duration">{formatDuration(act.duration)}</span> {confirm}</td>
                        </tr>
                    );
                }

                if (this.props.includeUsers) {
                    dayRows.push(
                        <tr className="row-sum-partial" key={day.day.format(i + '_DD-MM-YYYY_') + "user_" + dayUser.name + "_" + j + "_activities_" + l}>
                            <td>{actorView}</td>
                            <th colSpan={3} className="confirm-all-user-cell"><span className="duration">{formatDuration(totalForUser)}</span> {confirmAllForUser}</th>
                        </tr>
                    );
                }

                for (let y = 0; y < userRows.length; y++) {
                    dayRows.push(userRows[y]);
                }
            }

            rows.push(
                <tr className="row-header" key={notConfirmedGroupId}>
                    <th>{day.day.format('dddd, DD MMMM YYYY')}</th>
                    <th colSpan={3} className="confirm-cell"><span className="duration">{formatDuration(total)}</span> {confirmAll}</th>
                </tr>
            );

            for (let x = 0; x < dayRows.length; x++) {
                rows.push(dayRows[x]);
            }
        }
        return (
            <table className="verify-activities-table by-chronological-view" id="verifyTableId">
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}