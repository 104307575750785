/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SensorEventSortOption,
  SensorEventType,
  SensorEventViewPagedSensorEventQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Sensorevents<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SensorEvent
   * @name QuerySensorEvents
   * @request GET:/api/sensorevents
   * @secure
   */
  querySensorEvents = (
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      sortBy?: SensorEventSortOption;
      sortDirection?: SortDirection;
      sensorId?: string;
      sensorPartId?: string;
      resourceId?: string;
      eventType?: SensorEventType;
      /** @format date-time */
      fromDateTime?: string;
      /** @format date-time */
      toDateTime?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SensorEventViewPagedSensorEventQueryCollectionViewModel, any>({
      path: `/api/sensorevents`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
