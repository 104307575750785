/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActivityAggregationResultActivityAggregateQueryAggregationResults,
  ActivityAggregationTypes,
  ActivitySortOption,
  ActivitySubType,
  ActivityType,
  ActivityView,
  ActivityViewEntityViewModel,
  ActivityViewPagedActivityQueryCollectionViewModel,
  CreateCustomerActivityWebRequest,
  CreateInternalActivityWebRequest,
  CreateProjectActivityWebRequest,
  DeclineAbsence,
  HourCategoryType,
  SortDirection,
  UpdateCustomerActivity,
  UpdateInternalActivity,
  UpdateManyRequest,
  UpdateProjectActivity,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Activities<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Activity
   * @name GetActivityById
   * @request GET:/api/activities/{id}
   * @secure
   */
  getActivityById = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityViewEntityViewModel, any>({
      path: `/api/activities/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name DeleteActivity
   * @request DELETE:/api/activities/{id}
   * @secure
   */
  deleteActivity = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name CreateProjectActivity
   * @request POST:/api/activities/project
   * @secure
   */
  createProjectActivity = (data: CreateProjectActivityWebRequest, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/project`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name CreateCustomerActivity
   * @request POST:/api/activities/customer
   * @secure
   */
  createCustomerActivity = (data: CreateCustomerActivityWebRequest, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/customer`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name CreateInternalActivity
   * @request POST:/api/activities/internal
   * @secure
   */
  createInternalActivity = (data: CreateInternalActivityWebRequest, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/internal`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name UpdateProjectActivity
   * @request PUT:/api/activities/{id}/project
   * @secure
   */
  updateProjectActivity = (id: string, data: UpdateProjectActivity, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/${id}/project`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name UpdateCustomerActivity
   * @request PUT:/api/activities/{id}/customer
   * @secure
   */
  updateCustomerActivity = (id: string, data: UpdateCustomerActivity, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/${id}/customer`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name UpdateInternalActivity
   * @request PUT:/api/activities/{id}/internal
   * @secure
   */
  updateInternalActivity = (id: string, data: UpdateInternalActivity, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/${id}/internal`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name QueryActivities
   * @request GET:/api/activities
   * @secure
   */
  queryActivities = (
    query?: {
      sortBy?: ActivitySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      types?: ActivityType[];
      subTypes?: ActivitySubType[];
      actorId?: string;
      relatedActorId?: string;
      relatedActorId_ExcludePurePersonal?: boolean;
      categoryIds?: string[];
      taskId?: string;
      taskClosed?: boolean;
      projectId?: string;
      projectActive?: boolean;
      projectEnded?: boolean;
      relationId?: string;
      ticketId?: string;
      productId?: string;
      meetingId?: string;
      groupId?: string;
      participantIds?: string[];
      actorIdOrParticipantId?: string;
      /** @format date-time */
      startAfter?: string;
      /** @format date-time */
      startBefore?: string;
      /** @format date-time */
      endBefore?: string;
      /** @format date-time */
      endAfter?: string;
      /** @format date-time */
      calendarStart?: string;
      /** @format date-time */
      calendarEnd?: string;
      /** @format date-time */
      activeAt?: string;
      confirmed?: boolean;
      locked?: boolean;
      running?: boolean;
      requiresAdminApproval?: boolean;
      tagIds?: string[];
      categoryTypes?: HourCategoryType[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActivityViewPagedActivityQueryCollectionViewModel, any>({
      path: `/api/activities`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name GetSuggestedActivities
   * @request GET:/api/activities/_suggestedHours
   * @secure
   */
  getSuggestedActivities = (
    query?: {
      personId?: string;
      /** @format date-time */
      to?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActivityView[], any>({
      path: `/api/activities/_suggestedHours`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name ConfirmActivity
   * @request POST:/api/activities/{id}/confirmation
   * @secure
   */
  confirmActivity = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/${id}/confirmation`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name UnconfirmActivity
   * @request DELETE:/api/activities/{id}/confirmation
   * @secure
   */
  unconfirmActivity = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/${id}/confirmation`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name ConfirmActivities
   * @request POST:/api/activities/confirm
   * @secure
   */
  confirmActivities = (data: UpdateManyRequest[], params: RequestParams = {}) =>
    this.http.request<ActivityView[], any>({
      path: `/api/activities/confirm`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name RestoreActivity
   * @request POST:/api/activities/{id}/restore
   * @secure
   */
  restoreActivity = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityView, any>({
      path: `/api/activities/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name MoveActivities
   * @request GET:/api/activities/move
   * @secure
   */
  moveActivities = (
    query?: {
      toTaskId?: string;
      sortBy?: ActivitySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      types?: ActivityType[];
      subTypes?: ActivitySubType[];
      actorId?: string;
      relatedActorId?: string;
      relatedActorId_ExcludePurePersonal?: boolean;
      categoryIds?: string[];
      taskId?: string;
      taskClosed?: boolean;
      projectId?: string;
      projectActive?: boolean;
      projectEnded?: boolean;
      relationId?: string;
      ticketId?: string;
      productId?: string;
      meetingId?: string;
      groupId?: string;
      participantIds?: string[];
      actorIdOrParticipantId?: string;
      /** @format date-time */
      startAfter?: string;
      /** @format date-time */
      startBefore?: string;
      /** @format date-time */
      endBefore?: string;
      /** @format date-time */
      endAfter?: string;
      /** @format date-time */
      calendarStart?: string;
      /** @format date-time */
      calendarEnd?: string;
      /** @format date-time */
      activeAt?: string;
      confirmed?: boolean;
      locked?: boolean;
      running?: boolean;
      requiresAdminApproval?: boolean;
      tagIds?: string[];
      categoryTypes?: HourCategoryType[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<string, any>({
      path: `/api/activities/move`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name GetActivityAggregates
   * @request GET:/aggs/activities
   * @secure
   */
  getActivityAggregates = (
    query?: {
      aggs?: ActivityAggregationTypes[];
      /** @format int32 */
      comparePeriods?: number;
      compareCategories?: string[];
      historyCategories?: string[];
      types?: ActivityType[];
      subTypes?: ActivitySubType[];
      actorId?: string;
      relatedActorId?: string;
      relatedActorId_ExcludePurePersonal?: boolean;
      categoryIds?: string[];
      taskId?: string;
      taskClosed?: boolean;
      projectId?: string;
      projectActive?: boolean;
      projectEnded?: boolean;
      relationId?: string;
      ticketId?: string;
      productId?: string;
      meetingId?: string;
      groupId?: string;
      participantIds?: string[];
      actorIdOrParticipantId?: string;
      /** @format date-time */
      startAfter?: string;
      /** @format date-time */
      startBefore?: string;
      /** @format date-time */
      endBefore?: string;
      /** @format date-time */
      endAfter?: string;
      /** @format date-time */
      calendarStart?: string;
      /** @format date-time */
      calendarEnd?: string;
      /** @format date-time */
      activeAt?: string;
      confirmed?: boolean;
      locked?: boolean;
      running?: boolean;
      requiresAdminApproval?: boolean;
      tagIds?: string[];
      categoryTypes?: HourCategoryType[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActivityAggregationResultActivityAggregateQueryAggregationResults, any>({
      path: `/aggs/activities`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Activity
   * @name DeclineAbsence
   * @request POST:/api/activities/declineabsence
   * @secure
   */
  declineAbsence = (data: DeclineAbsence, params: RequestParams = {}) =>
    this.http.request<ActivityView[], any>({
      path: `/api/activities/declineabsence`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
