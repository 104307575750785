import * as React from "react";
import _ from 'lodash';
import { Form, Upload, Button } from "antd";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { UploadFile, UploadListType } from "antd/lib/upload/interface";

const { Dragger } = Upload;


interface FileInputProps {
    required?: boolean,
    param: string,
    title?: string,
    multiple?: boolean,
    onChange?: (data) => any
    onObjectChange?: (data) => any
    minified?: boolean
    listType?: UploadListType
    fileList?: UploadFile[]
    disabled?: boolean
}

interface FileInputState {
    fileList: UploadFile[],
    uploading: boolean
}

export class FileInput extends React.Component<FileInputProps, FileInputState> {

    constructor(props: any) {
        super(props);

        this.state = {
            fileList: [],
            uploading: false
        }
    }

    getFilesFromEvent = (data) => {
        const file = data.file;
        const files = data.fileList;

        if (file.status == "removed" && data.fileList.length == 1) {
            if (files[0].originFileObj) return [files[0].originFileObj];

            return [files[0]];
        }

        if (data.fileList.length <= 1) {
            if (data.fileList.length == 0 && file.status == "removed") {
                return [];
            }

            return [file];
        }


        if (this.props.multiple)
            return files;
        else
            return [file];
    }

    beforeUpload = (file) => {
        if (this.props.multiple)
            this.setState({ fileList: [...this.state.fileList, file] });
        else
            this.setState({ fileList: [file] });

        return false;
    }

    onRemove = (file: UploadFile) => {
        let files = this.state.fileList.slice();

        if (files.length > 0) {
            const index = files.indexOf(file);
            files.splice(index, 1);
        } else {
            files = [];
        }

        this.setState({ fileList: files });
    }
    onChange = (change) => {
        if (this.props.onObjectChange)
            this.props.onObjectChange(change);

        if(this.props.onChange)
            this.props.onChange(change);
    }
    render = () => {
        const { param, required, multiple } = this.props;
        const optional = !this.props.required ? " (optional)" : "";
        const title = this.props.title ? <div>{this.props.title} {optional}</div> : null;

        return (
            <Form.Item
                label={title}
                name={param}
                valuePropName="fileList"
                getValueFromEvent={this.getFilesFromEvent}
                rules={
                    [{
                        required: required,
                        message: 'Please choose a file'
                    }]}
            >
                {this.props.minified
                    ? <Upload
                        name={param}
                        multiple={multiple}
                        defaultFileList={ this.props.fileList }
                        beforeUpload={this.beforeUpload}
                        onRemove={this.onRemove}
                        showUploadList
                        listType={ this.props.listType}
                        fileList={this.state.fileList}
                        onChange={this.onChange}
                        disabled={this.props.disabled ?? false}                    >
                        {this.props.disabled ? null : <Button icon={<PlusOutlined />}>Add files</Button>}
                    </Upload>
                    : <Dragger
                        name={param}
                        defaultFileList={this.props.fileList}
                        multiple={multiple}
                        beforeUpload={this.beforeUpload}
                        onRemove={this.onRemove}
                        showUploadList
                        listType={ this.props.listType }
                        fileList={this.state.fileList}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled ?? false}
                    >
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Click or drag files to this area to upload</p>
                    </Dragger>
                }
            </Form.Item>
        );
    }
}