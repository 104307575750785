/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActivityType,
  CategorySortOption,
  HourCategoryType,
  HourCategoryViewEntityViewModel,
  HourCategoryViewPagedHourCategoryQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Hourcategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags HourCategory
   * @name GetHourCategoryById
   * @request GET:/api/hourcategories/{id}
   * @secure
   */
  getHourCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<HourCategoryViewEntityViewModel, any>({
      path: `/api/hourcategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags HourCategory
   * @name QueryHourCategories
   * @request GET:/api/hourcategories
   * @secure
   */
  queryHourCategories = (
    query?: {
      sortBy?: CategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      types?: HourCategoryType[];
      activityType?: ActivityType;
      excludedCategories?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<HourCategoryViewPagedHourCategoryQueryCollectionViewModel, any>({
      path: `/api/hourcategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
