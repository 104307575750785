import * as React from "react";
import _ from 'lodash';
import { AutoComplete } from 'antd';
import { capitalizeEveryWord } from "../../Helpers/Index";
import { BronnoysundOrganization } from "../../Models/_bronnoysund";
import { EntitySelectorProps } from "../../Models/EntitySelectorProps";
import client from "../../ApiClient/client";

interface BaseEntityFilterState {
    collection: BronnoysundOrganization[];
    value: any;
    loading: boolean;
}

class BronnoysundRegisterSelector extends React.Component<EntitySelectorProps, BaseEntityFilterState> {
    _isMounted = false;
    _LastSearch = 0;

    constructor(props: any) {
        super(props)
        this.state = {
            collection: [],
            value: props.value,
            loading: false
        };
        this.handleSearch = _.debounce(this.handleSearch, 500);
    }

    componentDidMount = async () => {
        this._isMounted = true;
    }

    //componentDidUpdate = async (prevProps: any) => {
    //    if (!_.isEqual(prevProps.filters, this.props.filters)) {
    //        this.onLoadCollection(this.state.query, this._LastSearch);
    //    }
    //}

    componentWillUnmount = () => {
        this._isMounted = false;
    }

    onLoadCollection = async (terms, searchId) => {
        if (this._isMounted)
            this.setState({ loading: true });

        try {
            const data = await client.brreg.query(terms);
            if (this._isMounted && this._LastSearch == searchId) {
                if (data && data._embedded && data._embedded.enheter)
                    this.setState({ collection: data._embedded.enheter, loading: false });
                else
                    this.setState({ collection: [], loading: false });
            }
        }
        catch (error: any) {
            //this.setState({ error });
        }

        if (this._isMounted)
            this.setState({ loading: false });
    }

    handleSearch = (terms) => {
        this._LastSearch += 1;
        const searchId = this._LastSearch;
        this.onLoadCollection(terms, searchId);
    }

    handleChange = (value) => {
        if (!this._isMounted || (!this.props.onChange && !this.props.onObjectChange)) return;
        this.setState({ value: value });

        if (value === undefined || !value || value.length === 0)
            value = null;

        if (this.props.onChange)
            this.props.onChange(value);
        if (this.props.onObjectChange)
            this.props.onObjectChange(_.find(this.state.collection, (o) => {
                const formattedCompanyName = capitalizeEveryWord(o.navn);
                return formattedCompanyName === value
            }))
    }

    onClear = () => {
        if (this.props.onObjectChange)
            this.props.onObjectChange(null);
    }

    render = () => {
        const { value } = this.state;
        const collection = this.state.collection.slice();

        const options = [];

        if (value && !Array.isArray(value) && typeof value == "object") {
            collection.push(value);
        }

        if (collection) {
            _.each(collection, (o) => {
                if (o) {
                    const formattedCompanyName = capitalizeEveryWord(o.navn);
                    options.push({ value: formattedCompanyName, key: o.organisasjonsnummer });
                }
            });
        }

        const title = this.props.title ? <label>{this.props.title}</label> : null;

        return (
            <div className={`selector autocomplete-input ${this.props.className}`}>
                {title}
                <AutoComplete
                    options={options}
                    placeholder={this.props.placeholder}
                    size="middle"
                    allowClear={true}
                    onChange={this.handleChange}
                    className={this.props.className ? this.props.className : ''}
                    disabled={this.props.disabled}
                    onSearch={this.handleSearch}
                    onClear={this.onClear}
                    value={this.state.value}
                />
            </div>
        );
    }
}

export default BronnoysundRegisterSelector;