import * as React from "react";
import { MeasurementLevel, PagedResourceQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { QuickSearch } from "../Shared/QuickSearch";
import { ActorIds } from "../../Definitions/_definitions";

interface ResourceQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedResourceQuery>
}

export class ResourceQuickSearch extends React.Component<ResourceQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;


    render = () => {
        return (
            <React.Fragment>
                <QuickSearch
                    name="All"
                    queryParameters={{
                        status: null,
                        relatedUserId: null,
                        hasOverdueRoutineExecutions: null
                    } as Partial<PagedResourceQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />
                <QuickSearch
                    name="Critical"
                    queryParameters={{
                        status: MeasurementLevel.Critical
                    } as Partial<PagedResourceQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Cautions"
                    queryParameters={{
                        status: MeasurementLevel.Caution
                    } as Partial<PagedResourceQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Overdue"
                    queryParameters={{
                        hasOverdueRoutineExecutions: true
                    } as Partial<PagedResourceQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                {this.context.user.actorId !== ActorIds.System ? < QuickSearch
                    name="My Resources"
                    queryParameters={{
                        relatedUserId: this.context.user.actorId
                    } as Partial<PagedResourceQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                /> : null}
            </React.Fragment>
        );
    }
}

export default ResourceQuickSearch;