/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateMeeting,
  MeetingAggregationResultMeetingAggregateQueryAggregationResults,
  MeetingAggregationTypes,
  MeetingSortOption,
  MeetingType,
  MeetingView,
  MeetingViewEntityViewModel,
  MeetingViewPagedMeetingQueryCollectionViewModel,
  SortDirection,
  UpcomingAppointmentViewPagedMeetingQueryCollectionViewModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Meetings<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Meeting
   * @name GetMeetingById
   * @request GET:/api/meetings/{id}
   * @secure
   */
  getMeetingById = (id: string, params: RequestParams = {}) =>
    this.http.request<MeetingViewEntityViewModel, any>({
      path: `/api/meetings/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name UpdateMeeting
   * @request PUT:/api/meetings/{id}
   * @secure
   */
  updateMeeting = (id: string, data: CreateOrUpdateMeeting, params: RequestParams = {}) =>
    this.http.request<MeetingView, any>({
      path: `/api/meetings/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name DeleteMeeting
   * @request DELETE:/api/meetings/{id}
   * @secure
   */
  deleteMeeting = (id: string, params: RequestParams = {}) =>
    this.http.request<MeetingView, any>({
      path: `/api/meetings/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name CreateMeeting
   * @request POST:/api/meetings
   * @secure
   */
  createMeeting = (data: CreateOrUpdateMeeting, params: RequestParams = {}) =>
    this.http.request<MeetingView, any>({
      path: `/api/meetings`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name QueryMeetings
   * @request GET:/api/meetings
   * @secure
   */
  queryMeetings = (
    query?: {
      sortBy?: MeetingSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      type?: MeetingType;
      organizerContactPointId?: string;
      organizerId?: string;
      participantIds?: string[];
      displayForActorId?: string;
      /** @format date-time */
      startAfter?: string;
      /** @format date-time */
      endBefore?: string;
      /** @format date-time */
      activeAt?: string;
      isUpcoming?: boolean;
      organizationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MeetingViewPagedMeetingQueryCollectionViewModel, any>({
      path: `/api/meetings`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name RestoreMeeting
   * @request POST:/api/meetings/{id}/restore
   * @secure
   */
  restoreMeeting = (id: string, params: RequestParams = {}) =>
    this.http.request<MeetingView, any>({
      path: `/api/meetings/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name UpdateMeetingAttributes
   * @request POST:/api/meetings/{id}/attributes
   * @secure
   */
  updateMeetingAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<MeetingView, any>({
      path: `/api/meetings/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name IgnoreMeeting
   * @request DELETE:/api/meetings/{id}/ignore/{participantId}
   * @secure
   */
  ignoreMeeting = (id: string, participantId: string, params: RequestParams = {}) =>
    this.http.request<MeetingView, any>({
      path: `/api/meetings/${id}/ignore/${participantId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name GetMeetingsUpcomming
   * @request GET:/api/meetings/{actorId}/upcomingAppointments
   * @secure
   */
  getMeetingsUpcomming = (actorId: string, params: RequestParams = {}) =>
    this.http.request<UpcomingAppointmentViewPagedMeetingQueryCollectionViewModel, any>({
      path: `/api/meetings/${actorId}/upcomingAppointments`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name GetMeetingAggregates
   * @request GET:/aggs/meetings
   * @secure
   */
  getMeetingAggregates = (
    query?: {
      meetingId?: string;
      aggs?: MeetingAggregationTypes[];
      type?: MeetingType;
      organizerContactPointId?: string;
      organizerId?: string;
      participantIds?: string[];
      displayForActorId?: string;
      /** @format date-time */
      startAfter?: string;
      /** @format date-time */
      endBefore?: string;
      /** @format date-time */
      activeAt?: string;
      isUpcoming?: boolean;
      organizationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MeetingAggregationResultMeetingAggregateQueryAggregationResults, any>({
      path: `/aggs/meetings`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Meeting
   * @name GetMeetingAggregatesById
   * @request GET:/aggs/meetings/{id}
   * @secure
   */
  getMeetingAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/meetings/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
