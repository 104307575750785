/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateResourceCategory,
  ResourceCategorySortOption,
  ResourceCategoryView,
  ResourceCategoryViewEntityViewModel,
  ResourceCategoryViewPagedResourceCategoryQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Resourcecategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ResourceCategory
   * @name GetResourceCategoryById
   * @request GET:/api/resourcecategories/{id}
   * @secure
   */
  getResourceCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceCategoryViewEntityViewModel, any>({
      path: `/api/resourcecategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name UpdateResourceCategory
   * @request PUT:/api/resourcecategories/{id}
   * @secure
   */
  updateResourceCategory = (id: string, data: CreateOrUpdateResourceCategory, params: RequestParams = {}) =>
    this.http.request<ResourceCategoryView, any>({
      path: `/api/resourcecategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name DeleteResourceCategory
   * @request DELETE:/api/resourcecategories/{id}
   * @secure
   */
  deleteResourceCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceCategoryView, any>({
      path: `/api/resourcecategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name CreateResourceCategory
   * @request POST:/api/resourcecategories
   * @secure
   */
  createResourceCategory = (data: CreateOrUpdateResourceCategory, params: RequestParams = {}) =>
    this.http.request<ResourceCategoryView, any>({
      path: `/api/resourcecategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name QueryResourceCategories
   * @request GET:/api/resourcecategories
   * @secure
   */
  queryResourceCategories = (
    query?: {
      sortBy?: ResourceCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      monitored?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ResourceCategoryViewPagedResourceCategoryQueryCollectionViewModel, any>({
      path: `/api/resourcecategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name RestoreResourceCategory
   * @request POST:/api/resourcecategories/{id}/restore
   * @secure
   */
  restoreResourceCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceCategoryView, any>({
      path: `/api/resourcecategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name UpdateResourceCategoryAttributes
   * @request POST:/api/resourcecategories/{id}/attributes
   * @secure
   */
  updateResourceCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ResourceCategoryView, any>({
      path: `/api/resourcecategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name GetResourceCategoryImage
   * @request GET:/api/resourcecategories/{id}/placeholderimage
   * @secure
   */
  getResourceCategoryImage = (
    id: string,
    query?: {
      /** @format uuid */
      version?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/resourcecategories/${id}/placeholderimage`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name UpdateResourceCategoryImage
   * @request POST:/api/resourcecategories/{id}/placeholderimage
   * @secure
   */
  updateResourceCategoryImage = (
    id: string,
    data: {
      /** @format binary */
      placeholderImage: File;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ResourceCategoryView, any>({
      path: `/api/resourcecategories/${id}/placeholderimage`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ResourceCategory
   * @name DeleteResourceCategoryImage
   * @request DELETE:/api/resourcecategories/{id}/placeholderimage
   * @secure
   */
  deleteResourceCategoryImage = (id: string, params: RequestParams = {}) =>
    this.http.request<ResourceCategoryView, any>({
      path: `/api/resourcecategories/${id}/placeholderimage`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
