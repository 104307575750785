/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateMeasuringPoint,
  CreateNote,
  MeasuringPointSortOption,
  MeasuringPointView,
  MeasuringPointViewEntityViewModel,
  MeasuringPointViewPagedMeasuringPointQueryCollectionViewModel,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  SortDirection,
  UpdateMeasuringPoint,
  UpdateNote,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Measuringpoints<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MeasuringPoint
   * @name GetMeasuringPointById
   * @request GET:/api/measuringpoints/{id}
   * @secure
   */
  getMeasuringPointById = (id: string, params: RequestParams = {}) =>
    this.http.request<MeasuringPointViewEntityViewModel, any>({
      path: `/api/measuringpoints/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPoint
   * @name UpdateMeasuringPoint
   * @request PUT:/api/measuringpoints/{id}
   * @secure
   */
  updateMeasuringPoint = (id: string, data: UpdateMeasuringPoint, params: RequestParams = {}) =>
    this.http.request<MeasuringPointView, any>({
      path: `/api/measuringpoints/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPoint
   * @name DeleteMeasuringPoint
   * @request DELETE:/api/measuringpoints/{id}
   * @secure
   */
  deleteMeasuringPoint = (id: string, params: RequestParams = {}) =>
    this.http.request<MeasuringPointView, any>({
      path: `/api/measuringpoints/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPoint
   * @name CreateMeasuringPoint
   * @request POST:/api/measuringpoints
   * @secure
   */
  createMeasuringPoint = (data: CreateMeasuringPoint, params: RequestParams = {}) =>
    this.http.request<MeasuringPointView, any>({
      path: `/api/measuringpoints`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPoint
   * @name QueryMeasuringPoints
   * @request GET:/api/measuringpoints
   * @secure
   */
  queryMeasuringPoints = (
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      sortBy?: MeasuringPointSortOption;
      sortDirection?: SortDirection;
      typeId?: string;
      sensorId?: string;
      sensorPartId?: string;
      resourceId?: string;
      measuringPointIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<MeasuringPointViewPagedMeasuringPointQueryCollectionViewModel, any>({
      path: `/api/measuringpoints`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPoint
   * @name RestoreMeasuringPoint
   * @request POST:/api/measuringpoints/{id}/restore
   * @secure
   */
  restoreMeasuringPoint = (id: string, params: RequestParams = {}) =>
    this.http.request<MeasuringPointView, any>({
      path: `/api/measuringpoints/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointNote
   * @name GetMeasuringPointNoteById
   * @request GET:/api/measuringpoints/{entityId}/notes/{noteId}
   * @secure
   */
  getMeasuringPointNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/measuringpoints/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointNote
   * @name UpdateMeasuringPointNote
   * @request PUT:/api/measuringpoints/{entityId}/notes/{noteId}
   * @secure
   */
  updateMeasuringPointNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/measuringpoints/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointNote
   * @name DeleteMeasuringPointNote
   * @request DELETE:/api/measuringpoints/{entityId}/notes/{noteId}
   * @secure
   */
  deleteMeasuringPointNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/measuringpoints/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointNote
   * @name CreateMeasuringPointNote
   * @request POST:/api/measuringpoints/{entityId}/notes
   * @secure
   */
  createMeasuringPointNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/measuringpoints/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointNote
   * @name QueryMeasuringPointNotes
   * @request GET:/api/measuringpoints/{entityId}/notes
   * @secure
   */
  queryMeasuringPointNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/measuringpoints/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MeasuringPointNote
   * @name RestoreMeasuringPointNote
   * @request POST:/api/measuringpoints/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreMeasuringPointNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/measuringpoints/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
