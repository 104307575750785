import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Button, Table, Typography, Breadcrumb, Breakpoint, Tooltip } from 'antd';
import { EditOutlined, FolderFilled, RollbackOutlined, FolderAddOutlined, FileAddOutlined, ArrowRightOutlined, TeamOutlined } from '@ant-design/icons';
import { pdfjs } from 'react-pdf';
import { Capabilities } from "../../Definitions/_capabilties";
import { FileLinkView, FileVersionView, FileView, FolderView, PreviewType, RevertFileToVersion } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { openNotification } from "../../Helpers/BasePageHelpers";
import { blobToBase64, formatFileSize } from "./FileHelpers";
import { getExtensionIcon } from "./ExtensionMappers";
import { EntitySearch } from "../Shared/EntitySearch";
import { RequireCapability } from "../Shared/RequireCapability";
import { Drawer } from "../Shared/Drawer";
import FileCreateForm from "./FileCreateForm";
import FileEditForm from "./FileEditForm";
import FolderCreateForm from "./FolderCreateForm";
import FolderEditForm from "./FolderEditForm";
import FileMoveForm from "./FileMoveForm";
import { FileModal } from "./FileModal";
import { EntityFileClient } from "../../ApiClient/EntityFileClient";
import LastModifiedView from "../Shared/LastModifiedView";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();


const { Title } = Typography;


interface FileListCardProps {
    entityId: string;
    disableFolder?: boolean;
    className?: any;
    writeAccess?: boolean;
    writeCapability?: Capabilities;
    fullSize?: boolean;
    fileClient: EntityFileClient;
    description?: React.ReactNode;
}

interface FileListCardState {
    showCreateFileDrawer: boolean;
    showCreateFolderDrawer: boolean;
    showFileModal: boolean;
    folder: FolderView;
    selectedFolder: FolderView;
    selectedFile: FileView;
    error: string | null;
    loadingDelete: boolean;
    loadingData: boolean;
    created: string[];
    searchText: string;
    searchFiles: FileView[];
    fileOrFolderIdToMove: string;
    fileOrFolderTypeToMove: "folder" | "file";
    fileToDisplay: FileView;
    fileDataToDisplay: any;
    entityRootFolderId: string;
}

export class FileListCard extends React.Component<FileListCardProps, FileListCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);
        this.state = {
            showCreateFileDrawer: false,
            showCreateFolderDrawer: false,
            showFileModal: false,
            folder: null,
            selectedFolder: null,
            selectedFile: null,
            loadingData: false,
            error: null,
            loadingDelete: false,
            created: [],
            searchText: null,
            searchFiles: null,
            fileOrFolderIdToMove: null,
            fileOrFolderTypeToMove: null,
            fileDataToDisplay: null,
            fileToDisplay: null,
            entityRootFolderId: "_root",
        }
    }

    componentDidMount = async () => {
        this.loadFolder(this.state.entityRootFolderId);

        this.context.events.folders.onMany({
            'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent
        });

        this.context.events.files.onMany({
            'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent
        });
    }

    componentWillUnmount = () => {
        this.context.events.folders.offMany({
            'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent
        });

        this.context.events.files.offMany({
            'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent
        });
    }

    onItemCreatedEvent = async (eventData: FileView | FolderView) => {

        const firstParent = _.last(eventData.path ?? []);

        if (firstParent && firstParent.id == this.state.folder.id) {
            await this.loadFolder(this.state.folder.id);

            openNotification(`New folder or file created`,
                `A new folder or file has been added to this folder`,
                "success",
                null,
                eventData.id
            );
        }
    }

    onItemCreated = async (response: FileView | FolderView) => {
        this.setState({
            created: [...this.state.created, response.id],
            showCreateFileDrawer: false,
            showCreateFolderDrawer: false
        });

        const folderId = this.state.folder?.id ?? "_root";

        await this.loadFolder(folderId);
    }

    onItemUpdatedEvent = async (eventData) => {
        const isSubFolder = _.find(this.state.folder.subFolders, folder => { return folder.id === eventData.id }) != null;
        const isFileInFolder = _.find(this.state.folder.files, file => { return file.id === eventData.id }) != null;

        if (this.state.folder && (this.state.folder.id === eventData.id || isSubFolder || isFileInFolder)) {
            await this.loadFolder(this.state.folder.id);

            openNotification(`Folder updated`,
                `Folder has been updated with latest changes`,
                "success",
                null,
                eventData.id
            );
        }
    }

    loadFolder = async (folderId: string, override: boolean = false) => {
        if (!this.state.entityRootFolderId && !override) return;

        this.setState({ loadingData: true });

        try {
            const response = await this.props.fileClient.getFolderById(this.props.entityId, folderId);

            if (response) {
                this.setState({ folder: response.data.view });
            }
        }
        catch (error: any) {
            this.setState({ error: error.error.title });
        }

        this.setState({ loadingData: false });
    }

    toggleAddFileDrawer = () => {
        this.setState({ showCreateFileDrawer: !this.state.showCreateFileDrawer });
    }

    onEditFileDrawerClose = () => {
        this.setState({ selectedFile: null });
    }

    onEditFile = (event, selectedFile) => {
        event.stopPropagation();
        this.setState({ selectedFile });
    }

    toggleAddFolderDrawer = () => {
        this.setState({ showCreateFolderDrawer: !this.state.showCreateFolderDrawer });
    }

    onEditFolderDrawerClose = () => {
        this.setState({ selectedFolder: null });
    }

    onEditFolder = (event, selectedFolder) => {
        event.stopPropagation();
        this.setState({ selectedFolder });
    }

    onRevert = async (event, selectedVersion) => {
        event.stopPropagation();

        try {
            const request: RevertFileToVersion = {
                versionId: selectedVersion.id
            };

            await this.props.fileClient.revertFile(this.props.entityId, selectedVersion.parentFileId, request);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onMoveFileOrFolder = (event, type: "folder" | "file", id: string) => {
        if (event)
            event.stopPropagation();

        this.setState({
            fileOrFolderTypeToMove: type,
            fileOrFolderIdToMove: id
        });
    }

    onSelect = async (selected) => {
        if (selected.type == "parent" || selected.type == "folder") {
            this.loadFolder(selected.id);
        }
        else if (selected.type == "file" || selected.type == "version") {

            if (selected.extension != null)
                selected.extension = selected.extension.toLowerCase();

            if (selected["extension"] === ".pdf") {
                const fileData = await this.props.fileClient.downloadFile(this.props.entityId, selected.parentFileId ?? selected.id, selected.type == "version" ? selected.id : null);

                const base64string: any = await blobToBase64(fileData);

                this.setState({
                    fileToDisplay: selected,
                    fileDataToDisplay: base64string,
                    showFileModal: true
                });
            }
            else if (selected.extension === ".xlsx" || selected.extension === ".eml" || selected.extension === ".xls" || selected.extension === ".docx" || selected.extension === ".doc" || selected.extension === ".pptx" || selected.extension === ".ppt") {
                const fileData = await this.props.fileClient.previewFile(this.props.entityId, selected.parentFileId ?? selected.id, PreviewType.PDF);
                const blobbed = fileData ? await fileData : null;
                const base64string: any = blobbed ? await blobToBase64(blobbed) : null;

                this.setState({
                    fileToDisplay: selected,
                    fileDataToDisplay: base64string,
                    showFileModal: true
                });
            }
            else {
                const fileData = await this.props.fileClient.downloadFile(this.props.entityId, selected.parentFileId ?? selected.id, selected.type == "version" ? selected.id : null);

                this.setState({
                    fileToDisplay: selected,
                    fileDataToDisplay: fileData,
                    showFileModal: true
                });
            }
        }
        else {
            return;
        }
    }

    changeFileModalVisibility = (fileModalVisibility: boolean) => {
        if (fileModalVisibility == false) {
            this.setState({
                fileDataToDisplay: null,
                fileToDisplay: null,
            })
        }

        this.setState({
            showFileModal: fileModalVisibility,
        })
    }

    onSearch = async (terms) => {
        this.setState({ loadingData: true, searchText: terms });

        try {
            const response = await this.props.fileClient.queryFiles(this.props.entityId, { terms: terms, parentId: this.state.folder.id, parentCollection: this.state.folder.reference?.collection, deleted: false });

            if (response)
                this.setState({
                    searchFiles: response.data.items
                });
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loadingData: false });

    }

    onBreadcrumbNavigation = async (folderId) => {
        this.setState({
            searchText: null,
            searchFiles: null
        })

        if (this.state.folder.id != folderId)
            this.loadFolder(folderId);
    }

    handleListData = (folder: FolderView, files: FileView[] | FileLinkView[]) => {

        let parentNavigator = null;
        for (let i = 0; i < folder.path?.length; i++) {
            const p = folder.path[i];

            if (folder.path.length > 1 && p.id == folder.id) {
                const parent = folder.path[i - 1];

                //@ts-ignore
                parent.type = "parent";
                //@ts-ignore
                parent.key = parent.id;

                parentNavigator = parent;
            }
        }

        const mappedFolders = this.mapFolders(folder);
        const versionFiles = this.mapFiles(files);

        const data = [...mappedFolders, ...versionFiles];

        if (parentNavigator != null)
            data.unshift(parentNavigator);

        return data;
    }

    mapFiles = (files: FileView[] | FileLinkView[]) => {
        const mapped = _.map(files, (file: FileView) => {
            if (file.versions && file.versions.length > 0) {
                const versions = _.map(file.versions, version => {
                    //@ts-ignore
                    version.type = "version";
                    //@ts-ignore
                    version.key = version.id;
                    //@ts-ignore
                    version.parentFileId = file.id;
                    return version;
                }).reverse();

                //@ts-ignore
                file.children = versions;
            }

            //@ts-ignore
            file.type = "file";
            //@ts-ignore
            file.key = file.id;

            return file;
        });

        return mapped;
    }

    mapFolders = (folder: FolderView) => {
        const mappedFolders = _.map(folder.subFolders, f => {
            //@ts-ignore
            f.type = "folder";
            //@ts-ignore
            f.key = f.id;

            return f;
        });

        return mappedFolders;
    }

    render = () => {

        //if (this.state.error) return <ErrorPage status="500" />;

        const listColumns = [
            {
                title: 'Name',
                render: (data: any) => {
                    let content;
                    if (data.type == "parent") {
                        content = <React.Fragment><FolderFilled /> <a>..</a></React.Fragment>;
                    }
                    else if (data.type == "folder") {
                        content = <React.Fragment><FolderFilled /> <a> {data.name}</a></React.Fragment>;
                    }
                    else if (data.type == "file" || data.type == "version") {
                        content = <React.Fragment>{getExtensionIcon(data.extension)}<a> {data.name}</a></React.Fragment>;
                    }
                    else {
                        content = "";
                    }
                    return (
                        <Tooltip title={data.name} placement="topLeft">
                            {content}
                        </Tooltip>
                    );
                },
                key: 'name',
                ellipsis: true,
                width: 150
            },
            {
                title: '',
                render: (data: any) => {
                    const isWebShared = _.find(data.applications ?? [], app => app.id == this.context.applicationId) !== undefined;
                    return ((isWebShared && data.applications?.length > 2) || !isWebShared && data.applications?.length > 1) ? <Tooltip title={_.map(data.applications, app => app.name).join(", ")}><TeamOutlined /></Tooltip > : null;
                },
                key: 'sharedIcon',
                responsive: ["md"] as Breakpoint[],
                width: 40
            },
            {
                title: 'Size',
                render: (data: any) => data.size ? formatFileSize(data.size) : "-",
                key: 'size',
                responsive: ["md"] as Breakpoint[],
                width: 100
            },
            {
                title: 'Last updated',
                render: (data: FileView | FolderView | FileVersionView) => {
                    //@ts-ignore
                    if (data.type == "version") {
                        const version = data as FileVersionView;
                        return <div style={{ whiteSpace: 'normal' }}>{`${moment(version.created).format('DD.MM.YYYY HH:mm')}`}</div>;
                    } else {
                        return <LastModifiedView {...data} />;
                        //var fileOrFolder = data as FileView | FolderView;

                        //var lastUpdatedByName = fileOrFolder.lastModifiedBy ? fileOrFolder.lastModifiedBy["name"] : null; //TODO: Name does not exists
                        //return <div style={{ whiteSpace: 'normal' }}>{`${moment(fileOrFolder.created).format('DD.MM.YYYY HH:mm')}${lastUpdatedByName ? " - " + lastUpdatedByName : ""}`}</div>;
                    }
                },
                key: 'lastUpdated',
                responsive: ['md'] as Breakpoint[],
                width: 125
            },
        ];

        if (this.props.fullSize) {
            //@ts-ignore
            listColumns.splice(1, 0, {
                title: 'Description',
                render: (data: any) => data.description ?? "",
                key: 'description',
                responsive: ["xxl"] as Breakpoint[],
            });

            //@ts-ignore
            //listColumns.splice(2, 0, {
            //    title: 'Accessible in',
            //    render: (data: any) => {
            //        var applicationNames = _.map(data.applications ?? [], app => app.name);
            //        return applicationNames.join(', ');
            //    },
            //    key: 'applications',
            //    responsive: ["xxl"] as Breakpoint[]
            //});
        }

        if (this.props.writeAccess === true || (this.props.writeAccess !== false && this.context.user.hasCapability(this.props.writeCapability))) {
            listColumns.push(
                //@ts-ignore
                {
                    title: '',
                    render: (data: any) => {
                        if (data.type == "version")
                            return (
                                <div className="table-actions">
                                    <Button
                                        icon={<RollbackOutlined />}
                                        type="default"
                                        onClick={(e) => this.onRevert(e, data)}
                                    >
                                        Revert
                                    </Button>
                                </div>
                            );
                        else
                            return (
                                <div className="table-actions">
                                    <Button
                                        shape="circle"
                                        icon={<EditOutlined />}
                                        type="default"
                                        onClick={(e) => data.type == "file" ? this.onEditFile(e, data) : data.type == "folder" ? this.onEditFolder(e, data) : null}
                                    />
                                    <Button
                                        shape="circle"
                                        key={`move - ${data.id}`}
                                        icon={<ArrowRightOutlined />}
                                        type="default"
                                        onClick={(e) => this.onMoveFileOrFolder(e, data.type, data.id)}
                                    />
                                </div>
                            );
                    },
                    key: 'actions',
                    ellipsis: false,
                    //@ts-ignore
                    width: 110
                });
        }

        const path = this.state.folder ? this.state.folder.path ?? [] : [];

        const breadcrumb = _.map(path ?? [], (p) => {
            return (
                <Breadcrumb.Item key={p.id}>
                    {p.id == this.state.folder.id ? p.name : <Button onClick={() => this.onBreadcrumbNavigation(p.id)} type="link">{p.name}</Button>}
                </Breadcrumb.Item>
            );
        });

        breadcrumb.unshift(
            <Breadcrumb.Item key="root">
                <Button onClick={() => this.onBreadcrumbNavigation("_root")} type="link">Root</Button>
            </Breadcrumb.Item>
        );

        let data = this.state.folder ? this.handleListData(this.state.folder, this.state.folder.files) : [];

        if (this.state.searchFiles && this.state.searchText)
            data = this.mapFiles(this.state.searchFiles);

        //var excludeDeleted = _.reject(data, d => {
        //    //@ts-ignore
        //    if (d.state)
        //        //@ts-ignore
        //        return d.state.deleted;
        //    else
        //        return d == null;
        //});
        const excludeDeleted = _.reject(data, { 'deleted': true })

        const scroll = 100 * listColumns.length;

        const actions = this.context.isMobile ? [] : [<EntitySearch autofocus={false} loading={this.state.loadingData} onChange={this.onSearch} value={this.state.searchText} size="small" className="files-search" key="entitySearch" />];

        if (!this.props.disableFolder)
            actions.push(
                <RequireCapability key="add-folder-capability" bypass={this.props.writeAccess} capability={this.props.writeCapability}>
                    <Button size={this.context.isMobile ? "large" : "small"} className={this.context.isMobile ? "file-action file-title-action" : "file-title-action"} onClick={this.toggleAddFolderDrawer} icon={this.context.isMobile ? <FolderAddOutlined /> : null}>
                        {this.context.isMobile ? null : "New folder"}
                    </Button>
                </RequireCapability>
            );

        actions.push(
            <RequireCapability key="add-file-capability" bypass={this.props.writeAccess} capability={this.props.writeCapability}>
                <Button size={this.context.isMobile ? "large" : "small"} className={this.context.isMobile ? "file-action file-title-action" : "file-title-action"} onClick={this.toggleAddFileDrawer} icon={this.context.isMobile ? <FileAddOutlined /> : null}>
                    {this.context.isMobile ? null : "Add files"}
                </Button>
            </RequireCapability>
        );

        return (
            <div className={`documents ${this.props.className ? this.props.className : ''} ${this.props.fullSize ? 'file-list-card-fullSize' : ''}`}>
                <Drawer
                    title="Add files"
                    onClose={this.toggleAddFileDrawer}
                    open={this.state.showCreateFileDrawer}
                    destroyOnClose={true}
                    component={
                        <FileCreateForm
                            onCancel={this.toggleAddFileDrawer}
                            entityId={this.props.entityId}
                            folderId={this.state.folder?.id}
                            onComplete={(created) => created ? this.onItemCreated(created) : this.toggleAddFileDrawer()}
                            getFolderSource={this.props.fileClient.getFolderById}
                            createFileSource={this.props.fileClient.createFile}
                        />
                    }
                />

                <Drawer
                    title="Edit file"
                    onClose={this.onEditFileDrawerClose}
                    open={this.state.selectedFile != null}
                    destroyOnClose={true}
                    component={
                        <FileEditForm
                            file={this.state.selectedFile}
                            entityId={this.props.entityId}
                            onComplete={this.onEditFileDrawerClose}
                            onCancel={this.onEditFileDrawerClose}
                            updateFileSource={this.props.fileClient.editFile}
                            deleteFileSource={this.props.fileClient.deleteFile}
                            restoreFileSource={this.props.fileClient.restoreFile}
                            getFolderSource={this.props.fileClient.getFolderById}
                            folderId={this.state.folder?.id}
                        />
                    }
                />

                <Drawer
                    title="New folder"
                    onClose={this.toggleAddFolderDrawer}
                    open={this.state.showCreateFolderDrawer}
                    destroyOnClose={true}
                    component={
                        <FolderCreateForm
                            onCancel={this.toggleAddFolderDrawer}
                            entityId={this.props.entityId}
                            parent={this.state.folder?.reference}
                            onComplete={(created) => created ? this.onItemCreated(created) : this.toggleAddFolderDrawer()}
                            getFolderSource={this.props.fileClient.getFolderById}
                            createFolderSource={this.props.fileClient.createFolder}
                        />
                    }
                />

                <Drawer
                    title="Edit folder"
                    onClose={this.onEditFolderDrawerClose}
                    open={this.state.selectedFolder != null}
                    destroyOnClose={true}
                    component={
                        <FolderEditForm
                            folder={this.state.selectedFolder}
                            entityId={this.props.entityId}
                            onComplete={this.onEditFolderDrawerClose}
                            editFolderSource={this.props.fileClient.editFolder}
                            deleteFolderSource={this.props.fileClient.deleteFolder}
                            restoreFolderSource={ this.props.fileClient.restoreFolder}
                        />
                    }
                />

                <Drawer
                    className="move-file-or-folder-drawer"
                    title={this.state.fileOrFolderTypeToMove && this.state.fileOrFolderTypeToMove != "folder" ? "Move file" : "Move folder"}
                    onClose={() => this.onMoveFileOrFolder(null, null, null)}
                    open={this.state.fileOrFolderIdToMove != null}
                    destroyOnClose={true}
                    component={
                        <FileMoveForm
                            fileOrFolderId={this.state.fileOrFolderIdToMove}
                            type={this.state.fileOrFolderTypeToMove}
                            rootFolderId={this.state.folder ? this.state.folder.path?.length > 0 ? this.state.folder.path[0].id : this.state.folder.id : null}
                            onComplete={() => this.onMoveFileOrFolder(null, null, null)}
                        />
                    }
                />

                <FileModal
                    filesInFolder={excludeDeleted}
                    file={this.state.fileToDisplay}
                    fileData={this.state.fileDataToDisplay}
                    showFileModal={this.state.showFileModal}
                    changeFileModalVisibility={this.changeFileModalVisibility}
                    changeFile={this.onSelect}
                />

                <div className="list-view-card files-list-card">
                    <Table
                        title={() =>
                            <React.Fragment>
                                {this.props.description}
                                <div className="list-title file-list-title">
                                    <Title level={4} className={this.context.isMobile ? "mobile-title" : "title"}>Files</Title>
                                    {this.context.isMobile
                                        ? <div style={{ display: "flex" }}>{actions}</div>
                                        : actions
                                    }
                                </div>
                                {<div className="subtitle"><Breadcrumb>{breadcrumb && breadcrumb.length > 1 ? breadcrumb : null}</Breadcrumb></div>}
                            </React.Fragment>
                        }
                        bordered
                        //@ts-ignore
                        dataSource={excludeDeleted}
                        columns={listColumns}
                        size={"small"}
                        loading={this.state.loadingData}
                        //scroll={{ x: scroll > width ? true : null }}
                        pagination={false}
                        onRow={(data, rowIndex) => {
                            return {
                                onClick: event => {
                                    event.stopPropagation();
                                    this.onSelect(data);
                                }
                            };
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default FileListCard;