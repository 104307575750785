import * as React from "react";
import _ from 'lodash';
import AppContext from "../../Definitions/AppContext";
import { PagedProductQuery, ProductUnit } from "../../ApiClient/swagger/data-contracts";
import { QuickSearch } from "../Shared/QuickSearch";

interface ProductQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedProductQuery>
}

export class ProductQuickSearch extends React.Component<ProductQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <>
                <QuickSearch name="All" queryParameters={{
                    units: null,
                    withParts: null
                } as PagedProductQuery}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Hourly" queryParameters={{
                    units: [ProductUnit.Hour],
                    withParts: null
                } as PagedProductQuery}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Subscription" queryParameters={{
                    units: [ProductUnit.Yearly, ProductUnit.Quarterly, ProductUnit.Monthly],
                    withParts: null
                } as PagedProductQuery}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Other products" queryParameters={{
                    units: [ProductUnit.Cubic, ProductUnit.Kilo, ProductUnit.Litre, ProductUnit.Meter, ProductUnit.Piece, ProductUnit.Square],
                    withParts: null
                } as PagedProductQuery}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="With parts" queryParameters={{
                    withParts: true,
                    units: null
                } as PagedProductQuery}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />
            </>
        );
    }
}