/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActivityType,
  CreateOrUpdateWorkCategory,
  SortDirection,
  WorkCategorySortOption,
  WorkCategoryView,
  WorkCategoryViewEntityViewModel,
  WorkCategoryViewPagedWorkCategoryQueryCollectionViewModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Workcategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags WorkCategory
   * @name GetWorkCategoryById
   * @request GET:/api/workcategories/{id}
   * @secure
   */
  getWorkCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<WorkCategoryViewEntityViewModel, any>({
      path: `/api/workcategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkCategory
   * @name UpdateWorkCategory
   * @request PUT:/api/workcategories/{id}
   * @secure
   */
  updateWorkCategory = (id: string, data: CreateOrUpdateWorkCategory, params: RequestParams = {}) =>
    this.http.request<WorkCategoryView, any>({
      path: `/api/workcategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkCategory
   * @name DeleteWorkCategory
   * @request DELETE:/api/workcategories/{id}
   * @secure
   */
  deleteWorkCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<WorkCategoryView, any>({
      path: `/api/workcategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkCategory
   * @name CreateWorkCategory
   * @request POST:/api/workcategories
   * @secure
   */
  createWorkCategory = (data: CreateOrUpdateWorkCategory, params: RequestParams = {}) =>
    this.http.request<WorkCategoryView, any>({
      path: `/api/workcategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkCategory
   * @name QueryWorkCategories
   * @request GET:/api/workcategories
   * @secure
   */
  queryWorkCategories = (
    query?: {
      sortBy?: WorkCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      activityTypes?: ActivityType[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<WorkCategoryViewPagedWorkCategoryQueryCollectionViewModel, any>({
      path: `/api/workcategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkCategory
   * @name RestoreWorkCategory
   * @request POST:/api/workcategories/{id}/restore
   * @secure
   */
  restoreWorkCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<WorkCategoryView, any>({
      path: `/api/workcategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags WorkCategory
   * @name UpdateWorkCategoryAttributes
   * @request POST:/api/workcategories/{id}/attributes
   * @secure
   */
  updateWorkCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<WorkCategoryView, any>({
      path: `/api/workcategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
