import _ from 'lodash';
import moment from 'moment';
import { Card } from "antd";
import ListViewCard from "../Shared/ListViewCard";
import { ProjectView, PagedProjectQuery, ProjectRoleType, ProjectType } from "../../ApiClient/swagger/data-contracts";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from '../../ApiClient/client';
import { updateCollectionWithEvent } from '../../Helpers/BasePageHelpers';
import { ProjectLink } from './ProjectLink';
import { ActorLink } from '../Actors';
import { formatCurrency, formatDuration } from '../../Helpers/Formatters';
import ProgressBar from '../Shared/ProgressBar';
import PriceProgressBar from '../Shared/PriceProgressBar';
import { useNavigate } from 'react-router-dom';


interface ProjectWidgetProps {
    title: any;
    className?: string;
    query: Partial<PagedProjectQuery>;
    exclutions?: string[];
    onItemCreatedEvent?: (collection: ProjectView[], project: ProjectView) => ProjectView[];
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    //formFilters?: Partial<PagedProjectQuery>;
}

export function ProjectWidget(props: ProjectWidgetProps) {

    const [collection, setCollection] = useState<ProjectView[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    //const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

    const context = useContext(AppContext);
    const navigate = useNavigate();

    async function loadCollection() {
        setLoading(true);

        const response = await client.projects
            .queryProjects(props.query)
            .catch(exception => setError(exception.error));

        if (response) setCollection(response.data.items)

        setLoading(false);
    }

    function onItemCreatedEvent(response: ProjectView) {
        if (!response || !props.onItemCreatedEvent) return;

        setCollection(prevCollection => {
            const newCollection = props.onItemCreatedEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }

    function onItemUpdatedEvent(response: ProjectView) {
        if (!response) return;

        setCollection(prevCollection => {
            const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }

    useEffect(() => {
        if (!collection) loadCollection();

        context.events.projects.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent
        });

        return () => {
            context.events.projects.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent
            });
        }
    }, [])

    const baseColumns = _.reject(props.customColumns ?? [
        {
            title: 'Project name',
            render: (project: ProjectView) => <ProjectLink {...project} />,
            key: 'name',
            ellipsis: true,
        },
        {
            title: 'Project leader',
            render: (project: ProjectView) => {
                const projectLeader = _.find(project.roles || [], r => {
                    return r.role == ProjectRoleType.Leader;
                });

                return projectLeader ? <ActorLink {...projectLeader.actor} /> : "";
            },
            key: 'projectLeader',
            ellipsis: true
        },
        {
            title: "Customer",
            render: (project: ProjectView) => project.customer ? <ActorLink {...project.customer} /> : "-",
            key: 'customer',
            ellipsis: true
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Start',
            render: (project: ProjectView) => project.firstActivity ? moment(project.firstActivity).format('DD.MM.YYYY') : project.plannedStart ? <span style={{ fontStyle: "italic" }}>{moment(project.plannedStart).format('DD.MM.YYYY')}</span> : "-",
            key: 'start',
            width: 90
        },
        {
            title: '',
            key: 'duration',
            render: (project: ProjectView) => formatDuration(project.duration),
            align: 'right',
            width: 90
        },
        {
            title: 'Progress',
            key: 'progress',
            render: (project: ProjectView) => {
                const hasEstimate = project.estimate != null && project.estimate > 0;
                const completed = project.ended != null;
                const paused = !project.activated && !project.ended && project.firstActivity != null;

                if (completed && !hasEstimate) {
                    return "Ended";
                }
                else if (paused && !hasEstimate) {
                    return "Paused";
                }
                else
                    return project.estimate != null && project.estimate > 0 ?
                        <ProgressBar hideDurationText estimate={project.estimate} duration={project.duration || 0} complete={completed} paused={paused} /> : ""
            },
        },
        {
            title: 'Estimate',
            key: 'estimate',
            render: (project: ProjectView) => project.estimate != null && project.estimate > 0 ? formatDuration(project.estimate) : "",
            width: 100
        },
        {
            title: '',
            key: 'invoiced',
            render: (project: ProjectView) => project.type == ProjectType.Hourly || project.type == ProjectType.Fixed ? formatCurrency(project.netInvoiced) : "",
            align: 'right',
            width: 90
        },
        {
            title: "Invoices",
            render: (project: ProjectView) => {
                if (project.type == ProjectType.Hourly)
                    return <PriceProgressBar hideInvoicedText invoiced={project.netInvoiced} runningSales={project.runningSalesPrice} />;

                if (project.type == ProjectType.Fixed)
                    return <PriceProgressBar hideInvoicedText invoiced={project.netInvoiced} runningSales={project.price} />;

                if (project.type == ProjectType.Internal)
                    return "-";

            },
            key: 'invoices'
        }
    ], c => _.find(props.exclutions, e => { return c.key == e }) != null);

    if (error) return <Card title={props.title}>{error}</Card>;

    return (
        <>
            <ListViewCard
                title={props.title}
                columns={baseColumns}
                data={collection}
                loading={loading}
                onSelect={(data: ProjectView) => navigate(`/projects/${data.id}`)}
                className="project-widget"
                //actions={context.user.hasCapability(Capabilities.ProjectsWrite) ? <Button key="add" size="small" onClick={() => setShowCreateDrawer(previous => !previous)}>Add project</Button> : null}
                {...props}
            />

            {/*<Drawer
                title="Create project"
                onClose={() => setShowCreateDrawer(false)}
                open={showCreateDrawer}
                component={
                    <ProjectCreateForm
                        filters={props.query}
                        onCancel={() => setShowCreateDrawer(false)}
                        onComplete={() => setShowCreateDrawer(previous => !previous)}
                    />
                }
            />*/}
        </>
    );
}