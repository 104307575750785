import { createContext } from "react";
import UserProfile from "./UserProfile";
import { EventClient } from '../ApiClient/Events';


export interface AppContextModel {
    user?: UserProfile;
    isMobile: boolean;
    breadcrumb: React.ReactNode;
    setBreadcrumbItems: (path: React.ReactNode) => void;
    //appConfig: AppConfiguration;
    applicationId: string;
    tenantId: string;
    events: EventClient;
    byndleEnabled: boolean;
}

const initialContext: AppContextModel = {
    user: null,
    isMobile: false,
    breadcrumb: null,
    setBreadcrumbItems: null,
    //appConfig: null,
    applicationId: null,
    tenantId: null,
    events: null,
    byndleEnabled: false
};

const AppContext = createContext<AppContextModel>(initialContext);
export default AppContext;