import { ProjectType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";


export function ProjectTypeSelector(props: EnumSelectorProps) {

    function renderOptionItem(item: ProjectType) {
        if (item == ProjectType.Fixed)
            return "Fixed price";

        return item;
    }

    enum ReversedProjectType {
        Fixed = 'Fixed',
        Hourly = 'Hourly',
        Internal = 'Internal',
    }

    return (
        <EnumSelector
            className="project-type-selector"
            enum={ReversedProjectType}
            placeholder={props.placeholder ?? 'Select project type...'}
            renderOptionItem={renderOptionItem}
            {...props}
        />
    );
}

export default ProjectTypeSelector;