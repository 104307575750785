import * as React from "react";
import _ from 'lodash';
import { AddOrUpdateProductPart, OrderlineView, OrderView, ProductLinkView, ProductPartialView, ProductPartView, ProductType, ProductView } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { ProductSelector } from "../Products";
import { NumberInput } from "../Shared/NumberInput";
import { Alert } from "antd";


interface CreateProductPartOnOrderlineProps {
    onComplete?: (created: OrderlineView) => void;
    customSubmit?: (part: ProductPartView) => void;
    product: ProductView | ProductLinkView;
    part?: ProductPartView;
    orderline?: Partial<OrderlineView>;
    order?: OrderView;
}

interface CreateProductPartOnOrderlineState {
    loading: boolean;
    error: string;
    productPart: ProductView | ProductPartialView;
    existsWarning: string;
}

class ProductPartOnOrderlineForm extends React.Component<CreateProductPartOnOrderlineProps, CreateProductPartOnOrderlineState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            productPart: this.props.part ? this.props.part.product : null,
            existsWarning: null
        }
    }

    onSubmit = async (part: AddOrUpdateProductPart) => {
        if (this.props.product.id === part.subProductId) {
            this.setState({
                error: "Product can't be a part of itself."
            });

            return;
        }

        this.setState({ loading: true });

        if (this.props.order) {
            const response = await client.orders
                .orderlineAddOrUpdateProductPart(this.props.order.id, this.props.orderline.id, part, addChangeVectorHeader(this.props.order.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }

        this.setState({ loading: false });
    }

    onCustomSubmit = (part: ProductPartView) => {
        part.product = this.state.productPart;

        if (this.props.product.id === part.product.id) {
            this.setState({
                error: "Product can't be a part of itself."
            });

            return;
        }

        this.setState({ loading: true });

        this.props.customSubmit(part);

        this.setState({ loading: false });
    }

    onProductPartChange = (productPart: ProductView) => {
        const productPartMatch = productPart ? _.find(this.props.orderline?.parts ?? [], p => p.product.id === productPart.id) != null : false;

        this.setState({
            productPart,
            existsWarning: productPartMatch ? "This product is already added. If you continue this will override the existing product line." : null
        });
    }

    render = () => {
        const productSelectorOptions = {
            filters: { types: [ProductType.Standard], withParts: false },
            onObjectChange: this.onProductPartChange
        };

        const productParam = this.props.customSubmit && this.props.part ? "product" : this.props.part ? "subProductId" : null;
        const productValue = this.props.part ? this.props.part.product.id : null;

        return (
            <BaseForm
                type={this.props.part != null ? FormType.Edit : FormType.Create}
                onSubmit={this.props.customSubmit ? this.onCustomSubmit : this.onSubmit}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={this.props.part != null ?
                    {
                        [productParam]: productValue,
                        quantity: this.props.part ? this.props.part.quantity : null
                    } : null}
            >
                <SelectorInput
                    param={this.props.customSubmit ? "product" : "subProductId"}
                    required
                    warningMessage="Please choose a product"
                    title="Product"
                    selector={<ProductSelector {...productSelectorOptions} />}
                />

                <NumberInput
                    param="quantity"
                    title="Quantity"
                />


                {this.state.existsWarning ? <Alert message={this.state.existsWarning} type="warning" style={{ marginBottom: 24 }} /> : null}

            </BaseForm>
        );
    }
}

export default ProductPartOnOrderlineForm;

