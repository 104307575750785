import * as React from "react";
import { Typography } from "antd";
import AppContext from "../../Definitions/AppContext";
import { AttributesView } from "./AttributesView";
const { Title } = Typography;


interface ViewPageHeaderProps {
    title: string;
    avatar?: React.ReactNode;
    actions?: React.ReactNode;
    titleActions?: React.ReactNode;
    links?: React.ReactNode;
    className?: string;
    attributes?: any;
    breakOnWidth?: number;
}

export class ViewPageHeader extends React.Component<ViewPageHeaderProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    componentDidMount = () => {
        this.setTabTitle();
    }

    componentDidUpdate = (prevProps: ViewPageHeaderProps) => {
        if (this.props.title != prevProps.title)
            this.setTabTitle();
    }

    setTabTitle = () => {
        if (this.props.title)
            document.title = this.props.title + " | " + "Overviu";
        else
            document.title = "Overviu";
    }

    render = () => {

        const hideAttributes = this.props.attributes === undefined;

        const titleActionsBreak = this.props.titleActions && window.matchMedia(`(min-width: 0) and (max-width: ${this.props.breakOnWidth ? this.props.breakOnWidth + "px" : "1600px"}) `).matches;

        return (
            <React.Fragment>
                <header
                    className={`page-header ${this.context.isMobile ? 'view-page-header-mobile' : 'view-page-header-desktop'} ${this.props.actions ? 'page-header-with-action' : null} ${this.props.className ?? ""}`}
                    style={titleActionsBreak ? null : { marginBottom: 18 }}
                >
                    <Title level={this.context.isMobile ? 3 : 2} className={`page-title view-page-title ${titleActionsBreak ? "act" : ""}`} style={this.props.titleActions && !titleActionsBreak ? { width: 'auto' } : {}}>{this.props.avatar}{this.props.avatar ? " " + this.props.title : this.props.title}</Title>
                    {titleActionsBreak ? null : this.props.titleActions ? <div className="title-actions">{this.props.titleActions}</div> : null}
                    {this.props.actions ? <nav className="page-actions">{this.props.actions}</nav> : null}
                </header>
                {hideAttributes ? null : <AttributesView attributes={this.props.attributes} />}
                {titleActionsBreak ? <div className="title-actions-break">{this.props.titleActions}</div> : null}
            </React.Fragment>
        );
    }
}

export default ViewPageHeader;