import * as React from "react";
import _ from 'lodash';
import { Tabs, Typography } from "antd";
import { formatMonthNr } from "../../Helpers/Formatters";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { ColumnChartCard } from "../Shared/Histogram";
import { ValueType } from "../../Models/ValueType";


const { TabPane } = Tabs;
const { Title } = Typography;

interface ProjectContributorsCategoriesRevenueTabProps {
    aggregates: any;
}

interface ProjectContributorsCategoriesRevenueTabState {
    activeTab: "contributors" | "categories" | "revenue";
}

export class ProjectContributorsCategoriesRevenueTab extends React.Component<ProjectContributorsCategoriesRevenueTabProps, ProjectContributorsCategoriesRevenueTabState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "contributors"
        };
    }

    onTabChange = (activeTab) => {
        this.setState({ activeTab });
    }

    formatMonths = (data) => {
        const month = data.split("|")[1];
        return month ? formatMonthNr(month) : data;
    }

    render = () => {

        const hasActivityRead = this.context.user.hasCapability(Capabilities.ActivitiesReadActor);
        const hasActorsRead = hasActivityRead && this.context.user.hasCapability(Capabilities.PeopleReadRelated);
        const hasInvoicesRead = this.context.user.hasCapability(Capabilities.InvoicesReadPersonal);
        const hasCategoriesRead = hasActivityRead && this.context.user.hasCapability(Capabilities.ActivitiesReadTimeTrackingCategories);

        return (
            <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange} className="project-graphs-tab">
                <TabPane key="contributors" tab={<Title level={4} className="title">Top contributors</Title>}>
                    {hasActorsRead ?
                        this.props.aggregates && this.props.aggregates["topProjectContributors"] ?
                            <ColumnChartCard
                                data={this.props.aggregates["topProjectContributors"]}
                                xField="label"
                                yField="sum"
                                valueBy={ValueType.Duration}
                                cardClassName="top-actors"
                                height={200}
                            /> : null
                        : null}
                </TabPane>

                <TabPane key="categories" tab={<Title level={4} className="title">Top categories</Title>}>
                    {hasCategoriesRead ?
                        this.props.aggregates && this.props.aggregates["topProjectCategories"] ?
                            <ColumnChartCard
                                xField="label"
                                yField="sum"
                                valueBy={ValueType.Duration}
                                height={200}
                                data={this.props.aggregates["topProjectCategories"]}
                            /> : null
                        : null}
                </TabPane>

                <TabPane key="revenue" tab={<Title level={4} className="title">Revenue</Title>}>
                    {hasInvoicesRead ?
                        <ColumnChartCard
                            title=""
                            height={200}
                            xField="label"
                            yField="sum"
                            drilldownField="drilldown"
                            drilldownXField="label"
                            drilldownYField="sum"
                            stopSort
                            xAxisFormatter={this.formatMonths}
                            data={this.props.aggregates ? this.props.aggregates["netAmountPerYearAndMonth"] : null}
                            valueBy={ValueType.Price}
                            totalDisplayCount={10}
                        />
                        : null}
                </TabPane>
            </Tabs>
        );
    }
}