import { Loading3QuartersOutlined } from '@ant-design/icons';
import AppContent from "../Framework/AppContent";
import AppLayout from '../Framework/AppLayout';

export function LoadingPage() {
    return (
        <section className={`page page-loading`}>
            <Loading3QuartersOutlined spin className="icon icon-loading" />
        </section>
    );
}

export function LoadingLayout() {
    return (
        <AppLayout>
            <AppContent>
                <LoadingPage />
            </AppContent>
        </AppLayout>
    );
}
