import * as React from "react";
import { ProductUnit } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";


export class ProductUnitSelector extends React.Component<EnumSelectorProps> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select unit...';

        return (
            <EnumSelector
                className="unit-selector"
                enum={ProductUnit}
                placeholder={placeholder}
                {...this.props}
            />
        );
    }
}