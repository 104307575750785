import * as React from 'react';
import _ from 'lodash';
import { Checkbox, Button, Card, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { CapabilityInfo } from '../../ApiClient/swagger/data-contracts';
import AppContext from '../../Definitions/AppContext';
import { displayFriendlyKey } from '../../Helpers/Formatters';
const { Title } = Typography;


interface CapabilitiesEditorProps {
    apiCapabilitites: Record<string, CapabilityInfo>;
    selected: Record<string, string[]>;
    applicationSpesific?: boolean;
    onChange: (key, value) => void;
    onSave: () => void;
    onCancel: () => void;
    disabledSubmit: boolean;
    selectAll: () => void;
    multiTenant: boolean;
}

interface CapabilitiesEditorState {
    isLoading: boolean;
    openAdvancedOptions: string[];
}


export class ApplicationCapabilitiesEditor extends React.Component<CapabilitiesEditorProps, CapabilitiesEditorState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            isLoading: false,
            openAdvancedOptions: [],
        }
    }

    render() {
        if (this.state.isLoading) return <LoadingOutlined />;
        const groups = [];
        const sorted = _.sortBy(this.props.apiCapabilitites, (v: CapabilityInfo, key) => { return -(v.capabilities?.api?.length ?? 0); });
        const realSorted = [];
        _.each(sorted, (c) => {
            _.each(this.props.apiCapabilitites, (d, key) => {
                if (d.application.id == c.application.id) {
                    const obj = {};
                    obj[key] = d;
                    realSorted.push(obj);
                }
            });
        });

        for (let i = 0; i < realSorted.length; i++) {
            _.each(realSorted[i], (v: CapabilityInfo, key) => {
                const subGroups = [];
                const resourceGroup = _.groupBy(v.capabilities.api, c => c.resource);

                const sortedResourceGroups = Object.entries(resourceGroup).sort();
                const sortedResourceGroupsDictionary = Object.fromEntries(sortedResourceGroups);

                _.each(sortedResourceGroupsDictionary, (r, k) => {
                    const subResources = _.groupBy(r, (sg) => sg.subResource ?? "");

                    const caps = _.map(subResources, (sr2, sr2Key) => {
                        const subGroup = _.map(sr2, cap => {

                            const selected = this.props.selected && this.props.selected[key] ? this.props.selected[key] : [];
                            const isSelected = _.find(selected, x => x == cap.id) != null;
                            return (
                                <div key={cap.id} className="capability-items" >
                                    <Checkbox disabled={this.props.multiTenant} value={cap.id} checked={isSelected} onChange={() => this.props.onChange(key, cap.id)}>
                                        {displayFriendlyKey(cap.action)}
                                    </Checkbox>
                                </div>
                            );
                        });

                        return (
                            <>
                                <div className="sub-group">{displayFriendlyKey(sr2Key)}</div>
                                {subGroup}
                            </>
                        );
                    });

                    subGroups.push(
                        <div className="sub-group-container" key={k}>
                            <Title level={5} className="sub-group-title">{displayFriendlyKey(k)}</Title>
                            <div className="capability-wrapper">{caps}</div>
                        </div>
                    );
                });

                groups.push(
                    <div key={key} className="group-wrapper">
                        <Title level={4} className="group-title">{v.application.name}</Title>
                        <span>{v.application.description}</span>
                        <div className="sub-group-wrapper">{subGroups}</div>
                    </div>
                );
            });
        }

        const actions = [
            <Button key="save-btn" disabled={this.props.multiTenant} className="ant-btn-primary" onClick={() => this.props.onSave()}>Save</Button>,
            <Button disabled={this.props.multiTenant } onClick={() => this.props.onCancel()}>Cancel</Button>,
            <Button disabled={this.props.disabledSubmit} onClick={() => this.props.selectAll()}>Select all</Button>
        ];

        return (
            <Card className="application-capability-editor" title={
                <div className="card-header">
                    <Title className="capa-title" level={3}>Capabilities</Title>
                    <div className="actions"> {actions} </div>
                </div>
            }>
                {groups}
            </Card>
        );
    }
}

export default ApplicationCapabilitiesEditor;