import _ from 'lodash';
import { Popover, Spin, Card } from 'antd';
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { useContext, useEffect, useState } from "react";
import { HourCategoryLinkView, HourCategoryView } from "../../ApiClient/swagger/data-contracts";

const { Meta } = Card;

interface WorkCategoryLinkCardProps {
    id?: string;
}


export function WorkCategoryLinkCard({ id }: WorkCategoryLinkCardProps) {

    const [workCategory, setWorkCategory] = useState<HourCategoryView>();
    const [error, setError] = useState<string>();

    const context = useContext(AppContext);

    useEffect(() => {
        if (!workCategory || id != workCategory.id)
            loadWorkCategory();

        context.events.workCategories.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.workCategories.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }

    }, [id, workCategory])


    function onItemUpdatedEvent(eventData: HourCategoryView) {
        if (workCategory?.id === eventData.id) {
            if (workCategory.changeVector == eventData.changeVector) return;

            setWorkCategory(eventData);
        }
    }

    async function loadWorkCategory() {
        const response = await client
            .workCategories.getWorkCategoryById(id)
            .catch(exception => setError(exception.error));

        if (response)
            setWorkCategory(response.data.view);
    }

    if (error) return <span>{error}</span>;
    if (!workCategory) return <Spin size="large" />;

    return (
        <Card className="category-link-card" bordered={false} size="small" title={workCategory.name}>
            <Meta description={workCategory.description} />
        </Card>
    );
}

interface WorkCategoryLinkProps extends WorkCategoryLinkCardProps, HourCategoryLinkView {
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
}

export function WorkCategoryLink(props: WorkCategoryLinkProps) {

    //const [error, setError] = useState<string>();

    const [workCategory, setWorkCategory] = useState<HourCategoryLinkView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.workCategories.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.workCategories.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    function onItemUpdatedEvent(eventData: HourCategoryView) {
        if (workCategory.id === eventData.id) {
            setWorkCategory(eventData);
        }
    }

    if (!workCategory.name) return null;

    if (!context.user.hasCapability(Capabilities.ActivitiesReadTimeTrackingCategories))
        return <Popover content="You do not have access to read actors.">{workCategory.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (workCategory.deleted)
        className += " deleted";

    if (props.className)
        className += " " + props.className;

    //var link = props.disableRedirect
    //    ? <Button type="link" className={className}>{props.name}</Button>
    //    : <Link onClick={(e) => e.stopPropagation()} to={`/workCategories/${props.id}`} className={className}>{props.name}</Link>;


    return <span className={className}>{workCategory.name}</span>;

    //if (props.disablePopover || context.isMobile)
    //    return link;

    //return (
    //    <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
    //        <Popover zIndex={1051} placement="bottomLeft" content={<WorkCategoryLinkCard id={props.id} />}>
    //            {link}
    //        </Popover>
    //    </span>
    //);
}