/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AbsenceCategorySortOption,
  AbsenceCategoryView,
  AbsenceCategoryViewEntityViewModel,
  AbsenceCategoryViewPagedAbsenceCategoryQueryCollectionViewModel,
  CreateOrUpdateAbsenceCategory,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Absencecategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AbsenceCategory
   * @name GetAbsenceCategoryById
   * @request GET:/api/absencecategories/{id}
   * @secure
   */
  getAbsenceCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<AbsenceCategoryViewEntityViewModel, any>({
      path: `/api/absencecategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AbsenceCategory
   * @name UpdateAbsenceCategory
   * @request PUT:/api/absencecategories/{id}
   * @secure
   */
  updateAbsenceCategory = (id: string, data: CreateOrUpdateAbsenceCategory, params: RequestParams = {}) =>
    this.http.request<AbsenceCategoryView, any>({
      path: `/api/absencecategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AbsenceCategory
   * @name DeleteAbsenceCategory
   * @request DELETE:/api/absencecategories/{id}
   * @secure
   */
  deleteAbsenceCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<AbsenceCategoryView, any>({
      path: `/api/absencecategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AbsenceCategory
   * @name CreateAbsenceCategory
   * @request POST:/api/absencecategories
   * @secure
   */
  createAbsenceCategory = (data: CreateOrUpdateAbsenceCategory, params: RequestParams = {}) =>
    this.http.request<AbsenceCategoryView, any>({
      path: `/api/absencecategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AbsenceCategory
   * @name QueryAbsenceCategories
   * @request GET:/api/absencecategories
   * @secure
   */
  queryAbsenceCategories = (
    query?: {
      sortBy?: AbsenceCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      requiresAdminApproval?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AbsenceCategoryViewPagedAbsenceCategoryQueryCollectionViewModel, any>({
      path: `/api/absencecategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AbsenceCategory
   * @name RestoreAbsenceCategory
   * @request POST:/api/absencecategories/{id}/restore
   * @secure
   */
  restoreAbsenceCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<AbsenceCategoryView, any>({
      path: `/api/absencecategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AbsenceCategory
   * @name UpdateAbsenceCategoryAttributes
   * @request POST:/api/absencecategories/{id}/attributes
   * @secure
   */
  updateAbsenceCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<AbsenceCategoryView, any>({
      path: `/api/absencecategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
