import { RoutineSeverityType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";

export function SeveritySelector(props: EnumSelectorProps) {
    return (
        <EnumSelector
            className="severity-selector"
            enum={RoutineSeverityType}
            placeholder={props.placeholder ? props.placeholder : 'Select severity...'}
            {...props}
        />
    );
}

export default SeveritySelector;