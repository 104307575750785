import { useContext, useEffect, useState } from "react";
import { ActivityView } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { Spin } from "antd";
import ActivityUpdateForm from "./ActivityUpdateForm";
import AppContext from "../../Definitions/AppContext";
import { Drawer } from "../Shared/Drawer";


interface ActivityEditDrawerProps {
    activityId?: string;
    onClose: () => void;
    open: boolean;
    onComplete?: (response: ActivityView) => void;
}

const ActivityEditDrawer: React.FC<ActivityEditDrawerProps> = ({ activityId, onClose, open, onComplete }) => {
    const context = useContext(AppContext);
    
    const [loadingActivity, setLoadingActivity] = useState(false);
    const [activity, setActivity] = useState<ActivityView>(null);

    async function loadActivity(activityId: string) {
        setLoadingActivity(true);
        const response = await client.activities.getActivityById(activityId);
        if (response) {
            setActivity(response.data.view);
        }
        setLoadingActivity(false);
    }

    useEffect(() => {
        if (activityId) {
            loadActivity(activityId);
        }
    }, [activityId]);

    return (
        <Drawer
            title={"Edit hour"}
            onClose={onClose}
            open={open}
            destroyOnClose
            component={
                <Spin spinning={loadingActivity}>
                    {activity &&
                        <ActivityUpdateForm
                            activity={activity}
                            actorId={context.user.actorId}
                            onComplete={onComplete}
                            onClose={onClose}
                        />
                    }
                </Spin>
            }
        />
    );
}

export default ActivityEditDrawer;