import { Result } from "antd";
import AppContent from "../Framework/AppContent";
import AppLayout from "../Framework/AppLayout";

//interface AccessDeniedPageProps {
//}

function AccessDeniedPage() {
    return (
        <div className="error-page">
            <Result
                status="403"
                title="Access denied"
                //subTitle="You are not authorized to access this page."
                //extra={<Button type="primary" onClick={() => window.location.href = "/logout"}>Login</Button>}
            />
        </div>
    );
}

export function AccessDeniedPageLayout() {
    return (
        <AppLayout>
            <AppContent>
                <AccessDeniedPage />
            </AppContent>
        </AppLayout>
    );
}

export default AccessDeniedPage;