import * as React from "react";
import _ from 'lodash';
import { Card } from "antd";
import Title from "antd/lib/typography/Title";
import { OrderType, OrderView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { formatCurrency } from "../../Helpers/Formatters";


interface OrderStatsCardProps {
    order: OrderView;
    className?: string
}


export class OrderStatsCard extends React.Component<OrderStatsCardProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render() {
        if (!this.props.order) return null;

        const stats = this.props.order.values;

        return (
            <React.Fragment>
                <Card
                    key="order-stats-card"
                    title={
                        <React.Fragment>
                            <Title level={4} className="title">Stats</Title>
                        </React.Fragment>
                    }
                    className={`order-stats-card info-card-container ${this.props.className ? this.props.className : ""}`}
                >
                    <dl className="">
                        <div>
                            <dt>Sales price</dt>
                            <dd>{formatCurrency(stats?.salesPrice ?? 0)}</dd>
                        </div>
                        <div>
                            <dt>Discount</dt>
                            <dd>{formatCurrency(stats?.discountAmount ?? 0)} ({stats?.discountPercentage.toFixed(2) ?? 0} %)</dd>
                        </div>
                        <div>
                            <dt>Discounted price</dt>
                            <dd>{formatCurrency(stats?.discountedPrice ?? 0)}</dd>
                        </div>
                        <div>
                            <dt>Cost price</dt>
                            <dd>{formatCurrency(stats?.costPrice ?? 0)}</dd>
                        </div>
                        <div>
                            <dt>Coverage</dt>
                            <dd>{formatCurrency(stats?.coverageAmount ?? 0)} ({stats?.coveragePercentage.toFixed(2) ?? 0} %)</dd>
                        </div>
                        {this.props.order.type != OrderType.Direct ?
                            <div>
                                <dt>Completed</dt>
                                <dd>{stats?.completed ?? 0} of {stats?.quantity ?? 0}</dd>
                            </div> : null}
                    </dl>
                </Card>
            </React.Fragment>
        );
    }
}

export default OrderStatsCard;