import moment from "moment";
import _ from 'lodash';
import { CallView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import ListViewCard from "../Shared/ListViewCard";
import { ContactPointPartialLink } from "../ContactPoints";
import { formatDuration } from "../../Helpers/Formatters";
import { useEffect, useState, useContext } from "react";
import client from "../../ApiClient/client";
import { updateCollectionWithEvent } from "../../Helpers/BasePageHelpers";
import { useNavigate } from "react-router-dom";


interface CallProps {
    call: CallView;
}

export function RelatedCallsListCard({ call }: CallProps) {

    const [calls, setCalls] = useState<CallView[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>();


    const context = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        loadCalls();

        context.events.calls.onMany({
            "created": onItemCreatedEvent,
            "updated": onItemUpdatedEvent,
            "deleted": onItemUpdatedEvent,
            "restored": onItemUpdatedEvent
        });

        return () => {
            context.events.calls.offMany({
                "created": onItemCreatedEvent,
                "updated": onItemUpdatedEvent,
                "deleted": onItemUpdatedEvent,
                "restored": onItemUpdatedEvent
            });
        }
    }, [])

    function onItemCreatedEvent(relatedCall: CallView) {

        if (call.id == relatedCall.id) return;

        const isSameFrom = relatedCall.from.id == call.from.id;
        const isSameTo = relatedCall.to.id == call.to.id;

        let matchingCompany = false;
        _.each(call.organizations || [], a => {
            const isCompanyMatch = _.find(relatedCall.organizations || [], a2 => { return a2.id == a.id; }) != null;
            if (isCompanyMatch) matchingCompany = true;
        });

        const isSameFromAndTo = (isSameFrom && isSameTo) || (relatedCall.from.id == call.to.id && relatedCall.to.id == call.from.id);
        const isSameFromAndSameCompany = isSameFromAndTo && matchingCompany;
        const isSameFromCompanyAndSameTo = matchingCompany && isSameTo;

        if (isSameFromAndTo || isSameFromAndSameCompany || isSameFromCompanyAndSameTo) {
            //const callsCopy = Array.isArray(calls) ? calls.slice() : [];
            //callsCopy.push(relatedCall);

            //const sortedCalls = _.orderBy(callsCopy, [(c) => {
            //    return (c.from.id == call.from.id && c.to.id == call.to.id) || (c.to.id == call.from.id && c.from.id == call.to.id)
            //}, "start"], ['desc', "desc"]);

            setCalls(prevValue => {
                const callsCopy = Array.isArray(prevValue) ? prevValue.slice() : [];
                callsCopy.push(relatedCall);

                const sortedCalls = _.orderBy(callsCopy, [(c) => {
                    return (c.from.id == call.from.id && c.to.id == call.to.id) || (c.to.id == call.from.id && c.from.id == call.to.id)
                }, "start"], ['desc', "desc"]);
                return sortedCalls;
            });
        }
    }

    function onItemUpdatedEvent(data: CallView) {
        setCalls(prev => {
            return updateCollectionWithEvent(prev?.slice(), data);
        });
    }

    async function loadCalls() {
        setLoading(true);

        const response = await client.calls
            .getCallRelated(call.id)
            .catch(exception => setError(exception.error));

        if (response) setCalls(response.data.items);

        setLoading(false);
    }

    const listColumns = [
        {
            title: 'Time',
            render: (call: CallView) => moment(call.start).format('DD.MM.YYYY HH:mm'),
            key: 'start',
            width: 140
        },
        {
            title: 'Duration',
            render: (call: CallView) => call.duration ? formatDuration(call.duration) : null,
            key: 'duration',
            width: 100
        },
        {
            title: 'From',
            render: (call: CallView) => call.from ? <ContactPointPartialLink contactPoint={call.from} hideContactPointIfActor /> : null,
            key: 'from',
            ellipsis: true
        },
        {
            title: 'To',
            render: (call: CallView) => call.to ? <ContactPointPartialLink contactPoint={call.to} hideContactPointIfActor /> : null,
            key: 'to',
            ellipsis: true
        },
        {
            title: 'Note',
            key: "note",
            dataIndex: 'note',
            ellipsis: true,
        }
    ];

    return (
        <ListViewCard
            title={null}
            columns={listColumns}
            data={calls}
            onSelect={(data: CallView) => navigate("/calls/" + data.id)}
            loading={loading}
            takeElements={10}
            className="related-calls"
        />
    );
}

export default RelatedCallsListCard;