/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateFileOrFolder,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveFileOrFolder,
  RootFolderView,
  SortDirection,
  UpdateFileOrFolder,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Folders<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags FileSystem
   * @name GetFolderById
   * @request GET:/api/folders/{folderId}
   * @secure
   */
  getFolderById = (folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name UpdateFolder
   * @request PUT:/api/folders/{folderId}
   * @secure
   */
  updateFolder = (folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name DeleteFolder
   * @request DELETE:/api/folders/{folderId}
   * @secure
   */
  deleteFolder = (folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name GetRootFoldersAndFiles
   * @request GET:/api/folders/_root
   * @secure
   */
  getRootFoldersAndFiles = (
    query?: {
      applicationId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RootFolderView, any>({
      path: `/api/folders/_root`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name QueryFolders
   * @request GET:/api/folders
   * @secure
   */
  queryFolders = (
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name CreateFolder
   * @request POST:/api/folders
   * @secure
   */
  createFolder = (data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name RestoreFolder
   * @request POST:/api/folders/{folderId}/restore
   * @secure
   */
  restoreFolder = (folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name MoveFolder
   * @request POST:/api/folders/{folderId}/move
   * @secure
   */
  moveFolder = (folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/folders/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags FileSystem
   * @name UpdateFolderAttributes
   * @request POST:/api/folders/{folderId}/attributes
   * @secure
   */
  updateFolderAttributes = (folderId: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/folders/${folderId}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
