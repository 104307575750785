/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateOrderCategory,
  OrderCategorySortOption,
  OrderCategoryView,
  OrderCategoryViewEntityViewModel,
  OrderCategoryViewPagedOrderCategoryQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Ordercategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags OrderCategory
   * @name GetOrderCategoryById
   * @request GET:/api/ordercategories/{id}
   * @secure
   */
  getOrderCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderCategoryViewEntityViewModel, any>({
      path: `/api/ordercategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderCategory
   * @name UpdateOrderCategory
   * @request PUT:/api/ordercategories/{id}
   * @secure
   */
  updateOrderCategory = (id: string, data: CreateOrUpdateOrderCategory, params: RequestParams = {}) =>
    this.http.request<OrderCategoryView, any>({
      path: `/api/ordercategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderCategory
   * @name DeleteOrderCategory
   * @request DELETE:/api/ordercategories/{id}
   * @secure
   */
  deleteOrderCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderCategoryView, any>({
      path: `/api/ordercategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderCategory
   * @name CreateOrderCategory
   * @request POST:/api/ordercategories
   * @secure
   */
  createOrderCategory = (data: CreateOrUpdateOrderCategory, params: RequestParams = {}) =>
    this.http.request<OrderCategoryView, any>({
      path: `/api/ordercategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderCategory
   * @name QueryOrderCategories
   * @request GET:/api/ordercategories
   * @secure
   */
  queryOrderCategories = (
    query?: {
      sortBy?: OrderCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrderCategoryViewPagedOrderCategoryQueryCollectionViewModel, any>({
      path: `/api/ordercategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderCategory
   * @name RestoreOrderCategory
   * @request POST:/api/ordercategories/{id}/restore
   * @secure
   */
  restoreOrderCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderCategoryView, any>({
      path: `/api/ordercategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderCategory
   * @name UpdateOrderCategoryAttributes
   * @request POST:/api/ordercategories/{id}/attributes
   * @secure
   */
  updateOrderCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<OrderCategoryView, any>({
      path: `/api/ordercategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
