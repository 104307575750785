import * as React from "react";
import _ from 'lodash';
import { QuickSearch } from "../Shared/QuickSearch";
import { PagedTaskQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";


interface TaskQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedTaskQuery>
}

export default class TaskQuickSearch extends React.Component<TaskQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <React.Fragment>
                <QuickSearch name="All" queryParameters={{
                    assigneeId: null,
                    ownerId: null,
                    closed: null
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="All open tasks" queryParameters={{
                    assigneeId: null,
                    ownerId: null,
                    closed: false
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="My open tasks" queryParameters={{
                    assigneeId: this.context ? this.context.user.actorId : "",
                    ownerId: this.context ? this.context.user.actorId : "",
                    closed: false
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>


            </React.Fragment>
        );
    }
}