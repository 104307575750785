import { HttpClient, RequestParams } from './swagger/http-client';
import { Applications } from './swagger/Applications';
import { Authentication } from './swagger/Authentication';
import { Calls } from './swagger/Calls';
import { Capabilities } from './swagger/Capabilities';
import { Contactpoints } from './swagger/Contactpoints';
import { Logins } from './swagger/Logins';
import { Roles } from './swagger/Roles';
import { Files } from './swagger/Files';
import { Folders } from './swagger/Folders';
import { Organizations } from './swagger/Organizations';
import BronnoysundService from './BronnoysundService';
import { People } from './swagger/People';
import { Products } from './swagger/Products';
import { Subscriptions } from './swagger/Subscriptions';
import { Tags } from './swagger/Tags';
import { Actors } from './swagger/Actors';
import { Tickets } from './swagger/Tickets';
import { Tagcategories } from './swagger/Tagcategories';
import { Workcategories } from './swagger/Workcategories';
import { Absencecategories } from './swagger/Absencecategories';
import { Hourcategories } from './swagger/Hourcategories';
import { Ordercategories } from './swagger/Ordercategories';
import { Meetings } from './swagger/Meetings';
import { Activities } from './swagger/Activities';
import { Productcategories } from './swagger/Productcategories';
import { Projects } from './swagger/Projects';
import { Tasks } from './swagger/Tasks';
import { Activitytransfers } from './swagger/Activitytransfers';
import { Suggestions } from './swagger/Suggestions';
import { Routines } from './swagger/Routines';
import { Orders } from './swagger/Orders';
import { Invoices } from './swagger/Invoices';
import { Leads } from './swagger/Leads';
import { Resources } from './swagger/Resources';
import { Incidents } from './swagger/Incidents';
import { Incidentcategories } from './swagger/Incidentcategories';
import { Resourcecategories } from './swagger/Resourcecategories';
import { Subscriptiontransfers } from './swagger/Subscriptiontransfers';
import { Routinecategories } from './swagger/Routinecategories';
import { Channels } from './swagger/Channels';
import { Externallinks } from './swagger/Externallinks';
import { Dashboards } from './swagger/Dashboards';
import { Feeds } from './swagger/Feeds';
import { News } from './swagger/News';
import { Newsfeed } from './swagger/Newsfeed';
import { Imageoftheday } from './swagger/Imageoftheday';
import { Customercategories } from './swagger/Customercategories';
import { Suppliercategories } from './swagger/Suppliercategories';
import { Polls } from './swagger/Polls';
import { Measuringpoints } from './swagger/Measuringpoints';
import { Measurements } from './swagger/Measurements';
import { Sensors } from './swagger/Sensors';
import { Sensoralerts } from './swagger/Sensoralerts';
import { Measuringpointtemplates } from './swagger/Measuringpointtemplates';
import { MeasurementTypes } from './swagger/MeasurementTypes';
import { SensorParts } from './swagger/SensorParts';
import { Sensorevents } from './swagger/Sensorevents';
import { Birthdays } from './swagger/Birthdays';
import { Notifications } from './swagger/Notifications';
import { Notificationtemplatess } from './swagger/Notificationtemplatess';
import { Notificationsettings } from './swagger/Notificationsettings';
import { Contacts } from './swagger/Contacts';
import { Employees } from './swagger/Employees';

export const csrf_header = "X-XSRF-TOKEN";
const csrf_cookie_name = "action_web.XSRF-TOKEN";

const httpClient = new HttpClient({
    baseUrl: "",
    baseApiParams: {
        secure: true
    },
    securityWorker: () => {
        return {
            headers: setInitHeaders()
        } as RequestParams;
    }
});

function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setInitHeaders(): HeadersInit {
    const headers: HeadersInit = {};

    const csrfToken = getCookie(csrf_cookie_name);
    if (csrfToken)
        headers[csrf_header] = csrfToken;

    return headers;
}

//async function CustomFetch(input: RequestInfo | URL, init?: RequestInit) {

//    var response = await fetch(input, {
//        method: init.method,
//        headers: init.headers
//    });

//    if (response.status === 200 || response.status === 201)
//        return await response.blob();
//    else
//        return null;
//}


class FileApi extends Files {
    constructor(http: HttpClient) {
        super(http);
    }

    download = async (fileId: string, versionId?: string) => {

        let url = `/api/files/${fileId}/download`;
        if (versionId)
            url += `?versionId=${versionId}`;

        const response = await fetch(url, {
            method: "GET",
            //headers: init.headers
        });

        if (response.status === 200 || response.status === 201) {
            const blobbed = await response.blob();
            return blobbed;
        }

        else
            return null;
    }

    //getThumbnail = async (fileId: string) => {
    //    var response = await fetch(`/api/files/${fileId}/thumbnail`, {
    //        method: "GET",
    //        //headers: init.headers
    //    });

    //    if (response.status === 200 || response.status === 201) {
    //        var blobbed = await response.blob();
    //        return blobbed;
    //    }

    //    else
    //        return null;
    //}
}

const client = {
    //auth
    auth: new Authentication(httpClient),
    roles: new Roles(httpClient),
    applications: new Applications(httpClient),
    logins: new Logins(httpClient),
    capabilities: new Capabilities(httpClient),

    //core
    actors: new Actors(httpClient),
    organizations: new Organizations(httpClient),
    people: new People(httpClient),
    resources: new Resources(httpClient),
    resourceCategories: new Resourcecategories(httpClient),
    products: new Products(httpClient),
    contactPoints: new Contactpoints(httpClient),
    calls: new Calls(httpClient),
    subscriptions: new Subscriptions(httpClient),
    subscriptionTransfers: new Subscriptiontransfers(httpClient),
    tags: new Tags(httpClient),
    tickets: new Tickets(httpClient),
    tagCategories: new Tagcategories(httpClient),
    hourCategories: new Hourcategories(httpClient),
    workCategories: new Workcategories(httpClient),
    absenceCategories: new Absencecategories(httpClient),
    orderCategories: new Ordercategories(httpClient),
    meetings: new Meetings(httpClient),
    activities: new Activities(httpClient),
    activityTransfers: new Activitytransfers(httpClient),
    suggestions: new Suggestions(httpClient),
    productCategories: new Productcategories(httpClient),
    projects: new Projects(httpClient),
    tasks: new Tasks(httpClient),
    routines: new Routines(httpClient),
    routineCategories: new Routinecategories(httpClient),
    orders: new Orders(httpClient),
    invoices: new Invoices(httpClient),
    leads: new Leads(httpClient),
    incidents: new Incidents(httpClient),
    incidentCategories: new Incidentcategories(httpClient),
    channels: new Channels(httpClient),
    externalLinks: new Externallinks(httpClient),
    dashboards: new Dashboards(httpClient),
    feeds: new Feeds(httpClient),
    news: new News(httpClient),
    newsFeed: new Newsfeed(httpClient),
    imageoftheday: new Imageoftheday(httpClient),
    customerCategories: new Customercategories(httpClient),
    supplierCategories: new Suppliercategories(httpClient),
    polls: new Polls(httpClient),
    measuringPoints: new Measuringpoints(httpClient),
    measuringPointTemplates: new Measuringpointtemplates(httpClient),
    measurements: new Measurements(httpClient),
    measurementTypes: new MeasurementTypes(httpClient),
    sensors: new Sensors(httpClient),
    sensorEvents: new Sensorevents(httpClient),
    sensorParts: new SensorParts(httpClient),
    sensorAlerts: new Sensoralerts(httpClient),
    birthdays: new Birthdays(httpClient),
    contacts: new Contacts(httpClient),
    notifications: new Notifications(httpClient),
    notificationSettingss: new Notificationsettings(httpClient),
    Notificationtemplatess: new Notificationtemplatess(httpClient),
    employees: new Employees(httpClient),

    //files
    files: new FileApi(httpClient),
    folders: new Folders(httpClient),

    //client


    //misc
    brreg: new BronnoysundService()
};


export default client;