/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateRoutineCategory,
  RoutineCategorySortOption,
  RoutineCategoryView,
  RoutineCategoryViewEntityViewModel,
  RoutineCategoryViewPagedRoutineCategoryQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Routinecategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags RoutineCategory
   * @name GetRoutineCategoryById
   * @request GET:/api/routinecategories/{id}
   * @secure
   */
  getRoutineCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<RoutineCategoryViewEntityViewModel, any>({
      path: `/api/routinecategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineCategory
   * @name UpdateRoutineCategory
   * @request PUT:/api/routinecategories/{id}
   * @secure
   */
  updateRoutineCategory = (id: string, data: CreateOrUpdateRoutineCategory, params: RequestParams = {}) =>
    this.http.request<RoutineCategoryView, any>({
      path: `/api/routinecategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineCategory
   * @name DeleteRoutineCategory
   * @request DELETE:/api/routinecategories/{id}
   * @secure
   */
  deleteRoutineCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<RoutineCategoryView, any>({
      path: `/api/routinecategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineCategory
   * @name CreateRoutineCategory
   * @request POST:/api/routinecategories
   * @secure
   */
  createRoutineCategory = (data: CreateOrUpdateRoutineCategory, params: RequestParams = {}) =>
    this.http.request<RoutineCategoryView, any>({
      path: `/api/routinecategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineCategory
   * @name QueryRoutineCategories
   * @request GET:/api/routinecategories
   * @secure
   */
  queryRoutineCategories = (
    query?: {
      sortBy?: RoutineCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RoutineCategoryViewPagedRoutineCategoryQueryCollectionViewModel, any>({
      path: `/api/routinecategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineCategory
   * @name RestoreRoutineCategory
   * @request POST:/api/routinecategories/{id}/restore
   * @secure
   */
  restoreRoutineCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<RoutineCategoryView, any>({
      path: `/api/routinecategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RoutineCategory
   * @name UpdateRoutineCategoryAttributes
   * @request POST:/api/routinecategories/{id}/attributes
   * @secure
   */
  updateRoutineCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<RoutineCategoryView, any>({
      path: `/api/routinecategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
