import _ from 'lodash';
import { Button, Card, Divider, Avatar, Dropdown } from 'antd';
import { UserOutlined, CloseOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorIds } from "../../Definitions/_definitions";



export default function ProfileHeaderDropdown() {

    const [visible, setDropdownVisible] = useState(false);

    const context = useContext(AppContext);
    if (context.user == null) return null;

    const organizations = _.map(_.filter(context.user?.organizationRoles, or => !or.endDate), r => {
        if (context.user.hasCapability(Capabilities.OrganizationsReadRelated))
            return <div key={r.organization.id}><Link to={`/organizations/${r.organization.id}`} onClick={() => setDropdownVisible(false)}>{r.organization.name}</Link></div>;
        else
            return <div key={r.organization.id}>{r.organization.name}</div>;
    });

    const readProfile = context.user.hasCapability(Capabilities.ProfileRead);

    return (
        <Dropdown
            overlay={
                <Card className="dropdown-card profile-dropdown-card" size="small">
                    {context.user.actorId == ActorIds.System ? null :
                        <div className={`dropdown-links ${!readProfile ? "right-align" : null}`}>
                            {context.user.hasCapability(Capabilities.ProfileRead) ? <Link to={context.user ? `/people/${context.user.actorId}` : "/profile"} onClick={() => setDropdownVisible(false)}>View profile</Link> : null}
                            <Button icon={<CloseOutlined className="close-icon" />} onClick={() => setDropdownVisible(false)} />
                        </div>
                    }

                    <div className="profile-info">
                        <div className="profile-icon">
                            <Avatar shape="square" src={context.user != null && context.user.avatar != null ? `/api/people/${context.user.actorId}/avatar` : null} icon={<UserOutlined />} />
                        </div>
                        <div>
                            <div>{context.user != null ? context.user.name : context.user.name}</div>
                            {organizations}
                        </div>
                    </div>

                    <Divider className="profile-divider" />
                    <Button className="dropdown-btn logout-btn" type="link" onClick={() => window.location.href = "/logout"}>Log out</Button>
                    {context.user.manageAccountUrl ? <a className="dropdown-btn changepw-btn" type="link" target="_blank" href={context.user.manageAccountUrl}>Manage account <ArrowRightOutlined rotate={-45} /></a> : null}
                </Card>
            }
            placement="bottomRight"
            arrow
            trigger={['click']}
            onOpenChange={(flag => setDropdownVisible(flag))}
            open={visible}
        >
            <UserOutlined />
        </Dropdown>
    );
}