/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdateProductPart,
  CompletionSortOption,
  CompletionView,
  CompletionViewPagedCompletionQueryCollectionViewModel,
  CreateCompletion,
  CreateFileOrFolder,
  CreateNote,
  CreateOrder,
  CreateOrderline,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  OrderAggregationResultOrderAggregateQueryAggregationResults,
  OrderAggregationTypes,
  OrderDateType,
  OrderSortOption,
  OrderType,
  OrderView,
  OrderViewEntityViewModel,
  OrderViewPagedOrderQueryCollectionViewModel,
  OrderlineReportGroupBy,
  OrderlineReportSortOption,
  OrderlineReportTransferType,
  OrderlineReportTypeOfTransfer,
  OrderlineReportViewPagedOrderlineReportQueryCollectionViewModel,
  OrderlineSortOption,
  OrderlineView,
  OrderlineViewEntityViewModel,
  OrderlineViewPagedOrderlineQueryCollectionViewModel,
  PreviewType,
  RevertFileToVersion,
  SortDirection,
  UpdateCompletion,
  UpdateFileOrFolder,
  UpdateNote,
  UpdateOrder,
  UpdateOrderline,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Orders<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Order
   * @name GetOrderById
   * @request GET:/api/orders/{id}
   * @secure
   */
  getOrderById = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderViewEntityViewModel, any>({
      path: `/api/orders/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name UpdateOrder
   * @request PUT:/api/orders/{id}
   * @secure
   */
  updateOrder = (id: string, data: UpdateOrder, params: RequestParams = {}) =>
    this.http.request<OrderView, any>({
      path: `/api/orders/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name DeleteOrder
   * @request DELETE:/api/orders/{id}
   * @secure
   */
  deleteOrder = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderView, any>({
      path: `/api/orders/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name CreateOrder
   * @request POST:/api/orders
   * @secure
   */
  createOrder = (data: CreateOrder, params: RequestParams = {}) =>
    this.http.request<OrderView, any>({
      path: `/api/orders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name QueryOrders
   * @request GET:/api/orders
   * @secure
   */
  queryOrders = (
    query?: {
      sortBy?: OrderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      types?: OrderType[];
      actorId?: string;
      applicationId?: string;
      projectId?: string;
      categoryId?: string;
      subscriptionId?: string;
      subscriptionTransferId?: string;
      activityTransferId?: string;
      tagIds?: string[];
      reference?: string;
      dateTypes?: OrderDateType[];
      /** @format date-time */
      dateFrom?: string;
      /** @format date-time */
      dateTo?: string;
      completed?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrderViewPagedOrderQueryCollectionViewModel, any>({
      path: `/api/orders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name RestoreOrder
   * @request POST:/api/orders/{id}/restore
   * @secure
   */
  restoreOrder = (id: string, params: RequestParams = {}) =>
    this.http.request<OrderView, any>({
      path: `/api/orders/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name UpdateOrderAttributes
   * @request POST:/api/orders/{id}/attributes
   * @secure
   */
  updateOrderAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<OrderView, any>({
      path: `/api/orders/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name GetOrderlineById
   * @request GET:/api/orders/{orderId}/lines/{orderlineId}
   * @secure
   */
  getOrderlineById = (orderId: string, orderlineId: string, params: RequestParams = {}) =>
    this.http.request<OrderlineViewEntityViewModel, any>({
      path: `/api/orders/${orderId}/lines/${orderlineId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name UpdateOrderline
   * @request PUT:/api/orders/{orderId}/lines/{orderlineId}
   * @secure
   */
  updateOrderline = (orderId: string, orderlineId: string, data: UpdateOrderline, params: RequestParams = {}) =>
    this.http.request<OrderlineView, any>({
      path: `/api/orders/${orderId}/lines/${orderlineId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name DeleteOrderline
   * @request DELETE:/api/orders/{orderId}/lines/{orderlineId}
   * @secure
   */
  deleteOrderline = (orderId: string, orderlineId: string, params: RequestParams = {}) =>
    this.http.request<OrderlineView, any>({
      path: `/api/orders/${orderId}/lines/${orderlineId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name CreateOrderline
   * @request POST:/api/orders/{orderId}/lines
   * @secure
   */
  createOrderline = (orderId: string, data: CreateOrderline, params: RequestParams = {}) =>
    this.http.request<OrderlineView, any>({
      path: `/api/orders/${orderId}/lines`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name QueryOrderline
   * @request GET:/api/orders/_all/lines
   * @secure
   */
  queryOrderline = (
    query?: {
      sortBy?: OrderlineSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      orderId?: string;
      productId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrderlineViewPagedOrderlineQueryCollectionViewModel, any>({
      path: `/api/orders/_all/lines`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name RestoreOrderline
   * @request POST:/api/orders/{orderId}/lines/{orderlineId}/restore
   * @secure
   */
  restoreOrderline = (orderId: string, orderlineId: string, params: RequestParams = {}) =>
    this.http.request<OrderlineView, any>({
      path: `/api/orders/${orderId}/lines/${orderlineId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderlineAddOrUpdateProductPart
   * @request POST:/api/orders/{orderId}/lines/{orderlineId}/parts
   * @secure
   */
  orderlineAddOrUpdateProductPart = (
    orderId: string,
    orderlineId: string,
    data: AddOrUpdateProductPart,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderlineView, any>({
      path: `/api/orders/${orderId}/lines/${orderlineId}/parts`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderlineRemoveProductPart
   * @request DELETE:/api/orders/{orderId}/lines/{orderlineId}/parts/{productId}
   * @secure
   */
  orderlineRemoveProductPart = (orderId: string, orderlineId: string, productId: string, params: RequestParams = {}) =>
    this.http.request<OrderlineView, any>({
      path: `/api/orders/${orderId}/lines/${orderlineId}/parts/${productId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name UpdateOrderlineAttributes
   * @request POST:/api/orders/{orderId}/lines/{orderlineId}/attributes
   * @secure
   */
  updateOrderlineAttributes = (
    orderId: string,
    orderlineId: string,
    data: Record<string, string>,
    params: RequestParams = {},
  ) =>
    this.http.request<OrderlineView, any>({
      path: `/api/orders/${orderId}/lines/${orderlineId}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name GetOrderlineReport
   * @request GET:/api/orders/report
   * @secure
   */
  getOrderlineReport = (
    query?: {
      sortBy?: OrderlineReportSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      customerIds?: string[];
      categoryIds?: string[];
      productIds?: string[];
      subscriptionTransferIds?: string[];
      activityTransferIds?: string[];
      groupBy?: OrderlineReportGroupBy;
      type?: OrderlineReportTypeOfTransfer;
      transferType?: OrderlineReportTransferType;
      /** @format date-time */
      fromDate?: string;
      /** @format date-time */
      toDate?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrderlineReportViewPagedOrderlineReportQueryCollectionViewModel, any>({
      path: `/api/orders/report`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name GetCompletionById
   * @request GET:/api/orders/{orderId}/completions/{completionId}
   * @secure
   */
  getCompletionById = (orderId: string, completionId: string, params: RequestParams = {}) =>
    this.http.request<CompletionView, any>({
      path: `/api/orders/${orderId}/completions/${completionId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name UpdateCompletion
   * @request PUT:/api/orders/{orderId}/completions/{completionId}
   * @secure
   */
  updateCompletion = (orderId: string, completionId: string, data: UpdateCompletion, params: RequestParams = {}) =>
    this.http.request<CompletionView, any>({
      path: `/api/orders/${orderId}/completions/${completionId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name DeleteCompletion
   * @request DELETE:/api/orders/{orderId}/completions/{completionId}
   * @secure
   */
  deleteCompletion = (orderId: string, completionId: string, params: RequestParams = {}) =>
    this.http.request<CompletionView, any>({
      path: `/api/orders/${orderId}/completions/${completionId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name CreateCompletion
   * @request POST:/api/orders/{orderId}/completions
   * @secure
   */
  createCompletion = (orderId: string, data: CreateCompletion, params: RequestParams = {}) =>
    this.http.request<CompletionView, any>({
      path: `/api/orders/${orderId}/completions`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name QueryCompletions
   * @request GET:/api/orders/_all/completions
   * @secure
   */
  queryCompletions = (
    query?: {
      sortBy?: CompletionSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      orderlineId?: string;
      productId?: string;
      /** @format date-time */
      timestampAfter?: string;
      /** @format date-time */
      timestampBefore?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CompletionViewPagedCompletionQueryCollectionViewModel, any>({
      path: `/api/orders/_all/completions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name RestoreCompletion
   * @request POST:/api/orders/{orderId}/completions/{completionId}/restore
   * @secure
   */
  restoreCompletion = (orderId: string, completionId: string, params: RequestParams = {}) =>
    this.http.request<CompletionView, any>({
      path: `/api/orders/${orderId}/completions/${completionId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name UpdateCompletionAttributes
   * @request POST:/api/orders/{orderId}/completions/{completionId}/attributes
   * @secure
   */
  updateCompletionAttributes = (
    orderId: string,
    completionId: string,
    data: Record<string, string>,
    params: RequestParams = {},
  ) =>
    this.http.request<CompletionView, any>({
      path: `/api/orders/${orderId}/completions/${completionId}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name GetOrderAggregates
   * @request GET:/aggs/orders
   * @secure
   */
  getOrderAggregates = (
    query?: {
      orderId?: string;
      aggs?: OrderAggregationTypes[];
      types?: OrderType[];
      actorId?: string;
      applicationId?: string;
      projectId?: string;
      categoryId?: string;
      subscriptionId?: string;
      subscriptionTransferId?: string;
      activityTransferId?: string;
      tagIds?: string[];
      reference?: string;
      dateTypes?: OrderDateType[];
      /** @format date-time */
      dateFrom?: string;
      /** @format date-time */
      dateTo?: string;
      completed?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OrderAggregationResultOrderAggregateQueryAggregationResults, any>({
      path: `/aggs/orders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name GetOrderAggregatesById
   * @request GET:/aggs/orders/{id}
   * @secure
   */
  getOrderAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/orders/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name GetOrderFileById
   * @request GET:/api/orders/{entityId}/files/{fileId}
   * @secure
   */
  getOrderFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/orders/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name UpdateOrderFile
   * @request PUT:/api/orders/{entityId}/files/{fileId}
   * @secure
   */
  updateOrderFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/orders/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name DeleteOrderFile
   * @request DELETE:/api/orders/{entityId}/files/{fileId}
   * @secure
   */
  deleteOrderFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/orders/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name DownloadOrderFile
   * @request GET:/api/orders/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadOrderFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/orders/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name PreviewOrderFile
   * @request GET:/api/orders/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewOrderFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/orders/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name CreateOrderFile
   * @request POST:/api/orders/{entityId}/files
   * @secure
   */
  createOrderFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/orders/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name QueryOrderFiles
   * @request GET:/api/orders/{entityId}/files
   * @secure
   */
  queryOrderFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/orders/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name RestoreOrderFile
   * @request POST:/api/orders/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreOrderFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/orders/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name MoveOrderFile
   * @request POST:/api/orders/{entityId}/files/{fileId}/move
   * @secure
   */
  moveOrderFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/orders/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name RevertOrderFile
   * @request POST:/api/orders/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertOrderFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/orders/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name GetOrderFolderById
   * @request GET:/api/orders/{entityId}/folders/{folderId}
   * @secure
   */
  getOrderFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/orders/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name UpdateOrderFolder
   * @request PUT:/api/orders/{entityId}/folders/{folderId}
   * @secure
   */
  updateOrderFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/orders/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name DeleteOrderFolder
   * @request DELETE:/api/orders/{entityId}/folders/{folderId}
   * @secure
   */
  deleteOrderFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/orders/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name CreateOrderFolder
   * @request POST:/api/orders/{entityId}/folders
   * @secure
   */
  createOrderFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/orders/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name QueryOrderFolders
   * @request GET:/api/orders/{entityId}/folders
   * @secure
   */
  queryOrderFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/orders/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name RestoreOrderFolder
   * @request POST:/api/orders/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreOrderFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/orders/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderFile
   * @name MoveOrderFolder
   * @request POST:/api/orders/{entityId}/{folderId}/move
   * @secure
   */
  moveOrderFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/orders/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderNote
   * @name GetOrderNoteById
   * @request GET:/api/orders/{entityId}/notes/{noteId}
   * @secure
   */
  getOrderNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/orders/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderNote
   * @name UpdateOrderNote
   * @request PUT:/api/orders/{entityId}/notes/{noteId}
   * @secure
   */
  updateOrderNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/orders/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderNote
   * @name DeleteOrderNote
   * @request DELETE:/api/orders/{entityId}/notes/{noteId}
   * @secure
   */
  deleteOrderNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/orders/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderNote
   * @name CreateOrderNote
   * @request POST:/api/orders/{entityId}/notes
   * @secure
   */
  createOrderNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/orders/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderNote
   * @name QueryOrderNotes
   * @request GET:/api/orders/{entityId}/notes
   * @secure
   */
  queryOrderNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/orders/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderNote
   * @name RestoreOrderNote
   * @request POST:/api/orders/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreOrderNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/orders/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
