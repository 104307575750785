/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateNote,
  CreateSubscription,
  CreateSubscriptionLine,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  ProductReportResultViewPagedSubscriptionReportQueryCollectionViewModel,
  ResourceReportType,
  ResumeOrUpdateSubscriptionLine,
  SortDirection,
  StopSubscriptionLine,
  SubscriptionAggregationResultSubscriptionAggregateQueryAggregationResults,
  SubscriptionAggregationTypes,
  SubscriptionLineSortOption,
  SubscriptionLineView,
  SubscriptionLineViewPagedSubscriptionLineQueryCollectionViewModel,
  SubscriptionReportGroupBy,
  SubscriptionReportSortOption,
  SubscriptionSortOption,
  SubscriptionStatusType,
  SubscriptionType,
  SubscriptionView,
  SubscriptionViewEntityViewModel,
  SubscriptionViewPagedSubscriptionQueryCollectionViewModel,
  UpdateNote,
  UpdateSubscription,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Subscriptions<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Subscription
   * @name GetSubscriptionById
   * @request GET:/api/subscriptions/{id}
   * @secure
   */
  getSubscriptionById = (id: string, params: RequestParams = {}) =>
    this.http.request<SubscriptionViewEntityViewModel, any>({
      path: `/api/subscriptions/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name UpdateSubscription
   * @request PUT:/api/subscriptions/{id}
   * @secure
   */
  updateSubscription = (id: string, data: UpdateSubscription, params: RequestParams = {}) =>
    this.http.request<SubscriptionView, any>({
      path: `/api/subscriptions/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name DeleteSubscription
   * @request DELETE:/api/subscriptions/{id}
   * @secure
   */
  deleteSubscription = (id: string, params: RequestParams = {}) =>
    this.http.request<SubscriptionView, any>({
      path: `/api/subscriptions/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name CreateSubscription
   * @request POST:/api/subscriptions
   * @secure
   */
  createSubscription = (data: CreateSubscription, params: RequestParams = {}) =>
    this.http.request<SubscriptionView, any>({
      path: `/api/subscriptions`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name QuerySubscriptions
   * @request GET:/api/subscriptions
   * @secure
   */
  querySubscriptions = (
    query?: {
      sortBy?: SubscriptionSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      actorId?: string;
      types?: SubscriptionType[];
      statuses?: SubscriptionStatusType[];
      hasDifference?: boolean;
      hasProductDifference?: boolean;
      hasResourceDifference?: boolean;
      tagIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SubscriptionViewPagedSubscriptionQueryCollectionViewModel, any>({
      path: `/api/subscriptions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name RestoreSubscription
   * @request POST:/api/subscriptions/{id}/restore
   * @secure
   */
  restoreSubscription = (id: string, params: RequestParams = {}) =>
    this.http.request<SubscriptionView, any>({
      path: `/api/subscriptions/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name UpdateSubscriptionAttributes
   * @request POST:/api/subscriptions/{id}/attributes
   * @secure
   */
  updateSubscriptionAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<SubscriptionView, any>({
      path: `/api/subscriptions/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name StopSubscriptionLine
   * @request DELETE:/api/subscriptions/{id}/lines/{lineId}
   * @secure
   */
  stopSubscriptionLine = (id: string, lineId: string, data: StopSubscriptionLine, params: RequestParams = {}) =>
    this.http.request<SubscriptionLineView, any>({
      path: `/api/subscriptions/${id}/lines/${lineId}`,
      method: "DELETE",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name ResumeOrUpdateSubscriptionLine
   * @request PUT:/api/subscriptions/{id}/lines/{lineId}
   * @secure
   */
  resumeOrUpdateSubscriptionLine = (
    id: string,
    lineId: string,
    data: ResumeOrUpdateSubscriptionLine,
    params: RequestParams = {},
  ) =>
    this.http.request<SubscriptionLineView, any>({
      path: `/api/subscriptions/${id}/lines/${lineId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name QuerySubscriptionLines
   * @request GET:/api/subscriptions/{id}/lines
   * @secure
   */
  querySubscriptionLines = (
    id: string,
    query?: {
      sortBy?: SubscriptionLineSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SubscriptionLineViewPagedSubscriptionLineQueryCollectionViewModel, any>({
      path: `/api/subscriptions/${id}/lines`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name CreateSubscriptionLine
   * @request POST:/api/subscriptions/{id}/lines
   * @secure
   */
  createSubscriptionLine = (id: string, data: CreateSubscriptionLine, params: RequestParams = {}) =>
    this.http.request<SubscriptionLineView, any>({
      path: `/api/subscriptions/${id}/lines`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name GetProductReport
   * @request GET:/api/subscriptions/productreport
   * @secure
   */
  getProductReport = (
    query?: {
      sortBy?: SubscriptionReportSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      actorId?: string;
      categoryIds?: string[];
      productIds?: string[];
      groupBy?: SubscriptionReportGroupBy;
      /** @format date-time */
      date?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProductReportResultViewPagedSubscriptionReportQueryCollectionViewModel, any>({
      path: `/api/subscriptions/productreport`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name GetResourceTypes
   * @request GET:/api/subscriptions/resourcetypes
   * @secure
   */
  getResourceTypes = (params: RequestParams = {}) =>
    this.http.request<Record<string, ResourceReportType>, any>({
      path: `/api/subscriptions/resourcetypes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name GetSubscriptionAggregatesById
   * @request GET:/aggs/subscriptions/{id}
   * @secure
   */
  getSubscriptionAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/subscriptions/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Subscription
   * @name QuerySubscriptionAggregates
   * @request GET:/aggs/subscriptions
   * @secure
   */
  querySubscriptionAggregates = (
    query?: {
      subscriptionId?: string;
      aggs?: SubscriptionAggregationTypes[];
      actorId?: string;
      types?: SubscriptionType[];
      statuses?: SubscriptionStatusType[];
      hasDifference?: boolean;
      hasProductDifference?: boolean;
      hasResourceDifference?: boolean;
      tagIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SubscriptionAggregationResultSubscriptionAggregateQueryAggregationResults, any>({
      path: `/aggs/subscriptions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionNote
   * @name GetSubscriptionNoteById
   * @request GET:/api/subscriptions/{entityId}/notes/{noteId}
   * @secure
   */
  getSubscriptionNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/subscriptions/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionNote
   * @name UpdateSubscriptionNote
   * @request PUT:/api/subscriptions/{entityId}/notes/{noteId}
   * @secure
   */
  updateSubscriptionNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/subscriptions/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionNote
   * @name DeleteSubscriptionNote
   * @request DELETE:/api/subscriptions/{entityId}/notes/{noteId}
   * @secure
   */
  deleteSubscriptionNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/subscriptions/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionNote
   * @name CreateSubscriptionNote
   * @request POST:/api/subscriptions/{entityId}/notes
   * @secure
   */
  createSubscriptionNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/subscriptions/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionNote
   * @name QuerySubscriptionNotes
   * @request GET:/api/subscriptions/{entityId}/notes
   * @secure
   */
  querySubscriptionNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/subscriptions/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SubscriptionNote
   * @name RestoreSubscriptionNote
   * @request POST:/api/subscriptions/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreSubscriptionNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/subscriptions/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
