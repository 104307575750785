import * as React from "react";
import { CallMissedReason } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";


export class CallMissedReasonSelector extends React.Component<EnumSelectorProps> {
    render = () => {
        return (
            <EnumSelector
                className="call-missedReason-selector"
                enum={CallMissedReason}
                placeholder={this.props.placeholder ? this.props.placeholder : 'Select missed reason...'}
                {...this.props}
            />
        );
    }
}

export default CallMissedReasonSelector;