import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { PagedCallQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { QuickSearch } from "../Shared/QuickSearch";
import { ActorIds } from "../../Definitions/_definitions";


interface CallQuickSearchProps {
    onQuickSearch: (filters) => any;
    query: Partial<PagedCallQuery>;
    hasPreFilters?: boolean;
}


export class CallQuickSearch extends React.Component<CallQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <>
                <QuickSearch name="All" queryParameters={{
                    fromDate: null,
                    toDate: null
                } as Partial<PagedCallQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="This year" queryParameters={{
                    fromDate: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    toDate: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss')
                } as Partial<PagedCallQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="This week" queryParameters={{
                    fromDate: moment().startOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss'),
                    toDate: moment().endOf('isoWeek').format('YYYY-MM-DDTHH:mm:ss')
                } as Partial<PagedCallQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Today" queryParameters={{
                    fromDate: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                    toDate: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
                } as Partial<PagedCallQuery>}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                {this.context.user.actorId == ActorIds.System || this.props.hasPreFilters ? null :
                    <QuickSearch name="My calls" queryParameters={{
                        contactId: this.context.user.actorId
                    } as Partial<PagedCallQuery>}
                        currentQuery={this.props.query}
                        onQueryChange={this.props.onQuickSearch}
                    />
                }
            </>
        );
    }
}

export default CallQuickSearch;