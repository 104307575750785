import * as React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { Popover, Spin, Card, Avatar } from 'antd';
import { CarryOutOutlined } from "@ant-design/icons";
import client from "../../ApiClient/client";
import { RoutineLinkView, RoutineView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorLink } from "../Actors";
import { CategoryLink } from "../Categories";


const { Meta } = Card;


interface RoutineLinkCardProps {
    routineId: string;
}

interface RoutineLinkCardState {
    routine?: RoutineView;
    error?: Error;
}

class RoutineLinkCard extends React.Component<RoutineLinkCardProps, RoutineLinkCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            routine: null,
            error: null
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.loadRoutine();

        this.context.events.routines.onMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentWillUnmount = () => {
        this._isMounted = false;

        this.context.events.routines.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.routineId !== this.props.routineId) {
            this.loadRoutine()
        }
    }

    onItemUpdatedEvent = (eventData: RoutineView) => {
        if (this.state.routine && this.state.routine.id === eventData.id) {
            if (this.state.routine.changeVector === eventData.changeVector) {
                return;
            }
            this.setState({
                routine: eventData
            });
        }
    }

    loadRoutine = async () => {
        try {
            const routine = await client.routines.getRoutineById(this.props.routineId);

            if (routine && this._isMounted)
                this.setState({ routine: routine.data.view });

        } catch (error) {
            //this.setState({ error });
        }
    }

    render = () => {
        if (this.state.error) {
            return this.state.error.message;
        }

        if (!this.state.routine)
            return <Spin size="large" />;

        const avatar = <Avatar shape="square" size={100} icon={<CarryOutOutlined />} className="avatar" />

        return (
            <Card className="routine-link-card" bordered={false} size="small" title={this.state.routine.title}>
                <Meta avatar={avatar} description={
                    <dl>
                        {this.state.routine.owner ? <div className="customer"><dt>Owner</dt>{<ActorLink {...this.state.routine.owner} />}</div> : null}
                        {this.state.routine.category ? <div className="requester"><dt>Category</dt>{<CategoryLink {...this.state.routine.category} />}</div> : null}
                        {this.state.routine.originalNextExecution ? <div className="assignee"><dt>Original execution date</dt>{moment(this.state.routine.originalNextExecution).format('DD.MM.YYYY')}</div> : null}
                        {this.state.routine.description ? <div className="description"><dt>Description</dt>{this.state.routine.description}</div> : null}
                    </dl>
                }
                />
            </Card>
        );

    }
}


interface RoutineLinkProps extends RoutineLinkView {
    disablePopover?: boolean;
    break?: boolean;
}

interface RoutineLinkState {
    id: string;
    title: string;
    deleted: boolean;
    changeVector?: string;
}

export class RoutineLink extends React.Component<RoutineLinkProps, RoutineLinkState>{
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            title: this.props.title,
            deleted: this.props.deleted,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.id !== this.props.id) {
            this.setState({
                id: this.props.id,
                title: this.props.title,
                deleted: this.props.deleted,
                changeVector: null
            });
        }
    }

    componentDidMount = () => {
        this.context.events.routines.onMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentWillUnmount = () => {
        this.context.events.routines.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    onItemUpdatedEvent = (eventData: RoutineView) => {
        if (this.state.id && this.state.id === eventData.id) {
            if (this.state.changeVector === eventData.changeVector) {
                return;
            }

            this.setState({
                title: eventData.title,
                deleted: eventData.deleted,
                changeVector: eventData.changeVector
            });
        }
    }

    render = () => {
        if (!this.context.user.hasCapability(Capabilities.RoutinesReadActor))
            return <Popover content="You do not have access to read routines.">{this.state.title}</Popover>;

        let className = this.props.break ? "" : "no-break";

        if (!this.props.disablePopover)
            className += " list-ellipsis";

        if (this.state.deleted)
            className += " deleted";

        const link = <Link
            style={this.props.disablePopover ? { color: 'unset' } : null}
            to={`/routines/${this.state.id}`}
            className={className}>
            {this.state.title}
        </Link>;

        if (this.props.disablePopover)
            return link;

        return (
            <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
                <Popover placement="bottomLeft" content={<RoutineLinkCard routineId={this.state.id} />}>
                    {link}
                </Popover>
            </span>
        );
    }
}