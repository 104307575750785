/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateRole,
  RoleSortOption,
  RoleView,
  RoleViewEntityViewModel,
  RoleViewPagedRoleQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Roles<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Role
   * @name GetRoleById
   * @request GET:/api/roles/{id}
   * @secure
   */
  getRoleById = (id: string, params: RequestParams = {}) =>
    this.http.request<RoleViewEntityViewModel, any>({
      path: `/api/roles/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name UpdateRole
   * @request PUT:/api/roles/{id}
   * @secure
   */
  updateRole = (id: string, data: CreateOrUpdateRole, params: RequestParams = {}) =>
    this.http.request<RoleView, any>({
      path: `/api/roles/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name DeleteRole
   * @request DELETE:/api/roles/{id}
   * @secure
   */
  deleteRole = (id: string, params: RequestParams = {}) =>
    this.http.request<RoleView, any>({
      path: `/api/roles/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name CreateRole
   * @request POST:/api/roles
   * @secure
   */
  createRole = (data: CreateOrUpdateRole, params: RequestParams = {}) =>
    this.http.request<RoleView, any>({
      path: `/api/roles`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name QueryRoles
   * @request GET:/api/roles
   * @secure
   */
  queryRoles = (
    query?: {
      sortBy?: RoleSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RoleViewPagedRoleQueryCollectionViewModel, any>({
      path: `/api/roles`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name RestoreRole
   * @request POST:/api/roles/{id}/restore
   * @secure
   */
  restoreRole = (id: string, params: RequestParams = {}) =>
    this.http.request<RoleView, any>({
      path: `/api/roles/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name UpdateRoleAttributes
   * @request POST:/api/roles/{id}/attributes
   * @secure
   */
  updateRoleAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<RoleView, any>({
      path: `/api/roles/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
