import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Form, Card, Button } from 'antd';
import {
    ActivityAggregateQuery, ActivityAggregationResult, ActivityAggregationTypes, ActivitySubType, ActivityType, ActivityView,
    HourCategoryType, PagedActivityQuery, PersonSortOption, ProjectType, TaggableType
} from "../ApiClient/swagger/data-contracts";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { ListViewState } from "../Models/ListViewState";
import AppContext from "../Definitions/AppContext";
import { addParamsToQuery, handleQueryParamsOnLoad } from "../Helpers/QueryHashHandlers";
import { handleListDuplicates, openNotification, updateCollectionFromEvent } from "../Helpers/BasePageHelpers";
import client from "../ApiClient/client";
import { addChangeVectorHeader } from "../Helpers/RequestHelpers";
import { Capabilities } from "../Definitions/_capabilties";
import { Keyboard } from "../Definitions/_keyboard";
import ErrorPage from "./ErrorPage";
import { formatDuration, formatEllipsis } from "../Helpers/Formatters";
import { ActorLink, ActorSelector, CustomerSelector } from "../Modules/Actors";
import { ProjectLink, ProjectSelector } from "../Modules/Projects";
import { TaskLink, TaskSelector } from "../Modules/Tasks";
import { CategoryLink } from "../Modules/Categories";
import { ProductLink, ProductSelector } from "../Modules/Products";
import { TagLink, TagSelector } from "../Modules/Tags";
import { ActorIds } from "../Definitions/_definitions";
import { RequireCapability } from "../Modules/Shared/RequireCapability";
import { Drawer } from "../Modules/Shared/Drawer";
import ActivityUpdateForm from "../Modules/Activities/ActivityUpdateForm";
import ListPageHeader from "../Modules/Shared/ListPageHeader";
import ActivityQuickSearch from "../Modules/Activities/ActivityQuickSearch";
import { PersonSelector } from "../Modules/People";
import { HourCategorySelector } from "../Modules/HourCategories";
import DateSelector from "../Modules/Shared/DateSelector";
import DeletedSelector from "../Modules/Shared/DeletedSelector";
import ActivityStatisticsView from "../Modules/Activities/ActivityStatisticsView";
import { ListView } from "../Modules/Shared/ListView";
import Page from "./Page";
import EnumSelector from "../Modules/Shared/EnumSelector";
import StaticSelector from "../Modules/Shared/StaticSelector";
import ActivityMoveForm from "../Modules/Activities/ActivityMoveForm";
import { TicketLink, TicketSelector } from "../Modules/Tickets";
import exportClient from "../ApiClient/Excel";

interface ActivitiesProps {
    filters?: Partial<PagedActivityQuery>;
    replaceFilters?: boolean;
    hideTopCustomers?: boolean;
}

interface ActivitiesState extends ListViewState<ActivityView, PagedActivityQuery> {
    activity: ActivityView;
    created: string[];
    showMoveDrawer: boolean;
    loadingCardAggs: boolean;
    loadingGraphAggs: boolean;
    graphAggregates: ActivityAggregationResult;
}

export default class ActivityListPage extends React.Component<ActivitiesProps, ActivitiesState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            collection: [],
            query: {},
            loading: false,
            exporting: false,
            count: 0,
            showCreateDrawer: false,
            activity: null,
            error: null,
            created: [],
            aggregates: null,
            showMoveDrawer: false,
            loadingCardAggs: false,
            loadingGraphAggs: false,
            graphAggregates: {},
            initialQuery: Object.assign({}, this.props.replaceFilters ? {} :
                {
                    startAfter: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                    endBefore: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
                }, this.props.filters ?? {})
        }

        this.handleKeyDown = _.debounce(this.handleKeyDown, 100);
    }

    componentDidMount = async () => {
        if (this.props.filters) {
            this.loadCollection(Object.assign({}, this.state.initialQuery));
            this.loadCardAggregates(Object.assign({}, this.state.initialQuery));
        }
        else {
            const urlQuery = await handleQueryParamsOnLoad();
            const query = await addParamsToQuery(urlQuery.query, urlQuery.default ? this.state.initialQuery : {}, true);

            this.loadCollection(query);
            this.loadCardAggregates(query);
        }

        document.addEventListener("keydown", this.handleKeyDown);

        this.context.events.activities.onMany({
            //'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
            'confirmed': this.onItemUpdatedEvent,
            'unconfirmed': this.onItemUpdatedEvent,
            'locked': this.onItemUpdatedEvent,
            'unlocked': this.onItemUpdatedEvent
        });
    }

    componentWillUnmount = () => {
        document.removeEventListener("keydown", this.handleKeyDown);

        this.context.events.activities.offMany({
            //'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
            'confirmed': this.onItemUpdatedEvent,
            'unconfirmed': this.onItemUpdatedEvent,
            'locked': this.onItemUpdatedEvent,
            'unlocked': this.onItemUpdatedEvent
        });


    }

    onItemCreatedEvent = async (eventData) => {
        const index = _.indexOf(this.state.created, eventData.id);
        if (index >= 0)
            return;

        openNotification(`New activity created`,
            `Data might be stale, click here to refresh`,
            "success",
            () => {
                this.loadCollection(Object.assign({}, this.state.query, { from: 0 }));
                this.loadGraphAggregates(Object.assign({}, this.state.query));
                this.loadCardAggregates(Object.assign({}, this.state.query));
            });
    }

    onItemUpdatedEvent = async (eventData) => {
        const newCollection = await updateCollectionFromEvent(this.state.collection, eventData);

        if (this.state.activity?.id == eventData.id) {
            this.setState({ activity: eventData });
        }

        this.setState({ collection: newCollection as ActivityView[] });
    }

    onEditClose = (created?: ActivityView) => {
        if (created) {
            const collection = this.state.collection?.slice() ?? [];
            const index = _.findIndex(collection, c => c.id == created.id);
            if (index == -1) {
                collection.push(created);
                this.setState({ collection });
            }
        }

        this.setState({ activity: null, showCreateDrawer: false });
    }

    onMoveClose = () => {
        this.setState({ showMoveDrawer: false });
    }

    loadCollection = async (query?: Partial<PagedActivityQuery>) => {
        this.setState({ loading: true });

        try {
            query.from = 0;
            const response = await client.activities.queryActivities(query);
            if (response)
                this.setState({
                    collection: response.data.items,
                    query: response.data.query,
                    count: response.data.count,
                    //aggregates: response.data.aggregates
                });
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    loadMore = async () => {
        if (this.state.loading || this.state.collection.length >= this.state.count) return;
        this.setState({ loading: true });

        const query = Object.assign({}, this.state.query);
        query.from += query.limit;

        let updatedCollection = [];

        try {
            const response = await client.activities.queryActivities(query);
            if (response) {
                const newCollection = await handleListDuplicates(this.state.collection, response.data.items);
                updatedCollection = newCollection;

                this.setState({
                    collection: newCollection,
                    query: response.data.query,
                    count: response.data.count
                });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
        return updatedCollection;
    }

    loadCardAggregates = async (aggQuery?: PagedActivityQuery) => {
        this.setState({ loadingCardAggs: true });

        var q: ActivityAggregateQuery = aggQuery ? Object.assign({}, aggQuery) : null;

        var q: ActivityAggregateQuery = aggQuery ? Object.assign({}, aggQuery) : null;
        if (!q) {
            const urlQuery = await handleQueryParamsOnLoad();
            q = await addParamsToQuery(urlQuery.query, this.state.initialQuery, true);
        }

        q.aggs = [ActivityAggregationTypes.TotalDuration, ActivityAggregationTypes.BySubType, ActivityAggregationTypes.CategoryTypeDuration];

        const response = await client.activities
            .getActivityAggregates(q)
            .catch(exception => { });

        if (!response) {
            this.setState({ loadingCardAggs: false });
            return;
        }

        this.setState({ aggregates: response.data.results, loadingCardAggs: false });
    }

    loadGraphAggregates = async (aggQuery?: PagedActivityQuery) => {
        this.setState({ loadingGraphAggs: true });

        const q: ActivityAggregateQuery = aggQuery ? Object.assign({}, aggQuery) : Object.assign({}, this.state.query);
        q.aggs = [ActivityAggregationTypes.ActorDuration, ActivityAggregationTypes.CustomerDuration, ActivityAggregationTypes.TypeDuration, ActivityAggregationTypes.CategoryDuration, ActivityAggregationTypes.ProductDuration, ActivityAggregationTypes.ProjectDuration];

        const response = await client.activities
            .getActivityAggregates(q)
            .catch(exception => { });

        if (!response) {
            this.setState({ loadingGraphAggs: false });
            return;
        }

        //var aggregates = Object.assign({}, this.state.aggregates);


        //_.each(response.data.results, (res, key) => {
        //    var currentVal = aggregates[key];
        //    if (currentVal != null && res == null) return;

        //});

        this.setState({ graphAggregates: response.data.results, loadingGraphAggs: false });
    }

    onQueryChange = async (group) => {
        let query = Object.assign({}, this.state.query);
        query = await addParamsToQuery(query, group, true);
        this.loadCollection(query);
        this.loadCardAggregates(query);
        this.loadGraphAggregates(query);
    }

    onDelete = async (ids: string[], changeVector: string) => {
        await _.each(ids, async (id) => {
            await client.activities.deleteActivity(id, addChangeVectorHeader(changeVector));
        });
    }

    onSelect = async (data: ActivityView) => {
        if (this.context.user.hasCapability(Capabilities.ActivitiesWriteActor))
            this.setState({ activity: data, showCreateDrawer: true });
    }

    toggleCreateDrawer = async () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    onExportClick = async () => {
        this.setState({
            exporting: true
        })

        await exportClient.activities
            .excel(this.state.query, "hours")
            .catch(ex => this.setState({ error: "Sorry, there was an issue during export" }));

        this.setState({
            exporting: false
        })
    }

    handleKeyDown = async (e, fromClick?: boolean) => {
        try {
            if (this.state.activity != null && e.keyCode == Keyboard.DOWN_ARROW) {
                var collection = this.state.collection.slice();
                var index = collection.indexOf(this.state.activity);

                if (index == collection.length - 1) {
                    const updatedCollection = await this.loadMore();
                    if (updatedCollection) {
                        this.setState({ activity: updatedCollection[index + 1] });
                    }
                }
                else {
                    this.setState({ activity: collection[index + 1] });
                }
            }
            else if (this.state.activity != null && e.keyCode == Keyboard.UP_ARROW) {
                var collection = this.state.collection.slice();
                var index = collection.indexOf(this.state.activity);

                if (index == 0)
                    this.setState({ activity: null, showCreateDrawer: false });

                else
                    this.setState({ activity: collection[index - 1] });
            }
        }
        catch (error) {

        }
    }

    onRowClassName = (record: ActivityView, index: number) => {
        let classes = "";

        if (record.deleted == true) {
            classes += "deleted"
        }

        if (this.state.activity) {
            if (record.id == this.state.activity.id)
                classes += "element-selected-focus";
        }

        return classes;
    }

    render = () => {
        if (this.state.error) return <ErrorPage status={this.state.error} />

        const listColumns = [
            {
                title: 'Time',
                render: (data: ActivityView) => {
                    let time = data.start ? moment(data.start).format('DD.MM.YYYY HH:mm') : "";

                    if (data.end)
                        time = time + " - " + moment(data.end).format('HH:mm');

                    return (
                        <div>
                            <div>{time}</div>
                            <div>{formatDuration(data.duration)}</div>
                        </div>
                    );
                },
                key: 'start',
                sorter: 'start',
                width: 150
            },
            {
                title: 'Customer',
                render: (data: ActivityView) => data.relation ? <ActorLink break {...data.relation} /> : "",
                key: 'relation',
                sorter: 'relation',
                width: 190,
                responsive: ['md'],
            },
            {
                title: 'Project & task',
                render: (data: ActivityView) => {
                    const project = data.project ? <ProjectLink {...data.project} /> : null;
                    const task = data.task ? <React.Fragment> / <TaskLink break {...data.task} /> </React.Fragment> : null;

                    return <React.Fragment>{project}{task}</React.Fragment>;
                },
                key: 'project',
                sorter: 'project',
                width: 250,
                responsive: ['xxl'],
                ellipsis: true,
            },
            {
                title: 'Description',
                key: 'description',
                render: (data: ActivityView) => formatEllipsis(data.description, 500),
                width: 180,
            },
            {
                title: 'Category & Type',
                render: (data: ActivityView) => {
                    return (
                        <>
                            {data.category ? <div>Category: <CategoryLink {...data.category} /></div> : null}
                            {data.type ? <div>Type of work: {data.type}</div> : null}
                        </>
                    );
                },
                key: 'category',
                sorter: 'category',
                ellipsis: true,
                width: 135,
                responsive: ['xl']
            },
            {
                title: 'Product',
                render: (data: ActivityView) => {
                    if (data.project && data.project.type === ProjectType.Internal)
                        return null;

                    if (data.product)
                        return <ProductLink {...data.product} />;
                    else if (data.type !== ActivityType.Internal && data.category && data.category.product)
                        return <ProductLink {...data.category.product} />;
                    else
                        return "-";
                },
                key: 'product',
                ellipsis: true,
                width: 150,
                responsive: ['xl']
            },
            {
                title: 'Additional info',
                render: (data: ActivityView) => {

                    const ticket = data.ticket ? <TicketLink {...data.ticket} /> : null;

                    const tags = _.map(data.tags ?? [], tag => {
                        return <TagLink key={tag.id} {...tag} />;
                    });

                    const participantsWithActor = _.filter(data.participants ?? [], p => p.contact != null);

                    const participants = _.map(participantsWithActor, (p, i) => {
                        return (
                            <React.Fragment key={p.contact.id}>
                                <ActorLink key={p.contact.id} {...p.contact} />
                                {i == data.participants.length - 1 ? "" : <span >, </span>}
                            </React.Fragment>
                        );
                    });

                    return (
                        <div>
                            {ticket ? <div>Ticket: {ticket}</div> : null}
                            {tags?.length > 0 ? <div className="tags">{tags}</div> : null}

                            {participants?.length > 0 ?
                                <div>
                                    <span>Participants: </span>
                                    {participants}
                                </div>
                                : null}
                        </div>
                    );
                },
                key: 'additional',
                responsive: ['xxl'],
                ellipsis: true,
                width: 150
            },
            {
                title: 'Employee',
                render: (data: ActivityView) => data.actor ? <ActorLink break {...data.actor} /> : "",
                key: 'actor',
                sorter: 'actor',
                width: 100,
                ellipsis: true
            }
        ];

        const actions = this.context.isMobile
            ? null
            : <>
                {this.context.user.actorId == ActorIds.System && this.state.query?.projectId ? <Button onClick={() => { this.setState({ showMoveDrawer: true }); }} key="move" style={{ whiteSpace: 'nowrap' }}>Move hours</Button> : null}
                <RequireCapability capability={Capabilities.ActivitiesConfirmActor} key="confirmactivities" ><Link to="/hours/confirm" key="confirmactivities" style={{ whiteSpace: 'nowrap' }}>Confirm hours <ArrowRightOutlined /></Link></RequireCapability>
                <RequireCapability capability={Capabilities.ActivitiesTransfer} key="activitytransfers" ><Link to="/hours/transfer" key="activitytransfers" style={{ whiteSpace: 'nowrap' }}>Transfer hours <ArrowRightOutlined /></Link></RequireCapability>
            </>;

        const formatAbsence = () => {
            const absenceAmount = _.find(this.state.aggregates["categoryTypeDuration"], x => x.label.toLowerCase() == HourCategoryType.Absence.toLowerCase())?.sum || 0;
            const total = this.state.aggregates["totalDuration"]?.sum ?? 0;
            const percent = (absenceAmount / (total || 1) * 100).toFixed(1);

            return `${formatDuration(absenceAmount)} (${percent}%)`;
        }

        const formatInternalWork = () => {
            const internalAmount = _.find(this.state.aggregates["bySubType"], x => x.label.toLowerCase() == ActivitySubType.InternalWork.toLowerCase())?.sum || 0;
            const hourlyNotInvoiceable = _.find(this.state.aggregates["bySubType"], x => x.label.toLowerCase() == ActivitySubType.HourlyNotInvoicable.toLowerCase())?.sum || 0;
            const notInvoiceableSum = internalAmount + hourlyNotInvoiceable;

            const total = this.state.aggregates["totalDuration"]?.sum ?? 0;
            const percent = (notInvoiceableSum / (total || 1) * 100).toFixed(1);
            return `${formatDuration(notInvoiceableSum)} (${percent}%)`;
        }

        const formatSubType = (type: ActivitySubType) => {
            const amount = _.find(this.state.aggregates["bySubType"], x => x.label.toLowerCase() == type.toLowerCase())?.sum || 0;
            const total = this.state.aggregates["totalDuration"]?.sum ?? 0;

            const percent = (amount / (total || 1) * 100).toFixed(1);
            return `${formatDuration(amount)} (${percent}%)`;
        }

        const pageContent = (
            <>
                <Drawer
                    title={this.state.activity ? "Edit hour" : "Create hour"}
                    onClose={() => this.onEditClose()}
                    open={this.state.showCreateDrawer}
                    destroyOnClose
                    component={
                        <ActivityUpdateForm
                            activity={this.state.activity ?? { start: moment().format(), end: moment().format() } as ActivityView}
                            actorId={this.state.activity?.actor?.id ?? null}
                            onComplete={this.onEditClose}
                            onClose={this.onEditClose}
                            showConfirm
                            disableAutoCloseConfirmUnconfirm
                        />
                    }
                />

                <Drawer
                    title={"Move all found activities"}
                    onClose={this.onMoveClose}
                    open={this.state.showMoveDrawer}
                    destroyOnClose
                    component={
                        <ActivityMoveForm
                            activityQuery={this.state.query}
                            onComplete={this.onMoveClose}
                            onCancel={this.onMoveClose}
                        />
                    }
                />

                <ListPageHeader
                    title="Hours"
                    actions={this.props.filters ? null : actions}
                    onQueryChange={this.onQueryChange}
                    loading={this.state.loading}
                    initialValues={this.state.query}
                    quickSearch={<ActivityQuickSearch onQuickSearch={this.onQueryChange} query={this.state.query} />}
                    initialQuery={this.state.initialQuery}
                >
                    <RequireCapability capability={Capabilities.PeopleRead}>
                        <Form.Item name="actorId" noStyle>
                            <PersonSelector
                                title="Employee"
                                placeholder="Choose employee"
                                filters={{ isEmployee: true }}
                            />
                        </Form.Item>

                        {
                            this.props.filters != null && this.props.filters.relationId != null ? null
                                : <Form.Item name="relationId" noStyle>
                                    <CustomerSelector
                                        title="Customer"
                                        placeholder="Choose customer"
                                    />
                                </Form.Item>
                        }

                        <Form.Item name="participantIds" noStyle>
                            <PersonSelector
                                title="Participants"
                                placeholder="Choose participants"
                                filters={{
                                    sortBy: PersonSortOption.Name
                                }}
                                multiple
                            />
                        </Form.Item>
                    </RequireCapability>

                    <RequireCapability capability={Capabilities.ProjectsRoleBased}>
                        <Form.Item name="projectId" noStyle>
                            <ProjectSelector
                                title="Project"
                                filters={{
                                    customerId: this.state.query.relationId
                                }}
                            />
                        </Form.Item>
                    </RequireCapability>

                    <RequireCapability capability={Capabilities.ProjectsRoleBased}>
                        <Form.Item name="taskId" noStyle>
                            <TaskSelector
                                title="Project task"
                                filters={{
                                    customerId: this.state.query.relationId,
                                    projectId: this.state.query.projectId,
                                    showAll: true
                                }}
                            />
                        </Form.Item>
                    </RequireCapability>

                    <RequireCapability capability={Capabilities.ActivitiesReadTimeTrackingCategories}>
                        <Form.Item name="categoryIds" noStyle>
                            <HourCategorySelector
                                title="Category"
                                multiple
                                filters={{
                                }}
                            />
                        </Form.Item>
                    </RequireCapability>

                    <RequireCapability capability={Capabilities.TicketsReadRelated}>
                        <Form.Item name="ticketId" noStyle>
                            <TicketSelector
                                title="Ticket"
                                filters={{
                                    customerId: this.state.query.relationId
                                }}
                            />
                        </Form.Item>
                    </RequireCapability>

                    <Form.Item name="types" noStyle>
                        <EnumSelector
                            enum={ActivityType}
                            title="Type of work"
                            placeholder="Select activity type..."
                            multiple
                        />
                    </Form.Item>

                    <Form.Item name="subTypes" noStyle>
                        <EnumSelector
                            enum={ActivitySubType}
                            title="Invoice type "
                            multiple
                        />
                    </Form.Item>

                    <RequireCapability capability={Capabilities.ProductsRead}>
                        <Form.Item name="productId" noStyle>
                            <ProductSelector
                                title="Product"
                            />
                        </Form.Item>
                    </RequireCapability>

                    <RequireCapability capability={Capabilities.TagsRead}>
                        <Form.Item name="tagIds" noStyle>
                            <TagSelector
                                title="Tags"
                                filters={{ taggableTypes: [TaggableType.Activity, TaggableType.Organization, TaggableType.Person, TaggableType.Project, TaggableType.Task, TaggableType.Ticket] }}
                            />
                        </Form.Item>
                    </RequireCapability>

                    <Form.Item name="startAfter" noStyle>
                        <DateSelector title="Start" />
                    </Form.Item>

                    <Form.Item name="endBefore" noStyle>
                        <DateSelector title="End" endOfDay />
                    </Form.Item>

                    <Form.Item name="confirmed" noStyle>
                        <StaticSelector
                            title="Show confirmed"
                            placeholder="Select activity confirmation..."
                            options={
                                [
                                    {
                                        name: 'Yes',
                                        value: true
                                    },
                                    {
                                        name: 'No',
                                        value: false
                                    }
                                ]
                            }
                        />
                    </Form.Item>

                    <Form.Item name="running" noStyle>
                        <StaticSelector
                            title="Running"
                            placeholder="Select a value..."
                            options={[
                                {
                                    name: 'Yes',
                                    value: true
                                },
                                {
                                    name: 'No',
                                    value: false
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item name="deleted" noStyle>
                        <DeletedSelector title="Show deleted" />
                    </Form.Item>
                </ListPageHeader>

                {!this.context.isMobile ?
                    <Card className="page-stats-card" loading={this.state.loadingCardAggs}>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Total hours</label>
                            <div className="result">{this.state.aggregates && this.state.aggregates["totalDuration"] ? formatDuration(this.state.aggregates["totalDuration"].sum || 0) : "-"}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Invoiceable - per hour</label>
                            <div className="result">{this.state.aggregates ? formatSubType(ActivitySubType.HourlyInvoiceable) : "-"}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Invoiceable - fixed price</label>
                            <div className="result">{this.state.aggregates ? formatSubType(ActivitySubType.Fixed) : "-"}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Not invoiceable</label>
                            <div className="result">{this.state.aggregates ? formatInternalWork() : "-"}</div>
                        </Card.Grid>
                        <Card.Grid hoverable={false} className="grid-item">
                            <label>Absence</label>
                            <div className="result">{this.state.aggregates ? formatAbsence() : "-"}</div>
                        </Card.Grid>
                    </Card>
                    : null}

                <ActivityStatisticsView
                    aggregates={this.state.graphAggregates}
                    loading={this.state.loadingGraphAggs}
                    onExportClick={this.onExportClick}
                    exporting={this.state.exporting}
                    loadAggregates={this.loadGraphAggregates}
                    hideTopCustomers={this.props.hideTopCustomers}
                />

                <ListView
                    onQueryChange={this.onQueryChange}
                    columns={listColumns}
                    collection={this.state.collection}
                    loading={this.state.loading}
                    onSelect={this.onSelect}
                    onScrollEnd={this.loadMore}
                    sortBy={this.state.query ? this.state.query.sortBy : null}
                    sortDirection={this.state.query ? this.state.query.sortDirection : null}
                    count={this.context.isMobile ? this.state.count : null}
                    rowClassName={this.onRowClassName}
                />
            </>
        );

        if (this.props.filters) {
            return pageContent;
        }

        return (
            <Page className="activity-list-page">
                {pageContent}
            </Page>
        );
    }
}