import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import { AddOrUpdateSupplierOptions, ProductSupplierView, ProductView } from "../../ApiClient/swagger/data-contracts";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { ActorSelector } from "../Actors";


interface CreateProductSupplierProps {
    onComplete: (product: ProductView) => void,
    onCancel?: () => void,
    product: ProductView,
    supplier?: ProductSupplierView
}

interface CreateProductSupplierState {
    loading: boolean,
    error: string
}

export class ProductSupplierForm extends React.Component<CreateProductSupplierProps, CreateProductSupplierState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null
        }
    }

    onSubmit = async (supplierRequest: AddOrUpdateSupplierOptions) => {
        this.setState({ loading: true });

        try {

            if (this.props.supplier) {
                const updateResponse = await client.products.updateProductSupplier(this.props.product.id, this.props.supplier.optionId, supplierRequest);
                if (updateResponse)
                    this.props.onComplete(updateResponse.data);
            }
            else {
                const createResponse = await client.products.addProductSupplier(this.props.product.id, supplierRequest);
                if (createResponse)
                    this.props.onComplete(createResponse.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    render = () => {
        return (
            <BaseForm
                type={this.props.supplier != null ? FormType.Edit : FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={this.props.supplier != null ?
                    {
                        supplierId: this.props.supplier ? this.props.supplier.supplier.id : null,
                    } : null}
            >
                <SelectorInput
                    param="supplierId"
                    required
                    warningMessage="Please choose a supplier"
                    title="Supplier"
                    selector={<ActorSelector />}
                />
            </BaseForm>
        );
    }
}

export default ProductSupplierForm;

