import client from "../../ApiClient/client";
import { PagedActorQuery } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";


interface ActorFilterProps extends GenericEntitySelectorProps<PagedActorQuery> {
    types?: string[];
}

export function ActorSelector(props: ActorFilterProps) {
    const placeholder = props.placeholder ? props.placeholder : 'Select actor...';
    const source = props.source ? props.source : client.actors.queryActors;
    const defaultValueSource = props.defaultValueSource ? props.defaultValueSource : client.actors.getActorById;
    const optionDisplayNameParam = props.optionDisplayNameParam ? props.optionDisplayNameParam : 'name';

    return (
        <EntitySelector
            placeholder={placeholder}
            source={source}
            defaultValueSource={defaultValueSource}
            optionDisplayNameParam={optionDisplayNameParam}
            {...props}
        />
    );
}

export default ActorSelector;