/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdateProjectRole,
  CreateFileOrFolder,
  CreateNote,
  CreateProject,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  PreviewType,
  ProjectAggregationResultProjectAggregateQueryAggregationResults,
  ProjectAggregationTypes,
  ProjectRoleType,
  ProjectSortOption,
  ProjectStatus,
  ProjectType,
  ProjectView,
  ProjectViewEntityViewModel,
  ProjectViewPagedProjectQueryCollectionViewModel,
  RevertFileToVersion,
  SortDirection,
  TaskView,
  UpdateFileOrFolder,
  UpdateNote,
  UpdateProject,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Projects<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Project
   * @name GetProjectById
   * @request GET:/api/projects/{id}
   * @secure
   */
  getProjectById = (id: string, params: RequestParams = {}) =>
    this.http.request<ProjectViewEntityViewModel, any>({
      path: `/api/projects/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name UpdateProject
   * @request PUT:/api/projects/{id}
   * @secure
   */
  updateProject = (id: string, data: UpdateProject, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name DeleteProject
   * @request DELETE:/api/projects/{id}
   * @secure
   */
  deleteProject = (id: string, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name CreateProject
   * @request POST:/api/projects
   * @secure
   */
  createProject = (data: CreateProject, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name QueryProjects
   * @request GET:/api/projects
   * @secure
   */
  queryProjects = (
    query?: {
      sortBy?: ProjectSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      types?: ProjectType[];
      /** @format date-time */
      startPeriod?: string;
      /** @format date-time */
      endPeriod?: string;
      status?: ProjectStatus[];
      isActive?: boolean;
      isEnded?: boolean;
      /** @format int64 */
      estimateMin?: number;
      /** @format int64 */
      estimateMax?: number;
      /** @format int64 */
      priceMin?: number;
      /** @format int64 */
      priceMax?: number;
      tagIds?: string[];
      actorId?: string;
      customerId?: string;
      invoiceRecipientId?: string;
      roleHolderId?: string;
      roleTypes?: ProjectRoleType[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProjectViewPagedProjectQueryCollectionViewModel, any>({
      path: `/api/projects`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name RestoreProject
   * @request POST:/api/projects/{id}/restore
   * @secure
   */
  restoreProject = (id: string, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name UpdateProjectAttributes
   * @request POST:/api/projects/{id}/attributes
   * @secure
   */
  updateProjectAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name AddOrUpdateProjectRole
   * @request POST:/api/projects/{id}/roles
   * @secure
   */
  addOrUpdateProjectRole = (id: string, data: AddOrUpdateProjectRole, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/roles`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name RemoveProjectRole
   * @request DELETE:/api/projects/{id}/roles/{relationId}
   * @secure
   */
  removeProjectRole = (id: string, relationId: string, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/roles/${relationId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name ActivateProject
   * @request POST:/api/projects/{id}/activate
   * @secure
   */
  activateProject = (id: string, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/activate`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name DeactivateProject
   * @request DELETE:/api/projects/{id}/activate
   * @secure
   */
  deactivateProject = (id: string, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/activate`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name EndProject
   * @request POST:/api/projects/{id}/end
   * @secure
   */
  endProject = (id: string, data: string, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/end`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name ResumeProject
   * @request DELETE:/api/projects/{id}/end
   * @secure
   */
  resumeProject = (id: string, params: RequestParams = {}) =>
    this.http.request<ProjectView, any>({
      path: `/api/projects/${id}/end`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name ImportTasksFromProject
   * @request POST:/api/projects/{id}/import/{oldprojectId}
   * @secure
   */
  importTasksFromProject = (id: string, oldprojectId: string, params: RequestParams = {}) =>
    this.http.request<TaskView[], any>({
      path: `/api/projects/${id}/import/${oldprojectId}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name GetProjectAggregates
   * @request GET:/aggs/projects
   * @secure
   */
  getProjectAggregates = (
    query?: {
      projectId?: string;
      aggs?: ProjectAggregationTypes[];
      types?: ProjectType[];
      /** @format date-time */
      startPeriod?: string;
      /** @format date-time */
      endPeriod?: string;
      status?: ProjectStatus[];
      isActive?: boolean;
      isEnded?: boolean;
      /** @format int64 */
      estimateMin?: number;
      /** @format int64 */
      estimateMax?: number;
      /** @format int64 */
      priceMin?: number;
      /** @format int64 */
      priceMax?: number;
      tagIds?: string[];
      actorId?: string;
      customerId?: string;
      invoiceRecipientId?: string;
      roleHolderId?: string;
      roleTypes?: ProjectRoleType[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ProjectAggregationResultProjectAggregateQueryAggregationResults, any>({
      path: `/aggs/projects`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Project
   * @name GetProjectAggregatesById
   * @request GET:/aggs/projects/{id}
   * @secure
   */
  getProjectAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/projects/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name GetProjectFileById
   * @request GET:/api/projects/{entityId}/files/{fileId}
   * @secure
   */
  getProjectFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/projects/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name UpdateProjectFile
   * @request PUT:/api/projects/{entityId}/files/{fileId}
   * @secure
   */
  updateProjectFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/projects/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name DeleteProjectFile
   * @request DELETE:/api/projects/{entityId}/files/{fileId}
   * @secure
   */
  deleteProjectFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/projects/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name DownloadProjectFile
   * @request GET:/api/projects/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadProjectFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/projects/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name PreviewProjectFile
   * @request GET:/api/projects/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewProjectFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/projects/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name CreateProjectFile
   * @request POST:/api/projects/{entityId}/files
   * @secure
   */
  createProjectFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/projects/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name QueryProjectFiles
   * @request GET:/api/projects/{entityId}/files
   * @secure
   */
  queryProjectFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/projects/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name RestoreProjectFile
   * @request POST:/api/projects/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreProjectFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/projects/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name MoveProjectFile
   * @request POST:/api/projects/{entityId}/files/{fileId}/move
   * @secure
   */
  moveProjectFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/projects/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name RevertProjectFile
   * @request POST:/api/projects/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertProjectFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/projects/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name GetProjectFolderById
   * @request GET:/api/projects/{entityId}/folders/{folderId}
   * @secure
   */
  getProjectFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/projects/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name UpdateProjectFolder
   * @request PUT:/api/projects/{entityId}/folders/{folderId}
   * @secure
   */
  updateProjectFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/projects/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name DeleteProjectFolder
   * @request DELETE:/api/projects/{entityId}/folders/{folderId}
   * @secure
   */
  deleteProjectFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/projects/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name CreateProjectFolder
   * @request POST:/api/projects/{entityId}/folders
   * @secure
   */
  createProjectFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/projects/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name QueryProjectFolders
   * @request GET:/api/projects/{entityId}/folders
   * @secure
   */
  queryProjectFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/projects/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name RestoreProjectFolder
   * @request POST:/api/projects/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreProjectFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/projects/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectFile
   * @name MoveProjectFolder
   * @request POST:/api/projects/{entityId}/{folderId}/move
   * @secure
   */
  moveProjectFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/projects/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectNote
   * @name GetProjectNoteById
   * @request GET:/api/projects/{entityId}/notes/{noteId}
   * @secure
   */
  getProjectNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/projects/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectNote
   * @name UpdateProjectNote
   * @request PUT:/api/projects/{entityId}/notes/{noteId}
   * @secure
   */
  updateProjectNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/projects/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectNote
   * @name DeleteProjectNote
   * @request DELETE:/api/projects/{entityId}/notes/{noteId}
   * @secure
   */
  deleteProjectNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/projects/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectNote
   * @name CreateProjectNote
   * @request POST:/api/projects/{entityId}/notes
   * @secure
   */
  createProjectNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/projects/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectNote
   * @name QueryProjectNotes
   * @request GET:/api/projects/{entityId}/notes
   * @secure
   */
  queryProjectNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/projects/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ProjectNote
   * @name RestoreProjectNote
   * @request POST:/api/projects/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreProjectNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/projects/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
