import * as React from "react";
import _ from 'lodash';
import { Table, Button, Typography } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { TableRowSelection } from "antd/lib/table/interface";
import AppContext from "../../Definitions/AppContext";
import { WidthComponentSize } from "../../Hooks/ComponentSizeHook";
import { SortDirection } from "../../ApiClient/swagger/data-contracts";

const { Title } = Typography;


interface ListViewCardProps {
    title?: string | React.ReactNode;
    subtableTitle?: () => React.ReactNode;
    subtitle?: string | JSX.Element;
    actions?: React.ReactNode;
    onAdd?: () => void;
    addButtonText?: string;
    columns: any;
    data: any[];
    loadMore?: () => void;
    className?: string;
    loading?: boolean;
    onSelect?: (data: any) => void;
    onExpand?: (expanded: any, record: any) => any;
    rowSelection?: TableRowSelection<any>;
    footer?: any;
    paging?: boolean;
    nestedSubTables?: JSX.Element[];
    isSubTable?: boolean;
    takeElements?: number;
    childrenParam?: string;
    customExpandable?: (record: any) => boolean;
    emptyText?: React.ReactNode;
    loadAllOnLoadMore?: boolean;
    onQueryChange?: (values: any) => any;
    rowClassName?: (record, index) => string;
    showDeleted?: boolean;
    tableLayout?: "auto" | "fixed";
}

interface ListViewCardState {
    takeElements: number
}

export class ListViewCard extends React.Component<ListViewCardProps, ListViewCardState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            takeElements: this.props.takeElements ?? 5
        }
    }


    loadMore = () => {
        if (this.props.loadMore) {
            this.props.loadMore();
            var shownElements = this.state.takeElements;
            shownElements += this.props.takeElements ?? 40;
            this.setState({ takeElements: shownElements });
        }
        else {
            if (this.props.loadAllOnLoadMore) {
                this.setState({ takeElements: 99999 });
            }
            else {
                var shownElements = this.state.takeElements;
                shownElements += this.props.takeElements ?? 5;
                this.setState({ takeElements: shownElements });
            }
        }
    }

    expandedSubTable = (row) => {
        return _.find(this.props.nestedSubTables, c => { return c.key == row.key; });
    }

    onListChange = (pagination, filters, sorter, extra) => {
        if (this.props.onQueryChange) {
            if (sorter && Object.keys(sorter).length > 0 && sorter.order) {
                let sortDirection = SortDirection.Asc;
                if (sorter.order == "descend")
                    sortDirection = SortDirection.Desc;

                const sortBy = sorter.column && typeof sorter.column.sorter == 'string'
                    ? sorter.column.sorter
                    : sorter.columnKey;

                this.props.onQueryChange({ sortBy, sortDirection });
            }
            else {
                this.props.onQueryChange({ sortBy: null, sortDirection: null });
            }
        }
    }

    render = () => {
        const notDeletedElements = this.props.showDeleted ? this.props.data : _.reject(this.props.data, d => {
            if (d)
                return d.deleted;
            else
                return d == null;
        });
        const shownElements = _.take(notDeletedElements, this.props.paging !== false ? this.state.takeElements : notDeletedElements.length);
        const dataSource = _.map(shownElements, (item, key) => {
            //@ts-ignore
            if (item.id) item.key = item.id;
            else item.key = key;
            return item;
        });

        const columns = _.reject(this.props.columns, column => { return column.hide; });

        const scroll = 100 * columns.length;

        const tableAction = this.props.actions ? this.props.actions : this.props.onAdd ? <Button size="small" className="action" onClick={this.props.onAdd} icon={this.context.isMobile ? <PlusCircleOutlined /> : null}>{this.context.isMobile ? null : this.props.addButtonText ? this.props.addButtonText : "Add"}</Button> : null;

        return (
            <WidthComponentSize render={(ref, width) =>
                <div ref={ref} data-width={width} className={`list-view ${this.props.isSubTable ? '' : 'list-view-card'} ${this.context.isMobile ? 'list-view-mobile' : 'list-view-desktop'} ${this.props.className ? this.props.className : ''}`}>
                    <WidthComponentSize render={(ref2, width2) =>

                        <Table
                            title={this.props.subtableTitle ? this.props.subtableTitle : () => this.props.title || tableAction ?
                                <>
                                    <div className="list-title">
                                        <Title level={4} className="title">{this.props.title}</Title>
                                        {tableAction}
                                    </div>
                                    {this.props.subtitle ? <div className="subtitle">{this.props.subtitle}</div> : null}
                                </> : null
                            }

                            data-width={width2}
                            onChange={this.onListChange}
                            bordered
                            dataSource={dataSource}
                            columns={columns}
                            size={"small"}
                            loading={this.props.loading}
                            scroll={{ x: this.context.isMobile && scroll > width ? true : null }}
                            pagination={false}
                            footer={this.props.footer ?? (this.props.loadMore || (dataSource != null && dataSource.length < notDeletedElements.length) ? () =>
                                <div className="table-footer">
                                    <Button onClick={this.loadMore}>Load more</Button>
                                </div> : null)
                            }
                            onRow={(data, rowIndex) => {
                                return {
                                    onClick: event => {
                                        event.stopPropagation();
                                        if (this.props.onSelect)
                                            this.props.onSelect(data);
                                    }
                                };
                            }}
                            className="list-table list-table-card"
                            rowSelection={this.props.rowSelection ? this.props.rowSelection : null}
                            rowClassName={(record, index) => this.props.rowClassName ? this.props.rowClassName(record, index) : `list-row ${record && record.deleted && !this.props.showDeleted ? 'deleted' : ''}`}
                            onExpand={this.props.onExpand}
                            expandable={{
                                expandedRowRender: this.props.nestedSubTables ? this.expandedSubTable : null,
                                rowExpandable: record => this.props.customExpandable ? this.props.customExpandable(record) : this.props.nestedSubTables && this.props.childrenParam ? record[this.props.childrenParam] && record[this.props.childrenParam].length > 0 : true,
                            }}
                            tableLayout={this.props.tableLayout ?? this.context.isMobile ? "auto" : "fixed"}
                            locale={{
                                emptyText: this.props.emptyText
                            }}
                        />
                    } />
                </div>
            } />
        );
    }
}

export default ListViewCard;