import { ReactElement } from "react";

export interface CustomFormLabelProps {
    optional?: boolean;
    label: string;
    action?: ReactElement;
}

export function CustomFormLabel({ optional, label, action }: CustomFormLabelProps) {
    return (
        <div className={`ant-form-item-label custom-form-label`}>
            <label style={{ fontWeight: 500 }}>{label}{optional ? " (optional)" : null} {action}</label>
        </div>
    );
}