import * as React from "react";
import _ from 'lodash';
import { Button } from "antd";
import { RequireCapability } from "./RequireCapability";
import { Drawer } from "./Drawer";
import { Capabilities } from "../../Definitions/_capabilties";


interface AttributesViewProps {
    attributes: any,
    className?: string
}

interface AttributesViewState {
    showDrawer: boolean;
}

export class AttributesView extends React.Component<AttributesViewProps, AttributesViewState> {
    constructor(props: any) {
        super(props);

        this.state = {
            showDrawer: false
        }
    }

    toggleDrawer = () => {
        this.setState({ showDrawer: !this.state.showDrawer });
    }

    render() {
        //if (!this.props.attributes)
        //    return null;

        const attributesView = _.map(this.props.attributes, (attribute, key) => {
            return <div key={key}><dt>{key}</dt><dd>{attribute}</dd></div>
        });

        return (
            <RequireCapability capability={Capabilities.OtherAttributes}>
                <div className="attributes-view" >
                    <Button type="link" onClick={this.toggleDrawer}>
                        <span>View attributes</span>
                    </Button>

                    <Drawer
                        title="Attributes"
                        onClose={this.toggleDrawer}
                        open={this.state.showDrawer}
                        destroyOnClose
                        component={<div>{attributesView}</div>}
                    />
                </div>
            </RequireCapability>
        );
    }
}