import * as React from "react";
import _ from 'lodash';
import { Button, Tooltip } from "antd";
import { ProjectRoleType, ProjectRoleView, ProjectView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { ProjectRoleDescription } from "./ProjectRoleTypeSelector";
import { Capabilities } from "../../Definitions/_capabilties";
import { Drawer } from "../Shared/Drawer";
import ListViewCard from "../Shared/ListViewCard";
import ProjectRoleCreateOrEditForm from "./ProjectRoleCreateOrEditForm";
import { PersonLink } from "../People";
import { ActorLink } from "../Actors";



interface ProjectRoleListProps {
    project: ProjectView;
    className?: string;
    onEditComplete: (project: ProjectView) => void;
}

interface ProjectRoleListState {
    showCreateDrawer: boolean,
    projectRole: ProjectRoleView,
    error: string,
    loadingDelete: boolean,
    takeElements: number
}

export class ProjectRoleList extends React.Component<ProjectRoleListProps, ProjectRoleListState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showCreateDrawer: false,
            projectRole: null,
            error: null,
            loadingDelete: false,
            takeElements: 5
        }
    }

    toggleCreateDrawer = (project?: ProjectView) => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
        if (project) this.props.onEditComplete(project);
    }

    onEdit = async (projectRole) => {
        this.setState({ projectRole });
    }

    onEditDrawerClose = (project?: ProjectView) => {
        this.setState({ projectRole: null });
        if (project) this.props.onEditComplete(project);
    }

    render = () => {
        const listColumns = [
            {
                title: 'Person',
                render: (data: ProjectRoleView) => <ActorLink {...data.actor} />,
                key: 'actor'
            },
            {
                title: 'Role',
                render: (data: ProjectRoleView) => {

                    const tooltupText = ProjectRoleDescription(data.role);
                    return (
                        <Tooltip title={tooltupText}>
                            <div>{data.role}</div>
                        </Tooltip>
                    );
                },
                key: 'role',
                width: 100
            }
        ];

        const { user } = this.context;
        const userRole = _.find(this.props.project?.roles ?? [], r => r.actor.id == user.actorId);
        const hasFullProjectAccess = user.hasAnyCapability([Capabilities.ProjectsWrite]) || (user.hasCapability(Capabilities.ProjectsRoleBased) && (userRole?.role == ProjectRoleType.Leader || userRole?.role == ProjectRoleType.Manager))

        if (hasFullProjectAccess) {
            listColumns.push(
                //@ts-ignore
                {
                    title: '',
                    render: (data: ProjectRoleView) =>
                        <Button
                            className="edit-btn"
                            //shape="circle"
                            type="link"
                            style={{ margin: 0, padding: 0 }}
                            //icon={<EditOutlined />}
                            onClick={() => this.onEdit(data)}
                        >Details</Button>,
                    key: 'actions',
                    //@ts-ignore
                    width: 60
                }
            );
        }

        return (
            <>
                <Drawer
                    title="Add member"
                    onClose={() => this.toggleCreateDrawer()}
                    open={this.state.showCreateDrawer}
                    destroyOnClose
                    component={
                        <ProjectRoleCreateOrEditForm
                            project={this.props.project}
                            onComplete={this.toggleCreateDrawer}
                            onCancel={() => this.toggleCreateDrawer()}
                        />
                    }
                />
                <Drawer
                    title="Update member"
                    onClose={() => this.onEditDrawerClose()}
                    open={this.state.projectRole != null}
                    destroyOnClose
                    component={
                        <ProjectRoleCreateOrEditForm
                            project={this.props.project}
                            projectRole={this.state.projectRole}
                            onComplete={this.onEditDrawerClose}
                            onCancel={this.onEditDrawerClose}
                        />
                    }
                />

                <ListViewCard
                    title="Project team"
                    onAdd={hasFullProjectAccess ? () => this.toggleCreateDrawer() : null}
                    addButtonText="Add member"
                    columns={listColumns}
                    data={this.props.project?.roles ?? []}
                    className={`project-role-list ${this.props.className ? this.props.className : ''}`}
                />
            </>
        );
    }
}

export default ProjectRoleList;