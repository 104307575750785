import _ from 'lodash';
import moment from 'moment';
import { ArrowLeftOutlined, BellOutlined, CloseOutlined, DownOutlined, ExclamationCircleOutlined, NotificationOutlined, SettingOutlined, UpOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Checkbox, Dropdown, Form } from "antd";
import { useContext, useEffect, useState } from "react";
import { CreateNotificationWebRequest, NotificationQuery, NotificationStatus, NotificationView, UpdateNotifications } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { ActorIds } from "../../Definitions/_definitions";
import client from "../../ApiClient/client";
import { AiOutlineAim, AiOutlineAlert, AiOutlineBank, AiOutlineCarryOut, AiOutlineContacts, AiOutlineDeliveredProcedure, AiOutlineGold, AiOutlineGroup, AiOutlineIssuesClose, AiOutlineMessage, AiOutlinePhone, AiOutlineProject, AiOutlineRead, AiOutlineTable, AiOutlineTool } from 'react-icons/ai';
import { PersonLink } from '../../Modules/People';
import BaseForm, { FormType } from '../../Modules/Shared/Form';
import { openNotification } from '../../Helpers/BasePageHelpers';
import { RoleSelector } from '../../Modules/Roles';
import { RequireCapability } from '../../Modules/Shared/RequireCapability';
import { Capabilities } from '../../Definitions/_capabilties';
import { TextAreaInput } from "../../Modules/Shared/TextAreaInput";
import { NotificationSettingsGroup } from '../../ApiClient/swagger/data-contracts';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { EmployeeSelector } from '../../Modules/Employees';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActivityEditDrawer from '../../Modules/Activities/ActivityEditDrawer';


enum NotificationViewMode {
    Overview = "Overview",
    Settings = "Settings",
    Create = "Create",
}

interface NotificationTypeDetails {
    icon: JSX.Element;
    route?: string;
    subRoute?: string;
}

export default function NotificationDropDown() {

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSettings, setLoadingSettings] = useState(false);
    const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
    const [selectAllEmployees, setSelectAllEmployees] = useState(false);
    const [error, setError] = useState<string>();
    const [viewMode, setViewMode] = useState(NotificationViewMode.Overview);

    const [notifications, setNotifications] = useState<NotificationView[]>([]);
    const [settings, setSettings] = useState<NotificationSettingsGroup[]>();
    const [selectedSettings, setSelectedSettings] = useState<string[]>([]);
    const [count, setCount] = useState<number>(0);

    const [unreadCount, setUnreadCount] = useState<number>(0);
    const [faviconHref, setFaviconHref] = useState<string>();
    const [notificationFavicon, setNotificationFavicon] = useState<string>();
    const [query, setQuery] = useState<NotificationQuery>({
        deleted: false,
        from: 0,
        limit: 10
    });

    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!context.user)
            return;

        if (context.user.actorId != ActorIds.System) {
            loadNotifications();
            loadSettings();
        }
    }, [context.user])

    useEffect(() => {
        createFavicon();
    }, [])

    useEffect(() => {
        if (!context.events)
            return;

        context.events.notifications.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent
        });

        return () => {
            context.events.notifications.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent
            });
        }
    }, [context.events, notifications])

    function createFavicon(){
        // Save original favicon
        const favicon = document.getElementById('favicon') as HTMLLinkElement;
        setFaviconHref(favicon.href);

        // Create new favicon using the existing favicon but add red dot
        const img = document.createElement('img')
        img.src = favicon.href;
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const context = canvas.getContext('2d');
            context.drawImage(img, 0, 0, img.width, img.height);
            context.beginPath();
            context.arc(img.width - img.width / 5, img.height / 5,
                img.width / 5, 0, 2 * Math.PI);
            context.fillStyle = '#e6f7ff';
            context.fill();
            setNotificationFavicon(canvas.toDataURL('image/png'));
        }
    }
    function updateFavicon(notificationsToUse?: NotificationView[]) {
        const notificationViews = notificationsToUse ? notificationsToUse : notifications;
        const unread = _.filter(notificationViews, (n) => { return n.status != NotificationStatus.Read });
        if (unread.length != 0) {
            (document.getElementById('favicon') as HTMLLinkElement).href = notificationFavicon
        }
        else {
            (document.getElementById('favicon') as HTMLLinkElement).href = faviconHref;
        }
    }
    function onItemCreatedEvent(notification: NotificationView) {
        if (notification.toUserId == null || notification.toUserId == context.user.actorId) {
            const copy = _.map(notifications, (x) => { return x });
            const index = _.findIndex(copy, n => { return n.id == notification.id });
            if (index < 0) {
                copy.unshift(notification);
                setNotifications(copy);
                updateFavicon();
                
                setUnreadCount(unreadCount+1);
            }
        }
    }
    function onItemUpdatedEvent(notification: NotificationView) {
        if (notification.toUserId == context.user.actorId) {
            const copy = _.map(notifications, (x) => { return x });
            const index = _.findIndex(copy, n => { return n.id == notification.id });
            if (index >= 0) {
                copy[index] = notification;
                setNotifications(copy);
                updateFavicon(copy);
            } 
        }
    }

    async function onMarkMany() {
        const res = await client.notifications.updateStatusMany({ status: NotificationStatus.Read });
        const copy = _.map(notifications, (x) => { return x });
        _.each(res.data, view => {
            const index = _.findIndex(copy, n => { return n.id == view.id });
            if (index >= 0) {
                copy[index] = view;
            }
        });
        setNotifications(copy);
        updateFavicon(copy);
        setUnreadCount(0);
    }

    async function loadNotifications() {
        setLoading(true);
        const response = await client.notifications
            .queryNotifications(Object.assign({}, query, { userId: context.user.actorId}))
            .catch(exception => setError(exception.error));

        if (response && response.data) {
            setNotifications(response.data.items);
            setQuery(response.data.query);
            setCount(response.data.counter.count);
            setUnreadCount(response.data.counter.unread);
            updateFavicon(response.data.items);
        }

        setLoading(false);
    }
    async function loadMoreNotifications() {
        setLoading(true);
        const q = Object.assign({}, query, { userId: context.user.actorId });
        q.from = query.from + query.limit;
        const response = await client.notifications
            .queryNotifications(q)
            .catch(exception => setError(exception.error));

        if (response && response.data) {
            const copy = _.map(notifications, x => { return x; });
            response.data.items.forEach(x => copy.push(x));
            setNotifications(copy);
            setQuery(response.data.query);
            setCount(response.data.counter.count);
            updateFavicon(copy);
        }

        setLoading(false);
    }
    async function loadSettings() {
        setLoadingSettings(true);

        const response = await client.notificationSettingss.getUsersSettings(context.user.actorId);
        if (response) {
            const selected = [];
            response.data.forEach(x => {
                if (IsGroupSelected(x.groupName, response.data))
                    selected.push(x.groupName)
                else
                    x.settings.forEach(y => { if (y.selected) { selected.push(y.id) } }
                    )
            });
            setSelectedSettings(selected);
            setSettings(response.data);
        }
        setLoadingSettings(false);
    }
    async function onSubmitSettings() {
        const selected = [];
        const groupNames = _.map(settings, (g) => { return g.groupName });
        _.each(selectedSettings, sett => {
            if (groupNames.includes(sett)) {
                const grp = _.filter(settings, (group) => { return group.groupName == sett; })[0];
                _.each(grp.settings, g => {
                    if (!selected.includes(g.id))
                        selected.push(g.id);
                });
            }
            else if (!selected.includes(sett))
                selected.push(sett);
        });
        const data: UpdateNotifications = { userId: context.user.actorId, updated: selected};
        await client.notificationSettingss
            .updateUsersNotificationSettings(context.user.actorId, data);
        openNotification("Updated settings", "Successfully updated notification settings", "success", null, "notification-update-settings", true, "notification-settings-update-notification");
        await loadSettings();
        setViewMode(NotificationViewMode.Overview);
    }
    function IsGroupSelected(groupName: string, responseSettings?: NotificationSettingsGroup[]) {
        const settingsToUse = responseSettings ? responseSettings : settings;
        const group = _.filter(settingsToUse, s => { return s.groupName == groupName })[0];
        return _.every(group.settings, s => { return s.selected })
    }
    function updateSelected(ev: CheckboxChangeEvent) {
        const target = ev.target;
        let copy = _.map(selectedSettings, (x) => { return x });
        if (target.checked && !copy.includes(target.value)) {
            copy.push(target.value);
        }
        else if (!target.checked && copy.includes(target.value)) {
            copy = copy.filter(e => e !== target.value);
        }
        setSelectedSettings(copy);
    }
    function updateSelectAll(ev: CheckboxChangeEvent) {
        const target = ev.target;
        setSelectAllEmployees(target.checked);
    }
    function getDetails(type: string): NotificationTypeDetails {
        switch (type) {
            case ("newsinternal:created:related"):
                return { icon: <AiOutlineRead />, route: "/news?newsId=" };
            case ("message:created:related"):
                return { icon: <AiOutlineMessage />, route: null };
            case ("sensoralert:created:related"):
                return { icon: <AiOutlineAlert />, route: "/resources/" };
            case ("news:comment:created"):
                return { icon: <AiOutlineRead />, route: "/news?newsId=" };
            case ("organization:notes:created:related"):
                return { icon: <AiOutlineBank />, route: "/organizations/" };
            case ("people:notes:created:related"):
                return { icon: <AiOutlineContacts />, route: "/people/" };
            case ("project:notes:created:related"):
                return { icon: <AiOutlineProject />, route: "/projects/" };
            case ("project:task:comment:created:related"):
                return { icon: <AiOutlineProject />, route: "/tasks/" };
            case ("ticket:comment:created:related"):
                return { icon: <AiOutlineGroup />, route: "/tickets/" };
            case ("call: notes: created:related"):
                return { icon: <AiOutlinePhone />, route: "/calls/" };
            case ("resource:notes:created:related"):
                return { icon: <AiOutlineGold />, route: "/resources/" };
            case ("measuringpoint:notes:created:related"):
                return { icon: <AiOutlineGold />, route: "/resources/", subRoute:"?subRoute=MeasuringPoints" };
            case ("incident:comments:created:related"):
                return { icon: <AiOutlineIssuesClose />, route: "/incidents/" };
            case ("routine:comments:created:related"):
                return { icon: <AiOutlineCarryOut />, route: "/routines/" };
            case ("order:notes:created:related"):
                return { icon: <AiOutlineDeliveredProcedure />, route: "/orders/" };
            case ("product:notes:created:related"):
                return { icon: <AiOutlineTool />, route: "/products/" };
            case ("subscription:notes:created:related"):
                return { icon: <AiOutlineTable />, route: "/subscriptions/" };
            case ("leads:notes:created:related"):
                return { icon: <AiOutlineAim />, route: "/leads/" };
            case ("comments:created:related"):
                return { icon: <AiOutlineRead />, route: "" };
            case ("ticket:participant:assigned"):
                return { icon: <AiOutlineGroup />, route: "/tickets/" };
            case ("routine:owner:assigned"):
                return { icon: <AiOutlineCarryOut />, route: "/routines/" };
            case ("routine:responsible:assigned"):
                return { icon: <AiOutlineCarryOut />, route: "/routines/" };
            case ("incident:owner:assigned"):
                return { icon: <AiOutlineIssuesClose />, route: "/incidents/" };
            case ("incident:participant:assigned"):
                return { icon: <AiOutlineIssuesClose />, route: "/incidents/" };
            case ("project:participant:assigned"):
                return { icon: <AiOutlineProject />, route: "/projects/" };
            case ("project:task:owner:assigned"):
                return { icon: <AiOutlineProject />, route: "/tasks/" };
            case ("project:task:participant:assigned"):
                return { icon: <AiOutlineProject />, route: "/tasks/" };
            case ("lead:owner:assigned"):
                return { icon: <AiOutlineAim />, route: "/leads/" };
            case ("ticket:duedate"):
                return { icon: <AiOutlineGroup />, route: "/tickets/" };
            case ("routine:executiondate"):
                return { icon: <AiOutlineCarryOut />, route: "/routines/" };
            case ("incident:expectedclosingdate"):
                return { icon: <AiOutlineIssuesClose />, route: "/incidents/" };
            case ("project:plannedend"):
                return { icon: <AiOutlineProject />, route: "/projects/" };
            case ("project:task:plannedend"):
                return { icon: <AiOutlineProject />, route: "/tasks/" };
            case ("lead:nextactiondate"):
                return { icon: <AiOutlineAim />, route: "/leads/" };
            case ("absence:declined"):
                return { icon: <ExclamationCircleOutlined />, route: "?activity=" };
        }
        return;
    }

    async function onCreateNotification(request: CreateNotificationWebRequest) {
        request.selectAllEmployees = selectAllEmployees;
        const result = await client.notifications.createAdminNotification(request).catch(exception => setError(exception.error));

        if (result) {
            openNotification("Sent notification", "Successfully created notification", "success", null, "notification-create", true, "notification-create-notification");
            setViewMode(NotificationViewMode.Overview);
        }
    }
    function resetViewModeAndClose() {
        setVisible(false);
        setViewMode(NotificationViewMode.Overview);
    }
    async function onNavigate(changeStatus: boolean, route: string, notificationId?: string, changeVector?: string, entityId?: string, subroute?: string) {
        if (changeStatus) {

            const res = await client.notifications.updateStatus(notificationId, { status: NotificationStatus.Read }, { headers: { "Change-Vector": changeVector } });
            onItemUpdatedEvent(res.data);
        }
        if (route != null) {
            resetViewModeAndClose();

            //
            let url = `${route}`;
            if (entityId)
                url += `${entityId}`;
            if (subroute)
                url += subroute;
                            
            navigate(url);
            //window.location.href = url;
        }
    }
    function renderNotification(notification: NotificationView) {
        const status = notification.status == NotificationStatus.Read ? "notification-read" : "notification-unread";
        const details = getDetails(notification.type);
       
        return (
            <Card className={`notification-card ${status}`} key={notification.id} onClick={() => onNavigate(notification.status != NotificationStatus.Read, details.route, notification.id, notification.changeVector, notification.entity?.id, details.subRoute)}>
                <div className="notification-header">{details?.icon} {notification.header}</div>
                <div className="notification-content">{notification.content}</div>
                <div className="notification-timestamp">{notification.createdBy != null ? <PersonLink id={notification.createdBy.id} disablePopover disableRedirect spanReturn /> : notification.createdBy?.id}{ notification.createdBy ? ",":null} {moment(notification.created).fromNow()}</div>
            </Card>);
    }

    function renderNotificationList() {
        const notificationViews = _.map(notifications, n => { return renderNotification(n); });
        if (notificationViews.length == 0)
            notificationViews.push(<span key="no-notifications-key">No notifications ...</span>)

        return <Card className="dropdown-card notification-dropdown-card" key="notification-list" size="small">
            <div className="notification-dropdown-links">
                <div className="notification-title">Notifications</div>
                <div className="notification-actions">
                    <RequireCapability capability={Capabilities.AdminNotificationCreate}><Button icon={<NotificationOutlined />} onClick={() => setViewMode(NotificationViewMode.Create)}></Button></RequireCapability>
                    <Button icon={<SettingOutlined onClick={() => setViewMode(NotificationViewMode.Settings)} />}></Button>
                    <Button icon={<CloseOutlined className="close-icon" />} onClick={() => resetViewModeAndClose()} />
                </div>
            </div>
            {_.countBy(notifications, (c) => { return c.status != NotificationStatus.Read })['true'] > 0 ? <div className="mark-all"><Button type="link" onClick={() => { onMarkMany() }}>Mark all as read</Button></div> : null}
            {notificationViews}
            {count > notifications.length ? <div className="load-more-footer"><Button className="load-more-button" onClick={() => loadMoreNotifications()}>Load more</Button></div> : null}
        </Card>;
    }
    function renderNotificationSettings() {
        const allSettings = _.flatten(_.map(settings, s => { return s.settings; }));
        const newsSetting = _.filter(allSettings, s => { return s.id == "InternalNews"; })[0];
        return <Card className="dropdown-card notification-dropdown-card" key="settings-list" size="small">
            <BaseForm
                type={FormType.Edit}
                onSubmit={onSubmitSettings}
                onCancel={() => setViewMode(NotificationViewMode.Overview)}
                onClose={() => resetViewModeAndClose()}
                loading={loadingSettings}
                error={error}
            >
                <div>
                    <div className="notification-settings-header">General options</div>
                    <div className="notification-settings-wrapper">
                        <Checkbox value="InternalNews" defaultChecked={newsSetting?.selected} onChange={(ev) => { updateSelected(ev); }}>
                            Internal news
                        </Checkbox>
                    </div>
                    <div className="notification-settings-wrapper">
                        <Checkbox value="Notes and comments" defaultChecked={IsGroupSelected("Notes and comments")} onChange={(ev) => { updateSelected(ev); }}>
                            Notes and comments related to you
                        </Checkbox>
                    </div>
                    <div className="notification-settings-wrapper">
                        <Checkbox value="Owner, assignes or responsible" defaultChecked={IsGroupSelected("Owner, assignes or responsible")} onChange={(ev) => { updateSelected(ev); }}>
                            If you are set as owner, assignee or responsible
                        </Checkbox>
                    </div>
                    <div className="notification-settings-wrapper">
                        <Checkbox value="Important dates" defaultChecked={IsGroupSelected("Important dates")} onChange={(ev) => { updateSelected(ev); }}>
                            Important dates related to you
                        </Checkbox>
                    </div>
                    <div className="notification-settings-wrapper">
                        <Checkbox disabled>
                            Sensor alerts
                        </Checkbox>
                    </div>
                    <div className="sensor-alert-tooltip">You will recieve notifications if you are listed as notification reciever on any resource. This is controlled from the spesific resource page.</div>
                    <div className="advanced-options" onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}>Advanced options <span className="toggle-icon">{isAdvancedOpen ? <UpOutlined /> : <DownOutlined />}</span></div>
                    {isAdvancedOpen ? _.map(settings, s => {
                        if (!s.groupName || s.groupName.length == 0) return null;
                        return <>
                            <div className="notification-settings-header">{s.groupName}</div>
                            {_.map(s.settings, sett => {
                                return <div className="notification-settings-wrapper-under">
                                    <RequireCapability capability={sett.requiredCapability as Capabilities}>
                                    <Checkbox value={sett.id} defaultChecked={selectedSettings.includes(sett.id)} indeterminate={selectedSettings.includes(s.groupName) && !selectedSettings.includes(sett.id)} onChange={(ev) => { updateSelected(ev); }}>
                                        {sett.uiDescription}
                                        </Checkbox>
                                    </RequireCapability>
                                </div>
                            })}
                        </>
                    }) : null}
                </div>
            </BaseForm>
        </Card>;
    }
    function renderCreateNotification() {
        return <Card className="dropdown-card notification-dropdown-card" key="create-notifications" size="small">
            <div className="create-notification-header"><Button type="link" icon={<ArrowLeftOutlined />} onClick={() => { setViewMode(NotificationViewMode.Overview)} } >Back to notification list</Button></div>
            <BaseForm
                type={FormType.Create}
                onSubmit={onCreateNotification}
                onClear={() => setSelectAllEmployees(false) }
                loading={loading}
                error={error}
                onClose={() => resetViewModeAndClose()}
                className="create-notification-form"
            >
                <Form.Item name="roleIds" label="Who should be notified?" className="role-selector">
                    <RoleSelector
                        placeholder="Select roles"
                        multiple
                        disabled={selectAllEmployees}
                        renderInfo={() => { return <span className="selector-suffix-label">and/or</span>; }}
                        
                    />
                </Form.Item>
                <Form.Item name="personIds" className="person-selector">
                    <EmployeeSelector
                        placeholder="Select employees"
                        multiple
                        disabled={selectAllEmployees}
                        renderInfo={() => {
                            return (<Checkbox value="allEmployees" className="selector-suffix-label" onChange={(ev) => { updateSelectAll(ev); }}>
                                Select all
                            </Checkbox>);
                        }}
                    />
                </Form.Item>
                <TextAreaInput
                    title="Notification"
                    shouldUpdate
                    param="message"
                    required={true}
                    warningMessage="Notification"
                    placeholder="Write here"
                    rows={5}
                />

            </BaseForm></Card>;
    }

    if (context.user == null) return null;

    const content = viewMode == NotificationViewMode.Overview ? renderNotificationList() : viewMode == NotificationViewMode.Settings ? renderNotificationSettings() : renderCreateNotification();

    return (
        <>
            <Dropdown
                overlay={content}
                placement="bottomRight"
                arrow
                trigger={['click']}
                onOpenChange={(flag => setVisible(flag))}
                open={visible}
            >
                <Badge size="small" className="notification-badge" count={unreadCount} offset={[0, 5]}>
                    <BellOutlined />
                </Badge>
            </Dropdown>
            <ActivityEditDrawer
                activityId={searchParams.get("activity")}
                open={searchParams.get("activity") !== null}
                onClose={() => setSearchParams({})}
                onComplete={() => setSearchParams({})}
            />
        </>
    );
}
