import * as React from "react";
import _ from 'lodash';
import { Input, Form, } from 'antd';
import { SizeType } from "antd/lib/config-provider/SizeContext";
import SelectorProps from "../../Models/SelectorProps";
import AppContext from "../../Definitions/AppContext";

const { Search } = Input;
const typeTimeoutInMs = 400;

interface EntitySearchProps extends SelectorProps {
    loading: boolean;
    size?: SizeType;
    className?: string;
    showClear?: boolean;
    placeholder?: string;
    autofocus?: boolean;
}

interface EntitySearchState {
    value: any;
}

export class EntitySearch extends React.Component<EntitySearchProps, EntitySearchState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props)
        this.state = {
            value: undefined
        };
        this.onSearch = _.debounce(this.onSearch, 500);
    }

    componentDidMount = () => {
        if (this.props.value)
            this.setState({ value: this.props.value });
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    onSearch = async (terms: string) => {
        if (this.props.onChange)
            this.props.onChange(terms);
    }

    onChange = (event) => {
        const terms = event.target.value;
        this.setState({ value: terms });
        this.onSearch(terms);
    }

    handleClick = (event) => {
        event.stopPropagation();
    }

    render = () => {
        const size = this.props.size ? this.props.size : 'large';

        return (
            <div className={`entity-search ${this.props.className ? this.props.className : ""}`}>
                <Search
                    placeholder={this.props.placeholder ?? "Search..."}
                    enterButton
                    allowClear={this.props.showClear ?? false}
                    value={this.state.value}
                    size={size}
                    onChange={this.onChange}
                    onClick={this.handleClick}
                    loading={this.props.loading}
                    autoFocus={this.props.autofocus == null ? this.context.isMobile ? false : true : this.props.autofocus}
                />
            </div>
        );
    }
}