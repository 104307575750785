import * as React from "react";
import _ from 'lodash';
import {
    AddOrUpdateAddressCollectionItem,
    AddressType,
    CallTargetView,
    ContactPointType,
    ContactPointView,
    CreateOrUpdateOrganizationWebRequest,
    OrganizationView,
    TaggableType,
} from "../../ApiClient/swagger/data-contracts";
import { BronnoysundOrganization } from "../../Models/_bronnoysund";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { TextInput } from "../Shared/TextInput";
import BronnoysundRegisterSelector from "../Shared/BronnoysundRegisterSelector";
import { TagSelector } from "../Tags";
import { Checkbox, Form, Space, Typography } from "antd";
import { CustomFormLabel } from "../Shared/CustomFormLabel";
import { NumberInput } from "../Shared/NumberInput";
import CustomerCategorySelector from "../Shared/CustomerCategorySelector";
import SupplierCategorySelector from "../Shared/SupplierCategorySelector";
import { openNotification } from "../../Helpers/BasePageHelpers";
import { CloseCircleFilled } from "@ant-design/icons";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";

const { Paragraph} = Typography;

interface CreateOrganizationProps {
    onComplete: (created?: OrganizationView) => void;
    onCancel?: () => void;
    cp?: ContactPointView | CallTargetView;
}

interface CreateOrganizationState {
    loading: boolean;
    error: string | null;
    brOrganization: BronnoysundOrganization;
    isCustomer: boolean;
    isSupplier: boolean;
    email: ContactPointView | CallTargetView;
    phoneNumber: ContactPointView | CallTargetView;
    useOfficeAddress: boolean;
}

interface ExtendedCreateOrganizationWebRequest extends CreateOrUpdateOrganizationWebRequest {
    email: string;
    phoneNumber: string;
}

export class OrganizationCreateForm extends React.Component<
    CreateOrganizationProps,
    CreateOrganizationState
> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            brOrganization: null,
            isCustomer: false,
            isSupplier: false,
            email: null,
            phoneNumber: null,
            useOfficeAddress: false,
        };
    }

    onSubmit = async (request: ExtendedCreateOrganizationWebRequest) => {
        this.setState({ loading: true });
        
        try {
            const response = await client.organizations.createOrganization(
                request
            );

            if (response) {
                if (request.email && this.props.cp?.type !== ContactPointType.Email) {
                    try {
                        await client.contactPoints.createContactPoint({
                            type: ContactPointType.Email,
                            value: request.email,
                            contactId: response.data.id,
                            description: null
                        });
                    }
                    catch (error: any) {
                        openNotification("Error adding email to organization", error.error, "error", null, "emailError", false);
                    }
                }

                if (request.phoneNumber && this.props.cp?.type != ContactPointType.Phone) {
                    try {
                        await client.contactPoints.createContactPoint({
                            type: ContactPointType.Phone,
                            value: request.phoneNumber.toString(),
                            contactId: response.data.id,
                            description: null
                        });
                    }
                    catch (error: any) {
                        openNotification("Error adding phone number to organization", error.error, "error", null, "phoneError", false);
                    }
                }

                if (this.state.useOfficeAddress) {
                    try {
                        const address: AddOrUpdateAddressCollectionItem = {
                            types: [AddressType.Office], 
                            line1: this.state.brOrganization?.forretningsadresse?.adresse[0],
                            zipCode: this.state.brOrganization?.forretningsadresse?.postnummer,
                            area: this.state.brOrganization?.forretningsadresse?.poststed,
                        }
                        await client.organizations.createOrganizationAddress(response.data.id, address, addChangeVectorHeader(response.data.changeVector))
                    } catch (error: any) {
                        openNotification("Error adding address to organization", error.message, "error", null, "addressError", false);
                    }
                }

                this.props.onComplete(response.data);
            }
        } catch (error: any) {
            this.setState({ error: error.message ?? error.error });
        }

        this.setState({ loading: false });
    };

    onOrganizationChange = (brOrganization: BronnoysundOrganization) => {
        if (brOrganization !== undefined || brOrganization === null) {
            this.setState({ brOrganization });
            if (brOrganization?.forretningsadresse) {
                this.setState({ useOfficeAddress: true });
            }
        }
    };

    render = () => {
        const initialValues: Partial<ExtendedCreateOrganizationWebRequest> = {};
        if (this.props.cp) {
            if (this.props.cp.type === ContactPointType.Phone)
                initialValues.phoneNumber = this.props.cp.value;
            else if (this.props.cp.type === ContactPointType.Email)
                initialValues.email = this.props.cp.value;
        }

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    description:
                        this.state.brOrganization != null &&
                        this.state.brOrganization.naeringskode1 &&
                        this.state.brOrganization.naeringskode1.beskrivelse &&
                        this.state.brOrganization.naeringskode1.beskrivelse !==
                            "Uoppgitt"
                            ? this.state.brOrganization.naeringskode1
                                  .beskrivelse
                            : null,
                    organizationNumber:
                        this.state.brOrganization != null
                            ? this.state.brOrganization.organisasjonsnummer
                            : null,
                    officeAddress: 
                        this.state.brOrganization != null
                            ? this.state.brOrganization.forretningsadresse
                            : null,
                    email: initialValues?.email,
                    phoneNumber: initialValues?.phoneNumber
                }}
            >
                <SelectorInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    title="Name"
                    selector={
                        <BronnoysundRegisterSelector
                            placeholder=" Name (Suggestions from Brønnøysundreg.)"
                            onObjectChange={this.onOrganizationChange}
                        />
                    }
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                <TextInput
                    param="organizationNumber"
                    warningMessage="Please type a Organization number"
                    placeholder="Organization number"
                    title="Organization number"
                />

                <TextInput
                    param="email"
                    placeholder="Email"
                    title="Email"
                    disabled={this.props.cp?.type === ContactPointType.Email}
                />

                <TextInput
                    param="phoneNumber"
                    placeholder="Phone number"
                    title="Phone number"
                    disabled={this.props.cp?.type === ContactPointType.Phone}
                />

                {this.state.useOfficeAddress ? (
                    <>
                        <CustomFormLabel 
                            label="Office address" 
                            action={
                                <CloseCircleFilled 
                                    style={{ color: "rgba(0, 0, 0, 0.25)", fontSize: "12px", marginLeft: "10px", cursor: "pointer" }} 
                                    onClick={() => this.setState({ useOfficeAddress: false })}
                            />} 
                        />
                        <Form.Item name="officeAddress">
                            <Paragraph style={{margin: 0}}>{this.state.brOrganization?.forretningsadresse?.adresse[0]}</Paragraph>
                            <Paragraph style={{margin: 0}}><span>{this.state.brOrganization?.forretningsadresse?.postnummer}</span> <span>{this.state.brOrganization?.forretningsadresse?.poststed}</span></Paragraph>
                        </Form.Item>
                    </>
                ) : null}

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={
                        <TagSelector
                            multiple
                            filters={{
                                taggableTypes: [TaggableType.Organization],
                            }}
                        />
                    }
                />

                <CustomFormLabel label="Type of business relation" optional />

                <Space>
                    <Form.Item name="isCustomer" valuePropName="checked">
                        <Checkbox
                            onChange={(e) =>
                                this.setState({ isCustomer: e.target.checked })
                            }
                        >
                            Customer
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="isSupplier" valuePropName="checked">
                        <Checkbox
                            onChange={(e) =>
                                this.setState({ isSupplier: e.target.checked })
                            }
                        >
                            Supplier
                        </Checkbox>
                    </Form.Item>
                </Space>

                {this.state.isCustomer ? (
                    <>
                        <NumberInput
                            param="creditLimit"
                            title="Credit limit"
                            placeholder="Enter"
                            addonAfter="kr"
                        />
                        <TextInput param="invoiceEmail" title="Invoice email" placeholder="Enter" type="email" />
                        <SelectorInput 
                            param="customerCategoryId"
                            title="Customer category"
                            selector={<CustomerCategorySelector />}
                        />
                    </>
                ) : null}
                
                {this.state.isSupplier ? (
                    <>
                        <SelectorInput 
                            param="supplierCategoryId"
                            title="Supplier category"
                            selector={<SupplierCategorySelector />}
                        />
                    </>
                ) : null}
            </BaseForm>
        );
    };
}
