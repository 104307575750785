import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Form, Radio, Card, Checkbox, Alert, Button } from "antd";
import {
    ActivityType, ActivityView, ActorLinkView, ActorView, CallDirection, CallView,
    HourCategoryType, ProductUnit, ProductView, ProjectView, TaggableType, TaskView, TicketView, PersonView,
    CreateCustomerActivityWebRequest, CreateProjectActivityWebRequest, CreateInternalActivityWebRequest, ActorSortOption, ActorType, OrganizationView
} from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { TagIds } from "../../Definitions/_definitions";
import { roundDownToNearestQuarter, roundToNearestQuarter, roundUpToNearestQuarter, setTimeToDate } from "../../Helpers/BasePageHelpers";
import ActivityHelper from "./ActivityHelper";
import { CreateActivity } from "./ActivityCreateForm";
import { formatDuration } from "../../Helpers/Formatters";
import { SelectorInput } from "../Shared/SelectorInput";
import TimeSelector from "../Shared/TimeSelector";
import DateSelector from "../Shared/DateSelector";
import { Capabilities } from "../../Definitions/_capabilties";
import BaseForm, { FormType } from "../Shared/Form";
import { ActorSelector, CustomerSelector } from "../Actors";
import SelectorInformation from "../Shared/SelectorInformation";
import { HourCategorySelector } from "../HourCategories";
import { ProjectSelector } from "../Projects";
import { TaskSelector } from "../Tasks";
import { ProductSelector } from "../Products";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { TagSelector } from "../Tags";
import { Drawer } from "../Shared/Drawer";
import { LoadingPage } from "../../Pages/LoadingPage";
import EnumSelector from "../Shared/EnumSelector";
import { TicketCreateForm, TicketSelector } from "../Tickets";
import { CoreCollection } from "../../Definitions/collections";
import { log } from "console";


interface RegisterActivityFromCallProps {
    calls: CallView[];
    call: CallView;
    actorId?: string;
    onClose: Function;
    onComplete?: (created: ActivityView[]) => void;
}

enum PeriodStartEnd {
    Start = "start",
    End = "end"
}

class ActivityFromCallForm extends React.Component<RegisterActivityFromCallProps, any> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activity: null,
            loading: false,
            error: null,
            startDate: null,
            endDate: null,
            type: ActivityType.Customer,
            selectedCall: null,
            suggestions: [],
            actor: null,
            startTime: null,
            endTime: null,
            useCallNotes: false,
            project: null,
            task: null,
            category: null,
            customer: null,
            product: null,
            callActor: null,
            callActorOrganizations: null,
            customerNotInvoiceableWarning: null,
            showCreateTicketDrawer: false
        }
    }


    componentDidMount = async () => {

        this.mapSuggestions();

        let actor;
        if (this.props.actorId)
            actor = { id: this.props.actorId, name: "", type: null, state: null };
        else {
            actor = {
                id: this.context.user.actorId,
                name: this.context.user.name
            }
        }

        this.setState({ actor: actor });

        const selectedCall = this.props.call ? this.props.call : this.props.calls.length > 0 ? this.props.calls[0] : null;
        this.setState({ selectedCall });

        const initialmomentStart = selectedCall ? roundToNearestQuarter(selectedCall.start) : null;
        const initialmomentEnd = selectedCall ? roundToNearestQuarter(selectedCall.end ?? moment().format()) : null;
        if (initialmomentStart && initialmomentEnd && initialmomentStart.format("HH:mm") == initialmomentEnd.format("HH:mm"))
            initialmomentEnd.set({ minutes: initialmomentEnd.minute() + 15 });

        const initialStartTime = initialmomentStart.format("HH:mm");
        const initialEndTime = initialmomentEnd.format("HH:mm");
        const initialStartDate = selectedCall ? moment(selectedCall.start).startOf('minute') : null;
        const initialEndDate = selectedCall ? moment(selectedCall.start).endOf('minute') : null;

        this.setState({ 
            startTime: initialStartTime,
            endTime: initialEndTime,
            startDate: initialStartDate,
            endDate: initialEndDate,
        })
        

        await this.loadCallActor(actor.id, selectedCall);
    }

    

    loadCallActor = async (actorId: string, selectedCall: CallView) => {
        if (!actorId || !selectedCall) return;

        if (selectedCall.direction == CallDirection.Internal) {
            this.setState({ type: ActivityType.Internal });
            return;
        }

        let callActor: ActorLinkView = null;
        if (selectedCall.to.contact && actorId == selectedCall.to.contact.id) {
            callActor = selectedCall.from.contact ?? null;
        }
        else {
            callActor = selectedCall.to.contact ?? null;
        }

        if (callActor != null && callActor.id != this.state.callActor?.id) {

            try {
                const actorData = await client.people.getPersonById(callActor.id);
                const orgs = _.map(actorData.data.view.organizationRoles ?? [], or => or.organization);

                //var organizationData = await client.organizations.queryOrganizations({ personId: callActor.id, tagIds: [TagIds.Invoiceable], deleted: false });
                this.setState({ callActor: actorData.data.view, callActorOrganizations: orgs });

                let type = null;
                const activity = this.state.activity;

                if (this.state.type == null && activity && activity.type) {
                    type = activity.type;
                } else {
                    type = this.state.type;
                }

                const callActorIsCustomer = actorData.data.view != null && actorData.data.view?.customerInfo != null;

                if (type === ActivityType.Customer) {
                    if (orgs.length === 1) {
                        this.setState({ customer: orgs[0] });
                    }
                    else if (callActorIsCustomer && orgs.length < 1) {
                        this.setState({ customer: actorData.data.view });
                }
        }
            }
            catch (error) {

            }
        }
    }

    onAddActivity = async (e) => {
        const { activity } = this.state;

        const start = setTimeToDate(e.start, e.startTime, "HH:mm");
        const end = setTimeToDate(e.end, e.endTime, "HH:mm");
        const tagIds = e.tagIds;

        const links = ActivityHelper.formatRequestTypeFromRequest(e);
        this.setState({ loading: true });

        if (links && ((activity && activity.actor && activity.actor.id) || this.state.actor.id)) {
            const request: CreateActivity = {
                actorId: activity && activity.actor ? activity.actor.id : this.state.actor.id,
                start: start.format(),
                end: end.format(),
                description: e.description,
                links: links,
                callId: this.state.selectedCall?.id ?? null,
                copyNoteToCall: e.copyToCall,
                groupId: null,
                meetingId: null,
                participants: null,
                suggestToOthers: false,
                tagIds: tagIds
            }

            try {
                const activityResponse = links.type == ActivityType.Customer
                    ? await client.activities.createCustomerActivity(request as CreateCustomerActivityWebRequest)
                    : links.type == ActivityType.Project
                        ? await client.activities.createProjectActivity(request as CreateProjectActivityWebRequest)
                        : links.type == ActivityType.Internal
                            ? await client.activities.createInternalActivity(request as CreateInternalActivityWebRequest)
                            : null;

                if (activityResponse) {
                    this.props.onComplete([activityResponse.data]);
                }
            }
            catch (error: any) {
                this.setState({ error: error.error });
            }
        }
        this.setState({ loading: false });
    }

    resetStateValues = () => {
        this.setState({ project: null, task: null, category: null, customer: null, ticket: null });
    }

    onTypeChange = async (value) => {
        this.resetStateValues();
        this.setState({ type: value });
    }

    onCallChange = (call: CallView) => {
        this.setState({ useCallNotes: false, selectedCall: call });
        this.loadCallActor(this.state.actor.id, call);
    }

    onTimeChange = (time, type: PeriodStartEnd) => {
        let timeObject = time;

        if (type == PeriodStartEnd.Start) {
            if (typeof timeObject !== "object") {
                timeObject = roundDownToNearestQuarter(time);
            }

            this.setState({ startTime: time });
        }

        else if (type == PeriodStartEnd.End) {
            if (typeof timeObject !== "object") {
                timeObject = roundUpToNearestQuarter(time);
            }
            this.setState({ endTime: time });
        }
    }

    onDateChange = (date, type: PeriodStartEnd) => {
        if (type == PeriodStartEnd.Start) {
            this.setState({ startDate: date });
        }
        else if (type == PeriodStartEnd.End) {
            this.setState({ endDate: date });
        }
    }

    onProjectChange = (project: ProjectView) => {
        this.setState({
            task: null,
            project: project,
            customer: project != null ? project.customer : null
        });
    }

    onTaskChange = (task: TaskView) => {
        this.setState({
            task: task,
            project: task != null ? task.project : null,
            customer: task != null ? task.projectOwner : null,
            category: task != null ? task.category : null
        });
    }

    onCustomerChange = (customer: ActorView) => {
        this.setState({
            task: null,
            project: null,
            ticket: null,
            customer: customer != null ? customer : null,
            customerNotInvoiceableWarning: customer != null ? customer.actorType === ActorType.Person
                                            ? (customer as PersonView).customerInfo == null
                                            : customer.actorType === ActorType.Organization
                                                ? (customer as OrganizationView).customerInfo == null
                                                : true : null
        });
        
    }

    onTicketChange = (ticket: TicketView) => {
        this.setState({
            ticket: ticket,
            customer: ticket != null ? ticket.customer : null
        });
    }

    onProductChange = (product: ProductView) => {
        //var placeholderProduct = this.state.task
        //    && this.state.task.category
        //    && this.state.task.category.product ? this.state.task.category.product
        //    : this.state.category && this.state.category.product
        //        ? this.state.category.product : null;

        //if (product == null || product == undefined || !product) {
        //    this.setState({ productOverrideWarning: null });
        //}
        //else if (placeholderProduct != null && placeholderProduct.id != product.id) {
        //    this.setState({ productOverrideWarning: "This will override the existing product" });
        //}

        this.setState({ product });
    }

    mapCallCard = () => {
        if (!this.state.actor)
            return;

        const { calls } = this.props;

        if (!calls || calls.length <= 0)
            return;

        const actorId = this.state.actor.id;

        const cards = _.map(calls, (call) => {
            let userInfo = "";
            const start = moment(call.start).format("HH:mm");
            const end = moment(call.end).format("HH:mm");
            const duration = formatDuration(call.duration);

            if (call.to.contact && actorId == call.to.contact.id) {
                const callVal = call.from.contact ? call.from.contact.name : call.from.value;
                userInfo = `From: ${callVal}`;
            }
            else {
                const callVal = call.to.contact ? call.to.contact.name : call.to.value;
                userInfo = `To: ${callVal}`;
            }
            const callDurationInfo = `Time: ${start} - ${end} ${duration}`
            if (calls.length == 1) {
                return (<div key={call.id} className="activity-call-radiogroup" >
                    <div className="activity-call-radio-option"><label>{userInfo}</label></div>
                    <div className="activity-call-radio-time-info">{callDurationInfo}</div>
                </div>);
            }
            else {
                const active = this.state.selectedCall && this.state.selectedCall.id == call.id;
                return (<Card key={call.id} className={`activity-call-card ${active ? "selected-call" : ""}`} onClick={() => { this.onCallChange(call) }}>
                    <Radio value={call.id} checked={active} className="activity-call-radio-option"> <span>{userInfo}</span> </Radio>
                    <span className="activity-call-radio-time-info">{callDurationInfo}</span>
                </Card>);
            }
        });
        if (calls.length == 1) {
            return cards;
        }

        return (
            <Radio.Group className="activity-call-radiogroup" key={this.state.selectedCall ? this.state.selectedCall.id : ""} defaultValue={this.state.selectedCall ? this.state.selectedCall.id : ""} >
                {cards}
            </Radio.Group>
        );
    }

    mapSuggestions = async () => {
        const suggestionsResponse = await client.activities.getSuggestedActivities({
            personId: this.props.actorId ? this.props.actorId : this.context.user.actorId,
            to: this.state.endTime ?? null
        });

        const keys = {};
        _.forEach(suggestionsResponse.data, a => {
            switch (a.type) {
                case ActivityType.Project:
                    if (a.task) {
                        var key = "project" + a.task.id;
                        var item = keys[key];
                        if (item) item.count++
                        else {
                            let text = "";
                            if (a.task.parents == null || a.task.parents.length == 0) {
                                text = `${a.project.name} / ${a.task.name}`;
                            }
                            else {
                                text = `${a.project.name}`;
                                if (a.task.parents != null) {
                                    for (let i = 0; i < a.task.parents.length; i++) {
                                        text += ` / ${a.task.parents[i].name} `;
                                    }
                                }
                                text += ` / ${a.task.name}`;
                            }
                            keys[key] = {
                                count: 1,
                                text: text,
                                type: a.type,
                                project: a.project,
                                task: a.task
                            }
                        }
                    }
                    break;

                case ActivityType.Customer: {
                    if (a.category && a.relation) {
                        var key = "customer" + a.category.id + a.relation.id;
                        var item = keys[key];
                        if (item) item.count++
                        else {
                            keys[key] = {
                                count: 1,
                                text: `${a.relation.name} / ${a.category.name}`,
                                type: a.type,
                                category: a.category,
                                customer: a.relation,
                                parents: null
                            }
                        }
                    }
                }
                    break;

                case ActivityType.Internal: {
                    if (a.category) {
                        var key = "internal" + a.category.id;
                        var item = keys[key];
                        if (item) item.count++
                        else {
                            keys[key] = {
                                count: 1,
                                text: a.category.name,
                                type: a.type,
                                category: a.category
                            }
                        }
                    }
                }
            }
        })

        const suggestions = _.map(keys, s => { return s });
        let top = [];
        if (suggestions && suggestions.length) {
            top = suggestions.sort((a, b) =>
                //@ts-ignore
                (a.count > b.count) ? -1 : 1).slice(0, 5);
        }
        this.setState({ suggestions: top });
    }

    onSuggestionClick = (s) => {
        this.setState({ project: s.project, task: s.task, category: s.category, customer: s.customer, type: _.upperFirst(_.toLower(s.type)) });
    }

    toggleCreateTicketDrawer = () => {
        this.setState({ showCreateTicketDrawer: !this.state.showCreateTicketDrawer });
    }

    onCompleteAddTicket = async (data) => {
        try {
            this.setState({ ticket: data });
            this.toggleCreateTicketDrawer();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    render() {
        if (!this.state.selectedCall)
            return <LoadingPage />;

        let type = null;
        const activity = this.state.activity;

        if (this.state.type == null && activity && activity.type) {
            type = activity.type;
        } else {
            type = this.state.type;
        }

        let suggestions = [];
        if (type == null) {
            suggestions = _.map(this.state.suggestions, s => {
                return (<div className="suggestion" key={Math.random()} onClick={() => this.onSuggestionClick(s)}>
                    <div>{_.upperFirst(_.toLower(s.type))}</div>
                    <div>{s.text}</div>
                </div>);
            });
        }

        let suggestionsTitle = <div className="ant-drawer-title suggestions-title">Recent hours</div>;

        if (suggestions.length == 0) {
            suggestionsTitle = null;
        }

        const timeSelectors = [
            <div className="ant-form-item-label" key="start-header"><label>Start</label></div>,
            <div className="start-selectors" key="start-selectors">
                <SelectorInput
                    param="startTime"
                    key={this.state.selectedCall ? `startTime-${this.state.selectedCall.id}` : "startTime"}
                    required
                    warningMessage="Start time is required"
                    selector={
                        <TimeSelector
                            key={this.state.selectedCall ? `startTime-${this.state.selectedCall.id}` : "startTime"}
                            className="startTime"
                            onObjectChange={(obj) => this.onTimeChange(obj, PeriodStartEnd.Start)}
                            value={this.state.startTime}
                            disableClear
                        />
                    }
                />
                <SelectorInput
                    param="start"
                    key="start"
                    required
                    warningMessage="Start date is required"
                    selector={
                        <DateSelector
                            className="start"
                            onObjectChange={(obj) => this.onDateChange(obj, PeriodStartEnd.Start)}
                            disableClear
                        />
                    }
                />
            </div>,
            <div className="ant-form-item-label" key="end-header"><label>End</label></div>,
            <div className="end-selectors" key="end-selectors">
                <SelectorInput
                    param="endTime"
                    key={this.state.selectedCall ? `endTime-${this.state.selectedCall.id}` : "endTime"}
                    required
                    warningMessage="End time is required"
                    selector={
                        <TimeSelector
                            key={this.state.selectedCall ? `startTime-${this.state.selectedCall.id}` : "startTime"}
                            className="endTime"
                            onObjectChange={(obj) => this.onTimeChange(obj, PeriodStartEnd.End)}
                            value={this.state.endTime}
                            disableClear
                        />
                    }
                />
                <SelectorInput
                    param="end"
                    key="end"
                    selector={
                        <DateSelector
                            className="end"
                            onObjectChange={(obj) => this.onDateChange(obj, PeriodStartEnd.End)}
                            disableClear
                        />
                    }
                />
            </div>];

        const cards = this.state.selectedCall ? this.mapCallCard() : null;

        const initialValues = {
            start: this.state.startDate ? this.state.startDate : null,
            end: this.state.endDate ? this.state.endDate : null,
            startTime: this.state.startTime ? this.state.startTime : null,
            endTime: this.state.endTime ? this.state.endTime : null,
            taskId: this.state.task ? this.state.task.id : null,
            projectId: this.state.project ? this.state.project.id : null,
            customerId: this.state.customer ? this.state.customer.id : null,
            categoryId: this.state.category ? this.state.category.id : null,
            ticketId: this.state.ticket ? this.state.ticket.id : null,
            productId: this.state.product ? this.state.product.id : null,
            copyToCall: true,
            description: this.state.selectedCall && this.state.useCallNotes ? this.state.selectedCall.note : "",
            tagIds: activity ? _.map(activity.tags, tag => {
                if (tag.category?.deleted || tag?.deleted) return;
                return tag.id; 
            }) : null
        };

        const callActor: PersonView = this.state.callActor ?? null;

        const write = this.context.user.hasCapability(Capabilities.ActivitiesWrite) || (this.state.actor.id == this.context.user.actorId && this.context.user.hasCapability(Capabilities.ActivitiesWriteActor));

        let path = null;
        if (this.state.task && this.state.task.parents != null && this.state.task.parents.length > 0) {
            path = "";
            _.each(this.state.task.parents.reverse(), (p) => {
                path += `${p.name} / `
            });
        }

        return (
            <div>
                <BaseForm
                    type={FormType.Create}
                    loading={this.state.loading}
                    error={this.state.error}
                    onSubmit={write ? this.onAddActivity : null}
                    onCancel={() => { this.props.onClose() }}
                    className="activity-from-call-form"
                    hideActions={!type}
                    initialValues={initialValues}
                >
                    <div className="ant-form-item-label"><label>{this.props.calls.length > 1 ? "Select call" : "Call"}</label></div>
                    {cards}
                    {timeSelectors}

                    <EnumSelector
                        enum={ActivityType}
                        title="Type of call"
                        placeholder="Select activity type..."
                        type="radio"
                        onChange={this.onTypeChange}
                        value={type}
                        className="activity-type"
                        wrappedFormTitle
                    />

                    {type == ActivityType.Customer ? <SelectorInput
                        param="customerId"
                        title="Customer"
                        required
                        selector={
                            <CustomerSelector
                                placeholder={"Choose customer..."}
                                onObjectChange={this.onCustomerChange}
                            />
                        }
                    /> : null}
                    {type && type !== ActivityType.Project ?
                        <SelectorInput
                            param="categoryId"
                            required
                            title="Category"
                            selector={
                                <HourCategorySelector
                                    filters={{
                                        types: type == ActivityType.Internal ? [HourCategoryType.Work, HourCategoryType.Absence] : [HourCategoryType.Work],
                                        activityType: type
                                    }}
                                    onObjectChange={(cat) => this.setState({ category: cat })}
                                />
                            }
                        />
                        : null}

                    {type === ActivityType.Project ?
                        <SelectorInput
                            param="projectId"
                            title="Project"
                            required
                            selector={
                                <ProjectSelector
                                    onObjectChange={this.onProjectChange}
                                    filters={{
                                        isActive: true,
                                        //isPaused: false,
                                        isEnded: false
                                    }}
                                />
                            }
                        /> : null}
                    {type === ActivityType.Project ?
                        <SelectorInput
                            shouldUpdate
                            param="taskId"
                            title="Task"
                            required
                            warningMessage="Choose a task"
                            selector={
                                <TaskSelector
                                    title={path}
                                    filters={{
                                        showAll: true,
                                        closed: false,
                                        projectId: this.state.project ? this.state.project.id : null,
                                        projectActive: true,
                                        isContainer: false
                                    }}
                                    onObjectChange={this.onTaskChange}
                                />
                            }
                        /> : null}
                    {type === ActivityType.Project || type === ActivityType.Customer ?
                        <SelectorInput
                            param="productId"
                            title="Product"
                            optional
                            shouldUpdate
                            className="product-override"
                            selector={
                                <React.Fragment>
                                    <ProductSelector
                                        placeholder={
                                            this.state.task
                                                && this.state.task.category
                                                && this.state.task.category.product ? this.state.task.category.product.name
                                                : this.state.category && this.state.category.product
                                                    ? this.state.category.product.name
                                                    : "Select product..."}
                                        filters={{ units: [ProductUnit.Hour] }}
                                        onObjectChange={this.onProductChange}
                                    />
                                    {/*<div className="warning">{this.state.productOverrideWarning ?? null}</div>*/}
                                </React.Fragment>
                            }
                        /> : null}

                    {this.context.user.hasCapability(Capabilities.TicketsReadRelated) && type === ActivityType.Customer ?
                        <SelectorInput
                            param="ticketId"
                            title={
                                <React.Fragment>
                                    <span style={{ width: '100%' }}>Ticket (optional)</span>
                                    <Button type="link" onClick={this.toggleCreateTicketDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                                        + Create new ticket
                                    </Button>
                                </React.Fragment>
                            }
                            optional={false}
                            shouldUpdate
                            selector={
                                <TicketSelector
                                    onObjectChange={this.onTicketChange}
                                    filters={{
                                        closed: false,
                                        customerId: this.state.customer?.id
                                    }}
                                />
                            }
                        /> : null}

                    {type != null ?
                        <React.Fragment>
                            <div className="ant-form-item-label description-header"><label>Description</label>
                                {this.state.selectedCall && (this.state.selectedCall.note == null || this.state.selectedCall.note.length == 0) ? <span>No call note</span> : <a onClick={() => { this.setState({ useCallNotes: true }) }}>Use call note</a>}
                            </div>
                            <TextAreaInput
                                shouldUpdate
                                onCleared={() => this.setState({ useCallNotes: false })}
                                param="description"
                                required
                                warningMessage="Please provide a description"
                                placeholder="Description"
                                rows={3}
                            />

                            {this.state.selectedCall && (this.state.selectedCall.note == null || this.state.selectedCall.note.length == 0) ?
                                <Form.Item name="copyToCall" className="copyToCall" key="description-copy" valuePropName="checked">
                                    <Checkbox checked>Use description as call note</Checkbox>
                                </Form.Item>
                                : null}

                            <SelectorInput
                                param="tagIds"
                                title="Tags"
                                selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Activity] }} />}
                            />
                        </React.Fragment>
                        : null}
                </BaseForm>

                {
                    type == null
                        ? (<React.Fragment>
                            {suggestionsTitle}
                            {suggestions}
                        </React.Fragment>)
                        : null}


                {<Drawer
                    title="Create ticket"
                    onClose={this.toggleCreateTicketDrawer}
                    open={this.state.showCreateTicketDrawer}
                    component={
                        <TicketCreateForm
                            onComplete={this.onCompleteAddTicket}
                            onCancel={this.toggleCreateTicketDrawer}
                            filters={{
                                customerId: initialValues.customerId,
                                requesterId: callActor?.actorType == ActorType.Person ? callActor.id : null
                            }}
                        />
                    }
                />}
            </div>);
    }
}

export default ActivityFromCallForm;