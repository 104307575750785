/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdateClosingCommentForIncident,
  AddOrUpdateCorrectiveActionForIncident,
  AddOrUpdateFinancialConsequences,
  CloseIncident,
  CommentSortOption,
  CommentView,
  CommentViewEntityViewModel,
  CommentViewPagedCommentQueryCollectionViewModel,
  CreateComment,
  CreateFileOrFolder,
  CreateIncident,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  IncidentAggregationResultIncidentAggregateQueryAggregationResults,
  IncidentAggregationTypes,
  IncidentDateType,
  IncidentSortOption,
  IncidentView,
  IncidentViewEntityViewModel,
  IncidentViewPagedIncidentQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  PreviewType,
  RevertFileToVersion,
  SortDirection,
  UpdateComment,
  UpdateFileOrFolder,
  UpdateIncident,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Incidents<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Incident
   * @name GetIncidentById
   * @request GET:/api/incidents/{id}
   * @secure
   */
  getIncidentById = (id: string, params: RequestParams = {}) =>
    this.http.request<IncidentViewEntityViewModel, any>({
      path: `/api/incidents/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name UpdateIncident
   * @request PUT:/api/incidents/{id}
   * @secure
   */
  updateIncident = (id: string, data: UpdateIncident, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name DeleteIncident
   * @request DELETE:/api/incidents/{id}
   * @secure
   */
  deleteIncident = (id: string, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name CreateIncident
   * @request POST:/api/incidents
   * @secure
   */
  createIncident = (data: CreateIncident, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name QueryIncidents
   * @request GET:/api/incidents
   * @secure
   */
  queryIncidents = (
    query?: {
      sortBy?: IncidentSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      ownerId?: string;
      routineId?: string;
      limitToCategories?: string[];
      limitToCustomers?: string[];
      limitToResources?: string[];
      limitToParticipants?: string[];
      limitToProducts?: string[];
      limitToTags?: string[];
      limitToCauses?: string[];
      dateTypes?: IncidentDateType[];
      registratorId?: string;
      closerId?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
      /** @format date-time */
      occuredStart?: string;
      /** @format date-time */
      occuredEnd?: string;
      closed?: boolean;
      myIncidents?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<IncidentViewPagedIncidentQueryCollectionViewModel, any>({
      path: `/api/incidents`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name RestoreIncident
   * @request POST:/api/incidents/{id}/restore
   * @secure
   */
  restoreIncident = (id: string, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name UpdateIncidentAttributes
   * @request POST:/api/incidents/{id}/attributes
   * @secure
   */
  updateIncidentAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name AddOrUpdateCorrectiveAction
   * @request PUT:/api/incidents/{id}/correctiveActions
   * @secure
   */
  addOrUpdateCorrectiveAction = (
    id: string,
    data: AddOrUpdateCorrectiveActionForIncident,
    params: RequestParams = {},
  ) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}/correctiveActions`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name AddOrUpdateClosingComment
   * @request PUT:/api/incidents/{id}/closingComment
   * @secure
   */
  addOrUpdateClosingComment = (id: string, data: AddOrUpdateClosingCommentForIncident, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}/closingComment`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name AddOrUpdateFinancialConsequences
   * @request PUT:/api/incidents/{id}/financialConsequences
   * @secure
   */
  addOrUpdateFinancialConsequences = (id: string, data: AddOrUpdateFinancialConsequences, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}/financialConsequences`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name CloseIncident
   * @request PUT:/api/incidents/{id}/close
   * @secure
   */
  closeIncident = (id: string, data: CloseIncident, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}/close`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name OpenIncident
   * @request PUT:/api/incidents/{id}/open
   * @secure
   */
  openIncident = (id: string, params: RequestParams = {}) =>
    this.http.request<IncidentView, any>({
      path: `/api/incidents/${id}/open`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name GetIncidentAggregates
   * @request GET:/aggs/incidents
   * @secure
   */
  getIncidentAggregates = (
    query?: {
      incidentId?: string;
      aggs?: IncidentAggregationTypes[];
      /** @format int32 */
      openCount?: number;
      /** @format int32 */
      overDueCount?: number;
      /** @format int32 */
      closedCount?: number;
      ownerId?: string;
      routineId?: string;
      limitToCategories?: string[];
      limitToCustomers?: string[];
      limitToResources?: string[];
      limitToParticipants?: string[];
      limitToProducts?: string[];
      limitToTags?: string[];
      limitToCauses?: string[];
      dateTypes?: IncidentDateType[];
      registratorId?: string;
      closerId?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
      /** @format date-time */
      occuredStart?: string;
      /** @format date-time */
      occuredEnd?: string;
      closed?: boolean;
      myIncidents?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<IncidentAggregationResultIncidentAggregateQueryAggregationResults, any>({
      path: `/aggs/incidents`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Incident
   * @name GetIncidentAggregatesById
   * @request GET:/aggs/incidents/{id}
   * @secure
   */
  getIncidentAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/incidents/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentComment
   * @name GetIncidentCommentById
   * @request GET:/api/incidents/{entityId}/comments/{commentId}
   * @secure
   */
  getIncidentCommentById = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentViewEntityViewModel, any>({
      path: `/api/incidents/${entityId}/comments/${commentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentComment
   * @name UpdateIncidentComment
   * @request PUT:/api/incidents/{entityId}/comments/{commentId}
   * @secure
   */
  updateIncidentComment = (entityId: string, commentId: string, data: UpdateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/incidents/${entityId}/comments/${commentId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentComment
   * @name DeleteIncidentComment
   * @request DELETE:/api/incidents/{entityId}/comments/{commentId}
   * @secure
   */
  deleteIncidentComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/incidents/${entityId}/comments/${commentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentComment
   * @name CreateIncidentComment
   * @request POST:/api/incidents/{entityId}/comments
   * @secure
   */
  createIncidentComment = (entityId: string, data: CreateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/incidents/${entityId}/comments`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentComment
   * @name QueryIncidentComments
   * @request GET:/api/incidents/{entityId}/comments
   * @secure
   */
  queryIncidentComments = (
    entityId: string,
    query?: {
      sortBy?: CommentSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CommentViewPagedCommentQueryCollectionViewModel, any>({
      path: `/api/incidents/${entityId}/comments`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentComment
   * @name RestoreIncidentComment
   * @request POST:/api/incidents/{entityId}/comments/{commentId}/restore
   * @secure
   */
  restoreIncidentComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/incidents/${entityId}/comments/${commentId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentComment
   * @name AddIncidentCommentEmoji
   * @request POST:/api/incidents/{entityId}/comments/{commentId}/reactions/{emojicode}
   * @secure
   */
  addIncidentCommentEmoji = (entityId: string, commentId: string, emojicode: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/incidents/${entityId}/comments/${commentId}/reactions/${emojicode}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name GetIncidentFileById
   * @request GET:/api/incidents/{entityId}/files/{fileId}
   * @secure
   */
  getIncidentFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/incidents/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name UpdateIncidentFile
   * @request PUT:/api/incidents/{entityId}/files/{fileId}
   * @secure
   */
  updateIncidentFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/incidents/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name DeleteIncidentFile
   * @request DELETE:/api/incidents/{entityId}/files/{fileId}
   * @secure
   */
  deleteIncidentFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/incidents/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name DownloadIncidentFile
   * @request GET:/api/incidents/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadIncidentFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/incidents/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name PreviewIncidentFile
   * @request GET:/api/incidents/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewIncidentFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/incidents/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name CreateIncidentFile
   * @request POST:/api/incidents/{entityId}/files
   * @secure
   */
  createIncidentFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/incidents/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name QueryIncidentFiles
   * @request GET:/api/incidents/{entityId}/files
   * @secure
   */
  queryIncidentFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/incidents/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name RestoreIncidentFile
   * @request POST:/api/incidents/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreIncidentFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/incidents/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name MoveIncidentFile
   * @request POST:/api/incidents/{entityId}/files/{fileId}/move
   * @secure
   */
  moveIncidentFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/incidents/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name RevertIncidentFile
   * @request POST:/api/incidents/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertIncidentFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/incidents/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name GetIncidentFolderById
   * @request GET:/api/incidents/{entityId}/folder/{folderId}
   * @secure
   */
  getIncidentFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/incidents/${entityId}/folder/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name CreateIncidentFolder
   * @request POST:/api/incidents/{entityId}/folders
   * @secure
   */
  createIncidentFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/incidents/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name QueryIncidentFolders
   * @request GET:/api/incidents/{entityId}/folders
   * @secure
   */
  queryIncidentFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/incidents/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name UpdateIncidentFolder
   * @request PUT:/api/incidents/{entityId}/folders/{folderId}
   * @secure
   */
  updateIncidentFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/incidents/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name DeleteIncidentFolder
   * @request DELETE:/api/incidents/{entityId}/folders/{folderId}
   * @secure
   */
  deleteIncidentFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/incidents/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name RestoreIncidentFolder
   * @request POST:/api/incidents/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreIncidentFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/incidents/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncidentFile
   * @name MoveIncidentFolder
   * @request POST:/api/incidents/{entityId}/{folderId}/move
   * @secure
   */
  moveIncidentFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/incidents/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
