import _ from "lodash";
import moment from "moment";
import client from "../client";
import { PagedProductQuery, ProductUnit, ProductView } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class ProductExport extends BaseExportService<PagedProductQuery, ProductView> {

    constructor() {

        const specification: ExportConfig<ProductView>[] = [
            { header: 'Code', value: (data: ProductView) => data.code ?? null },
            { header: 'Name', value: (data: ProductView) => data.name ?? null },
            { header: 'Type', value: (data: ProductView) => data.type ?? null },
            { header: 'Unit', value: (data: ProductView) => data.defaultUnit ?? null },
            { header: 'Description', value: (data: ProductView) => data.description ?? null },
            { header: 'Category', value: (data: ProductView) => data.category ? data.category.name : null },
            {
                header: 'Suppliers', value: (data: ProductView) => {
                    let suppliers = "";
                    _.map(data.suppliers, (supplier, index) => {
                        if (index != 0) suppliers = suppliers + ", ";
                        suppliers = suppliers + supplier.supplier.name;
                    })
                    return suppliers;
                }
            },
        ];

        _.each(Object.values(ProductUnit), (val) => {
            specification.push({
                header: `Cost price - ${val}`,
                value: (data: ProductView) => {
                    const match = _.find(data.prices ?? [], p => p.unit === val);
                    if (match) return match.cost;
                    return null;
                },
                format: ExportFormat.Currency
            });
            specification.push({
                header: `Sales price - ${val}`,
                value: (data: ProductView) => {
                    const match = _.find(data.prices ?? [], p => p.unit === val);
                    if (match) return match.sales;
                    return null;
                },
                format: ExportFormat.Currency
            });
        });

        specification.push({
            header: 'Attributes', value: (data: ProductView) => {
                let dataString = "";

                if (data.attributes) {
                    _.each(data.attributes, (key, value) => {
                        if (value != "rootFolderId") {
                            if (dataString.length > 0) dataString = dataString + ", ";
                            dataString = dataString + key + ": " + value;
                        }
                    })
                }

                return dataString;
            }
        });

        specification.push(
            { header: 'Deleted', value: (data: ProductView) => data.deleted ? true : false },
            { header: 'Created', value: (data: ProductView) => data.created ? moment(data.created).format('DD.MM.YYYY') : null },
            { header: 'Updated', value: (data: ProductView) => data.lastModified ? moment(data.lastModified).format('DD.MM.YYYY') : null });

        super(specification, client.products.queryProducts);
    }
}