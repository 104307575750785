import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Button, Checkbox, Form, Alert } from "antd";
import { Loading3QuartersOutlined, UpOutlined, DownOutlined, PlayCircleOutlined } from "@ant-design/icons";
import {
    ActivityType, ActivityView, ActorLinkView, ActorSortOption, ActorType, ActorView, CategoryLinkView,
    CreateCustomerActivityWebRequest, CreateInternalActivityWebRequest, CreateProjectActivityWebRequest, CustomerActivityTypeLinks, HourCategoryLinkView, HourCategoryType,
    HourCategoryView,
    InternalActivityTypeLinks, OrganizationView, PersonSortOption, PersonView, ProductLinkView, ProductUnit, ProductView, ProjectActivityTypeLinks,
    ProjectLinkView, ProjectType, ProjectView, TaggableType, TaskView, TicketLinkView, TicketView
} from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { isColorDark, setTimeToDate } from "../../Helpers/BasePageHelpers";
import ActivityHelper from "./ActivityHelper";
import client from "../../ApiClient/client";
import { SelectorInput } from "../Shared/SelectorInput";
import { HourCategorySelector } from "../HourCategories";
import { ProductSelector } from "../Products";
import { ProjectSelector } from "../Projects";
import { TaskSelector } from "../Tasks";
import { ActorWithCreateInput } from "../Actors";
import SelectorInformation from "../Shared/SelectorInformation";
import { TagSelector } from "../Tags";
import { Capabilities } from "../../Definitions/_capabilties";
import BaseForm, { FormType } from "../Shared/Form";
import DateSelector from "../Shared/DateSelector";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { Drawer } from "../Shared/Drawer";
import { PersonSelector } from "../People";
import TimeSelector from "../Shared/TimeSelector";
import EnumSelector from "../Shared/EnumSelector";
import { ActivityMold, CalendarResourceType } from "../../Models/CalendarModels";
import { TicketCreateForm, TicketSelector } from "../Tickets";



interface CreateActivityProps {
    activityMolds: Partial<ActivityMold>[];
    actorId: string;
    onClose: Function;
    onComplete?: (created: ActivityView[]) => void;
    calendarDate?: string;
    trackingForm?: boolean;
}

interface CreateActivityState {
    type: ActivityType;
    loading: boolean;
    saturdayIncluded: boolean;
    sundayIncluded: boolean;
    error: any;
    participants: string[];
    hasParticipantsAtRender: boolean;
    hasTicketAtRender: boolean;
    hasTagsAtRender: boolean;
    hasCustomerAtRender: boolean;
    displayMoreInfo: boolean;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    project: ProjectLinkView;
    task: TaskView;
    customer: ActorLinkView;
    category: CategoryLinkView;
    product: ProductLinkView;
    ticket: TicketLinkView;
    tagIds: string[];
    trackHourSuggestions: any[];
    loadingTackHourSuggestions: boolean;
    showCreateTicketDrawer: boolean;
    suggestToOthers: boolean;
    beforeOrganizerWarning: boolean;
    formHasChanged: boolean;
    participantsHasChanged: boolean;
    description: string;
}

enum PeriodStartEnd {
    Start = "start",
    End = "end"
}

export interface CreateActivity {
    description?: string | null;
    start: string;
    end?: string | null;
    tagIds?: string[] | null;
    participants?: string[] | null;
    suggestToOthers?: boolean;
    actorId?: string | null;
    callId?: string | null;
    copyNoteToCall?: boolean | null;
    groupId?: string | null;
    meetingId?: string | null;
    links: ProjectActivityTypeLinks | CustomerActivityTypeLinks | InternalActivityTypeLinks;
}

interface CreateActivityExtended extends CreateActivity {
    startTime: string;
    endTime: string;
    saturdayIncluded: boolean;
    sundayIncluded: boolean;
    updateSuggestion: boolean;
}

class ActivityCreateForm extends React.Component<CreateActivityProps, CreateActivityState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            type: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].type : null,
            loading: false,
            saturdayIncluded: false,
            sundayIncluded: false,
            error: null,
            participants: null,
            hasParticipantsAtRender: false,
            hasTicketAtRender: this.props.activityMolds?.length > 0 && this.props.activityMolds[0].ticket != null,
            hasTagsAtRender: this.props.activityMolds?.length > 0 && this.props.activityMolds[0].tags?.length > 0,
            hasCustomerAtRender: this.props.activityMolds?.length > 0 && this.props.activityMolds[0].relation != null,
            displayMoreInfo: false,
            startDate: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0]?.start ? this.props.activityMolds[0]?.start : this.props.calendarDate : null,
            endDate: this.props.activityMolds?.length > 0 ? this.props.activityMolds[this.props.activityMolds.length - 1].end ? this.props.activityMolds[this.props.activityMolds.length - 1].end : this.props.calendarDate : null,
            startTime: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].start : null,
            endTime: this.props.activityMolds?.length > 0 ? this.props.activityMolds[this.props.activityMolds.length - 1].end : null,
            project: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].project : null,
            task: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].task as TaskView : null,
            customer: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].relation : null,
            category: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].category : null,
            product: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].product : null,
            ticket: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].ticket : null,
            tagIds: this.props.activityMolds?.length > 0 && this.props.activityMolds[0].tags ? _.map(this.props.activityMolds[0].tags, tag => {
                if (tag.category?.deleted || tag?.deleted) return;
                return tag.id; 
            }) : [],
            trackHourSuggestions: null,
            loadingTackHourSuggestions: false,
            showCreateTicketDrawer: false,
            suggestToOthers: false,
            beforeOrganizerWarning: false,
            formHasChanged: false,
            participantsHasChanged: false,
            description: this.props.activityMolds?.length > 0 ? this.props.activityMolds[0].description : null
        }
    }

    componentDidMount = async () => {
        this.getTrackHourSuggestions();

        if (this.props.activityMolds?.length > 0) {
            const firstMold = this.props.activityMolds[0];

            const otherParticipants = [];
            const userIsNotOrganizer = firstMold.organizer != null && firstMold.organizer.contact != null && firstMold.organizer.contact.id != this.props.actorId;

            if (firstMold?.participants) {
                if (userIsNotOrganizer)
                    otherParticipants.push(firstMold.organizer.contact.id);

                _.each(firstMold.participants, participant => {
                    if (participant && participant.contact.id != this.props.actorId)
                        otherParticipants.push(participant.contact.id);
                });
            }

            this.setState({
                participants: otherParticipants,
                hasParticipantsAtRender: otherParticipants.length > 0,
            });

            const lastMold = this.props.activityMolds[this.props.activityMolds.length - 1];
            this.isWeekendIncluded(firstMold.start, lastMold.end);
        }
    }

    componentDidUpdate = (prevProps: CreateActivityProps) => {
        const prevActivity = prevProps.activityMolds?.length > 0 ? prevProps.activityMolds[0] : null;
        const newActivity = this.props.activityMolds?.length > 0 ? this.props.activityMolds[0] : null;

        if (!_.isEqual(newActivity, prevActivity)) {
            const lastMold = this.props.activityMolds?.length > 0 ? this.props.activityMolds[this.props.activityMolds.length - 1] : null;

            this.setState({
                startDate: newActivity.start ?? null,
                endDate: lastMold.end ?? null,
                startTime: newActivity.start ?? null,
                endTime: lastMold.end ?? null,
                project: newActivity?.project ?? null,
                task: newActivity.task as TaskView ?? null,
                customer: newActivity.relation ?? null,
                category: newActivity?.category ?? null,
                //productId: this.props.activity?.product?.id ?? null,
                ticket: newActivity?.ticket ?? null,
            });

            const start = newActivity != null ? moment(newActivity.start).format('YYYY-MM-DDTHH:mm:ss') : null;
            const end = newActivity != null ? newActivity.end == null ? moment().format('YYYY-MM-DDTHH:mm:ss') : moment(this.props.activityMolds[this.props.activityMolds.length - 1].end).format('YYYY-MM-DDTHH:mm:ss') : null;

            if (start && end)
                this.isWeekendIncluded(start, end);
        }
    }

    onAddActivity = async (tempReq: CreateActivityExtended) => {
        this.setState({ loading: true });

        if (this.props.actorId) {

            const start = this.state.startDate ? moment(this.state.startDate) : null;
            const end = this.state.endDate ? moment(this.state.endDate) : null;

            let dayDiffs = start && end
                ? Math.ceil(moment.duration(end.diff(start)).asDays())
                : 0;

            const lessThanADayAndOverMidnight = dayDiffs == 0 && start && end && start.dayOfYear() != end.dayOfYear();

            if (dayDiffs == 0)
                dayDiffs = 1;

            const activityTemplate = this.props.activityMolds[0];
            const countDate = setTimeToDate(moment(tempReq.start), this.state.startTime, "HH:mm");

            const links = ActivityHelper.formatRequestTypeFromRequest(tempReq);
            const startTime = moment(this.state.startTime);
            const endTime = this.state.endTime ? moment(this.state.endTime) : null;

            const activityResponses: ActivityView[] = [];

            if (links) {
                for (let i = 0; i < dayDiffs; i++) {
                    if (dayDiffs > 1 && !tempReq.saturdayIncluded && countDate.isoWeekday() == 6) {
                        countDate.add(1, "day");
                        continue;
                    }

                    if (dayDiffs > 1 && !tempReq.sundayIncluded && countDate.isoWeekday() == 7) {
                        countDate.add(1, "day");
                        continue;
                    }

                    const requestStartDate = (lessThanADayAndOverMidnight && start != null) || dayDiffs == 1 ? start != null ? start : countDate.clone() : countDate.clone();
                    const requestEndDate = (lessThanADayAndOverMidnight && end != null) || dayDiffs == 1 ? end : endTime != null ? countDate.clone() : null;

                    const requestStart = requestStartDate.clone().set({
                        hours: startTime.hour(),
                        minutes: startTime.minute()
                    });

                    let requestEnd = null;

                    if (requestEndDate) {
                        requestEnd = requestEndDate.clone().set({
                            hours: endTime.hour(),
                            minutes: endTime.minute()
                        });
                    }

                    try {
                        const activityReq: CreateActivity = {
                            actorId: this.props.actorId,
                            start: requestStart.format(),
                            end: requestEnd != null ? requestEnd.format() : null,
                            description: tempReq.description,
                            links: links,
                            tagIds: this.state.tagIds,
                            meetingId: activityTemplate?.meetingId ?? null,
                            groupId: activityTemplate?.groupId ?? null,
                            participants: this.state.participants?.length > 0 ? this.state.participants : null,
                            suggestToOthers: this.state.suggestToOthers,
                            callId: null
                        };

                        const activityResponse = links.type == ActivityType.Customer
                            ? await client.activities.createCustomerActivity(activityReq as CreateCustomerActivityWebRequest)
                                
                            : links.type == ActivityType.Project
                                ? await client.activities.createProjectActivity(activityReq as CreateProjectActivityWebRequest)
                                    
                                : links.type == ActivityType.Internal
                                    ? await client.activities.createInternalActivity(activityReq as CreateInternalActivityWebRequest)
                                        
                                    : null;

                        if (activityResponse) {
                            activityResponses.push(activityResponse.data);
                        }
                        if (this.props.onComplete) {
                            this.props.onComplete(activityResponses);
                        }
                    }
                    catch (error: any) {
                        this.setState({ error: error.error });
                    }
                    finally {
                        countDate.add(1, "day");
                    }
                }
            }
        }

        this.setState({ loading: false });
    }

    isWeekendIncluded = async (startDate, endDate) => {
        if (startDate && endDate) {
            let start = moment(startDate, "YYYY-MM-DDTHH:mm:ss");
            let end = moment(endDate, "YYYY-MM-DDTHH:mm:ss");

            if (!start.isValid() || !end.isValid()) {
                start = moment(startDate, "YYYY-MM-DDTHH:mm:ss");
                end = moment(endDate, "YYYY-MM-DDTHH:mm:ss");
            }
            const countDate = start.clone();

            let satIncluded = false;
            let sunIncluded = false;

            while (countDate.isSameOrBefore(end) && (!satIncluded || !sunIncluded)) {
                if (countDate.isoWeekday() == 6) {
                    satIncluded = true;
                }

                if (countDate.isoWeekday() == 7) {
                    sunIncluded = true;
                }
                countDate.add(1, "day");
            }

            this.setState({
                startDate: start.format(),
                endDate: end.format(),
                saturdayIncluded: satIncluded,
                sundayIncluded: sunIncluded
            });
        }
    }

    resetStateValues = () => {
        this.setState({
            project: null,
            task: null,
            customer: null,
            category: null,
            product: null,
            ticket: this.state.hasTicketAtRender ? this.state.ticket : null,
            //description: null
        });
    }

    onTypeChange = async (value: ActivityType) => {
        this.resetStateValues();
        this.setState({
            type: value,
            formHasChanged: true
        });
    }

    onTypeToggle = async (value) => {
        this.resetStateValues();

        this.setState({
            type: this.state.type == value ? null : value,
            formHasChanged: true
        });
    }

    onDateChange = (date, type: PeriodStartEnd) => {
        if (type == PeriodStartEnd.Start) {
            this.setState({
                startDate: date,
                formHasChanged: true
            });
            this.isWeekendIncluded(date, this.state.endDate);
        }
        else if (type == PeriodStartEnd.End) {
            this.setState({
                endDate: date,
                formHasChanged: true
            });
            this.isWeekendIncluded(this.state.startDate, date);
        }
    }

    onTimeChange = (time, type: PeriodStartEnd) => {
        if (type == PeriodStartEnd.Start) {
            this.setState({
                startTime: time,
                formHasChanged: true
            });
        }
        else if (type == PeriodStartEnd.End) {
            this.setState({
                endTime: time,
                formHasChanged: true
            });
        }
    }

    onProjectChange = (project: ProjectView) => {
        this.setState({
            project: project,
            task: null,
            customer: project?.customer ?? null,
            formHasChanged: true
        });
    }

    onTaskChange = (task: TaskView) => {
        this.setState({
            task: task,
            project: task?.project ?? null,
            customer: task?.projectOwner ?? null,
            category: task?.category ?? null,
            formHasChanged: true
        });
    }

    onCustomerChange = (customer: ActorView) => {
        this.setState({
            task: null,
            project: null,
            ticket: null,
            customer: customer,
            formHasChanged: true
        });
    }

    onCategoryChange = (category: HourCategoryView) => {
        this.setState({
            category,
            formHasChanged: true
        });
    }

    onTicketChange = (ticket: TicketView) => {
        this.setState({
            ticket: ticket,
            customer: ticket?.customer ?? this.state.customer,
            formHasChanged: true
        });
    }

    onTagsChange = (tagIds: string[]) => {
        this.setState({
            formHasChanged: true,
            tagIds
        });
    }

    onProductChange = (product: ProductView) => {
        this.setState({
            formHasChanged: true,
            product
        });
    }

    getTrackHourSuggestions = async () => {
        this.setState({ loadingTackHourSuggestions: true });

        try {
            const suggestionsResponse = await client.activities.getSuggestedActivities({
                personId: this.props.actorId ? this.props.actorId : this.context.user.actorId,
                to: this.state.endTime ?? null
            });

            const keys = {};
            _.forEach(suggestionsResponse.data, a => {
                switch (a.type) {
                    case ActivityType.Project:
                        if (a.task) {
                            var key = "project" + a.task.id;
                            var item = keys[key];
                            if (item) item.count++
                            else {
                                let text = "";
                                if (a.task.parents == null || a.task.parents.length == 0) {
                                    const projectAndTaskName = `${a.project.name} / ${a.task.name}`;
                                    text = a.relation ? a.relation.name + " / " + projectAndTaskName : projectAndTaskName;
                                }
                                else {
                                    text = a.relation ? `${a.relation.name} / ${a.project.name}` : `${a.project.name}`;
                                    if (a.task.parents != null) {
                                        for (let i = 0; i < a.task.parents.length; i++) {
                                            text += ` / ${a.task.parents[i].name} `;
                                        }
                                    }
                                    text += ` / ${a.task.name}`;
                                }

                                keys[key] = {
                                    count: 1,
                                    text: text,
                                    type: a.type,
                                    project: a.project,
                                    task: a.task,
                                    date: a.start,
                                }
                            }
                        }
                        break;

                    case ActivityType.Customer: {
                        if (a.category && a.relation) {
                            var key = "customer" + a.category.id + a.relation.id;
                            var item = keys[key];
                            if (item) item.count++
                            else {
                                keys[key] = {
                                    count: 1,
                                    text: `${a.relation.name} / ${a.category.name}`,
                                    type: a.type,
                                    category: a.category,
                                    customer: a.relation,
                                    parents: null,
                                    date: a.start,
                                }
                            }
                        }
                    }
                        break;

                    case ActivityType.Internal: {
                        if (a.category) {
                            var key = "internal" + a.category.id;
                            var item = keys[key];
                            if (item) item.count++
                            else {
                                keys[key] = {
                                    count: 1,
                                    text: a.category.name,
                                    type: a.type,
                                    category: a.category,
                                    date: a.start,
                                }
                            }
                        }
                    }
                }
            })

            const suggestions = _.map(keys, s => { return s; });

            let top = [];
            if (suggestions && suggestions.length) {

                top = suggestions.sort((a, b) =>
                    //@ts-ignore
                    (a.count > b.count) ? -1 : 1).slice(0, 8).map(async (item: any) => {
                        if (item.project) {
                            const task = (await client.tasks.getTaskById(item.task.id))?.data.view;
                            item.category = task?.category;
                        }

                        return item;
                    });

                await Promise.all(top).then(function (results) {
                    top = results.sort((a, b) => (a.date > b.date) ? -1 : 1);
                })
            }

            this.setState({ trackHourSuggestions: top });
        }

        catch (error) {

        }

        this.setState({ loadingTackHourSuggestions: false });
    }

    onSuggestionClick = (suggestion) => {
        if (suggestion.task != null && suggestion.task.parents != null) suggestion.task.parents = suggestion.task.parents.reverse();

        this.setState({
            project: suggestion.project,
            task: suggestion.task,
            category: suggestion.category,
            customer: suggestion.customer,
            type: suggestion.type as ActivityType,
            formHasChanged: true
        });
    }

    toggleCreateTicketDrawer = () => {
        this.setState({ showCreateTicketDrawer: !this.state.showCreateTicketDrawer });
    }

    onCompleteAddTicket = async (data) => {
        this.setState({
            ticket: data,
            formHasChanged: true
        });

        this.toggleCreateTicketDrawer();
    }

    onSuggestToOthersChange = (e) => {
        const checked = e.target.checked;
        const firstMold = this.props.activityMolds?.length > 0 ? this.props.activityMolds[0] : null;

        this.setState({
            suggestToOthers: checked,
            //updateParticipants: this.state.participantsHasChanged ?? false,
            beforeOrganizerWarning: checked && firstMold && firstMold.organizer && firstMold.organizer.contact && firstMold.organizer.contact.id !== this.props.actorId
        });
    }

    onParticipantsChange = (participants: string[]) => {
        this.setState({
            participants,
            participantsHasChanged: true
        });
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    render() {
        if (!this.props.activityMolds || this.props.activityMolds.length == 0) return "No activity found";

        const { type, project, task, customer, category, product, ticket, startTime, endTime, participants, tagIds } = this.state;
        const activity = this.props.activityMolds[0];

        let suggestions = [];
        if (type == null) {
            suggestions = _.map(this.state.trackHourSuggestions, s => {
                let color = "#F5F9F7";

                if (s.category && s.category.color) color = s.category.color;

                return (<div className={isColorDark(color) ? `suggestion color-light` : `suggestion color-dark`} key={Math.random()} style={{ backgroundColor: color }} onClick={() => this.onSuggestionClick(s)}>
                    <div>{_.upperFirst(_.toLower(s.type))}</div>
                    <div>{s.text}</div>
                </div>);
            });
        }

        const workCategory = this.state.category
            ? this.state.category as HourCategoryLinkView
            : null;

        let taskParentPath = null;
        if (task && task.parents != null && task.parents.length > 0) {
            taskParentPath = "";
            _.each(this.state.task.parents.reverse(), (p) => {
                taskParentPath += `${p.name} / `
            });
        }

        const categorySelector = (
            <SelectorInput
                param="categoryId"
                required
                title="Category"
                selector={
                    <HourCategorySelector
                        filters={{
                            types: type == ActivityType.Internal ? [HourCategoryType.Work, HourCategoryType.Absence] : [HourCategoryType.Work],
                            activityType: type
                        }}
                        onObjectChange={this.onCategoryChange}
                    />
                }
            />
        );

        const productSelector = (
            <SelectorInput
                param="productId"
                title="Product"
                optional
                shouldUpdate
                className="product-override"
                selector={
                    <ProductSelector
                        placeholder={
                            task && task.category && task.category.product
                                ? task.category.product.name
                                : workCategory && workCategory.product
                                    ? workCategory.product.name
                                    : "Select product..."}
                        filters={{ units: [ProductUnit.Hour] }}
                        onObjectChange={this.onProductChange}
                    />
                }
            />
        );

        let formItems = null;

        if (this.state.type == ActivityType.Project) {
            formItems = (
                <React.Fragment>
                    <SelectorInput
                        param="projectId"
                        title="Project"
                        required
                        selector={
                            <ProjectSelector
                                onObjectChange={this.onProjectChange}
                                filters={{
                                    isActive: true,
                                    isEnded: false
                                }}
                            />
                        }
                    />
                    <SelectorInput
                        shouldUpdate
                        param="taskId"
                        title="Project task"
                        required
                        warningMessage="Choose a task"
                        selector={
                            <TaskSelector
                                title={taskParentPath}
                                filters={{
                                    showAll: true,
                                    closed: false,
                                    projectId: project?.id ?? null,
                                    projectActive: true,
                                    isContainer: false
                                }}
                                onObjectChange={this.onTaskChange}
                            />
                        }
                    />

                    {
                        this.state.project && this.state.project.type == ProjectType.Hourly
                            ? productSelector
                            : null
                    }
                </React.Fragment>
            );
        }
        else if (this.state.type == ActivityType.Customer) {
            formItems = (
                <React.Fragment>
                    <ActorWithCreateInput
                        selectorTitle="Customer"
                        param="customerId"
                        types={[ActorType.Organization, ActorType.Person]}
                        onActorChange={this.onCustomerChange}
                        disabled={this.state.hasCustomerAtRender && this.state.hasTicketAtRender}
                        required
                        selectorPlaceholder="Choose customer"
                        addText="Add customer"
                        renderOptionItem={(actor: ActorView) => {
                            if (actor.tags && actor.tags.length > 0) {
                                const tagNames = _.map(actor.tags, t => { return t.name });
                                return <SelectorInformation displayText={actor.name} hoverText={_.join(tagNames, ', ')} />
                            }
                            return actor.name;
                        }}
                        renderInfo={(entities: ActorView[]) => {

                            if (!entities || entities.length == 0)
                                return null;
                            const customerinfo
                                = entities[0].actorType === ActorType.Person ? (entities[0] as PersonView).customerInfo
                                : entities[0].actorType === ActorType.Organization ? (entities[0] as OrganizationView).customerInfo
                                : null;

                            if (!customerinfo)
                                return <Alert type="info" message={"This organization/person is not registered as a customer"} showIcon className="warning-text" />;

                            return null;

                        }}
                        filters={{
                            //sortBy: ActorSortOption.Name,
                            sortBy: ActorSortOption.Type,
                            //invoicableOrOrganization: true,
                            //isCustomer: true
                        }}
                    />

                    {categorySelector}

                    {productSelector}
                </React.Fragment>
            );
        }
        else if (this.state.type == ActivityType.Internal) {
            formItems = categorySelector;
        }



        const ticketInput = (
            <SelectorInput
                param="ticketId"
                title={
                    
                        <React.Fragment>
                            <span style={{ width: '100%' }}>Ticket {this.state.hasTicketAtRender ? null : "(optional)"}</span>
                            {this.state.hasTicketAtRender ? null : (
                                <Button type="link" onClick={this.toggleCreateTicketDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                                    + Create new ticket
                                </Button>
                            )}
                        </React.Fragment>
                }
                optional={false}
                shouldUpdate
                selector={
                    <TicketSelector
                        onObjectChange={this.onTicketChange}
                        disabled={this.state.hasTicketAtRender}
                        filters={{
                            closed: false,
                            customerId: customer?.id ?? null
                        }}
                    />
                }
            />
        );

        const tagInput = (
            <SelectorInput
                param="tagIds"
                title="Tags"
                selector={
                    <TagSelector
                        multiple
                        filters={{
                            taggableTypes: [TaggableType.Activity]
                        }}
                        onChange={this.onTagsChange}
                    />
                }
            />
        );

        const suggestionInput = (
            <React.Fragment>
                <SelectorInput
                    param="participants"
                    title="Other participants"
                    className="participant-input suggestion-input"
                    selector={
                        <PersonSelector
                            multiple
                            filters={{
                                deleted: false,
                                sortBy: PersonSortOption.Name
                            }}
                            onChange={this.onParticipantsChange}
                            disableOptionIds={activity.organizer?.contact ? [activity.organizer.contact.id] : null}
                            exlcludeOptionIds={this.props.actorId ? [this.props.actorId] : null}
                            placeholder="Select participants"
                        />
                    }
                />

                <Form.Item name="suggestToOthers" valuePropName="checked" className="checkbox-input">
                    <Checkbox
                        onChange={this.onSuggestToOthersChange}
                        disabled={((activity.resourceType == CalendarResourceType.Activity || activity.resourceType == CalendarResourceType.Meeting) && (this.state.participants == null || this.state.participants.length == 0))
                            || (activity.resourceType == CalendarResourceType.Suggestion && !this.state.formHasChanged)
                        }
                        checked={this.state.suggestToOthers}
                    >
                        {
                            activity.resourceType == CalendarResourceType.Suggestion && this.state.formHasChanged
                                ? "Send updated time tracking suggestion to internal participants"
                                : "Send time tracking suggestion to internal participants"
                        }
                    </Checkbox>
                </Form.Item>
            </React.Fragment>
        );

        const moreInfoContent = (
            <React.Fragment>

                {
                    this.state.type != null && this.context.user.hasCapability(Capabilities.TicketsReadRelated) && !this.state.hasTagsAtRender && !this.state.hasTicketAtRender
                        ? ticketInput
                        : null
                }

                {
                    this.state.hasTagsAtRender
                        ? null
                        : tagInput
                }

                {this.state.hasParticipantsAtRender
                    ? null
                    : suggestionInput}
            </React.Fragment>
        );

        const hasWrite = (this.context.user && this.context.user.hasCapability(Capabilities.ActivitiesWriteActor))
            || (!activity && this.context.user && this.context.user.hasCapability(Capabilities.ActivitiesWriteActor))
            || ((activity && activity.actor && activity.actor.id == this.context.user?.actorId && this.context.user?.hasCapability(Capabilities.ActivitiesWriteActor)));

        return (
            <div>
                <BaseForm
                    type={FormType.Create}
                    loading={this.state.loading}
                    error={this.state.error}
                    onSubmit={hasWrite
                        ? this.onAddActivity
                        : null}
                    onCancel={() => { this.props.onClose() }}
                    disableSubmit={!type}
                    className="activity-edit-or-create-form activity-from-event-form"
                    customSubmit={this.props.trackingForm ? <Button loading={this.state.loading} disabled={task == null && category == null} type="primary" htmlType="submit" size="large" icon={<PlayCircleOutlined />}>Start</Button> : null}
                    initialValues={{
                        start: this.state.startDate,
                        end: this.state.endDate,
                        startTime: startTime ? moment(startTime).format('HH:mm') : null,
                        endTime: endTime ? moment(endTime).format('HH:mm') : null,
                        taskId: task?.id ?? null,
                        projectId: project?.id ?? null,
                        customerId: customer?.id ?? null,
                        categoryId: category?.id ?? null,
                        ticketId: ticket?.id ?? null,
                        productId: product?.id ?? null,
                        description: this.state.description,
                        participants: participants,
                        tagIds: tagIds,
                        //saturdayIncluded: this.state.saturdayIncluded,
                        //sundayIncluded: this.state.sundayIncluded,
                        suggestToOthers: this.state.suggestToOthers,
                        updateSuggestion: false,
                        updateParticipants: false,
                    } as Partial<CreateActivityExtended>}
                >
                    {
                        this.props.trackingForm ? null :
                            <React.Fragment>
                                <div className="ant-form-item-label" key="start-header"><label>Start</label></div>
                                <div className="start-selectors" key="start-selectors">
                                    <SelectorInput
                                        param="startTime"
                                        required
                                        warningMessage="Start time is required"
                                        selector={
                                            <TimeSelector
                                                className="startTime"
                                                onObjectChange={(obj) => this.onTimeChange(obj, PeriodStartEnd.Start)}
                                                disableClear
                                            />
                                        }
                                    />
                                    <SelectorInput
                                        param="start"
                                        key="start"
                                        required
                                        warningMessage="Start date is required"
                                        selector={
                                            <DateSelector
                                                className="start"
                                                onObjectChange={(date) => { this.onDateChange(date, PeriodStartEnd.Start) }}
                                                disableClear
                                                value={this.state.startDate}
                                            />
                                        }
                                    />
                                </div>
                                <div className="ant-form-item-label" key="end-header"><label>End</label></div>
                                <div className="end-selectors" key="end-selectors">
                                    <SelectorInput
                                        param="endTime"
                                        required
                                        warningMessage="End time is required"
                                        selector={
                                            <TimeSelector
                                                className="endTime"
                                                onObjectChange={(obj) => this.onTimeChange(obj, PeriodStartEnd.End)}
                                                disableClear
                                            />
                                        }
                                    />
                                    <SelectorInput
                                        param="end"
                                        key="end"
                                        selector={
                                            <DateSelector
                                                className="end"
                                                onObjectChange={(date) => { this.onDateChange(date, PeriodStartEnd.End) }}
                                                disableClear
                                                //value={this.state.endDate}
                                            />
                                        }
                                    />
                                </div>

                                {this.props.activityMolds?.length > 1 && (this.state.saturdayIncluded || this.state.sundayIncluded)
                                    ?
                                    <div className="weekend-checkboxes" key="weekends">
                                        {this.state.saturdayIncluded ?
                                            <Form.Item name="saturdayIncluded" key="saturday" valuePropName="checked">
                                                <Checkbox>Include Saturday</Checkbox>
                                            </Form.Item> : null}
                                        {this.state.sundayIncluded ?
                                            <Form.Item name="sundayIncluded" key="sunday" valuePropName="checked">
                                                <Checkbox>Include Sunday</Checkbox>
                                            </Form.Item> : null}
                                    </div>
                                    : null
                                }
                            </React.Fragment>
                    }

                    <EnumSelector
                        enum={ActivityType}
                        title="Select type of work"
                        placeholder="Select activity type..."
                        type="radio"
                        onChange={this.onTypeChange}
                        onToggle={this.onTypeToggle}
                        value={type}
                        className="activity-type"
                        wrappedFormTitle
                        excluded={[
                            !this.context.user.hasCapability(Capabilities.ProjectsRead) && ActivityType.Project,
                            !this.context.user.hasAnyCapability([Capabilities.OrganizationsRead, Capabilities.PeopleRead]) && ActivityType.Customer,
                            !this.context.user.hasAnyCapability([Capabilities.ActivitiesWrite, Capabilities.ActivitiesWriteActor]) && ActivityType.Internal
                        ]}
                    />

                    {type != null ?
                        <React.Fragment>
                            {formItems}

                            <div className="ant-form-item-label description-header"><label>Description {type == ActivityType.Internal || (this.state.project && this.state.project.type == "Internal") ? "(optional)" : null}</label></div>
                            <div className="ant-form-item-label">{workCategory?.informationText}</div>
                            <TextAreaInput
                                shouldUpdate
                                param="description"
                                required={type == ActivityType.Internal || (project && project.type == "Internal") ? false : true}
                                warningMessage="Please provide a description"
                                placeholder="Description"
                                rows={3}
                                onChange={(updDesc) => this.setState({ description: updDesc })}
                            />
                        </React.Fragment>
                        : null}

                    {
                        type != null && this.state.hasTicketAtRender
                            ? ticketInput
                            : null
                    }

                    {
                        type != null && this.state.hasTagsAtRender
                            ? tagInput
                            : null
                    }

                    {
                        type != null && this.state.hasParticipantsAtRender
                            ? suggestionInput
                            : null
                    }

                    {
                        type != null
                            ?
                            <React.Fragment>
                                <div className={`ant-form-item-label add-more-info-header ${this.state.hasParticipantsAtRender ? "participants-at-render" : ""} ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}>
                                    <div className="more-info-label">
                                        <label>
                                            Additional info (optional)
                                            <div className="more-info-icon-container">{this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                {this.state.displayMoreInfo ? <div className="more-info-content">{moreInfoContent}</div> : null}
                            </React.Fragment>
                            : null
                    }

                    {
                        type != null && this.state.beforeOrganizerWarning
                            ? <Alert className="before-organizer-warning" showIcon type="warning" message="You are not the organizer" description="By proceeding you will send out a time tracking suggestion to all internal participants" />
                            : null
                    }
                    {
                        type == null
                            ? (
                                <React.Fragment>
                                    {<div className="ant-drawer-title suggestions-title">or select a recent hour (optional)</div>}
                                    {this.state.loadingTackHourSuggestions ? <Loading3QuartersOutlined spin /> : suggestions?.length == 0 ? "No recent hours found" : <div className="suggestions-container">{suggestions}</div>}
                                </React.Fragment>
                            )
                            : null
                    }
                </BaseForm>

                <Drawer
                    title="Create ticket"
                    onClose={this.toggleCreateTicketDrawer}
                    open={this.state.showCreateTicketDrawer}
                    component={
                        <TicketCreateForm
                            onComplete={this.onCompleteAddTicket}
                            onCancel={this.toggleCreateTicketDrawer}
                            filters={{
                                customerId: customer?.id ?? null,
                                requesterId: this.props.actorId
                            }}
                        />
                    }
                />
            </div>);
    }
}

export default ActivityCreateForm;