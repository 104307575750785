
interface CallAnimationProps {
    animate: boolean;
    className?: string;
}

//https://codepen.io/callmenick/pen/XdwmeV


export function CallAnimation({ animate, className }: CallAnimationProps) {
    return (
        <span className="phone-vibrating-animation">
            <span className={`phone ${animate ? 'phone-animation' : ""} ${className ?? ""} `}></span>
        </span>
    )
}