import React from 'react';
import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';


export interface CustomRouter {
    location: Location,
    navigate: NavigateFunction,
    params: Readonly<Params<string>>;
}

export function withRouter<ComponentProps>(Component: React.ComponentClass<ComponentProps>) {
    function ComponentWithRouterProp(props: ComponentProps) {
        const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

        return <Component {...props} router={{ location, navigate, params } as CustomRouter} />;
    }

    return ComponentWithRouterProp;
}