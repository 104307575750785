import _ from 'lodash';
import moment from 'moment';
import { Button, Card } from "antd";
import ListViewCard from "../Shared/ListViewCard";
import { TaskView, PagedTaskQuery } from "../../ApiClient/swagger/data-contracts";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from '../../ApiClient/client';
import { updateCollectionWithEvent } from '../../Helpers/BasePageHelpers';
import { ActorLink } from '../Actors';
import { formatDuration } from '../../Helpers/Formatters';
import ProgressBar from '../Shared/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { ProjectLink } from '../Projects';
import { RequireCapability } from '../Shared/RequireCapability';
import { addChangeVectorHeader } from '../../Helpers/RequestHelpers';
import { Capabilities } from '../../Definitions/_capabilties';


interface TaskWidgetProps {
    title: any;
    className?: string;
    query: Partial<PagedTaskQuery>;
    exclutions?: string[];
    onItemCreatedEvent?: (collection: TaskView[], task: TaskView) => Promise<TaskView[]>;
    onItemUpdatedEvent?: (collection: TaskView[], task: TaskView) => Promise<TaskView[]>;
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    //formFilters?: Partial<PagedTaskQuery>;
}

export function TaskWidget(props: TaskWidgetProps) {

    const [collection, setCollection] = useState<TaskView[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [loadingClosedIds, setLoadingClosedIds] = useState<string[]>([]);

    //const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

    const context = useContext(AppContext);
    const navigate = useNavigate();

    async function loadCollection() {
        setLoading(true);

        const response = await client.tasks
            .queryTasks(props.query)
            .catch(exception => setError(exception.error));

        if (response) setCollection(response.data.items)

        setLoading(false);
    }

    async function onItemCreatedEvent(response: TaskView) {
        if (!response || !props.onItemCreatedEvent) return;
        const newCollection = await props.onItemCreatedEvent(collection?.slice() ?? [], response);
        setCollection(newCollection);
    }

    async function onItemUpdatedEvent(response: TaskView) {
        if (!response) return;

        if (props.onItemUpdatedEvent) {
            const updatedCollection = await props.onItemUpdatedEvent(collection?.slice() ?? [], response);
            setCollection(updatedCollection);
        }
        else {
            setCollection(prevCollection => {
                const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], response);
                return newCollection;
            });
        }
    }

    async function closeTask(event, task: TaskView) {
        event.stopPropagation();

        const newLoadingClosedIds = loadingClosedIds?.slice() ?? [];

        if (!newLoadingClosedIds.includes(task.id)) {
            newLoadingClosedIds.push(task.id)
            setLoadingClosedIds(newLoadingClosedIds);
        }

        const response = await client.tasks.closeTask(task.id, addChangeVectorHeader(task.changeVector));

        if (response) {
            setCollection(prevCollection => {
                const newCollection = prevCollection?.slice() ?? [];
                const index = _.findIndex(newCollection, c => c.id == response.data.id);
                if (index == -1) return prevCollection;
                newCollection[index] = response.data;

                return newCollection;
            });
        }

        if (newLoadingClosedIds.includes(task.id)) {
            newLoadingClosedIds.splice(0, 1);
            setLoadingClosedIds(newLoadingClosedIds);
        }
    }

    useEffect(() => {
        if (!collection) loadCollection();

        context.events.tasks.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent
        });

        return () => {
            context.events.tasks.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent
            });
        }
    }, [])

    const baseColumns = _.reject(props.customColumns ?? [
        {
            title: 'Task name',
            render: (task: TaskView) => task.name,
            key: 'name'
        },
        {
            title: 'Project',
            render: (task: TaskView) => task.project ? <ProjectLink {...task.project} /> : "",
            key: 'project',
            ellipsis: true
        },
        {
            title: 'Task owner',
            render: (task: TaskView) => task.owner ? <ActorLink {...task.owner} /> : "",
            key: 'owner',
            ellipsis: true
        },
        {
            title: 'Assignee',
            render: (task: TaskView) => task.assignee ? <ActorLink {...task.assignee} /> : "",
            key: 'assignee',
            ellipsis: true
        },
        {
            title: 'Planned end',
            render: (task: TaskView) => task.plannedEnd ? moment(task.plannedEnd).format('DD.MM.YYYY') : "-",
            key: 'end',
            width: 100,
        },
        {
            title: '',
            key: 'duration',
            render: (task: TaskView) => {
                const aggregatedDuration = task.aggregatedDuration ?? 0;
                return formatDuration(aggregatedDuration);
            },
            align: 'right',
            width: 90
        },
        {
            title: 'Progress',
            key: 'progress',
            render: (task: TaskView) => {
                const aggregatedEstimate = task.aggregatedEstimate ?? 0;
                const aggregatedDuration = task.aggregatedDuration ?? 0;

                return aggregatedEstimate != null && aggregatedEstimate > 0 ?
                    <ProgressBar hideDurationText estimate={aggregatedEstimate} duration={aggregatedDuration} complete={task.actualEnd != null} /> : "";
            }
        },
        {
            title: 'Estimate',
            render: (task: TaskView) => {
                const aggregatedEstimate = task.aggregatedEstimate ?? 0;
                if (aggregatedEstimate != null && aggregatedEstimate > 0)
                    return formatDuration(task.aggregatedEstimate ?? 0);
                else
                    return null;
            },
            key: 'estimate',
            width: 100
        },
        {
            title: 'Closed',
            key: 'close',
            render: (task: TaskView) => task.actualEnd != null
                ? moment(task.actualEnd).format('DD.MM.YYYY')
                : <RequireCapability capability={Capabilities.ProjectsRoleBased}><Button onClick={(e) => closeTask(e, task)} loading={loadingClosedIds.includes(task.id)}>Close</Button></RequireCapability>,
            width: 90
        }
    ], c => _.find(props.exclutions, e => { return c.key == e }) != null);

    if (error) return <Card title={props.title}>{error}</Card>;

    return (
        <>
            <ListViewCard
                title={props.title}
                columns={baseColumns}
                data={collection}
                loading={loading}
                onSelect={(data: TaskView) => navigate(`/tasks/${data.id}`)}
                className="task-widget"
                //actions={context.user.hasCapability(Capabilities.TasksWrite) ? <Button key="add" size="small" onClick={() => setShowCreateDrawer(previous => !previous)}>Add task</Button> : null}
                {...props}
            />

            {/*<Drawer
                title="Create task"
                onClose={() => setShowCreateDrawer(false)}
                open={showCreateDrawer}
                component={
                    <TaskCreateForm
                        filters={props.query}
                        onCancel={() => setShowCreateDrawer(false)}
                        onComplete={() => setShowCreateDrawer(previous => !previous)}
                    />
                }
            />*/}
        </>
    );
}