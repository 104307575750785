import * as React from "react";
import { Form, InputNumber, Input } from "antd";

export interface NumberInputProps {
    placeholder?: string,
    required?: boolean,
    warningMessage?: string,
    param: string,
    title?: string,
    value?: number,
    disabled?: boolean,
    footnote?: string
}

interface NumberInputState {
    value?: number;
}

export class PriceInput extends React.Component<NumberInputProps, NumberInputState> {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value ?? 0
        }
    }
    onChange = (event) => {
        const value = event.target.value;

        if (value)
            this.setState({ value });
        else
            this.setState({ value: 0 });
    }

    render = () => {
        const { param, required, warningMessage, placeholder } = this.props;

        const optional = !this.props.required ? " (optional)" : "";
        const title = this.props.title ? <div>{this.props.title} {optional}</div> : null;

        return (
            <Form.Item
                label={title}
                name={param}
                help={ this.props.footnote }
                rules={
                    [{
                        required: required,
                        message: warningMessage ? warningMessage : 'Please type a value for this field'
                    }]}
            >
                <Input
                    value={this.state.value}
                    onChange={this.onChange}
                    disabled={ this.props.disabled }
                    type="number"
                    min={0}
                    step={ 0.01 }
                    placeholder={placeholder}
                    size="middle"
                    style={{
                        width: "100%"
                    }}
                    addonAfter="kr"
                />
            </Form.Item>
        );
    }
}