import _ from 'lodash';
//import moment from 'moment';

const formatDuration = (duration, includeMinutes?: boolean) => {
    if (duration === null || duration === undefined || duration === "") return "";
    if (!duration) duration = 0;

    if (duration == 0) return "0h 0m";

    let isNegative = false;
    if (duration < 0) {
        isNegative = true;
        duration = duration * (-1);
    }

    const hours = Math.floor(duration / (60 * 60 * 1000));
    const minutes = Math.floor((duration - hours * 60 * 60 * 1000) / (60 * 1000));
    const seconds = Math.floor((duration - (hours * 60 * 60 * 1000) - (minutes * 60 * 1000)) / 1000);

    const out = [];
    if (hours > 0)
        out.push(hours + "h");
    if (minutes > 0 || includeMinutes)
        out.push(minutes + "m");
    if (hours == 0)
        out.push(seconds + "s");

    let formatted = out.join(' ');
    if (isNegative)
        formatted = "-" + formatted;

    return formatted;
}

const roundToNearestHour = (duration) => {
    if (duration === null || duration === undefined || duration === "") return "";
    if (!duration) duration = 0;

    let hours = Math.floor(duration / (60 * 60 * 1000));
    const minute = Math.floor((duration - hours * 60 * 60 * 1000) / (60 * 1000));
    const roundedMinute = Math.round(minute / 60) * 60;

    if (roundedMinute == 60)
        hours += 1;

    return hours + "h";
}

const formatEllipsis = (text: string, maxLength?: number) => {
    if (!text) return "";
    if (!maxLength)
        maxLength = 400;

    if (text.length > maxLength)
        return text.substring(0, maxLength) + "...";
    else
        return text;
}

const formatCurrency = (price: number | string, fractionDigits?: number, showCurrency?: boolean) => {
    const formatOptions: Intl.NumberFormatOptions = {
        currency: 'NOK',
        minimumFractionDigits: fractionDigits ?? 0,
        maximumFractionDigits: fractionDigits ?? 0,        
    };

    if (showCurrency) {
        formatOptions.style = "currency";
        formatOptions.currencyDisplay = "narrowSymbol"
    }

    const formatter = new Intl.NumberFormat('no', formatOptions);

    const formattedPrice = typeof price == "number" ? price : parseInt(price);
    const value = formatter.format(formattedPrice);

    return value;
}

const formatNumber = (number: number, fractionDigits?: number) => {
    const formatter = new Intl.NumberFormat('no', {
        minimumFractionDigits: fractionDigits ?? 0,
        maximumFractionDigits: fractionDigits ?? 0
    });

    const value = formatter.format(number);
    return value;
}

const capitalizeFirstLetter = (word: string) => {
    if (!word) return "";

    return word.charAt(0).toUpperCase() + word.slice(1);
}

const capitalizeEveryWord = (sentence: string) => {
    if (!sentence) return "";

    const words = sentence.split(" ");

    const newSentence = _.map(words, word => {
        if (word.toLowerCase() == "as")
            return "AS";
        else
            return capitalizeFirstLetter(word.toLowerCase());
    });

    return newSentence.join(" ");
}

const displayFriendlyKey = (key: string) => {
    const parts = key.split("_");
    const res = [capitalizeFirstLetter(parts[0])];
    for (let i = 1; i < parts.length; i++) {
        res.push(parts[i]);
    }
    return res.join(" ");
}

const formatMonthNr = (monthNr: number) => {
    if (!monthNr) return null;

    let nr = monthNr;

    if (typeof nr == "string") {
        try {
            nr = parseInt(nr);
        }
        catch (error) {
            return null;
        }
    }
        
    let month = null;

    switch (nr) {
        case 1:
            month = "January";
            break;
        case 2:
            month = "February";
            break;
        case 3:
            month = "March";
            break;
        case 4:
            month = "April";
            break;
        case 5:
            month = "May";
            break;
        case 6:
            month = "June";
            break;
        case 7:
            month = "July";
            break;
        case 8:
            month = "August";
            break;
        case 9:
            month = "September";
            break;
        case 10:
            month = "October";
            break;
        case 11:
            month = "November";
            break;
        case 12:
            month = "December";
            break;
    }

    return month;

}

const msToDays = (ms: number) => Math.floor(ms / 1000 / 60 / 60 / 24);

const daysToMs = (days: number) => days * 1000 * 60 * 60 * 24;

const hoursToMs = (hours: number) => hours * 1000 * 60 * 60;

export {
    formatDuration,
    roundToNearestHour,
    formatEllipsis,
    formatCurrency,
    capitalizeFirstLetter,
    capitalizeEveryWord,
    formatMonthNr,
    formatNumber,
    displayFriendlyKey,
    msToDays,
    daysToMs,
    hoursToMs
};