import * as React from "react";
import _ from 'lodash';
import { Button } from "antd";
import { ApplicationView, CreateOrUpdateRole, RoleView } from "../../ApiClient/swagger/data-contracts";
import BaseForm, { FormType } from "../Shared/Form";
import client from "../../ApiClient/client";
import AppContext from "../../Definitions/AppContext";
import CapabilitiesEditor, { CapabilityGroupView, CapabilitySubGroupView, CapabilityView } from "./CapabilitiesEditor";
import { TextInput } from "../Shared/TextInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { ApplicationSelector } from "../Applications";
import { CheckboxGroupInput } from "../Shared/CheckboxGroupInput";
import { Drawer } from "../Shared/Drawer";
import RoleSelector from "./RoleSelector";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { Capabilities } from "../../Definitions/_capabilties";


interface EditRoleProps {
    onComplete?: (action: 'updated' | 'deleted' | 'restored', role: RoleView) => void;
    role: RoleView;
    goBack: () => void;
}

interface EditRoleState {
    loading: boolean;
    error: string | null;

    application: ApplicationView;
    groupedApplicationCapabilities: CapabilityGroupView[];

    import_showDrawer: boolean;
    import_selectedRole: RoleView;

    initialCapabilities: string[];
}

enum RoleEditFormPromptEvents {
    Change = "roleeditformchange",
    Clear = "roleeditformclear"

}

export class RoleEditForm extends React.Component<EditRoleProps, EditRoleState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: true,
            error: null,

            application: null,
            groupedApplicationCapabilities: null,

            import_showDrawer: false,
            import_selectedRole: null,

            initialCapabilities: this.props.role.capabilities,
        }
    }

    componentDidMount = async () => {
        this.getApplication(this.props.role.application.id);
    }

    getApplication = async (applicationId: string) => {
        this.setState({ loading: true });

        const response = await client.applications
            .getApplicationById(applicationId)
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) {
            const groupedCapabilities = _.groupBy(response.data.view?.capabilities?.app ?? [], c => c.group);

            const capabilityGroupView: CapabilityGroupView[] = _.map(groupedCapabilities, (capabilities, title) => {

                const groupedSubCapabilities = _.groupBy(capabilities, c => c.subGroup ?? "");

                return {
                    name: title ?? null,
                    hasRequirements: _.filter(capabilities, c => c.requires?.length > 0) != null,
                    subGroups: _.map(groupedSubCapabilities, (subCapabilities, subKey) => {
                        return {
                            name: subKey ?? null,
                            hasRequirements: _.filter(subCapabilities, c => c.requires?.length > 0) != null,
                            capabilities: _.map(subCapabilities, capability => {
                                return {
                                    id: capability.id,
                                    name: capability.name,
                                    description: capability.description,
                                    includes: capability.includes,
                                    requires: capability.requires
                                } as CapabilityView
                            })


                        } as CapabilitySubGroupView
                    })
                } as CapabilityGroupView
            });

            this.setState({
                groupedApplicationCapabilities: capabilityGroupView,
                
                application: response ? response.data.view as ApplicationView : null
            });
        }

        this.setState({ loading: false });
    }

    clearPrompt = () => {
        window.dispatchEvent(new Event(RoleEditFormPromptEvents.Clear));
    }

    markPrompt = () => {
        window.dispatchEvent(new Event(RoleEditFormPromptEvents.Change));
    }

    onSubmit = async (request: CreateOrUpdateRole) => {
        try {

            this.setState({ loading: true });

            const response = await client.roles
                .updateRole(this.props.role.id, request, addChangeVectorHeader(this.props.role.changeVector));

            this.setState({ loading: false });
            this.clearPrompt();
            this.props.onComplete('updated', response.data);
            this.setState({ initialCapabilities: response.data.capabilities });
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onDelete = async () => {
        if (this.props.role != null) {
            try {
                this.setState({ loading: true });
                const response = await client.roles.deleteRole(this.props.role.id, addChangeVectorHeader(this.props.role.changeVector));
                this.setState({ loading: false });
                this.props.onComplete('deleted', response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onRestore = async () => {
        if (this.props.role != null) {
            try {
                this.setState({ loading: true });
                const response = await client.roles.restoreRole(this.props.role.id, addChangeVectorHeader(this.props.role.changeVector));
                this.setState({ loading: false });
                this.props.onComplete('restored', response.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onCancel = () => {
        if (this.props.goBack) {
            this.props.goBack();
        }
    }

    toggleImportPermissions = () => {
        this.setState({ import_showDrawer: !this.state.import_showDrawer });
    }

    onImportRoleChange = (importedRole: RoleView) => {
        this.setState({ import_selectedRole: importedRole });
    }

    onImportPermissions = () => {
        const existingCapabilities = this.state.initialCapabilities;
        const importedCapabilities = this.state.import_selectedRole?.capabilities ?? [];

        const newCapabilities = _.uniq([...existingCapabilities, ...importedCapabilities]);

        this.setState({
            initialCapabilities: newCapabilities,
            import_selectedRole: null,
            import_showDrawer: false
        });

        this.markPrompt();
    }

    render = () => {
        const { application, groupedApplicationCapabilities } = this.state;

        const availableCapabilities: CapabilityGroupView[] = [];

        const hasWriteAccess = this.context.user.hasCapability(Capabilities.RolesWrite);

        const appCapabilities = groupedApplicationCapabilities?.slice() ?? [];

        if (appCapabilities?.length > 0 && application?.id === this.context.applicationId) {

            const files = _.find(appCapabilities, g => g.name === "Documents (specific location)");
            if (files) {
                _.remove(appCapabilities, c => c.name === "Documents (specific location)");
                availableCapabilities.push(files);
            }

            const notes = _.find(appCapabilities, g => g.name === "Notes and comments");
            if (notes) {
                _.remove(appCapabilities, c => c.name === "Notes and comments");
                availableCapabilities.push(notes);
            }

            const menu = _.find(appCapabilities, g => g.name === "Menu");
            if (menu) {
                _.remove(appCapabilities, c => c.name === "Menu");
                availableCapabilities.push(menu);
            }
        }

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={hasWriteAccess ? this.onSubmit : null}
                onDelete={hasWriteAccess ? this.onDelete : null}
                onRestore={hasWriteAccess ? this.onRestore : null}
                onCancel={hasWriteAccess ? this.onCancel : null}
                isDeleted={this.props.role.deleted}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    name: this.props.role.name,
                    description: this.props.role.description,
                    capabilities: this.state.initialCapabilities,
                    applicationId: this.state.application?.id ?? this.props.role.application?.id ?? null
                }}
            >
                <div className="field-inputs">
                    <TextInput
                        param="name"
                        required
                        warningMessage="Please type a name"
                        placeholder="Name"
                        title="Name"
                        onObjectChange={() => { this.markPrompt() }}
                        disabled={!hasWriteAccess}
                    />

                    <SelectorInput
                        param="applicationId"
                        title="Where should the role apply?"
                        required
                        selector={
                            <ApplicationSelector
                                placeholder="Select application"
                                disabled
                            //onObjectChange={this.onApplicationChange}
                            />
                        }
                    />

                    <TextInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                        formItemClassName="role-desc"
                        onObjectChange={() => { this.markPrompt() }}
                        disabled={!hasWriteAccess}
                    />

                    <Button className="import-btn" disabled={!hasWriteAccess} onClick={this.toggleImportPermissions}>Import permissions</Button>
                </div>

                <CheckboxGroupInput
                    param="capabilities"
                    title={application && application.id == this.context.applicationId ? "Permissions from application" : "Permissions"}
                    key={this.state.application?.id}
                    value={this.state.initialCapabilities}
                    onObjectChange={() => { this.markPrompt(); }}
                    disabled={!hasWriteAccess}
                    render={(selected) =>
                        <CapabilitiesEditor
                            selected={selected}
                            apiCapabilitites={availableCapabilities ?? []}
                            applicationCapabilities={appCapabilities}
                            applicationSpesific={application && application.id == this.context.applicationId}
                        />
                    }
                />

                <Drawer
                    title="Import permissions"
                    onClose={this.toggleImportPermissions}
                    open={this.state.import_showDrawer}
                    component={
                        <div className="import-permissions-drawer">
                            <RoleSelector title="Role" placeholder="Select role..." onObjectChange={this.onImportRoleChange} />
                            <Button onClick={this.onImportPermissions} disabled={!this.state.import_selectedRole}>Import</Button>
                        </div>
                    }
                />

                {/*<MyPrompt*/}
                {/*    changeEventKey={RoleEditFormPromptEvents.Change}*/}
                {/*    clearEventKey={RoleEditFormPromptEvents.Clear}*/}
                {/*/>*/}
            </BaseForm>
        );
    }
}

export default RoleEditForm;