import _ from "lodash";
import moment from "moment";
import client from "../client";
import { ActivityType, ActivityView, PagedActivityQuery, ProjectType } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class ActivityExport extends BaseExportService<PagedActivityQuery, ActivityView> {
    constructor() {

        const specification: ExportConfig<ActivityView>[] = [
            { header: 'Date', value: (data: ActivityView) => data.start ? moment(data.start).format() : null },
            { header: 'Start', value: (data: ActivityView) => data.start ? moment(data.start).format("HH:mm") : null },
            { header: 'End', value: (data: ActivityView) => data.end ? moment(data.end).format("HH:mm") : null },
            { header: 'Duration', value: (data: ActivityView) => data.start && data.end ? moment(data.end).diff(moment(data.start)) : null, format: ExportFormat.Duration },
            { header: 'Customer', value: (data: ActivityView) => data.relation ? data.relation.name : null },
            { header: 'Project', value: (data: ActivityView) => data.project ? data.project.name : null },
            { header: 'Task', value: (data: ActivityView) => data.task ? data.task.name : null },
            { header: 'Description', value: (data: ActivityView) => data.description ?? null },
            { header: 'Type of work', value: (data: ActivityView) => data.type ?? null },
            { header: 'Category', value: (data: ActivityView) => data.category ? data.category.name : null },
            { header: 'Product', value: (data: ActivityView) => data.project && data.project.type === ProjectType.Internal ? null : data.product ? data.product.name : data.type !== ActivityType.Internal && data.category && data.category.product ? data.category.product.name : "-" },
            { header: 'Employee', value: (data: ActivityView) => data.actor ? data.actor.name : null },
            {
                header: 'Attributes', value: (data: ActivityView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })
                    }

                    return dataString;
                }
            },
            { header: 'Deleted', value: (data: ActivityView) => data.deleted ? true : false }
        ];

        super(specification, client.activities.queryActivities);
    }
}