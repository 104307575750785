import * as React from "react";
import client from "../../ApiClient/client";
import { PagedResourceCategoryQuery, ResourceCategoryView } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";
import SelectorInformation from "../Shared/SelectorInformation";


export class ResourceCategorySelector extends React.Component<GenericEntitySelectorProps<PagedResourceCategoryQuery>> {
    renderOptionItem = (resourceCategory: ResourceCategoryView) => resourceCategory.description ? <SelectorInformation displayText={resourceCategory.name} hoverText={resourceCategory.description} /> : resourceCategory.name;
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select resource category...';
        const source = this.props.source ? this.props.source : client.resourceCategories.queryResourceCategories;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.resourceCategories.getResourceCategoryById;

        return (
            <EntitySelector
                className="resource-category-selector"
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                renderOptionItem={this.renderOptionItem}
                {...this.props}
            />
        );
    }
}

export default ResourceCategorySelector;