import * as React from "react";
import _ from 'lodash';
import Modal from "antd/lib/modal/Modal";
import { LeftOutlined, RightOutlined, DownloadOutlined } from "@ant-design/icons";
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Image } from "antd";
import { FileView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { downloadFile } from "./FileHelpers";
import FilesTextContent from "./FileTextContent";

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();



interface FileModalProps {
    filesInFolder?: FileView[] | any[];
    file: any;
    fileData: any;
    showFileModal: boolean;
    changeFile?: (file: FileView) => void
    changeFileModalVisibility: (fileModalVisibility: boolean) => void;
    isInvoice?: boolean;
}

interface FileModalState {
    numPages: number;
    filecurrentPageNumber: number;
    urlForFile: any;
    loading: boolean;
}

export class FileModal extends React.Component<FileModalProps, FileModalState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            numPages: null,
            filecurrentPageNumber: 1,
            urlForFile: null,
            loading: false,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.file != prevProps.file) this.setState({ filecurrentPageNumber: 1 });
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages })
    }

    onPrevPagePress = () => {
        if (this.state.filecurrentPageNumber > 1)
            this.setState({ filecurrentPageNumber: this.state.filecurrentPageNumber - 1 });
    }

    onNextPagePress = () => {
        if (this.state.filecurrentPageNumber < this.state.numPages)
            this.setState({ filecurrentPageNumber: this.state.filecurrentPageNumber + 1 });
    }

    onPrevFile = async () => {
        let index = this.getCurrentIndex();
        index--;

        if (index == -1) index = this.props.filesInFolder.length - 1;

        this.setState({ loading: true });

        await this.props.changeFile(this.props.filesInFolder[index]);

        this.setState({ loading: false });
    }

    onNextFile = async () => {
        let index = this.getCurrentIndex();
        index++;

        if (index == this.props.filesInFolder.length) index = 0;

        this.setState({ loading: true });

        await this.props.changeFile(this.props.filesInFolder[index]);

        this.setState({ loading: false });
    }

    onCancel = () => {
        this.setState({
            filecurrentPageNumber: 1,
        });

        this.props.changeFileModalVisibility(false);
    }

    onDownload = async () => {
        if (this.props.isInvoice) {
            const response = await client.invoices.getInvoiceDocumentById(this.props.file.id);
            const blob = await response.blob();

            if (blob != null) {
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.style.display = 'none';
                a.download = this.props.file.document.name;
                a.href = url;

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            return;
        }

        return downloadFile(this.props.file)
    }

    getCurrentIndex = () => {
        let i = -1;

        _.each(this.props.filesInFolder, (file, index) => {
            if (file.id == this.props.file.id) i = index;

            return file;
        })

        return i;
    }

    displayContent = () => {
        if (this.props.isInvoice) {
            return (
                <>
                    <LeftOutlined onClick={() => this.onPrevPagePress()} disabled={this.state.filecurrentPageNumber <= 1} />
                    <div>
                        <Document
                            file={`data:application/pdf;base64,${this.props.fileData}`}
                            onLoadSuccess={this.onDocumentLoadSuccess}
                        >
                            <Page pageNumber={this.state.filecurrentPageNumber} />
                        </Document>
                    </div>
                    <RightOutlined onClick={() => this.onNextPagePress()} disabled={this.state.filecurrentPageNumber >= this.state.numPages} />
                </>
            );
        }

        //var extension = this.props.file ? this.props.file["extension"].replace('.', '').toLowerCase() : null;

        //const byteCharacters = atob(this.props.fileData);
        //const byteNumbers = new Array(byteCharacters.length);
        //for (let i = 0; i < byteCharacters.length; i++) {
        //    byteNumbers[i] = byteCharacters.charCodeAt(i);
        //}
        //const byteArray = new Uint8Array(byteNumbers);
        //const blob = new Blob([byteArray], { type: "image/png" /*"application/vnd.openxmlformats-officedocument.wordprocessingml.document"*/ });

        //var docRenderProps = {
        //    pluginRenderers: DocViewerRenderers,
        //    documents: this.props.fileData
        //        ? [{ uri: window.URL.createObjectURL(this.props.fileData), fileName: this.props.file.name, fileType: 'docx' }]
        //        : null
        //}
        switch (this.props.file["extension"].toLowerCase()) {
            case ".pdf":
            case ".eml":
            case ".xlsx":
            case ".xls":
            case ".docx":
            case ".doc":
            case ".pptx":
            case ".ppt":
                return (
                    <>
                        <LeftOutlined onClick={() => this.onPrevPagePress()} disabled={this.state.filecurrentPageNumber <= 1} />
                        <div>
                            <Document
                                file={`data:application/pdf;base64,${this.props.fileData}`}
                                onLoadSuccess={this.onDocumentLoadSuccess}
                            >
                                <Page pageNumber={this.state.filecurrentPageNumber} />
                            </Document>
                        </div>
                        <RightOutlined onClick={() => this.onNextPagePress()} disabled={this.state.filecurrentPageNumber >= this.state.numPages} />
                    </>
                );
            case ".jpg":
            case ".jpeg":
            case ".png":
                return <Image className="image-preview" preview={false} src={typeof this.props.fileData === 'object' ? URL.createObjectURL(this.props.fileData) : this.props.fileData} /> /*<DocViewer {...docRenderProps} />; */// (<FileViewer filePath={this.props.fileData ? typeof this.props.fileData === 'string' ? this.props.fileData : URL.createObjectURL(this.props.fileData) : null} fileType={extension} />);
            //case ".xlsx":
            //    return "TODO"; /*<DocViewer {...docRenderProps} />;*/ //(<div className="excel-modal-content"><FileViewer filePath={this.props.fileData ? typeof this.props.fileData === 'string' ? this.props.fileData : URL.createObjectURL(this.props.fileData) : null} fileType={extension} /></div>);
            //case ".xls":
            //    return "TODO"; /*<DocViewer {...docRenderProps} />;*/ //(<div className="not-supported-modal-content"><b>.xls</b> is not supported. <br />Click <a onClick={this.onDownload}>here</a> to download.</div>);
            //case ".docx":
            //    return "TODO"; /*<DocViewer {...docRenderProps} />;*/ //(<div className="word-modal-content"><FileViewer filePath={this.props.fileData ? typeof this.props.fileData === 'string' ? this.props.fileData : URL.createObjectURL(this.props.fileData) : null} fileType={extension} /></div>);
            //case ".doc":
            //    return "TODO"; /*<DocViewer {...docRenderProps} />;*/ //(<div className="not-supported-modal-content"><b>.doc</b> is not supported. <br />Click <a onClick={this.onDownload}>here</a> to download.</div>);
            case ".mp4":
                return (<div className="video-modal-content"><video width="100%" height="100%" controls><source src={URL.createObjectURL(this.props.fileData)} type="video/mp4" /></video></div>);
            case ".jfif":
                return (<div className="not-supported-modal-content"><b>.jfif</b> is not supported. <br />Click <a onClick={this.onDownload}>here</a> to download.</div>);
            case ".txt":
                return (<div className="text-modal-content"><FilesTextContent fileData={this.props.fileData} /></div>);
            default:
                return (<div className="not-supported-modal-content"><b>This</b> filetype is not supported. <br />Click <a onClick={this.onDownload}>here</a> to download.</div>);
        }
    }

    render() {
        return (
            this.props.file ?
                <Modal
                    title={this.props.isInvoice ? <>{this.props.file.document.name} <DownloadOutlined onClick={this.onDownload} /></> : this.props.file ? <>{this.props.file.name} <DownloadOutlined onClick={this.onDownload} /></> : null}
                    centered
                    open={this.props.showFileModal}
                    onCancel={this.onCancel}
                    footer={this.props.file && this.props.file["extension"] === ".pdf" || this.props.isInvoice ? <p>Page {this.state.filecurrentPageNumber} of {this.state.numPages}</p> : null}
                    className={this.props.file && this.props.file["extension"] === ".jpg" ? "jpg-modal" : null}
                >
                    {this.props.filesInFolder ?
                        <div className="prev-file">
                            <Button size="large" shape="circle" style={{ background: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.onPrevFile()}>
                                <LeftOutlined style={{ color: "rgba(255, 255, 255, 1)" }} />
                            </Button>
                        </div> : null}
                    {this.props.file && !this.state.loading ? this.displayContent() : null}
                    {this.props.filesInFolder ?
                        <div className="next-file">
                            <Button size="large" shape="circle" style={{ background: "rgba(0, 0, 0, 0.65)" }} onClick={() => this.onNextFile()}>
                                <RightOutlined style={{ color: "rgba(255, 255, 255, 1)" }} />
                            </Button>
                        </div> : null}
                </Modal>
                : null
        );
    }
}