import * as React from "react";
import _ from 'lodash';
import { FileImageOutlined, VideoCameraOutlined, FileOutlined, FolderOutlined, LoadingOutlined } from "@ant-design/icons";
import { Card, Spin } from "antd";
import { FileLinkView, FileView, FolderLinkView, FolderView, PreviewType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { ImageExtentions, VideoExtentions } from "../../Definitions/_fileextentions";
import client from "../../ApiClient/client";
import { blobToBase64 } from "./FileHelpers";
import { FileModal } from "./FileModal";


const { Meta } = Card;


interface GalleryViewProps {
    folders: (FolderLinkView | FolderView)[];
    files: (FileLinkView | FileView)[];
    onFolderClick: (folderId: string) => void;
    onFileClick?: (file) => void;
    showDeleted?: boolean;
}

interface FileCardProps {
    filesInFolder?: FileView[] | any[];
    file: FileView | FileLinkView;
    onFileClick?: (file) => void;
}

interface FileCardState {
    blobUrl?: string;
    fileToDisplay: FileView;
    fileDataToDisplay: any;
    showFileModal: boolean;
    loadingFile: boolean;
}

interface FolderCardProps {
    folder: FolderView | FolderLinkView;
    onClick: () => void;
}


class FileCard extends React.Component<FileCardProps, FileCardState> {

    constructor(props) {
        super(props)

        this.state = {
            blobUrl: null,
            fileDataToDisplay: null,
            fileToDisplay: null,
            showFileModal: false,
            loadingFile: false,
        }
    }


    componentDidMount = async () => {
        if (Object.values(ImageExtentions).includes(this.props.file.extension)) {
            try {
                const thumbnailRes = await client.files.getThumbnail(this.props.file.id);
                const blob = thumbnailRes ? await thumbnailRes.blob() : null;
                const blobUrl = blob ? window.URL.createObjectURL(blob) : null;
                this.setState({ blobUrl: blobUrl });
            }
            catch (error) {
                if (console && console.error)
                    console.error(`Error retreiving thumbnail for file id: ${this.props.file.id}`);
            }
        }
    }

    onSelect = async (selected) => {
        this.setState({ loadingFile: true });
        if (selected.extension != null)
            selected.extension = selected.extension.toLowerCase();

        if (selected["extension"] === ".pdf") {
            const fileData = await client.files.downloadFile(selected.id);

            const base64string: any = await blobToBase64(fileData.data);

            this.setState({
                fileToDisplay: selected,
                fileDataToDisplay: base64string,
                showFileModal: true
            });
        }
        else if (selected.extension === ".xlsx" || selected.extension === ".eml" || selected.extension === ".xls" || selected.extension === ".docx" || selected.extension === ".doc" || selected.extension === ".pptx" || selected.extension === ".ppt") {
            const fileData = await client.files.getPreview(selected.parentFileId ?? selected.id, { type: PreviewType.PDF });
            const blobbed = fileData ? await fileData.blob() : null;
            const base64string: any = blobbed ? await blobToBase64(blobbed) : null;

            this.setState({
                fileToDisplay: selected,
                fileDataToDisplay: base64string,
                showFileModal: true
            });
        }
        else {
            const fileData = await client.files.download(selected.id);

            this.setState({
                fileToDisplay: selected,
                fileDataToDisplay: fileData,
                showFileModal: true
            });
        }

        this.setState({ loadingFile: false });
    }

    changeFileModalVisibility = (fileModalVisibility: boolean) => {
        if (fileModalVisibility == false) {
            this.setState({
                fileDataToDisplay: null,
                fileToDisplay: null,
            })
        }

        this.setState({
            showFileModal: fileModalVisibility,
        })
    }

    render() {
        const { file } = this.props;
        const { blobUrl } = this.state;

        const fallbackIcon =
            Object.values(ImageExtentions).includes(file.extension) ? <FileImageOutlined /> :
                Object.values(VideoExtentions).includes(file.extension) ? <VideoCameraOutlined /> :
                    <FileOutlined />;

        const cover = blobUrl ? <div className="thumbnail-image-wrapper"><img alt={file.name} src={blobUrl} className="thumbnail-image" /></div> : fallbackIcon;

        return <>
            {!this.state.loadingFile ?
                <Card
                    key={file.id}
                    onClick={() => this.props.onFileClick ? this.props.onFileClick(file) : this.onSelect(file)}
                    className="thumbnail-card"
                    hoverable
                    cover={cover}
                >
                    <Meta title={file.name} description={file.description} />
                </Card> :
                <Spin style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
            }

            <FileModal
                filesInFolder={this.props.filesInFolder}
                file={this.state.fileToDisplay}
                fileData={this.state.fileDataToDisplay}
                showFileModal={this.state.showFileModal}
                changeFileModalVisibility={this.changeFileModalVisibility}
                changeFile={this.onSelect}
            />
        </>;
    }

}


class FolderCard extends React.Component<FolderCardProps> {

    constructor(props) {
        super(props)
    }


    render() {
        return <Card
            key={this.props.folder.id}
            className="folder-card"
            hoverable
            onClick={() => this.props.onClick()}
            cover={<FolderOutlined />}
        >
            <Meta title={this.props.folder.name} description={(this.props.folder as FolderView)?.description ?? ""} />
        </Card>;
    }

}


export class GalleryView extends React.Component<GalleryViewProps> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render() {
        const { folders, files } = this.props;

        const filteredFolders = _.filter(folders, folder => this.props.showDeleted == null || folder.deleted == this.props.showDeleted);
        const filteredFiles = _.filter(files, file => this.props.showDeleted == null || file.deleted == this.props.showDeleted);

        const folderViews = _.map(filteredFolders, (f) => <FolderCard folder={f} onClick={() => this.props.onFolderClick(f.id)} key={f.id} />)
        const fileViews = _.map(filteredFiles, (f) => <FileCard file={f} filesInFolder={filteredFiles} key={f.id} onFileClick={this.props.onFileClick} />)

        return (
            <div className="folder-view-page-gallery">
                {folderViews}
                {fileViews}
            </div>
        );
    }
}

export default GalleryView;