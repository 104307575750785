import * as React from "react";
import _ from 'lodash';
import { Select } from 'antd';
import { StaticSelectorProps } from "../../Models/StaticSelectorProps";
const { Option } = Select;

interface BaseFilterSelectorState {
    value: any
}

class StaticSelector extends React.Component<StaticSelectorProps, BaseFilterSelectorState> {
    constructor(props) {
        super(props);

        this.state = {
            value: undefined
        };
    }

    componentDidMount = () => {
        if (this.props.value != null || typeof this.props.value == "boolean")
            this.setState({ value: this.props.value });
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.value != prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    handleChange = (value) => {
        this.setState({ value });

        if (value === undefined || value === null || (value.length != null && value.length === 0))
            value = null

        if (this.props.onChange)
            this.props.onChange(value);
    }

    render = () => {
        const options = [];

        if (!this.props.multiple && !this.props.excludeAllOption)
            options.push(<Option key="All" value={null}>All</Option>)

        _.each(this.props.options, (f, key) => {
            options.push(<Option key={f.name} value={f.value}>{f.name}</Option>);
        });

        const title = this.props.title ? <label>{this.props.title}</label> : null;

        const variableProps = {
            value: this.state.value === undefined ? undefined : this.state.value === null ? null : this.state.value
        };

        return (
            <div className={`selector static-selector ${this.props.className}`}>
                {title}
                <Select
                    mode={this.props.multiple ? 'multiple' : null}
                    placeholder={this.props.placeholder}
                    size={this.props.size ? this.props.size : "middle"}
                    allowClear={this.props.disableClear ? false : true}
                    onChange={this.handleChange}
                    className="select"
                    disabled={this.props.disabled}
                    {...variableProps}
                >
                    {options}
                </Select>
            </div>
        );
    }
}

export default StaticSelector;