/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommentSortOption,
  CommentView,
  CommentViewEntityViewModel,
  CommentViewPagedCommentQueryCollectionViewModel,
  CreateComment,
  CreateFileOrFolder,
  CreateTask,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  MoveTask,
  PreviewType,
  RevertFileToVersion,
  SortDirection,
  TaskAggregationResultTaskAggregateQueryAggregationResults,
  TaskAggregationTypes,
  TaskSortOption,
  TaskView,
  TaskViewEntityViewModel,
  TaskViewPagedTaskQueryCollectionViewModel,
  UpdateComment,
  UpdateFileOrFolder,
  UpdateTask,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Tasks<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Task
   * @name GetTaskById
   * @request GET:/api/tasks/{id}
   * @secure
   */
  getTaskById = (id: string, params: RequestParams = {}) =>
    this.http.request<TaskViewEntityViewModel, any>({
      path: `/api/tasks/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name UpdateTask
   * @request PUT:/api/tasks/{id}
   * @secure
   */
  updateTask = (id: string, data: UpdateTask, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name DeleteTask
   * @request DELETE:/api/tasks/{id}
   * @secure
   */
  deleteTask = (id: string, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name CreateTask
   * @request POST:/api/tasks
   * @secure
   */
  createTask = (data: CreateTask, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name QueryTasks
   * @request GET:/api/tasks
   * @secure
   */
  queryTasks = (
    query?: {
      sortBy?: TaskSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      ownerId?: string;
      assigneeId?: string;
      actorId?: string;
      projectId?: string;
      customerId?: string;
      projectActive?: boolean;
      categoryId?: string;
      parentId?: string;
      closed?: boolean;
      /** @format date-time */
      closedAfter?: string;
      /** @format date-time */
      closedBefore?: string;
      /** @format date-time */
      startPeriod?: string;
      /** @format date-time */
      endPeriod?: string;
      /** @format int64 */
      estimateMin?: number;
      /** @format int64 */
      estimateMax?: number;
      /** @format date-time */
      invoicedAfter?: string;
      /** @format date-time */
      invoicedBefore?: string;
      tagIds?: string[];
      showAll?: boolean;
      hasChildren?: boolean;
      isContainer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TaskViewPagedTaskQueryCollectionViewModel, any>({
      path: `/api/tasks`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name RestoreTask
   * @request POST:/api/tasks/{id}/restore
   * @secure
   */
  restoreTask = (id: string, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name UpdateTaskAttributes
   * @request POST:/api/tasks/{id}/attributes
   * @secure
   */
  updateTaskAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name CloseTask
   * @request POST:/api/tasks/{id}/close
   * @secure
   */
  closeTask = (id: string, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}/close`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name OpenTask
   * @request POST:/api/tasks/{id}/open
   * @secure
   */
  openTask = (id: string, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}/open`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name MoveTask
   * @request POST:/api/tasks/{id}/move
   * @secure
   */
  moveTask = (id: string, data: MoveTask, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name MergeTask
   * @request GET:/api/tasks/{id}/mergeto/{newId}
   * @secure
   */
  mergeTask = (id: string, newId: string, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}/mergeto/${newId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name ImportSubTasks
   * @request GET:/api/tasks/{id}/import/{fromId}
   * @secure
   */
  importSubTasks = (id: string, fromId: string, params: RequestParams = {}) =>
    this.http.request<TaskView, any>({
      path: `/api/tasks/${id}/import/${fromId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name GetTaskAggregates
   * @request GET:/aggs/tasks
   * @secure
   */
  getTaskAggregates = (
    query?: {
      taskId?: string;
      aggs?: TaskAggregationTypes[];
      ownerId?: string;
      assigneeId?: string;
      actorId?: string;
      projectId?: string;
      customerId?: string;
      projectActive?: boolean;
      categoryId?: string;
      parentId?: string;
      closed?: boolean;
      /** @format date-time */
      closedAfter?: string;
      /** @format date-time */
      closedBefore?: string;
      /** @format date-time */
      startPeriod?: string;
      /** @format date-time */
      endPeriod?: string;
      /** @format int64 */
      estimateMin?: number;
      /** @format int64 */
      estimateMax?: number;
      /** @format date-time */
      invoicedAfter?: string;
      /** @format date-time */
      invoicedBefore?: string;
      tagIds?: string[];
      showAll?: boolean;
      hasChildren?: boolean;
      isContainer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TaskAggregationResultTaskAggregateQueryAggregationResults, any>({
      path: `/aggs/tasks`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name GetSTaskAggregatesById
   * @request GET:/aggs/tasks/{id}
   * @secure
   */
  getSTaskAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/tasks/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskComment
   * @name GetTaskCommentById
   * @request GET:/api/tasks/{entityId}/comments/{commentId}
   * @secure
   */
  getTaskCommentById = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentViewEntityViewModel, any>({
      path: `/api/tasks/${entityId}/comments/${commentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskComment
   * @name UpdateTaskComment
   * @request PUT:/api/tasks/{entityId}/comments/{commentId}
   * @secure
   */
  updateTaskComment = (entityId: string, commentId: string, data: UpdateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tasks/${entityId}/comments/${commentId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskComment
   * @name DeleteTaskComment
   * @request DELETE:/api/tasks/{entityId}/comments/{commentId}
   * @secure
   */
  deleteTaskComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tasks/${entityId}/comments/${commentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskComment
   * @name CreateTaskComment
   * @request POST:/api/tasks/{entityId}/comments
   * @secure
   */
  createTaskComment = (entityId: string, data: CreateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tasks/${entityId}/comments`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskComment
   * @name QueryTaskComments
   * @request GET:/api/tasks/{entityId}/comments
   * @secure
   */
  queryTaskComments = (
    entityId: string,
    query?: {
      sortBy?: CommentSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CommentViewPagedCommentQueryCollectionViewModel, any>({
      path: `/api/tasks/${entityId}/comments`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskComment
   * @name RestoreTaskComment
   * @request POST:/api/tasks/{entityId}/comments/{commentId}/restore
   * @secure
   */
  restoreTaskComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tasks/${entityId}/comments/${commentId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskComment
   * @name AddTaskCommentEmoji
   * @request POST:/api/tasks/{entityId}/comments/{commentId}/reactions/{emojicode}
   * @secure
   */
  addTaskCommentEmoji = (entityId: string, commentId: string, emojicode: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tasks/${entityId}/comments/${commentId}/reactions/${emojicode}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name GetTaskFileById
   * @request GET:/api/tasks/{entityId}/files/{fileId}
   * @secure
   */
  getTaskFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/tasks/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name UpdateTaskFile
   * @request PUT:/api/tasks/{entityId}/files/{fileId}
   * @secure
   */
  updateTaskFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/tasks/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name DeleteTaskFile
   * @request DELETE:/api/tasks/{entityId}/files/{fileId}
   * @secure
   */
  deleteTaskFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tasks/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name DownloadTaskFile
   * @request GET:/api/tasks/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadTaskFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/tasks/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name PreviewTaskFile
   * @request GET:/api/tasks/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewTaskFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/tasks/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name CreateTaskFile
   * @request POST:/api/tasks/{entityId}/files
   * @secure
   */
  createTaskFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/tasks/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name QueryTaskFiles
   * @request GET:/api/tasks/{entityId}/files
   * @secure
   */
  queryTaskFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/tasks/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name RestoreTaskFile
   * @request POST:/api/tasks/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreTaskFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tasks/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name MoveTaskFile
   * @request POST:/api/tasks/{entityId}/files/{fileId}/move
   * @secure
   */
  moveTaskFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tasks/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name RevertTaskFile
   * @request POST:/api/tasks/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertTaskFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tasks/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name GetTaskFolderById
   * @request GET:/api/tasks/{entityId}/folders/{folderId}
   * @secure
   */
  getTaskFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/tasks/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name UpdateTaskFolder
   * @request PUT:/api/tasks/{entityId}/folders/{folderId}
   * @secure
   */
  updateTaskFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tasks/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name DeleteTaskFolder
   * @request DELETE:/api/tasks/{entityId}/folders/{folderId}
   * @secure
   */
  deleteTaskFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tasks/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name CreateTaskFolder
   * @request POST:/api/tasks/{entityId}/folders
   * @secure
   */
  createTaskFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tasks/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name QueryTaskFolders
   * @request GET:/api/tasks/{entityId}/folders
   * @secure
   */
  queryTaskFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/tasks/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name RestoreTaskFolder
   * @request POST:/api/tasks/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreTaskFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tasks/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFile
   * @name MoveTaskFolder
   * @request POST:/api/tasks/{entityId}/{folderId}/move
   * @secure
   */
  moveTaskFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tasks/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
