import { OptionsStackingValue, VerticalAlignValue, YAxisPlotLinesOptions } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { SortDirection } from "../ApiClient/swagger/data-contracts";
import { ValueType } from "../Models/ValueType";

export interface StackedHistorgramCollection {
    data: any[] | Record<any, any>;
    color?: string;
    seriesYField?: string;
    drilldownField?: string;
    drilldownXField?: string;
    drilldownYField?: string;
    stacking?: OptionsStackingValue;
    seriesName?: string;
}

export enum HistogramType {
    Column = "column",
    Bar = "bar",
    Line = "line",
    Pie = "pie"
}

export interface SeriesData {
    name: string;
    data: any[];
    color?: string;
    type: HistogramType;
    stacking?: any;
}

interface SharedHistogramProps {
    type?: HistogramType;
    drillUpOptions?: Highcharts.DrilldownDrillUpButtonOptions;
    xField: string;
    valueBy: ValueType;
    title?: JSX.Element | string;
    loading?: boolean;
    height?: number;
    className?: string;
    enableLegend?: boolean;
    reverseLegend?: boolean;
    legendAlign?: VerticalAlignValue;
    labelRotation?: number;
    xAxisFormatter?: (value) => string;
    totalDisplayCount?: number;
    sortDirection?: SortDirection;
    stopSort?: boolean;
    isStackedHistogram?: boolean;
    route?: string;
    sortBy?: string;
    actions?: React.ReactNode;
    labelsStep?: number;
    disableDataLabels?: boolean;
    exportSourceWidth?: number;
    chartMarginRight?: number;
    stackedLabelSteps?: number;
    yAxisPlotLines?: YAxisPlotLinesOptions[];
    enableMarkers?: boolean;
    unit?: string;
    categories?: string[];
    yAxisMinUnlimited?: boolean;
    enableScroll?: boolean;
}

export interface NormalHistogramProps extends SharedHistogramProps {
    data: any;
    yField?: string;
    drilldownField?: string;
    drilldownXField?: string;
    drilldownYField?: string;
}

export interface StackedHistogramProps extends SharedHistogramProps {
    data: Partial<StackedHistorgramCollection>[];
    xField: string;
    chartClassName?: string;
}


interface SharedHistogramCardProps {
    cardClassName?: string;
    disableCardBorder?: boolean;
}

export interface HistogramCardProps extends NormalHistogramProps, SharedHistogramCardProps { }

export interface StackedHistogramCardProps extends StackedHistogramProps, SharedHistogramCardProps { }