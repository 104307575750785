import client from "../../ApiClient/client";
import { PagedContactQuery } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";


export function ContactSelector(props: GenericEntitySelectorProps<PagedContactQuery>) {
    return (
        <EntitySelector
            placeholder={props.placeholder ? props.placeholder : 'Select contact...'}
            source={props.source ? props.source : client.contacts.queryContacts}
            defaultValueSource={props.defaultValueSource ? props.defaultValueSource : client.contacts.getContactById}
            optionDisplayNameParam={props.optionDisplayNameParam ? props.optionDisplayNameParam : 'name'}
            {...props}
        />
    );
}

export default ContactSelector;