import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Card, Button } from "antd";
import Title from "antd/lib/typography/Title";
import { ActorLinkView, CallsRelatedNotesView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { ActorLink } from "../Actors";
import { ProjectLink } from "../Projects";


interface RelatedNotesCardProps {
    className?: string;
    title?: string;
    callId: string;
    relatedActors?: ActorLinkView[];
}

interface RelatedNotesCardState {
    notes: CallsRelatedNotesView[];
    error: string;
    takeElements: number;
}

export default class RelatedNotesCard extends React.Component<RelatedNotesCardProps, RelatedNotesCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            notes: [],
            error: null,
            takeElements: 5
        };
    }

    componentDidMount = () => {
        this.loadRelatedNotes();
    }

    loadRelatedNotes = async () => {
        const response = await client.calls
            .getCallRelatedNotes(this.props.callId)
            .catch(ex => this.setState({ error: ex.error.title }));

        if (response)
            this.setState({ notes: response.data });
    }

    loadMore = () => {
        let shownElements = this.state.takeElements;
        shownElements += 5;
        this.setState({ takeElements: shownElements });
    }

    render = () => {
        const shownElements = _.take(this.state.notes || [], this.state.takeElements);

        const noteItems = _.map(shownElements, note => {

            let noteFooter = null;

            if (note.entityType == "Actor" || note.entityType == "Project") {
                const by = note.byActorId ? <ActorLink id={note.byActorId} name={note.byActorName} /> : note.byActorName;
                const regarding = note.entityId ? note.entityType == "Actor" ? <ActorLink id={note.entityId} name={note.entityName} /> : note.entityType == "Project" ? <ProjectLink id={note.entityId} name={note.entityName} /> : note.entityName : note.entityName;

                noteFooter = (
                    <React.Fragment>
                        <div>By: {by}</div>
                        <div>Regarding: {regarding}</div>
                    </React.Fragment>
                );
            }
            else if (note.entityType == "Call") {
                const from = note.callFromActorId ? <ActorLink id={note.callFromActorId} name={note.callFromActorName} /> : note.callFromActorName;
                const to = note.callToActorId ? <ActorLink id={note.callToActorId} name={note.callToActorName} /> : note.callToActorName;

                noteFooter = (
                    <React.Fragment>
                        <div>From: {from}</div>
                        <div>To: {to}</div>
                    </React.Fragment>
                );
            }

            return (
                <div key={note.id} className="related-note-item">
                    <div className="time">{moment(note.timestamp).format('DD.MM.YYYY HH:mm')} - {note.entityType}</div>
                    <div className="text">{note.text}</div>
                    <div className="note-footer-container">
                        <div className="footer">{noteFooter}</div>
                    </div>
                </div>
            );
        });

        return (
            <Card
                key="related-notes-card"
                title={<Title level={4} className="title">{this.props.title ?? "Related notes"}</Title>}
                className={`related-notes-card ${this.props.className ? this.props.className : ""}`}
            >
                <div className="note-items">
                    {noteItems && noteItems.length > 0 ? noteItems : "No related notes found"}
                </div>

                {noteItems && noteItems.length > 0 && this.state.notes.length > noteItems.length ?
                    <div className="load-more-notes">
                        <Button onClick={this.loadMore} disabled={this.state.notes.length <= noteItems.length}>Load more</Button>
                    </div>
                    : null}
            </Card>
        );
    }
}