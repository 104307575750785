import * as React from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import client from "../../ApiClient/client";
import { Popover, Spin, Card, Avatar } from 'antd';
import { ToolOutlined } from "@ant-design/icons";
import AppContext from "../../Definitions/AppContext";
import { OrderLinkView, OrderType, OrderView } from "../../ApiClient/swagger/data-contracts";
import { Capabilities } from "../../Definitions/_capabilties";
import { useContext, useEffect, useState } from "react";
import { ActorLink } from "../Actors";

const { Meta } = Card;

interface OrderLinkCardProps {
    orderId: string
}

interface OrderLinkCardState {
    order?: OrderView,
    error?: Error,
    avatarUrl: string,
}

class OrderLinkCard extends React.Component<OrderLinkCardProps, OrderLinkCardState> {
    _isMounted = false;

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
        this.state = {
            order: null,
            error: null,
            avatarUrl: null
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.loadOrder();

        this.context.events.orders.onMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentWillUnmount = () => {
        this._isMounted = false;

        this.context.events.people.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
        this.context.events.organizations.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.orderId !== this.props.orderId) {
            this.loadOrder()
        }
    }

    onItemUpdatedEvent = (eventData: OrderView) => {
        if (this.state.order && this.state.order.id === eventData.id) {
            if (this.state.order.changeVector == eventData.changeVector) {
                return;
            }
            this.setState({
                order: eventData
            });
        }
    }

    loadOrder = async () => {
        try {
            const order = await client.orders.getOrderById(this.props.orderId);

            if (order && this._isMounted) {
                this.setState({ order: order.data.view })
            }
        } catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    render = () => {
        if (this.state.error)
            return this.state.error.message;

        if (!this.state.order)
            return <Spin size="large" />;

        const { order } = this.state;

        const details = (
            <dl>
                {order.type ? <div className="type"><dt>Order Type</dt><dd>{order.type}</dd></div> : null}
                {order.customer ? <div className="customer"><dt>Customer</dt><ActorLink {...order.customer} /></div> : null}
            </dl>
        );

        return (
            <Card className="order-link-card" bordered={false} size="small" title={this.state.order.orderNumbers.map((value) => {
                return value.applicationLink?.name + ": " + value.orderNumber
            }).join(", ")}>
                <Meta description={
                    <React.Fragment>
                        {details}
                    </React.Fragment>
                }
                />
            </Card>
        );
    }
}

interface OrderLinkProps extends OrderLinkView {
    disablePopover?: boolean
}

export function OrderLink(props: OrderLinkProps){
    const [order, setOrder] = useState<OrderView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.orders.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.orders.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    function onItemUpdatedEvent(eventData: OrderView) {
        if (order && order.id === eventData.id) {
            setOrder(eventData);
        }
    }

    if (!order.orderNumbers) return null;

    if (!context.user.hasCapability(Capabilities.OrdersRead))
        return <Popover content="You do not have access to read orders.">{order.orderNumbers.map((value) => {
            return value.applicationLink?.name + ": " + value.orderNumber;
        }).join(", ")}</Popover>;

    let className = ""; //"no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (order.deleted)
        className += " deleted";

    const link = <Link
        //style={this.props.disablePopover ? { color: 'unset' } : null}
        to={`/orders/${order.id}`}
        className={className}>
        {order.orderNumbers.map((value) => {
            return `${value.applicationLink?.name}: ${value.orderNumber}`;
        }).join(", ")}
    </Link>;

    if (props.disablePopover)
        return link;

    return (
        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
            <Popover placement="bottomLeft" content={<OrderLinkCard orderId={order.id} />}>
                {link}
            </Popover>
        </span>
    );
}
