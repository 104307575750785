import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { OrderDateType, PagedOrderQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { QuickSearch } from "../Shared/QuickSearch";


interface OrderQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedOrderQuery>
}

export default class OrderQuickSearch extends React.Component<OrderQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        const endOfCurrentMonth = moment().endOf('month').endOf('day');
        const twelveMonthsAgo = endOfCurrentMonth.clone().subtract(11, 'months').startOf('month').startOf('day');

        const startOfNextMonth = moment().add(1, "month").startOf('month').startOf('day');
        const inTwelveMonths = startOfNextMonth.clone().add(1, "year").startOf('month').startOf('day');

        return (
            <React.Fragment>
                <QuickSearch
                    name="All"
                    queryParameters={{
                        dateTypes: null,
                        dateFrom: null,
                        dateTo: null
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Last 12 months"
                    queryParameters={{
                        dateTypes: [OrderDateType.Sales, OrderDateType.Quotation],
                        dateFrom: twelveMonthsAgo.format('YYYY-MM-DDTHH:mm:ss'),
                        dateTo: endOfCurrentMonth.format('YYYY-MM-DDTHH:mm:ss')
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Next 12 months"
                    queryParameters={{
                        dateTypes: [OrderDateType.Sales, OrderDateType.Quotation],
                        dateFrom: startOfNextMonth.format('YYYY-MM-DDTHH:mm:ss'),
                        dateTo: inTwelveMonths.format('YYYY-MM-DDTHH:mm:ss')
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="This month"
                    queryParameters={{
                        dateTypes: [OrderDateType.Sales, OrderDateType.Quotation],
                        dateFrom: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                        dateTo: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss')
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name="Last month"
                    queryParameters={{
                        dateTypes: [OrderDateType.Sales, OrderDateType.Quotation],
                        dateFrom: moment().subtract(1, "month").startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                        dateTo: moment().subtract(1, "month").endOf('month').format('YYYY-MM-DDTHH:mm:ss')
                    }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

            </React.Fragment>
        );
    }
}