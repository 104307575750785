/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ContactLinkViewPagedContactQueryCollectionViewModel,
  ContactLinkViewViewModel,
  ContactSortOption,
  SortDirection,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Contacts<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Contact
   * @name GetContactById
   * @request GET:/api/contacts/{id}
   * @secure
   */
  getContactById = (id: string, params: RequestParams = {}) =>
    this.http.request<ContactLinkViewViewModel, any>({
      path: `/api/contacts/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contact
   * @name QueryContacts
   * @request GET:/api/contacts
   * @secure
   */
  queryContacts = (
    query?: {
      sortBy?: ContactSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ContactLinkViewPagedContactQueryCollectionViewModel, any>({
      path: `/api/contacts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
