import * as React from "react";
import _ from 'lodash';
import { Alert, Button } from "antd";
import { ActorView, CreateSubscription, OrganizationView, PagedSubscriptionQuery, PersonView, SubscriptionView, TaggableType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { TagIds } from "../../Definitions/_definitions";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { CustomerSelector } from "../Actors";
import { Drawer } from "../Shared/Drawer";
import { OrganizationCreateForm } from "../Organizations";
import { TextInput } from "../Shared/TextInput";
import { TagSelector } from "../Tags";
import { SubscriptionTypeSelector } from "./SubscriptionTypeSelector";



interface CreateSubscriptionProps {
    onCancel?: () => void;
    onComplete: (created: SubscriptionView) => void;
    organization?: OrganizationView;
    filters?: Partial<PagedSubscriptionQuery>;
}

interface CreateSubscriptionState {
    loading: boolean;
    error: string;
    customerNotInvoiceableWarning: boolean;
    showCreateOrganizationDrawer: boolean;
    organization: OrganizationView;
}

export class SubscriptionCreateForm extends React.Component<CreateSubscriptionProps, CreateSubscriptionState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            showCreateOrganizationDrawer: false,
            organization: this.props.organization,
            customerNotInvoiceableWarning: false
        }
    }

    onSubmit = async (req: CreateSubscription) => {
        this.setState({ loading: true });

        const response = await client.subscriptions.createSubscription(req).catch(exception => this.setState({ error: exception.error.title }));
        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    toggleAddOrganizationDrawer = () => {
        this.setState({ showCreateOrganizationDrawer: !this.state.showCreateOrganizationDrawer });
    }

    onCompleteAddOrganization = async (data: OrganizationView) => {
        if (data) {
            this.setState({ organization: data });
            this.toggleAddOrganizationDrawer();
        }
    }

    render = () => {
        const initialValues: Partial<CreateSubscription> = {};

        if (this.state.organization)
            initialValues["customerId"] = this.state.organization.id;

        if (this.props.filters?.actorId) {
            initialValues["customerId"] = this.props.filters.actorId;
        }

        return (
            <div>
                <BaseForm
                    type={FormType.Create}
                    onSubmit={this.onSubmit}
                    onCancel={this.props.onCancel}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={initialValues}
                >
                    <SelectorInput
                        param="customerId"
                        title={
                            <React.Fragment>
                                <span style={{ width: '100%' }}>Customer</span>
                                <Button type="link" onClick={this.toggleAddOrganizationDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>{this.context.isMobile ? "+ Add" : "+ Add organization"}
                                </Button>
                            </React.Fragment>
                        }
                        required
                        selector={<CustomerSelector placeholder="Select customer" />}
                    />

                    <Drawer
                        title="Create organization"
                        onClose={this.toggleAddOrganizationDrawer}
                        open={this.state.showCreateOrganizationDrawer}
                        destroyOnClose={true}
                        component={this.state.showCreateOrganizationDrawer ?
                            <OrganizationCreateForm
                                onCancel={this.toggleAddOrganizationDrawer}
                                onComplete={(created) => created ? this.onCompleteAddOrganization(created) : this.toggleAddOrganizationDrawer()}
                            /> : null
                        }
                    />

                    <SelectorInput
                        param="invoiceReceiverId"
                        title="Invoice Receiver"
                        selector={<CustomerSelector placeholder="Select invoice receiver" />}
                    />

                    <TextInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                    />

                    <SelectorInput
                        param="type"
                        required
                        warningMessage="Please select a type"
                        title="Type"
                        selector={<SubscriptionTypeSelector excludeAllOption />}
                    />

                    <SelectorInput
                        param="tagIds"
                        title="Tags"
                        selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Subscription] }} />}
                    />
                </BaseForm>
            </div>
        );
    }
}

export default SubscriptionCreateForm;