import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from 'highcharts/highcharts-more';
import { useRef } from "react";
import Boost from "highcharts/modules/boost";
HighchartsMore(Highcharts);
Boost(Highcharts);

type GaugeChartProps = {
    value: number;
    cautionMin: number;
    cautionMax: number;
    criticalMin: number;
    criticalMax: number;
    unit: string;
}

const GaugeChart = ({ value, cautionMin, cautionMax, criticalMin, criticalMax, }: GaugeChartProps) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const max = criticalMax ? (10 / 100) * criticalMax : cautionMax ? (10 / 100) * cautionMax : cautionMin ? (10 / 100) * cautionMin : (10 / 100) * criticalMin;
    const maxValue = criticalMax ? criticalMax + (max) : cautionMax ? cautionMax + (max) : cautionMin ? cautionMin + (max) : criticalMin;
    const minValue = criticalMin ? criticalMin - (max) : cautionMin - (max);

    const plotbands = () => {
        const plotbands = [];
        if (criticalMin) {
            plotbands.push({
                from: minValue,
                to: criticalMin,
                color: '#DF5353', // red
                thickness: 5
            })
        }
        if (criticalMax) {
            plotbands.push({
                from: criticalMax,
                to: maxValue,
                color: '#DF5353', // red
                thickness: 5
            })
        }
        if (cautionMin && criticalMin) {
            plotbands.push({
                from: criticalMin,
                to: cautionMin,
                color: '#DDDF0D', // yellow
                thickness: 5
            })
        } else if (cautionMin) {
            plotbands.push({
                from: minValue,
                to: cautionMin,
                color: '#DDDF0D', // yellow
                thickness: 5
            })
        }
        if (cautionMax && criticalMax) {
            plotbands.push({
                from: cautionMax,
                to: criticalMax,
                color: '#DDDF0D', // yellow
                thickness: 5
            })
        } else if (cautionMax) {
            plotbands.push({
                from: cautionMax,
                to: maxValue,
                color: '#DDDF0D', // yellow
                thickness: 5
            })
        }

        plotbands.push({
            from: cautionMin ? cautionMin : criticalMin ? criticalMin : minValue,
            to: cautionMax ? cautionMax : criticalMax ? criticalMax : maxValue,
            color: '#55BF3B', // green
            thickness: 5
        })

        return plotbands;
    }

    const tickPositions = () => {
        const tickPositions = [];
        if (cautionMin) {
            tickPositions.push(cautionMin)
        }
        if (cautionMax) {
            tickPositions.push(cautionMax)
        }
        if (criticalMin) {
            tickPositions.push(criticalMin)
        }
        if (criticalMax) {
            tickPositions.push(criticalMax)
        }
        return tickPositions;
    }

    const options: Highcharts.Options = {
        chart: {
            type: 'gauge',
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: 75,
            width: 175,
            spacingBottom: -25,
            spacingTop: 7,
        },
    
        title: {
            text: undefined
        },

        exporting: {
            enabled: false
        },

        credits: {
            enabled: false,
        },

        tooltip: {
            enabled: false,
        },
    
        pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ['50%', '69%'],
            size: '110%'
        },
    
        // the value axis
        yAxis: {
            min: minValue,
            max: maxValue,
            tickPositions: tickPositions(),
            tickPosition: 'inside',
            tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
            tickLength: 20,
            tickWidth: 1,
            minorTickInterval: null,
            labels: {
                distance: 15,
                style: {
                    fontSize: '11px'
                }
            },
            lineWidth: 0,
            plotBands: plotbands(),
        },

        series: [{
            type: "gauge",
            data: [value],
            dataLabels: {
                enabled: false,
            },
            dial: {
                radius: '80%',
                backgroundColor: 'gray',
                baseWidth: 8,
                baseLength: '0%',
                rearLength: '0%',
            },
            pivot: {
                backgroundColor: 'gray',
                radius: 4,
            },
            wrap: false,
        }]
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponentRef}
        />
    );
};
export default GaugeChart;
