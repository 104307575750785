import * as React from "react";
import _ from 'lodash';
import { Button, Upload, Radio, Space, Form, Checkbox } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { CallTargetView, ContactPointType, ContactPointView, CreateOrUpdatePersonWebRequest, OrganizationAccessLevel, OrganizationRole, PagedPersonQuery, PersonView, TaggableType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { openNotification } from "../../Helpers/BasePageHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { OrganizationCreateForm, OrganizationPersonRoleForm, OrganizationSelector } from "../Organizations";
import { Drawer } from "../Shared/Drawer";
import { TagSelector } from "../Tags";
import { CustomFormLabel } from "../Shared/CustomFormLabel";
import { NumberInput } from "../Shared/NumberInput";
import CustomerCategorySelector from "../Shared/CustomerCategorySelector";
import SupplierCategorySelector from "../Shared/SupplierCategorySelector";


interface CreatePeopleProps {
    onComplete: (created?: PersonView) => void;
    onCancel?: () => void;
    fromOrganization?: boolean;
    filters?: PagedPersonQuery;
    organizationId?: string;
    cp?: ContactPointView | CallTargetView;
    newPerson?: boolean;
}

interface CreatePeopleState {
    loading: boolean;
    error: string | null;
    showCreateOrganizationDrawer: boolean;
    organizationId: string;
    isOrganizationPicked: boolean;
    jobTitle: string;
    email: ContactPointView | CallTargetView;
    phoneNumber: ContactPointView | CallTargetView;
    picture: UploadFile<any>;
    newPerson: boolean;
    isCustomer: boolean;
    isSupplier: boolean;
}

interface CreatePersonWithOrganizationRequest extends CreateOrUpdatePersonWebRequest {
    organizationId: string;
    email: string;  
    phoneNumber: string;
}

export class PersonCreateForm extends React.Component<CreatePeopleProps, CreatePeopleState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            showCreateOrganizationDrawer: false,
            organizationId: this.props.organizationId,
            isOrganizationPicked: this.props.filters?.organizationId != null || this.props.organizationId != null,
            jobTitle: null,
            email: null,
            phoneNumber: null,
            picture: null,
            newPerson: this.props.newPerson ?? null,
            isCustomer: false,
            isSupplier: false,
        }
    }

    onSubmit = async (request: CreatePersonWithOrganizationRequest) => {
        this.setState({ loading: true });

        try {
            const response = await client.people.createPerson(request);

            if (response) {
                if (this.state.picture) {
                    try {
                        //@ts-ignore
                        await client.people.updatePersonAvatar(response.data.id, { avatar: this.state.picture.originFileObj });
                    }
                    catch (error: any) {
                        openNotification("Error adding picture to person", "An error occured while trying to add the picture.", "error");
                    }
                }

                if (request.organizationId) {
                    try {
                        const orgResponse = await this.addOrganizationToPerson(response.data.id, request.organizationId, this.state.jobTitle);
                        if (orgResponse) {
                            response.data.organizationRoles = orgResponse.data.organizationRoles;
                        }
                    }
                    catch (error: any) {
                        openNotification("Error adding organization to person", "An error occured while trying to add the person to an organization.", "error");
                    }
                }

                if (request.email && this.props.cp?.type != ContactPointType.Email) {
                    try {
                        await client.contactPoints.createContactPoint({
                            type: ContactPointType.Email,
                            value: request.email,
                            contactId: response.data.id,
                            description: null
                        });
                    }
                    catch (error: any) {
                        openNotification("Error adding email to person", error.error, "error", null, "emailError", false);
                    }
                }

                if (request.phoneNumber && this.props.cp?.type != ContactPointType.Phone) {
                    try {
                        await client.contactPoints.createContactPoint({
                            type: ContactPointType.Phone,
                            value: request.phoneNumber.toString(),
                            contactId: response.data.id,
                            description: null
                        });
                    }
                    catch (error: any) {
                        openNotification("Error adding phone number to person", error.error, "error", null, "phoneError", false);
                    }
                }

                this.props.onComplete(response.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    toggleAddOrganizationDrawer = () => {
        this.setState({ showCreateOrganizationDrawer: !this.state.showCreateOrganizationDrawer });
    }

    onCompleteAddOrganization = async (data) => {
        try {
            if (data) {
                this.setState({
                    organizationId: data.id,
                    isOrganizationPicked: true
                });
                this.toggleAddOrganizationDrawer();
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onValueChanged = (allValues) => {
        if (allValues.organizationId != null && allValues.organizationId != undefined) {
            this.setState({
                isOrganizationPicked: true,
            })
        } else {
            this.setState({
                isOrganizationPicked: false,
            })
        }

        if (allValues.jobTitle != null && allValues.jobTitle != undefined) {
            this.setState({
                jobTitle: allValues.jobTitle,
            })
        } else {
            this.setState({
                jobTitle: null,
            })
        }
    }

    addOrganizationToPerson = async (personId: string, organizationId: string, jobTitle: string) => {
        try {
            const organizationRoleCommand: OrganizationRole = {
                accessLevel: OrganizationAccessLevel.None,
                description: jobTitle,
                jobTitle: jobTitle
            };

            const orgRes = await client.people.addOrUpdatePersonOrganizationRole(personId, organizationId, organizationRoleCommand);
            return orgRes;
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onEmailChange = (email) => {
        this.setState({ email });
    }

    onPhoneNumberChange = (phoneNumber) => {
        this.setState({ phoneNumber });
    }

    handlePictureChange = (info: UploadChangeParam<UploadFile<any>>) => {
        this.setState({ picture: info.file });
    }

    validateImage = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif';
        return isJpgOrPng;
    }

    removePicture = () => {
        this.setState({ picture: null });
    }

    onNewPersonChange = (event) => {
        this.setState({ newPerson: event.target.value });
    }

    render = () => {
        const initialValues: Partial<CreatePersonWithOrganizationRequest> = {};

        if (this.props.filters?.organizationId)
            initialValues.organizationId = this.props.filters.organizationId;
        else if (this.state.organizationId)
            initialValues.organizationId = this.state.organizationId;

        if (this.props.cp) {
            if (this.props.cp.type == ContactPointType.Phone)
                initialValues.phoneNumber = this.props.cp.value;
            else if (this.props.cp.type == ContactPointType.Email)
                initialValues.email = this.props.cp.value;
        }

        const standardPeopleCreateForm = (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={initialValues}
                onValuesChange={(changedValues, allValues) => this.onValueChanged(allValues)}
                className="person-create-form"
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please input your name"
                    placeholder="Name"
                    title="Name"
                    formItemClassName="actor-name-input"
                    titleLink={
                        this.state.picture ? <div className="form-picture-upload-container">{this.state.picture.name} <Button type="link" className="actor-picture-btn" icon={<DeleteOutlined />} onClick={this.removePicture} /></div> :
                            <div>
                                <Upload
                                    onChange={this.handlePictureChange}
                                    beforeUpload={this.validateImage}
                                    onRemove={this.removePicture}
                                    //headers={client.csrf()}
                                    maxCount={1}
                                    className="actor-form-picture-upload"
                                >
                                    <Button className="actor-picture-btn" icon={<UploadOutlined />} type="link">{this.context.isMobile ? "Add" : "Add picture"}</Button>
                                </Upload>
                            </div>
                    }
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                {this.props.fromOrganization
                    ? null
                    :
                    <>
                        <SelectorInput
                            title="Organization"
                            titleLink={
                                <React.Fragment>
                                    <Button type="link" onClick={this.toggleAddOrganizationDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`} disabled={this.props.filters?.organizationId != null || this.props.organizationId != null}>
                                        {this.context.isMobile ? "+ Add" : "+ Add organization"}
                                    </Button>
                                </React.Fragment>
                            }
                            param="organizationId"
                            selector={<OrganizationSelector filters={{}} placeholder="Select organization..." disabled={this.props.filters?.organizationId != null || this.props.organizationId != null} />}
                            className="include-selector-info-text"
                        />

                        <Drawer
                            title="Create organization"
                            onClose={this.toggleAddOrganizationDrawer}
                            open={this.state.showCreateOrganizationDrawer}
                            destroyOnClose={true}
                            component={this.state.showCreateOrganizationDrawer ?
                                <OrganizationCreateForm
                                    onCancel={this.toggleAddOrganizationDrawer}
                                    onComplete={(created) => created ? this.onCompleteAddOrganization(created) : this.toggleAddOrganizationDrawer()}
                                /> : null
                            }
                        />
                        <div className="selector-info-text">If needed, you can add more organizations after the person is created</div>
                    </>
                }

                {this.state.isOrganizationPicked ?
                    <TextInput
                        param="jobTitle"
                        warningMessage="Please input your name"
                        placeholder="Job title"
                        title="Job title"
                    />
                    :
                    null}

                <TextInput
                    param="email"
                    placeholder="Email"
                    title="Email"
                    disabled={this.props.cp?.type == ContactPointType.Email}
                />

                <TextInput
                    param="phoneNumber"
                    placeholder="Phone number"
                    title="Phone number"
                    disabled={this.props.cp?.type == ContactPointType.Phone}
                />

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Person] }} />}
                />

                <CustomFormLabel label="Type of business relation" optional />

                <Space>
                    <Form.Item
                        name="isCustomer"
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(e) =>
                                this.setState({ isCustomer: e.target.checked })
                            }
                        >Customer</Checkbox>
                    </Form.Item>

                    <Form.Item
                        name="isSupplier"
                        valuePropName="checked"
                    >
                        <Checkbox
                            onChange={(e) =>
                                this.setState({ isSupplier: e.target.checked })
                            }
                        >Supplier</Checkbox>
                    </Form.Item>
                </Space>

                {this.state.isCustomer ? (
                    <>
                        <NumberInput
                            param="creditLimit"
                            title="Credit limit"
                            placeholder="Enter"
                            addonAfter="kr"
                        />
                        <TextInput param="invoiceEmail" title="Invoice email" placeholder="Enter" type="email" />
                        <SelectorInput 
                            param="customerCategoryId"
                            title="Customer category"
                            selector={<CustomerCategorySelector />}
                        />
                    </>
                ) : null}
                
                {this.state.isSupplier ? (
                    <>
                        <SelectorInput 
                            param="supplierCategoryId"
                            title="Supplier category"
                            selector={<SupplierCategorySelector />}
                        />
                    </>
                ) : null}
            </BaseForm>
        );

        return (
            <>
                {this.props.newPerson == null ? null :
                    <div className="new-or-registered-person-form">
                        <div className="form-label">Is the person already registered in the system?</div>

                        <Radio.Group onChange={this.onNewPersonChange} value={this.state.newPerson}>
                            <Space direction="vertical">
                                <Radio value={true}>No, the person is new</Radio>
                                <Radio value={false}>Yes, the person has been registered</Radio>
                            </Space>
                        </Radio.Group>
                    </div>
                }

                {this.props.newPerson == null || this.state.newPerson ? standardPeopleCreateForm :
                    <OrganizationPersonRoleForm
                        organizationId={this.props.filters && this.props.filters.organizationId ? this.props.filters.organizationId : this.props.organizationId ? this.props.organizationId : null}
                        onComplete={(updated) => this.props.onComplete(updated)}
                        onCancel={this.props.onCancel}
                    />
                }
            </>
        );
    }
}

export default PersonCreateForm;