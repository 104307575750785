import * as React from "react";
import _ from 'lodash';
import { CreateOrderline, DecimalOrderlineStatus, OrderType, OrderlineStatus, OrderlineView, ProductUnit, ProductView, UnitPrice } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { NumberInput } from "../Shared/NumberInput";
import { ProductSelector, ProductUnitSelector } from "../Products";
import { PriceInput } from "../Shared/PriceInput";
import OrderPriceSelector from "./OrderPriceSelector";
import { PercentageInput } from "../Shared/PercentageInput";


export interface CreateOrderlineProps {
    orderline?: Partial<OrderlineView>,
    onComplete: (request: CreateOrderline, product: ProductView) => Promise<void>,
    onCancel?: () => void,
    orderType?: OrderType,
}

export interface CreateOrderlineState {
    loading: boolean,
    error: string,
    product: ProductView,
    unit: ProductUnit,
    salesRadioValue: "auto" | "fixed"
    costRadioValue: "auto" | "fixed"
}

interface OrderlineRequest {
    reference?: string | null;
    productId: string;
    description?: string | null;
    quantity: number;
    unit?: ProductUnit;
    costPrice?: number;
    salesPrice: number;
    discount: number;
}

export class OrderlineForm extends React.Component<CreateOrderlineProps, CreateOrderlineState> {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            product: null,
            unit: null,
            salesRadioValue: "auto",
            costRadioValue: "auto",
        }
    }

    componentDidMount = async () => {

        if (this.props.orderline && this.props.orderline.product) {
            try {
                const response = await client.products.getProductById(this.props.orderline.product.id);
                this.setState({ product: response.data.view });
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onComplete = async (request: OrderlineRequest) => {
        const salesPrice: DecimalOrderlineStatus = { value: request.salesPrice, status: this.state.salesRadioValue === "auto" ? OrderlineStatus.Pending : OrderlineStatus.Final };
        const costPrice: DecimalOrderlineStatus = { value: request.costPrice, status: this.state.costRadioValue === "auto" ? OrderlineStatus.Pending : OrderlineStatus.Final };
        const discount: DecimalOrderlineStatus = { value: request.discount, status: OrderlineStatus.Final };

        const orderlineRequest: CreateOrderline = {
            productId: request.productId,
            quantity: request.quantity,
            salesPrice,
            costPrice,
            discount,
            description: request.description,
            reference: request.reference,
            unit: request.unit
        };
     
        await this.props.onComplete(orderlineRequest, this.state.product);
    }

    validateProduct = (product: ProductView) => {
        this.setState({ error: null });
        if (this.props.orderType !== OrderType.Production) {
            return;
        }

        if (product.parts?.length !== 0) {
            return;
        }

        this.setState({ error: "Products on production order must be a product package" });
    }

    onSalesPriceChange = (e) => {
        this.setState({ salesRadioValue: e.target.value });
    }

    onCostPriceChange = (e) => {
        this.setState({ costRadioValue: e.target.value });
    }

    onProductChange = async (product: ProductView) => {
        if (product) {
            this.validateProduct(product);
            this.setState({ product: product, unit: null });
        } else {
            this.setState({ product: null, unit: null });
        }
    }

    onUnitChange = (unit) => {
        this.setState({ unit });
    }

    render = () => {
        let unit = this.state.unit != null ? this.state.unit : null;
        if (!unit)
            unit = this.state.product != null ? this.state.product.defaultUnit : null;
        if(!unit)
            unit = this.props.orderline != null ? this.props.orderline.unit : null;

        let costPrice = this.props.orderline != null ? this.props.orderline.costPrice : null;
        let salesPrice = this.props.orderline != null ? this.props.orderline.salesPrice : null;
        const description = this.props.orderline != null ? this.props.orderline.description : this.state.product != null ? this.state.product.description : null;
        
        if (this.state.product != null && this.state.product.prices.length > 0 && unit != null) {
            const costPrices = _.filter(this.state.product.prices, (p) => { return p.unit === unit && p.cost != null });
            if (costPrices.length > 0)
                costPrice = {
                    status: OrderlineStatus.Pending,
                    value: costPrices[0].cost
                };

            const salesPrices = _.filter(this.state.product.prices, (p) => { return p.unit === unit && p.sales != null });
            if (salesPrices.length > 0)
                salesPrice = {
                    status: OrderlineStatus.Pending,
                    value: salesPrices[0].sales
                };
        }

        const initalValuesFromOrderline = this.props.orderline != null ? {
            productId: this.state.product ? this.state.product.id : this.props.orderline.product ? this.props.orderline.product.id : null,
            reference: this.props.orderline.reference,
            discount: this.props.orderline.discount?.value,
            quantity: this.props.orderline.quantity,
            salesPrice: this.props.orderline.salesPrice?.value,
            costPrice: this.props.orderline.costPrice?.value,
        } : {
            //salesPrice: 0,
            //costPrice: 0
        };

        const getPrices = () => {
            const filteredPrices = _.filter(this.state.product?.prices, p => p.unit === unit);
            const priceList = filteredPrices.length > 0 ? filteredPrices : this.state.product?.prices.filter(x => x.unit == null);

            let lowestPrices: UnitPrice;
            if (priceList !== undefined && priceList.length > 0) {
                const lowestCostPrice = priceList?.reduce((previous, current) => previous.cost < current.cost ? previous : current).cost;
                const lowestSalesPrice = priceList?.reduce((previous, current) => previous.sales < current.sales ? previous : current).sales;

                lowestPrices = {
                    cost: lowestCostPrice ?? 0,
                    sales: lowestSalesPrice ?? 0,
                    unit: unit
                }
            }
            return lowestPrices;
        }
        
        const prices = getPrices();
        
        const productCostPrice = this.state.product != null ? {
            status: OrderlineStatus.Pending,
            value: prices?.cost ?? 0,
        } as DecimalOrderlineStatus : null

        const productSalesPrice = this.state.product != null ? {
            status: OrderlineStatus.Pending,
            value: prices?.sales ?? 0,
        } as DecimalOrderlineStatus : null

        const initalValuesFromProduct = {
            description: description,
            unit: unit,
            costPrice: productCostPrice?.value,
            salesPrice: productSalesPrice?.value,
            sales: this.state.salesRadioValue,
            cost: this.state.costRadioValue
        };
        

        const initialValues = Object.assign({}, this.props.orderline ? initalValuesFromOrderline : initalValuesFromProduct)

        const pendingSalesPrice = this.props.orderline?.salesPrice?.status === OrderlineStatus.Pending;
        const pendingCostPrice = this.props.orderline?.costPrice?.status === OrderlineStatus.Pending;
        //var pendingDiscount = this.props.orderline?.discount?.status === OrderlineStatus.Pending;

        return (
            <BaseForm
                type={this.props.orderline != null ? FormType.Edit : FormType.Create}
                onSubmit={this.onComplete}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={initialValues}
            >
                <TextInput
                    param="reference"
                    placeholder="Reference..."
                    title="Reference"
                />

                <SelectorInput
                    param="productId"
                    title="Product"
                    required
                    warningMessage="Please select a product"
                    selector={
                        <ProductSelector
                            onObjectChange={this.onProductChange}
                            filters={this.props.orderType === OrderType.Production ? {withParts: true} : null }
                        />
                    }
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                <NumberInput
                    param="quantity"
                    title="Quantity"
                    required
                    warningMessage="Please type the quantity"
                />

                <SelectorInput
                    param="unit"
                    title="Unit"
                    warningMessage="Please select a unit"
                    selector={
                        <ProductUnitSelector
                            onChange={this.onUnitChange}
                            excludeAllOption
                        />
                    }
                />

                {this.props.orderline == null || (this.props.orderline.id && this.props.orderline.id.includes("-created"))
                    ? <OrderPriceSelector key={this.state.salesRadioValue + unit + this.state.product?.id}
                        radioValue={this.state.salesRadioValue}
                        title="Sales price"
                        param="sales"
                        onSalesPriceChange={this.onSalesPriceChange}
                        unit={unit}
                        product={this.state.product}
                        selectorProps={{
                            param: "salesPrice",
                            required: true,
                            warningMessage: "Please type the sales price"
                        }}
                    />
                    : <PriceInput
                        param="salesPrice"
                        title="Sales price"
                        required
                        warningMessage="Please type the sales price"
                        footnote={pendingSalesPrice ? "Orderline has yet to recieve updated price from external order system" : null}
                />}

                {this.props.orderline == null || (this.props.orderline.id && this.props.orderline.id.includes("-created"))
                    ? <OrderPriceSelector key={"cost" + this.state.costRadioValue + unit + this.state.product?.id}
                        title="Cost price"
                        radioValue={this.state.costRadioValue}
                        onSalesPriceChange={this.onCostPriceChange}
                        unit={unit}
                        param="cost"
                        product={this.state.product}
                        selectorProps={{
                            param: "costPrice",
                            required: true,
                            warningMessage: "Please type the cost price"
                        }}
                    />
                    : <PriceInput
                        param="costPrice"
                        title="Cost price"
                        required
                        warningMessage="Please type the cost price"
                        footnote={pendingCostPrice ? "Orderline has yet to recieve updated cost price from external order system" : null}
                />}

                <PercentageInput
                    param="discount"
                    title="Discount"
                    warningMessage="Please type the discount"
                />
            </BaseForm>
        );
    }
}

export default OrderlineForm;