/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateLogin,
  LoginSortOption,
  LoginWebView,
  LoginWebViewEntityViewModel,
  LoginWebViewPagedLoginQueryCollectionViewModel,
  SortDirection,
  UpdateLogin,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Logins<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Login
   * @name GetLoginById
   * @request GET:/api/logins/{id}
   * @secure
   */
  getLoginById = (id: string, params: RequestParams = {}) =>
    this.http.request<LoginWebViewEntityViewModel, any>({
      path: `/api/logins/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name UpdateLogin
   * @request PUT:/api/logins/{id}
   * @secure
   */
  updateLogin = (id: string, data: UpdateLogin, params: RequestParams = {}) =>
    this.http.request<LoginWebView, any>({
      path: `/api/logins/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name DeleteLogin
   * @request DELETE:/api/logins/{id}
   * @secure
   */
  deleteLogin = (id: string, params: RequestParams = {}) =>
    this.http.request<LoginWebView, any>({
      path: `/api/logins/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name CreateLogin
   * @request POST:/api/logins
   * @secure
   */
  createLogin = (data: CreateLogin, params: RequestParams = {}) =>
    this.http.request<LoginWebView, any>({
      path: `/api/logins`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name QueryLogins
   * @request GET:/api/logins
   * @secure
   */
  queryLogins = (
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      sortBy?: LoginSortOption;
      sortDirection?: SortDirection;
      actorId?: string;
      roleIds?: string[];
      roleApplicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<LoginWebViewPagedLoginQueryCollectionViewModel, any>({
      path: `/api/logins`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name RestoreLogin
   * @request POST:/api/logins/{id}/restore
   * @secure
   */
  restoreLogin = (id: string, params: RequestParams = {}) =>
    this.http.request<LoginWebView, any>({
      path: `/api/logins/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Login
   * @name UpdateLoginAttributes
   * @request POST:/api/logins/{id}/attributes
   * @secure
   */
  updateLoginAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<LoginWebView, any>({
      path: `/api/logins/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
