/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CommentSortOption,
  CommentView,
  CommentViewEntityViewModel,
  CommentViewPagedCommentQueryCollectionViewModel,
  CreateComment,
  CreateFileOrFolder,
  CreateOrUpdateTicket,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  PreviewType,
  RevertFileToVersion,
  SortDirection,
  TicketAggregationResultTicketAggregateQueryAggregationResults,
  TicketAggregationTypes,
  TicketSeverity,
  TicketSortOption,
  TicketStatus,
  TicketView,
  TicketViewEntityViewModel,
  TicketViewPagedTicketQueryCollectionViewModel,
  UpdateComment,
  UpdateFileOrFolder,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Tickets<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Ticket
   * @name GetTicketById
   * @request GET:/api/tickets/{id}
   * @secure
   */
  getTicketById = (id: string, params: RequestParams = {}) =>
    this.http.request<TicketViewEntityViewModel, any>({
      path: `/api/tickets/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name UpdateTicket
   * @request PUT:/api/tickets/{id}
   * @secure
   */
  updateTicket = (id: string, data: CreateOrUpdateTicket, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name DeleteTicket
   * @request DELETE:/api/tickets/{id}
   * @secure
   */
  deleteTicket = (id: string, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name CreateTicket
   * @request POST:/api/tickets
   * @secure
   */
  createTicket = (data: CreateOrUpdateTicket, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name QueryTickets
   * @request GET:/api/tickets
   * @secure
   */
  queryTickets = (
    query?: {
      sortBy?: TicketSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      requesterId?: string;
      assigneeId?: string;
      customerId?: string;
      customerIdOrAssigneeId?: string;
      assigneeIdOrOtherParticipantId?: string;
      relatedToId?: string;
      /** @format date-time */
      dueAfter?: string;
      /** @format date-time */
      dueBefore?: string;
      closed?: boolean;
      onHold?: boolean;
      status?: TicketStatus[];
      severity?: TicketSeverity[];
      /** @format date-time */
      closedAfter?: string;
      /** @format date-time */
      closedBefore?: string;
      tagIds?: string[];
      otherParticipantIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TicketViewPagedTicketQueryCollectionViewModel, any>({
      path: `/api/tickets`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name RestoreTicket
   * @request POST:/api/tickets/{id}/restore
   * @secure
   */
  restoreTicket = (id: string, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name UpdateTicketAttributes
   * @request POST:/api/tickets/{id}/attributes
   * @secure
   */
  updateTicketAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name CloseTicket
   * @request POST:/api/tickets/{id}/close
   * @secure
   */
  closeTicket = (id: string, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets/${id}/close`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name OpenTicket
   * @request POST:/api/tickets/{id}/open
   * @secure
   */
  openTicket = (id: string, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets/${id}/open`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name SetTicketOnHold
   * @request POST:/api/tickets/{id}/onhold
   * @secure
   */
  setTicketOnHold = (id: string, params: RequestParams = {}) =>
    this.http.request<TicketView, any>({
      path: `/api/tickets/${id}/onhold`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name GetTicketAggregates
   * @request GET:/aggs/tickets
   * @secure
   */
  getTicketAggregates = (
    query?: {
      aggs?: TicketAggregationTypes[];
      requesterId?: string;
      assigneeId?: string;
      customerId?: string;
      customerIdOrAssigneeId?: string;
      assigneeIdOrOtherParticipantId?: string;
      relatedToId?: string;
      /** @format date-time */
      dueAfter?: string;
      /** @format date-time */
      dueBefore?: string;
      closed?: boolean;
      onHold?: boolean;
      status?: TicketStatus[];
      severity?: TicketSeverity[];
      /** @format date-time */
      closedAfter?: string;
      /** @format date-time */
      closedBefore?: string;
      tagIds?: string[];
      otherParticipantIds?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TicketAggregationResultTicketAggregateQueryAggregationResults, any>({
      path: `/aggs/tickets`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ticket
   * @name GetTicketAggregatesById
   * @request GET:/aggs/tickets/{id}
   * @secure
   */
  getTicketAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/tickets/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketComment
   * @name GetTicketCommentById
   * @request GET:/api/tickets/{entityId}/comments/{commentId}
   * @secure
   */
  getTicketCommentById = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentViewEntityViewModel, any>({
      path: `/api/tickets/${entityId}/comments/${commentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketComment
   * @name UpdateTicketComment
   * @request PUT:/api/tickets/{entityId}/comments/{commentId}
   * @secure
   */
  updateTicketComment = (entityId: string, commentId: string, data: UpdateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tickets/${entityId}/comments/${commentId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketComment
   * @name DeleteTicketComment
   * @request DELETE:/api/tickets/{entityId}/comments/{commentId}
   * @secure
   */
  deleteTicketComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tickets/${entityId}/comments/${commentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketComment
   * @name CreateTicketComment
   * @request POST:/api/tickets/{entityId}/comments
   * @secure
   */
  createTicketComment = (entityId: string, data: CreateComment, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tickets/${entityId}/comments`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketComment
   * @name QueryTicketComments
   * @request GET:/api/tickets/{entityId}/comments
   * @secure
   */
  queryTicketComments = (
    entityId: string,
    query?: {
      sortBy?: CommentSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CommentViewPagedCommentQueryCollectionViewModel, any>({
      path: `/api/tickets/${entityId}/comments`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketComment
   * @name RestoreTicketComment
   * @request POST:/api/tickets/{entityId}/comments/{commentId}/restore
   * @secure
   */
  restoreTicketComment = (entityId: string, commentId: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tickets/${entityId}/comments/${commentId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketComment
   * @name AddTicketCommentEmoji
   * @request POST:/api/tickets/{entityId}/comments/{commentId}/reactions/{emojicode}
   * @secure
   */
  addTicketCommentEmoji = (entityId: string, commentId: string, emojicode: string, params: RequestParams = {}) =>
    this.http.request<CommentView, any>({
      path: `/api/tickets/${entityId}/comments/${commentId}/reactions/${emojicode}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name GetTicketFileById
   * @request GET:/api/tickets/{entityId}/files/{fileId}
   * @secure
   */
  getTicketFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/tickets/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name UpdateTicketFile
   * @request PUT:/api/tickets/{entityId}/files/{fileId}
   * @secure
   */
  updateTicketFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/tickets/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name DeleteTicketFile
   * @request DELETE:/api/tickets/{entityId}/files/{fileId}
   * @secure
   */
  deleteTicketFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tickets/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name DownloadTicketFile
   * @request GET:/api/tickets/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadTicketFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/tickets/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name PreviewTicketFile
   * @request GET:/api/tickets/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewTicketFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/tickets/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name CreateTicketFile
   * @request POST:/api/tickets/{entityId}/files
   * @secure
   */
  createTicketFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/tickets/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name QueryTicketFiles
   * @request GET:/api/tickets/{entityId}/files
   * @secure
   */
  queryTicketFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/tickets/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name RestoreTicketFile
   * @request POST:/api/tickets/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreTicketFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tickets/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name MoveTicketFile
   * @request POST:/api/tickets/{entityId}/files/{fileId}/move
   * @secure
   */
  moveTicketFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tickets/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name RevertTicketFile
   * @request POST:/api/tickets/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertTicketFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/tickets/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name GetTicketFolderById
   * @request GET:/api/tickets/{entityId}/folders/{folderId}
   * @secure
   */
  getTicketFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/tickets/${entityId}/folders/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name UpdateTicketFolder
   * @request PUT:/api/tickets/{entityId}/folders/{folderId}
   * @secure
   */
  updateTicketFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tickets/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name DeleteTicketFolder
   * @request DELETE:/api/tickets/{entityId}/folders/{folderId}
   * @secure
   */
  deleteTicketFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tickets/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name CreateTicketFolder
   * @request POST:/api/tickets/{entityId}/folders
   * @secure
   */
  createTicketFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tickets/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name QueryTicketFolders
   * @request GET:/api/tickets/{entityId}/folders
   * @secure
   */
  queryTicketFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/tickets/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name RestoreTicketFolder
   * @request POST:/api/tickets/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreTicketFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tickets/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TicketFile
   * @name MoveTicketFolder
   * @request POST:/api/tickets/{entityId}/{folderId}/move
   * @secure
   */
  moveTicketFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/tickets/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
