import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { Button, Typography } from 'antd';
import { PlusCircleOutlined, ImportOutlined } from '@ant-design/icons';
import { PagedTaskQuery, ProjectRoleType, ProjectType, ProjectView, TaskSortOption, TaskView } from "../../ApiClient/swagger/data-contracts";
import { ListViewState } from "../../Models/ListViewState";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { Capabilities } from "../../Definitions/_capabilties";
import { handleListDuplicates, openNotification, updateCollectionFromEvent } from "../../Helpers/BasePageHelpers";
import { addParamsToQuery } from "../../Helpers/QueryHashHandlers";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { ActorLink } from "../Actors";
import { CategoryLink } from "../Categories";
import { formatDuration } from "../../Helpers/Formatters";
import ProgressBar from "../Shared/ProgressBar";
import { RequireCapability } from "../Shared/RequireCapability";
import { Drawer } from "../Shared/Drawer";
import TaskCreateForm from "./TaskCreateForm";
import TaskImportForm from "./TaskImportForm";
import { ListView } from "../Shared/ListView";
import StaticSelector from "../Shared/StaticSelector";
import { CustomRouter, withRouter } from "../../Hooks/withRouter";
import TaskEditForm from "./TaskEditForm";


const { Title } = Typography;

interface TasksProps {
    filters?: Partial<PagedTaskQuery>;
    title?: string;
    showImport?: boolean;
    projectId: string;
    getActions?: (actions: React.ReactNode) => void;
    onNavigate?: (taskId: string) => void;
    onCancel?: () => void;
    router?: CustomRouter;
}

interface TasksState extends ListViewState<TaskView, PagedTaskQuery> {
    expanded: object;
    showImportDrawer?: boolean;
    showTaskImportDrawer?: boolean;
    project: ProjectView;
    actions: React.ReactNode;
    loadingClosedIds: string[];
}

export class BaseTaskListCard extends React.Component<TasksProps, TasksState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
        this.state = {
            collection: [],
            query: {
                sortBy: TaskSortOption.Name
            },
            loading: false,
            count: 0,
            showCreateDrawer: false,
            showImportDrawer: false,
            showTaskImportDrawer: false,
            error: null,
            expanded: {},
            project: null,
            actions: null,
            loadingClosedIds: []
        }
    }

    componentDidMount = async () => {
        this.context.events.tasks.onMany({
            'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'opened': this.onItemUpdatedEvent,
            'closed': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent
        });

        let project = null;
        if (this.props.projectId) {
            const response = await client.projects.getProjectById(this.props.projectId);
            if (response) {
                project = response.data.view;
                this.setState({ project: response.data.view });
            }
        }

        const query = Object.assign({}, this.state.query);
        _.each(this.props.filters, (filter, key) => {
            query[key] = filter;
        });

        this.loadCollection(query);

        const user = this.context.user;
        const userRole = _.find(project?.roles ?? [], r => r.actor.id == user.actorId);

        const roleBased = userRole != null && user.hasCapability(Capabilities.ProjectsRoleBased) ? userRole.role : null;
        const roleBased_edit = roleBased && roleBased != ProjectRoleType.Viewer && roleBased != ProjectRoleType.Participant;

        const canWriteProject = roleBased_edit || user.hasCapability(Capabilities.ProjectsWrite);

        const actions = (
            <React.Fragment>
                <div className="open-closed-selector">
                    <StaticSelector
                        placeholder="Select status..."
                        onChange={(value) => this.onQueryChange({ closed: value })}
                        size="small"
                        options={[
                            {
                                name: 'Closed',
                                value: true
                            },
                            {
                                name: 'Open',
                                value: false
                            }
                        ]}
                    />
                </div>
                {this.props.showImport && canWriteProject && this.props.filters.projectId ? <Button size="small" icon={this.context.isMobile ? <ImportOutlined /> : null} type="default" className="action" onClick={() => this.toggleImportDrawer()}>{this.context.isMobile ? null : "Import from project"}</Button> : null}
                {this.props.showImport && canWriteProject && this.props.filters.parentId ? <Button size="small" icon={this.context.isMobile ? <ImportOutlined /> : null} type="default" className="action" onClick={() => this.toggleTaskImportDrawer()}>{this.context.isMobile ? null : "Import subtasks"}</Button> : null}
                {canWriteProject ? <Button size="small" icon={this.context.isMobile ? <PlusCircleOutlined /> : null} type="default" className="action" onClick={this.toggleCreateDrawer}> {this.context.isMobile ? null : "Add task"}</Button> : null}
            </React.Fragment>
        );

        this.setState({ actions });

        if (this.props.getActions && this.context.user.hasCapability(Capabilities.ProjectsRoleBased)) {
            this.props.getActions(actions);
        }
    }

    componentWillUnmount = () => {
        this.context.events.tasks.offMany({
            'created': this.onItemCreatedEvent,
            'updated': this.onItemUpdatedEvent,
            'opened': this.onItemUpdatedEvent,
            'closed': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent
        });
    }

    onItemCreatedEvent = (eventData: TaskView) => {
        if (this.props.filters.projectId == eventData.project.id) {
            const taskInCollection = _.find(this.state.collection, (task: TaskView) => { return task.id == eventData.id });
            if (taskInCollection) {
                return;
            }

            if (!this.props.filters.parentId && eventData.parents?.length == 0) {
                var taskCollection = this.state.collection.slice();
                taskCollection.push(eventData);
                this.setState({ collection: taskCollection });
            }
            else if (eventData.parents && eventData.parents.length && this.props.filters.parentId == eventData.parents[0].id) {
                var taskCollection = this.state.collection.slice();
                taskCollection.push(eventData);
                this.setState({ collection: taskCollection });
            }
            else if (eventData.parents && eventData.parents.length) {
                const expandedParent: TaskView[] = this.state.expanded[eventData.parents[0].id];
                const inExpanded = expandedParent ? _.filter(expandedParent, (task) => { return task.id == eventData.id }) : null;
                if (inExpanded || !expandedParent) return;

                this.setState(state => {
                    state.expanded[eventData.parents[0].id].push(eventData);
                    return { expanded: state.expanded };
                });
            }
            else if ((eventData.parents == null || eventData.parents.length == 0) && eventData.project.id == this.props.filters.projectId) {
                const taskCollection = this.state.collection.slice();
                taskCollection.push(eventData);
                this.setState({ collection: taskCollection });
            }
        }
        else if (this.props.filters.parentId) {
            const parentExists = eventData && eventData.parents ? _.find(eventData.parents || [], p => { return p.id == this.props.filters.parentId; }) : null;

            if (parentExists) {
                const subTasks = this.state.collection.slice();
                const alreadyExists = _.find(subTasks, t => { return t.id == eventData.id; });

                if (!alreadyExists) {
                    subTasks.push(eventData);
                    this.setState({ collection: subTasks });
                }
            }
        }
    }

    onItemUpdatedEvent = async (eventData: TaskView) => {
        if (this.props.projectId == eventData.project.id) {
            if ((!this.props.filters.parentId && eventData.parents?.length == 0) || (eventData.parents?.length > 0 && this.props.filters.parentId == eventData.parents[0].id)) {
                const newCollection = await updateCollectionFromEvent(this.state.collection, eventData);
                this.setState({ collection: newCollection as TaskView[] });
            }
            else if (eventData.parents?.length > 0 && this.state.expanded[eventData.parents[0].id]) {
                const index = _.indexOf(this.state.expanded[eventData.parents[0].id], _.find(this.state.expanded[eventData.parents[0].id], x => x.id == eventData.id));
                if (index > -1)
                    this.setState(state => {
                        state.expanded[eventData.parents[0].id][index] = eventData;
                        return { expanded: state.expanded }
                    });
            }
            else if ((eventData.parents == null || eventData.parents.length == 0) && eventData.project.id == this.props.filters.projectId) {
                const newCollection = await updateCollectionFromEvent(this.state.collection, eventData);
                this.setState({ collection: newCollection as TaskView[] });
            }
        }
    }

    loadCollection = async (query) => {
        this.setState({ loading: true });

        try {
            const response = await client.tasks.queryTasks(query);

            if (response)
                this.setState({ collection: response.data.items, query: response.data.query, count: response.data.count, expanded: {} });
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    loadMore = async () => {
        if (this.state.loading || this.state.collection.length >= this.state.count) return;
        this.setState({ loading: true });

        const query = Object.assign({}, this.state.query);
        query.from += query.limit;

        try {
            const response = await client.tasks.queryTasks(query);
            if (response) {
                const newCollection = await handleListDuplicates(this.state.collection, response.data.items);

                this.setState({
                    collection: newCollection,
                    query: response.data.query,
                    count: response.data.count
                });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onSelect = async (data: TaskView) => {
        if (!data) return;

        if (this.props.onNavigate)
            this.props.onNavigate(data.id);
        else if (this.props.router)
            this.props.router.navigate(`/tasks/${data.id}`)
    }

    onExpand = async (expanded, record) => {
        if (!expanded) {
            const expandedEntry = this.state.expanded[record.id];
            if (expandedEntry) {
                const copy = Object.assign({}, this.state.expanded);
                delete copy[record.id]
                this.setState({ expanded: copy });
            }
            return;
        }

        this.setState({ loading: true });
        try {
            const query = { parentId: record.id, sortBy: TaskSortOption.Name }
            if (this.props.filters.deleted != null)
                query["deleted"] = this.props.filters.deleted;

            const response = await client.tasks.queryTasks(query);
            if (response) {
                const clone = this.state.expanded;
                clone[record.id] = response.data.items;

                this.setState({ expanded: clone });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onQueryChange = async (group) => {
        let query = Object.assign({}, this.state.query);
        query = await addParamsToQuery(query, group, true);
        this.loadCollection(query);
    }

    toggleCreateDrawer = async () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    toggleImportDrawer = async (tasks?: TaskView[]) => {
        this.setState({ showImportDrawer: !this.state.showImportDrawer });
        if (tasks) {
            const collection = this.state.collection?.slice() ?? [];
            _.each(tasks, task => {
                const index = _.findIndex(collection, c => c.id == task.id);
                if (index == -1 && (task.parents == null || task.parents.length == 0))
                    collection.push(task);
            });

            this.setState({ collection });
        }
    }

    toggleTaskImportDrawer = async (tasks?: TaskView[]) => {
        this.setState({ showTaskImportDrawer: !this.state.showTaskImportDrawer });
        if (tasks) {
            const collection = this.state.collection?.slice() ?? [];
            _.each(tasks, task => {
                const index = _.findIndex(collection, c => c.id == task.id);
                if (index == -1 && (task.parents == null || task.parents.length == 0))
                    collection.push(task);
            });

            this.setState({ collection });
        }
    }

    closeTask = async (event, task: TaskView) => {
        event.stopPropagation();

        const loadingClosedIds = this.state.loadingClosedIds.slice();

        if (!loadingClosedIds.includes(task.id)) {
            loadingClosedIds.push(task.id);
            this.setState({ loadingClosedIds });
        }

        const response = await client.tasks
            .closeTask(task.id, addChangeVectorHeader(task.changeVector))
            .catch((exception) => openNotification("Error closing task", exception.error.title));

        if (response) this.onItemUpdatedEvent(response.data);

        if (loadingClosedIds.includes(task.id)) {
            loadingClosedIds.splice(0, 1);
            this.setState({ loadingClosedIds });
        }
    }

    updateTaskFromEvent = (task: TaskView) => {
        const collection = this.state.collection?.slice() ?? [];
        const index = _.findIndex(collection, c => c.id == task.id);

        if (index == -1 && task.project.id == this.props.projectId)
            collection.push(task);
        else if (index != -1)
            collection[index] = task;

        this.setState({ collection });

        openNotification(`Task updated`,
            `${task.name} has been updated with latest changes`,
            "success",
            null,
            task.id
        );
    }


    render = () => {

        const columns = [
            {
                title: 'Task name',
                render: (task: TaskView) => <div className="taskNameContainer">{task.name}</div>,
                key: 'name',
                width: 400
            },
            {
                title: 'Task owner',
                render: (task: TaskView) => task.owner ? <ActorLink {...task.owner} /> : "",
                key: 'owner',
                width: 200,
                responsive: ['md']
            },
            {
                title: 'Assignee',
                render: (task: TaskView) => task.assignee ? <ActorLink {...task.assignee} /> : "",
                key: 'assignee',
                ellipsis: true,
                width: 200,
                responsive: ['xxl']
            },
            {
                title: 'Category',
                render: (task: TaskView) => task.category ? <CategoryLink {...task.category} /> : "",
                key: 'category',
                sorter: 'category',
                ellipsis: true,
                width: 150,
                responsive: ['md']
            },
            {
                title: 'Planned end',
                render: (task: TaskView) => task.plannedEnd ? moment(task.plannedEnd).format('DD.MM.YYYY') : "",
                key: 'end',
                width: 100,
                responsive: ['xxl']
            },
            {
                title: '',
                key: 'duration',
                render: (task: TaskView) => {
                    const aggregatedDuration = task.aggregatedDuration ?? 0;
                    return formatDuration(aggregatedDuration);
                },
                align: 'right',
                width: 100,
                responsive: ['md']
            },
            {
                title: 'Progress',
                key: 'progress',
                render: (task: TaskView) => {
                    const aggregatedEstimate = task.aggregatedEstimate ?? 0;
                    const aggregatedDuration = task.aggregatedDuration ?? 0;

                    return aggregatedEstimate != null && aggregatedEstimate > 0 ?
                        <ProgressBar hideDurationText estimate={aggregatedEstimate} duration={aggregatedDuration} complete={task.actualEnd != null} /> : "";
                },
                width: 200,
                responsive: ['md']
            },
            {
                title: 'Estimate',
                key: 'estimate',
                render: (task: TaskView) => {
                    const aggregatedEstimate = task.aggregatedEstimate ?? 0;
                    if (aggregatedEstimate != null && aggregatedEstimate > 0)
                        return formatDuration(task.aggregatedEstimate ?? 0);
                    else
                        return null;
                },
                width: 100,
                responsive: ['xxl']
            },
            {
                title: 'Closed',
                key: 'close',
                render: (task: TaskView) => task != null ? task.actualEnd
                    ? moment(task.actualEnd).format('DD.MM.YYYY') : task.assignee != null && task.owner != null && (task.assignee.id == this.context.user.actorId || task.owner.id == this.context.user.actorId)
                        ? <RequireCapability capability={Capabilities.ProjectsRoleBased} > <Button onClick={(e) => this.closeTask(e, task)} loading={this.state.loadingClosedIds.includes(task.id)}>Close</Button></RequireCapability>
                        : <RequireCapability capability={Capabilities.ProjectsRoleBased}> <Button onClick={(e) => this.closeTask(e, task)} loading={this.state.loadingClosedIds.includes(task.id)}>Close</Button></RequireCapability>
                    : null,
                width: 90,
                responsive: ['xxl']
            }
        ];

        if (this.state.project && this.state.project.type == ProjectType.Hourly) {
            columns.splice(5, 0,
                //@ts-ignore
                {
                    title: 'Discount',
                    render: (data: TaskView) => data.discount ? `${data.discount}%` : "",
                    key: 'discount',
                    width: 100,
                    responsive: ['xxl']
                });
        }

        const { user } = this.context;
        const userRole = this.state.project != null ? _.find(this.state.project.roles ?? [], r => r.actor.id == user.actorId) : null;

        const roleBased = userRole != null && user.hasCapability(Capabilities.ProjectsRoleBased) ? userRole.role : null;
        const roleBased_edit = roleBased && roleBased != ProjectRoleType.Viewer && roleBased != ProjectRoleType.Participant;

        const canWriteProject = roleBased_edit || user.hasCapability(Capabilities.ProjectsWrite);

        return (
            <>
                <Drawer
                    title={this.props.filters && this.props.filters.parentId ? 'New sub task' : 'New task'}
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    destroyOnClose
                    component={
                        <TaskCreateForm
                            onCancel={this.toggleCreateDrawer}
                            project={this.state.project}
                            parentId={this.props.filters ? this.props.filters.parentId : null}
                            onComplete={(data) => {
                                this.onItemCreatedEvent(data);
                                this.toggleCreateDrawer();
                            }}
                        />
                    }
                />
                <Drawer
                    title={'Import tasks from project'}
                    onClose={() => this.toggleImportDrawer()}
                    open={this.state.showImportDrawer}
                    destroyOnClose
                    component={
                        <TaskImportForm
                            projectId={this.props.filters.projectId}
                            onComplete={this.toggleImportDrawer}
                            onCancel={() => this.toggleImportDrawer()}
                        />
                    }
                />

                <Drawer
                    title={'Import subtasks'}
                    onClose={() => this.toggleTaskImportDrawer()}
                    open={this.state.showTaskImportDrawer}
                    destroyOnClose
                    component={
                        <TaskImportForm
                            parentId={this.props.filters.parentId}
                            onComplete={this.toggleTaskImportDrawer}
                            onCancel={() => this.toggleTaskImportDrawer()}
                        />
                    }
                />

                <div className="list-view-card task-list-card">
                    <ListView
                        onEdit={{
                            title: 'Edit task',
                            requiredCapability: Capabilities.ProjectsWrite,
                            form: (data: TaskView, onClose: () => void) => {
                                return <TaskEditForm task={data} onComplete={(updated) => { this.updateTaskFromEvent(updated); onClose(); }} onCancel={onClose} />
                            }
                        }}
                        title={() =>
                            <React.Fragment>
                                <div className="list-title">
                                    <Title level={4} className="title">{this.props.title}</Title>
                                    <RequireCapability bypass={canWriteProject} capability={Capabilities.ProjectsWrite}>
                                        {this.state.actions}
                                    </RequireCapability>
                                </div>
                            </React.Fragment>
                        }
                        expandedValues={this.state.expanded}
                        size="small"
                        bordered
                        onQueryChange={this.onQueryChange}
                        columns={columns}
                        collection={this.state.collection}
                        loading={this.state.loading}
                        onSelect={this.onSelect}
                        onScrollEnd={this.loadMore}
                        onExpand={this.onExpand}
                    />
                </div>
            </>
        );
    }
}

export const TaskListCard = withRouter(BaseTaskListCard);
export default TaskListCard;