import * as React from "react";
import _ from 'lodash';
import AppContext from "../../Definitions/AppContext";
import { IncidentView } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Card, Form } from "antd";
import Title from "antd/es/typography/Title";
import { TextAreaInput } from "../Shared/TextAreaInput";
import BaseForm from "../Shared/Form";

interface IncidentCorrectiveActionsProps {
    incident: IncidentView;
    className?: string;
    writeAccess?: boolean;
    onCAAction: (incident: IncidentView) => void;
}

interface IncidentCorrectiveActionsState {
    value: string,
    conflict: boolean,
    error?: any
}

export class IncidentCorrectiveActionsCard extends React.Component<IncidentCorrectiveActionsProps, IncidentCorrectiveActionsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.incident ? this.props.incident.correctiveActions : null,
            conflict: false
        }
    }

    componentDidMount = () => {
        const self = this;
    }

    componentDidUpdate = (prevProps: IncidentCorrectiveActionsProps) => {
        if (!this.props.incident || prevProps == this.props) return;

        const newStringValue = this.props.incident.correctiveActions || "";
        const olderStringValue = prevProps.incident ? prevProps.incident.correctiveActions || "" : "";
        const editedStringValue = this.state.value || "";

        if (newStringValue === "" || newStringValue == editedStringValue) {
            if (this.state.conflict)
                this.setState({ conflict: false });

            this.setState({ value: this.props.incident.correctiveActions });
            return;
        }

        const isValueEdited = editedStringValue != olderStringValue;

        if (isValueEdited && newStringValue != olderStringValue) {
            this.setState({ conflict: true });
            return;
        }

        if (newStringValue != olderStringValue) {
            this.setState({ value: this.props.incident.correctiveActions, conflict: false })
        }
    }

    saveChanges = async () => {
        try {
            const response = await client.incidents.addOrUpdateCorrectiveAction(
                this.props.incident.id,
                { correctiveAction: this.state.value });

            if (response) this.props.onCAAction(response.data);

            this.setState({
                error: null
            })
        }
        catch (e) {
            this.setState({
                error: e
            });
        }
    }

    autoSaveChanges = _.debounce(this.saveChanges, 2000);

    onValueChange = (value) => {
        if (value !== this.state.value) {
            this.setState({ value });

            //if (value && value != "") {
                this.autoSaveChanges();
            //}
        }
    }

    render() {
        if (!this.props.incident) return null;

        const stringValue = this.state.value || "";
        const correctiveActionValue = this.props.incident.correctiveActions || "";

        const willDelete = stringValue == "" && correctiveActionValue != "";
        const isChanged = stringValue !== correctiveActionValue;
        const isConflict = this.state.conflict;
        const isEditable = this.props.writeAccess;
        const isClosed = this.props.incident.dates["closed"] != undefined;

        const text = willDelete ? "Delete" : isConflict ? "Override" : isChanged ? "Save" : "Saved";
        const icon = willDelete ? <DeleteOutlined /> : <SaveOutlined />;

        const save = isEditable
            ? !isClosed ?
                <Button className="action" onClick={this.saveChanges} disabled={!willDelete && !isChanged} icon={icon} > {text}</Button> : null
            : null;

        return (
            <React.Fragment>
                <Card
                    key="incident-correctiveActions-card"
                    title={
                        <React.Fragment>
                            <Title level={4} className="title">Step 2: Corrective actions</Title>
                            {save}
                        </React.Fragment>
                    }
                    className={`incident-correctiveActions-card info-incident-container ${this.props.className ? this.props.className : ""}`}
                >
                    {!isClosed ?
                        <TextAreaInput className="corrective-actions-input" disabled={!isEditable} defaultValue={this.state.value ?? ""} param="correctiveActions" placeholder={this.state.value || !isEditable ? this.state.value : "Write here"} onChange={this.onValueChange} bordered={false} />
                        :
                        <p>{this.props.incident.correctiveActions ? this.props.incident.correctiveActions : `-`}</p>}
                </Card>
            </React.Fragment>
        );
    }
}