import * as React from "react";
import { Form, Input } from "antd";

interface NumberInputProps {
    placeholder?: string;
    required?: boolean;
    warningMessage?: string;
    param: string;
    title?: string;
    onChange?: (event) => void;
    className?: string;
    formClassName?: string;
    disabled?: boolean;
}

export class PercentageInput extends React.Component<NumberInputProps> {
    render = () => {
        const { param, required, warningMessage, placeholder } = this.props;

        const optional = !this.props.required ? " (optional)" : "";
        const title = this.props.title ? <div>{this.props.title} {optional}</div> : null;

        return (
            <Form.Item
                label={title}
                name={param}
                rules={
                    [{
                        required: required,
                        message: warningMessage ? warningMessage : 'Please type a value for this field'
                    }]}
                className={this.props.formClassName ?? ""}
            >
                <Input
                    type="number"
                    //min={0}
                    //formatter={value => `${value}%`}
                    //parser={value => value.replace('%', '')}
                    placeholder={placeholder}
                    size="middle"
                    style={{
                        width: "100%"
                    }}
                    addonAfter="%"
                    onChange={this.props.onChange ?? null}
                    className={this.props.className ?? ""}
                    disabled={this.props.disabled ?? false}
                />
            </Form.Item>
        );
    }
}