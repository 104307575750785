import _ from 'lodash';
import moment from 'moment';
import { Card } from "antd";
import ListViewCard from "../Shared/ListViewCard";
import { OrderType, OrderView, PagedOrderQuery } from "../../ApiClient/swagger/data-contracts";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from '../../ApiClient/client';
import { updateCollectionWithEvent } from '../../Helpers/BasePageHelpers';
import { ActorLink } from '../Actors';
import { useNavigate } from 'react-router-dom';
import { formatCurrency } from '../../Helpers/Formatters';
import { ProjectLink } from '../Projects';
import { CategoryLink } from '../Categories';
import { ColumnExpander } from '../Shared/ColumnExpander';


interface OrderWidgetProps {
    title?: any;
    className?: string;
    query: Partial<PagedOrderQuery>;
    exclutions?: string[];
    onItemCreatedEvent?: (collection: OrderView[], order: OrderView) => OrderView[];
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    actions?: React.ReactNode;
    //formFilters?: Partial<PagedOrderQuery>;
}

export function OrderWidget(props: OrderWidgetProps) {

    const [collection, setCollection] = useState<OrderView[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    //const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

    const context = useContext(AppContext);
    const navigate = useNavigate();

    async function loadCollection() {
        setLoading(true);

        const response = await client.orders
            .queryOrders(props.query)
            .catch(exception => setError(exception.error));

        if (response) setCollection(response.data.items)

        setLoading(false);
    }

    function onItemCreatedEvent(response: OrderView) {
        if (!response || !props.onItemCreatedEvent) return;

        setCollection(prevCollection => {
            const newCollection = props.onItemCreatedEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }

    function onItemUpdatedEvent(response: OrderView) {
        if (!response) return;

        setCollection(prevCollection => {
            const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], response);
            return newCollection;
        });
    }

    useEffect(() => {
        loadCollection();
    }, [props.query])

    useEffect(() => {
        if (!collection) loadCollection();

        context.events.orders.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent
        });

        return () => {
            context.events.orders.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent
            });
        }
    }, [])

    const baseColumns = _.reject(props.customColumns ?? [
        {
            title: 'Number',
            render: (data: OrderView) => {
                const orderNumbers = data.orderNumbers !== null ? _.map(data.orderNumbers, (value) => {
                    return <div key={value.orderNumber} className="extend"> {value.applicationLink?.name ? value.applicationLink.name + ": " + value.orderNumber : value.orderNumber} </div>
                }) : "";

                return <ColumnExpander>{orderNumbers}</ColumnExpander>
            },
            key: 'number'
        },
        {
            title: 'Type',
            dataIndex: "type",
            key: 'type'
        },
        {
            title: 'Reference',
            dataIndex: "reference",
            key: 'reference'
        },
        {
            title: 'Label',
            dataIndex: "label",
            key: 'label'
        },
        {
            title: 'Project',
            render: (data: OrderView) => data.project ? <ProjectLink {...data.project} /> : "",
            key: 'project'
        },
        {
            title: 'Category',
            render: (data: OrderView) => data.category ? <CategoryLink {...data.category} /> : "",
            key: 'category'
        },
        {
            title: 'Roles',
            render: (data: OrderView) => {
                const roles = [];

                if (data.customer) roles.push(<div key="customer-role">Customer: {<ActorLink {...data.customer} />}</div>)
                if (data.invoiceReceiver) roles.push(<div key="invoiceReceiver-role">Invoice receiver: {<ActorLink {...data.invoiceReceiver} />}</div>)
                if (data.externalContact) roles.push(<div key="externalContact-role">External contact: {<ActorLink {...data.externalContact} />}</div>)
                if (data.internalContact) roles.push(<div key="internalContact-role">Internal contact: {<ActorLink {...data.internalContact} />}</div>)

                return <ColumnExpander>{roles}</ColumnExpander>;
            },
            key: 'roles',
        },
        {
            title: 'Dates',
            render: (data: OrderView) => {
                //TODO: Translate keys to text, color on orderline for lost?
                const dates = _.map(data.dates, (value, key) => {
                    return <div key={key}>{key}: {moment(value).format('DD.MM.YYYY')}</div>
                });
                return <ColumnExpander>{dates}</ColumnExpander>;
            },
            key: 'dates',
        },
        {
            title: 'Completed',
            render: (data: OrderView) => {
                //TODO: Progress bar, color on orderline?
                if (data.type != OrderType.Direct) { //Set green || 100% for Direct?
                    return data.values ? <span>{data.values.completed} of {data.values.quantity}</span> : null;
                }
            },
            key: "completed",
        },
        {
            title: 'Sum',
            render: (data: OrderView) => {
                //TODO: Show more stats by using columnexpander?
                return data.values ? <span>{formatCurrency(data.values.discountedPrice)}</span> : null;
            },
            key: "sum",
        }
    ], c => _.find(props.exclutions, e => { return c.key == e }) != null);

    if (error) return <Card title={props.title}>{error}</Card>;

    return (
        <>
            <ListViewCard
                title={props.title}
                columns={baseColumns}
                data={collection}
                loading={loading}
                onSelect={(data: OrderView) => navigate(`/orders/${data.id}`)}
                className="order-widget"
                actions={props.actions}
                {...props}
            />

            {/*<Drawer
                title="Create order"
                onClose={() => setShowCreateDrawer(false)}
                open={showCreateDrawer}
                component={
                    <OrderCreateForm
                        filters={props.query}
                        onCancel={() => setShowCreateDrawer(false)}
                        onComplete={() => setShowCreateDrawer(previous => !previous)}
                    />
                }
            />*/}
        </>
    );
}