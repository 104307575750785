import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import 'moment-duration-format';
import { Card, Button, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { TaskView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { TagLink } from "../Tags";
import { Capabilities } from "../../Definitions/_capabilties";
import { RequireCapability } from "../Shared/RequireCapability";
import { ActorLink } from "../Actors";
import { ProjectLink } from "../Projects";
import { CategoryLink } from "../Categories";
import { formatDuration } from "../../Helpers/Formatters";
import { Drawer } from "../Shared/Drawer";
import TaskEditForm from "./TaskEditForm";

const { Title } = Typography;


interface TaskInfoCardProps {
    task: TaskView;
    className?: string;
    onEditComplete: (task: TaskView) => void;
}

interface TaskInfoCardState {
    showEditDrawer: boolean,
}

export class TaskInfoCard extends React.Component<TaskInfoCardProps, TaskInfoCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showEditDrawer: false,
        }
    }

    toggleEditDrawer = () => {
        this.setState({ showEditDrawer: !this.state.showEditDrawer });
    }

    render() {
        if (!this.props.task) return null;

        const { name, project, category, plannedStart, plannedEnd, owner, assignee, description, tags, estimate } = this.props.task;

        const tagView = _.map(tags, tag => {
            return <TagLink key={tag.id} {...tag} />;
        });

        let plannedPeriod = plannedStart ? moment(plannedStart).format("DD.MM.YYYY") : null;
        if (plannedEnd) {
            const endDate = moment(plannedEnd).format("DD.MM.YYYY")
            plannedPeriod = plannedPeriod != null ? plannedPeriod + " - " + endDate : endDate;
        }

        let related = false;
        if ((owner != null && owner.id == this.context.user.actorId) || (assignee != null && assignee.id == this.context.user.actorId)) related = true;

        return (
            <>
                <Card
                    key="task-info-card"
                    title={
                        <div>
                            <Title level={4} className="title">Task details</Title>
                            <RequireCapability capability={related ? Capabilities.ProjectsRoleBased : Capabilities.ProjectsWrite}>
                                <Button size="small" className="action" onClick={this.toggleEditDrawer} icon={<EditOutlined />}>Edit</Button>
                            </RequireCapability>
                        </div>
                    }
                    className={`task-info-card info-card ${this.props.className ? this.props.className : ''}`}
                >
                    <dl>
                        <div className="description">
                            <dt>{name}</dt>
                            <dd>{description ?? "-"}</dd>
                        </div>

                        <div>
                            <dt>Owner</dt>
                            <dd>{owner ? <ActorLink {...owner} /> : '-'}</dd>
                        </div>

                        <div>
                            <dt>Assignee</dt>
                            <dd>{assignee ? <ActorLink {...assignee} /> : '-'}</dd>
                        </div>

                        <div>
                            <dt>Category</dt>
                            <dd>{category ? <CategoryLink {...category} /> : "-"}</dd>
                        </div>

                        <div>
                            <dt>Planned task period</dt>
                            <dd>{plannedPeriod ?? "-"}</dd>
                        </div>

                        <div>
                            <dt>Project</dt>
                            <dd>{project ? <ProjectLink {...project} /> : "-"}</dd>
                        </div>

                        <div>
                            <dt>Task estimate</dt>
                            <dd>{formatDuration(estimate)}</dd>
                        </div>

                        <div>
                            <dt>Tags</dt>
                            <dd>{tagView && tagView.length > 0 ? tagView : "-"}</dd>
                        </div>
                    </dl>

                    {/*<div className="created-updated">*/}
                    {/*    {state.created ? <div><span className="record-text">Created</span> {moment(state.created.date).fromNow()}, {moment(state.created.date).format('DD.MM.YYYY')} by <ActorLink {...state.created.actor} /></div> : null}*/}
                    {/*    {state.updated ? <div><span className="record-text">Edited</span> {moment(state.updated.date).fromNow()}, {moment(state.updated.date).format('DD.MM.YYYY')} by <ActorLink {...state.updated.actor} /></div> : null}*/}
                    {/*</div>*/}
                </Card>

                <Drawer
                    title="Edit task"
                    onClose={this.toggleEditDrawer}
                    open={this.state.showEditDrawer}
                    component={
                        <TaskEditForm
                            task={this.props.task}
                            onComplete={(data) => {
                                this.props.onEditComplete(data);
                                this.toggleEditDrawer();
                            }}
                            onCancel={this.toggleEditDrawer}
                        />
                    }
                />
            </>
        );
    }
}

export default TaskInfoCard;