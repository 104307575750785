import * as React from "react";
import { Form } from "antd";
import { SketchPicker } from 'react-color';

interface ColorPickerProps {
    title?: string | JSX.Element,
    className?: string
    param: string,
}

export class ColorInput extends React.Component<ColorPickerProps> {

    constructor(props) {
        super(props);
    }

    render = () => {
        const { className, param } = this.props;
        const title = this.props.title ? <div>{this.props.title}</div> : null;
        return (

            <Form.Item
                noStyle
                shouldUpdate={(prev, next) => { return prev[param] !== next[param] }}
            >
                {({ getFieldValue, setFieldsValue }) => {

                    const value = getFieldValue(param) || "rgba(0, 0, 0, 1)";

                    //TODO: Change this to a smaller input with the color picker as a popup? Example: https://casesandberg.github.io/react-color/#examples
                    return (
                        <Form.Item
                            name={param}
                            label={title}
                        >
                            <div className={`date-picker-input ${className ? className : ''}`}>
                                <SketchPicker width="auto" color={value} onChangeComplete={(color, event) => {
                                    const colorCode = `rgba(${color.rgb.r},${color.rgb.g},${color.rgb.b},${color.rgb.a})`;
                                    this.setState({ value: colorCode });
                                    const values = {};
                                    values[param] = colorCode;
                                    setFieldsValue(values);
                                }} />
                            </div>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        );
    }
}


