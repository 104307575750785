import { Button, Divider, Form } from "antd";
import { useContext, useState } from "react";
import client from "../../ApiClient/client";
import { PagedProjectQuery, ProjectView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import { Drawer } from "../Shared/Drawer";
import EntitySelector from "../Shared/EntitySelector";
import { RequireCapability } from "../Shared/RequireCapability";
import ProjectCreateForm from "./ProjectCreateForm";


export function ProjectSelector(props: GenericEntitySelectorProps<PagedProjectQuery>) {
    return (
        <EntitySelector
            placeholder={props.placeholder ?? 'Select project...'}
            source={props.source ?? client.projects.queryProjects}
            defaultValueSource={props.defaultValueSource ?? client.projects.getProjectById}
            optionDisplayNameParam={props.optionDisplayNameParam ?? 'name'}
            className="project-selector"
            renderOptionItem={(item, isLastItem) => (
                <>
                    <p style={{ margin: 0, fontSize: 11 }}>{item.customer?.name}</p>
                    {item.name}
                    {isLastItem ? null : <Divider style={{ margin: 0, marginTop: "5px" }} />}
                </>
            )}
            {...props}
        />
    );
}


interface ProjectSelectorInputProps extends GenericEntitySelectorProps<PagedProjectQuery> {
    required?: boolean;
    warningMessage?: string;
    param: string;
    className?: string;
    titleLink?: React.ReactNode;
}


export function ProjectSelectorInput(props: ProjectSelectorInputProps) {

    const [addedProject, setAddeProject] = useState<ProjectView>();
    const [showCreateDrawer, setShowCreateDrawer] = useState(false);

    const context = useContext(AppContext);

    function onComplete(added: ProjectView) {
        setAddeProject(added);

        if (props.onChange)
            props.onChange(added.id);

        if (props.onObjectChange)
            props.onObjectChange(added);

        setShowCreateDrawer(false);
    }

    return (
        <>
            <Form.Item
                name={props.param}
                label={
                    <>
                        <span style={{ width: '100%' }}>{props.title}</span>
                        <RequireCapability capability={Capabilities.ProjectsWrite}>
                            <Button type="link" onClick={() => setShowCreateDrawer((current) => !current)} className={`select-project-title ${context.isMobile ? "project-title-mobile" : "project-title-desktop"}`}>
                                {context.isMobile ? "+ Add" : "+ Add  project"}
                            </Button>
                        </RequireCapability>
                    </>
                }
            >
                <ProjectSelector
                    {...Object.assign(
                        {},
                        props,
                        { value: addedProject?.id ?? props.value },
                        { title: props.title ? null : props.title }
                    )}
                />

                <Drawer
                    title="Add new project"
                    onClose={() => setShowCreateDrawer(false)}
                    open={showCreateDrawer}
                    destroyOnClose={true}
                    component={<ProjectCreateForm onComplete={onComplete} onCancel={() => setShowCreateDrawer(false)} filters={props.filters} />}
                />
            </Form.Item>

        </>
    );
}

export default ProjectSelector;