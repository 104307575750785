import * as React from "react";
import AppContext from "../../Definitions/AppContext";
import { formatMonthNr } from "../../Helpers/Formatters";
import { RequireCapability } from "../Shared/RequireCapability";
import { Capabilities } from "../../Definitions/_capabilties";
import { Statistics } from "../Shared/Statistics";
import { BarChartCard, ColumnChartCard } from "../Shared/Histogram";
import { ValueType } from "../../Models/ValueType";
import { PieChartCard } from "../Shared/PieChart";
import { CallAggregationResult } from "../../ApiClient/swagger/data-contracts";


interface StatisticsProps {
    aggregates: CallAggregationResult;
    loading: boolean;
    createActions?: any;
    onExportClick?: () => void;
    exporting?: boolean;
    loadAggregates: () => void;
    isCustomer?: boolean;
}

interface StatisticsState { }

export class CallStatisticsView extends React.Component<StatisticsProps, StatisticsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    formatMonth = (value) => {
        if (value.length === 4) return value;

        return formatMonthNr(value.split("|")[1]);
    }

    formatMonth2 = (monthNr) => {
        return formatMonthNr(monthNr);
    }

    render = () => {

        const { aggregates } = this.props;

        return (
            <RequireCapability capability={Capabilities.CallsReadActor}>
                <Statistics
                    className="call-statistics-view"
                    shownElements={0}
                    actions={this.props.createActions ? this.props.createActions : null}
                    onExportClick={this.props.onExportClick ? this.props.onExportClick : null}
                    exporting={this.props.exporting ? this.props.exporting : null}
                    loadAggregates={this.props.loadAggregates}
                >
                    <BarChartCard
                        title="Top contacts - Number of calls"
                        loading={this.props.loading}
                        xField="label"
                        yField="count"
                        valueBy={ValueType.Count}
                        data={aggregates?.personDuration}
                        height={200}
                        route="people"
                    />
                    <BarChartCard
                        title="Top contacts - Total call duration"
                        loading={this.props.loading}
                        xField="label"
                        yField="sum"
                        valueBy={ValueType.Duration}
                        data={aggregates?.personDuration}
                        height={200}
                        route="people"
                    />
                    <BarChartCard
                        title="Top contacts - Average call duration"
                        loading={this.props.loading}
                        xField="label"
                        yField="average"
                        valueBy={ValueType.Duration}
                        data={aggregates?.personDuration}
                        height={200}
                        route="people"
                    />
                    {!this.props.isCustomer ? <BarChartCard
                        title="Top organizations - Number of calls"
                        loading={this.props.loading}
                        xField="label"
                        yField="count"
                        valueBy={ValueType.Count}
                        data={aggregates?.organizationDuration}
                        height={200}
                        route="organizations"
                    /> : null}
                    {!this.props.isCustomer ? <BarChartCard
                        title="Top organizations - Total call duration"
                        loading={this.props.loading}
                        xField="label"
                        yField="sum"
                        valueBy={ValueType.Duration}
                        data={aggregates?.organizationDuration}
                        height={200}
                        route="organizations"
                    /> : null}
                    <ColumnChartCard
                        title="Top categories - Tracked call duration"
                        loading={this.props.loading}
                        xField="label"
                        yField="sum"
                        valueBy={ValueType.Duration}
                        data={aggregates?.perTrackedCategory}
                        height={200}
                    />

                    <PieChartCard
                        title="Missed call reasons"
                        xField="label"
                        yField="count"
                        valueBy={ValueType.Count}
                        loading={this.props.loading}
                        data={aggregates?.missedReason}
                        height={200}
                    />
                    <PieChartCard
                        title="Call direction"
                        xField="label"
                        yField="count"
                        valueBy={ValueType.Count}
                        loading={this.props.loading}
                        data={aggregates?.directionDuration}
                        height={200}
                    />
                    <PieChartCard
                        title="Known contact"
                        xField="label"
                        yField="count"
                        valueBy={ValueType.Count}
                        loading={this.props.loading}
                        data={aggregates?.knownDuration}
                        height={200}
                    />

                    <ColumnChartCard
                        title="Per year & month - Total call duration"
                        xField="label"
                        yField="sum"
                        drilldownField="drilldown"
                        drilldownXField="label"
                        drilldownYField="sum"
                        valueBy={ValueType.Duration}
                        data={aggregates?.callsDurationPerMonth}
                        height={200}
                        cardClassName="wide-stat"
                        totalDisplayCount={100}
                        stopSort
                        xAxisFormatter={this.formatMonth}
                    />

                    <div className="wide-stat wide-stat-split month">
                        <ColumnChartCard
                            title="Per month - Total duration"
                            xField="label"
                            yField="sum"
                            valueBy={ValueType.Duration}
                            data={aggregates?.callsPerMonthOfYear}
                            height={200}
                            totalDisplayCount={12}
                            stopSort
                            xAxisFormatter={this.formatMonth2}
                        />
                        <ColumnChartCard
                            title="Per month - Total count"
                            xField="label"
                            yField="count"
                            valueBy={ValueType.Count}
                            data={aggregates?.callsPerMonthOfYear}
                            height={200}
                            totalDisplayCount={12}
                            stopSort
                            xAxisFormatter={this.formatMonth2}
                        />
                    </div>

                    <div className="wide-stat wide-stat-split">
                        <ColumnChartCard
                            title="Per day of week - Total duration"
                            xField="label"
                            yField="sum"
                            valueBy={ValueType.Duration}
                            data={aggregates?.callsPerDayOfWeek}
                            height={200}
                            totalDisplayCount={7}
                            stopSort
                        />
                        <ColumnChartCard
                            title="Per day of week - Total count"
                            xField="label"
                            yField="count"
                            valueBy={ValueType.Count}
                            data={aggregates?.callsPerDayOfWeek}
                            height={200}
                            totalDisplayCount={7}
                            stopSort
                        />
                    </div>

                    <ColumnChartCard
                        title="Per hour of day - Total count"
                        xField="label"
                        yField="count"
                        valueBy={ValueType.Count}
                        data={aggregates?.callsHourOfDay}
                        height={200}
                        cardClassName="wide-stat"
                        totalDisplayCount={24}
                        stopSort
                    />
                    <ColumnChartCard
                        title="Per hour of day - Total duration"
                        xField="label"
                        yField="sum"
                        valueBy={ValueType.Duration}
                        data={aggregates?.callsHourOfDay}
                        height={200}
                        cardClassName="wide-stat"
                        totalDisplayCount={24}
                        stopSort
                    />
                </Statistics>
            </RequireCapability>
        );
    }
}

export default CallStatisticsView;