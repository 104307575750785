import * as React from "react";
import { StaticSelectorProps } from "../../Models/StaticSelectorProps";
import StaticSelector from "./StaticSelector";


class DeletedSelector extends React.Component<StaticSelectorProps> {
    render = () => {
        const values = [
            {
                name: 'Yes',
                value: true
            },
            {
                name: 'No',
                value: false
            }
        ];

        const options = this.props.options ? this.props.options : values;
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select deleted...';

        return (
            <StaticSelector
                className="deleted-selector"
                options={options}
                placeholder={placeholder}
                {...this.props}
            />
        );
    }
}

export default DeletedSelector;