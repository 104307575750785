import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import { Drawer } from "../../Modules/Shared/Drawer";
import AppContext from "../../Definitions/AppContext";
import { ContactPointLinkView, ContactPointView } from "../../ApiClient/swagger/data-contracts";
import { CloseOutlined, EditOutlined, MailOutlined, PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Capabilities } from "../../Definitions/_capabilties";
import { Button, Popconfirm } from "antd";
import { ContactPointCreateForm, ContactPointEditForm, ContactPointLink } from "../ContactPoints";
import ListViewCard from "../Shared/ListViewCard";

interface ActorContactPointListProps {
    actor: any;
    className?: string;
    onCPAction: (cp: ContactPointView) => void;
}

interface ActorContactPointListState {
    showCreateDrawer: boolean,
    contactPoint: ContactPointView,
    error: string,
    loadingRemove: boolean
}

export class ActorContactPointList extends React.Component<ActorContactPointListProps, ActorContactPointListState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showCreateDrawer: false,
            contactPoint: null,
            error: null,
            loadingRemove: false
        }
    }

    toggleCreateDrawer = () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    onEdit = (contactPoint) => {
        this.setState({ contactPoint });
    }

    onEditDrawerClose = () => {
        this.setState({ contactPoint: null });
    }

    removeContactPoint = async (cp: ContactPointLinkView) => {
        try {
            const response = await client.contactPoints.deleteContactPoint(cp.id, null);
            if (response) this.props.onCPAction(response.data);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    render = () => {
        const iconLookup = {
            email: <MailOutlined />,
            phone: <PhoneOutlined />,
            mobile: <PhoneOutlined />,
            video: <VideoCameraOutlined />
        }

        const listColumns = [
            {
                title: '',
                render: (data: ContactPointLinkView) => {
                    return (
                        <div>
                            <div><div style={{marginRight: 20, float: 'left'}}>{iconLookup[data.type.toLowerCase()]} {data.type}</div> <ContactPointLink {...data} hideLabel /></div>
                            {data.description ? <div>{data.description}</div> : null}
                        </div>
                    );
                },
                key: 'type',
                align: 'left',
                //width: 70
            },
            //{
            //    title: '',
            //    render: (data) => <ContactPointLink {...data} hideLabel />,
            //    key: 'value'
            //}
        ];

        if (this.context.user.hasCapability(Capabilities.ContactPointsWrite)) {
            listColumns.push(
                {
                    title: '',
                    render: (data: ContactPointLinkView) => {
                        return (
                            <div className="cp-list-actions">
                                <Button
                                    className="edit-btn"
                                    type="link"
                                    style={{ margin: 0, padding: 0 }}
                                    onClick={() => this.onEdit(data)}
                                    icon={<EditOutlined />}
                                />
                                <Popconfirm title="Remove this contact info?" onConfirm={() => this.removeContactPoint(data)}>
                                    <Button
                                        className="edit-btn"
                                        type="link"
                                        style={{ margin: 0, padding: 0 }}
                                        icon={<CloseOutlined />}
                                        shape="circle"
                                    />
                                </Popconfirm>
                            </div>
                        );
                    },
                    key: 'actions',
                    align: 'right',
                    //@ts-ignore
                    width: 60
                }
            );
        }

        return (
            <React.Fragment>
                <Drawer
                    title="Create contact point"
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    destroyOnClose={true}
                    component={
                        <ContactPointCreateForm
                            onCancel={this.toggleCreateDrawer}
                            actor={this.props.actor}
                            onComplete={(data) => {
                                this.props.onCPAction(data);
                                this.toggleCreateDrawer();
                            }}
                        />
                    }
                />

                <Drawer
                    title="Edit contact point"
                    onClose={this.onEditDrawerClose}
                    open={this.state.contactPoint != null}
                    destroyOnClose={true}
                    component={
                        <ContactPointEditForm
                            actorId={this.props.actor.id}
                            contactPoint={this.state.contactPoint}
                            onComplete={(data) => {
                                this.props.onCPAction(data);
                                this.onEditDrawerClose();
                            }}
                            onCancel={this.onEditDrawerClose}
                            onDelete={() => this.setState({ contactPoint: null })}
                        />
                    }
                />

                <ListViewCard
                    title="Contact info"
                    onAdd={this.context.user.hasCapability(Capabilities.ContactPointsWrite) ? this.toggleCreateDrawer : null}
                    columns={listColumns}
                    data={this.props.actor.contactPoints}
                    className={`contact-point-list contact-point-list-card ${this.props.className ? this.props.className : ''}`}
                    emptyText=" "
                />
            </React.Fragment>
        );
    }
}

export default ActorContactPointList;