import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import AppContext from "../../Definitions/AppContext";
import { LeadStatus, PagedLeadQuery } from "../../ApiClient/swagger/data-contracts";
import { QuickSearch } from "../Shared/QuickSearch";



interface LeadQuickSearchProps {
    onQuickSearch: (filters) => any;
    query: Partial<PagedLeadQuery>;
    setQuickSearchName?: (activeQuickSearches: Record<string, boolean>) => void;
}

interface LeadQuickSearchState {
    allQueryParams: Partial<PagedLeadQuery>;
    activeQueryParams: Partial<PagedLeadQuery>;
    postponedQueryParams: Partial<PagedLeadQuery>;
    thisYearQueryParams: Partial<PagedLeadQuery>;
    closingQueryParams: Partial<PagedLeadQuery>;
}

enum LeadQuickSearchNames {
    "All" = "All",
    "Active" = "Active",
    "Postponed" = "Postponed",
    "ThisYear" = "This year",
    "Closing" = "Next action this month & next month",
}

export class LeadQuickSearch extends React.Component<LeadQuickSearchProps, LeadQuickSearchState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;


    constructor(props) {
        super(props);
        this.state = {
            allQueryParams: {
                ownerId: null,
                contactPersonId: null,
                probabilities: null,
                limitToTags: null,
                limitToCauses: null,
                deleted: false,
                closingStartPeriod: null,
                closingEndPeriod: null,
                status: null
            },
            activeQueryParams: {
                ownerId: null,
                contactPersonId: null,
                probabilities: null,
                limitToTags: null,
                limitToCauses: null,
                deleted: false,
                closingStartPeriod: null,
                closingEndPeriod: null,
                status: [LeadStatus.Active]
            },
            postponedQueryParams: {
                ownerId: null,
                contactPersonId: null,
                probabilities: null,
                limitToTags: null,
                limitToCauses: null,
                deleted: false,
                closingStartPeriod: null,
                closingEndPeriod: null,
                status: [LeadStatus.Postponed]
            },
            thisYearQueryParams: {
                ownerId: null,
                contactPersonId: null,
                probabilities: null,
                limitToTags: null,
                limitToCauses: null,
                deleted: false,
                closingStartPeriod: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                closingEndPeriod: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                status: null
            },
            closingQueryParams: {
                ownerId: null,
                contactPersonId: null,
                probabilities: null,
                limitToTags: null,
                limitToCauses: null,
                deleted: false,
                closingStartPeriod: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                closingEndPeriod: moment().add(1, "month").endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                status: [LeadStatus.Active, LeadStatus.Postponed]
            }
        };
    }

    componentDidUpdate = (prevProps: LeadQuickSearchProps) => {
        if (this.props.setQuickSearchName) {
            const isEqual = _.isEqual(prevProps.query, this.props.query);
            if (!isEqual) {
                this.handleActiveQuickSearches();
            }
        }
    }

    handleActiveQuickSearches = () => {
        const allIsActive = this.isActive(this.state.allQueryParams, this.props.query);
        const activeIsActive = this.isActive(this.state.activeQueryParams, this.props.query);
        const postponedIsActive = this.isActive(this.state.postponedQueryParams, this.props.query);
        const thisYearIsActive = this.isActive(this.state.thisYearQueryParams, this.props.query);
        const closingIsActive = this.isActive(this.state.closingQueryParams, this.props.query);

        const activeQuickSearches: Record<string, boolean> = {
            [LeadQuickSearchNames.All]: allIsActive,
            [LeadQuickSearchNames.Active]: activeIsActive,
            [LeadQuickSearchNames.Postponed]: postponedIsActive,
            [LeadQuickSearchNames.ThisYear]: thisYearIsActive,
            [LeadQuickSearchNames.Closing]: closingIsActive
        };

        this.props.setQuickSearchName(activeQuickSearches);
    }

    isActive = (queryParams: Partial<PagedLeadQuery>, query: Partial<PagedLeadQuery>,) => {
        let active = true;
        const keys = Object.keys(queryParams);

        _.each(keys, key => {
            const value = query[key];
            const matchValue = queryParams[key];

            if (Array.isArray(matchValue)) {
                const arrayValue = value || [];
                const diff1 = _.difference(arrayValue, matchValue);
                const diff2 = _.difference(matchValue, arrayValue);
                if (diff1.length !== 0 || diff2.length !== 0) {
                    active = false;
                }
            }
            else if (matchValue != value) {
                active = false;
            }
        });

        return active;
    }

    render = () => {

        return (
            <React.Fragment>
                <QuickSearch
                    name={LeadQuickSearchNames.All}
                    queryParameters={this.state.allQueryParams}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name={LeadQuickSearchNames.Active}
                    queryParameters={this.state.activeQueryParams}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name={LeadQuickSearchNames.Postponed}
                    queryParameters={this.state.postponedQueryParams}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name={LeadQuickSearchNames.ThisYear}
                    queryParameters={this.state.thisYearQueryParams}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch
                    name={LeadQuickSearchNames.Closing}
                    queryParameters={this.state.closingQueryParams}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />
            </React.Fragment>
        );
    }
}