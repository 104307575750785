import * as React from "react";
import _ from 'lodash';
import { FileJpgOutlined, FileImageOutlined, FileUnknownOutlined, FileGifOutlined, FilePdfOutlined, FileExcelOutlined, FileWordOutlined, FileZipOutlined, FilePptOutlined, FileTextOutlined } from '@ant-design/icons';


const getExtensionIcon = (extension: string) => {
    if (!extension) return <FileUnknownOutlined />;

    let icon = null;

    switch (extension) {
        case ".jpg":
            icon = <FileJpgOutlined />;
            break;
        case ".jpeg":
            icon = <FileJpgOutlined />;
            break;
        case ".png":
            icon = <FileImageOutlined />;
            break;
        case ".gif":
            icon = <FileGifOutlined />;
            break;
        case ".pdf":
            icon = <FilePdfOutlined />;
            break;
        case ".xlsx":
            icon = <FileExcelOutlined />;
            break;
        case ".xls":
            icon = <FileExcelOutlined />;
            break;
        case ".doc":
            icon = <FileWordOutlined />;
            break;
        case ".docx":
            icon = <FileWordOutlined />;
            break;
        case ".zip":
            icon = <FileZipOutlined />;
            break;
        case ".pptx":
            icon = <FilePptOutlined />;
            break;
        case ".txt":
            icon = <FileTextOutlined />;
            break;
        default:
            icon = <FileUnknownOutlined />;
            break;
    }

    return icon;
}

const getExtensionType = (extension: string) => {
    if (!extension) return null;

    return extension.charAt(0) == '.' ? extension.substr(1) : extension;
}

export {
    getExtensionIcon,
    getExtensionType
}