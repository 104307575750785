import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { CreateSubscriptionLine, ProductUnit, SubscriptionLineView, SubscriptionView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import { ProductSelector } from "../Products";
import { NumberInput } from "../Shared/NumberInput";
import { PercentageInput } from "../Shared/PercentageInput";
import { TextAreaInput } from "../Shared/TextAreaInput";

interface SubscriptionLineCreateFormProps {
    onCancel?: () => void;
    onComplete: (created: SubscriptionLineView[]) => void;
    subscription: SubscriptionView;
    hideContent?: boolean;
}

interface SubscriptionLineCreateFormState {
    loading: boolean;
    error: string;
}

interface SubscriptionLineCreateRequest extends CreateSubscriptionLine {
    productIds: string[];
}

export class SubscriptionLineCreateForm extends React.Component<SubscriptionLineCreateFormProps, SubscriptionLineCreateFormState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null
        }
    }

    onSubmit = async (request: SubscriptionLineCreateRequest) => {
        this.setState({ loading: true });

        if (request.productIds) {
            const baseRequest = Object.assign({}, request);
            if (baseRequest.productIds) delete baseRequest.productIds;

            var results: SubscriptionLineView[] = [];
            for (let i = 0; i < request.productIds.length; i++) {
                const req: CreateSubscriptionLine = Object.assign({}, baseRequest, { productId: request.productIds[i] });
                const res = await client.subscriptions.createSubscriptionLine(this.props.subscription.id, req).catch();
                if (res) results.push(res.data);
            }
        }

        this.props.onComplete(results);

        this.setState({ loading: false });
    }

    render() {
        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                className={this.props.hideContent ? "hideContent" : ""}
                initialValues={{
                    application: moment().startOf('month').startOf('day').format()
                }}
            >
                <SelectorInput
                    param="application"
                    required
                    warningMessage="Please choose a application date"
                    title="Start date"
                    selector={
                        <DateSelector />
                    }
                />

                <SelectorInput
                    param="productIds"
                    title="Product"
                    required
                    warningMessage="Please select a product"
                    selector={
                        <ProductSelector
                            placeholder="Select product..."
                            filters={{ units: [ProductUnit.Monthly, ProductUnit.Quarterly, ProductUnit.Yearly] }}
                            multiple
                        />
                    }
                />

                <NumberInput
                    param="quantity"
                    title="Quantity"
                    required
                    warningMessage="Please fill in the quantity"
                />

                <PercentageInput
                    param="discount"
                    title="Discount"
                />

                <TextAreaInput
                    title="Invoice note"
                    param="note"
                />

                <TextAreaInput
                    title="Internal note"
                    param="internalNote"
                />
            </BaseForm>
        );
    }
}

export default SubscriptionLineCreateForm;