/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateFileOrFolder,
  CreateLead,
  CreateNote,
  FileSortOption,
  FileView,
  FileViewEntityViewModel,
  FileViewPagedFileQueryCollectionViewModel,
  FolderSortOption,
  FolderView,
  FolderViewEntityViewModel,
  FolderViewPagedFolderQueryCollectionViewModel,
  LeadAggregationResultLeadAggregateQueryAggregationResults,
  LeadAggregationTypes,
  LeadProbability,
  LeadSortOption,
  LeadStatus,
  LeadView,
  LeadViewEntityViewModel,
  LeadViewPagedLeadQueryCollectionViewModel,
  MoveEntityFileOrFolder,
  MoveFileOrFolder,
  NoteView,
  NoteViewEntityViewModel,
  NoteViewPagedNoteQueryCollectionViewModel,
  PreviewType,
  RevertFileToVersion,
  SortDirection,
  UpdateFileOrFolder,
  UpdateLead,
  UpdateNote,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Leads<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Lead
   * @name GetLeadById
   * @request GET:/api/leads/{id}
   * @secure
   */
  getLeadById = (id: string, params: RequestParams = {}) =>
    this.http.request<LeadViewEntityViewModel, any>({
      path: `/api/leads/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name UpdateLead
   * @request PUT:/api/leads/{id}
   * @secure
   */
  updateLead = (id: string, data: UpdateLead, params: RequestParams = {}) =>
    this.http.request<LeadView, any>({
      path: `/api/leads/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name DeleteLead
   * @request DELETE:/api/leads/{id}
   * @secure
   */
  deleteLead = (id: string, params: RequestParams = {}) =>
    this.http.request<LeadView, any>({
      path: `/api/leads/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name CreateLead
   * @request POST:/api/leads
   * @secure
   */
  createLead = (data: CreateLead, params: RequestParams = {}) =>
    this.http.request<LeadView, any>({
      path: `/api/leads`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name QueryLeads
   * @request GET:/api/leads
   * @secure
   */
  queryLeads = (
    query?: {
      sortBy?: LeadSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      ownerId?: string;
      customerId?: string;
      contactPersonId?: string;
      /** @format date-time */
      startPeriod?: string;
      /** @format date-time */
      endPeriod?: string;
      /** @format date-time */
      closingStartPeriod?: string;
      /** @format date-time */
      closingEndPeriod?: string;
      probabilities?: LeadProbability[];
      status?: LeadStatus[];
      limitToCauses?: string[];
      limitToTags?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<LeadViewPagedLeadQueryCollectionViewModel, any>({
      path: `/api/leads`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name RestoreLead
   * @request POST:/api/leads/{id}/restore
   * @secure
   */
  restoreLead = (id: string, params: RequestParams = {}) =>
    this.http.request<LeadView, any>({
      path: `/api/leads/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name UpdateLeadAttributes
   * @request POST:/api/leads/{id}/attributes
   * @secure
   */
  updateLeadAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<LeadView, any>({
      path: `/api/leads/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name GetLeadAggregates
   * @request GET:/aggs/leads
   * @secure
   */
  getLeadAggregates = (
    query?: {
      leadId?: string;
      aggs?: LeadAggregationTypes[];
      ownerId?: string;
      customerId?: string;
      contactPersonId?: string;
      /** @format date-time */
      startPeriod?: string;
      /** @format date-time */
      endPeriod?: string;
      /** @format date-time */
      closingStartPeriod?: string;
      /** @format date-time */
      closingEndPeriod?: string;
      probabilities?: LeadProbability[];
      status?: LeadStatus[];
      limitToCauses?: string[];
      limitToTags?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<LeadAggregationResultLeadAggregateQueryAggregationResults, any>({
      path: `/aggs/leads`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Lead
   * @name GetLeadAggregatesById
   * @request GET:/aggs/leads/{id}
   * @secure
   */
  getLeadAggregatesById = (id: string, params: RequestParams = {}) =>
    this.http.request<Record<string, any>, any>({
      path: `/aggs/leads/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name GetLeadFileById
   * @request GET:/api/leads/{entityId}/files/{fileId}
   * @secure
   */
  getLeadFileById = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileViewEntityViewModel, any>({
      path: `/api/leads/${entityId}/files/${fileId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name UpdateLeadFile
   * @request PUT:/api/leads/{entityId}/files/{fileId}
   * @secure
   */
  updateLeadFile = (
    entityId: string,
    fileId: string,
    data: {
      /** @format binary */
      file: File;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/leads/${entityId}/files/${fileId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name DeleteLeadFile
   * @request DELETE:/api/leads/{entityId}/files/{fileId}
   * @secure
   */
  deleteLeadFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/leads/${entityId}/files/${fileId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name DownloadLeadFile
   * @request GET:/api/leads/{entityId}/files/{fileId}/download
   * @secure
   */
  downloadLeadFile = (
    entityId: string,
    fileId: string,
    query?: {
      versionId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/leads/${entityId}/files/${fileId}/download`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name PreviewLeadFile
   * @request GET:/api/leads/{entityId}/files/{fileId}/preview
   * @secure
   */
  previewLeadFile = (
    entityId: string,
    fileId: string,
    query?: {
      type?: PreviewType;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<File, any>({
      path: `/api/leads/${entityId}/files/${fileId}/preview`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name CreateLeadFile
   * @request POST:/api/leads/{entityId}/files
   * @secure
   */
  createLeadFile = (
    entityId: string,
    data: {
      /** @format binary */
      file: File;
      "parent.id"?: string;
      "parent.collection"?: string;
      name: string;
      description?: string;
      applicationIds?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileView, any>({
      path: `/api/leads/${entityId}/files`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name QueryLeadFiles
   * @request GET:/api/leads/{entityId}/files
   * @secure
   */
  queryLeadFiles = (
    entityId: string,
    query?: {
      sortBy?: FileSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFiles?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      extentions?: string[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FileViewPagedFileQueryCollectionViewModel, any>({
      path: `/api/leads/${entityId}/files`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name RestoreLeadFile
   * @request POST:/api/leads/{entityId}/files/{fileId}/restore
   * @secure
   */
  restoreLeadFile = (entityId: string, fileId: string, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/leads/${entityId}/files/${fileId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name MoveLeadFile
   * @request POST:/api/leads/{entityId}/files/{fileId}/move
   * @secure
   */
  moveLeadFile = (entityId: string, fileId: string, data: MoveEntityFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/leads/${entityId}/files/${fileId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name RevertLeadFile
   * @request POST:/api/leads/{entityId}/files/{fileId}/revert
   * @secure
   */
  revertLeadFile = (entityId: string, fileId: string, data: RevertFileToVersion, params: RequestParams = {}) =>
    this.http.request<FileView, any>({
      path: `/api/leads/${entityId}/files/${fileId}/revert`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name GetLeadFolderById
   * @request GET:/api/leads/{entityId}/folder/{folderId}
   * @secure
   */
  getLeadFolderById = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderViewEntityViewModel, any>({
      path: `/api/leads/${entityId}/folder/${folderId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name CreateLeadFolder
   * @request POST:/api/leads/{entityId}/folders
   * @secure
   */
  createLeadFolder = (entityId: string, data: CreateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/leads/${entityId}/folders`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name QueryLeadFolders
   * @request GET:/api/leads/{entityId}/folders
   * @secure
   */
  queryLeadFolders = (
    entityId: string,
    query?: {
      sortBy?: FolderSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      allFolders?: boolean;
      parentId?: string;
      parentCollection?: string;
      applicationId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<FolderViewPagedFolderQueryCollectionViewModel, any>({
      path: `/api/leads/${entityId}/folders`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name UpdateLeadFolder
   * @request PUT:/api/leads/{entityId}/folders/{folderId}
   * @secure
   */
  updateLeadFolder = (entityId: string, folderId: string, data: UpdateFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/leads/${entityId}/folders/${folderId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name DeleteLeadFolder
   * @request DELETE:/api/leads/{entityId}/folders/{folderId}
   * @secure
   */
  deleteLeadFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/leads/${entityId}/folders/${folderId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name RestoreLeadFolder
   * @request POST:/api/leads/{entityId}/folders/{folderId}/restore
   * @secure
   */
  restoreLeadFolder = (entityId: string, folderId: string, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/leads/${entityId}/folders/${folderId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadFile
   * @name MoveLeadFolder
   * @request POST:/api/leads/{entityId}/{folderId}/move
   * @secure
   */
  moveLeadFolder = (entityId: string, folderId: string, data: MoveFileOrFolder, params: RequestParams = {}) =>
    this.http.request<FolderView, any>({
      path: `/api/leads/${entityId}/${folderId}/move`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadNote
   * @name GetLeadNoteById
   * @request GET:/api/leads/{entityId}/notes/{noteId}
   * @secure
   */
  getLeadNoteById = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteViewEntityViewModel, any>({
      path: `/api/leads/${entityId}/notes/${noteId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadNote
   * @name UpdateLeadNote
   * @request PUT:/api/leads/{entityId}/notes/{noteId}
   * @secure
   */
  updateLeadNote = (entityId: string, noteId: string, data: UpdateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/leads/${entityId}/notes/${noteId}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadNote
   * @name DeleteLeadNote
   * @request DELETE:/api/leads/{entityId}/notes/{noteId}
   * @secure
   */
  deleteLeadNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/leads/${entityId}/notes/${noteId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadNote
   * @name CreateLeadNote
   * @request POST:/api/leads/{entityId}/notes
   * @secure
   */
  createLeadNote = (entityId: string, data: CreateNote, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/leads/${entityId}/notes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadNote
   * @name QueryLeadNotes
   * @request GET:/api/leads/{entityId}/notes
   * @secure
   */
  queryLeadNotes = (
    entityId: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      private?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<NoteViewPagedNoteQueryCollectionViewModel, any>({
      path: `/api/leads/${entityId}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LeadNote
   * @name RestoreLeadNote
   * @request POST:/api/leads/{entityId}/notes/{noteId}/restore
   * @secure
   */
  restoreLeadNote = (entityId: string, noteId: string, params: RequestParams = {}) =>
    this.http.request<NoteView, any>({
      path: `/api/leads/${entityId}/notes/${noteId}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
