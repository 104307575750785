import * as React from "react";
import client from "../../ApiClient/client";
import { useState } from "react";
import AppContext from "../../Definitions/AppContext";
import { ActorType, CreateOrUpdateTicket, OrganizationView, PagedTicketQuery, TaggableType, TicketStatus, TicketView } from "../../ApiClient/swagger/data-contracts";
import { Capabilities } from "../../Definitions/_capabilties";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { Button, Form, Upload, UploadFile } from "antd";
import { Drawer } from "../../Modules/Shared/Drawer";
import { ActorWithCreateInput, CustomerSelector } from "../Actors";
import { OrganizationCreateForm } from "../Organizations";
import { PersonSelector } from "../People";
import DateSelector from "../Shared/DateSelector";
import { TagSelector } from "../Tags";
import TicketStatusSelector from "./TicketStatusSelector";
import TicketSeveritySelector from "./TicketSeveritySelector";
import { RequireCapability } from "../Shared/RequireCapability";
import { UploadOutlined } from "@ant-design/icons";
import { openNotification } from "../../Helpers/BasePageHelpers";

interface CreateTicketProps {
    onComplete: (created?: TicketView) => void;
    onCancel?: () => void;
    filters?: Partial<PagedTicketQuery> & { description?: string };
    organization?: Partial<OrganizationView>;
}

export function TicketCreateForm(props: CreateTicketProps)  {

    const [organization, setOrganization] = useState<Partial<OrganizationView>>(props.organization ?? null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showCreateOrganizationDrawer, setShowCreateOrganizationDrawer] = useState<boolean>(false);
    const [error, setError] = useState<string>();

    const context = React.useContext(AppContext);

    async function onSubmit(request: CreateOrUpdateTicket & { upload: UploadFile[] } ) {
        
        setLoading(true)

        const ticket = await client.tickets.createTicket(request).catch(exception => setError(exception.error));
        if (ticket) {
            if (request.upload && request.upload.length > 0) {
                const uploadPromises = request.upload.map(async (file) => {
                    await client.tickets.createTicketFile(ticket.data.id, {
                        file: file.originFileObj,
                        name: file.name,
                        "parent.collection": "tickets",
                        "parent.id": ticket.data.id,
                    }).catch(exception => {
                        openNotification(
                            "Error uploading file to ticket",
                            `An error occurred while uploading file ${file.name} to ticket ${ticket.data.name}. ${exception.error}`,
                            "error"
                        );
                    });
                });
                await Promise.all(uploadPromises);
            }

            props.onComplete(ticket.data);
        } 

        setLoading(false)
    }

    function toggleAddOrganizationDrawer() {
        setShowCreateOrganizationDrawer((current) => !current);
    }

    async function onCompleteAddOrganization(data: OrganizationView) {
        try {
            if (data) {
                setOrganization(data);
                toggleAddOrganizationDrawer();
            }
        }
        catch (error: unknown) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('An unknown error occurred');
            }
        }
    }

    const initialValues: Partial<CreateOrUpdateTicket> = {
        assigneeId: context.user.actorId,
        status: TicketStatus.Open,
    };

    if (props.filters?.customerId)
        initialValues.customerId = props.filters.customerId;
    else if (organization)
        initialValues.customerId = organization.id;

    if (props.filters?.requesterId)
        initialValues.requesterId = props.filters.requesterId;

    if (props.filters?.description)
        initialValues.description = props.filters.description;

    const actorTypes: ActorType[] = [];
    if (context.user.hasCapability(Capabilities.PeopleReadRelated))
        actorTypes.push(ActorType.Person);

    if (context.user.hasCapability(Capabilities.OrganizationsReadRelated))
        actorTypes.push(ActorType.Organization);

    const normFile = (e: { fileList: UploadFile[] }) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    return (
        <BaseForm
            type={FormType.Create}
            onSubmit={onSubmit}
            onCancel={props.onCancel}
            loading={loading}
            error={error}
            initialValues={initialValues}
        >
            <TextInput
                param="name"
                placeholder="Title"
                title="Title"
                required
            />

            <TextAreaInput
                param="description"
                placeholder="Description"
                title="Description"
            />

            <SelectorInput
                param="status"
                title= "Status"
                required
                selector={
                    <TicketStatusSelector
                        excludeAllOption
                    />
                }
            />

            <Form.Item
                label={<div>Upload files (optional)</div>}
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
            >
                <Upload
                    beforeUpload={() => false}
                    listType="picture"
                >
                    <Button icon={<UploadOutlined />}>
                        Upload
                    </Button>
                </Upload>
            </Form.Item>

            <SelectorInput
                param="severity"
                title= "Severity"
                selector={
                    <TicketSeveritySelector
                        excludeAllOption
                    />
                }
            />

            <SelectorInput
                param="customerId"
                title={
                    <>
                        <span style={{ width: '100%' }}>Customer (optional)</span>
                        <RequireCapability capability={Capabilities.OrganizationsWrite}>
                            <Button 
                                type="link" 
                                onClick={toggleAddOrganizationDrawer} 
                                className={`select-actor-title ${context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}
                            >
                                {context.isMobile ? "+ Add" : "+ Add organization"}
                            </Button>
                        </RequireCapability>
                    </>
                }
                optional={false}
                selector={
                    <CustomerSelector
                        types={actorTypes}
                        placeholder="Select customer..."
                    />
                }
            />
            

            <Drawer
                title="Create organization"
                onClose={toggleAddOrganizationDrawer}
                open={showCreateOrganizationDrawer}
                destroyOnClose={true}
                component={showCreateOrganizationDrawer ?
                    <OrganizationCreateForm
                        onCancel={toggleAddOrganizationDrawer}
                        onComplete={(created) => created ? onCompleteAddOrganization(created) : toggleAddOrganizationDrawer()}
                    /> : null
                }
            />

            <ActorWithCreateInput
                selectorTitle="Requester (optional)"
                param="requesterId"
                optional={false}
                selectorPlaceholder="Select person"
                types={[ActorType.Person]}
            />

            <SelectorInput
                param="assigneeId"
                title="Assignee"
                selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select person" disabled={!context.user.hasCapability(Capabilities.TicketsWrite)} />}
            />

            <SelectorInput
                param="dueDate"
                title="Due date"
                selector={<DateSelector />}
            />

            <SelectorInput
                param="otherParticipants"
                title="Other participants"
                selector={<PersonSelector multiple placeholder="Select people" filters={{ isEmployee: true }} />}
            />

            <SelectorInput
                param="tagIds"
                title="Tags"
                selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Ticket] }} />}
            />
        </BaseForm>
    );
}

export default TicketCreateForm;