import * as React from "react";
import _ from 'lodash';
import moment from 'moment';
import { PagedActivityQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { QuickSearch } from "../Shared/QuickSearch";

interface ActivityQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedActivityQuery>
}


export default class ActivityQuickSearch extends React.Component<ActivityQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }

    render = () => {
        return (
            <React.Fragment>
                <QuickSearch name="All" queryParameters={{
                    startAfter: null,
                    endBefore: null,
                    running: null
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="Running" queryParameters={{
                    startAfter: null,
                    endBefore: null,
                    running: true
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="This year" queryParameters={{
                    startAfter: moment().startOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    endBefore: moment().endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    running: null
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="Last year" queryParameters={{
                    startAfter: moment().subtract(1, "year").startOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    endBefore: moment().subtract(1, "year").endOf('year').format('YYYY-MM-DDTHH:mm:ss'),
                    running: null
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="This month" queryParameters={{
                    startAfter: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                    endBefore: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                    running: null
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>

                <QuickSearch name="Last month" queryParameters={{
                    startAfter: moment().subtract(1, "month").startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                    endBefore: moment().subtract(1, "month").endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
                    running: null
                }} currentQuery={this.props.query} onQueryChange={this.props.onQuickSearch}></QuickSearch>
            </React.Fragment>
        );
    }
}