import * as React from "react";
import _ from 'lodash';
import { ApplicationView, FileView, FolderView, PagedApplicationQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { FileInput } from "../Shared/FileInput";
import { DeleteEntityFile, EditEntityFile, GetEntityFolderById, RestoreEntityFile } from "../../ApiClient/EntityFileClient";
import client from "../../ApiClient/client";
import { ApplicationSelector } from "../Applications";
import { AdditionalInfo } from "../Shared/AdditionalInfo";
import { CustomFormLabel } from "../Shared/CustomFormLabel";
import { Loading3QuartersOutlined } from "@ant-design/icons";

interface EditFileProps {
    file: FileView;
    entityId: string;
    onComplete: (file: FileView) => void;
    onDelete?: () => void;
    onCancel?: () => void;
    updateFileSource: EditEntityFile;
    deleteFileSource: DeleteEntityFile;
    restoreFileSource: RestoreEntityFile;
    folderId: string;
    getFolderSource: GetEntityFolderById;
}

interface EditFileState {
    loading: boolean;
    error: string | null;
    filename: string;
    folder: FolderView;
    loadingFolder: boolean;
    application: ApplicationView;
    loadingApp: boolean;
}

export class FileEditForm extends React.Component<EditFileProps, EditFileState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            filename: null,
            folder: null,
            loadingFolder: false,
            application: null,
            loadingApp: false
        }
    }

    componentDidMount = async () => {
        if (!this.props.folderId) return;
        this.setState({ loadingFolder: true });

        try {
            const response = await this.props.getFolderSource(this.props.entityId, this.props.folderId);
            if (response) {
                this.setState({ folder: response.data.view });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loadingFolder: false });

        this.loadApplication();
    }

    loadApplication = async () => {
        if (!this.context.applicationId) return;

        this.setState({ loadingApp: true });

        const response = await client.applications.getApplicationById(this.context.applicationId).catch(ex => { });
        if (response) this.setState({ application: response.data.view });

        this.setState({ loadingApp: false });
    }

    onSubmit = async (request: any) => {
        this.setState({ loading: true });

        if (!request.file) {
            const fileData = await client.files.download(this.props.file.id);
            request.file = [fileData];
        }

        const mapRequest = () => {
            const data = { file: request.file[0], name: request.name };
            if (request.description) data["description"] = request.description;
            if (request.applicationIds) data["applicationIds"] = request.applicationIds;
            return data;
        }

        try {
            const response = await this.props.updateFileSource(this.props.entityId, this.props.file.id, mapRequest());

            if (response)
                this.props.onComplete(response.data);
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.onDelete) {
            this.props.onDelete();
            return;
        }
        if (this.props.file != null) {
            try {
                const response = await this.props.deleteFileSource(this.props.entityId, this.props.file.id);
                this.props.onComplete(response?.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onRestore = async () => {
        if (this.props.file != null) {
            try {
                const response = await this.props.restoreFileSource(this.props.entityId, this.props.file.id);
                this.props.onComplete(response?.data);
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }
    }

    onFileChange = (data) => {
        const filename = data.file.name.split('.').shift();
        this.setState({ filename });
    }

    render() {
        if (!this.props.file) return <div>No file</div>;

        const parentApps = _.flatMap(this.state.folder?.applications ?? []);
        const pathApps = _.flatMap(_.flatMap(this.state.folder?.path ?? [], p => p.applications) ?? []);
        const appNames = [..._.map(parentApps, a => a.name), ..._.map(pathApps, a => a.name), ...this.state.application && !this.state.folder?.attributes["entityId"] ? [this.state.application.name] : []];
        //const parentAppIds = [..._.map(parentApps, a => a.id), ..._.map(pathApps, a => a.id)];

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                isDeleted={this.props.file.deleted}
                isSystemPreset={this.props.file.systemPreset}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    name: this.state.filename ?? this.props.file.name,
                    description: this.props.file.description,
                    applicationIds: _.map(this.props.file.applications, x => x.id)
                }}
            >
                <FileInput
                    param="file"
                    onChange={this.onFileChange}
                />

                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Name"
                />

                <AdditionalInfo defaultOpen={this.props.file?.description != null || _.reject(this.props.file?.applications ?? [], a => a.id === this.context.applicationId)?.length > 0}>
                    <TextAreaInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                    />

                    <CustomFormLabel label="This file is accessible in" />
                    <div style={{ marginBottom: 24 }}>{this.state.loadingApp || this.state.loadingFolder ? <Loading3QuartersOutlined spin /> : _.uniq(appNames).join(", ") ?? "-"}</div>

                    <SelectorInput
                        param="applicationIds"
                        title="Share with"
                        selector={
                            <ApplicationSelector
                                disableOptionIds={[this.context.applicationId]}
                                multiple
                                filters={{
                                    deleted: false
                                } as PagedApplicationQuery}
                            />
                        }
                    />
                </AdditionalInfo>

            </BaseForm>
        );
    }
}

export default FileEditForm;
