import * as React from "react";
import _ from 'lodash';
import { Checkbox, Tooltip, Alert, Button } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { ActorView, CreateProject, OrganizationView, PagedProjectQuery, ProjectRoleType, ProjectType, ProjectView, TaggableType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { ActorIds, TagIds } from "../../Definitions/_definitions";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { PersonSelector } from "../People";
import ProjectTypeSelector from "./ProjectTypeSelector";
import { RequireCapability } from "../Shared/RequireCapability";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorSelector, CustomerSelector } from "../Actors";
import { PriceInput } from "../Shared/PriceInput";
import { PercentageInput } from "../Shared/PercentageInput";
import DateSelector from "../Shared/DateSelector";
import { DurationInput } from "../Shared/DurationInput";
import OrderCategorySelector from "../Orders/OrderCategorySelector";
import { TagSelector } from "../Tags";
import { Drawer } from "../Shared/Drawer";
import { OrganizationCreateForm } from "../Organizations";


interface CreateProjectProps {
    onCancel?: () => void;
    onComplete: (created: ProjectView) => void;
    organization?: OrganizationView;
    filters: Partial<PagedProjectQuery>;
}

interface CreateProjectState {
    loading: boolean;
    error: string;
    showCreateOrganizationDrawer: boolean;
    organization: Partial<OrganizationView>;
    type: ProjectType;
    activeChecked: boolean;
    customerNotInvoiceableWarning: boolean;
    setUserAsManager: boolean;
    displayMoreInfo: boolean;
}

interface CreateProjectWithLeaderRequest extends CreateProject {
    projectLeaderId: string;
}

export class ProjectCreateForm extends React.Component<CreateProjectProps, CreateProjectState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            showCreateOrganizationDrawer: false,
            organization: this.props.organization,
            type: null,
            activeChecked: false,
            customerNotInvoiceableWarning: false,
            setUserAsManager: false,
            displayMoreInfo: false
        }
    }

    onSubmit = async (request: CreateProjectWithLeaderRequest) => {
        this.setState({ loading: true });

        try {
            if (this.state.type == ProjectType.Internal) {
                request.customerId = null;
                request.invoiceRecipientId = null;
                request.price = null;
                request.discount = null;
            }
            else if (this.state.type == ProjectType.Fixed) {
                request.discount = null;
            }
            else if (this.state.type == ProjectType.Hourly) {
                request.price = null;
            }

            request.active = this.state.activeChecked;

            if (this.state.setUserAsManager)
                request.managerId = this.context.user.actorId;

            const response = await client.projects
                .createProject(request)
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) {
                this.props.onComplete(response.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    toggleAddOrganizationDrawer = () => {
        this.setState({ showCreateOrganizationDrawer: !this.state.showCreateOrganizationDrawer });
    }

    onCompleteAddOrganization = async (data: OrganizationView) => {
        try {
            if (data) {
                this.setState({ organization: data });
                this.toggleAddOrganizationDrawer();
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onProjectTypeChange = (type) => {
        this.setState({ type });
    }

    onCheckChange = () => {
        this.setState({ activeChecked: !this.state.activeChecked });
    }

    //onCustomerChange = (customer: ActorView) => {
    //    if (!customer) return;

    //    this.setState({
    //        customerNotInvoiceableWarning: _.find(customer.tags || [], tag => tag.id == TagIds.Invoiceable) == null
    //    });
    //}

    onProjectLeaderChange = (actorId: string) => {
        this.setState({ setUserAsManager: actorId != this.context.user.actorId });
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    render = () => {
        const isFixedOrHourly = this.state.type == ProjectType.Fixed || this.state.type == ProjectType.Hourly;

        const initialValues: Partial<CreateProjectWithLeaderRequest> = {};

        if (this.state.type == ProjectType.Fixed) {
            initialValues.price = 0;
        }
        else if (this.state.type == ProjectType.Hourly) {
            initialValues.discount = 0;
        }

        if (this.context.user.actorId != ActorIds.System && this.context.user.actorId && !this.state.setUserAsManager)
            initialValues.projectLeaderId = this.context.user.actorId;

        if ((this.state.type == ProjectType.Fixed || this.state.type == ProjectType.Hourly) && this.props.filters?.customerId)
            initialValues.customerId = this.props.filters.customerId;
        else if (this.state.organization)
            initialValues["customerId"] = this.state.organization.id;

        return (
            <div>
                <BaseForm
                    type={FormType.Create}
                    onSubmit={this.onSubmit}
                    onCancel={this.props.onCancel}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={initialValues}
                    className="project-form-drawer"
                >
                    <TextInput
                        param="name"
                        required
                        warningMessage="Please type a name"
                        placeholder="Name"
                        title="Project name"
                    />

                    <TextAreaInput
                        param="description"
                        placeholder="Write here"
                        title="Description"
                    />

                    <SelectorInput
                        title="Project leader"
                        param="projectLeaderId"
                        required
                        selector={<PersonSelector placeholder="Select person" onChange={this.onProjectLeaderChange} />}
                    />

                    {this.state.setUserAsManager ? <Alert className="manager-alert" message="You will automatically be set as manager in the project. You can change this after the project is created" type="info" showIcon /> : null}

                    <SelectorInput
                        param="type"
                        title="Type of project"
                        required
                        selector={
                            <ProjectTypeSelector
                                type="radio"
                                onChange={this.onProjectTypeChange}
                                className="project-type"
                                excludeAllOption
                            />
                        }
                    />

                    {this.state.type && isFixedOrHourly ?
                        <SelectorInput
                            param="customerId"
                            title={
                                <React.Fragment>
                                    <span style={{ width: '100%' }}>Customer</span>
                                    <RequireCapability capability={Capabilities.OrganizationsWrite}>
                                        <Button type="link" onClick={this.toggleAddOrganizationDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                                            {this.context.isMobile ? "+ Add" : "+ Add organization"}
                                        </Button>
                                    </RequireCapability>
                                </React.Fragment>
                            }
                            required
                            selector={
                                <CustomerSelector
                                    //onObjectChange={this.onCustomerChange}
                                    placeholder="Select a customer"
                                />
                            }
                        /> : null}

                    {this.state.type ?
                        <SelectorInput
                            title="Contact persons"
                            param="contactPersonIds"
                            selector={
                                <PersonSelector
                                    placeholder="Select contact persons"
                                    multiple
                                />
                            }
                        />
                        : null}

                    {this.state.type && this.state.type == ProjectType.Fixed ?
                        <PriceInput
                            title="Price"
                            param="price"
                            required
                        /> : null}

                    {this.state.type && this.state.type == ProjectType.Hourly ?
                        <PercentageInput
                            param="discount"
                            title="Discount"
                            required
                        /> : null}

                    {this.state.type ?
                        <div className="start-end-combo-input-container">
                            <div className="label-container">
                                <label className="start-end-label">Planned project period (optional)</label>
                            </div>
                            <div className="start-end-group">
                                <SelectorInput
                                    param="plannedStart"
                                    selector={<DateSelector placeholder="Start" />}
                                />

                                <SelectorInput
                                    param="plannedEnd"
                                    selector={<DateSelector placeholder="End" endOfDay />}
                                />
                            </div>
                        </div>
                        : null}

                    {this.state.type ?
                        <DurationInput
                            param="estimate"
                            title="Estimate"
                        /> : null}

                    {this.state.type ?
                        <Tooltip title="You can't track hours on the project before it's active. You can do this later aswell.">
                            <div><Checkbox onChange={this.onCheckChange} className="active-checkbox">Set project to active</Checkbox></div>
                        </Tooltip>
                        : null}

                    {this.state.type ?
                        <div className={`ant-form-item-label add-more-routine-info-header custom-routine-form-label ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}>
                            <div className="more-info-label">
                                <label className="label-text">Additional info (optional) <div className="more-info-icon-container">{this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}</div></label>
                            </div>
                        </div>
                        : null}

                    <div className="more-info-content" style={!this.state.displayMoreInfo || !this.state.type ? { display: 'none' } : {}}>
                        {isFixedOrHourly ?
                            <SelectorInput
                                param="invoiceId"
                                title="Use another invoice recipient"
                                selector={
                                    <ActorSelector
                                        placeholder="Select an invoice recipient"
                                        filters={{
                                            deleted: false
                                        }}
                                    />
                                }
                            /> : null}

                        {isFixedOrHourly ?
                            <SelectorInput
                                param="orderCategoryId"
                                title="Order category"
                                selector={
                                    <OrderCategorySelector
                                        placeholder="Select an order category"
                                        filters={{
                                            deleted: false
                                        }}
                                    />
                                }
                            /> : null}

                        <SelectorInput
                            param="tagIds"
                            title="Tags"
                            selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Project] }} />}
                        />
                    </div>

                    <Drawer
                        title="Create organization"
                        onClose={this.toggleAddOrganizationDrawer}
                        open={this.state.showCreateOrganizationDrawer}
                        destroyOnClose={true}
                        component={this.state.showCreateOrganizationDrawer ?
                            <OrganizationCreateForm
                                onCancel={this.toggleAddOrganizationDrawer}
                                onComplete={(created) => created ? this.onCompleteAddOrganization(created) : this.toggleAddOrganizationDrawer()}
                            /> : null
                        }
                    />

                </BaseForm>

                {/*this.state.customerNotInvoiceableWarning ? <Alert type="warning" message="Info: This customer is not tagged as invoicable." showIcon className="warning-text" /> : null*/}
            </div>
        );
    }
}

export default ProjectCreateForm;