import HighchartsReact, { HighchartsReactRefObject } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import {
    Aggregate,
    MeasurementThreshold,
    MeasurementType,
    MeasurementView,
} from "../../ApiClient/swagger/data-contracts";
import _ from "lodash";
import { getThresholdUnitFromType, mapMeasurementTypeName } from "./MeasuringPointHelpers";
import { Card, Empty, Spin } from "antd";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import AppContext from "../../Definitions/AppContext";
import Boost from "highcharts/modules/boost";

Boost(Highcharts);

type MeasuringPointGraphProps = {
    loading: boolean;
    cautionThreshold?: MeasurementThreshold;
    criticalThreshold?: MeasurementThreshold;
    type?: MeasurementType;
    title?: React.ReactNode;
    selected?: number;
    measuringPointId: string | undefined | null;
    data?: Aggregate[];
    className?: string;
};

const MeasuringPointGraph = ({
    loading,
    cautionThreshold,
    criticalThreshold,
    type,
    title,
    measuringPointId,
    data,
    className,
}: MeasuringPointGraphProps) => {
    const context = useContext(AppContext);
    const chartComponent = useRef<HighchartsReactRefObject>(null);

    const calculateOffset = (timestamp: string) => {
        const offset = new Date(timestamp).getTimezoneOffset();
        return new Date(timestamp).getTime() - offset * 60000;
    }

    const series = useMemo(
        () =>
            _.map(data, (m) => {
                return m.label ? [calculateOffset(m.label), m.average] : undefined;
            }),
        [data]
    );

    const onItemUpdatedEvent = useCallback((eventData: unknown) => {
        const measurementEvent = eventData as MeasurementView;
        if (measurementEvent.measuringPoint?.id === measuringPointId && measurementEvent.timestamp) {
            chartComponent.current.chart.series[0].addPoint([calculateOffset(measurementEvent.timestamp), measurementEvent.value]);
        }
    }, [measuringPointId]);

    useEffect(() => {
        context.events?.measurements.onMany({
            registered: onItemUpdatedEvent,
        });

        return () => {
            context.events?.measurements.offMany({
                registered: onItemUpdatedEvent,
            });
        };
    }, [onItemUpdatedEvent, context.events?.measurements]);

    const yAxis = useMemo(
        () => ({
            title: {
                text: `${type?.eventType} ( ${getThresholdUnitFromType(
                    type as MeasurementType
                )} )`,
            },
            opposite: false,
            softMin: criticalThreshold?.lowerOrEqual
                ? criticalThreshold?.lowerOrEqual
                : cautionThreshold?.lowerOrEqual
                ? cautionThreshold?.lowerOrEqual
                : undefined,
            softMax: criticalThreshold?.higherOrEqual
                ? criticalThreshold?.higherOrEqual
                : cautionThreshold?.higherOrEqual
                ? cautionThreshold?.higherOrEqual
                : undefined,
            plotLines: [
                {
                    color: "#f9e34e",
                    value: cautionThreshold?.higherOrEqual,
                    width: 2,
                    dashStyle: "ShortDash",
                    label: {
                        useHTML: true,
                        formatter: () => `<div>Caution <div>high</div></div>`,
                    },
                },
                {
                    color: "#f9e34e",
                    value: cautionThreshold?.lowerOrEqual,
                    width: 2,
                    dashStyle: "ShortDash",
                    label: {
                        useHTML: true,
                        formatter: () => `<div>Caution <div>low</div></div>`,
                    },
                },
                {
                    color: "#f3555e",
                    value: criticalThreshold?.higherOrEqual,
                    width: 2,
                    dashStyle: "ShortDash",
                    label: {
                        useHTML: true,
                        formatter: () => `<div>Critical <div>high</div></div>`,
                    },
                },
                {
                    color: "#f3555e",
                    value: criticalThreshold?.lowerOrEqual,
                    width: 2,
                    dashStyle: "ShortDash",
                    label: {
                        useHTML: true,
                        formatter: () => `<div>Critical <div>low</div></div>`,
                    },
                },
            ] as Highcharts.YAxisPlotLinesOptions[],
        }),
        [type, criticalThreshold, cautionThreshold]
    );

    const options: Highcharts.Options = {
        credits: {
            enabled: false,
        },
        rangeSelector: {
            enabled: false,
        },
        accessibility: {
            enabled: false,
        },
        yAxis,
        series: [
            {
                name: mapMeasurementTypeName(type),
                type: "line",
                data: series as never[],
                marker: {
                    enabled: undefined,
                    radius: 3,
                },
                tooltip: {
                    valueDecimals: 2,
                    valueSuffix: getThresholdUnitFromType(
                        type as MeasurementType
                    ),
                },
            },
        ],
    };

    useEffect(() => {
        if (
            chartComponent.current?.chart.xAxis[0] &&
            series !== undefined &&
            series.length > 0
        ) {
            chartComponent.current.chart.xAxis[0].setExtremes(undefined);
        }
    }, [series]);

    return (
        <Card
            title={title}
            className={`graph-card measuring-point-graph ${className}`}
        >
            <Spin spinning={loading}>
                {series !== undefined && series.length > 0 ? (
                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType="stockChart"
                        options={options}
                        ref={chartComponent}
                    />
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </Spin>
        </Card>
    );
};
export default MeasuringPointGraph;
