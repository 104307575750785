import * as React from "react";
import _ from 'lodash';
import { Button, Form, Input } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import SelectorProps from "../../Models/SelectorProps";

interface CheckboxlistProps extends SelectorProps {

}
interface CheckboxlistState {
    checkboxlist: string[];

}

class CheckboxlistSelector extends React.Component<CheckboxlistProps, CheckboxlistState> {
    constructor(props) {
        super(props);

        this.state = {
            checkboxlist: []
        }
    }

    componentDidMount = () => {
        if (this.props.value) {
            this.setState({ checkboxlist: this.props.value });
        }
    }

    componentDidUpdate = (prevProps: SelectorProps) => {
        if (!_.isEqual(this.props.value, prevProps.value)) {
            this.setState({ checkboxlist: this.props.value });
        }
    }

    changeText = (e, i) => {
        const text = e.target.value;
        this.setState(state => {
            state.checkboxlist[i] = (text);
            return { checkboxlist: state.checkboxlist };
        }, () => {
            if (this.props.onChange)
                this.props.onChange(this.state.checkboxlist);
            if (this.props.onObjectChange)
                this.props.onObjectChange(this.state.checkboxlist);
        });
    }

    removeChecklistEntry = (i) => {
        const checkboxlist = this.state.checkboxlist.slice();
        checkboxlist.splice(i, 1);
        this.setState({ checkboxlist }, () => {
            if (this.props.onChange)
                this.props.onChange(this.state.checkboxlist);
            if (this.props.onObjectChange)
                this.props.onObjectChange(this.state.checkboxlist);
        });
    }

    render = () => {
        const checkboxes = _.map(this.state.checkboxlist, (c, i) => {
            return <Form.Item key={i} name={["checklists", i]} className="checklist-entry" required={c.length == 0} rules={
                [{
                    required: c.length == 0,
                    message: 'Checklist entry cannot be empty'
                }]}
            ><Input
                    key={i}
                    placeholder={"Checklist entry"}
                    size="middle"
                    readOnly={false}
                    className="checklist"
                    type={"text"}
                    value={c}
                    onChange={e => { this.changeText(e, i) }}
                /><Button type="link" icon={<CloseOutlined />} onClick={() => this.removeChecklistEntry(i)} /></Form.Item>
        });
        return (
            <>
                {checkboxes}
                <Button size="small" icon={<PlusOutlined />} onClick={() => { const test = this.state.checkboxlist.slice(); test.push(""); this.setState({ checkboxlist: test }) }}>{"Add list element"}</Button>
            </>
        );
    }
}

export default CheckboxlistSelector;