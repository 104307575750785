import * as React from "react";
import _ from 'lodash';
import { Alert } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import AppContext from "../../Definitions/AppContext";
import { ActorView, ProjectType, ProjectView, TaggableType, UpdateProject } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { TagIds } from "../../Definitions/_definitions";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { ActorSelector, CustomerSelector } from "../Actors";
import { PersonSelector } from "../People";
import { PriceInput } from "../Shared/PriceInput";
import DateSelector from "../Shared/DateSelector";
import OrderCategorySelector from "../Orders/OrderCategorySelector";
import { TagSelector } from "../Tags";
import { PercentageInput } from "../Shared/PercentageInput";
import { DurationInput } from "../Shared/DurationInput";
import ProjectTypeSelector from "./ProjectTypeSelector";



interface EditProjectProps {
    project: ProjectView;
    onComplete: (response: ProjectView) => void;
    onCancel?: () => void;
}

interface EditProjectState {
    loading: boolean;
    error: string;
    type: ProjectType;
    //activeChecked: boolean;
    customerNotInvoiceableWarning: boolean;
    displayMoreInfo: boolean;
}

export class ProjectEditForm extends React.Component<EditProjectProps, EditProjectState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            type: this.props.project ? this.props.project.type : null,
            customerNotInvoiceableWarning: false,
            displayMoreInfo: this.props.project?.invoice != null || this.props.project?.orderCategory != null || (this.props.project?.tags != null && this.props.project.tags.length > 0)
        }
    }

    onSubmit = async (request: UpdateProject) => {
        this.setState({ loading: true });


        if (this.state.type == ProjectType.Internal) {
            request.customerId = null;
            request.invoiceRecipientId = null;
            request.price = null;
            request.discount = null;
        }
        else if (this.state.type == ProjectType.Fixed) {
            request.discount = null;
        }
        else if (this.state.type == ProjectType.Hourly) {
            request.price = null;
        }

        const response = await client.projects
            .updateProject(this.props.project.id, request, addChangeVectorHeader(this.props.project.changeVector))
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) this.props.onComplete(response.data);


        this.setState({ loading: false });
    }

    onDelete = async () => {
        if (this.props.project != null) {

            const response = await client.projects
                .deleteProject(this.props.project.id, addChangeVectorHeader(this.props.project.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    onRestore = async () => {
        if (this.props.project != null) {
            const response = await client.projects
                .restoreProject(this.props.project.id, addChangeVectorHeader(this.props.project.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    onProjectTypeChange = (type: ProjectType) => {
        this.setState({ type });
    }

    //onCustomerChange = (customer: ActorView) => {
    //    if (customer) {
    //        this.setState({
    //            customerNotInvoiceableWarning: _.find(customer.tags || [], tag => tag.id == TagIds.Invoiceable) == null
    //        })
    //    }
    //}

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    render = () => {
        if (!this.props.project) return <div>No project</div>;

        //const { user } = this.context;
        //var userRole = _.find(this.props.project.roles ?? [], r => r.actor.id == user.actorId);
        //var hasFullProjectAccess = user.hasAnyCapability([Capabilities.ProjectsWrite, Capabilities.ProjectsFullAccess]) || (user.hasCapability(Capabilities.ProjectsRoleBased) && (userRole?.role == ProjectRoleType.Leader || userRole?.role == ProjectRoleType.Manager))
        //var hasBasicProjectAccess = user.hasCapability(Capabilities.ProjectsRoleBased) && userRole?.role != ProjectRoleType.Viewer;


        const initialValues: Partial<UpdateProject> = {
            name: this.props.project.name,
            description: this.props.project.description,
            type: this.state.type ? this.state.type : this.props.project.type ?? null,
            estimate: this.props.project.estimate,
            plannedStart: this.props.project.plannedStart,
            plannedEnd: this.props.project.plannedEnd,
            contactPersonIds: _.map(this.props.project?.contacts ?? [], c => c.id),
            tagIds: _.map(this.props.project.tags, tag => {
                if (tag.category?.deleted || tag?.deleted) return;
                return tag.id; 
            })
        };

        if (this.state.type == ProjectType.Fixed) {
            initialValues.customerId = this.props.project.customer ? this.props.project.customer.id : null;
            initialValues.invoiceRecipientId = this.props.project.invoice ? this.props.project.invoice.id : null;
            initialValues.orderCategoryId = this.props.project.orderCategory ? this.props.project.orderCategory.id : null;
            initialValues.price = this.props.project.price ?? 0;
        }
        else if (this.state.type == ProjectType.Hourly) {
            initialValues.customerId = this.props.project.customer ? this.props.project.customer.id : null;
            initialValues.invoiceRecipientId = this.props.project.invoice ? this.props.project.invoice.id : null;
            initialValues.orderCategoryId = this.props.project.orderCategory ? this.props.project.orderCategory.id : null;
            initialValues.discount = this.props.project.discount ?? 0;
        }

        const isFixedOrHourly = this.state.type == ProjectType.Fixed || this.state.type == ProjectType.Hourly;

        return (
            <div>
                <BaseForm
                    type={FormType.Edit}
                    onSubmit={this.onSubmit}
                    onDelete={this.onDelete}
                    onRestore={this.onRestore}
                    onCancel={this.props.onCancel}
                    isDeleted={this.props.project.deleted}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={initialValues}
                    className="project-form-drawer"
                >
                    <TextInput
                        param="name"
                        required
                        warningMessage="Please type a name"
                        placeholder="Name"
                        title="Project name"
                    />

                    <TextAreaInput
                        param="description"
                        placeholder="Write here"
                        title="Description"
                    />

                    <SelectorInput
                        param="type"
                        title="Type of project"
                        required
                        selector={
                            <ProjectTypeSelector
                                type="radio"
                                onChange={this.onProjectTypeChange}
                                className="project-type"
                                excludeAllOption
                            />
                        }
                    />

                    {this.state.type && isFixedOrHourly ?
                        <SelectorInput
                            param="customerId"
                            title="Customer"
                            required
                            selector={
                                <CustomerSelector
                                    //onObjectChange={this.onCustomerChange}
                                    placeholder="Select a customer"
                                />
                            }
                        /> : null}

                    {this.state.type ?
                        <SelectorInput
                            title="Contact persons"
                            param="contactPersonIds"
                            selector={
                                <PersonSelector
                                    placeholder="Select contact persons"
                                    multiple
                                />
                            }
                        />
                        : null}

                    {this.state.type && this.state.type == ProjectType.Fixed ?
                        <PriceInput
                            title="Price"
                            param="price"
                            required
                        /> : null}

                    {this.state.type && this.state.type == ProjectType.Hourly ?
                        <PercentageInput
                            param="discount"
                            title="Discount"
                            required
                        /> : null}

                    {this.state.type ?
                        <div className="start-end-combo-input-container">
                            <div className="label-container">
                                <label className="start-end-label">Planned project period (optional)</label>
                            </div>
                            <div className="start-end-group">
                                <SelectorInput
                                    param="plannedStart"
                                    selector={<DateSelector placeholder="Start" />}
                                />

                                <SelectorInput
                                    param="plannedEnd"
                                    selector={<DateSelector placeholder="End" endOfDay />}
                                />
                            </div>
                        </div>
                        : null}

                    {this.state.type ?
                        <DurationInput
                            param="estimate"
                            title="Estimate"
                            duration={this.props.project.estimate}
                        /> : null}


                    {this.state.type ?
                        <div className={`ant-form-item-label add-more-routine-info-header custom-form-label ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}>
                            <div className="more-info-label">
                                <label className="label-text">Additional info (optional) </label>
                                <div className="more-info-icon-container">{this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}</div>
                            </div>
                        </div>
                        : null}

                    {this.state.type ?
                        <div className="more-info-content" style={!this.state.displayMoreInfo ? { display: 'none' } : {}}>

                            {isFixedOrHourly ?
                                <SelectorInput
                                    param="invoiceRecipientId"
                                    title="Use another invoice recipient"
                                    selector={
                                        <ActorSelector
                                            placeholder="Select an invoice recipient"
                                        />
                                    }
                                /> : null}

                            {this.state.type && isFixedOrHourly ?
                                <SelectorInput
                                    param="orderCategoryId"
                                    title="Order category"
                                    selector={
                                        <OrderCategorySelector
                                            placeholder="Select an order category"
                                            filters={{
                                                deleted: false
                                            }}
                                        />
                                    }
                                /> : null}

                            <SelectorInput
                                param="tagIds"
                                title="Tags"
                                selector={
                                    <TagSelector
                                        multiple filters={{ taggableTypes: [TaggableType.Project] }}
                                    />
                                }
                            />
                        </div>
                        : null}

                </BaseForm>

                {/*this.state.customerNotInvoiceableWarning ? <Alert type="warning" message="Info: This customer is not tagged as invoicable." showIcon className="warning-text" /> : null*/}

            </div>
        );
    }
}

export default ProjectEditForm;