/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateTagCategory,
  SortDirection,
  TagCategorySortOption,
  TagCategoryView,
  TagCategoryViewEntityViewModel,
  TagCategoryViewPagedTagCategoryQueryCollectionViewModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Tagcategories<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags TagCategory
   * @name GetTagCategoryById
   * @request GET:/api/tagcategories/{id}
   * @secure
   */
  getTagCategoryById = (id: string, params: RequestParams = {}) =>
    this.http.request<TagCategoryViewEntityViewModel, any>({
      path: `/api/tagcategories/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TagCategory
   * @name UpdateTagCategory
   * @request PUT:/api/tagcategories/{id}
   * @secure
   */
  updateTagCategory = (id: string, data: CreateOrUpdateTagCategory, params: RequestParams = {}) =>
    this.http.request<TagCategoryView, any>({
      path: `/api/tagcategories/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TagCategory
   * @name DeleteTagCategory
   * @request DELETE:/api/tagcategories/{id}
   * @secure
   */
  deleteTagCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<TagCategoryView, any>({
      path: `/api/tagcategories/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TagCategory
   * @name CreateTagCategory
   * @request POST:/api/tagcategories
   * @secure
   */
  createTagCategory = (data: CreateOrUpdateTagCategory, params: RequestParams = {}) =>
    this.http.request<TagCategoryView, any>({
      path: `/api/tagcategories`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TagCategory
   * @name QueryTagCategories
   * @request GET:/api/tagcategories
   * @secure
   */
  queryTagCategories = (
    query?: {
      sortBy?: TagCategorySortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      excludeLeadCauses?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TagCategoryViewPagedTagCategoryQueryCollectionViewModel, any>({
      path: `/api/tagcategories`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TagCategory
   * @name RestoreTagCategory
   * @request POST:/api/tagcategories/{id}/restore
   * @secure
   */
  restoreTagCategory = (id: string, params: RequestParams = {}) =>
    this.http.request<TagCategoryView, any>({
      path: `/api/tagcategories/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TagCategory
   * @name UpdateTagCategoryAttributes
   * @request POST:/api/tagcategories/{id}/attributes
   * @secure
   */
  updateTagCategoryAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<TagCategoryView, any>({
      path: `/api/tagcategories/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
