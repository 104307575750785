import * as React from "react";
import { TaskType } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";
import SelectorInformation from "../Shared/SelectorInformation";


export class TaskTypeSelector extends React.Component<EnumSelectorProps> {
    renderOptionItem = (role: TaskType) => {

        let hoverInfoText = "";

        switch (role) {
            case TaskType.Normal:
                hoverInfoText = "A normal task will be available for time tracking, and inside the task you can also add subtasks available for time tracking.";
                break;
            case TaskType.Container:
                hoverInfoText = "You cannot track hours on a container task. But inside the task you can add subtasks that can be available for time tracking.";
                break;
            default:
                hoverInfoText = "";
                break;
        }

        return <SelectorInformation displayText={role} hoverText={hoverInfoText} />;
    }

    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select task type...';

        return (
            <EnumSelector
                enum={TaskType}
                placeholder={placeholder}
                {...this.props}
                renderOptionItem={this.renderOptionItem}
                className={`task-type-selector ${this.props.className}`}
            />
        );
    }
}

export default TaskTypeSelector;