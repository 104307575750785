
export enum ImageExtentions{
    ".bmp", ".jpg", ".png", ".gif", ".ico", "jpeg"
}

export enum DocExtentions {
    ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".txt"
}

export enum VideoExtentions {
    ".avi", ".mpg", ".mp4", ".wmv", ".mov", ".flv", ".swf", ".mkv"
}
