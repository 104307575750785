import _ from "lodash";
import moment from "moment";
import client from "../client";
import { PagedTaskQuery, TaskView } from "../swagger/data-contracts";
import BaseExportService, { ExportConfig, ExportFormat } from "./BaseExportService";

export class TaskExport extends BaseExportService<PagedTaskQuery, TaskView> {
    constructor() {
        const specification: ExportConfig<TaskView>[] = [
            { header: 'Task name', value: (data: TaskView) => data.name ?? null },
            { header: 'Project', value: (data: TaskView) => data.project ? data.project.name : null },
            { header: 'Task owner', value: (data: TaskView) => data.owner ? data.owner.name : null },
            { header: 'Assignee', value: (data: TaskView) => data.assignee ? data.assignee.name : null },
            { header: 'Category', value: (data: TaskView) => data.category ? data.category.name : null },
            { header: 'Description', value: (data: TaskView) => data.description ? data.description : null },
            { header: 'Actual start', value: (data: TaskView) => data.actualStart ? moment(data.actualStart).format() : null },
            { header: 'Actual end', value: (data: TaskView) => data.actualEnd ? moment(data.actualEnd).format() : null },
            { header: 'Planned start', value: (data: TaskView) => data.plannedStart ? moment(data.plannedStart).format() : null },
            { header: 'Planned end', value: (data: TaskView) => data.plannedEnd ? moment(data.plannedEnd).format() : null },
            { header: 'Duration', value: (data: TaskView) => data.aggregatedDuration ? data.aggregatedDuration : null, format: ExportFormat.Duration },
            { header: 'Progress', value: (data: TaskView) => data.aggregatedEstimate != null && data.aggregatedEstimate > 0 ? Math.round(data.aggregatedDuration / data.aggregatedEstimate * 100) + '%' : null, format: ExportFormat.Percentage },
            { header: 'Estimate', value: (data: TaskView) => data.aggregatedEstimate != null && data.aggregatedEstimate > 0 ? data.aggregatedEstimate : null, format: ExportFormat.Duration },
            { header: 'Closed', value: (data: TaskView) => data.actualEnd == null ? false : true },
            {
                header: 'Attributes', value: (data: TaskView) => {
                    let dataString = "";

                    if (data.attributes) {
                        _.each(data.attributes, (key, value) => {
                            if (value != "rootFolderId") {
                                if (dataString.length > 0) dataString = dataString + ", ";

                                dataString = dataString + key + ": " + value;
                            }
                        })
                    }

                    return dataString;
                }
            },
            { header: 'Created', value: (data: TaskView) => data.created ? moment(data.created).format('DD.MM.YYYY') : null },
            { header: 'Updated', value: (data: TaskView) => data.lastModified ? moment(data.lastModified).format('DD.MM.YYYY') : null },
            { header: 'Deleted', value: (data: TaskView) => data.deleted ? true : false }
        ];

        super(specification, client.tasks.queryTasks);
    }
}