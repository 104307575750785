/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActivityTransferAggregationResultsActivityTransferAggregateQueryAggregationResults,
  ActivityTransferAggregationTypes,
  ActivityTransferSortOption,
  ActivityTransferView,
  ActivityTransferViewEntityViewModel,
  ActivityTransferViewPagedActivityTransferQueryCollectionViewModel,
  ActivityWarningViewPagedActivityTransferWarningQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Activitytransfers<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ActivityTransfer
   * @name GetActivityTransferById
   * @request GET:/api/activitytransfers/{id}
   * @secure
   */
  getActivityTransferById = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityTransferViewEntityViewModel, any>({
      path: `/api/activitytransfers/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ActivityTransfer
   * @name QueryActivityTransfers
   * @request GET:/api/activitytransfers
   * @secure
   */
  queryActivityTransfers = (
    query?: {
      sortBy?: ActivityTransferSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
      /** @format date-time */
      lastCalculated?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActivityTransferViewPagedActivityTransferQueryCollectionViewModel, any>({
      path: `/api/activitytransfers`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ActivityTransfer
   * @name TransferActivityTransfer
   * @request POST:/api/activitytransfers/{id}/transfer
   * @secure
   */
  transferActivityTransfer = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityTransferView, any>({
      path: `/api/activitytransfers/${id}/transfer`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ActivityTransfer
   * @name QueryActivityTransferWarnings
   * @request GET:/api/activitytransfers/{id}/warnings
   * @secure
   */
  queryActivityTransferWarnings = (
    id: string,
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActivityWarningViewPagedActivityTransferWarningQueryCollectionViewModel, any>({
      path: `/api/activitytransfers/${id}/warnings`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ActivityTransfer
   * @name GetActivityTransferAggregates
   * @request GET:/aggs/activitytransfers
   * @secure
   */
  getActivityTransferAggregates = (
    query?: {
      aggs?: ActivityTransferAggregationTypes[];
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
      /** @format date-time */
      lastCalculated?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActivityTransferAggregationResultsActivityTransferAggregateQueryAggregationResults, any>({
      path: `/aggs/activitytransfers`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ActivityTransfer
   * @name CalculateActivityTransfer
   * @request GET:/api/activitytransfers/{id}/calculate
   * @secure
   */
  calculateActivityTransfer = (id: string, params: RequestParams = {}) =>
    this.http.request<ActivityTransferView, any>({
      path: `/api/activitytransfers/${id}/calculate`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
