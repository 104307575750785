import * as React from "react";
import moment from 'moment';
import AppContext from "../../Definitions/AppContext";
import { TicketLinkView, TicketView } from "../../ApiClient/swagger/data-contracts";
import { Avatar, Card, Popover, Spin } from "antd";
import client from "../../ApiClient/client";
import { GroupOutlined } from "@ant-design/icons";
import { ActorLink } from "../Actors";
import { Capabilities } from "../../Definitions/_capabilties";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

const { Meta } = Card;


interface TicketLinkCardProps {
    ticketId: string;
}

interface TicketLinkCardState {
    ticket?: TicketView;
    error?: Error;
}

export class TicketLinkCard extends React.Component<TicketLinkCardProps, TicketLinkCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            ticket: null,
            error: null
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.loadTicket();


        this.context.events.tickets.onMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentWillUnmount = () => {
        this._isMounted = false;

        this.context.events.tickets.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.ticketId !== this.props.ticketId) {
            this.loadTicket()
        }
    }

    onItemUpdatedEvent = (eventData: TicketView) => {
        if (this.state.ticket && this.state.ticket.id === eventData.id) {
            if (this.state.ticket.changeVector == eventData.changeVector) {
                return;
            }
            this.setState({
                ticket: eventData
            });
        }
    }

    loadTicket = async () => {
        try {
            const response = await client.tickets.getTicketById(this.props.ticketId);

            if (response && this._isMounted)
                this.setState({ ticket: response.data.view });

        } catch (error: any) {
            this.setState({ error });
        }
    }

    render = () => {
        if (this.state.error) {
            return this.state.error.message;
        }

        if (!this.state.ticket)
            return <Spin size="large" />;

        const avatar = <Avatar shape="square" size={100} icon={<GroupOutlined />} className="avatar" />

        return (
            <Card className="ticket-link-card" bordered={false} size="small" title={this.state.ticket.name}>
                <Meta avatar={avatar} description={
                    <dl>
                        {this.state.ticket.customer ? <div className="customer"><dt>Customer</dt>{<ActorLink {...this.state.ticket.customer} />}</div> : null}
                        {this.state.ticket.requester ? <div className="requester"><dt>Requester</dt>{<ActorLink {...this.state.ticket.requester} />}</div> : null}
                        {this.state.ticket.assignee ? <div className="assignee"><dt>Assignee</dt>{<ActorLink {...this.state.ticket.assignee} />}</div> : null}
                        {this.state.ticket.dueDate ? <div className="description"><dt>Due date</dt>{moment(this.state.ticket.dueDate).format("DD.MM.YYYY")}</div> : null}
                        {this.state.ticket.description ? <div className="description"><dt>Description</dt>{this.state.ticket.description}</div> : null}
                    </dl>
                }
                />
            </Card>
        );

    }
}


interface TicketLinkProps extends TicketLinkView {
    disablePopover?: boolean;
    break?: boolean;
}

export function TicketLink(props: TicketLinkProps){
    const [ticket, setTicket] = useState<TicketLinkView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.tickets.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.tickets.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    function onItemUpdatedEvent(eventData: TicketView) {
        if (ticket.id === eventData.id) {
            setTicket(eventData);
        }
    }

    if (!context.user.hasCapability(Capabilities.TicketsReadRelated))
        return <Popover content="You do not have access to read tickets.">{ticket.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (ticket.deleted)
        className += " deleted";

    const link = <Link
        style={props.disablePopover ? { color: 'unset' } : null}
        to={`/tickets/${ticket.id}`}
        className={className}>
        {ticket.name}
    </Link>;

    if (props.disablePopover)
        return link;

    return (
        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
            <Popover placement="bottomLeft" content={<TicketLinkCard ticketId={ticket.id} />}>
                {link}
            </Popover>
        </span>
    );
}