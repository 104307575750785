import * as React from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { Popover, Spin, Card, Avatar, Button } from 'antd';
import { BankOutlined } from "@ant-design/icons";
import { ContactPointLinkView, ContactPointType, ApplicationView, ApplicationLinkView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { useContext, useEffect, useState } from "react";


const { Meta } = Card;

interface ApplicationLinkCardProps {
    id?: string;
}


export function ApplicationLinkCard({ id }: ApplicationLinkCardProps) {

    const [error, setError] = useState<string>();
    const [avatarUrl, setAvatarUrl] = useState<string>();
    const [application, setApplication] = useState<ApplicationView>();

    const context = useContext(AppContext);

    useEffect(() => {
        if (!application || id != application.id)
            loadApplication();

        context.events.applications.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.applications.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }

    }, [id, application])


    function onItemUpdatedEvent(eventData: ApplicationView) {
        if (application?.id === eventData.id) {
            if (application.changeVector == eventData.changeVector) return;

            setApplication(eventData);
        }
    }

    async function loadApplication() {
        const response = await client
            .applications.getApplicationById(id)
            .catch(exception => setError(exception.error));

        if (response) {
            setApplication(response.data.view);
            if (response.data.links?.avatar)
                setAvatarUrl(response.data.links.avatar.url);
        }
    }

    if (error) return <span>{error}</span>;
    if (!application) return <Spin size="large" />;

    const fallbackAvatar = <Avatar shape="square" size={100} icon={<BankOutlined />} className="avatar" />;
    const avatar = avatarUrl ? <Avatar src={avatarUrl} shape="square" alt="avatar" /> : fallbackAvatar;

    return (
        <Card className="actor-link-card link-card" bordered={false} size="small" title={application.name}>
            <Meta avatar={avatar} description={
                <React.Fragment>
                    {application.description}
                </React.Fragment>
            }
            />
        </Card>
    );
}

interface ApplicationLinkProps extends ApplicationLinkCardProps, ApplicationLinkView {
    changeVector?: string;
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
}



export function ApplicationLink(props: ApplicationLinkProps) {

    //const [error, setError] = useState<string>();

    const [application, setApplication] = useState<ApplicationLinkView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.applications.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.applications.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    function onItemUpdatedEvent(eventData: ApplicationView) {
        if (application.id === eventData.id) {
            setApplication(eventData);
        }
    }

    if (!application.name) return null;

    if (!context.user.hasCapability(Capabilities.ApplicationsRead))
        return <Popover content="You do not have access to read actors.">{application.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (application.deleted)
        className += " deleted";

    if (props.className)
        className += " " + props.className;

    const link = props.disableRedirect
        ? <Button type="link" className={className}>{application.name}</Button>
        : <Link onClick={(e) => e.stopPropagation()} to={`/applications/${application.id}`} className={className}>{application.name}</Link>;

    if (props.disablePopover || context.isMobile)
        return link;

    return (
        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
            <Popover zIndex={1051} placement="bottomLeft" content={<ApplicationLinkCard id={application.id} />}>
                {link}
            </Popover>
        </span>
    );
}