import * as React from "react";
import _ from 'lodash';
import { ActorType, ActorView, ContactLinkView, ContactPointType, ContactPointView, ContactSortOption, CreateContactPoint, UpdateContactPoint } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { Capabilities } from "../../Definitions/_capabilties";
import BaseForm, { FormType } from "../Shared/Form";
import { ContactPointTypeSelector } from ".";
import { SelectorInput } from "../Shared/SelectorInput";
import { TextInput } from "../Shared/TextInput";
import { AdditionalInfo } from "../Shared/AdditionalInfo";
import { TextAreaInput } from "../Shared/TextAreaInput";
import ContactSelector from "../Contacts/ContactSelector";

interface CreateContactPointProps {
    onComplete: (created: ContactPointView) => void;
    onCancel?: () => void;
    actor?: ActorView;
    types?: ContactPointType[];
}

interface CreateContactPointState {
    loading: boolean;
    error: string | null;
    cp: ContactPointView;
    type: ContactPointType;
    contact: ContactLinkView;
}


export class ContactPointCreateForm extends React.Component<CreateContactPointProps, CreateContactPointState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            cp: null,
            type: null,
            contact: null
        }
    }

    onSubmit = async (request: CreateContactPoint) => {
        this.setState({ loading: true });

        if (this.state.cp) {
            if (this.props.actor)
                request.contactId = this.props.actor.id;
            else if (this.state.contact)
                request.contactId = this.state.contact.id;

            const updateResponse = await client.contactPoints
                .updateContactPoint(this.state.cp.id, request as UpdateContactPoint)
                .catch(ex => this.setState({ error: ex.error }));

            if (updateResponse) this.props.onComplete(updateResponse.data);
        }
        else {
            if (this.props.actor)
                request.contactId = this.props.actor.id;
            else if (this.state.contact)
                request.contactId = this.state.contact.id;

            const createResponse = await client.contactPoints
                .createContactPoint(request as CreateContactPoint)
                .catch(ex => this.setState({ error: ex.error }));

            if (createResponse) this.props.onComplete(createResponse.data);
        }

        this.setState({ loading: false });
    }

    onObjectChange = (obj) => {
        this.setState({ cp: obj ?? null });
    }

    onTypeChange = (type) => {
        this.setState({ type: type });
    }

    onContactChange = (contact: ContactLinkView) => {
        this.setState({ contact: contact });
    }

    render = () => {
        let initVals = {};
        if (this.state.cp)
            initVals = {
                type: this.state.cp.type,
                description: this.state.cp.description
            };

        if (this.state.contact)
            initVals["contact"] = this.state.contact;


        const { user } = this.context;

        const types: ActorType[] = [];

        if (user.hasCapability(Capabilities.PeopleReadRelated))
            types.push(ActorType.Person);
        if (user.hasCapability(Capabilities.OrganizationsReadRelated))
            types.push(ActorType.Organization);

        const excludedCpTypes = this.props.types ? _.reject(ContactPointType, type => this.props.types.includes(type)) : null;

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={initVals}
                className="cp-form"
            >
                <SelectorInput
                    title="Select type of contact info"
                    param="type"
                    required
                    selector={<ContactPointTypeSelector excluded={excludedCpTypes} className="cp-type-selector" excludeAllOption disabled={this.state.cp != null} onChange={this.onTypeChange} type="radio" />}
                    warningMessage="Please choose a type"
                />

                {/*this.props.actor ?
                    <Form.Item name="value">
                        <ContactPointAutoComplete
                            title="Contact info"
                            onObjectChange={this.onObjectChange}
                            placeholder="Enter contact point"
                            filters={{ missingActor: true, types: this.state.type ? [this.state.type] : null }}
                        />
                    </Form.Item> :*/
                    <TextInput
                        param="value"
                        required
                        warningMessage="This field is required"
                        placeholder="Enter contact info"
                        title="Contact info"
                    />}


                {this.props.actor ?
                    null :
                    <SelectorInput
                        title="Contact"
                        param="contact"
                        warningMessage="This field is required"
                        required
                        selector={
                            <ContactSelector
                                placeholder="Select"
                                onObjectChange={this.onContactChange}
                                filters={{
                                    sortBy: ContactSortOption.Name
                                }}
                            />
                        }
                    />
                }

                <AdditionalInfo>
                    <TextAreaInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                        rows={4}
                    />
                </AdditionalInfo>

            </BaseForm>
        );
    }
}

export default ContactPointCreateForm;