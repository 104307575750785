import { TicketSeverity } from "../../ApiClient/swagger/data-contracts";
import { EnumSelectorProps } from "../../Models/EnumSelectorProps";
import EnumSelector from "../Shared/EnumSelector";


export function TicketSeveritySelector(props: EnumSelectorProps) {

    return (
        <EnumSelector
            className="ticket-status-selector"
            enum={TicketSeverity}
            placeholder={props.placeholder ?? 'Select ticket severity...'}
            {...props}
        />
    );
}

export default TicketSeveritySelector;