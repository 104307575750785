import * as XLSX from 'xlsx';
import _ from 'lodash';
import moment from 'moment';
import { WorkSheet } from "xlsx";
import { openModal } from '../../Helpers/BasePageHelpers';
import { HttpResponse } from '../swagger/http-client';

export interface ExportConfig<T> {
    header: string;
    value: (T) => string | number | boolean;
    format?: ExportFormat;
}
export enum ExportFormat {
    Default = 'Default',
    Date = 'Date',
    Percentage = 'Percentage',
    Duration = 'Duration',
    Currency = "Currency"
}

export interface CollectionViewModel<TItem, TQuery> {
    items?: TItem[] | null;
    query?: TQuery;
    count?: number;
}


export default class BaseExportService<TQuery, TView> {
    //where Tquery extends ...

    protected defaultSpec: ExportConfig<TView>[];
    protected queryFunction: (q: Partial<TQuery>) => Promise<HttpResponse<CollectionViewModel<TView, TQuery>, any>>;

    constructor(
        defaultSpecification: ExportConfig<TView>[],
        queryFunction: (q: Partial<TQuery>) => Promise<HttpResponse<CollectionViewModel<TView, TQuery>, any>>
    ) {

        this.defaultSpec = defaultSpecification;
        this.queryFunction = queryFunction;
    }



    private queryCheck = (query: Partial<TQuery>, specification: ExportConfig<TView>[]) => {
        //if ((query as Partial<PagedActorQuery>).types) {
        //    let newSpec: ExportConfig<TView>[] = [];

        //    _.map(specification, spec => {
        //        newSpec.push({ header: spec.header, value: spec.value });
        //    });

        //    let actorQuery = query as Partial<ActorQuery>;
        //    if (actorQuery.types.includes(ActorType.Organization)) {
        //        newSpec.push(
        //            {
        //                header: 'Primary', value: (data: OrganizationView) => {
        //                    var handlers = "";
        //                    _.map(data.handlers, (handler) => {
        //                        if (handler.type == HandlerType.Primary) {
        //                            if (handlers.length > 0) handlers = handlers + ", ";
        //                            handlers = handlers + handler.employee.name;
        //                        }
        //                    })
        //                    return handlers;
        //                }
        //            },
        //            {
        //                header: 'Secondary', value: (data: OrganizationView) => {
        //                    var handlers = "";
        //                    _.map(data.handlers, (handler) => {
        //                        if (handler.type == HandlerType.Secondary) {
        //                            if (handlers.length > 0) handlers = handlers + ", ";
        //                            handlers = handlers + handler.employee.name;
        //                        }
        //                    })
        //                    return handlers;
        //                }
        //            },
        //            {
        //                header: 'Consultant', value: (data: OrganizationView) => {
        //                    var handlers = "";
        //                    _.map(data.handlers, (handler) => {
        //                        if (handler.type == HandlerType.Consultant) {
        //                            if (handlers.length > 0) handlers = handlers + ", ";
        //                            handlers = handlers + handler.employee.name;
        //                        }
        //                    })
        //                    return handlers;
        //                }
        //            },
        //            {
        //                header: 'Active subscription', value: (data: OrganizationView) => {
        //                    var subscriptionDate = data.metadata && data.metadata.subscriptionDuration ? moment(data.metadata.subscriptionDuration) : null;
        //                    if (subscriptionDate != null) {
        //                        if (subscriptionDate.format('YYYY') == "9999" || subscriptionDate.isSameOrAfter(moment())) {
        //                            return true;
        //                        }
        //                        else {
        //                            return false;
        //                        }
        //                    }
        //                    else {
        //                        return false;
        //                    }
        //                }
        //            },
        //            { header: 'Last order', value: (data: OrganizationView) => data.metadata && data.metadata.lastOrder ? moment(data.metadata.lastOrder).format() : null },
        //            { header: 'Last call', value: (data: OrganizationView) => data.metadata && data.metadata.lastCall ? moment(data.metadata.lastCall).format() : null, format: ExportFormat.Date },
        //        );
        //    } else if (actorQuery.types.includes(ActorType.Person)) {
        //        newSpec.push(
        //            {
        //                header: 'Organizations', value: (data: PersonView) => {
        //                    var organizations = "";
        //                    _.map(data.organizationRoles, (or: OrganizationRoleView, index) => {
        //                        if (index != 0) organizations = organizations + ", ";
        //                        organizations = organizations + or.organization.name;
        //                    })
        //                    return organizations;
        //                }
        //            },
        //            { header: 'Last call', value: (data: PersonView) => data.metadata && data.metadata.lastCall ? moment(data.metadata.lastCall).format() : null },
        //        );
        //    } else if (actorQuery.types.includes(ActorType.Resource)) {
        //        newSpec.push({ header: 'Organization', value: (data: ResourceView) => data.organization ? data.organization.name : null },);
        //    }
        //    specification = newSpec;
        //}

        return specification;
    }
    protected correctCase = (name: string) => {
        return name.charAt(0).toLowerCase() + name.slice(1);
    };

    private getData = async (query: Partial<TQuery>, specification: ExportConfig<TView>[] = null) => {
        if (specification == null)
            specification = this.defaultSpec;

        specification = this.queryCheck(query, specification);

        const fullQuery = Object.assign(query, { from: 0, limit: 2147483647 });
        const res = await this.queryFunction(fullQuery);

        const headers = [];
        const rows = [];
        const formats = [];


        (res.data.items ?? []).forEach((data, index) => {
            const row = [];

            specification.forEach(spec => {
                if (index == 0)
                    headers.push(spec.header);

                if (index == 0)
                    formats.push(spec.format);

                row.push(spec.value(data))
            })

            rows.push(row);
        })

        return {
            headers: headers,
            data: rows,
            formats: formats
        }
    }
    private getSheetFromData(COLUMNS: any[], data: any[], formats: ExportFormat[], opts: any = {}): WorkSheet {
        const ws = {};

        const range = { s: { c: COLUMNS[0].length, r: 10000000 }, e: { c: 0, r: 0 } };
        for (let R = 0; R !== data.length; ++R) {
            const dataRow = R;
            const dataToPrint = data[dataRow];
            for (let C = 0; C !== COLUMNS[0].length; C++) {
                if (range.s.r > R) {
                    range.s.r = R;
                }
                if (range.s.c > C) {
                    range.s.c = C;
                }
                if (range.e.r < R) {
                    range.e.r = R;
                }
                if (range.e.c < C) {
                    range.e.c = C;
                }

                const cell = {};
                const format = formats[C];

                cell['v'] = dataToPrint[C];

                if (!cell['v'] && typeof cell['v'] != 'boolean')
                    continue;

                const cell_ref = XLSX.utils.encode_cell({ c: C, r: R });

                if (R == 0) {//Header
                    ws[cell_ref] = cell;
                    continue;
                }


                if (typeof cell['v'] == 'number') {
                    cell['t'] = 'n';
                } else if (typeof cell['v'] == 'boolean') {
                    cell['t'] = 'b';
                } else if (moment(cell['v'], "YYYY-MM-DDTHH:mm:ssZ", true).isValid()) {
                    cell['t'] = 'd';
                } else {
                    cell['t'] = 's';
                }

                if (format && format == ExportFormat.Percentage) {
                    cell['v'] = parseFloat(cell['v']) / 100;
                    cell['t'] = 'n'
                    cell['z'] = '0.00 %'
                }
                else if (format && format == ExportFormat.Duration) {
                    cell['v'] = parseFloat(cell['v']) / 86400000
                    cell['t'] = 'n'
                    cell['z'] = '[h]:mm:ss'
                }
                else if (format && format == ExportFormat.Currency) {
                    cell['v'] = parseFloat(cell['v']);
                    cell['t'] = 'n';
                    cell['z'] = '0,00';
                }

                ws[cell_ref] = cell;
            }
        }
        if (range.s.c < 10000000) {
            ws['!ref'] = XLSX.utils.encode_range(range);
        }
        return ws;
    }

    private getColumnWidths = (data) => {
        const objectMaxLength = [];
        for (let i = 0; i < data.length; i++) {
            const value = <any>Object.values(data[i]);
            for (let j = 0; j < value.length; j++) {
                if (!value[j] && typeof value[j] != "boolean")
                    continue;

                if (typeof value[j] == "number") {
                    objectMaxLength[j] = 10;
                } else if (typeof value[j] == "boolean") {
                    objectMaxLength[j] = 10;
                } else {
                    objectMaxLength[j] =
                        objectMaxLength[j] >= value[j].length
                            ? objectMaxLength[j]
                            : value[j].length;
                }
            }
        }
        const wscols = [];
        _.each(objectMaxLength, length => {
            wscols.push({ width: length });
        })
        return wscols;
    }

    excel = async (query: Partial<TQuery>, name: string, specification: ExportConfig<TView>[] = null, shouldReturnNull: boolean = true) => {
        const countQuery = Object.assign(query, { from: 0, limit: 0 });
        const countRes = await this.queryFunction(countQuery);

        if (shouldReturnNull && countRes.data.count > 20000) {
            await openModal(
                `Warning: ${countRes.data.count} results`,
                'Exporting large datasets to Excel files can take several minutes. \n Please do not navigate away from this page before export is completed. \n Press export to continue.',
                "warn",
                async () => {
                    await this.excel(query, name, specification, false)
                },
                "Export",
            )
        }

        if (shouldReturnNull && countRes.data.count > 20000) return;

        const data = await this.getData(query, specification);

        if (data == null || data.data.length == 0) {
            return;
        }

        let newHeaders = [];

        newHeaders.push(data.headers);
        let joined = newHeaders.concat(data.data);

        const indexesWithValue = [];
        _.each(data.data, (ent) => {
            _.each(ent, (val, index) => {
                if (val) {
                    const i = indexesWithValue.indexOf(index);
                    if (i < 0)
                        indexesWithValue.push(index);
                }
            });
        });

        joined = _.map(joined, j => {
            return _.filter(j, (val, ind) => {
                const index = indexesWithValue.indexOf(ind);
                return index >= 0;
            });
        });
        newHeaders = _.map(newHeaders, j => {
            return _.filter(j, (val, ind) => {
                const index = indexesWithValue.indexOf(ind);
                return index >= 0;
            });
        });
        const formats = _.filter(data.formats, (val, ind) => {
            const index = indexesWithValue.indexOf(ind);
            return index >= 0;
        });

        name = moment().format("DD-MM-YYYY").toString() + "-" + name;

        if (name.length > 30) {
            name = name.slice(0, 30)
        }

        const wscols = this.getColumnWidths(joined);

        const ws = this.getSheetFromData(newHeaders, joined, formats);
        ws["!cols"] = wscols;

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, name);
        XLSX.writeFile(wb, name + ".xlsx", { bookSST: true, bookType: "xlsx" });
    }
}