import _ from 'lodash';
import moment from 'moment';
import { Card } from "antd";
import ListViewCard from "../Shared/ListViewCard";
import { ActorLinkView, CallMissedReason, CallStatus, CallView, PagedCallQuery } from "../../ApiClient/swagger/data-contracts";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Definitions/AppContext";
import client from '../../ApiClient/client';
import { updateCollectionWithEvent } from '../../Helpers/BasePageHelpers';
import { ActorLink } from '../Actors';
import { useNavigate } from 'react-router-dom';
import { formatDuration } from '../../Helpers/Formatters';
import { ColumnExpander } from '../Shared/ColumnExpander';
import CountUpDuration from '../Shared/CountUpDuration';
import { ContactPointLink, ContactPointPartialLink } from '../ContactPoints';


interface CallWidgetProps {
    title?: any;
    className?: string;
    query: Partial<PagedCallQuery>;
    exclutions?: string[];
    onItemCreatedEvent?: (collection: CallView[], call: CallView) => CallView[];
    customColumns?: any[];
    takeElements?: number;
    loadAllOnLoadMore?: boolean;
    //formFilters?: Partial<PagedCallQuery>;
}

export function CallWidget(props: CallWidgetProps) {

    const [collection, setCollection] = useState<CallView[]>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    //const [showCreateDrawer, setShowCreateDrawer] = useState<boolean>(false);

    const context = useContext(AppContext);
    const navigate = useNavigate();

    async function loadCollection() {
        setLoading(true);

        const response = await client.calls
            .queryCalls(props.query)
            .catch(exception => setError(exception.error));

        if (response) setCollection(response.data.items)

        setLoading(false);
    }

    function onItemCreatedEvent(response: CallView) {
        if (!response || !props.onItemCreatedEvent) return;

        setCollection(prevCollection => {
            const newCollection = props.onItemCreatedEvent(prevCollection?.slice() ?? [], response);
            return newCollection ?? [];
        });
    }

    function onItemUpdatedEvent(response: CallView) {
        if (!response) return;

        setCollection(prevCollection => {
            const newCollection = updateCollectionWithEvent(prevCollection?.slice() ?? [], response);
            return newCollection ?? [];
        });
    }

    useEffect(() => {
        if (!collection) loadCollection();

        context.events.calls.onMany({
            'created': onItemCreatedEvent,
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent
        });

        return () => {
            context.events.calls.offMany({
                'created': onItemCreatedEvent,
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent
            });
        }
    }, [])

    const baseColumns = _.reject(props.customColumns ?? [
        {
            title: 'Time',
            render: (call: CallView) => {
                if (call.start)
                    return moment(call.start).format('DD.MM.YYYY HH:mm');

                return moment(call.init).format('DD.MM.YYYY HH:mm')
            },
            key: 'start',
            width: 140
        },
        {
            title: 'Status',
            render: (call: CallView) => {
                const callStatusText = call.status == CallStatus.Connected ? "Active" : call.status;

                return call.status == CallStatus.Missed && call.missedReason && call.missedReason != CallMissedReason.Unknown
                    ? `${callStatusText}: ${call.missedReason}`
                    : callStatusText;
            },
            key: 'status',
            width: 140
        },
        {
            title: 'Duration',
            render: (call: CallView) => {
                if (call.status == CallStatus.Connected)
                    return <CountUpDuration start={call.start} />;
                else
                    return call.duration ? formatDuration(call.duration) : null;
            },
            key: 'duration',
            width: 100
        },
        {
            title: 'From',
            render: (call: CallView) => {
                const actor = call.from?.contact ? <ActorLink {...call.from.contact} /> : null;
                const cp = actor ? <ContactPointLink {...call.from} hideLabel disablePopover /> : <ContactPointPartialLink contactPoint={call.from} hideLabel disablePopover />;

                return (
                    <>
                        <div>{actor ?? cp}</div>
                        {actor ? <div>({cp})</div> : null}
                    </>
                );
            },
            key: 'from',
            ellipsis: true
        },
        {
            title: 'To',
            render: (call: CallView) => {
                const actor = call.to?.contact ? <ActorLink {...call.to.contact} /> : null;
                const cp = actor ? <ContactPointLink {...call.to} hideLabel disablePopover /> : <ContactPointPartialLink contactPoint={call.to} hideLabel disablePopover />;

                return (
                    <>
                        <div>{actor ?? cp}</div>
                        {actor ? <div>({cp})</div> : null}
                    </>
                );
            },
            key: 'to',
            ellipsis: true
        },
        {
            title: "Organizations",
            render: (data: CallView) => {
                const actors = _.map(data.organizations, (a: ActorLinkView) => {
                    return <div key={a.id}><ActorLink {...a} /></div>;
                });
                return <ColumnExpander>{actors}</ColumnExpander>;
            },
            key: 'organizations',
            ellipsis: true
        },
        {
            title: 'Note',
            key: "note",
            render: (call: CallView) => <div className="vertical-limit">{call.note}</div>
        }
    ], c => _.find(props.exclutions, e => { return c.key == e }) != null);

    if (error) return <Card title={props.title}>{error}</Card>;

    return (
        <>
            <ListViewCard
                title={props.title}
                columns={baseColumns}
                data={collection}
                loading={loading}
                onSelect={(data: CallView) => navigate(`/calls/${data.id}`)}
                className="call-widget"
                //actions={context.user.hasCapability(Capabilities.CallsWrite) ? <Button key="add" size="small" onClick={() => setShowCreateDrawer(previous => !previous)}>Add call</Button> : null}
                {...props}
            />

            {/*<Drawer
                title="Create call"
                onClose={() => setShowCreateDrawer(false)}
                open={showCreateDrawer}
                component={
                    <CallCreateForm
                        filters={props.query}
                        onCancel={() => setShowCreateDrawer(false)}
                        onComplete={() => setShowCreateDrawer(previous => !previous)}
                    />
                }
            />*/}
        </>
    );
}