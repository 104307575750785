import * as React from "react";
import _ from 'lodash';
import { PagedSubscriptionQuery, SubscriptionStatusType, SubscriptionType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { QuickSearch } from "../Shared/QuickSearch";


interface SubscriptionQuickSearchProps {
    onQuickSearch: (filters) => any,
    query: Partial<PagedSubscriptionQuery>
}

export class SubscriptionQuickSearch extends React.Component<SubscriptionQuickSearchProps> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
    }


    render = () => {
        return (
            <>
                <QuickSearch name="All" queryParameters={{
                    statuses: [],
                    types: [],
                    hasDifference: null
                }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Active" queryParameters={{
                    statuses: [SubscriptionStatusType.Active]
                }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Deviation" queryParameters={{
                    hasDifference: true
                }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Monthly" queryParameters={{
                    types: [SubscriptionType.Monthly]
                }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Quarterly" queryParameters={{
                    types: [SubscriptionType.Quarterly]
                }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />

                <QuickSearch name="Yearly" queryParameters={{
                    types: [SubscriptionType.Yearly]
                }}
                    currentQuery={this.props.query}
                    onQueryChange={this.props.onQuickSearch}
                />
            </>
        );
    }
}