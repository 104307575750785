/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  SensorEventType,
  SensorPartSortOption,
  SensorPartViewEntityViewModel,
  SensorPartViewPagedSensorPartQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class SensorParts<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SensorPart
   * @name GetSensorPartById
   * @request GET:/api/sensorParts/{id}
   * @secure
   */
  getSensorPartById = (id: string, params: RequestParams = {}) =>
    this.http.request<SensorPartViewEntityViewModel, any>({
      path: `/api/sensorParts/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SensorPart
   * @name DeleteSensorPartById
   * @request DELETE:/api/sensorParts/{id}
   * @secure
   */
  deleteSensorPartById = (id: string, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/api/sensorParts/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SensorPart
   * @name QuerySensorsParts
   * @request GET:/api/sensorParts
   * @secure
   */
  querySensorsParts = (
    query?: {
      sortBy?: SensorPartSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      sensorId?: string;
      resourceId?: string;
      sensorEventType?: SensorEventType;
      availableForResourceId?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SensorPartViewPagedSensorPartQueryCollectionViewModel, any>({
      path: `/api/sensorParts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
