import { Form, InputNumber } from "antd";
﻿import * as React from "react";
import { NamePath } from "antd/es/form/interface";

interface NumberInputProps {
    placeholder?: string;
    required?: boolean;
    warningMessage?: string;
    param: NamePath;
    title?: string;
    min?: number;
    max?: number;
    validator?: (rule: any, value: any) => any;
    noStyle?: boolean;
    onChange?: (nr: number) => void;
    value?: number;
    addonAfter?: string;
    formClassName?: string;
}


export function NumberInput(props: NumberInputProps) {

    function onValidate(rule, value) {
        if (!props.required)
            return Promise.resolve();

        if (props.validator)
            return props.validator(rule, value);

        if (value)
            return Promise.resolve();
        else
            return Promise.reject(props.warningMessage);
    }

    const { param, required, warningMessage, placeholder } = props;

    const optional = !props.required ? " (optional)" : "";
    const title = props.title ? <div>{props.title} {optional}</div> : null;

    const rules = {
        required: required,
        message: props.validator ? null : warningMessage ? warningMessage : 'Please type a value for this field'
    };

    if (props.min)
        rules["min"] = props.min;

    if (props.max)
        rules["max"] = props.max;

    return (
        <Form.Item
            label={title}
            name={param}
            noStyle={props.noStyle === true ? true : props.noStyle === false ? false : false}
            rules={[
                {
                  required: required,
                  message: warningMessage ? warningMessage : 'Please type a value for this field',
                },
                {
                  type: 'number',
                  message: 'Please enter a valid number',
                },
              ]}
            className={props.formClassName}
        >
            <InputNumber
                placeholder={placeholder}
                size="middle"
                style={{ width: '100%' }}
                onChange={props.onChange}
                min={props.min}
                max={props.max}
                value={props.value ?? null}
                addonAfter={props.addonAfter}
                controls={false}
                decimalSeparator=","
            />
        </Form.Item>
    );

}