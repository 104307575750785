import * as React from "react";
import client from "../../ApiClient/client";
import { PagedContactPointQuery } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";



export class ContactPointSelector extends React.Component<GenericEntitySelectorProps<PagedContactPointQuery>> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select contact point...';
        const source = this.props.source ? this.props.source : client.contactPoints.queryContactPoints;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.contactPoints.getContactPointById;
        const optionDisplayNameParam = this.props.optionDisplayNameParam ? this.props.optionDisplayNameParam : 'value';

        return (
            <EntitySelector
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                optionDisplayNameParam={optionDisplayNameParam}
                {...this.props}
            />
        );
    }
}

export default ContactPointSelector;