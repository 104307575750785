import * as React from "react";
import _ from 'lodash';
import { ApplicationView, FileView, FolderView, PagedApplicationQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import BaseForm, { FormType } from "../Shared/Form";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { FileInput } from "../Shared/FileInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { CreateEntityFile, CreateEntityFileWebRequest, GetEntityFolderById } from "../../ApiClient/EntityFileClient";
import { ApplicationSelector } from "../Applications";
import client from "../../ApiClient/client";
import { CustomFormLabel } from "../Shared/CustomFormLabel";
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { FolderIds } from "../../Definitions/_definitions";
import { AdditionalInfo } from "../Shared/AdditionalInfo";



interface CreateFileProps {
    onCancel?: () => void;
    onComplete: (created: FileView) => void;
    entityId: string;
    folderId: string;
    getFolderSource: GetEntityFolderById;
    createFileSource: CreateEntityFile;
}

interface CreateFileState {
    loading: boolean;
    loadingApp: boolean;
    loadingFolder: boolean;
    error: string | null;
    filename: string;
    fileListLength: number;
    folder: FolderView;
    application: ApplicationView;
}


export class FileCreateForm extends React.Component<CreateFileProps, CreateFileState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);

        this.state = {
            loading: false,
            loadingApp: false,
            loadingFolder: false,
            error: null,
            filename: null,
            fileListLength: null,
            folder: null,
            application: null
        }
    }

    componentDidMount = async () => {
        if (this.props.folderId) {
            this.setState({ loadingFolder: true });

            try {
                const response = await this.props.getFolderSource(this.props.entityId, this.props.folderId);
                if (response) {
                    this.setState({ folder: response.data.view });
                }
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }

            this.setState({ loadingFolder: false });
        }

        this.loadApplication();
    }

    onSubmit = async (request: any) => {
        this.setState({ loading: true });

        try {
            const mapRequest = (file, name) => {
                const data: CreateEntityFileWebRequest = { file, name };
                if (request.description) data.description = request.description;
                if (this.props.folderId != null && this.props.folderId !== FolderIds.FakeDocumentFolderId) {
                    data["parent.id"] = this.state.folder.reference.id;
                    data["parent.collection"] = this.state.folder.reference.collection
                }
                if (request.applicationIds) data.applicationIds = request.applicationIds;
                return data;
            }            

            if (request.file.length > 1) {
                _.each(request.file, async (req) => {
                    const response = await this.props.createFileSource(this.props.entityId, mapRequest(req.originFileObj, req.name));
                    if (response) this.props.onComplete(response.data);
                });
            } else {
                const response = await this.props.createFileSource(this.props.entityId, mapRequest(request.file[0], request.name));
                if (response) this.props.onComplete(response.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message == null ? "Failed to save file" : error.message });
        }

        this.setState({ loading: false });
    }

    onFileChange = (data) => {

        const output = data.fileList.length == 0 ? null : data.file.status == "removed" && data.fileList.length == 1 ? data.fileList[0].name : data.file.name;
        const filename = output.substr(0, output.lastIndexOf('.')) || output;

        //var imagextentions = _.map(Object.values(ImageExtentions), e => { if (typeof (e) == "string") return e.toString() }).filter(e => e);
        //var docextentions = _.map(Object.values(DocExtentions), e => { if (typeof (e) == "string") return e.toString() }).filter(e => e);
        //var videoextentions = _.map(Object.values(VideoExtentions), e => { if (typeof (e) == "string") return e.toString() }).filter(e => e);

        //var allExtentions = imagextentions.concat(docextentions).concat(videoextentions);

        //var filename = data.fileList.length == 0 ? null : data.file.status == "removed" && data.fileList.length == 1 ? data.fileList[0].name : data.file.name;
        //_.each(allExtentions, (e) => {
        //    var regEx = new RegExp(e, "ig");
        //    filename = filename == null ? null : filename.replace(regEx, "");
        //});

        this.setState({
            filename,
            fileListLength: data.fileList.length
        });
    }


    loadApplication = async () => {
        if (!this.context.applicationId) return;

        this.setState({ loadingApp: true });

        const response = await client.applications.getApplicationById(this.context.applicationId).catch(ex => { });
        if (response) this.setState({ application: response.data.view });

        this.setState({ loadingApp: false });
    }

    render() {
        const parentApps = _.flatMap(this.state.folder?.applications ?? []);
        const pathApps = _.flatMap(_.flatMap(this.state.folder?.path ?? [], p => p.applications) ?? []);
        const appNames = [..._.map(parentApps, a => a.name), ..._.map(pathApps, a => a.name), ...this.state.application && !this.state.folder?.attributes["entityId"] ? [this.state.application.name] : []];

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    name: this.state.filename
                }}
            >
                <FileInput
                    param="file"
                    required
                    onChange={this.onFileChange}
                    multiple
                />

                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Name"
                    disabled={this.state.fileListLength > 1 ? true : false}
                />

                <AdditionalInfo>
                    <TextAreaInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                    />

                    <CustomFormLabel label="This file will be accessible in" />
                    <div style={{ marginBottom: 24 }}>{this.state.loadingApp || this.state.loadingFolder ? <Loading3QuartersOutlined spin /> : _.uniq(appNames).join(", ") ?? "-"}</div>

                    <SelectorInput
                        param="applicationIds"
                        title="Share with"
                        selector={
                            <ApplicationSelector
                                disableOptionIds={[this.context.applicationId]}
                                multiple
                                filters={{
                                    deleted: false
                                } as PagedApplicationQuery}
                            />
                        }
                    />
                </AdditionalInfo>
            </BaseForm>
        );
    }
}

export default FileCreateForm;
