import * as React from "react";
import _ from 'lodash';
import { Card, Button } from "antd";
import Title from "antd/lib/typography/Title";
import { SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { CallView, OrganizationAccessLevel } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { Capabilities } from "../../Definitions/_capabilties";
import TextArea from "antd/lib/input/TextArea";



interface CallNoteProps {
    call: CallView
    className?: string
}

interface CallNoteState {
    value: string,
    conflict: boolean,
    error?: any
}

export class CallNoteCard extends React.Component<CallNoteProps, CallNoteState> {

    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            value: this.props.call ? this.props.call.note : null,
            conflict: false
        }
    }

    componentDidUpdate = (prevProps: CallNoteProps) => {
        if (!this.props.call || prevProps == this.props) return;

        const newStringValue = this.props.call.note || "";
        const oldStringValue = prevProps.call ? prevProps.call.note || "" : "";
        const editedStringValue = this.state.value || "";

        if (newStringValue === "" || newStringValue == editedStringValue) {
            if (this.state.conflict)
                this.setState({ conflict: false });

            this.setState({ value: this.props.call.note });
            return;
        }

        const isValueEdited = editedStringValue != oldStringValue;

        if (isValueEdited && newStringValue != oldStringValue) {
            this.setState({ conflict: true });
            return;
        }

        if (newStringValue != oldStringValue) {
            this.setState({ value: this.props.call.note, conflict: false })
        }
    }

    deleteNote = async () => {
        try {
            const result = await client.calls.deleteCallNote(
                this.props.call.id,
                addChangeVectorHeader(this.props.call.changeVector)
            );

            this.setState({
                error: null
            })
        }
        catch (e) {
            this.setState({
                error: e
            });
        }
    }

    saveChanges = async () => {

        try {
            const result = this.state.value && this.state.value != ""
                ? await client.calls.addOrUpdateCallNote(
                    this.props.call.id,
                    { note: this.state.value },
                    addChangeVectorHeader(this.props.call.changeVector)
                )
                : await client.calls.deleteCallNote(
                    this.props.call.id,
                    addChangeVectorHeader(this.props.call.changeVector)
                );

            this.setState({
                error: null
            })
        }
        catch (e) {
            this.setState({
                error: e
            });
        }
    }

    autoSaveChanges = _.debounce(this.saveChanges, 2000);

    onValueChange = (e) => {
        const value = e.target.value;

        if (value !== this.state.value) {
            this.setState({ value });

            if (value && value != "") {
                this.autoSaveChanges();
            }
        }
    }

    render() {
        if (!this.props.call) return null;
        const { call } = this.props;
        const stringValue = this.state.value || "";
        const noteValue = this.props.call.note || "";

        const willDelete = stringValue == "" && noteValue != "";
        const isChanged = stringValue !== noteValue;
        const isConflict = this.state.conflict;
        let isEditable = false;

        const user = this.context.user;

        if (user.hasCapability(Capabilities.CallsWrite))
            isEditable = true;
        else if (user.hasCapability(Capabilities.CallsWriteRelated)) {
            const toOrganizations = _.map((call.to?.organizationRoles ?? []), role => {
                return role.organization?.id;
            });
            const fromOrganizations = _.map((call.from?.organizationRoles ?? []), role => {
                return role.organization?.id;
            });
            const allCallOrganizations = toOrganizations.concat(fromOrganizations);

            let allUserOrganizations = null;
            if (user.isEmployee)
                allUserOrganizations = user.companies ? Object.keys(user.companies).concat(user.handlerCompanies) : user.handlerCompanies
            else
                allUserOrganizations = user.companies ? _.compact(_.map(user.companies, (c, org) => { if (c == OrganizationAccessLevel.Standard) return org; })) : [];

            let authorized = false;
            _.each(allUserOrganizations, org => {
                if (_.indexOf(allCallOrganizations, org) != -1)
                    authorized = true;
            });

            isEditable = authorized;
        }
        else if (user.hasCapability(Capabilities.CallsWriteActor))
            isEditable = user.actorId == call.to?.contact?.id || user.actorId == call.from?.contact?.id;

        const text = willDelete ? "Delete" : isConflict ? "Override" : isChanged ? "Save" : "Saved";
        const icon = willDelete ? <DeleteOutlined /> : <SaveOutlined />;

        const save = isEditable
            ? <Button className="action" onClick={this.saveChanges} disabled={!willDelete && !isChanged} icon={icon} > {text}</Button>
            : null;

        return (
            <Card
                key="call-note-card"
                title={
                    <>
                        <Title level={4} className="title">Call note</Title>
                        {save}
                    </>
                }
                className={`call-note-card info-card-container ${this.props.className ? this.props.className : ""}`}
            >
                <TextArea
                    placeholder="Start writing your note here"
                    value={this.state.value}
                    readOnly={!isEditable}
                    onChange={this.onValueChange}
                    bordered={false}
                    autoSize={{
                        minRows: 2,
                        maxRows: 10
                    }}
                />
                {/*<PlainTextEditor value={this.state.value} setValue={this.onValueChange} readOnly={!isEditable} placeholder="Start writing your note here" />*/}
            </Card>
        );
    }
}

export default CallNoteCard;