import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { CreateComment, UpdateComment } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { ActorIds } from "../../Definitions/_definitions";
import { PersonSelector } from "../People";
import { RequireCapability } from "../Shared/RequireCapability";

interface CommentInputProps {
    action: CommentInputAction,
    text?: string,
    reply?: boolean,
    onSubmit: (data: any, topLevel?: boolean) => any,
    loading?: boolean,
    className?: string,
    writeAccess?: boolean,
    writeCapability: Capabilities;
}

interface CommentInputState {
    value: string,
    actor: string
}

export enum CommentInputAction {
    Create,
    Reply,
    Edit
}

export class CommentInput extends React.Component<CommentInputProps, CommentInputState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props)
        this.state = {
            value: undefined,
            actor: undefined,
        }
    }

    onInputChange = (event) => {
        this.setState({ value: event.target.value });
    }

    onActorChange = (event) => {
        this.setState({ actor: event });
    }

    onSubmit = () => {
        switch (this.props.action) {
            case CommentInputAction.Create:
                var createRequest: Partial<CreateComment> = {
                    text: this.state.value,
                    actorId: this.state.actor
                };
                this.props.onSubmit(createRequest, true);
                break;
            case CommentInputAction.Edit:
                var updateRequest: Partial<UpdateComment> = {
                    text: this.state.value
                };
                this.props.onSubmit(updateRequest);
                break;
            case CommentInputAction.Reply:
                var replyRequest: Partial<CreateComment> = {
                    text: this.state.value,
                    actorId: this.state.actor
                };
                this.props.onSubmit(replyRequest);
                break;
            default:
                break;
        }

        this.setState({ value: null });
    }

    render = () => {
        const text = this.props.text ? this.props.text : "";
        let buttonText = "";

        switch (this.props.action) {
            case CommentInputAction.Create:
                buttonText = "Add comment";
                break;
            case CommentInputAction.Edit:
                buttonText = "Save";
                break;
            case CommentInputAction.Reply:
                buttonText = "Add reply";
                break;
            default:
                buttonText = "";
                break;
        }

        return (
            <RequireCapability bypass={this.props.writeAccess} capability={this.props.writeCapability}>
                <div className={`comment-input ${this.props.className ? this.props.className : ""}`}>
                    <TextArea
                        rows={1}
                        onChange={this.onInputChange}
                        value={this.state.value ? this.state.value : text}
                    />
                    <div>
                        {this.props.action != CommentInputAction.Edit && this.context.user.actorId == ActorIds.System ?
                            <RequireCapability capability={Capabilities.FilesWrite}>
                                <PersonSelector onChange={this.onActorChange} value={this.context.user.actorId == ActorIds.System ? null : this.context.user.actorId} className="comment-actor" />
                            </RequireCapability> : null}
                        <Button type="primary" className="submit-comment" loading={this.props.loading} onClick={this.onSubmit}>{buttonText}</Button>
                    </div>
                </div>
            </RequireCapability>
        );
    }
}