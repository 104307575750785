import * as React from "react";
import client from "../../ApiClient/client";
import { PagedPersonQuery, PersonView } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";


export class EmployeeSelector extends React.Component<GenericEntitySelectorProps<PagedPersonQuery>> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select employee...';
        const source = this.props.source ? this.props.source : client.people.queryPeople;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.people.getPersonById;
        const optionDisplayNameParam = this.props.optionDisplayNameParam ? this.props.optionDisplayNameParam : 'name';

        return (
            <EntitySelector
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                optionDisplayNameParam={optionDisplayNameParam}
                renderOptionItem={(e: PersonView) => e.name}
                renderLabelItem={(e: PersonView) => e.name}
                filters={Object.assign({}, this.props.filters ?? {}, { isEmployee: true })}
                {...this.props}
            />
        );
    }
}


export default EmployeeSelector;