import * as React from "react";
import _ from 'lodash';
import client from "../../ApiClient/client";
import { Drawer } from "../../Modules/Shared/Drawer";
import { Button, Popconfirm } from "antd";
import AppContext from "../../Definitions/AppContext";
import { ProductPartView, ProductType, ProductView } from "../../ApiClient/swagger/data-contracts";
import { ProductLink } from "./ProductLink";
import { formatCurrency } from "../../Helpers/Formatters";
import { ColumnExpander } from "../Shared/ColumnExpander";
import { Capabilities } from "../../Definitions/_capabilties";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import ListViewCard from "../Shared/ListViewCard";
import ProductPartForm from "./ProductPartForm";


interface ProductPartListProps {
    product: ProductView;
    className?: string;
    onComplete: (part: ProductView) => void;
}

interface ProductPartListState {
    showCreateDrawer: boolean,
    part: ProductPartView,
    error: string,
    loadingRemove: boolean,
}

export class ProductPartList extends React.Component<ProductPartListProps, ProductPartListState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            showCreateDrawer: false,
            part: null,
            error: null,
            loadingRemove: false
        }
    }

    onRemove = async (part: ProductPartView) => {
        this.setState({ loadingRemove: true });

        if (part != null) {
            try {

                const response = await client.products.deleteProductPart(this.props.product.id, part.product.id);
                if (response) this.props.onComplete(response.data);
                this.onEditDrawerClose();
            }
            catch (error: any) {
                this.setState({ error: error.message });
            }
        }

        this.setState({ loadingRemove: false });
    }

    toggleCreateDrawer = () => {
        this.setState({ showCreateDrawer: !this.state.showCreateDrawer });
    }

    onEdit = async (part) => {
        this.setState({ part });
    }

    onEditDrawerClose = () => {
        this.setState({ part: null });
    }

    render = () => {
        if ((this.props.product && this.props.product.parts === undefined) || this.props.product.type != ProductType.Standard) return null;

        const listColumns = [
            {
                title: 'Product',
                render: (part: ProductPartView) => part.product ? <ProductLink {...part.product} /> : "",
                key: 'actor',
            },
            {
                title: 'Default unit',
                render: (part: ProductPartView) => part.product?.defaultUnit ?? "",
                key: 'unit'
            },
            {
                title: 'Quantity',
                dataIndex: 'quantity',
                key: 'quantity'
            },
            {
                title: 'Cost price',
                render: (data: ProductPartView) => {
                    const costPrices = _.filter(data.product.prices, (price) => { return price.cost != null });
                    const prices = _.map(costPrices, (value, key) => {
                        return <div key={value.unit ?? "Default"} className="extend">{value.unit ?? "Default"}: {formatCurrency(value.cost)}</div>
                    });
                    return <ColumnExpander>{prices}</ColumnExpander>;
                },
                key: 'costPrice',
                responsive: ['md']
            },
            {
                title: 'Sales price',
                render: (data: ProductPartView) => {
                    const salesPrices = _.filter(data.product.prices, (price) => { return price.sales != null });
                    const prices = _.map(salesPrices, (value, key) => {
                        return <div key={value.unit ?? "Default"} className="extend">{value.unit ?? "Default"}: {formatCurrency(value.sales)}</div>
                    });
                    return <ColumnExpander>{prices}</ColumnExpander>;
                },
                key: 'salesPrice',
                responsive: ['md']
            },
        ];

        if (this.context.user.hasCapability(Capabilities.ProductsWrite)) {
            listColumns.push({
                title: 'Actions',
                render: (part: ProductPartView) =>
                    <div className="table-actions">
                        <Button
                            shape="circle"
                            icon={<EditOutlined />}
                            type="default"
                            onClick={() => this.onEdit(part)}
                        />
                        <Popconfirm title="Are you sure to remove part?" onConfirm={() => this.onRemove(part)} okText="Yes" cancelText="No">
                            <Button
                                shape="circle"
                                icon={<DeleteOutlined />}
                                type="default" danger
                                loading={this.state.loadingRemove}
                                onClick={(e) => { e.stopPropagation() }}
                            />
                        </Popconfirm>
                    </div>,
                key: 'actions'
            });
        }

        return (
            <React.Fragment>
                <Drawer
                    title="Add part"
                    onClose={this.toggleCreateDrawer}
                    open={this.state.showCreateDrawer}
                    destroyOnClose={true}
                    component={
                        <ProductPartForm
                            product={this.props.product}
                            onComplete={(data) => { this.props.onComplete(data); this.toggleCreateDrawer(); }}
                            onCancel={this.toggleCreateDrawer}
                        />
                    }
                />

                <Drawer
                    title="Update part"
                    onClose={this.onEditDrawerClose}
                    open={this.state.part != null}
                    destroyOnClose={true}
                    component={
                        <ProductPartForm
                            product={this.props.product}
                            part={this.state.part}
                            onCancel={this.onEditDrawerClose}
                            onComplete={(data) => { this.props.onComplete(data); this.onEditDrawerClose(); }}
                        />
                    }
                />

                <ListViewCard
                    title="Parts"
                    onAdd={this.context.user.hasAnyCapability([Capabilities.ProductsWrite]) ? this.toggleCreateDrawer : null}
                    columns={listColumns}
                    data={this.props.product.parts}
                    className={`product-part-list ${this.props.className ? this.props.className : ''}`}
                />
            </React.Fragment>
        );
    }
}

export default ProductPartList;