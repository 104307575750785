import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { FolderFilled, LoadingOutlined, TeamOutlined } from "@ant-design/icons";
import { Breakpoint, Spin, Tooltip } from "antd";
import { FileLinkView, FileVersionView, FileView, FolderLinkView, FolderView, PagedFileQuery, PreviewType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { blobToBase64, formatFileSize } from "./FileHelpers";
import { getExtensionIcon, getExtensionType } from "./ExtensionMappers";
import { ListView } from "../Shared/ListView";
import { FileModal } from "./FileModal";
import LastModifiedView from "../Shared/LastModifiedView";
import { PersonLink } from "../People";


interface DetailsViewProps {
    folders: (FolderLinkView | FolderView)[];
    files: (FileLinkView | FileView)[];
    onFolderClick: (folderId: string) => void;
    onLinkClick: (e: any, url: string) => void;
    showDeleted?: boolean;
    showPath?: boolean;
    itemActions?: (item) => JSX.Element;
    onScrollEnd?: () => any;
    filters?: Partial<PagedFileQuery>;
    loadingFiles?: boolean;
}

interface DetailsViewState {
    fileToDisplay: FileView;
    fileDataToDisplay: any;
    showFileModal: boolean;
    loadingFile: boolean;
}


export class DetailsView extends React.Component<DetailsViewProps, DetailsViewState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            fileDataToDisplay: null,
            fileToDisplay: null,
            showFileModal: false,
            loadingFile: false,
        }
    }

    mapFiles = (files: FileLinkView[] | FileView[]) => {
        const mapped = _.map(files, (file: FileLinkView | FileView) => {
            if (file.versions && file.versions.length > 0) {
                const versions = _.map(file.versions, version => {
                    //@ts-ignore
                    version.type = "version";
                    //@ts-ignore
                    version.key = version.id;
                    //@ts-ignore
                    version.parentFileId = file.id;
                    return version;
                }).reverse();

                //@ts-ignore
                file.children = versions;
            }

            //@ts-ignore
            file.type = "file";
            //@ts-ignore
            file.key = file.id;

            return file;
        });

        return mapped;
    }

    mapFolders = (folders: (FolderLinkView | FolderView)[]) => {
        const mappedFolders = _.map(folders, f => {
            //@ts-ignore
            f.type = "folder";
            //@ts-ignore
            f.key = f.id;

            return f;
        });

        return mappedFolders;
    }

    onSelect = async (selected) => {
        if (selected.type == "folder") {
            this.props.onFolderClick(selected.id);
        }
        else if (selected.type == "file" || selected.type == "version") {
            this.setState({ loadingFile: true });

            if (selected.extension != null)
                selected.extension = selected.extension.toLowerCase();

            if (selected["extension"] === ".pdf") {
                const fileData = await client.files.download(selected.id);

                const base64string: any = await blobToBase64(fileData);

                this.setState({
                    fileToDisplay: selected,
                    fileDataToDisplay: base64string,
                    showFileModal: true
                });
            }
            else if (selected.extension === ".xlsx" || selected.extension === ".eml" || selected.extension === ".xls" || selected.extension === ".docx" || selected.extension === ".doc" || selected.extension === ".pptx" || selected.extension === ".ppt") {
                const fileData = await client.files.getPreview(selected.parentFileId ?? selected.id, { type: PreviewType.PDF });
                const blobbed = fileData ? await fileData.blob() : null;
                const base64string: any = blobbed ? await blobToBase64(blobbed) : null;

                this.setState({
                    fileToDisplay: selected,
                    fileDataToDisplay: base64string,
                    showFileModal: true
                });
            }
            else {
                const fileData = await client.files.download(selected.parentFileId ?? selected.id, selected.type === "version" ? selected.id : null);

                this.setState({
                    fileToDisplay: selected,
                    fileDataToDisplay: fileData,
                    showFileModal: true
                });
            }

            this.setState({ loadingFile: false });
        }
        else {
            return;
        }
    }

    changeFileModalVisibility = (fileModalVisibility: boolean) => {
        if (fileModalVisibility == false) {
            this.setState({
                fileDataToDisplay: null,
                fileToDisplay: null
            })
        }

        this.setState({
            showFileModal: fileModalVisibility,
        })
    }

    render() {
        const { folders, files } = this.props;

        const filteredFolders = _.filter(folders, folder => this.props.showDeleted == null || folder.deleted == this.props.showDeleted);
        const filteredFiles = _.filter(files, file => this.props.showDeleted == null || file.deleted == this.props.showDeleted);

        const mappedFiles = this.mapFiles(filteredFiles);
        const mappedFolders = this.mapFolders(filteredFolders);

        const mappedFilesAndFolders = [...(mappedFolders), ...(mappedFiles)]

        let listColumns: any[] = [
            {
                title: 'Name',
                render: (data: any) => {
                    if (data.type == "folder") {
                        return <React.Fragment><FolderFilled /> <a type="link">{data.name}</a></React.Fragment>;
                    }
                    else if (data.type == "file" || data.type == "version") {
                        return <React.Fragment>{getExtensionIcon(data.extension)} <a type="link"> {data.name}</a></React.Fragment>;
                    }
                    else {
                        return "";
                    }
                },
                key: 'name',
                ellipsis: true
            },
            {
                title: 'Description',
                render: (data: any) => data.description ?? "",
                key: 'description',
                responsive: ["lg"],
            }];


        if (this.props.showPath) {
            listColumns = [...(listColumns), {
                title: 'Path',
                render: (data: any) => {
                    const path = data.path as FolderLinkView[];
                    if (!path) return null;

                    const items = _.map(path, (p, key) => {
                        return (
                            <li key={key}>
                                <a type="link" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.props.onFolderClick(p.id) }}>{p.name}</a>
                            </li>
                        );
                    });


                    if (data.attributes) {
                        //const entityCollection = data.attributes["entityCollection"];
                        const entityId = data.attributes["entityId"];
                        const entityName = data.attributes["entityName"];
                        const entityCollection = data.attributes["entityCollection"];

                        if (entityId && entityName) {
                            items.unshift(
                                <li key={entityId}>
                                    <a type="link" onClick={(e) => { this.props.onLinkClick(e, `/${entityCollection}/${entityId}`) }}>{entityName}</a>
                                </li>
                            );
                        }
                    }


                    return <ul className="file-path">{items}</ul>;
                },
                key: 'path',
                responsive: ["md"],
            }];
        }

        listColumns = [...(listColumns),
        //{
        //    title: 'Accessible in',
        //    render: (data: any) => {
        //        const applicationNames = _.map(data.applications ?? [], app => app.name);
        //        return applicationNames.join(', ');
        //    },
        //    key: 'applications',
        //    responsive: ["xxl"],
            //},
            {
                title: '',
                render: (data: any) => data.applications && data.applications.length > 1 ? <Tooltip title={_.map(data.applications, app => app.name).join(", ")}><TeamOutlined /></Tooltip > : null,
                key: 'sharedIcon',
                responsive: ["md"] as Breakpoint[],
                width: 40
            },
        {
            title: 'Extension',
            render: (data: any) => data.type == "folder" ? "Folder" : getExtensionType(data.extension),
            key: 'extension',
            responsive: ["xxl"],
            width: 100
        },
        {
            title: 'Size',
            render: (data: any) => data.size ? formatFileSize(data.size) : null,
            key: 'size',
            responsive: ["xxl"],
            width: 100
        },
        {
            title: 'Last updated',
            render: (data: FileView | FolderView | FileVersionView) => {
                //@ts-ignore
                if (data.type == "version") {
                    const version = data as FileVersionView;
                    return <div style={{ whiteSpace: 'normal' }}>{`${moment(version.created).format('DD.MM.YYYY HH:mm')}`}</div>;
                } else {
                    const fileOrFolder = data as FileView | FolderView;
                    return (
                         
                        <div className="lastmodified">
                            {fileOrFolder.lastModified
                                ? moment(fileOrFolder.lastModified).format("DD.MM.YYYY HH:mm") + " - "
                                : "Never"}                                      
                            {fileOrFolder.lastModifiedBy ? <PersonLink id={fileOrFolder.lastModifiedBy.id} type={fileOrFolder.lastModifiedBy.type} name={fileOrFolder.lastModifiedBy.name} />
                                : null}
                        </div>
                    );
                }
            },
            key: 'uploadedBy',
            responsive: ["xl"],
            ellipsis: true
        }
        ];

        if (this.props.itemActions) {
            listColumns.push(
                {
                    title: 'Actions',
                    render: this.props.itemActions,
                    key: 'actions',
                    width: 110
                }
            );
        }

        return (
            <div className={`folder-view-page-details ${this.props.filters ? "details-filtered" : ""}`}>
                <ListView
                    title={() => null}
                    bordered
                    collection={mappedFilesAndFolders}
                    columns={listColumns}
                    size={"small"}
                    loading={this.props.loadingFiles || this.state.loadingFile}
                    onQueryChange={() => { }}
                    onSelect={this.onSelect}
                    onScrollEnd={this.props.onScrollEnd ?? null}
                />

                <FileModal
                    filesInFolder={mappedFiles}
                    file={this.state.fileToDisplay}
                    fileData={this.state.fileDataToDisplay}
                    showFileModal={this.state.showFileModal}
                    changeFileModalVisibility={this.changeFileModalVisibility}
                    changeFile={this.onSelect}
                />
            </div>
        );

    }
}
export default DetailsView;