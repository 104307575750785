import client from "./client";
import { CreateNote, NoteView, NoteViewPagedNoteQueryCollectionViewModel, PagedNoteQuery, UpdateNote } from "./swagger/data-contracts";
import { HttpResponse } from "./swagger/http-client";

export interface EntityNoteClient {
    queryEntityNotes: (entityId: string, query?: PagedNoteQuery) => Promise<HttpResponse<NoteViewPagedNoteQueryCollectionViewModel, any>>;
    createEntityNote: (entityId: string, data: CreateNote) => Promise<HttpResponse<NoteView, any>>;
    updateEntityNote: (entityId: string, noteId: string, data: UpdateNote) => Promise<HttpResponse<NoteView, any>>;
    deleteEntityNote: (entityId: string, noteId: string) => Promise<HttpResponse<NoteView, any>>;
}


export const personNoteClient: EntityNoteClient = {
    queryEntityNotes: client.people.queryPersonNotes,
    createEntityNote: client.people.createPersonNote,
    updateEntityNote: client.people.updatePersonNote,
    deleteEntityNote: client.people.deletePersonNote
}

export const organizationNoteClient: EntityNoteClient = {
    queryEntityNotes: client.organizations.queryOrganizationNotes,
    createEntityNote: client.organizations.createOrganizationNote,
    updateEntityNote: client.organizations.updateOrganizationNote,
    deleteEntityNote: client.organizations.deleteOrganizationNote
}

export const productNoteClient: EntityNoteClient = {
    queryEntityNotes: client.products.queryProductNotes,
    createEntityNote: client.products.createProductNote,
    updateEntityNote: client.products.updateProductNote,
    deleteEntityNote: client.products.deleteProductNote
}

export const projectNoteClient: EntityNoteClient = {
    queryEntityNotes: client.projects.queryProjectNotes,
    createEntityNote: client.projects.createProjectNote,
    updateEntityNote: client.projects.updateProjectNote,
    deleteEntityNote: client.projects.deleteProjectNote
}

export const leadNoteClient: EntityNoteClient = {
    queryEntityNotes: client.leads.queryLeadNotes,
    createEntityNote: client.leads.createLeadNote,
    updateEntityNote: client.leads.updateLeadNote,
    deleteEntityNote: client.leads.deleteLeadNote
}

export const orderNoteClient: EntityNoteClient = {
    queryEntityNotes: client.orders.queryOrderNotes,
    createEntityNote: client.orders.createOrderNote,
    updateEntityNote: client.orders.updateOrderNote,
    deleteEntityNote: client.orders.deleteOrderNote
}

export const resourceNoteClient: EntityNoteClient = {
    queryEntityNotes: client.resources.queryResourceNotes,
    createEntityNote: client.resources.createResourceNote,
    updateEntityNote: client.resources.updateResourceNote,
    deleteEntityNote: client.resources.deleteResourceNote
}

export const subscriptionNoteClient: EntityNoteClient = {
    queryEntityNotes: client.subscriptions.querySubscriptionNotes,
    createEntityNote: client.subscriptions.createSubscriptionNote,
    updateEntityNote: client.subscriptions.updateSubscriptionNote,
    deleteEntityNote: client.subscriptions.deleteSubscriptionNote
}