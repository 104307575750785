import { Button, Form } from "antd";
import * as React from "react";
import client from "../../ApiClient/client";
import { OrganizationView, PagedOrganizationQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import { Drawer } from "../Shared/Drawer";
import EntitySelector from "../Shared/EntitySelector";
import { RequireCapability } from "../Shared/RequireCapability";
import { OrganizationCreateForm } from "./OrganizationCreateForm";


export class OrganizationSelector extends React.Component<GenericEntitySelectorProps<PagedOrganizationQuery>> {
    render = () => {
        const placeholder = this.props.placeholder ? this.props.placeholder : 'Select organization...';
        const source = this.props.source ? this.props.source : client.organizations.queryOrganizations;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.organizations.getOrganizationById;
        const optionDisplayNameParam = this.props.optionDisplayNameParam ? this.props.optionDisplayNameParam : 'name';

        return (
            <EntitySelector
                placeholder={placeholder}
                source={source}
                defaultValueSource={defaultValueSource}
                optionDisplayNameParam={optionDisplayNameParam}
                {...this.props}
            />
        );
    }
}


interface OrganizationSelectorInputProps extends GenericEntitySelectorProps<PagedOrganizationQuery> {
    required?: boolean;
    warningMessage?: string;
    param: string;
    className?: string;
    titleLink?: React.ReactNode;
}

export function OrganizationSelectorInput(props: OrganizationSelectorInputProps) {

    const context = React.useContext(AppContext);

    const [showCreateDrawer, setShowCreateDrawer] = React.useState(false);

    return (
        <>
            <Form.Item
                name={props.param}
                label={
                    <>
                        <span style={{ width: '100%' }}>{props.title}</span>
                        <RequireCapability capability={Capabilities.OrganizationsWrite}>
                            <Button type="link" onClick={() => setShowCreateDrawer((current) => !current)} className={`select-actor-title ${context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                                {context.isMobile ? "+ Add" : "+ Add  organization"}
                            </Button>
                        </RequireCapability>
                    </>
                }
            >
                <OrganizationSelector {...props} />

                <Drawer
                    title="Add new organization"
                    onClose={() => setShowCreateDrawer(false)}
                    open={showCreateDrawer}
                    destroyOnClose={true}
                    component={<OrganizationCreateForm onComplete={() => setShowCreateDrawer(false)} />}
                />
            </Form.Item>

        </>
    );
}

export default OrganizationSelector;