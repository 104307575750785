import client from "../../ApiClient/client";
import { PagedRoutineCategoryQuery } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";


interface RoutineCategoryFilterProps extends GenericEntitySelectorProps<PagedRoutineCategoryQuery> {
    types?: string[];
}

export function RoutineCategorySelector(props: RoutineCategoryFilterProps) {
    const placeholder = props.placeholder ? props.placeholder : 'Select routine category...';
    const source = props.source ? props.source : client.routineCategories.queryRoutineCategories;
    const defaultValueSource = props.defaultValueSource ? props.defaultValueSource : client.routineCategories.getRoutineCategoryById;
    const optionDisplayNameParam = props.optionDisplayNameParam ? props.optionDisplayNameParam : 'name';

    return (
        <EntitySelector
            placeholder={placeholder}
            source={source}
            defaultValueSource={defaultValueSource}
            optionDisplayNameParam={optionDisplayNameParam}
            {...props}
        />
    );
}

export default RoutineCategorySelector;