import { Link } from "react-router-dom";
import _ from 'lodash';
import { Popover, Spin, Card, Avatar, Button } from 'antd';
import { BankOutlined } from "@ant-design/icons";
import { ProjectLinkView, ProjectView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { useContext, useEffect, useState } from "react";
import { ActorLink } from "../Actors";


const { Meta } = Card;

interface ProjectLinkCardProps {
    id?: string;
    collection?: string;
}


export function ProjectLinkCard({ id }: ProjectLinkCardProps) {

    const [error, setError] = useState<string>();
    const [avatarUrl, setAvatarUrl] = useState<string>();
    const [project, setProject] = useState<ProjectView>();

    const context = useContext(AppContext);

    useEffect(() => {
        if (!project || id != project.id)
            loadProject();

        context.events.projects.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.projects.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }

    }, [id, project])


    function onItemUpdatedEvent(eventData: ProjectView) {
        if (project?.id === eventData.id) {
            if (project.changeVector == eventData.changeVector) return;

            setProject(eventData);
        }
    }

    async function loadProject() {
        const response = await client
            .projects.getProjectById(id)
            .catch(exception => setError(exception.error));

        if (response) {
            setProject(response.data.view);
            if (response.data.links?.avatar)
                setAvatarUrl(response.data.links.avatar.url);
        }
    }

    if (error) return <span>{error}</span>;
    if (!project) return <Spin size="large" />;

    const fallbackAvatar = <Avatar shape="square" size={100} icon={<BankOutlined />} className="avatar" />;
    const avatar = avatarUrl ? <Avatar src={avatarUrl} shape="square" alt="avatar" /> : fallbackAvatar;


    return (
        <Card className="actor-link-card link-card" bordered={false} size="small" title={project.name}>
            <Meta avatar={avatar} description={
                <dl>
                    {project.customer ? <div className="owners"><dt>Owner</dt>{<ActorLink {...project.customer} />}</div> : null}
                    {project.invoice ? <div className="invoice"><dt>Invoice</dt>{<ActorLink {...project.invoice} />}</div> : null}
                </dl>
            }
            />
        </Card>
    );
}

interface ProjectLinkProps extends ProjectLinkCardProps, ProjectLinkView {
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
}



export function ProjectLink(props: ProjectLinkProps) {

    const [project, setProject] = useState<ProjectLinkView>(props);
    //const [error, setError] = useState<string>();

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.projects.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.projects.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    function onItemUpdatedEvent(eventData: ProjectView) {
        if (project.id === eventData.id) {
            setProject(eventData);
        }
    }

    if (!project.name) return null;

    if (!context.user.hasCapability(Capabilities.ProjectsRoleBased))
        return <Popover content="You do not have access to read projects.">{project.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (project.deleted)
        className += " deleted";

    if (props.className)
        className += " " + props.className;

    const link = props.disableRedirect
        ? <Button type="link" className={className}>{project.name}</Button>
        : <Link onClick={(e) => e.stopPropagation()} to={`/projects/${project.id}`} className={className}>{project.name}</Link>;

    if (props.disablePopover || context.isMobile)
        return link;

    return (
        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
            <Popover placement="bottomLeft" content={<ProjectLinkCard id={project.id} />}>
                {link}
            </Popover>
        </span>
    );
}