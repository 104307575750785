/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ActorSortOption,
  ActorViewEntityViewModel,
  ActorViewPagedActorQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Actors<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Actor
   * @name GetActorById
   * @request GET:/api/actors/{id}
   * @secure
   */
  getActorById = (id: string, params: RequestParams = {}) =>
    this.http.request<ActorViewEntityViewModel, any>({
      path: `/api/actors/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Actor
   * @name QueryActors
   * @request GET:/api/actors
   * @secure
   */
  queryActors = (
    query?: {
      sortBy?: ActorSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      tagIds?: string[];
      isSupplier?: boolean;
      isCustomer?: boolean;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ActorViewPagedActorQueryCollectionViewModel, any>({
      path: `/api/actors`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
