import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Address, AddressType, CreateOrder, CreateOrderline, OrderDateType, OrderlineView, OrderType, OrderView, OrganizationView, PersonView, ProductPart, ProductPartView, ProductView, TaggableType } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { ActorIds } from "../../Definitions/_definitions";
import client from "../../ApiClient/client";
import { createGuid } from "../../Helpers/BasePageHelpers";
import OrderTypeSelector from "./OrderTypeSelector";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import BaseForm, { FormType } from "../Shared/Form";
import { CustomerSelector } from "../Actors";
import { PersonSelector } from "../People";
import { TextInput } from "../Shared/TextInput";
import { ProjectSelector } from "../Projects";
import OrderCategorySelector from "./OrderCategorySelector";
import { TagSelector } from "../Tags";
import { AddressInput } from "../Shared/AddressInput";
import OrderlineList from "./OrderlineList";
import OrderSalesTypeSelector from "./OrderSalesTypeSelector";


interface CreateOrderProps {
    history?: any;
    orderMold?: Partial<OrderView>;
    onComplete: (created: OrderView) => void;
    onCancel?: () => void;
}

interface CreateOrderState {
    loading: boolean;
    error: string;
    type: OrderType;
    isSalesOrder: boolean;
    orderlines: Partial<OrderlineView>[];
    parts: any;
    deliveryAddress: Partial<Address>;
    invoiceAddress: Partial<Address>;
}


interface CreateRequest extends CreateOrder {
    [OrderDateType.Quotation]: string;
    [OrderDateType.QuotationExpiry]: string;
    [OrderDateType.Sales]: string;
    [OrderDateType.SalesCorrection]: string;
    [OrderDateType.SalesProduction]: string;
    [OrderDateType.RequestedDelivery]: string;
}


export class OrderCreateForm extends React.Component<CreateOrderProps, CreateOrderState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            type: OrderType.Quotation,
            isSalesOrder: false,
            orderlines: [],
            parts: [],
            deliveryAddress: null,
            invoiceAddress: null
        }
    }

    componentDidMount = () => {
        if (this.props.orderMold) {
            this.setState({
                type: this.props.orderMold.type,
                deliveryAddress: this.props.orderMold.deliveryAddress,
                invoiceAddress: this.props.orderMold.invoiceAddress,
                orderlines: this.props.orderMold.orderlines
            });
        }
    }

    onSubmit = async (request: CreateRequest) => {
        if (!this.state.type) return;

        this.setState({ loading: true });

        const dates: any = {};

        if (request[OrderDateType.Quotation])
            dates[OrderDateType.Quotation] = request[OrderDateType.Quotation];

        if (request[OrderDateType.QuotationExpiry])
            dates[OrderDateType.QuotationExpiry] = request[OrderDateType.QuotationExpiry];

        if (request[OrderDateType.Sales])
            dates[OrderDateType.Sales] = request[OrderDateType.Sales];

        if (request[OrderDateType.SalesCorrection])
            dates[OrderDateType.SalesCorrection] = request[OrderDateType.SalesCorrection];

        if (request[OrderDateType.SalesProduction])
            dates[OrderDateType.SalesProduction] = request[OrderDateType.SalesProduction];

        if (request[OrderDateType.RequestedDelivery])
            dates[OrderDateType.RequestedDelivery] = request[OrderDateType.RequestedDelivery];

        const orderlines = this.state.orderlines ? _.map(this.state.orderlines, (orderline) => {
            const request: CreateOrderline = {
                productId: orderline.product.id,
                costPrice: orderline.costPrice,
                description: orderline.description,
                discount: orderline.discount,
                quantity: orderline.quantity,
                reference: orderline.reference,
                salesPrice: orderline.salesPrice,
                unit: orderline.unit,
                parts: _.map(orderline.parts, p => {
                    const partRequest: ProductPart = {
                        productId: p.product.id,
                        quantity: p.quantity
                    }
                    return partRequest;
                })
            };
            return request;
        }) : null;

        const command: CreateOrder = {
            reference: request.reference,
            type: this.state.type,
            label: request.label,
            customerId: request.customerId,
            invoiceReceiverId: request.invoiceReceiverId,
            externalContactId: request.externalContactId,
            internalContactId: request.internalContactId,
            dates: dates,
            deliveryAddress: request.deliveryAddress,
            invoiceAddress: request.invoiceAddress,
            projectId: this.props.orderMold && this.props.orderMold.project ? this.props.orderMold.project.id : request.projectId,
            categoryId: request.categoryId,
            tagIds: request.tagIds,
            orderlines: orderlines,
        };

        const response = await client.orders.createOrder(command).catch(exception => this.setState({ error: exception.error.title }));
        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    onTypeChange = (type) => {
        if (type == "Sales") {
            this.setState({ isSalesOrder: true, type: null });
        }
        else {
            if (type === OrderType.Production || type === OrderType.Quotation) {
                this.setState({ isSalesOrder: false });
            }

            this.setState({ type: type });
        }
    }

    mapOrderline = (current: Partial<OrderlineView>, update: Partial<CreateOrderline>, product: ProductView) => {

        const fieldsToUpdate: Partial<OrderlineView> = {
            product: product,
            costPrice: update.costPrice,
            description: update.description,
            discount: update.discount,
            quantity: update.quantity,
            reference: update.reference,
            salesPrice: update.salesPrice,
            unit: update.unit,
            parts: product.parts,
            attributes: {}
        };

        return Object.assign({}, current, fieldsToUpdate);
    }

    onOrderlineCreated = async (request: Partial<CreateOrderline>, product: ProductView) => {
        this.setState({ loading: true });
        try {
            const orderline = this.mapOrderline({ id: createGuid() + "-created" }, request, product);
            const orderlines = this.state.orderlines.slice();
            orderlines.push(orderline);
            this.setState({ orderlines });
        } catch (error: any) {
            this.setState({ error: error.message });
        }
        this.setState({ loading: false });
    }

    onOrderlineEdited = async (orderline: Partial<OrderlineView>, request: Partial<CreateOrderline>, product: ProductView) => {
        this.setState({ loading: true });

        try {
            const orderlines = this.state.orderlines.slice();
            const index = _.indexOf(orderlines, _.find(orderlines, o => { return o.id == orderline.id; }));

            if (index != -1) {
                const existing = orderlines[index];
                const updated = this.mapOrderline(existing, request, product);
                orderlines[index] = updated;
                this.setState({ orderlines });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onOrderlineRemoved = async (orderline: Partial<OrderlineView>) => {
        this.setState({ loading: true });

        try {
            const orderlines = this.state.orderlines.slice();
            const index = orderlines.indexOf(orderline);
            if (index != -1) {
                orderlines.splice(index, 1);
                this.setState({ orderlines });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onPartAdded = (part: ProductPartView, orderlineId: string) => {
        const orderlines = this.state.orderlines.slice();
        const orderline = _.find(orderlines, o => { return o.id == orderlineId; });
        if (orderline != null) {
            const exists = _.find(orderline.parts || [], p => { return p.product.id == part.product.id; });
            const orderlineIndex = orderlines.indexOf(orderline);
            if (!exists) {
                orderline.parts.push(part);
            }
            else {
                const partIndex = orderline.parts.indexOf(exists);
                orderline.parts[partIndex] = part;
            }

            orderlines[orderlineIndex] = orderline;
            this.setState({ orderlines });
        }
    }

    onPartRemoved = (part: ProductPartView, orderlineId) => {
        try {
            const orderlines = this.state.orderlines.slice();
            const orderline = _.find(orderlines, o => { return o.id == orderlineId; });
            const orderlineIndex = orderlines.indexOf(orderline);

            if (orderline != null) {
                var part = _.find(orderline.parts, p => { return p.product.id == part.product.id; });
                const partIndex = orderline.parts.indexOf(part);
                if (partIndex != -1) {
                    orderline.parts.splice(partIndex, 1);
                    orderlines[orderlineIndex] = orderline;
                    this.setState({ orderlines });
                }
            }
        } catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onCustomerChange = (customer: OrganizationView | PersonView) => {
        let newDeliveryAddress: Partial<Address> = null;

        if (customer) {
            const deliveryAddress = _.find(customer.addresses, a => {
                return a.types.includes(AddressType.Delivery);
            });

            if (deliveryAddress) {
                newDeliveryAddress = {
                    line1: deliveryAddress.line1,
                    line2: deliveryAddress.line2,
                    zipCode: deliveryAddress.zipCode,
                    area: deliveryAddress.area,
                    country: deliveryAddress.country,
                    note: deliveryAddress.note
                };
            }
        }

        this.setState({ deliveryAddress: newDeliveryAddress });
    }

    onInvoiceReceiverChange = (receiver: OrganizationView | PersonView) => {
        let newReceiverAddress: Partial<Address> = null;

        if (receiver) {
            const invoiceAddress = _.find(receiver.addresses, a => {
                return a.types.includes(AddressType.Invoice);
            });

            if (invoiceAddress) {
                newReceiverAddress = {
                    line1: invoiceAddress.line1,
                    line2: invoiceAddress.line2,
                    zipCode: invoiceAddress.zipCode,
                    area: invoiceAddress.area,
                    country: invoiceAddress.country,
                    note: invoiceAddress.note
                };
            }
        }

        this.setState({ invoiceAddress: newReceiverAddress });
    }

    onCancel = () => {
        if (this.props.history) {
            this.props.history.push("/orders");
        }
    }

    render = () => {
        const { orderMold } = this.props;
        const typeSelector = (
            <React.Fragment>
                <div className="order-type-input">Order type</div>
                <OrderTypeSelector
                    type="radio"
                    onChange={(e) => this.onTypeChange(e)}
                    value={this.state.type}
                />
                {this.state.isSalesOrder == false ? null
                    :
                    <SelectorInput
                        key="saleType"
                        param="salesType"
                        title="Type of sales order"

                        required
                        selector={
                            <OrderSalesTypeSelector
                                type="radio"
                                onChange={this.onTypeChange}
                            />
                        }
                    />
                }
            </React.Fragment>

        );

        if (!this.state.type)
            return typeSelector;

        let dates = null;

        if (this.state.type == OrderType.Quotation) {
            dates = (
                <React.Fragment>
                    <SelectorInput
                        key="quotation"
                        param={OrderDateType.Quotation}
                        title="Quotation date"
                        required
                        warningMessage="Please choose a date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="quotationExpiry"
                        param={OrderDateType.QuotationExpiry}
                        title="Expiry date"
                        required
                        warningMessage="Please choose a expiry date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="requestedDelivery"
                        param={OrderDateType.RequestedDelivery}
                        warningMessage="Please choose a date"
                        title="Delivery date"
                        selector={<DateSelector />}
                    />
                </React.Fragment>
            );
        }
        else if (this.state.type == OrderType.Normal) {
            dates = (
                <React.Fragment>
                    <SelectorInput
                        key="sales"
                        param={OrderDateType.Sales}
                        title="Sales date"
                        required
                        warningMessage="Please choose a sales date"
                        selector={<DateSelector />}
                    />

                    <SelectorInput
                        key="salesCorrection"
                        param={OrderDateType.SalesCorrection}
                        title="Correction date"
                        selector={<DateSelector />}
                    />
                    <SelectorInput
                        key="salesProduction"
                        param={OrderDateType.SalesProduction}
                        title="Production date"
                        selector={<DateSelector />}
                    />
                    <SelectorInput
                        key="requestedDelivery"
                        param={OrderDateType.RequestedDelivery}
                        warningMessage="Please choose a delivery date"
                        required
                        title="Delivery date"
                        selector={<DateSelector />}
                    />
                </React.Fragment>
            );
        }
        else if (this.state.type == OrderType.Direct) {
            dates = (<SelectorInput
                key="sales"
                param={OrderDateType.Sales}
                title="Sales date"
                selector={<DateSelector />}
            />);
        }
        else if (this.state.type == OrderType.Production) {
            dates = (<SelectorInput
                key="salesProduction"
                param={OrderDateType.SalesProduction}
                title="Production date"
                selector={<DateSelector />}
            />);
        }

        let initValues = {
            [OrderDateType.Quotation]: moment(),
            [OrderDateType.QuotationExpiry]: moment().add(30, "days"),
            [OrderDateType.Sales]: moment(),
            internalContactId: this.context.user.actorId != ActorIds.System ? this.context.user.actorId : null,
            deliveryAddress: this.state.deliveryAddress ?? null,
            invoiceAddress: this.state.invoiceAddress ?? null
        };
        if (orderMold) {
            initValues = Object.assign(initValues,
                {
                    [OrderDateType.RequestedDelivery]: moment(),
                    customerId: orderMold.customer ? orderMold.customer.id : undefined,
                    invoiceReceiverId: orderMold.invoiceReceiver ? orderMold.invoiceReceiver.id : undefined,
                    internalContactId: orderMold.internalContact ? orderMold.internalContact.id : undefined,
                    externalContactId: orderMold.externalContact ? orderMold.externalContact.id : undefined,
                    label: orderMold.label,
                    projectId: orderMold.project ? orderMold.project.id : undefined,
                    categoryId: orderMold.category ? orderMold.category.id : undefined,
                    tagIds: _.map(orderMold.tags, (t) => { return t.id }),
                });
        }
        return (
            <React.Fragment>
                {this.props.orderMold ? null : typeSelector}
                <BaseForm
                    type={FormType.Create}
                    onSubmit={this.onSubmit}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={initValues}
                    onCancel={this.props.onCancel ?? this.onCancel}
                >
                    <div className="order-input-group">
                        <SelectorInput
                            key="customer"
                            param="customerId"
                            title="Customer"
                            required
                            warningMessage="Please select a customer"
                            selector={<CustomerSelector onObjectChange={this.onCustomerChange} placeholder="Select customer" />}
                        />
                        <SelectorInput
                            key="invoiceReceiver"
                            param="invoiceReceiverId"
                            title="Invoice receiver"
                            warningMessage="Please select a invoice receiver"
                            selector={<CustomerSelector onObjectChange={this.onInvoiceReceiverChange} placeholder="Select invoice receiver" />}
                        />

                        <SelectorInput
                            key="internalContact"
                            param="internalContactId"
                            title="Internal contact"
                            warningMessage="Please select a internal contact"
                            selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select person" />}
                        />

                        <SelectorInput
                            key="externalContact"
                            param="externalContactId"
                            title="External contact"
                            warningMessage="Please select a external contact"
                            selector={<PersonSelector placeholder="Select person" />}
                        />

                        <TextInput
                            param="label"
                            title="Label"
                            placeholder="Label..."
                        />
                        {!orderMold || !orderMold.project || !orderMold.project.id ?
                            <SelectorInput
                                param="projectId"
                                title="Project"
                                selector={<ProjectSelector />}
                            /> : null}

                        <SelectorInput
                            param="categoryId"
                            title="Category"
                            selector={<OrderCategorySelector />}
                        />
                        <SelectorInput
                            param="tagIds"
                            title="Tags"
                            selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Order] }} />}
                        />
                    </div>

                    <div className="order-input-group address-input">
                        <AddressInput
                            title="Delivery address"
                            param="deliveryAddress"
                            addressType={AddressType.Delivery}
                        />

                        <AddressInput
                            title="Invoice address"
                            param="invoiceAddress"
                            addressType={AddressType.Invoice}
                        />
                    </div>

                    <div className="order-input-group">
                        {dates}
                    </div>

                    <div className="order-input-group orderline-input">
                        <OrderlineList
                            onCreateCompleted={this.onOrderlineCreated}
                            onEditCompleted={this.onOrderlineEdited}
                            onRemoveCompleted={this.onOrderlineRemoved}
                            orderlines={this.state.orderlines}
                            showCompletions={false}
                            onAddPart={this.onPartAdded}
                            onRemovePart={this.onPartRemoved}
                            minimized={orderMold != null}
                            showSum={orderMold != null}
                            orderType={this.state.type}
                        />
                    </div>
                </BaseForm>
            </React.Fragment>
        );
    }
}

export default OrderCreateForm;