import * as React from "react";
import _ from 'lodash';
import moment from 'moment';

import {
    ActivityType, ActivityView, ActorSortOption, ActorType, ActorView, CategoryLinkView,
    CustomerActivityTypeLinks, HourCategoryType,
    HourCategoryView,
    InternalActivityTypeLinks, OrganizationView, PersonSortOption, PersonView, ProductUnit, ProductView, ProjectActivityTypeLinks,
    ProjectLinkView, ProjectType, ProjectView, TaggableType, TaskView, TicketView,
    UpdateCustomerActivity,
    UpdateInternalActivity,
    UpdateProjectActivity,
} from "../../ApiClient/swagger/data-contracts";

import { Button, Checkbox, Alert, Form } from "antd";
import { Loading3QuartersOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { ActivityMold } from "../../Models/CalendarModels";
import AppContext from "../../Definitions/AppContext";
import ActivityHelper from "./ActivityHelper";
import { isColorDark, openNotification, setTimeToDate } from "../../Helpers/BasePageHelpers";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { SelectorInput } from "../Shared/SelectorInput";
import { HourCategorySelector } from "../HourCategories";
import { ProductSelector } from "../Products";
import { ProjectSelector } from "../Projects";
import { TaskSelector } from "../Tasks";
import { ActorWithCreateInput } from "../Actors";
import SelectorInformation from "../Shared/SelectorInformation";
import { PersonSelector } from "../People";
import { TagSelector } from "../Tags";
import { Capabilities } from "../../Definitions/_capabilties";
import BaseForm, { FormType } from "../Shared/Form";
import TimeSelector from "../Shared/TimeSelector";
import DateSelector from "../Shared/DateSelector";
import { TextAreaInput } from "../Shared/TextAreaInput";
import EnumSelector from "../Shared/EnumSelector";
import { Drawer } from "../Shared/Drawer";
import { TicketCreateForm, TicketSelector } from "../Tickets";

import { RiStopCircleLine } from 'react-icons/ri'


interface UpdateActivityProps {
    activity: Partial<ActivityMold>;
    actorId: string;
    onClose: (response?: ActivityView) => void;
    onComplete?: (response: ActivityView, keepForm?: boolean) => void;
    calendarDate?: string;
    showConfirm?: boolean;
    disableAutoCloseConfirmUnconfirm?: boolean;
}

interface UpdateActivityState {
    type: ActivityType;
    loading: boolean;
    error: any;
    participants: string[];
    hasParticipantsAtRender: boolean;
    hasTicketAtRender: boolean;
    hasTagsAtRender: boolean;
    displayMoreInfo: boolean;
    startTime: string;
    endTime: string;
    start: string;
    end: string;
    project: ProjectLinkView;
    task: TaskView;
    customerId: string;
    category: CategoryLinkView;
    productId: string;
    ticketId: string;
    tagIds: string[];
    trackHourSuggestions: any[];
    loadingTackHourSuggestions: boolean;
    showCreateTicketDrawer: boolean;
    suggestToOthers: boolean;
    beforeOrganizerWarning: boolean;
    formHasChanged: boolean;
    participantsHasChanged: boolean;
}

enum PeriodStartEnd {
    Start = "start",
    End = "end"
}

export interface UpdateActivity {
    description?: string | null;
    start: string;
    end?: string | null;
    tagIds?: string[] | null;
    participants?: string[] | null;
    suggestToOthers?: boolean;
    links: ProjectActivityTypeLinks | CustomerActivityTypeLinks | InternalActivityTypeLinks;
}

interface UpdateActivityExtended extends UpdateActivity {
    startTime: string;
    endTime: string;
    updateSuggestion: boolean;
    updateParticipants: boolean;
}

class ActivityUpdateForm extends React.Component<UpdateActivityProps, UpdateActivityState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            type: this.props.activity?.type ?? null,
            loading: false,
            error: null,
            participants: null,
            hasParticipantsAtRender: false,
            hasTicketAtRender: this.props.activity?.ticket != null,
            hasTagsAtRender: this.props.activity?.tags?.length > 0,
            displayMoreInfo: false,
            startTime: this.props.activity?.start ?? null,
            endTime: this.props.activity?.end ?? null,
            start: this.props.activity?.start ?? null,
            end: this.props.activity?.end ?? null,
            project: this.props.activity?.project ?? null,
            task: this.props.activity?.task as TaskView ?? null,
            customerId: this.props.activity?.relation?.id ?? null,
            category: this.props.activity?.category ?? null,
            productId: this.props.activity?.productSet ? this.props.activity?.product?.id ?? null : null,
            ticketId: this.props.activity?.ticket?.id ?? null,
            tagIds: this.props.activity?.tags ? _.map(this.props.activity.tags, tag => tag.id) : [],
            trackHourSuggestions: null,
            loadingTackHourSuggestions: false,
            showCreateTicketDrawer: false,
            suggestToOthers: false,
            beforeOrganizerWarning: false,
            formHasChanged: false,
            participantsHasChanged: false
        }
    }

    componentDidMount = async () => {
        this.getTrackHourSuggestions();

        const { activity } = this.props;
        if (activity.start && ((activity.end && moment(activity.start).format("HH:mm DD.MM") == moment(activity.end).format("HH:mm DD.MM")) || (!activity.end && moment(activity.start).format("HH:mm DD.MM") == moment().format("HH:mm DD.MM")))) {
            const newEnd = activity.end ? moment(activity.end).add(1, "minute") : moment().add(1, "minute");
            this.setState({ endTime: newEnd.format() });
        }
        const otherParticipants = [];
        const userIsNotOrganizer = activity.organizer != null && activity.organizer.contact != null && activity.organizer.contact.id != this.props.actorId;

        if (activity?.participants) {
            if (userIsNotOrganizer)
                otherParticipants.push(activity.organizer.contact.id);

            _.each(activity.participants, participant => {
                if (participant && participant.contact.id != this.props.actorId)
                    otherParticipants.push(participant.contact.id);
            });
        }

        this.setState({
            participants: otherParticipants,
            hasParticipantsAtRender: otherParticipants.length > 0
        });
    }

    componentDidUpdate = (prevProps: UpdateActivityProps) => {
        const prevActivity = prevProps.activity;
        const newActivity = this.props.activity;

        if (!_.isEqual(newActivity, prevActivity)) {

            let newEnd = null;
            if (newActivity.start && ((newActivity.end && moment(newActivity.start).format("HH:mm DD.MM") == moment(newActivity.end).format("HH:mm DD.MM")) || (!newActivity.end && moment(newActivity.start).format("HH:mm DD.MM") == moment().format("HH:mm DD.MM")))) {
                newEnd = newActivity.end ? moment(newActivity.end).add(1, "minute") : moment().add(1, "minute");
            }

            this.setState({
                loading: false,
                startTime: newActivity.start ?? null,
                endTime: newEnd ? newEnd.format() : newActivity.end ?? null,
                project: newActivity?.project ?? null,
                task: newActivity.task as TaskView ?? null,
                customerId: newActivity.relation?.id ?? null,
                category: newActivity?.category ?? null,
                //productId: this.props.activity?.product?.id ?? null,
                ticketId: newActivity?.ticket?.id ?? null,
                type: newActivity?.type ?? null,
            });
        }
    }

    onUpdateActivity = async (tempReq: UpdateActivityExtended) => {
        this.setState({ loading: true });

        const { activity } = this.props;
        const links = ActivityHelper.formatRequestTypeFromRequest(tempReq);

        if (links && activity.actor.id) {
            const activityUpdateRequest: UpdateActivity = {
                start: setTimeToDate(moment(tempReq.start), this.state.startTime, "YYYY-MM-DDTHH:mm:ss").format(),
                end: setTimeToDate(moment(tempReq.end), this.state.endTime ?? moment().format(), "YYYY-MM-DDTHH:mm:ss").format(),
                description: tempReq.description,
                links: links,
                tagIds: this.state.tagIds,
                participants: this.state.participants?.length > 0 ? this.state.participants : null,
                suggestToOthers: this.state.suggestToOthers
            }
            try {
                const activityResponse = links.type == ActivityType.Customer
                    ? await client.activities.updateCustomerActivity(activity.id, activityUpdateRequest as UpdateCustomerActivity)
                    : links.type == ActivityType.Project
                        ? await client.activities.updateProjectActivity(activity.id, activityUpdateRequest as UpdateProjectActivity)
                        : links.type == ActivityType.Internal
                            ? await client.activities.updateInternalActivity(activity.id, activityUpdateRequest as UpdateInternalActivity)
                            : null;

                if (activityResponse) {
                    this.props.onClose(activityResponse.data);
                }
                else
                    this.setState({ loading: false });
            }
            catch (e: any) {
                this.setState({ loading: false, error: e.message });
            }
        }
    }

    onDelete = async () => {
        const { activity } = this.props;

        if (activity.id && !activity.id.includes("temp")) {
            const response = await client.activities.deleteActivity(activity.id);
            this.props.onComplete(response.data);
        }
    }

    onRestore = async () => {
        const { activity } = this.props;

        if (activity.id && activity.id != "temp") {
            const act = await client.activities.restoreActivity(activity.id, addChangeVectorHeader(activity.changeVector));
            if (act)
                this.props.onClose(act.data);
        }
    }

    unconfirmActivity = async () => {
        const { activity } = this.props;
        if (!activity) return;

        this.setState({ loading: true });

        if (activity.locked) {
            openNotification("Tracked hour already invoiced", "", "info", null, "invoiced", true, "", null, (<div></div>));
            return;
        }

        try {
            const response = await client.activities.unconfirmActivity(activity.id, addChangeVectorHeader(activity.changeVector));
            if (response) this.props.onComplete(response.data, this.props.disableAutoCloseConfirmUnconfirm);

            this.setState({ loading: false });
        }
        catch (error: any) {
            this.setState({ error: error.message });
            this.setState({ loading: false });
        }
    }

    confirmActivity = async () => {
        const { activity } = this.props;
        if (!activity) return;

        if (activity.locked || activity.confirmed)
            return;

        this.setState({ loading: true });

        try {
            const act = await client.activities.confirmActivity(activity.id, addChangeVectorHeader(activity.changeVector));
            if (!this.props.disableAutoCloseConfirmUnconfirm) {
                this.props.onClose(act.data);
                this.setState({ loading: false });
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
            this.setState({ loading: false });
        }
    }

    resetStateValues = () => {
        this.setState({
            project: null,
            task: null,
            customerId: null,
            category: null,
            //productId: null,
            ticketId: null
        });
    }

    onTypeChange = async (value: ActivityType) => {
        this.resetStateValues();
        this.setState({
            type: value,
            formHasChanged: true
        });
    }

    onTypeToggle = async (value) => {
        this.resetStateValues();

        this.setState({
            type: this.state.type == value ? null : value,
            formHasChanged: true
        });
    }

    onTimeChange = (time, type: PeriodStartEnd) => {
        if (type == PeriodStartEnd.Start) {
            this.setState({
                startTime: time,
                formHasChanged: true
            });
        }
        else if (type == PeriodStartEnd.End) {
            this.setState({
                endTime: time,
                formHasChanged: true
            });
        }
    }

    onProjectChange = (project: ProjectView) => {
        this.setState({
            project: project,
            task: null,
            customerId: project?.customer?.id ?? null,
            formHasChanged: true
        });
    }

    onTaskChange = (task: TaskView) => {
        this.setState({
            task: task,
            project: task?.project ?? null,
            customerId: task?.projectOwner?.id ?? null,
            category: task?.category ?? null,
            formHasChanged: true
        });
    }

    onCustomerChange = (customer: ActorView) => {
        this.setState({
            task: null,
            project: null,
            ticketId: null,
            customerId: customer?.id ?? null,
            formHasChanged: true
        });
    }

    onCategoryChange = (category: HourCategoryView) => {
        this.setState({
            category,
            formHasChanged: true
        });
    }

    onTicketChange = (ticket: TicketView) => {
        this.setState({
            ticketId: ticket?.id,
            customerId: ticket?.customer?.id ?? this.state.customerId,
            formHasChanged: true
        });
    }

    onTagsChange = (tagIds: string[]) => {
        this.setState({
            formHasChanged: true,
            tagIds
        });
    }

    onProductChange = (product: ProductView) => {
        this.setState({
            formHasChanged: true,
            productId: product?.id
        });
    }

    getTrackHourSuggestions = async () => {
        this.setState({ loadingTackHourSuggestions: true });

        try {
            const suggestionsResponse = await client.activities.getSuggestedActivities({
                personId: this.props.actorId ? this.props.actorId : this.context.user.actorId,
                to: this.state.endTime ?? null
            });

            const keys = {};
            _.forEach(suggestionsResponse.data, a => {
                switch (a.type) {
                    case ActivityType.Project:
                        if (a.task) {
                            var key = "project" + a.task.id;
                            var item = keys[key];
                            if (item) item.count++
                            else {
                                let text = "";
                                if (a.task.parents == null || a.task.parents.length == 0) {
                                    const projectAndTaskName = `${a.project.name} / ${a.task.name}`;
                                    text = a.relation ? a.relation.name + " / " + projectAndTaskName : projectAndTaskName;
                                }
                                else {
                                    text = a.relation ? `${a.relation.name} / ${a.project.name}` : `${a.project.name}`;
                                    if (a.task.parents != null) {
                                        for (let i = 0; i < a.task.parents.length; i++) {
                                            text += ` / ${a.task.parents[i].name} `;
                                        }
                                    }
                                    text += ` / ${a.task.name}`;
                                }

                                keys[key] = {
                                    count: 1,
                                    text: text,
                                    type: a.type,
                                    project: a.project,
                                    task: a.task,
                                    date: a.start,
                                }
                            }
                        }
                        break;

                    case ActivityType.Customer: {
                        if (a.category && a.relation) {
                            var key = "customer" + a.category.id + a.relation.id;
                            var item = keys[key];
                            if (item) item.count++
                            else {
                                keys[key] = {
                                    count: 1,
                                    text: `${a.relation.name} / ${a.category.name}`,
                                    type: a.type,
                                    category: a.category,
                                    customer: a.relation,
                                    parents: null,
                                    date: a.start,
                                }
                            }
                        }
                    }
                        break;

                    case ActivityType.Internal: {
                        if (a.category) {
                            var key = "internal" + a.category.id;
                            var item = keys[key];
                            if (item) item.count++
                            else {
                                keys[key] = {
                                    count: 1,
                                    text: a.category.name,
                                    type: a.type,
                                    category: a.category,
                                    date: a.start,
                                }
                            }
                        }
                    }
                }
            })

            const suggestions = _.map(keys, s => { return s; });

            let top = [];
            if (suggestions && suggestions.length) {

                top = suggestions.sort((a, b) =>
                    //@ts-ignore
                    (a.count > b.count) ? -1 : 1).slice(0, 8).map(async (item: any) => {
                        if (item.project) {
                            const task = (await client.tasks.getTaskById(item.task.id))?.data.view;
                            item.category = task?.category;
                        }

                        return item;
                    });

                await Promise.all(top).then(function (results) {
                    top = results.sort((a, b) => (a.date > b.date) ? -1 : 1);
                })
            }

            this.setState({ trackHourSuggestions: top });
        }

        catch (error) {

        }

        this.setState({ loadingTackHourSuggestions: false });
    }

    onSuggestionClick = (suggestion) => {
        this.setState({
            project: suggestion.project,
            task: suggestion.task,
            category: suggestion.category,
            customerId: suggestion.customer?.id ?? null,
            type: suggestion.type as ActivityType
        });
    }

    toggleCreateTicketDrawer = () => {
        this.setState({ showCreateTicketDrawer: !this.state.showCreateTicketDrawer });
    }

    onCompleteAddTicket = async (data) => {
        try {
            this.setState({
                ticketId: data?.id ?? null,
                formHasChanged: true
            });
            this.toggleCreateTicketDrawer();
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }
    }

    onSuggestToOthersChange = (e) => {
        const checked = e.target.checked;
        const { activity } = this.props;

        this.setState({
            suggestToOthers: checked,
            beforeOrganizerWarning: checked && activity && activity.organizer && activity.organizer.contact && activity.organizer.contact.id != this.props.actorId
        });
    }

    onParticipantsChange = (participants: string[]) => {
        this.setState({
            participants,
            participantsHasChanged: true
        });
    }

    toggleMoreInfo = () => {
        this.setState({ displayMoreInfo: !this.state.displayMoreInfo });
    }

    render() {
        if (!this.props.activity) return "No template found";

        const { type, project, task, customerId, category, productId, ticketId, startTime, endTime, participants, tagIds } = this.state;
        const { activity } = this.props;

        //const { event } = this.props;

        let suggestions = [];
        if (type == null) {
            suggestions = _.map(this.state.trackHourSuggestions, s => {
                let color = "#F5F9F7";

                if (s.category && s.category.color) color = s.category.color;

                return (
                    <div className={isColorDark(color) ? `suggestion color-light` : `suggestion color-dark`} key={Math.random()} style={{ backgroundColor: color }} onClick={() => this.onSuggestionClick(s)}>
                        <div>{_.upperFirst(_.toLower(s.type))}</div>
                        <div>{s.text}</div>
                    </div>
                );
            });
        }

        const workCategory = this.state.category
            ? this.state.category as HourCategoryView
            : null;

        let taskParentPath = null;
        if (task && task.parents != null && task.parents.length > 0) {
            taskParentPath = "";
            _.each(this.state.task.parents, (p) => {
                taskParentPath += `${p.name} / `
            });
        }

        const disabledInputs = activity != null && (activity.confirmed || activity.locked);

        const absenceCapability = workCategory.type == HourCategoryType.Absence && (activity.actor.id !== this.context.user.actorId && !this.context.user.hasCapability(Capabilities.ActivitiesAdministrateAbsence));

        const categorySelector = (
            <SelectorInput
                param="categoryId"
                required
                title="Category"
                selector={
                    <HourCategorySelector
                        filters={{
                            types: type == ActivityType.Internal ? [HourCategoryType.Work, HourCategoryType.Absence] : [HourCategoryType.Work],
                            activityType: type
                        }}
                        disabled={disabledInputs}
                        className={disabledInputs ? "disabled-input" : ""}
                        onObjectChange={this.onCategoryChange}
                    />
                }
            />
        );
        
        const productSelector = (
            <SelectorInput
                param="productId"
                title="Product"
                optional
                shouldUpdate
                className="product-override"
                selector={
                    <ProductSelector
                        placeholder={
                            activity.productSet && !this.state.formHasChanged
                                ? activity.product?.name
                                : workCategory && workCategory.product
                                    ? workCategory.product.name
                                    : "Select product..."}
                        filters={{ units: [ProductUnit.Hour] }}
                        disabled={disabledInputs}
                        className={disabledInputs ? "disabled-input" : ""}
                        onObjectChange={this.onProductChange}
                    />
                }
            />
        );

        let formItems = null;

        if (this.state.type == ActivityType.Project) {
            formItems = (
                <React.Fragment>
                    <SelectorInput
                        param="projectId"
                        title="Project"
                        required
                        selector={
                            <ProjectSelector
                                onObjectChange={this.onProjectChange}
                                filters={{
                                    isActive: true,
                                    isEnded: false
                                }}
                                disabled={disabledInputs}
                                className={disabledInputs ? "disabled-input" : ""}
                            />
                        }
                    />
                    <SelectorInput
                        shouldUpdate
                        param="taskId"
                        title="Project task"
                        required
                        warningMessage="Choose a task"
                        selector={
                            <TaskSelector
                                title={taskParentPath}
                                filters={{
                                    showAll: true,
                                    closed: false,
                                    projectId: project?.id ?? null,
                                    projectActive: true,
                                    isContainer: false
                                }}
                                onObjectChange={this.onTaskChange}
                                disabled={disabledInputs}
                                className={disabledInputs ? "disabled-input" : ""}
                            />
                        }
                    />

                    {
                        this.state.project && this.state.project.type == ProjectType.Hourly
                            ? productSelector
                            : null
                    }

                </React.Fragment>
            );
        }
        else if (this.state.type == ActivityType.Customer) {
            formItems = (
                <React.Fragment>
                    <ActorWithCreateInput
                        selectorTitle="Customer"
                        param="customerId"
                        types={[ActorType.Organization, ActorType.Person]}
                        onActorChange={this.onCustomerChange}
                        required
                        disabled={disabledInputs}
                        className={disabledInputs ? "disabled-input" : ""}
                        selectorPlaceholder="Choose customer"
                        addText="Add customer"
                        renderOptionItem={(actor: ActorView) => {
                            if (actor.tags && actor.tags.length > 0) {
                                const tagNames = _.map(actor.tags, t => { return t.name });
                                return <SelectorInformation displayText={actor.name} hoverText={_.join(tagNames, ', ')} />
                            }
                            return actor.name;
                        }}
                        renderInfo={(entities: ActorView[]) => {

                            if (!entities || entities.length == 0)
                                return null;

                            const customerinfo
                                = entities[0].actorType === ActorType.Person ? (entities[0] as PersonView).customerInfo
                                : entities[0].actorType === ActorType.Organization ? (entities[0] as OrganizationView).customerInfo
                                : null;

                            if (!customerinfo)
                                return <Alert type="info" message={"This organization/person is not registered as a customer"} showIcon className="warning-text" />;

                            return null;

                        }}
                        filters={{
                            //sortBy: ActorSortOption.Name,
                            sortBy: ActorSortOption.Type,
                            //invoicableOrOrganization: true,
                            //isCustomer: true,
                        }}
                    />

                    {categorySelector}

                    {productSelector}
                </React.Fragment>
            );
        }
        else if (this.state.type == ActivityType.Internal) {
            formItems = categorySelector;
        }

        const suggestionInput = (
            <React.Fragment>
                <SelectorInput
                    param="participants"
                    title="Other participants"
                    className="participant-input"
                    selector={
                        <PersonSelector
                            multiple
                            filters={{
                                deleted: false,
                                sortBy: PersonSortOption.Name
                            }}
                            onChange={this.onParticipantsChange}
                            disableOptionIds={activity.organizer?.contact ? [activity.organizer.contact.id] : null}
                            exlcludeOptionIds={this.props.actorId ? [this.props.actorId] : null}
                            placeholder="Select participants"
                        />
                    }
                />

                <Form.Item name="suggestToOthers" valuePropName="checked" className="checkbox-input">
                    <Checkbox
                        onChange={this.onSuggestToOthersChange}
                        disabled={!this.state.formHasChanged && !this.state.participantsHasChanged}
                        checked={this.state.suggestToOthers}
                    >
                        Send updated time tracking suggestion to internal participants
                    </Checkbox>
                </Form.Item>
            </React.Fragment>
        );

        const ticketInput = (
            <SelectorInput
                param="ticketId"
                title={
                    <React.Fragment>
                        <span style={{ width: '100%' }}>Ticket (optional)</span>
                        <Button type="link" onClick={this.toggleCreateTicketDrawer} className={`select-actor-title ${this.context.isMobile ? "actor-title-mobile" : "actor-title-desktop"}`}>
                            + Create new ticket
                        </Button>
                    </React.Fragment>
                }
                optional={false}
                shouldUpdate
                selector={
                    <TicketSelector
                        onObjectChange={this.onTicketChange}
                        filters={{
                            closed: false,
                            customerId: customerId ?? null
                        }}
                        disabled={disabledInputs}
                        className={disabledInputs ? "disabled-input" : ""}
                    />
                }
            />
        );

        const tagInput = (
            <SelectorInput
                param="tagIds"
                title="Tags"
                selector={
                    <TagSelector
                        multiple
                        filters={{
                            taggableTypes: [TaggableType.Activity]
                        }}
                        onChange={this.onTagsChange}
                    />
                }
            />
        );

        const moreInfoContent = (
            <React.Fragment>
                {
                    this.state.type != null && this.context.user.hasCapability(Capabilities.TicketsReadRelated) && !this.state.hasTicketAtRender
                        ? ticketInput
                        : null
                }

                {
                    this.state.hasTagsAtRender
                        ? null
                        : tagInput
                }

                {
                    this.state.hasParticipantsAtRender
                        ? null
                        : suggestionInput
                }
            </React.Fragment>
        );

        const hasWrite = (this.context.user && this.context.user.hasCapability(Capabilities.ActivitiesWrite))
            || ((activity && activity.actor && activity.actor.id == this.context.user?.actorId && this.context.user?.hasCapability(Capabilities.ActivitiesWriteActor)));

        //TODO: Consider implementing confirm related as well - Intentially not included now
        const hasConfirm = (this.context.user && this.context.user.hasCapability(Capabilities.ActivitiesConfirm))
            || ((activity && activity.actor && activity.actor.id == this.context.user?.actorId && this.context.user?.hasCapability(Capabilities.ActivitiesConfirmActor)));

        const isLocked = activity && activity.locked;
        const isConfirmed = activity && activity.confirmed;
        const isLockedOrConfirmed = isLocked || isConfirmed;
        const isRunning = activity.end == null;


        const extraActions = [];

        if (!isLocked && hasConfirm) {
            extraActions.push(
                <React.Fragment key="confirm">
                    {
                        isConfirmed
                            ? <Button key="unconfirm" onClick={this.unconfirmActivity} loading={this.state.loading} size="large" className={isLocked ? "locked-btn" : ""}>Unconfirm</Button>
                            : !isConfirmed && this.props.showConfirm
                                ? <Button key="confirm" onClick={this.confirmActivity} loading={this.state.loading} size="large">Confirm</Button>
                                : null
                    }
                </React.Fragment>
            );
        }

        return (
            <div>
                <BaseForm
                    type={FormType.Edit}
                    loading={this.state.loading}
                    error={this.state.error}
                    onSubmit={hasWrite && !isLockedOrConfirmed ? this.onUpdateActivity : null}
                    onDelete={hasWrite && !isLockedOrConfirmed && this.props.activity.end ? this.onDelete : null}
                    isDeleted={activity?.deleted ?? false}
                    onRestore={hasWrite && !isLockedOrConfirmed && !isRunning ? this.onRestore : null}
                    onCancel={() => { this.props.onClose() }}
                    disableSubmit={!type}
                    className="activity-edit-or-create-form activity-from-event-form"
                    extraFormActions={extraActions}
                    customSubmit={isRunning ? <Button loading={this.state.loading} className="stop-button" type="primary" htmlType="submit" size="large"><RiStopCircleLine style={{ color: "white", width: "20", height: "20" }} />Stop</Button> : null}
                    initialValues={{
                        start: this.state.start,
                        end: isRunning ? moment().format() : this.state.end,
                        startTime: startTime ? moment(startTime).format('HH:mm') : null,
                        endTime: isRunning ? moment().format('HH:mm') : endTime ? moment(endTime).format('HH:mm') : null,
                        taskId: task?.id ?? null,
                        projectId: project?.id ?? null,
                        customerId: customerId,
                        categoryId: category?.id ?? null,
                        ticketId: ticketId,
                        productId: productId,
                        description: activity?.description,
                        participants: participants,
                        tagIds: tagIds,
                        suggestToOthers: false,
                        updateSuggestion: false,
                        updateParticipants: false
                    } as Partial<UpdateActivityExtended>}
                >

                    <div className="ant-form-item-label" key="start-header"><label>Start</label></div>
                    <div className="start-selectors" key="start-selectors">
                        <SelectorInput
                            param="startTime"
                            required
                            warningMessage="Start time is required"
                            selector={
                                <TimeSelector
                                    className={`startTime ${disabledInputs ? "disabled-input" : ""}`}
                                    onObjectChange={(obj) => this.onTimeChange(obj, PeriodStartEnd.Start)}
                                    disableClear
                                    disabled={disabledInputs}
                                />
                            }
                        />
                        <SelectorInput
                            param="start"
                            key="start"
                            required
                            warningMessage="Start date is required"
                            selector={
                                <DateSelector
                                    className={`${PeriodStartEnd.Start.toLowerCase()} ${disabledInputs ? "disabled-input" : ""}`}
                                    disableClear
                                    onChange={(updatedStartDate) => this.setState({ start: updatedStartDate })}
                                    disabled={disabledInputs}
                                />
                            }
                        />
                    </div>
                    <div className="ant-form-item-label" key="end-header"><label>End</label></div>
                    <div className="end-selectors" key="end-selectors">
                        <SelectorInput
                            param="endTime"
                            required
                            warningMessage="End time is required"
                            selector={
                                <TimeSelector
                                    className={`endTime ${disabledInputs ? "disabled-input" : ""}`}
                                    onObjectChange={(obj) => this.onTimeChange(obj, PeriodStartEnd.End)}
                                    disableClear
                                    disabled={disabledInputs}
                                />
                            }
                        />
                        <SelectorInput
                            param="end"
                            key="end"
                            selector={
                                <DateSelector
                                    className={`${PeriodStartEnd.End.toLowerCase()} ${disabledInputs ? "disabled-input" : ""}`}
                                    disableClear
                                    onChange={(updatedEndDate) => this.setState({ end: updatedEndDate })}
                                    disabled={disabledInputs}
                                />
                            }
                        />
                    </div>

                    <EnumSelector
                        enum={ActivityType}
                        title="Select type of work"
                        placeholder="Select activity type..."
                        type="radio"
                        onChange={this.onTypeChange}
                        onToggle={this.onTypeToggle}
                        value={type}
                        className={`activity-type ${disabledInputs ? "disabled-input" : ""}`}
                        wrappedFormTitle
                        disabled={disabledInputs}
                        excluded={[
                            !this.context.user.hasCapability(Capabilities.ProjectsRead) && ActivityType.Project,
                            !this.context.user.hasAnyCapability([Capabilities.OrganizationsRead, Capabilities.PeopleRead]) && ActivityType.Customer,
                            !this.context.user.hasAnyCapability([Capabilities.ActivitiesWrite, Capabilities.ActivitiesWriteActor]) && ActivityType.Internal
                        ]}
                    />

                    {type != null ?
                        <React.Fragment>
                            {formItems}

                            <div className="ant-form-item-label description-header"><label>Description {type == ActivityType.Internal || (this.state.project && this.state.project.type == "Internal") ? "(optional)" : null}</label></div>
                            <div className="ant-form-item-label">{workCategory?.informationText}</div>
                            <TextAreaInput
                                shouldUpdate
                                param="description"
                                required={type == ActivityType.Internal || (project && project.type == "Internal") ? false : true}
                                warningMessage="Please provide a description"
                                placeholder="Description"
                                rows={3}
                                disabled={disabledInputs || absenceCapability}
                                className={disabledInputs ? "disabled-input" : ""}
                            />
                        </React.Fragment>
                        : null}

                    {
                        type != null && this.state.hasTicketAtRender
                            ? ticketInput
                            : null
                    }

                    {
                        type != null && this.state.hasTagsAtRender
                            ? tagInput
                            : null
                    }

                    {
                        type != null && this.state.hasParticipantsAtRender
                            ? suggestionInput
                            : null
                    }

                    {
                        type != null
                            ?
                            <React.Fragment>
                                <div className={`ant-form-item-label add-more-info-header ${this.state.hasParticipantsAtRender ? "participants-at-render" : ""} ${this.state.displayMoreInfo ? "header-open" : "header-closed"}`} onClick={this.toggleMoreInfo}><div className="more-info-label"><label>Additional info (optional) <div className="more-info-icon-container">{this.state.displayMoreInfo ? <UpOutlined /> : <DownOutlined />}</div></label></div></div>
                                {this.state.displayMoreInfo ? <div className="more-info-content">{moreInfoContent}</div> : null}
                            </React.Fragment>
                            : null
                    }

                    {
                        type != null && this.state.beforeOrganizerWarning
                            ? <Alert className="before-organizer-warning" showIcon type="warning" message="You are not the organizer" description="By proceeding you will send out a time tracking suggestion to all internal participants" />
                            : null
                    }

                    {
                        type == null
                            ? (
                                <React.Fragment>
                                    {<div className="ant-drawer-title suggestions-title">or select a recent hour (optional)</div>}
                                    {this.state.loadingTackHourSuggestions ? <Loading3QuartersOutlined spin /> : suggestions?.length == 0 ? "No recent hours found" : <div className="suggestions-container">{suggestions}</div>}
                                </React.Fragment>
                            )
                            : null
                    }
                </BaseForm>


                <Drawer
                    title="Create ticket"
                    onClose={this.toggleCreateTicketDrawer}
                    open={this.state.showCreateTicketDrawer}
                    component={
                        <TicketCreateForm
                            onComplete={this.onCompleteAddTicket}
                            filters={{
                                customerId: customerId,
                                requesterId: this.props.actorId
                            }}
                        />
                    }
                />
            </div>
        );
    }
}

export default ActivityUpdateForm;