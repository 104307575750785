/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdateSensorAlertResolution,
  MeasurementLevel,
  SensorAlertAggregationResultSensorAlertAggregateQueryAggregationResults,
  SensorAlertAggregationTypes,
  SensorAlertSortOption,
  SensorAlertView,
  SensorAlertViewEntityViewModel,
  SensorAlertViewSensorAlertQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Sensoralerts<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags SensorAlert
   * @name GetSensorAlertById
   * @request GET:/api/sensoralerts/{id}
   * @secure
   */
  getSensorAlertById = (id: string, params: RequestParams = {}) =>
    this.http.request<SensorAlertViewEntityViewModel, any>({
      path: `/api/sensoralerts/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SensorAlert
   * @name QuerySensorAlerts
   * @request GET:/api/sensoralerts
   * @secure
   */
  querySensorAlerts = (
    query?: {
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      sortBy?: SensorAlertSortOption;
      sortDirection?: SortDirection;
      level?: MeasurementLevel;
      resourceId?: string;
      sensorId?: string;
      sensorPartId?: string;
      measuringPointIds?: string[];
      /** @format date-time */
      fromDateTime?: string;
      /** @format date-time */
      toDateTime?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SensorAlertViewSensorAlertQueryCollectionViewModel, any>({
      path: `/api/sensoralerts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SensorAlert
   * @name ResolveAlert
   * @request POST:/api/sensoralerts/{id}/resolve
   * @secure
   */
  resolveAlert = (id: string, data: AddOrUpdateSensorAlertResolution, params: RequestParams = {}) =>
    this.http.request<SensorAlertView, any>({
      path: `/api/sensoralerts/${id}/resolve`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SensorAlert
   * @name RemoveResolve
   * @request DELETE:/api/sensoralerts/{id}/resolve/{resolutionId}
   * @secure
   */
  removeResolve = (id: string, resolutionId: string, params: RequestParams = {}) =>
    this.http.request<SensorAlertView, any>({
      path: `/api/sensoralerts/${id}/resolve/${resolutionId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SensorAlert
   * @name GetSensorAlertAggregates
   * @request GET:/aggs/sensoralerts
   * @secure
   */
  getSensorAlertAggregates = (
    query?: {
      aggs?: SensorAlertAggregationTypes[];
      level?: MeasurementLevel;
      resourceId?: string;
      sensorId?: string;
      sensorPartId?: string;
      measuringPointIds?: string[];
      /** @format date-time */
      fromDateTime?: string;
      /** @format date-time */
      toDateTime?: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<SensorAlertAggregationResultSensorAlertAggregateQueryAggregationResults, any>({
      path: `/aggs/sensoralerts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
