import * as React from "react";
import _ from 'lodash';
import moment from "moment";
import { Card, Progress, Button } from "antd";
import Title from "antd/lib/typography/Title";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { ActivityAggregateQuery, ActivityAggregationResult, ActivitySubType, PagedActivityQuery } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { addParamsToQuery } from "../../Helpers/QueryHashHandlers";
import { formatDuration } from "../../Helpers/Formatters";

interface EmployeeStatsProps {
    actorId: string;
    customActivityAggregates?: any;
}

interface EmployeeStatsState {
    activityQuery: Partial<PagedActivityQuery>;
    aggregates: ActivityAggregationResult;
    loading: boolean;
    active: "thisYear" | "thisMonth" | "thisWeek";
}

const dateFormat = "YYYY-MM-DDTHH:mm:ss";

export class EmployeeStatsCard extends React.Component<EmployeeStatsProps, EmployeeStatsState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activityQuery: {},
            aggregates: null,
            loading: false,
            active: "thisYear"
        };
    }

    componentDidMount = () => {
        if (!this.props.customActivityAggregates) {
            const activityQuery: Partial<ActivityAggregateQuery> = {
                actorId: this.props.actorId,
                startAfter: moment().startOf('year').format(dateFormat),
                endBefore: moment().endOf('year').format(dateFormat),
                deleted: false
            };

            this.loadActivities(activityQuery);
        }
    }

    loadActivities = async (query) => {
        if (this.props.customActivityAggregates) return;

        this.setState({ loading: true });

        const response = await client.activities.getActivityAggregates(query).catch(ex => { });
        if (response)
            this.setState({ aggregates: response.data.results, activityQuery: response.data.query });

        this.setState({ loading: false });
    }

    onQuickSearch = async (active, group) => {
        if (this.props.customActivityAggregates) return;

        let query = Object.assign({}, this.state.activityQuery);
        query = await addParamsToQuery(query, group, false);

        this.setState({ active });
        this.loadActivities(query);
    }

    render = () => {
        const { aggregates, active } = this.state;

        const aggs = this.props.customActivityAggregates ?? aggregates;

        const totalDuration = aggs != null ? aggs.totalDuration?.sum ?? 0 : 0;

        const hourlyInvoiceAmount = aggs != null ? _.find(aggs.bySubType || [], x => x.label == ActivitySubType.HourlyInvoiceable)?.sum || 0 : 0;
        const hourlyInvoicePercentage = (hourlyInvoiceAmount / (totalDuration || 1) * 100);

        const fixedInvoiceAmount = aggs != null ? _.find(aggs.bySubType || [], x => x.label == ActivitySubType.Fixed)?.sum || 0 : 0;
        const fixedInvoicePercentage = (fixedInvoiceAmount / (totalDuration || 1) * 100);

        const absenceAmount = aggs != null ? _.find(aggs.bySubType || [], x => x.label == ActivitySubType.InternalAbsence)?.sum || 0 : 0;
        const absencePercentage = (absenceAmount / (totalDuration || 1) * 100);

        const internalAmount = aggs != null ? _.find(aggs.bySubType || [], x => x.label == ActivitySubType.InternalWork)?.sum || 0 : 0;
        const hourlyNotInvoiceable = aggs != null ? _.find(aggs.bySubType || [], x => x.label == ActivitySubType.HourlyNotInvoicable)?.sum || 0 : 0;

        const totalNotInvoiceable = internalAmount + hourlyNotInvoiceable;
        const interalWorkPercentage = (totalNotInvoiceable / (totalDuration || 1) * 100);

        const formatPercentage = (percentage?: number) => {
            return <div style={{ color: 'rgba(0, 0, 0, 0.85)' }}>{(percentage || 0).toFixed(2) + "%"}</div>;
        };

        const progressProps = {
            //strokeColor: "#2679A1",
            trailColor: "#e5e5e5",
            strokeWidth: 10
        };


        const loadingBar = <Loading3QuartersOutlined spin className="loading-icon" />;

        return (
            <Card
                className="employee-stats-card"
                title={
                    <div className="list-title">
                        <Title level={4} className="title">Total hours: {formatDuration(totalDuration)}</Title>
                        {this.props.customActivityAggregates ? null :
                            <div className="search">
                                <Button type="link"
                                    onClick={() => this.onQuickSearch("thisYear", {
                                        startAfter: moment().startOf('year').format(dateFormat),
                                        endBefore: moment().endOf('year').format(dateFormat),
                                    })}
                                    className={active == "thisYear" ? "active" : ""}
                                >
                                    This year
                                </Button>

                                <Button type="link"
                                    onClick={() => this.onQuickSearch("thisMonth", {
                                        startAfter: moment().startOf('month').format(dateFormat),
                                        endBefore: moment().endOf('month').format(dateFormat)
                                    })}
                                    className={active == "thisMonth" ? "active" : ""}
                                >
                                    This month
                                </Button>

                                <Button type="link"
                                    onClick={() => this.onQuickSearch("thisWeek", {
                                        startAfter: moment().startOf('week').format(dateFormat),
                                        endBefore: moment().endOf('week').format(dateFormat),
                                    })}
                                    className={active == "thisWeek" ? "active" : ""}
                                >
                                    This week
                                </Button>
                            </div>}
                    </div>
                }
            >
                <div className="employee-stats-container">
                    <div className="stats-container">
                        <div className="stat">
                            <div className="stat-label">Invoiceable - per hour</div>
                            {this.state.loading ? loadingBar : <Progress {...progressProps} type="circle" percent={hourlyInvoicePercentage} format={formatPercentage} />}
                            <div className="result">{formatDuration(hourlyInvoiceAmount, true)}</div>
                        </div>

                        <div className="stat">
                            <div className="stat-label">Invoiceable - fixed price</div>
                            {this.state.loading ? loadingBar : <Progress {...progressProps} type="circle" percent={fixedInvoicePercentage} format={formatPercentage} />}
                            <div className="result">{formatDuration(fixedInvoiceAmount, true)}</div>
                        </div>

                        <div className="stat">
                            <div className="stat-label">Not invoiceable</div>
                            {this.state.loading ? loadingBar : <Progress {...progressProps} type="circle" percent={interalWorkPercentage} format={formatPercentage} />}
                            <div className="result">{formatDuration(totalNotInvoiceable, true)}</div>
                        </div>

                        <div className="stat">
                            <div className="stat-label">Absence</div>
                            {this.state.loading ? loadingBar : <Progress {...progressProps} type="circle" percent={absencePercentage} format={formatPercentage} />}
                            <div className="result">{formatDuration(absenceAmount, true)}</div>
                        </div>
                    </div>
                </div>
            </Card>
        );
    }
}