import * as React from "react";
import _ from 'lodash';
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import { PagedContactPointQuery } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import AutoCompleteInput from "../Shared/AutoCompleteInput";

interface ContactFilterProps extends GenericEntitySelectorProps<PagedContactPointQuery> {
    types?: string[]
}

export class ContactPointAutoComplete extends React.Component<ContactFilterProps> {
    render = () => {
        const source = this.props.source ? this.props.source : client.contactPoints.queryContactPoints;
        const defaultValueSource = this.props.defaultValueSource ? this.props.defaultValueSource : client.contactPoints.getContactPointById;

        return (
            <AutoCompleteInput
                source={source}
                defaultValueSource={defaultValueSource}
                {...this.props}
                className={`contactpoint-autocomplete ${this.props.className}`}
            />
        );
    }
}

export default ContactPointAutoComplete;