import * as React from "react";
import { Form, Input } from "antd";

interface DurationInputProps {
    required?: boolean;
    warningMessage?: string;
    param: string;
    title?: string;
    duration?: number;
}


export class DurationInput extends React.Component<DurationInputProps> {

    calculateTotalDuration = (hours, minutes) => {
        let totalHours = 0;
        let totalMinutes = 0;

        if (hours && hours > 0)
            totalHours = hours * 3600000;

        if (minutes && minutes > 0)
            totalMinutes = minutes * 60000;

        const total = totalHours + totalMinutes;
        return total;
    }

    render = () => {
        const { param, required, warningMessage } = this.props;

        const optional = !this.props.required ? " (optional)" : "";
        const title = this.props.title ? <div>{this.props.title} {optional}</div> : null;

        return (
            <Form.Item
                label={title}
                name={param}
                getValueFromEvent={this.calculateTotalDuration}
                rules={
                    [{
                        required: required,
                        message: warningMessage ? warningMessage : 'Please type a value for this field'
                    }]
                }
            >
                <Duration
                    onChange={this.calculateTotalDuration}
                    duration={this.props.duration}
                />
            </Form.Item>
        );
    }
}

export class Duration extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            hours: 0,
            minutes: 0
        }
    }

    componentDidMount = () => {
        this.setDefaultValues(this.props.duration)
    }

    setDefaultValues = (duration) => {
        if (!duration || duration <= 0) return;

        const ms = duration % 1000;
        duration = (duration - ms) / 1000;
        const seconds = duration % 60;
        duration = (duration - seconds) / 60;
        const minutes = duration % 60;
        const hours = (duration - minutes) / 60;

        this.setState({
            hours,
            minutes
        });
    }

    onHoursChange = (e) => {
        const hours = e.target.value;
        this.setState({ hours });
        this.props.onChange(hours, this.state.minutes);
    }

    onMinutesChange = (e) => {
        let minutes = e.target.value;
        if (minutes > 59)
            minutes = 59;

        this.setState({ minutes });
        this.props.onChange(this.state.hours, minutes);
    }

    render = () => {
        return (
            <div className="duration-input">
                <Input
                    type="number"
                    size="middle"
                    style={{
                        width: "100%"
                    }}
                    min={0}
                    addonAfter="h"
                    value={this.state.hours}
                    onChange={this.onHoursChange}
                    placeholder="Hours..."
                    className="hours"
                />

                <span className="duration-divider"> : </span>

                <Input
                    type="number"
                    size="middle"
                    style={{
                        width: "100%"
                    }}
                    addonAfter="m"
                    value={this.state.minutes}
                    min={0}
                    max={59}
                    onChange={this.onMinutesChange}
                    placeholder="Minutes..."
                    className="minutes"
                />
            </div>
        );
    }
}