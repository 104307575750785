/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddOrUpdateNoteForCall,
  CallAggregationResultCallAggregateQueryAggregationResults,
  CallAggregationTypes,
  CallDirection,
  CallMissedReason,
  CallSortOption,
  CallStatus,
  CallTrackingType,
  CallView,
  CallViewEntityViewModel,
  CallViewPagedCallQueryCollectionViewModel,
  CallsRelatedNotesView,
  CreateOrUpdateCall,
  SortDirection,
  UpdateCallTracking,
  UpdateCallTransfer,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Calls<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Call
   * @name GetCallById
   * @request GET:/api/calls/{id}
   * @secure
   */
  getCallById = (id: string, params: RequestParams = {}) =>
    this.http.request<CallViewEntityViewModel, any>({
      path: `/api/calls/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name UpdateCall
   * @request PUT:/api/calls/{id}
   * @secure
   */
  updateCall = (id: string, data: CreateOrUpdateCall, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name DeleteCall
   * @request DELETE:/api/calls/{id}
   * @secure
   */
  deleteCall = (id: string, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name CreateCall
   * @request POST:/api/calls
   * @secure
   */
  createCall = (data: CreateOrUpdateCall, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name QueryCalls
   * @request GET:/api/calls
   * @secure
   */
  queryCalls = (
    query?: {
      sortBy?: CallSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      contactId?: string;
      customerId?: string;
      trackingTypes?: CallTrackingType[];
      contactPointId?: string;
      internal?: boolean;
      /** @format date */
      fromDate?: string;
      /** @format date */
      toDate?: string;
      statuses?: CallStatus[];
      missedReasons?: CallMissedReason[];
      directions?: CallDirection[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CallViewPagedCallQueryCollectionViewModel, any>({
      path: `/api/calls`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name RestoreCall
   * @request POST:/api/calls/{id}/restore
   * @secure
   */
  restoreCall = (id: string, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name CallsAttributesCreate
   * @request POST:/api/calls/{id}/attributes
   * @secure
   */
  callsAttributesCreate = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name GetCallRelated
   * @request GET:/api/calls/{id}/related
   * @secure
   */
  getCallRelated = (
    id: string,
    query?: {
      sortBy?: CallSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      contactId?: string;
      customerId?: string;
      trackingTypes?: CallTrackingType[];
      contactPointId?: string;
      internal?: boolean;
      /** @format date */
      fromDate?: string;
      /** @format date */
      toDate?: string;
      statuses?: CallStatus[];
      missedReasons?: CallMissedReason[];
      directions?: CallDirection[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CallViewPagedCallQueryCollectionViewModel, any>({
      path: `/api/calls/${id}/related`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name GetCallRelatedNotes
   * @request GET:/api/calls/{id}/notes
   * @secure
   */
  getCallRelatedNotes = (
    id: string,
    query?: {
      sortBy?: CallSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      contactId?: string;
      customerId?: string;
      trackingTypes?: CallTrackingType[];
      contactPointId?: string;
      internal?: boolean;
      /** @format date */
      fromDate?: string;
      /** @format date */
      toDate?: string;
      statuses?: CallStatus[];
      missedReasons?: CallMissedReason[];
      directions?: CallDirection[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CallsRelatedNotesView[], any>({
      path: `/api/calls/${id}/notes`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name AddOrUpdateCallNote
   * @request POST:/api/calls/{id}/note
   * @secure
   */
  addOrUpdateCallNote = (id: string, data: AddOrUpdateNoteForCall, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}/note`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name DeleteCallNote
   * @request DELETE:/api/calls/{id}/note
   * @secure
   */
  deleteCallNote = (id: string, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}/note`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name AddOrUpdateCallTransfer
   * @request POST:/api/calls/{id}/transfer
   * @secure
   */
  addOrUpdateCallTransfer = (id: string, data: UpdateCallTransfer, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}/transfer`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name DeleteCallTransfer
   * @request DELETE:/api/calls/{id}/transfer
   * @secure
   */
  deleteCallTransfer = (id: string, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}/transfer`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name AddOrUpdateCallTracking
   * @request POST:/api/calls/{id}/tracking
   * @secure
   */
  addOrUpdateCallTracking = (id: string, data: UpdateCallTracking, params: RequestParams = {}) =>
    this.http.request<CallView, any>({
      path: `/api/calls/${id}/tracking`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name IgnoreCalls
   * @request POST:/api/calls/ignore
   * @secure
   */
  ignoreCalls = (data: string[], params: RequestParams = {}) =>
    this.http.request<CallView[], any>({
      path: `/api/calls/ignore`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name IgnoreAllCalls
   * @request POST:/api/calls/ignoreall
   * @secure
   */
  ignoreAllCalls = (params: RequestParams = {}) =>
    this.http.request<CallView[], any>({
      path: `/api/calls/ignoreall`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Call
   * @name GetCallAggregates
   * @request GET:/aggs/calls
   * @secure
   */
  getCallAggregates = (
    query?: {
      aggs?: CallAggregationTypes[];
      contactId?: string;
      customerId?: string;
      trackingTypes?: CallTrackingType[];
      contactPointId?: string;
      internal?: boolean;
      /** @format date */
      fromDate?: string;
      /** @format date */
      toDate?: string;
      statuses?: CallStatus[];
      missedReasons?: CallMissedReason[];
      directions?: CallDirection[];
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CallAggregationResultCallAggregateQueryAggregationResults, any>({
      path: `/aggs/calls`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
