import Layout from "antd/es/layout/layout";
import { useContext } from "react";
import AppContext from "../Definitions/AppContext";
import AppHeader from "./Header/AppHeader";
import { IAppMenuGroup, IAppMenuItem } from "../Models/MenuAndRouteConfig";

interface AppLayoutProps {
    children: React.ReactNode;
    menus?: (IAppMenuGroup | IAppMenuItem)[];
}

function AppLayout({ children, menus }: AppLayoutProps) {

    const context = useContext(AppContext);

    return (
        <Layout className={`app-layout ${context.isMobile ? "mobile-layout" : "desktop-layout"}`}>
            <AppHeader menus={menus } />
            {children}
        </Layout>
    );
}

export default AppLayout;