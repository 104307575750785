import * as React from "react";
import _ from 'lodash';
import { Alert, Form, Checkbox } from "antd";
import { ActorType, ActorView, SubscriptionType, SubscriptionView, TaggableType, UpdateSubscription } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { findCurrentTypeOrStatus } from "./SubscriptionHelper";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import { TagIds } from "../../Definitions/_definitions";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import { ActorSelector, CustomerSelector } from "../Actors";
import { TextInput } from "../Shared/TextInput";
import { TagSelector } from "../Tags";
import DateSelector from "../Shared/DateSelector";


interface EditSubscriptionProps {
    onComplete: (updated: SubscriptionView) => void;
    onCancel?: () => void;
    subscription: SubscriptionView;
}

interface EditSubscriptionState {
    loading: boolean;
    error: string;
    changeType: boolean;
}

export class SubscriptionEditForm extends React.Component<EditSubscriptionProps, EditSubscriptionState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            changeType: false
        }
    }

    onSubmit = async (req: UpdateSubscription) => {
        this.setState({ loading: true });

        const type = findCurrentTypeOrStatus(this.props.subscription.typeHistory);
        const diffType = type.value == SubscriptionType.Yearly
            ? null
            : type.value == SubscriptionType.Monthly
                ? SubscriptionType.Quarterly
                : type.value == SubscriptionType.Quarterly
                    ? SubscriptionType.Monthly
                    : null

        if (this.state.changeType && diffType) {
            req.changeType = {
                application: req.changeType.application,
                type: diffType
            };
        }
        else
            req.changeType = null;

        const response = await client.subscriptions
            .updateSubscription(this.props.subscription.id, req, addChangeVectorHeader(this.props.subscription.changeVector))
            .catch(exception => this.setState({ error: exception.error.title }));

        if (response) this.props.onComplete(response.data);

        this.setState({ loading: false });
    }

    toggleChangeType = () => {
        this.setState({ changeType: !this.state.changeType });
    }

    render = () => {
        if (!this.props.subscription) return null;

        const { customer, invoiceReceiver, tags, description } = this.props.subscription;


        const type = findCurrentTypeOrStatus(this.props.subscription.typeHistory);

        const diffType = type == null || type.value == SubscriptionType.Yearly
            ? null
            : type.value == SubscriptionType.Monthly
                ? SubscriptionType.Quarterly
                : type.value == SubscriptionType.Quarterly
                    ? SubscriptionType.Monthly
                    : null

        return (
            <div>
                <BaseForm
                    type={FormType.Edit}
                    onSubmit={this.onSubmit}
                    onCancel={this.props.onCancel}
                    loading={this.state.loading}
                    error={this.state.error}
                    initialValues={{
                        description: description,
                        customerId: customer != null ? customer.id : null,
                        invoiceReceiverId: invoiceReceiver != null ? invoiceReceiver.id : null,
                        tagIds: _.map(tags ?? [], tag => {
                            if (tag.category?.deleted || tag?.deleted) return;
                            return tag.id; 
                        })
                    }}
                    className="subscription-edit-form"
                >
                    <SelectorInput
                        param="customerId"
                        title="Customer"
                        key="customer"
                        required
                        selector={<CustomerSelector placeholder="Select customer" />}
                    />
                    <SelectorInput
                        param="invoiceReceiverId"
                        title="Invoice Receiver"
                        key="invoiceReceiver"
                        selector={<CustomerSelector placeholder="Select invoice receiver" />}
                    />

                    <TextInput
                        param="description"
                        placeholder="Description"
                        title="Description"
                    />

                    <SelectorInput
                        param="tagIds"
                        title="Tags"
                        key="tags"
                        selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Subscription] }} />}
                    />

                    <div className={`ant-row ant-form-item`}>
                        <div className="ant-col ant-form-item-label sub-label">
                            <label>
                                <div className="selector-label-container">
                                    <span className="text-title">Type</span>
                                </div>
                            </label>
                        </div>
                        <div className="sub-value">{type?.value ?? ""}</div>

                        {diffType == null ? null :
                            <div>
                                <Checkbox style={{ marginBottom: 5 }} onChange={this.toggleChangeType}>{`Change type to ${diffType.toLowerCase()}`}</Checkbox>

                                <Form.Item
                                    name={["changeType", "application"]}
                                    rules={
                                        [{
                                            required: this.state.changeType ? true : false,
                                            validator: async (rule, value) => {
                                                if (!this.state.changeType) return Promise.resolve();
                                                if (value) return Promise.resolve();
                                                else return Promise.reject();
                                            },
                                            message: 'This field is required'
                                        }]}
                                >
                                    <DateSelector placeholder="Date" disabled={!this.state.changeType} />
                                </Form.Item>
                            </div>
                        }
                    </div>
                </BaseForm>
            </div>
        );
    }
}

export default SubscriptionEditForm;