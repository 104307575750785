import * as React from "react";
import _ from 'lodash';
import { Popover, Spin, Card, Tag } from 'antd';
import { CategoryLinkView, TagCategoryView, TagLinkView, TagView } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
const { Meta } = Card;



interface TagLinkProps extends TagLinkView {
    disablePopover?: boolean;
    onClick?: (event, tag: TagLinkView) => void;
}

interface TagLinkState {
    id: string;
    name: string;
    color: string;
    //description: string;
    category: CategoryLinkView;
    overrideColor: boolean;
    deleted: boolean;
    systempreset: boolean;
    changeVector?: string;
    collection?: string;
}

interface TagLinkCardProps {
    tagId: string
}

interface TagLinkCardState {
    tag?: TagView,
    error?: Error
}

class TagLinkCard extends React.Component<TagLinkCardProps, TagLinkCardState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    _isMounted = false;

    constructor(props: any) {
        super(props);
        this.state = {
            tag: null,
            error: null
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;
        this.loadTag();

        this.context.events.tags.onMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }


    componentWillUnmount = () => {
        this._isMounted = false;

        this.context.events.tags.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });
    }


    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (prevProps.tagId !== this.props.tagId) {
            this.loadTag()
        }
    }

    onItemUpdatedEvent = (eventData: TagView) => {
        if (this.state.tag && this.state.tag.id === eventData.id) {
            if (this.state.tag.changeVector == eventData.changeVector) {
                return;
            }
            this.setState({
                tag: eventData
            });
        }
    }

    loadTag = async () => {
        try {
            const response = await client.tags.getTagById(this.props.tagId);

            if (response && this._isMounted)
                this.setState({ tag: response.data.view });

        } catch (error: any) {
            this.setState({ error });
        }
    }

    render = () => {
        if (this.state.error) {
            return this.state.error.message;
        }

        if (!this.state.tag)
            return <Spin />;

        return (
            <Card className="tag-link-card" bordered={false} size="small" title={this.state.tag.name}>
                <Meta description={
                    <React.Fragment>
                        {this.state.tag.description}
                    </React.Fragment>
                }
                />
            </Card>
        );
    }
}


export class TagLink extends React.Component<TagLinkProps, TagLinkState>{
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);
        this.state = {
            id: this.props.id,
            name: this.props.name,
            //description: this.props.description,
            category: this.props.category,
            color: this.props.color,
            deleted: this.props.deleted,
            systempreset: null,//this.props.systemPreset,
            overrideColor: this.props.overrideColor,
        };
    }

    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (prevProps.id !== this.props.id) {
            this.setState({
                id: this.props.id,
                name: this.props.name,
                category: this.props.category,
                deleted: this.props.deleted,
                changeVector: null
            });
        }
    }

    componentDidMount = () => {
        this.context.events.tags.onMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });

        //client.tagCategories.onMany({
        //    'updated': this.onCategoryUpdatedEvent,
        //    'deleted': this.onCategoryUpdatedEvent,
        //    'restored': this.onCategoryUpdatedEvent,
        //});
    }

    componentWillUnmount = () => {
        this.context.events.tags.offMany({
            'updated': this.onItemUpdatedEvent,
            'deleted': this.onItemUpdatedEvent,
            'restored': this.onItemUpdatedEvent,
        });

        //client.tagCategories.offMany({
        //    'updated': this.onCategoryUpdatedEvent,
        //    'deleted': this.onCategoryUpdatedEvent,
        //    'restored': this.onCategoryUpdatedEvent,
        //});
    }

    onItemUpdatedEvent = (eventData: TagView) => {
        if (this.state.id && this.state.id === eventData.id) {
            if (this.state.changeVector == eventData.changeVector) {
                return;
            }
            this.setState({
                name: eventData.name,
                category: eventData.category,
                deleted: eventData.deleted,
                color: eventData.color,
                changeVector: eventData.changeVector
            });
        }
    }

    onCategoryUpdatedEvent = (eventData: TagCategoryView) => {
        if (this.state.category && this.state.category.id === eventData.id) {
            //if (this.state.category.state.changeVector == eventData.state.changeVector) {
            //    return;
            //}
            this.setState({
                category: eventData,
            });
        }
    }

    render = () => {

        if (this.state?.deleted || this.state.category?.deleted)
            return null;

        if (!this.context.user.hasCapability(Capabilities.TagsRead))
            return <Popover content="You do not have access to read tags.">{this.state.name}</Popover>;

        const tag: TagLinkView = {
            id: this.state.id,
            name: this.state.name,
            overrideColor: this.state.overrideColor,
            category: this.state.category,
            color: this.state.color,
            deleted: this.state.deleted
        }

        const link = <Tag color={this.state.color ?? this.state.category?.color} onClick={(e) => this.props.onClick(e, tag)}>{this.state.name}</Tag>;

        /* TODO: Improve the design of the popver before enabling it */
        if (true || this.props.disablePopover)
            return link;

        return (
            <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
                <Popover placement="bottomLeft" content={<TagLinkCard tagId={this.state.id} />}>
                    {link}
                </Popover>
            </span>
        );
    }
}