import * as React from "react";
import _ from 'lodash';
import { Tabs, Typography } from "antd";
import { TaskView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import { updateCollectionFromEvent } from "../../Helpers/BasePageHelpers";
import { TaskGanttChartCard } from "../Shared/TaskGantChart";
import { TaskListCard } from "./TaskListCard";


const { TabPane } = Tabs;
const { Title } = Typography;

interface TaskTaskAndTimelineTabProps {
    task: TaskView;
    onNavigate: (taskId: string) => void;
}

interface TaskTaskAndTimelineTabState {
    activeTab: "subTasks" | "timeline";
    actions: any;
    subTasks: TaskView[];
}

export class SubTasksAndTimelineTab extends React.Component<TaskTaskAndTimelineTabProps, TaskTaskAndTimelineTabState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            activeTab: "subTasks",
            actions: null,
            subTasks: []
        };
    }

    componentDidMount = () => {
        this.loadTasks();

        this.context.events.tasks.onMany({
            created: this.onItemCreatedEvent,
            updated: this.onItemUpdatedEvent,
            deleted: this.onItemUpdatedEvent,
            restored: this.onItemUpdatedEvent
        });
    }

    componentWillUnmount = () => {
        this.context.events.tasks.offMany({
            created: this.onItemCreatedEvent,
            updated: this.onItemUpdatedEvent,
            deleted: this.onItemUpdatedEvent,
            restored: this.onItemUpdatedEvent
        });
    }

    onItemCreatedEvent = async (subTask: TaskView) => {
        const parentExists = subTask && subTask.parents ? _.find(subTask.parents || [], p => { return p.id == this.props.task.id; }) : null;

        if (parentExists) {
            const subTasks = this.state.subTasks.slice();
            const alreadyExists = _.find(subTasks, t => { return t.id == subTask.id; });

            if (!alreadyExists) {
                subTasks.push(subTask);
                this.setState({ subTasks });
            }
        }
    }

    onItemUpdatedEvent = async (subTask: TaskView) => {
        const newCollection = await updateCollectionFromEvent(this.state.subTasks.slice(), subTask);
        this.setState({ subTasks: newCollection as TaskView[] });
    }

    loadTasks = async () => {
        try {
            const response = await client.tasks.queryTasks({
                parentId: this.props.task.id,
                deleted: false,
                limit: 9999
            });
            this.setState({ subTasks: response.data.items });
        }
        catch (e) {

        }
    }

    onTabChange = (activeTab) =>
        this.setState({ activeTab });

    getActions = (actions: React.ReactNode) =>
        this.setState({ actions });
    

    render = () => {
        let operationsSlot = null;

        if (this.state.actions != null && this.state.activeTab == "subTasks") {
            operationsSlot = {
                right: <div className="operations">{this.state.actions}</div>
            };
        }

        return (
            <Tabs defaultActiveKey={this.state.activeTab} onChange={this.onTabChange} className="task-subTask-timeline-tab" tabBarExtraContent={operationsSlot}>
                <TabPane key="subTasks" tab={<Title level={4} className="title">Sub tasks</Title>}>
                    <TaskListCard
                        filters={{ parentId: this.props.task.id, deleted: false }}
                        showImport
                        getActions={this.getActions}
                        projectId={this.props.task.project?.id}
                        onNavigate={this.props.onNavigate}
                    />
                </TabPane>

                <TabPane key="timeline" tab={<Title level={4} className="title">Sub task timeline</Title>}>
                    <TaskGanttChartCard
                        data={this.state.subTasks}
                        nameProperty="name"
                        startProperty="plannedStart"
                        endProperty="plannedEnd"
                        estimateProperty="aggregatedEstimate"
                        durationProperty="aggregatedDuration"
                    />
                </TabPane>
            </Tabs>
        );
    }
}