import * as React from "react";
import _ from 'lodash';
import { ActivityType, CreateTask, ProjectType, ProjectView, TaggableType, TaskType, TaskView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import client from "../../ApiClient/client";
import BaseForm, { FormType } from "../Shared/Form";
import { ActorIds } from "../../Definitions/_definitions";
import { TextInput } from "../Shared/TextInput";
import { TextAreaInput } from "../Shared/TextAreaInput";
import { SelectorInput } from "../Shared/SelectorInput";
import { PersonSelector } from "../People";
import { WorkCategorySelector } from "../HourCategories";
import { PercentageInput } from "../Shared/PercentageInput";
import DateSelector from "../Shared/DateSelector";
import { DurationInput } from "../Shared/DurationInput";
import { TagSelector } from "../Tags";
import TaskTypeSelector from "./TaskTypeSelector";


interface CreateTaskProps {
    project: ProjectView;
    parentId?: string;
    onCancel?: () => void;
    onComplete: (response: TaskView) => void;
}

interface CreateTaskState {
    loading: boolean;
    error: string;
    discountWarning: string;
    type: TaskType;
    disbledTypeChoice: boolean;
}

export class TaskCreateForm extends React.Component<CreateTaskProps, CreateTaskState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            discountWarning: null,
            type: TaskType.Normal,
            disbledTypeChoice: false
        }
    }

    componentDidMount = async () => {
        if (this.props.parentId) {
            const response = await client.tasks.getTaskById(this.props.parentId);
            if (response?.data.view.type == TaskType.Normal)
                this.setState({ disbledTypeChoice: true });
        }
    }

    onSubmit = async (request: CreateTask) => {
        this.setState({ loading: true });

        if (!this.props.project)
            this.setState({ error: "Invalid project" });

        request.projectId = this.props.project.id;
        request.type = this.state.type;

        if (this.props.parentId)
            request.parentId = this.props.parentId;

        try {
            const task = await client.tasks.createTask(request);
            if (task) {
                this.props.onComplete(task.data);
            }
        }
        catch (error: any) {
            this.setState({ error: error.message });
        }

        this.setState({ loading: false });
    }

    onDiscountChange = (event) => {
        if (event.target.value == null || event.target.value == "" || event.target.value == undefined) {
            this.setState({ discountWarning: null });
        }
        else if (this.props.project.discount && this.props.project.discount != event.target.value) {
            this.setState({ discountWarning: "This will override the existing discount" });
        }
    }

    onTypeChange = (type) => {
        this.setState({ type });
    }

    render() {
        const { project } = this.props;
        const { type, disbledTypeChoice } = this.state;

        const isContainer = type == TaskType.Container;

        return (
            <BaseForm
                type={FormType.Create}
                onSubmit={this.onSubmit}
                onCancel={this.props.onCancel}
                loading={this.state.loading}
                error={this.state.error}
                className="task-form"
                initialValues={this.context.user.actorId == ActorIds.System ? null :
                    {
                        ownerId: this.context.user.actorId
                    }
                }
            >
                <TextInput
                    param="name"
                    required
                    warningMessage="Please type a name"
                    placeholder="Name"
                    title="Task name"
                />

                <TextAreaInput
                    param="description"
                    placeholder="Description"
                    title="Description"
                />

                <TaskTypeSelector
                    title="Type of task"
                    type="radio"
                    onChange={this.onTypeChange}
                    value={type}
                    className={`task-type ${disbledTypeChoice ? "disabled-input" : ""}`}
                    wrappedFormTitle
                    disabled={disbledTypeChoice}
                />

                {isContainer ? null : <SelectorInput
                    param="ownerId"
                    warningMessage="Please choose a owner"
                    required
                    title="Task owner"
                    selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select a person" />}
                />}

                {isContainer ? null : <SelectorInput
                    param="assigneeId"
                    title="Assignee"
                    selector={<PersonSelector filters={{ isEmployee: true }} placeholder="Select a person" />}
                />}

                {isContainer ? null : <SelectorInput
                    param="categoryId"
                    required
                    warningMessage="Please choose a category"
                    title="Category"
                    selector={<WorkCategorySelector filters={{ activityTypes: [ActivityType.Project] }} />}
                />}

                {project && project.type == ProjectType.Hourly && !isContainer ?
                    <React.Fragment>
                        <PercentageInput
                            param="discount"
                            title="Discount"
                            placeholder={project.discount ? project.discount.toString() : "0"}
                            onChange={this.onDiscountChange}
                            formClassName={this.state.discountWarning != null ? "override-margin" : ""}
                        />
                        {<div className="warning">{this.state.discountWarning ?? null}</div>}
                    </React.Fragment>
                    : null}
                {/* TODO: Validate start < end */}

                <div className="start-end-combo-input-container">
                    <div className="label-container">
                        <label className="start-end-label">Planned task period (optional)</label>
                    </div>
                    <div className="start-end-group">
                        <SelectorInput
                            param="plannedStart"
                            selector={<DateSelector placeholder="Start" />}
                        />

                        <SelectorInput
                            param="plannedEnd"
                            selector={<DateSelector placeholder="End" endOfDay />}
                        />
                    </div>
                </div>

                <DurationInput
                    param="estimate"
                    title="Estimate"
                />

                <SelectorInput
                    param="tagIds"
                    title="Tags"
                    selector={<TagSelector multiple filters={{ taggableTypes: [TaggableType.Task] }} />}
                />
            </BaseForm>
        );
    }
}

export default TaskCreateForm;