import { Link } from "react-router-dom";
import _ from 'lodash';
import { Popover, Spin, Card, Avatar, Button } from 'antd';
import { BankOutlined, SnippetsOutlined } from "@ant-design/icons";
import { TaskLinkView, TaskView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { useContext, useEffect, useState } from "react";
import { ActorLink } from "../Actors";
import { CoreCollection } from "../../Definitions/collections";
import { WorkCategoryLink } from "../HourCategories/WorkCategoryLink";
import { AbsenceCategoryLink } from "../HourCategories/AbsenceCategoryLink";


const { Meta } = Card;

interface TaskLinkCardProps {
    id?: string;
    collection?: string;
}


export function TaskLinkCard({ id }: TaskLinkCardProps) {

    const [error, setError] = useState<string>();
    const [avatarUrl, setAvatarUrl] = useState<string>();
    const [task, setTask] = useState<TaskView>();

    const context = useContext(AppContext);

    useEffect(() => {
        if (!task || id != task.id)
            loadTask();

        context.events.tasks.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.tasks.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }

    }, [id, task])


    function onItemUpdatedEvent(eventData: TaskView) {
        if (task?.id === eventData.id) {
            if (task.changeVector == eventData.changeVector) return;

            setTask(eventData);
        }
    }

    async function loadTask() {
        const response = await client
            .tasks.getTaskById(id)
            .catch(exception => setError(exception.error));

        if (response) {
            setTask(response.data.view);
            if (response.data.links?.avatar)
                setAvatarUrl(response.data.links.avatar.url);
        }
    }

    if (error) return <span>{error}</span>;
    if (!task) return <Spin size="large" />;

    const avatar = <Avatar shape="square" size={100} icon={<SnippetsOutlined />} className="avatar" />

    const details = (
        <dl>
            {task.project ? <div className="project"><dt>Project</dt><dd><Link to={`/projects/${task.project.id}`} {...task.project.deleted ? { className: "deleted" } : null}>{task.project.name}</Link></dd></div> : null}
            {task.owner ? <div className="owner"><dt>Owner</dt><dd><Link to={`/actors/${task.owner.id}`} {...task.owner.deleted ? { className: "deleted" } : null}>{task.owner.name}</Link></dd></div> : null}
            {task.assignee ? <div className="assignee"><dt>Assignee</dt><dd><Link to={`/actors/${task.assignee.id}`} {...task.assignee.deleted ? { className: "deleted" } : null}>{task.assignee.name}</Link></dd></div> : null}
            {task.category ? <div className="category"><dt>Category</dt><dd>{<WorkCategoryLink {...task.category} />}</dd></div> : null}
        </dl>
    );

    return (
        <Card className="task-link-card" bordered={false} size="small" title={task.name}>
            <Meta avatar={avatar} description={details} />
        </Card>
    );
}

interface TaskLinkProps extends TaskLinkCardProps, TaskLinkView {
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
    onNavigate?: (taskId: string) => void;
}



export function TaskLink(props: TaskLinkProps) {

    const [task, setTask] = useState<TaskLinkView>(props);
    //const [error, setError] = useState<string>();

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.tasks.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.tasks.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    function onItemUpdatedEvent(eventData: TaskView) {
        if (task.id === eventData.id) 
            setTask(eventData);
    }

    if (!task.name) return null;

    if (!context.user.hasCapability(Capabilities.ProjectsRoleBased))
        return <Popover content="You do not have access to read tasks.">{task.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (task.deleted)
        className += " deleted";

    if (props.className)
        className += " " + props.className;

    const link = <span
        //type="link"
        style={{ cursor: 'pointer' }}
        //style={props.disablePopover ? { color: 'unset' } : null}
        //to={`/tasks/${task.id}`}
        className={className}
        onClick={() => props.onNavigate(props.id)}
    >
        {task.name}
    </span>;

    if (props.disablePopover)
        return link;

    return (
        <span className="link-wrapper" onClick={(e) => e.stopPropagation()}>
            <Popover placement="bottomLeft" content={<TaskLinkCard id={task.id} />}>
                {link}
            </Popover>
        </span>
    );
}