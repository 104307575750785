import * as React from "react";
import _ from 'lodash';
import { Form } from "antd";
import AppContext from "../../Definitions/AppContext";
import { CallView, ContactPointType, CreateOrUpdateCall } from "../../ApiClient/swagger/data-contracts";
import client from "../../ApiClient/client";
import { addChangeVectorHeader } from "../../Helpers/RequestHelpers";
import BaseForm, { FormType } from "../Shared/Form";
import { SelectorInput } from "../Shared/SelectorInput";
import DateSelector from "../Shared/DateSelector";
import CallMissedReasonSelector from "./CallMissedReasonSelector";
import { ContactPointAutoComplete } from "../ContactPoints";



interface EditCallProps {
    call: CallView;
    onComplete: (call: CallView) => void;
    onCancel?: () => void;
}

interface EditCallState {
    loading: boolean;
    error: string;
    start?: string;
    end?: string;

}

class CallEditForm extends React.Component<EditCallProps, EditCallState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            start: this.props.call?.start,
            end: this.props.call?.end
        }
    }

    onSubmit = async (request: CreateOrUpdateCall) => {
        this.setState({ loading: true });

        if (!request.from || !request.to) return;

        request.reference = this.props.call.reference;

        const response = await client.calls
            .updateCall(this.props.call.id, request, addChangeVectorHeader(this.props.call.changeVector))
            .catch(exception => this.setState({ error: exception.error.title }));

        this.setState({ loading: false });

        if (response) this.props.onComplete(response.data);
    }

    onDelete = async () => {
        if (this.props.call != null) {
            const response = await client.calls
                .deleteCall(this.props.call.id, addChangeVectorHeader(this.props.call.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    onRestore = async () => {
        if (this.props.call != null) {
            const response = await client.calls
                .restoreCall(this.props.call.id, addChangeVectorHeader(this.props.call.changeVector))
                .catch(exception => this.setState({ error: exception.error.title }));

            if (response) this.props.onComplete(response.data);
        }
    }

    render = () => {
        if (!this.props.call) return <div>No call</div>;

        return (
            <BaseForm
                type={FormType.Edit}
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.props.onCancel}
                onRestore={this.onRestore}
                isDeleted={this.props.call.deleted}
                loading={this.state.loading}
                error={this.state.error}
                initialValues={{
                    start: this.props.call.start,
                    end: this.props.call.end,
                    from: this.props.call.from.value,
                    to: this.props.call.to.value,
                    missedReason: this.props.call.missedReason
                }}
            >
                <Form.Item name="from" rules={[{ required: true, whitespace: true, message: "This field is required" }]}>
                    <ContactPointAutoComplete
                        title="From"
                        placeholder="Please type a value"
                        filters={{ types: [ContactPointType.Phone, ContactPointType.Email] }}
                    />
                </Form.Item>

                <Form.Item name="to" rules={[{ required: true, whitespace: true, message: "This field is required" }]}>
                    <ContactPointAutoComplete
                        title="To"
                        placeholder="Please type a value"
                        filters={{ types: [ContactPointType.Phone, ContactPointType.Email] }}
                    />
                </Form.Item>

                <SelectorInput
                    title="Start"
                    param="start"
                    selector={
                        <DateSelector showTime onChange={(value) => this.setState({ start: value })} />
                    }
                />

                <SelectorInput
                    title="End"
                    param="end"
                    selector={
                        <DateSelector showTime endOfDay onChange={(value) => this.setState({ end: value })} />
                    }
                />

                {this.state.start && this.state.end && this.state.start == this.state.end ?
                    <SelectorInput
                        title="Missed reason"
                        param="missedReason"
                        selector={<CallMissedReasonSelector />}
                    /> : null
                }

            </BaseForm>
        );
    }
}

export default CallEditForm;

