import * as React from "react";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { Popover, Spin, Card, Avatar, Button } from 'antd';
import { BankOutlined } from "@ant-design/icons";
import { ContactPointLinkView, ContactPointType, OrganizationLinkView, OrganizationView } from "../../ApiClient/swagger/data-contracts";
import AppContext from "../../Definitions/AppContext";
import { Capabilities } from "../../Definitions/_capabilties";
import client from "../../ApiClient/client";
import { useContext, useEffect, useState } from "react";


const { Meta } = Card;

interface OrganizationLinkCardProps {
    id?: string;
}


export function OrganizationLinkCard({ id }: OrganizationLinkCardProps) {

    const [error, setError] = useState<string>();
    const [avatarUrl, setAvatarUrl] = useState<string>();
    const [organization, setOrganization] = useState<OrganizationView>();

    const context = useContext(AppContext);

    useEffect(() => {
        if (!organization || id != organization.id)
            loadOrganization();

        context.events.organizations.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.organizations.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }

    }, [id, organization])


    function onItemUpdatedEvent(eventData: OrganizationView) {
        if (organization?.id === eventData.id) {
            if (organization.changeVector == eventData.changeVector) return;

            setOrganization(eventData);
        }
    }

    async function loadOrganization() {
        const response = await client
            .organizations.getOrganizationById(id)
            .catch(exception => setError(exception.error));

        if (response) {
            setOrganization(response.data.view);
            if (response.data.links?.avatar)
                setAvatarUrl(response.data.links.avatar.url);
        }
    }

    if (error) return <span>{error}</span>;
    if (!organization) return <Spin size="large" />;

    const fallbackAvatar = <Avatar shape="square" size={100} icon={<BankOutlined />} className="avatar" />;
    const avatar = avatarUrl ? <Avatar src={avatarUrl} shape="square" alt="avatar" /> : fallbackAvatar;

    const contactPoint = (type: ContactPointType): ContactPointLinkView[] => _.filter(organization.contactPoints, (cp: ContactPointLinkView) => cp.type == type)

    const phones = contactPoint(ContactPointType.Phone);
    const videos = contactPoint(ContactPointType.Video);
    const emails = contactPoint(ContactPointType.Email);

    const contactPoints = (
        <dl>
            {phones && phones.length > 0 ? <div className="phone" style={{ marginBottom: '0.5em' }}><dt>Phone</dt>{_.map(phones, phone => <dd style={{ marginBottom: 0 }} key={phone.value}><a href={`tel:${phone.value}`}>{phone.value}</a></dd>)}</div> : null}
            {videos && videos.length > 0 ? <div className="mobile" style={{ marginBottom: '0.5em' }}><dt>Video</dt>{_.map(videos, video => <dd style={{ marginBottom: 0 }} key={video.value}><a href={`sip:${video.value}`}>{video.value}</a></dd>)}</div> : null}
            {emails && emails.length > 0 ? <div className="email" style={{ marginBottom: '0.5em' }}><dt>Email</dt>{_.map(emails, email => <dd style={{ marginBottom: 0 }} key={email.value}><a href={`mailto:${email.value}`}>{email.value}</a></dd>)}</div> : null
            }
        </dl>
    );

    return (
        <Card className="actor-link-card link-card" bordered={false} size="small" title={organization.name}>
            <Meta avatar={avatar} description={
                <React.Fragment>
                    {contactPoints}
                </React.Fragment>
            }
            />
        </Card>
    );
}

interface OrganizationLinkProps extends OrganizationLinkCardProps, OrganizationLinkView {
    disablePopover?: boolean;
    disableRedirect?: boolean;
    className?: string;
    break?: boolean;
    stopPropagation?: boolean;
}



export function OrganizationLink(props: OrganizationLinkProps) {

    //const [error, setError] = useState<string>();

    const [organization, setOrganization] = useState<OrganizationView>(props);

    const context = useContext(AppContext);

    useEffect(() => {
        context.events.organizations.onMany({
            'updated': onItemUpdatedEvent,
            'deleted': onItemUpdatedEvent,
            'restored': onItemUpdatedEvent,
        });

        return () => {
            context.events.organizations.offMany({
                'updated': onItemUpdatedEvent,
                'deleted': onItemUpdatedEvent,
                'restored': onItemUpdatedEvent,
            });
        }
    }, [])

    useEffect(() => {
        function handleOrgChange() {
            if (props.id != organization.id)
                setOrganization(props);
        }
        handleOrgChange();
    }, [props])

    function onItemUpdatedEvent(eventData: OrganizationView) {
        if (organization.id === eventData.id) {
            setOrganization(eventData);
        }
    }

    if (!props.name) return null;

    if (!context.user.hasCapability(Capabilities.OrganizationsReadRelated))
        return <Popover content="You do not have access to read actors.">{organization.name}</Popover>;

    let className = props.break ? "" : "no-break";

    if (!props.disablePopover)
        className += " list-ellipsis";

    if (organization.deleted)
        className += " deleted";

    if (props.className)
        className += " " + props.className;

    if (props.disableRedirect)
        className += " disable-redirect"

    const link = props.disableRedirect
        ? <Button type="link" className={className}>{organization.name}</Button>
        : <Link onClick={(e) => e.stopPropagation()} to={`/organizations/${props.id}`} className={className}>{organization.name}</Link>;

    if (props.disablePopover || context.isMobile)
        return link;

    return (
        <span className="link-wrapper" >
            <Popover zIndex={1051} placement="bottomLeft" content={<OrganizationLinkCard id={organization.id} />}>
                {link}
            </Popover>
        </span>
    );
}