import client from "../../ApiClient/client";
import { PagedOrderCategoryQuery } from "../../ApiClient/swagger/data-contracts";
import { GenericEntitySelectorProps } from "../../Models/EntitySelectorProps";
import EntitySelector from "../Shared/EntitySelector";


interface OrderCategoryFilterProps extends GenericEntitySelectorProps<PagedOrderCategoryQuery> {
    types?: string[];
}

export function OrderCategorySelector(props: OrderCategoryFilterProps) {
    const placeholder = props.placeholder ? props.placeholder : 'Select order category...';
    const source = props.source ? props.source : client.orderCategories.queryOrderCategories;
    const defaultValueSource = props.defaultValueSource ? props.defaultValueSource : client.orderCategories.getOrderCategoryById;
    const optionDisplayNameParam = props.optionDisplayNameParam ? props.optionDisplayNameParam : 'name';

    return (
        <EntitySelector
            placeholder={placeholder}
            source={source}
            defaultValueSource={defaultValueSource}
            optionDisplayNameParam={optionDisplayNameParam}
            {...props}
        />
    );
}

export default OrderCategorySelector;