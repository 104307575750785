/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateOrUpdateExternalLink,
  ExternalLinkEntityPagedExternalLinkQueryCollectionViewModel,
  ExternalLinkGroupView,
  ExternalLinkSortOption,
  ExternalLinkView,
  ExternalLinkViewEntityViewModel,
  ExternalLinkViewPagedExternalLinkQueryCollectionViewModel,
  SortDirection,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Externallinks<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ExternalLink
   * @name GetExternalLinkById
   * @request GET:/api/externallinks/{id}
   * @secure
   */
  getExternalLinkById = (id: string, params: RequestParams = {}) =>
    this.http.request<ExternalLinkViewEntityViewModel, any>({
      path: `/api/externallinks/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name UpdateExternalLink
   * @request PUT:/api/externallinks/{id}
   * @secure
   */
  updateExternalLink = (id: string, data: CreateOrUpdateExternalLink, params: RequestParams = {}) =>
    this.http.request<ExternalLinkView, any>({
      path: `/api/externallinks/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name DeleteExternalLink
   * @request DELETE:/api/externallinks/{id}
   * @secure
   */
  deleteExternalLink = (id: string, params: RequestParams = {}) =>
    this.http.request<ExternalLinkView, any>({
      path: `/api/externallinks/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name CreateExternalLink
   * @request POST:/api/externallinks
   * @secure
   */
  createExternalLink = (data: CreateOrUpdateExternalLink, params: RequestParams = {}) =>
    this.http.request<ExternalLinkView, any>({
      path: `/api/externallinks`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name QueryExternalLinks
   * @request GET:/api/externallinks
   * @secure
   */
  queryExternalLinks = (
    query: {
      sortBy?: ExternalLinkSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      entityId?: string;
      group: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ExternalLinkViewPagedExternalLinkQueryCollectionViewModel, any>({
      path: `/api/externallinks`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name RestoreExternalLink
   * @request POST:/api/externallinks/{id}/restore
   * @secure
   */
  restoreExternalLink = (id: string, params: RequestParams = {}) =>
    this.http.request<ExternalLinkView, any>({
      path: `/api/externallinks/${id}/restore`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name UpdateExternalLinkAttributes
   * @request POST:/api/externallinks/{id}/attributes
   * @secure
   */
  updateExternalLinkAttributes = (id: string, data: Record<string, string>, params: RequestParams = {}) =>
    this.http.request<ExternalLinkView, any>({
      path: `/api/externallinks/${id}/attributes`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name GetExternalLinkGroups
   * @request GET:/api/externallinks/_groups
   * @secure
   */
  getExternalLinkGroups = (params: RequestParams = {}) =>
    this.http.request<ExternalLinkGroupView[], any>({
      path: `/api/externallinks/_groups`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name GetExternalLinkVariables
   * @request GET:/api/externallinks/_variables/{group}
   * @secure
   */
  getExternalLinkVariables = (group: string, params: RequestParams = {}) =>
    this.http.request<string[], any>({
      path: `/api/externallinks/_variables/${group}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalLink
   * @name QueryExternalLinkEntities
   * @request GET:/api/externallinks/_entities/{group}
   * @secure
   */
  queryExternalLinkEntities = (
    group: string,
    query: {
      sortBy?: ExternalLinkSortOption;
      sortDirection?: SortDirection;
      /** @format int32 */
      from?: number;
      /** @format int32 */
      limit?: number;
      entityId?: string;
      group: string;
      terms?: string;
      deleted?: boolean;
      attributes?: Record<string, string | null>;
      /** @format date-time */
      modifiedAfter?: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ExternalLinkEntityPagedExternalLinkQueryCollectionViewModel, any>({
      path: `/api/externallinks/_entities/${group}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
